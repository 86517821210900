import React, { CSSProperties, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Logger } from "@openteam/app-util";
import { observer } from "mobx-react";
import { DockSeparator } from "./DockSeparator";
import { DataState } from "../../Data/DataState";
import { OTUITree } from "@openteam/app-core";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { dockAnimatingStart, dockAnimatingStop, setDockComponentLength, UIState } from "../../Data/UIState";
import { autorun, makeAutoObservable } from "mobx";
import { getUserInteresting } from "../User/UserUtil";
import { DSAnimateListGrow } from "../../DesignSystem/DSAnimate";
import { tutorialState } from "../../Controllers/TutorialService";
import { DockRoomIcon, getDockRoomLength } from "./DockRoomIcon";


const logger = new Logger("DockCalls")




interface IDockCallsProps {
  spaceId: string
  adjustDockScroll?: (amount: number) => void
}

export const DockCalls = observer((props: IDockCallsProps) => {
  const tileSizeRatio = DSTheme.DockRoomRatio;
  const itemLengthRatio = (1 - DSTheme.DockMargin * 2);

  const tileSize = Math.floor(DSTheme.DockRoomRatio * UIState.dockSize);
  const itemLength = Math.floor((1 - DSTheme.DockMargin * 2) * UIState.dockSize);

  const myUserId = OTUITree.auth.userId;

  const [currentCalls, setCurrentCalls] = useState<string[]>([]);
  const [callSizes, setCallSizes] = useState<{ [key: string]: number }>({});

  useEffect(
    () =>
      autorun(() => {
        let interestingCalls: string[] = [];
        const space = DataState.spaces[props.spaceId];

        logger.debug(`in DockCalls autorun with ${Object.keys(space.calls).length} calls`)

        Object.keys(space.calls).forEach((callId) => {
          const call = space.calls[callId];
          if (myUserId in call.participants) {
          } else if (call.channelId && call.channelId in space.pods) {
            // pod calls show in pods
            // interestingCalls.push(callId);
            //logger.debug(`Call ${callId} on channel ${call.channelId}`)
          } else if (call.requests?.[myUserId]?.active) {
            interestingCalls.push(callId);
          } else {
            const hasInterestingUser = Object.keys(call.participants).some((userId) => {
              const { hasAlert, openChat, pinned, hasMessage } = getUserInteresting(props.spaceId, userId);
              return hasAlert || openChat || pinned || hasMessage || userId in UIState.dockRecentUsers[props.spaceId];
            });

            //logger.debug("call.channelId: %s, space.pods: %o", call.channelId, Object.keys(space.pods));

            if (hasInterestingUser || (call.channelId && space.pods[call.channelId]?.pinned)) {
              logger.debug(`Call ${callId} is interesting`)
              interestingCalls.push(callId);
            } else {
              logger.debug(`Call ${callId} not interesting`)
            }
          }
        });

        if (tutorialState.active) {
          interestingCalls = [];
        }


        const callHeights = Object.fromEntries(
          interestingCalls.map((callId) => [callId, getDockRoomLength(props.spaceId, callId, tileSizeRatio, itemLengthRatio)])
        );

        const totalCallHeight = Object.values(callHeights).reduce((a, b) => a + b, 0);
        setDockComponentLength('Calls', totalCallHeight, true);

        setCallSizes((_callSizes) => {
          if (JSON.stringify(_callSizes) !== JSON.stringify(callHeights)) {
            logger.debug('callHeights: %o', props.spaceId, callHeights, totalCallHeight);
            return callHeights
          }
          return _callSizes;
        });

        setCurrentCalls((_calls) => {
          if (JSON.stringify(_calls) !== JSON.stringify(interestingCalls)) {
            logger.debug('interestingCalls', interestingCalls);
            return interestingCalls;
          }
          return _calls;
        });
      }),
    [props.spaceId, setCurrentCalls, itemLengthRatio]
  );

  const renderCall = useCallback((callId) => {

    const callSpace = DataState.spaces[props.spaceId]

    return callSpace.calls[callId] && <DockRoomIcon
      key={callId}
      spaceId={props.spaceId}
      callId={callId}
      podId={callSpace.calls[callId].channelId}
    />
  }, [props.spaceId])

  const getCallSizeStyle = useCallback(
    (callId: string, index: number) => ({
      height: UIState.dockHorizontal ? UIState.dockSize : Math.floor(callSizes[callId] * UIState.dockSize),
      width: UIState.dockHorizontal ? Math.floor(callSizes[callId] * UIState.dockSize) : UIState.dockSize,
      opacity: 1
    }),
    [callSizes, tileSizeRatio, currentCalls, UIState.dockHorizontal, UIState.dockSize]
  );

  const onAnimationStart = useCallback(() => dockAnimatingStart('dock-calls'), []);
  const onAnimationStop = useCallback(() => dockAnimatingStop('dock-calls'), []);

  useLayoutEffect(() => logger.debug("rendering dock calls"))

  return (
    <>
      {
        currentCalls.length > 0 ?
          <DockSeparator />
          :
          null
      }
      <DSAnimateListGrow
        label='DockCalls'
        horizontal={UIState.dockHorizontal}
        width={UIState.dockHorizontal ? itemLength : UIState.dockSize}
        height={UIState.dockHorizontal ? UIState.dockSize : itemLength}
        itemStyle={{
          position: 'relative',
        }}
        renderItem={renderCall}
        items={currentCalls}
        initialStyle={getCallSizeStyle}
        enterStyle={getCallSizeStyle}
        updateStyle={getCallSizeStyle}
        outerStyle={Styles.outerStyle}
        onListStart={onAnimationStart}
        onListRest={onAnimationStop}
      />


    </>
  )
})

class StylesClass {

  constructor() {
    makeAutoObservable(this);
  }

  get outerStyle(): CSSProperties {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }
}

const Styles = new StylesClass();
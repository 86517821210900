import React from 'react';

import { Logger } from "@openteam/app-util";
import { KCallId, KSpaceId, KSpacePodId } from '@openteam/models';
import { observer } from 'mobx-react';
import { DataState } from '../../Data/DataState';
import { getDummyCall, getDummyPod, isDummyCall, isDummyPod } from '../../Data/tutorialDummy';
import { DS2ButtonProps, DS2CallButton, DS2CancelCallButton, DS2LeaveCallButton } from '../../DesignSystem';
import { OTUITree } from '@openteam/app-core';
import { playKnock } from '../../Controllers/SoundEffects';
import { Icons, SplitButton } from '@openteam/design-system';

const logger = new Logger('RoomCallButton');


interface Props extends DS2ButtonProps {
  spaceId: KSpaceId;
  podId?: KSpacePodId;
  callId?: KCallId;
  analyticsPrefix?: string;
}

const RoomCallButton: React.FC<Props> = (
  { spaceId, podId, callId, analyticsPrefix = '', ...props }
) => {
  const space = DataState.spaces[spaceId]
  const channel = podId ? space.channels[podId] : undefined

  const pod = podId ? isDummyPod(podId) ? getDummyPod(podId) : DataState.spaces[spaceId].pods[podId] : undefined;
  const call = callId ? isDummyCall(callId) ? getDummyCall(callId) : space.calls[callId] : undefined;
  const participants = call?.participants || {}
  const callOpen = participants[OTUITree.auth.userId]?.currentSessionToken == OTUITree.auth.sessionToken
  const inCall = OTUITree.auth.userId in participants;

  
  const startCall = React.useCallback((roomMode) => {
    logger.debug(`startCall ${roomMode}`)
    channel?.actions.startCall(undefined, roomMode);
  }, [channel?.actions.startCall]);

  const leaveCall = React.useCallback(() => {
    call?.actions.leaveCall && call?.actions.leaveCall();
  }, [call?.actions.leaveCall]);

  const joinCall = React.useCallback(() => {
    call?.actions.joinCall()
  }, [call?.actions.joinCall]);

  const requestJoinCall = React.useCallback(() => {
    call?.actions.requestJoinCall();
    playKnock();
  }, [call?.actions.requestJoinCall]);

  const cancelRequestJoinCall = React.useCallback(() => {
    call?.actions.cancelRequestJoinCall();
  }, [call?.actions.cancelRequestJoinCall]);


  if (!call) {
    if ((props.buttonStyle ?? 'default') === 'default') {
      logger.debug(`buttonStyle: ${props.buttonStyle}, using Split`)
      return (
        <SplitButton
          analyticsEvent={`${analyticsPrefix}JoinCall`}
          color="success"
          analyticsArgs={{ podId }}
          onClick={startCall}
          options={[
            {value: "audio", icon: Icons.audio, title: "Enter Audio Call"},
            {value: "video", icon: Icons.video, title: "Enter Video Call"},
          ]}
          {...props}
        />
      )
    } else {
      logger.debug(`buttonStyle: ${props.buttonStyle}, using Call`)
      return (
        <DS2CallButton
          analyticsEvent={`${analyticsPrefix}JoinCall`}
          analyticsArgs={{ callId }}
          onClick={() => startCall('video')}
          title="Enter"
          {...props}
        />
      )
    }
  } else if (callOpen) {
    return (
      <DS2LeaveCallButton
        analyticsEvent={`${analyticsPrefix}LeaveCall`}
        onClick={leaveCall}
        {...props}

      />
    )
  } else if (pod) {
    const icon = call.roomMode === 'audio' ? Icons.audio : Icons.video;
    return (
      <DS2CallButton
        analyticsEvent={`${analyticsPrefix}JoinCall`}
        analyticsArgs={{ callId }}
        startIcon={icon}
        onClick={joinCall}
        title="Enter"
        {...props}
      />
    )
  } else if (call.requests?.[OTUITree.auth.userId]?.active) {
    return (
      <DS2CancelCallButton
        analyticsEvent={`${analyticsPrefix}RequestCancel`}
        title="Cancel Request to Join"
        analyticsArgs={{ callId }}
        onClick={cancelRequestJoinCall}
        {...props}
        />
    )
  } else {
    return (
      <DS2CallButton
        analyticsEvent={`${analyticsPrefix}RequestJoinCall`}
        analyticsArgs={{ callId }}
        onClick={requestJoinCall}
        title='Request to Join'
        {...props}
      />
    )
  }
}

export default observer(RoomCallButton);

import React, { DragEventHandler, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { DSPanel, DSRow } from '../../DesignSystem';
import { ChatMessages } from './ChatMessages';
import { ChatCompose } from './ChatCompose';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { CallDetailsDb } from '@openteam/app-core';
import { IMessageManager, KSpaceId, ILinkPreview, IFile, ICallDetails } from '@openteam/models';
import { FaUpload } from 'react-icons/fa';

import { Logger } from "@openteam/app-util";
import { DataState } from '../../Data/DataState';
import { Droppable } from '../DragAndDrop';
import { RemirrorCSS } from './MarkdownEditor';
import { ChatMarkdownCSS } from './ChatMarkdownView';
import { DSSpinner } from '../../DesignSystem/DSSpinner';
import * as Fire from "../../Controllers/Fire"
import { getFriendlyDate } from "../../Util/DateFormat";

import { DSBody, DSPrint } from '../../DesignSystem/DSText';
import { SpacePanelCallIcon } from '../Space/SpacePanelIcons';
import { LightBoxEntry } from '../Lightbox/Lightbox';
const logger = new Logger("RoomCallChat")


interface IRoomCallChatProps {
  spaceId: KSpaceId
  callId: string
  callMessageManager: IMessageManager,
  readOnly?: boolean
  windowId?: string
  showLightbox?: (items: LightBoxEntry[], initialSelected?: number) => void
  sendURL?: (url: string, systemMessage: string) => void
}

export const RoomCallChat = observer((props: IRoomCallChatProps) => {
  const _chatRef = React.useRef<HTMLDivElement>(null)
  const _composeRef = React.useRef<{ focus: () => void }>(null)

  const space = DataState.spaces[props.spaceId]


  const focusInput = () => {
    _composeRef.current?.focus()
  }

  const [isOver, setIsOver] = useState<boolean>(false);
  const onDrop: DragEventHandler = (e) => {
    setIsOver(false);

    if (!e.dataTransfer.files) {
      return;
    }

    Object.keys(e.dataTransfer.files || {}).forEach((i) => {
      let file = e.dataTransfer.files[i];

      props.callMessageManager.addDraftFiles([file])
    });
  }

  const getCallDetails = async () => CallDetailsDb.getCallDetails(
    Fire.getFirestore(),
    props.spaceId,
    props.callId
  );

  const getUser = (userId: string) => {
    return props.callMessageManager.users[userId]
  }




  const saveLinkToChat = async (id: string, linkPreview: ILinkPreview, linkId: string | undefined) => {
    await props.callMessageManager.saveLinkPreviewToChat(id, linkId);

    const systemMessage = props.callMessageManager.crDate ? `added from call @ ${getFriendlyDate(props.callMessageManager.crDate)}` : 'added from call';

    props.sendURL?.(linkPreview.url, systemMessage);
  }

  const saveAttachmentToChat = async (id: string, file: IFile, fileId: string) => {
    logger.debug("saveAttachmentToChat", file.url);
    await props.callMessageManager.saveAttachmentToChat(id, fileId);

    const systemMessage = props.callMessageManager.crDate ? `added from call @ ${getFriendlyDate(props.callMessageManager.crDate)}` : 'added from call';

    props.sendURL?.(file.url, systemMessage);
  }


  if (!props.callMessageManager.started) {
    return <DSPanel
      ref={_chatRef}
      style={{
        flex: 1,
        minHeight: 0,
        minWidth: 0,
        color: DSTheme.MainText,
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <DSSpinner />
    </DSPanel>
  }


  return (
    <DSPanel
      ref={_chatRef}
      style={{
        flex: 1,
        minHeight: 0,
        minWidth: 0,
        color: DSTheme.MainText
      }}>
      <Droppable
        acceptItemType={"Files"}
        onDrop={onDrop}
        onDragOver={(e) => setIsOver(true)}
        onDragLeave={(e) => setIsOver(false)}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
        }}
      >
        {isOver &&
          <div
            style={{
              position: "absolute",
              top: 0, left: 0, bottom: 0, right: 0,
              backgroundColor: 'rgba(255,255,255,0.7)',
              zIndex: 9,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaUpload size={40} style={{ color: 'red', pointerEvents: 'none' }} />
          </div>
        }

        <DSPanel style={{
          position: 'relative',
          borderRadius: 0,
          flex: 1,
          minHeight: 0,
          minWidth: 0,
          maxWidth: "100%",
        }}>
          <ChatMessages
            spaceId={props.spaceId}
            messages={props.callMessageManager.messages}
            atStart={true}
            pendingMessages={props.callMessageManager.pendingMessages}
            header={
              <DSRow style={{ alignItems: 'center', justifyContent: 'center', width: "100%", padding: 4 }}>
                <SpacePanelCallIcon size={12} />
                <DSBody style={{ marginLeft: 8 }}>
                  Messages in this chat will only be shown to members of this call.
                </DSBody>
              </DSRow>
            }
            messageId={props.callMessageManager.messageId}
            lastReadMessageId={props.callMessageManager.lastReadMessageId}
            messageNum={props.callMessageManager.messageNum}
            messageNumRead={props.callMessageManager.messageNumRead}
            numUnread={props.callMessageManager.numUnread}

            isWatching={props.callMessageManager.started}

            deleteMessage={props.callMessageManager.deleteChatMessage}
            editMessage={props.callMessageManager.editChatMessage}
            setReply={props.callMessageManager.setDraftReplyMessage}
            saveLinkToChat={saveLinkToChat}
            saveAttachmentToChat={saveAttachmentToChat}
            showLightbox={props.showLightbox}
            sendURL={props.sendURL}

            focusInput={focusInput}
            readOnly={props.readOnly}
            getUser={getUser}
          />
          {!props.readOnly ?
            <ChatCompose
              ref={_composeRef}
              spaceId={props.spaceId}
              draftMessage={props.callMessageManager.draftMessage}
              setMessage={(value) => {
                logger.debug("setMessage", value)
                props.callMessageManager.setDraftText(value)
              }}
              draftFiles={props.callMessageManager.draftFiles}
              addFile={(file) => props.callMessageManager.addDraftFiles([file])}
              addMessageFile={props.callMessageManager.addDraftMessageFile}
              removeFile={props.callMessageManager.removeDraftMessageFile}
              draftReply={props.callMessageManager.draftReplyMessage}
              setReply={props.callMessageManager.setDraftReplyMessage}
              userIds={Object.keys(props.callMessageManager.users)}
              usersTyping={props.callMessageManager.chatUserIsTyping}
              sendMessage={() => {

                if (props.callMessageManager.draftMessage || props.callMessageManager.draftFiles.length > 0) {
                  props.callMessageManager.sendChatMessage(
                    props.callMessageManager.draftMessage,
                    props.callMessageManager.draftFiles,
                    props.callMessageManager.draftReplyMessage,
                    props.windowId)
                  return true
                }
                return false
              }}
              resetDraft={props.callMessageManager.resetDraft}
            />
            : undefined
          }
        </DSPanel>
      </Droppable>
      <style type="text/css">{RemirrorCSS}</style>
      <style type="text/css">{ChatMarkdownCSS}</style>

    </DSPanel>
  )

})
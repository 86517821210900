import { Logger } from "@openteam/app-util"
import { observer } from "mobx-react";
import React from "react";
import { ModalWindow } from "../../Modal/ModalWindow";
import { SubWindowCSS } from "../../SubWindow";
import { DSModalPanel } from '../../Modal/ModalLayout';
import { DSPanel, DSRow } from '../../../DesignSystem';
import { DSButton } from '../../../DesignSystem/DSButton';
import { DSInput } from '../../../DesignSystem/DSInput';
import { DSPrint } from '../../../DesignSystem/DSText';
import { DSTheme } from '../../../DesignSystem/DSTheme';
import { DS2SaveButton } from "../../../DesignSystem/DS2";

const logger = new Logger("AddResourceWindow");

export const AddResourceWindow: React.FC<{
  onClose: () => void,
  parentWindowId: string,
  sendURL: (text: string, systemMessage: string) => void
}> = observer(({ onClose, parentWindowId, sendURL }) => {
  const windowId = `add-resource`;
  let width = 240
  let height = 260

  return (
    <ModalWindow
      id={windowId}
      parentId={parentWindowId}
      show={true}
      initialSize={{ width: width, height: height }}
      minHeight={height}
      minWidth={width}
      onClose={onClose}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <AddResource windowId={windowId} onClose={onClose} sendURL={sendURL}/>
    </ModalWindow>
  );
});

const AddResource: React.FC<{
  windowId: string,
  onClose: () => void,
  sendURL: (text: string, systemMessage: string) => void
}> = ({ windowId, onClose, sendURL }) => {

  const [link, setLink] = React.useState("");

  const onSave = () => {

    if (link) {
      sendURL(link, 'added from drive');
    }
    setLink("");
    onClose();
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') onSave();
  }

  return (
    <DSModalPanel
      windowId={windowId}
      title={"Add to drive"}
      onClose={onClose}
      footer={
        <DSRow>
          <DS2SaveButton
            fullWidth={true}
            title={"Add to drive"}
            onClick={onSave}
          />
        </DSRow>
      }
    >
      <DSPrint wrap style={{ marginBottom: 16 }}>
        Keep your files and links where you need them by adding them here.
      </DSPrint>

      <DSInput
        value={link}
        placeholder={"Paste link here"}
        onChange={(e) => setLink(e.target.value)}
        onKeyDown={onKeyDown}
      />
      <DSPanel style={{ flex: 1 }}>

      </DSPanel>
    </DSModalPanel >
  );
}

import { Logger } from "@openteam/app-util"
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DSColumn, DSHSpacer, DSPanel, DSRow } from '../../../DesignSystem';
import { DSButton, DSCircleIconButton } from '../../../DesignSystem/DSButton';
import { DSEditable2 } from '../../../DesignSystem/DSInput';
import { DSPrint } from '../../../DesignSystem/DSText';
import { DSTheme } from '../../../DesignSystem/DSTheme';
import { IPinnedResource } from "@openteam/models";
import { PopupWindow } from "../../Popup/PopupWindow";
import { Draggable } from "../../Draggable";
import { FaCopy, FaShareSquare, FaThumbtack, FaTimes, FaTrash } from "react-icons/fa";
import { FileIcon } from "../../FavIcon";
import { ToolTipContainer } from "../../../Controllers/ToolTips";
import { getFriendlyDate, isToday } from "../../../Util/DateFormat";
import { DSImage } from "../../../DesignSystem/DSImage";
import { DS2Button, DS2CloseIconButton, DS2CopyIconButton, DS2DeleteButton, DS2PinButton } from "../../../DesignSystem/DS2";

const logger = new Logger("ResourcePropertiesWindow");

export const ResourcePropertiesWindow: React.FC<{
  onClose: () => void,
  parentWindowId: string,
  windowId: string,
  resource: IPinnedResource,
  isPinned: boolean,
  togglePinnedResource: (resource: IPinnedResource) => Promise<void>,
  deleteResource: (resource: IPinnedResource) => Promise<void>,
  setResourceName: (resource: IPinnedResource, name: string) => Promise<void>,
  openResource: (resource: IPinnedResource, openForEveryone?: boolean) => Promise<void>,
  inCall: boolean,
  createdAt?: number,
  createdBy?: string,
}> = observer(({ windowId, onClose, parentWindowId, ...otherProps }) => {
  let width = 320
  let height = 380

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <PopupWindow
        id={windowId}
        targetRef={ref}
        parentId={parentWindowId}
        open={true}
        initialSize={{ width: width, height: height }}
        height={height}
        width={width}
        onClose={onClose}
      >
        <ToolTipContainer parentId={windowId} />
        <ResourceProperties
          windowId={windowId}
          onClose={onClose}
          {...otherProps}
        />
      </PopupWindow>
    </div>
  );
});

const ResourceProperties: React.FC<{
  windowId: string,
  onClose: () => void,
  resource: IPinnedResource,
  isPinned: boolean,
  togglePinnedResource: (resource: IPinnedResource) => Promise<void>,
  deleteResource: (resource: IPinnedResource) => Promise<void>,
  setResourceName: (resource: IPinnedResource, name: string) => Promise<void>,
  openResource: (resource: IPinnedResource, openForEveryone?: boolean) => Promise<void>,
  inCall: boolean,
  createdAt?: number,
  createdBy?: string,
}> = ({
  windowId,
  onClose,
  resource,
  isPinned,
  deleteResource,
  togglePinnedResource,
  setResourceName,
  openResource,
  createdAt,
  createdBy,
  inCall
}) => {
    const toolTipDefault = "Copy link"
    const [copyTooltip, setCopyTooltip] = useState(toolTipDefault)

    const linkCopiedTimeout = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
      return () => linkCopiedTimeout.current && clearTimeout(linkCopiedTimeout.current)
    }, [])

    const copyLink = useCallback(() => {
      window.Main.clipboardWriteText(resource.url);
      setCopyTooltip('Link copied!');
      linkCopiedTimeout.current = setTimeout(() => setCopyTooltip(toolTipDefault), 2000);
    }, [resource.url])

    const dtCreatedAt = createdAt ? new Date(createdAt) : undefined;

    return (
      <DSColumn style={{
        flexGrow: 1,
        height: "100%",
/*         borderRadius: "10px",
 */        backgroundColor: DSTheme.PanelBackgroundColor,
        color: DSTheme.MainText,
        overflow: 'hidden',
      }}>
        <Draggable windowId={windowId}>

          <DSRow
            style={{
              height: 64,
              paddingTop: 16,
              paddingBottom: 10,
              paddingLeft: 16,
              paddingRight: 8,
              alignItems: "flex-start",
            }}>
            <FileIcon hit={resource} size={40} />
            <DSColumn style={{ flexGrow: 1, paddingLeft: 10, paddingRight: 5, overflow: "hidden", }}>

              <DSEditable2
                value={resource.name}
                style={{
                  borderRadius: 6,
                  padding: "2px 0px",
                }}
                maxWidth={190}
                tabIndex={-1}
                onValueChange={(name) =>
                  setResourceName(resource, name)
                }
              />

              {!!dtCreatedAt && !!createdBy && (
                <DSPrint wrap style={{ padding: "5 0", color: "rgb(171, 171, 173)" }}>
                  by {createdBy} {isToday(dtCreatedAt) ? "at" : "on"} {getFriendlyDate(dtCreatedAt)}
                </DSPrint>
              )}

            </DSColumn>

            <DSRow style={{ alignItems: 'center', marginTop: -4 }} spacing={8}>
              <DS2PinButton
                pinned={isPinned}
                onClick={() => togglePinnedResource({ ...resource, createdAt, createdBy })}
              />
              <DS2CloseIconButton
                style={{ padding: 4 }}
                onClick={onClose}
              />
            </DSRow>
          </DSRow>
        </Draggable>

        <DSPanel style={{ flexGrow: 1, paddingLeft: 16, paddingRight: 16, paddingTop: 20, paddingBottom: 20}}>
          <DSButton
            data-tooltip='Open link'
            onClick={() => openResource(resource, false)}
            style={{ width: "100%" }}
          >
            <DSRow style={{ overflow: "hidden", maxHeight: 60, alignItems: "center", width: "100%" }}>
              <DSPrint
                wrap
                style={{
                  maxHeight: 30,
                  overflow: "hidden",
                  textOverflow: "elllipsis",
                  maxWidth: "100%",
                  color: "#2D71B6",
                  flex: 1,
                }}
              >
                {resource.url}
              </DSPrint>
              <DS2CopyIconButton
                data-tooltip={copyTooltip}
                onClick={copyLink} />
            </DSRow>
          </DSButton>
          {resource.description && (
            <DSRow style={{ overflow: "hidden", maxHeight: 60, alignItems: "center", marginTop: 8 }}>
              <DSPrint
                wrap
                style={{
                  maxHeight: 60,
                  overflow: "hidden",
                  textOverflow: "elllipsis",
                }}
              >
                {resource.description}
              </DSPrint>
            </DSRow>
          )}
          <DSColumn style={{ alignItems: 'flex-start' }}>
            <ResourcePreview resource={resource} />
          </DSColumn>
        </DSPanel>
        <DSPanel style={{ padding: 16 }}>
          <DSRow style={{ alignItems: "center", }} spacing={8}>
            <DS2DeleteButton
              style={{flexGrow: 1}}
              fullWidth={true}
              onClick={() => { deleteResource(resource); onClose() }}
            />

            <DS2Button
              style={{flexGrow: 1}}
              fullWidth={true}
              onClick={() => { openResource(resource, false); }}
            >
              Open
            </DS2Button>


            {inCall && (
              <>
                <DSHSpacer size={6} />
                <DSButton
                  title={"Share in call"}
                  style={{
                    backgroundColor: DSTheme.SecondaryColor,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 5,
                    paddingBottom: 5,
                    borderRadius: 4
                  }}
                  onClick={() => { openResource(resource, true); }}
                >
                  <FaShareSquare style={{ color: DSTheme.PanelContrastColor, paddingRight: 5 }} />
                </DSButton>
              </>
            )}
          </DSRow>
        </DSPanel>
      </DSColumn >
    )
  }

const ResourcePreview: React.FC<{ resource: IPinnedResource }> = ({ resource }) => {

  let imageUrl: string | undefined;

  if (
    (resource.recordType === 'link' && resource.contentType?.startsWith("image/")) ||
    (resource.recordType === 'attachment' && resource.type?.startsWith("image"))
  ) {
    imageUrl = resource.url;

  } else if (resource.image) {
    imageUrl = resource.image;
  }

  if (imageUrl) {
    return <DSImage
      url={imageUrl}
      maxWidth={200}
      maxHeight={150}
      dummyColor="transparent"
      style={{
        marginTop: 16,
      }}
    />
  } else {
    return null //<FileIcon hit={resource} size={80} />
  }
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  initDock,
  onDockMoved,
  shutdownDock,
  useWindowHide,
  // syncHideDock
} from "../../Controllers/DockWindow";

import { useHoverIntent } from "../../Util/HoverIntent";
import { observer } from "mobx-react";
import { DSTheme } from "../../DesignSystem/DSTheme";

import { Logger } from "@openteam/app-util";
import { OTUITree, UIDataState } from '@openteam/app-core';
import { dockAnimatingStart, dockAnimatingStop, dockComponentHeights, openMainSpace, openOnboarding, openSettings, UIState } from "../../Data/UIState";
import { animated, useSpring } from "@react-spring/web";
import { action, autorun, runInAction } from "mobx";
import * as Analytics from '../../Util/Analytics'
import { ToolTipContainer } from "../../Controllers/ToolTips";
import { tutorialState } from "../../Controllers/TutorialService";
import { DockContainer } from "./DockContainer";

const mainLogger = new Logger("Main")
const logger = new Logger("Dock")


const SHOW_COMPONENT_HEIGHTS = false;
const SHOW_COMPONENT_COLORS = ["red", "orange", "yellow", "green", "blue", "purple"]

interface IDockProps {
}

export const Dock = observer((props: IDockProps) => {
  const [componentHeights, setComponentHeights] = useState<number[]>([])
  let prevCurrentDisplay = useRef<number | undefined>(undefined);

  const myUserId = OTUITree.auth.userId;

  const [hovered, hoveredRef, clearHover] = useHoverIntent<HTMLDivElement>({
    minDelay: 150,
    minVelocity: 20,
    timeout: 500,
    //label: 'dock'
  });

  const [forceShowDock, setForceShowDock] = useState<boolean>(true)


  useEffect(() => {
    Analytics.logEvent("Screen_Dock",)

    setTimeout(() => setForceShowDock(false), 5000)
  }, [])


  useEffect(() => {
    if (prevCurrentDisplay.current != UIState.currentDisplay?.id) {
      Analytics.logEvent("UIState.currentDisplay changed",)

      revealDock();
      prevCurrentDisplay.current = UIState.currentDisplay?.id;
    }

  }, [UIState.currentDisplay?.id])

  const dockHasAlert = UIState.dockExistingCall ||
    UIState.dockHasUserAlert ||
    UIState.dockHasWaitingUsers ||
    UIState.dockOtherHasAlert ||
    UIState.dockCallHasAlert ||
    UIState.dockHasMeetingAlert;

  const hideDock = (UIState.dockEdge && UIState.dockAutoHide) && !hovered && !dockHasAlert && !UIState.dockPanelShown && !UIState.isDragging && !tutorialState.showDock && !forceShowDock;

  const revealDock = () => {
    setForceShowDock(true)

    setTimeout(() => setForceShowDock(false), 5000)
  }

  useEffect(() => autorun(() => {
    if (SHOW_COMPONENT_HEIGHTS) {
      setComponentHeights(dockComponentHeights())
    }
  }), [])

  useEffect(() => {


    const resized = (args) => {
      // setWindowHeight(args.dockPosition.height);
    }

    runInAction(() => UIState.dockShown = !hideDock)

    const mainLoggerCB = (logArgs) => { mainLogger.info(...logArgs?.args) }

    const offResize = window.Main.on("resized", resized);
    const offMoved = window.Main.on("dock-moved", onDockMoved)
    const offlog = window.Main.on("log", mainLoggerCB);
    const offMain = window.Main.on("open-main", openMainSpace)
    const offSettings = window.Main.on("open-settings", openSettings)
    const offRevealDock = window.Main.on("reveal-dock", revealDock)
    const offShowOnboarding = window.Main.on("show-onboarding", openOnboarding)

    initDock()

    return () => {
      offResize();
      offMoved();
      offlog();
      offMain();
      offSettings();
      shutdownDock();
      offRevealDock();
      offShowOnboarding();
    }
  }, [])

  useEffect(() => {
    if (hovered && forceShowDock) {
      setForceShowDock(false);
    }

    logger.debug("hideDock", {
      dockAutoHide: UIState.dockAutoHide,
      hovered,
      dockHasAlert: dockHasAlert,
      dockMainSpaceFocused: UIState.dockMainSpaceFocused,
      dockPanelShown: UIState.dockPanelShown,
      isDragging: UIState.isDragging,
      dockExistingCall: UIState.dockExistingCall,
      dockHasUserAlert: UIState.dockHasUserAlert,
      dockHasWaitingUsers: UIState.dockHasWaitingUsers,
      dockOtherHasAlert: UIState.dockOtherHasAlert,
      dockCallHasAlert: UIState.dockCallHasAlert
    })
  }, [UIState.dockAutoHide, hovered, dockHasAlert, UIState.dockMainSpaceFocused, UIState.dockPanelShown, UIState.isDragging])


  useEffect(() => {
    Analytics.logEvent(`Screen_${hideDock ? 'Hide' : 'Show'}Dock`, { teamId: OTUITree.userManager.currentTeamId })

    // if I'm showing the dock, mark it as shown immediately
    if (useWindowHide || (!hideDock && UIState.dockShown != !hideDock)) {
      UIState.dockShown = !hideDock;
    }

  }, [hideDock])

  const styles = useSpring({
    left: hideDock && !useWindowHide && !UIState.dockHorizontal ?
      (UIState.dockSize - Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)) * (UIState.dockEdge == 'left' ? -1 : 1) : 0,
    top: hideDock && !useWindowHide && UIState.dockHorizontal ?
      (UIState.dockSize - Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)) * (UIState.dockEdge == 'top' ? -1 : 1) : 0,

    onStart: action(() => {
      dockAnimatingStart('hide-dock');

    }),
    onRest: action(() => {
      // if im hiding the dock, once the dock animation has finished, set dockShown to false
      if (hideDock) {
        UIState.dockShown = !hideDock

      }
      dockAnimatingStop('hide-dock');
    })
  })

  const clearDockHover = useCallback(() => {
    clearHover()
  }, [clearHover])

  return (
    <>
      <ToolTipContainer
        parentId="__dock__"
        showWhenUnfocused={true}
        delay={0}
        offset={UIState.dockSize/9}
        show={hovered && !UIState.dockAnimating && !UIState.dockPanelShown}
      />
      <animated.div
        ref={hoveredRef}
        style={{
          overflow: "hidden",
          position: "absolute",
          display: 'flex',
          flexDirection: 'column',
          ...styles
        }}>
        {
          UIState.dockInited && OTUITree.userManager.currentTeamId && UIDataState.spaces[OTUITree.userManager.currentTeamId]?.users[myUserId] ?
            <DockContainer clearDockHover={clearDockHover} hovered={hovered}/>
              :
              null
          }
      </animated.div>
      {
        SHOW_COMPONENT_HEIGHTS && (
          <div style={{ height: UIState.dockLength, backgroundColor: "grey", width: 10, position: 'relative' }}>
            {componentHeights.map((componentHeight: number, idx) =>
              <div
                key={idx}
                style={{
                  position: "absolute",
                  backgroundColor: SHOW_COMPONENT_COLORS[idx % SHOW_COMPONENT_COLORS.length],
                  top: componentHeights.slice(0, idx).reduce((a, b) => a + b, 0),
                  width: 10,
                  height: componentHeight
                }} />
            )}
          </div>
        )
      }
    </>)

})

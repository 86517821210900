import React, { forwardRef, useRef, useMemo, useImperativeHandle, useState } from "react";
import { observer } from "mobx-react";
import { IMessageFile, IOTChatMessage, KSpaceChannelId, KSpaceId } from '@openteam/models';
import { OTUITree, WebFile } from '@openteam/app-core'
import { Logger } from "@openteam/app-util";
import { DataState } from "../../Data/DataState";

import { DSBody, DSH5 } from "../../DesignSystem/DSText"
import { DSColumn, DSPanel, DSRow, DSWrappedRow } from "../../DesignSystem"
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSButton, DSCircleIconButton } from "../../DesignSystem/DSButton";
import { FaLocationArrow, FaPaperclip,  FaRegSmile, FaTimes } from "react-icons/fa";

import { EmojiPicker } from './ChatEmojiPicker';
import { GifPicker } from './ChatGifPicker';
import { ChatFileUpload } from "./ChatFileUpload";
import { ChatMessage } from "./ChatMessage";
import { MarkdownEditor, MarkdownEditorHandle } from "./MarkdownEditor";
import { useCallback } from "react";
import { styled, DS2ToggleButtonGroup, DS2ToggleButton, DS2CloseIconButton, DS2Icons } from "../../DesignSystem/DS2";
import * as Analytics from '../../Util/Analytics'

const logger = new Logger("ChatCompose")

const acceptedImageFiles = ['image/gif', 'image/png', 'image/jpeg', 'image/bmp']

type Props = {
  spaceId: KSpaceId
  draftMessage: string
  setMessage: (value: string) => void
  draftFiles: IMessageFile[]
  addFile: (file: File) => void
  addMessageFile: (messageFile: IMessageFile) => void
  removeFile: (index: number) => void
  draftReply?: IOTChatMessage
  setReply: (message?: IOTChatMessage) => void
  userIds: string[]
  usersTyping: Record<string, { lastTyping: Date }>
  sendMessage: () => boolean
  resetDraft: () => void
}

export const ChatCompose = observer(forwardRef((props: Props, ref) => {
  const [assetPicker, setAssetPicker] = useState<undefined | string>(undefined);
  const [key, setKey] = useState(0)

  let _fileInput = React.createRef<HTMLInputElement>();
  const editorRef = React.useRef<MarkdownEditorHandle>(null)

  const space = DataState.spaces[props.spaceId]

  if (!space.channels) {
    return null
  }

  const initialValue = useRef(props.draftMessage);

  const users = useMemo(() =>
    props.userIds.map(userId => (
      {
        id: userId,
        label: space.users[userId]?.name,
        imageUrl: space.users[userId]?.imageUrl
      })), [space.users])

  function reinitValue () {
    initialValue.current = ""
    setKey(key => key + 1)
  }

  const sendMessage = useCallback(() => {
    logger.debug("sending message ", props.draftMessage)

    // if (props.draftMessage || props.draftFiles.length > 0) {
    // const _chat = DataState.spaces[props.spaceId].channels[props.channelId]

    // logger.debug("sending message ", _chat.draftMessage)
    // _chat.actions.sendMessage(_chat.draftMessage, _chat.draftFiles, _chat.draftReply)
    // _chat.actions.resetDraft()
    const result = props.sendMessage()

    if (result) {
      props.resetDraft()
      reinitValue()

      if (assetPicker) {
        setAssetPicker(undefined)
      }
    }

    Analytics.logEvent("chatSendMessage")

    // }
  }, [props.draftMessage, props.draftFiles, props.sendMessage, props.resetDraft, reinitValue, setAssetPicker])


  const focus = () => {
    editorRef.current?.focus();
  };

  useImperativeHandle(ref, () => ({
    focus
  }));


  const onPaste = (
    pasteEvent: React.ClipboardEvent<HTMLDivElement>
  ) => {
    Object.keys(pasteEvent.clipboardData.files).forEach((i) => {
      let item = pasteEvent.clipboardData.files[i];

      if (acceptedImageFiles.includes(item.type)) {
        // var blob = item.getAsFile() as File;
        console.log("got pasted image,");
        props.addFile(item)
      }
    })

    return false;
  };


  const addEmoji = (emoji) => {
    editorRef.current?.insertText(emoji)
    focus();
  };

  const addGif = (title, url, size) => {
    const newFile = new WebFile({
      name: title,
      url: url,
      size: size,
      type: "image/gif"
    })
    console.log("adding gif", title, url, size, newFile)
    props.addMessageFile(newFile)
  }

  const onFiles = (files: FileList | null) => {
    if (!files) {
      return;
    }

    Object.keys(files || {}).forEach((i) => {
      let file = files[i];

      props.addFile(file)
    });
  }

  const usersTyping = Object.keys(props.usersTyping || {})

  return (
    <DSColumn style={{ position: 'relative', marginLeft: 20, marginRight: 20, marginBottom: 18, maxWidth: "100%", borderRadius: 4}}>
      <DSRow style={{ height: 20, alignItems: 'center' }}>
        {
          usersTyping.length > 0 ?
            <DSBody style={{ paddingLeft: 8 }}>
              <DSBody style={{ fontWeight: 'bold' }}>
                {usersTyping.map((userId) => space.users[userId]?.name || "").join(", ")}
              </DSBody> {usersTyping.length > 1 ? "are" : "is"} typing ...
            </DSBody>
            :
            null
        }
      </DSRow>

      <DSColumn style={{
        flex: 1,
        borderRadius: 4,
        overflow: 'hidden',
        backgroundColor: DSTheme.PanelHeaderColor,
        border: `1px solid grey`
      }}>
        <DSColumn
          style={{
            position: 'relative',
            paddingLeft: 8,
            paddingRight: 8,
          }}>


          {assetPicker == 'emoji' ? <EmojiPicker onEmojiSelected={addEmoji} scrollHeight={200} /> : null}
          {assetPicker == 'gif' ? <GifPicker onGifSelected={addGif} /> : null}

          {
            props.draftReply ?
              <DSRow style={{
                position: 'relative',
                borderRadius: DSTheme.BaseBorderRadius,
                backgroundColor: DSTheme.ChatBodyColor,
                margin: 4,
                overflow: 'hidden'
              }}>
                <DSPanel style={{ flex: 1 }}>
                  <ChatMessage
                    spaceId={props.spaceId}
                    message={props.draftReply}
                    isReply={true}
                  />
                </DSPanel>
                <DS2CloseIconButton
                  data-tooltip="Remove reply"
                  analyticsEvent="removeReplyChatMessage"
                  onClick={() => props.setReply(undefined)}
                  style={{ padding: 6, position: 'absolute', top: 2, right: 2 }}
                />
              </DSRow>
              :
              null
          }

          <DSWrappedRow>
            {props.draftFiles.map((uf, index) => (
              <ChatFileUpload
                key={`${index}-${uf.file.name}`}
                file={uf}
                onRemove={() => props.removeFile(index)}
              />
            ))}
          </DSWrappedRow>

        </DSColumn>
        <DSRow className="compose" style={{
          color: DSTheme.MainText
        }} onPaste={onPaste} >

          <DSPanel style={{ flex: 1 }}>
            <MarkdownEditor
              key={key}
              ref={editorRef}
              initialContent={initialValue.current}
              onChange={(value) => {
                props.setMessage(value);
              }}
              onSubmit={sendMessage}
              mentionUsers={users}
              placeholder='Start typing...'
              toolbarRight={
                  <ToolbarGroup>
                    <ToolbarButton
                      value='emoji'
                      selected={assetPicker === 'emoji'}
                      onClick={(_, nv) => setAssetPicker((v) => v != nv ? nv : undefined)}
                    >
                      <FaRegSmile />
                    </ToolbarButton>

                    <ToolbarButton
                      value='gif'
                      selected={assetPicker === 'gif'}
                      onClick={(_, nv) => setAssetPicker((v) => v != nv ? nv : undefined)}
                    >
                      GIF
                    </ToolbarButton>

                    <ToolbarButton
                      value='gif'
                      onClick={() => {
                          setAssetPicker(undefined);
                          _fileInput.current?.click()
                      }}
                    >
                      <FaPaperclip />
                      <input
                        ref={_fileInput}
                        style={{ display: "none" }}
                        type="file"
                        multiple
                        onChange={(e) => onFiles(e.target.files)}
                      />
                    </ToolbarButton>

                    <SendButton
                      value=''
                      onClick={sendMessage}
                      disabled={!(props.draftMessage || props.draftFiles.length > 0)}
                      style={{}}
                    >
                      {DS2Icons.send}
                    </SendButton>
                  </ToolbarGroup>
              }
              />
          </DSPanel>
        </DSRow>
      </DSColumn>
    </DSColumn>
  )
}))


const ToolbarGroup = styled(DS2ToggleButtonGroup)(({theme}) => ({
  padding: 0,
  backgroundColor: theme.palette.secondary.dark,

  '& .MuiToggleButtonGroup-grouped': {
    '&:not(:first-of-type)': {
      borderLeft: `1px solid ${theme.palette.secondary.dark}`,
    },
  }
}))


const ToolbarButton = styled(DS2ToggleButton)(({theme}) => ({
  fontSize: '14px',
  fontFamily: 'Inter',
  padding: '4px 10px',
  backgroundColor: theme.palette.secondary.main,
  border: `1px solid ${theme.palette.secondary.dark}`,

  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }
}));

const SendButton = styled(ToolbarButton)(({theme}) => ({
  padding: '4px 20px',
  '&:not(.Mui-disabled)': {
    color: theme.palette.primary.main
  }
}))

import { Logger } from "@openteam/app-util"
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { PopupOptions, SubWindow, SubWindowCSS } from "../SubWindow";
import { InviteUsers } from "./InviteUsers";
import { showInviteUsers, UIState } from "../../Data/UIState";
import { OTUITree, UIDataState } from "@openteam/app-core";
import { isMacOs } from "react-device-detect";

const logger = new Logger("InviteUsersWindow")

type Props = {
}

const InviteUsersWrapper: React.FC<Props> = (props) => {
  const [position, setPosition] = React.useState<{ x: number, y: number }>()
  const spaceId = OTUITree.userManager.currentTeamId
  const windowId = `invite-users-${spaceId}`

  let width = 328
  let height = 320


  const onClose = useCallback(() => showInviteUsers(false), [])

  return (spaceId && UIState.inviteUsersShown) ? (
    <SubWindow
      id={windowId}
      initialPosition={position}
      show={true}
      initialSize={{ width: width, height: height }}
      title={'Invite teammates - Ehlo'}
      minHeight={height}
      minWidth={width}
      onClose={onClose}
      focus={true}
      options={
        {
          alwaysOnTop: false,
          hasShadow: true,
          frame: false,
          transparent: isMacOs ? true : false,
          titleBarStyle: isMacOs ? "hidden" : "default",
          trafficLightPosition: {
            x: 10,
            y: 16
          },
          autoHideMenuBar: true,
          useContentSize: true,
          resizable: true,
          skipTaskbar: false,
          maximizable: true,
          focusable: true,
          fullscreenable: false,
          backgroundColor: undefined,
          webPreferences: {
            backgroundThrottling: false
          }
        }
      }
    >
      <style type="text/css">{SubWindowCSS}</style>
      <InviteUsers windowId={windowId} spaceId={spaceId} onClose={onClose} topLevel/>
    </SubWindow>
  ) : null
}

export default observer(InviteUsersWrapper);

import React, {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Logger } from '@openteam/app-util';
import { SubWindow, SubWindowCSS, SubWindowHandle, ToolTipOptions } from '../Components/SubWindow';
import useResizeObserver from 'use-resize-observer';
import { DSBody, DSH4, DSPrint } from '../DesignSystem/DSText';
import { DSTheme } from '../DesignSystem/DSTheme';
import { animated, useTransition } from '@react-spring/web';
import { v1 as uuidv1 } from 'uuid';
import setAlpha from '../Util/setAlpha';
import { makeAutoObservable } from 'mobx';
import calcWindowPosition, { AlignType } from './calcWindowPosition';

const logger = new Logger('ToolTips');

type Bounds = { x: number; y: number, width: number, height: number };

type ToolTipContainerProps = {
  parentId: string;
  show?: boolean;
  showWhenUnfocused?: boolean;
  delay?: number;
  offset?: number;
};

export const ToolTipContainer: React.FC<ToolTipContainerProps> = memo((props) => {
  const { showWhenUnfocused = false, delay = 350, offset = 4 } = props
  const observer = useRef<MutationObserver>();
  const [tooltips, setTooltips] = useState(new Map());
  const ref = useRef<HTMLDivElement>(null);
  const [parentPosition, setParentPosition] = useState<Bounds>();
  const [workArea, setWorkArea] = useState<Electron.Rectangle>();
  const [hide, setHide] = useState(!showWhenUnfocused)
  const showTimer = useRef<ReturnType<typeof setTimeout>>()
  const cursorPos = useRef<{ x: number, y: number }>()
  const mounted = useRef(false)


  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      logger.info(`Unmounted tooltips for ${props.parentId}`)
    }
  }, [props.parentId])

  const clearShowTimer = useCallback(() => {
    if (showTimer.current) {
      clearTimeout(showTimer.current)
      showTimer.current = undefined;
    }
  }, [])


  const setUpHandler = useCallback((element: HTMLElement) => {
    element.addEventListener('mouseenter', (e) => {
      const ttDelay = parseAttrIntVal(element, 'data-tooltip-delay', delay);

      clearShowTimer();
      cursorPos.current = { x: e.clientX, y: e.clientY };
      if (ttDelay) {
        showTimer.current = setTimeout(() => showToolTip(element), ttDelay);
      } else {
        showToolTip(element);
      }
    });

    element.addEventListener('mouseleave', (e) => {
      clearShowTimer();
      hideToolTip(element)
    });

    element.addEventListener('mousedown', (e) => {
      clearShowTimer();
      hideToolTip(element)
    });

  }, [])

  const nodeAdded = useCallback((node: HTMLElement) => {
    if (node.getAttribute?.('data-tooltip')) {
      setUpHandler(node)
    }

    node.querySelectorAll?.(`[data-tooltip]`).forEach((match) => {
      //logger.debug(`in ${props.parentId} found-tooltip: ${match.getAttribute('data-tooltip')} `);
      setUpHandler(match as HTMLElement)
    });
  }, [setUpHandler]);

  const nodeRemoved = useCallback((element: HTMLElement) => {
    //logger.debug(`Node removed`)
    if (element.getAttribute?.('data-tooltip')) {
      hideToolTip(element);
    }

    element.querySelectorAll?.(`[data-tooltip]`).forEach((match) => {
      hideToolTip(match as HTMLElement);
    });
  }, [])

  const onMutations: MutationCallback = useCallback((mutations) => {
    for (const mutation of mutations) {
      if (mutation.type === 'childList') {
        //logger.debug(`Scanning ${mutation.addedNodes.length} added nodes`, mutation);
        for (const added of mutation.addedNodes) {
          //logger.debug(`Node added`, added);
          nodeAdded(added as HTMLElement);
        }

        for (const removed of mutation.removedNodes) {
          //logger.debug(`Node removed`, removed);
          nodeRemoved(removed as HTMLElement)
        }

      } else if (mutation.type === 'attributes' && mutation.attributeName === 'data-tooltip') {
        //logger.debug(
        //  `tooltip updated from ${mutation.oldValue} -> ${(mutation.target as HTMLElement).getAttribute('data-tooltip')}`
        //);
        if (!mutation.oldValue) {
          nodeAdded(mutation.target as HTMLElement);
        } else if (!(mutation.target as HTMLElement).getAttribute('data-tooltip')) {
          hideToolTip(mutation.target as HTMLElement);
        } else {
          setTooltips((_t) => {
            if (_t.has(mutation.target)) {
              return new Map(_t);
            }
            return _t
          })
        }
      } else if (mutation.type === 'attributes' && mutation.attributeName === 'data-tooltip-show') {
        //logger.debug(
        //  `tooltip updated from ${mutation.oldValue} -> ${(mutation.target as HTMLElement).getAttribute('data-tooltip')}`
        //);
        const attrVal = (mutation.target as HTMLElement).getAttribute('data-tooltip-show')
        if (attrVal) {
          showToolTip(mutation.target as HTMLElement);
        } else {
          hideToolTip(mutation.target as HTMLElement);
        }
      }
    }
  }, [setTooltips, nodeAdded, nodeRemoved]);

  useEffect(() => {
    observer.current = new MutationObserver(onMutations);
    if (ref.current) {
      observer.current.observe(ref.current.ownerDocument.body, {
        childList: true,
        attributes: true,
        attributeOldValue: true,
        subtree: true
      });
      nodeAdded(ref.current.ownerDocument.body);
    }
    return () => observer.current?.disconnect();
  }, [onMutations]);

  const transitions = useTransition(Array.from(tooltips.entries()), {
    keys: (item) => item[1],
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 5 },
  });

  const clearToolTips = useCallback((_hide = false) => {
    clearShowTimer();
    setTooltips((_t) => {
      if (_hide === true) {
        //logger.debug(`${props.parentId} Setting hide to true`)
        setHide(true);
      }

      if (_t.size) {
        return new Map()
      } else {
        return _t;
      }
    });
  }, [clearShowTimer]);

  useEffect(() => {
    if (!props.show) {
      clearToolTips();
    }
  }, [props.show, clearToolTips])

  const getWindowPosition = useCallback(
    async () => {
      const pos = await window.Main.getWindowBounds(props.parentId);
      const wa = await window.Main.getWindowWorkArea(props.parentId)
      if (mounted.current) {
        setParentPosition(pos);
        setWorkArea(wa);
      }
    },
    []
  );

  const showToolTip = React.useCallback(
    (e: HTMLElement) => {
      const text = e.getAttribute('data-tooltip');
      if (text) {
        //await getWindowPosition();
        setTooltips((t) => {
          if (!t.has(e)) {
            logger.debug(`ShowTooltip '${text}'`);
            return new Map().set(e, { id: `tooltip-${uuidv1()}`, cursorPos: cursorPos.current });
          }
          return t;
        });
      }
    },
    [setTooltips, setHide, getWindowPosition]
  );

  const hideToolTip = React.useCallback(
    (e: HTMLElement) => {
      const text = e.getAttribute('data-tooltip');
      setTooltips((t) => {
        if (t.delete(e)) {
          logger.debug(`HideTooltip '${text}'`);
          return new Map(t);
        }
        return t;
      });
    },
    [setTooltips]
  );

  useEffect(() => {
    getWindowPosition();
    const unsubscribe: (() => void)[] = []

    const handleMove = async ({bounds}) => {
      clearToolTips();
      setParentPosition(bounds)
      const workArea = await window.Main.getWindowWorkArea(props.parentId)
      mounted.current && setWorkArea(workArea)
    }

    if (props.parentId === '__dock__') {
      unsubscribe.push(window.Main.on(`dock-moved`, handleMove))
      unsubscribe.push(window.Main.on(`resized`, handleMove))
    } else {
      unsubscribe.push(window.Main.on(`window-moved-${props.parentId}`, handleMove))
      unsubscribe.push(window.Main.on(`window-resized-${props.parentId}`, handleMove))

      unsubscribe.push(window.Main.on(`window-blurred-${props.parentId}`, () => {
        //logger.debug(`${props.parentId} blurred, setting hide to ${!showWhenUnfocused}`);
        clearToolTips(!showWhenUnfocused)
      }));
      unsubscribe.push(window.Main.on(`window-hide-${props.parentId}`, () => clearToolTips(true)));
      unsubscribe.push(window.Main.on(`window-show-${props.parentId}`, () => {
        showWhenUnfocused && setHide(false)
        getWindowPosition();
      }));
      unsubscribe.push(window.Main.on(`window-focused-${props.parentId}`, () => {
        //logger.debug(`${props.parentId} focused, disabling hide`)
        setHide(false)
      }));
    }
    return () => {
      unsubscribe.forEach(f => f());
    };
  }, [props.parentId, showWhenUnfocused]);

  //useEffect(() => {
  //  logger.debug(`${props.parentId} hide ${hide}`)
  //}, [hide])

  return (
    <>
      <div ref={ref} />
      {transitions((style, value, t) => {
        const [triggerEl, data] = value;
        //logger.debug(`${props.parentId} Rendering tooltip '${triggerEl.getAttribute('data-tooltip')}' hide ${hide} cursor`, cursorPos.current);
        return (
          <ToolTip
            id={data.id}
            windowId={props.parentId}
            windowPosition={parentPosition}
            workArea={workArea}
            triggerEl={triggerEl}
            cursorPos={cursorPos.current!}
            style={style}
            offset={offset}
            hide={hide || props.show === false || triggerEl.offsetParent === null}
          />
        );
      })}
    </>
  );
});

type ToolTipProps = {
  id: string;
  windowId: string;
  offset: number;
  windowPosition?: Bounds;
  workArea?: Electron.Rectangle;
  triggerEl: HTMLElement;
  cursorPos: { x: number, y: number };
  style?: any;
  hide?: boolean
};

type toolTipPosition = 'top' | 'left' | 'bottom' | 'right'

const DFLT_WA = {
  x: 0,
  y: 0,
  width: 1024,
  height: 768
}

const ToolTip: React.FC<ToolTipProps> = memo((props) => {

  const { id, windowId, windowPosition, workArea = DFLT_WA, style: _style, triggerEl, cursorPos, hide = false } = props;

  const subWindowRef = useRef<SubWindowHandle>(null);
  const { ref, width = 10, height = 10 } = useResizeObserver<HTMLDivElement>({ box: 'border-box' });
  const [bounds, setBounds] = useState<Bounds | null>(null);
  const [position, setPosition] = useState<toolTipPosition>((triggerEl.getAttribute('data-tooltip-position') ?? 'top') as toolTipPosition)
  const [show, setShow] = useState(false);
  const [resized, setResized] = useState(false);
  const title = triggerEl.getAttribute('data-tooltip');
  const subtitle = triggerEl.getAttribute('data-tooltip-subtitle');
  const ttFor = triggerEl.getAttribute('data-tooltip-for');

  const offset = parseAttrIntVal(triggerEl, 'data-tooltip-offset', props.offset);

  const calcPosition = useCallback(
    (width, height, _show = false) => {

      if (width && height && windowPosition) {
        const result = calcWindowPosition({
          windowSize: { width, height },
          parentBounds: windowPosition,
          triggerPosition: triggerEl.getBoundingClientRect(),
          workArea,
          position,
          offset,
          align: triggerEl.getAttribute('data-tooltip-align') as AlignType,
          cursorPos,
        })

        //logger.debug(`CalcPosition for ${id} returned`, result)

        if (result) {
          setBounds(result.bounds);
          setPosition(result.position);
          _show && setShow(true)
        }
      } else {
        //logger.debug(`can't CalcPosition for ${id}, as width, height, windowPosition`, width, height, windowPosition)
      }
    },
    [position, windowPosition, workArea, triggerEl]
  );

  useEffect(() => {
    //logger.debug(`resize: width: ${width}, height: ${height}`);
    calcPosition(width, height, true);
  }, [width, height, calcPosition]);


  useEffect(() => {
    logger.debug(`Creating tooltip for ${title} with ${ttFor} at`, bounds)
  }, [bounds])

  return (
    (bounds && title) ? (
      <SubWindow
        ref={subWindowRef}
        id={id}
        parentId={windowId}
        {...bounds}
        initialPosition={bounds}
        show={show && !hide}
        focus={false}
        onCreated={() => calcPosition(width, height)}
        ignoreMouseEvents={true}
        onResize={({ bounds: newBounds }) => {
          if (newBounds.height > 10 || newBounds.width > 10) {
            setResized(true)
          }
          //logger.debug(`ToolTip resized to `, newBounds)
        }}
        options={{ ...ToolTipOptions }}
      >
        <style type="text/css">{SubWindowCSS}</style>
        <animated.div ref={ref} style={{ ...Styles.container, ...Styles[`container-${position}`], ..._style, visibility: resized ? "visible" : "hidden" }} >
          <animated.div style={{ ...Styles.toolTip }}>
            {(ttFor && _TOOLTIPS[ttFor]) ? (
              _TOOLTIPS[ttFor](title)
            ) : (
              <>
                <DSH4>{title}</DSH4>
                {subtitle && <DSPrint>{subtitle}</DSPrint>}
              </>
            )}
          </animated.div>
          <animated.div style={Styles[`arrow-${position}`]} />
        </animated.div>
      </SubWindow>
    ) : null
  );
});

function parseAttrIntVal (element: HTMLElement, attr: string, dflt: number) {
  const attrVal = element.getAttribute(attr);
  if (attrVal) {
    try {
      return parseInt(attrVal)
    } catch { }
  }
  return dflt
}
class StyleClass {
  constructor() {
    makeAutoObservable(this);
  }
  container: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    overflow: 'visible',
    width: 'fit-content',
    height: 'fit-content'
  }
  'container-top': CSSProperties = {
    flexDirection: "column"
  }

  'container-bottom': CSSProperties = {
    flexDirection: "column-reverse"
  }
  'container-left': CSSProperties = {
    flexDirection: "row"
  }
  'container-right': CSSProperties = {
    flexDirection: "row-reverse"
  }
  get toolTip (): CSSProperties {
    return {
      display: 'flex',
      flexDirection: "column",
      //position: 'absolute',
      overflow: 'visible',
      backgroundColor: DSTheme.ToolTipBackgroundColor,
      color: DSTheme.ToolTipTextColor,
      //transition: 'opacity .3s ease-in-out, visibility .3s ease-in-out',
      borderRadius: 8,
      padding: '10px 10px',
      boxShadow: `0 0 5px ${setAlpha(DSTheme.ToolTipBackgroundColor, 0.8)}`,
      margin: 5,
      zIndex: 50,
      opacity: 1,
      pointerEvents: 'none'
    }
  }
  get 'arrow-top' (): CSSProperties {
    return {
      width: 0,
      height: 0,
      marginTop: -5,
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderTop: `6px solid ${DSTheme.ToolTipBackgroundColor}`,
    }
  }
  get 'arrow-bottom' (): CSSProperties {
    return {
      width: 0,
      height: 0,
      marginBottom: -5,
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderBottom: `6px solid ${DSTheme.ToolTipBackgroundColor}`
    }
  }
  get 'arrow-right' (): CSSProperties {
    return {
      width: 0,
      height: 0,
      marginRight: -5,
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderRight: `5px solid ${DSTheme.ToolTipBackgroundColor}`
    }
  }
  get 'arrow-left' (): CSSProperties {
    return {
      width: 0,
      height: 0,
      marginLeft: -5,
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderLeft: `5px solid ${DSTheme.ToolTipBackgroundColor}`
    }
  }
};
const Styles = new StyleClass();

const _TOOLTIPS = {}

type ToolTipComponentProps = {
  id: string
  getContent?: (string) => any
}

export const ToolTipComponent: React.FC<ToolTipComponentProps> = ({ id, getContent, children }) => {
  _TOOLTIPS[id] = (param) => {
    if (getContent) {
      try {
        return getContent(param)
      } catch (err) {
        logger.error('Error get tooltip content', err)
      }
    } else {
      return children
    }
  }
  return null
}

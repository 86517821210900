import { observer } from "mobx-react";
import React from "react";
import { UIState } from "../../Data/UIState";
import { DSTheme } from "../../DesignSystem/DSTheme"


const DockChatOpenIndicator: React.FC<{
  tileSize: number,
  show: boolean,
  atTop?: boolean
}> = observer(({ tileSize, show, atTop }) => {
  const offset = 0

  const dotSize = Math.ceil(UIState.dockSize / 20)
  const atTopOffset = (tileSize - dotSize) / 2 + Math.round(UIState.dockSize * DSTheme.DockMargin)

  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        alignItems: atTop ? undefined : "center",

        top: UIState.dockHorizontal ? UIState.dockPosition.onTop ? offset : undefined : atTop ? atTopOffset : undefined,
        bottom: UIState.dockHorizontal ? UIState.dockPosition.onTop ? undefined : offset : undefined,
        left: UIState.dockHorizontal ? atTop ? atTopOffset : undefined : UIState.dockPosition.onLeft ? offset : undefined,
        right: UIState.dockHorizontal ? undefined : UIState.dockPosition.onLeft ? undefined : offset,
      }}
    >
      <div
        style={{
          width: dotSize,
          height: dotSize,
          backgroundColor: DSTheme.DockIndicatorColor,
          borderRadius: dotSize / 2,
          opacity: show ? 1 : 0
        }}
      />
    </div>
  );
});

export default DockChatOpenIndicator;
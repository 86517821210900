import { Logger } from "@openteam/app-util";
import { ICallRequest, TCallRequestStatus, TRoomMode } from "@openteam/models";

import {
  collection, deleteDoc, doc, Firestore, getDoc, getDocs, onSnapshot, query, serverTimestamp, setDoc, updateDoc, where
} from "firebase/firestore";

const logger = new Logger("CallRequestDb");


export class CallRequestDb2 {
  static _callWatches:(() => void)[] = [];



  static watchIncomingCallRequests = (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    callback: (added: ICallRequest[], edited: ICallRequest[], deleted: string[]) => void
  ) => {
    const qry = query(
      collection(fsDb, `callRequests/${teamId}/requests`),
      where("receiverUserId", "==", userId),
      where("active", "==", true)
    );

    const unsubscribe = onSnapshot(
      qry,
      (snapshot) => {
        const added: ICallRequest[] = [];
        const edited: ICallRequest[] = [];
        const deleted: string[] = [];

        // logger.debug("watchChannels", snapshot, snapshot?.docChanges());

        snapshot?.docChanges().forEach((change) => {
          if (change.type === "added") {
            var doc = change.doc.data() as ICallRequest;

            added.push(doc);
          }
          if (change.type === "modified") {
            var doc = change.doc.data() as ICallRequest;
            edited.push(doc);
          }
          if (change.type === "removed") {
            deleted.push(change.doc.id);
          }
        });
       
        callback(added, edited, deleted);
      },
      (error) => logger.error("watchIncomingCallRequests", error)
    );

    return unsubscribe;
  };

  static watchOutgoingCallRequests = (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    callback: (added: ICallRequest[], edited: ICallRequest[], deleted: string[]) => void
  ) => {
    const qry = query(
      collection(fsDb, `callRequests/${teamId}/requests`),
      where("senderUserId", "==", userId),
      where("active", "==", true)
    );

    const unsubscribe = onSnapshot(
      qry,
      (snapshot) => {
        const added: ICallRequest[] = [];
        const edited: ICallRequest[] = [];
        const deleted: string[] = [];

        // logger.debug("watchChannels", snapshot, snapshot?.docChanges());

        snapshot?.docChanges().forEach((change) => {
          if (change.type === "added") {
            var doc = change.doc.data() as ICallRequest;

            added.push(doc);
          }
          if (change.type === "modified") {
            var doc = change.doc.data() as ICallRequest;
            edited.push(doc);
          }
          if (change.type === "removed") {
            deleted.push(change.doc.id);
          }
        });
       
        callback(added, edited, deleted);
      },
      (error) => logger.error("watchOutgoingCallRequests", error)
    );

    return unsubscribe;
  };


  static startRequest = async (
    fsDb: Firestore,
    teamId: string,
    myUserId: string,
    userId: string,
    roomId?: string,
    roomMode?: TRoomMode
  ) => {

    const qry = query(
      collection(fsDb, `callRequests/${teamId}/requests`),
      where("senderUserId", "==", myUserId),
      where("receiverUserId", "==", userId),
      where("active", "==", true)
    );

    const existingCalls = await getDocs(qry)

    existingCalls.forEach((doc) => {
      deleteDoc(doc.ref)
    })

    const docRef = doc(collection(fsDb, `callRequests/${teamId}/requests`));
    const requestId = docRef.id;


    const msg: ICallRequest = {
      id: requestId,
      teamId: teamId,
      crDate: serverTimestamp() as any,
      senderUserId: myUserId,
      receiverUserId: userId,
      senderActive: true,
      receiverActive: true,
      active: true,
      roomMode: roomMode || 'video',
      roomId: roomId || null,
      // callKey: callKey || null,
      // callName: callName || null,
      status: "requesting",
      userIds: [myUserId, userId]
    }

    await setDoc(docRef, msg)

    return requestId
  }

  static setRequestMsg = async (
    fsDb: Firestore,
    teamId: string,
    id: string,
    msg: string,
  ) => {
    logger.debug("setRequestMsg", msg)

    const docRef = doc(fsDb, `callRequests/${teamId}/requests/${id}`);

    await updateDoc(docRef, {
      msg: msg
    });
    logger.debug("setRequestMsg done", msg)
  }

  static whenFreeRequest = async (
    fsDb: Firestore,
    teamId: string,
    id: string
  ) => {
    const docRef = doc(fsDb, `callRequests/${teamId}/requests/${id}`);

    await updateDoc(docRef, {
      status: "sendwhenfree",
      senderActive: false,
    });
  }

  static cancelRequest = async (
    fsDb: Firestore,
    teamId: string,
    id: string
  ) => {
    logger.info("cancelling call request", teamId, id)
    const docRef = doc(fsDb, `callRequests/${teamId}/requests/${id}`);

    await updateDoc(docRef, {
      status: "sendcancelled",
      senderActive: false,
    });
  }

  static clearRequest = async (
    fsDb: Firestore,
    teamId: string,
    id: string
  ) => {
    logger.info("clearRequest call request", teamId, id)
    const docRef = doc(fsDb, `callRequests/${teamId}/requests/${id}`);


    const snapshot = await getDoc(docRef);

    if (snapshot) {
      const callRequest = snapshot.data() as ICallRequest

      if (callRequest.status == 'recvlater') {
        // await updateDoc(docRef, {
        //   senderActive: false,
        //   active: false
        // });

        deleteDoc(docRef)
      }
    }
  }

  static respondToRequest = async (
    fsDb: Firestore,
    teamId: string,
    id: string,
    status: TCallRequestStatus
  ) => {
    const nonTerminal = ["recvaccepted", "recvholdon"];
    const docRef = doc(fsDb, `callRequests/${teamId}/requests/${id}`);

    if (!(nonTerminal.includes(status))) {
      await updateDoc(docRef, {
        status: status,
        receiverActive: false,
      });

    } else {
      await updateDoc(docRef, {
        status: status,
    });
  }
  }


  static dismissCanceledRequest = async (
    fsDb: Firestore,
    teamId: string,
    id: string
  ) => {
    logger.info("cancelling call request", teamId, id)
    const docRef = doc(fsDb, `callRequests/${teamId}/requests/${id}`);

    // await updateDoc(docRef, {
    //   receiverActive: false,
    //   active:false
    // });
    await deleteDoc(docRef)

  }

  static rerequestCall = async (
    fsDb: Firestore,
    teamId: string,
    myUserId: string,
    id: string
  ) => {
    logger.info("cancelling call request", teamId, id)
    const docRef = doc(fsDb, `callRequests/${teamId}/requests/${id}`);


    const snapshot = await getDoc(docRef);

    if (snapshot) {
      const callRequest = snapshot.data() as ICallRequest

      const newRequestId = await this.startRequest(
        fsDb,
        teamId,
        myUserId,
        callRequest.senderUserId == myUserId ? callRequest.receiverUserId : callRequest.senderUserId
      )

      if (callRequest.msg) {
        await this.setRequestMsg(
          fsDb,
          teamId,
          newRequestId,
          callRequest.msg
        )

      }

      deleteDoc(docRef);
    }
  }



  static callStarted = async (fsDb: Firestore, teamId: string, requestId: string, roomId: string) => {
    const docRef = doc(fsDb, `callRequests/${teamId}/requests/${requestId}`);

    await updateDoc(docRef, {
      status: "callstarted",
      senderActive: false,
      roomId: roomId,
    });
  };


  static callAccepted = async (fsDb: Firestore, teamId: string, requestId: string) => {
    const docRef = doc(fsDb, `callRequests/${teamId}/requests/${requestId}`);

    // await updateDoc(docRef, {
    //   receiverActive: false,
    //   active: false,
    // });

    deleteDoc(docRef)

  };

}

import { Logger } from '@openteam/app-util';
import { KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { DataState } from '../../Data/DataState';
import { DSModalPanel } from '../Modal/ModalLayout';
import * as Analytics from '../../Util/Analytics'
import { DSTab, DSTabs } from '../../DesignSystem/DSTabs';
import { DSColumn, DSPanel, DSPanelScrollable, DSRow, DSHSpacer } from '../../DesignSystem';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DSH1, DSH2, DSH3, DSH4 } from '../../DesignSystem/DSText';
import { SpaceIcon } from '../Space/SpaceIcon';
import { DSButton } from '../../DesignSystem/DSButton';
import { FaChevronDown, FaChevronUp, FaMinus, FaSignOutAlt, FaTimes } from 'react-icons/fa';
import { useHoverIntent } from '../../Util/HoverIntent';
import { animated, useSpring } from '@react-spring/web';
import useResizeObserver from 'use-resize-observer';

import { DockSettings } from './DockSettings';
import { GoogleCalendarSettings } from './GoogleCalendarSettings';
import AudioSettings from './AudioSettings';
import VideoSettings from './VideoSettings';
import { WorkspaceNotificationSettings } from './WorkspaceNotificationSettings';
import { WorkspaceUserProfileSettings } from './WorkspaceUserProfileSettings';
import { WorkspaceSettings } from './WorkspaceSettings';
import { WorkspaceTeamsSettings } from './WorkspaceTeamsSettings';
import { WorkspaceUsersSettings } from './WorkspaceUsersSettings';
import { Draggable } from '../Draggable';
import { isMacOs } from "react-device-detect";
import { signOut } from '../../Controllers/Login';
import { OTGlobals, OTUITree } from '@openteam/app-core';
import { SettingsTabState, UIState } from '../../Data/UIState';
import { action } from 'mobx';
import { lighten } from '@mui/material';
import { GoogleCalendarIcon } from '../Calendar/GoogleCalendarLogo';
import { ZoomIcon } from '../Zoom';
import { ZoomSettings } from './ZoomSettings';
import { DS2BaseButton, DS2Button, DS2Icons } from '../../DesignSystem';
import FeedbackTab from './FeedbackTab';

const logger = new Logger("GlobalSettings")

export const GlobalSettings: React.FC<{
  windowId: string,
  onClose: () => void,
}> = observer((props) => {

  const currentTeamId = OTUITree.userManager.currentTeamId!

  const space = DataState.spaces[currentTeamId];
  const myUserId = OTUITree.auth.userId;
  const user = space.users?.[myUserId];

  // const [tab, setTab] = useState<{ domain: string, tabId: string, spaceId?: string }>({ domain: "global", tabId: "dock" });
  const [expandedSpaceId, setExpandedSpaceId] = useState<string | undefined>(currentTeamId);
  const [scrollVersion, setScrollVersion] = useState<number>(0);
  const userManager = OTGlobals.auth.userManager;

  const setTab = useCallback(action((state: SettingsTabState) => {
    UIState.settingsTab = state
  }), [])


  useEffect(() => {

    if (!expandedSpaceId || !userManager.userTeams[expandedSpaceId]) {

      setExpandedSpaceId(currentTeamId)
    }


  }, [currentTeamId])

  useEffect(() => {

    const tabSpaceId = UIState.settingsTab?.spaceId
    if (tabSpaceId && !userManager.userTeamsIndex[tabSpaceId]) {
      setTab( { domain: 'global', tabId: 'dock' })
    }

  }, [userManager.userTeamsIndex])

  const calcExpandedSpaceId = expandedSpaceId && userManager.userTeams[expandedSpaceId] ? expandedSpaceId : currentTeamId

  logger.debug(`currentTeamId ${currentTeamId} expandedSpaceId ${expandedSpaceId} calcExpandedSpaceId ${calcExpandedSpaceId}`)


  const tab = UIState.settingsTab;

  useEffect(() => {
    Analytics.logEvent("Screen_DockSettings")
  }, [])

  return (
    <DSColumn style={{
      flexGrow: 1,
      height: "100%",
      borderRadius: "10px",
      color: DSTheme.PanelContrastColor
    }}>
      <Draggable windowId={props.windowId}>
        <DSRow style={{
          flexGrow: 0,
          height: 50,
          backgroundColor: DSTheme.SpacePanelRoomBackground,
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${lighten(DSTheme.BackgroundColor, 0.4)}`
        }}>
          <DSColumn style={{ width: 240 }}>
            <DSRow>
              <DSHSpacer size={24} />
              <DSH1 style={{ fontSize: 18, fontWeight: "bold" }}>Settings</DSH1>
            </DSRow>
          </DSColumn>

          <DSRow style={{ flexGrow: 1, height: 50, backgroundColor: DSTheme.BackgroundColor, }}>
            <DSH2 style={{
              flex: 1,
              fontSize: 18,
              paddingLeft: 30,
              lineHeight: "50px",
              height: 50,
              textTransform: "capitalize",
              verticalAlign: "middle",
            }}>{tab.tabId}</DSH2>
            <DSRow style={{ marginBottom: 3, marginRight: 12 }}>

              <DSButton
                style={{ padding: 6 }}
                onClick={() => {
                  window.Main.minimiseWindow(props.windowId)
                }} >

                <FaMinus size={14} style={{ color: DSTheme.ButtonIconColor }} />
              </DSButton>

              <DSButton
                style={{ padding: 6 }}
                onClick={() => {
                  window.Main.closeWindow(props.windowId)
                }} >

                <FaTimes size={14} style={{ color: DSTheme.ButtonIconColor }} />
              </DSButton>
            </DSRow>
          </DSRow>

        </DSRow >
      </Draggable >

      <DSRow style={{ height: "calc(100vh - 50px)" }}>
        <DSColumn style={{ width: 240, flexShrink: 0, backgroundColor: DSTheme.SpacePanelRoomBackground }}>

          <DSPanelScrollable style={{ flex: "1 1 auto" }}>

            <DSTabs
              activeTabId={tab.domain === "global" ? tab.tabId : "none"}
              onChange={(tabId: string) => setTab({ domain: "global", tabId })}
              style={{
                flexDirection: "column",
                alignItems: "stretch",
              }}
              activeButtonStyle={{
                backgroundColor: DSTheme.EmphasisColor
              }}
              hoverButtonStyle={{
                backgroundColor: lighten(DSTheme.SpacePanelRoomBackground, 0.1)
              }}
              tabStyle={Styles.tab2}
              buttonStyle={{
                width: "none",
                justifyContent: "left",
              }}
            >
              <DSTab tabId="dock">
                <DSH3 style={Styles.header}>Dock</DSH3>
              </DSTab>
              <DSTab tabId="audio">
                <DSH3 style={Styles.header}>Audio</DSH3>
              </DSTab>
              <DSTab tabId="video">
                <DSH3 style={Styles.header}>Video</DSH3>
              </DSTab>
              <DSTab tabId="google calendar">
                <DSRow spacing={8} style={Styles.header} >
                  <GoogleCalendarIcon size={24} />
                  <DSH3>Google Calendar</DSH3>
                </DSRow>
              </DSTab>
              <DSTab tabId="zoom">
                <DSRow spacing={4} style={Styles.header} >
                  <ZoomIcon size={24} />
                  <DSH3>Zoom Integration</DSH3>
                </DSRow>
              </DSTab>
            </DSTabs>

            <hr style={Styles.separator} />

            {currentTeamId &&
              <WorkspaceTabs
                key={`settings-${currentTeamId}`}
                spaceId={currentTeamId}
                activeTab={tab}
                isExpanded={currentTeamId === calcExpandedSpaceId}
                setExpandedSpaceId={setExpandedSpaceId}
                setTab={setTab} />
            }
            {Object.keys(DataState.spaces)
              .filter(x => x !== currentTeamId)
              .map((spaceId) => (
                <WorkspaceTabs
                  key={`settings-${spaceId}`}
                  spaceId={spaceId}
                  activeTab={tab}
                  isExpanded={spaceId === calcExpandedSpaceId}
                  setExpandedSpaceId={setExpandedSpaceId}
                  setTab={setTab} />
              ))
            }

          </DSPanelScrollable>

          <DSColumn
            style={{
              justifyContent: "flex-end",
              padding: "12px 12px 12px 12px",
              borderTop: `1px solid ${lighten(DSTheme.BackgroundColor, 0.4)}`,
            }} spacing={8}>

            <DS2Button
              variant='text'
              color='secondary'
              startIcon={DS2Icons.feedback}
              noBadge
              style={{width: 'fit-content'}}
              onClick={() => setTab({ domain: "global", tabId: "feedback and upgrade" })}
              >
              <DSH3>Feedback and Upgrade</DSH3>
            </DS2Button>
            <DSH3
              data-tooltip={user?.email}
              maxLines={1}
              style={{ color: DSTheme.PanelContrastColor }}
            >
              {user?.email}
            </DSH3>

            <DS2BaseButton
              startIcon={<FaSignOutAlt />}
              size='medium'
              color="error"
              analyticsEvent="signOut"
              onClick={() => signOut()}
            >
              Sign Out
            </DS2BaseButton>
          </DSColumn>
        </DSColumn>

        <DSColumn style={{ flexGrow: 1, backgroundColor: DSTheme.BackgroundColor, color: DSTheme.PanelContrastColor }}>
          <DSPanelScrollable onScroll={() => { logger.debug("scroll"); setScrollVersion(scrollVersion + 1) }}>
            <div style={{ padding: "5 30" }}>
              {tab.domain === "global" && tab.tabId === "dock" && <DockSettings />}
              {tab.domain === "global" && tab.tabId === "google calendar" && <GoogleCalendarSettings scrollVersion={scrollVersion} parentWindowId={props.windowId} spaceId={calcExpandedSpaceId!} />}
              {tab.domain === "global" && tab.tabId === "zoom" && <ZoomSettings scrollVersion={scrollVersion} parentWindowId={props.windowId} spaceId={calcExpandedSpaceId!} />}
              {tab.domain === "global" && tab.tabId === "audio" && <AudioSettings spaceId={currentTeamId} />}
              {tab.domain === "global" && tab.tabId === "video" && <VideoSettings spaceId={currentTeamId} />}
              {tab.domain === "global" && tab.tabId === "feedback and upgrade" && <FeedbackTab spaceId={calcExpandedSpaceId!} />}
              {tab.domain === "space" && tab.tabId === "profile" && <WorkspaceUserProfileSettings parentWindowId={props.windowId} spaceId={calcExpandedSpaceId!} />}
              {tab.domain === "space" && tab.tabId === "notification" && <WorkspaceNotificationSettings spaceId={calcExpandedSpaceId!} />}
              {tab.domain === "space" && tab.tabId === "workspace" && <WorkspaceSettings spaceId={calcExpandedSpaceId!} />}
              {tab.domain === "space" && tab.tabId === "teams" && <WorkspaceTeamsSettings parentWindowId={props.windowId} spaceId={calcExpandedSpaceId!} />}
              {tab.domain === "space" && tab.tabId === "users" && <WorkspaceUsersSettings scrollVersion={scrollVersion} parentWindowId={props.windowId} spaceId={calcExpandedSpaceId!} />}
            </div>
          </DSPanelScrollable>
        </DSColumn>
      </DSRow >
    </DSColumn >
  )
})


const WorkspaceTabs: React.FC<{
  spaceId: KSpaceId,
  isExpanded: boolean,
  setExpandedSpaceId: (spaceId: KSpaceId | undefined) => void,
  setTab: (tab: { domain: string, tabId: string, spaceId?: string }) => void,
  activeTab: { domain: string, tabId: string, spaceId?: string },
}> = observer(({ spaceId, isExpanded, setExpandedSpaceId, activeTab, setTab }) => {

  const space = DataState.spaces[spaceId];

  const [isHovering, hoverRef] = useHoverIntent<HTMLDivElement>({
    timeout: 0,
    minVelocity: 1000,
  });

  const { ref, height = 0 } = useResizeObserver<HTMLDivElement>();

  const springStyle = useSpring({
    height: isExpanded ? height : 0,
  })

  if (!space) {
    return null
  }

  return (
    <DSButton style={{
      width: "100%",
      color: DSTheme.PanelContrastColor
    }} onClick={() => setExpandedSpaceId(isExpanded ? undefined : spaceId)}>
      <DSPanel
        style={{ width: "100%" }}
        ref={hoverRef}
      >
        <DSRow style={Styles.header}>
          <SpaceIcon spaceId={spaceId} tileSize={30} borderRadius={20} />
          <DSH2 style={{ paddingLeft: 8, flexGrow: 1 }}>{DataState.spaces[spaceId].details.name}</DSH2>
          {
            (isHovering || isExpanded) ?
              isExpanded ? <FaChevronUp size={16} /> : <FaChevronDown size={16} />
              :
              null
          }
        </DSRow>
        <animated.div style={{ overflow: "hidden", ...springStyle }}>
          <div ref={ref}>
            <DSTabs
              activeTabId={activeTab.domain === "space" && activeTab.spaceId === spaceId ? activeTab.tabId : "none"}
              onChange={(tabId: string) => setTab({ domain: "space", tabId, spaceId })}
              style={{
                flexDirection: "column",
                alignItems: "stretch",
                backgroundColor: lighten(DSTheme.SpacePanelRoomBackground, 0.05)

              }}
              activeButtonStyle={{
                backgroundColor: DSTheme.EmphasisColor
              }}
              hoverButtonStyle={{
                backgroundColor: lighten(DSTheme.SpacePanelRoomBackground, 0.1)
              }}
              tabStyle={Styles.tab2}
              buttonStyle={{
                width: "none",
                justifyContent: "left",
              }}
            >
              <DSTab tabId={"profile"}>
                <DSH3 style={Styles.h4}>Profile</DSH3>
              </DSTab>
              {space.amAdmin &&
                <DSTab tabId={"workspace"}>
                  <DSH3 style={Styles.h4}>Workspace</DSH3>
                </DSTab>
              }
              <DSTab tabId={"notification"}>
                <DSH3 style={Styles.h4}>Notification</DSH3>
              </DSTab>
              {space.amAdmin &&
                <DSTab tabId={"teams"}>
                  <DSH3 style={Styles.h4}>Teams</DSH3>
                </DSTab>
              }
              {space.amAdmin &&
                <DSTab tabId={"users"}>
                  <DSH3 style={Styles.h4}>Users</DSH3>
                </DSTab>
              }

            </DSTabs>
          </div>
        </animated.div>
      </DSPanel>
    </DSButton >
  );
});



const Styles: Record<string, CSSProperties> = {
  tab2: {
    padding: 10,
    paddingLeft: 20,
  },
  header: {
    alignItems: "center",
    padding: 10,
    paddingLeft: 24,
  },
  separator: {
    height: 0,
    width: "80%",
    borderTopWidth: 1,
    borderBottomWidth: 0,
    borderColor: "white",
    borderTopStyle: "solid",
  },
  h4: {
    padding: 10,
    paddingLeft:32,
  }
}

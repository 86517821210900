import { setCallWidgetHovered } from '@openteam/app-core';
import { Column, darken, IconButton, Icons, lighten, Panel, Row, setAlpha, styled, useTheme } from '@openteam/design-system';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FaPaperclip } from 'react-icons/fa';
import { DataState } from '../../Data/DataState';
import { ChatButton } from './CallButtons';
import CallChat from './CallChat';
import { InlineChatCompose } from './CallChatCompose';

const CallChatContainer: React.FC = () => {
  const call = DataState.activeCall!;
  const [chatOpen, setChatOpen] = useState(false);
  const theme = useTheme();

  return call.mode === 'large' ? (
    <Column style={{ margin: 8 }}>
      {chatOpen ? (
        <Panel
          style={{
            background: setAlpha(darken(theme.palette.secondary.main, 0.1), 0.8),
            width: 350,
            borderRadius: 4,
            height: '100%'
          }}
        >
          <IconButton
            noBadge
            onClick={() => {
              setChatOpen(false);
            }}
            style={{ alignSelf: 'end' }}
          >
            {Icons.close}
          </IconButton>
          <CallChat
            spaceId={call.spaceId}
            callId={call.id}
            callMessageManager={call.callMessageManager} />
        </Panel>
      ) : (
        <>
          <Row style={{
              position: 'absolute',
              bottom: 12,
              right: 12,
              width: 326,
              borderRadius: 4,
              backgroundColor: lighten(theme.palette.secondary.main, 0.1),
            }}>
          <ChatButton onClick={() => setChatOpen(true)} />
          <Separator />
          {(!chatOpen && call.callMessageManager.draftFiles.length) ? (
            <IconButton
              badgeNum={call.callMessageManager.draftFiles.length}
              badgeColor="primary"
              onClick={() => setChatOpen(true)}
            >
              <FaPaperclip />
            </IconButton>
          ) : null}
          <InlineChatCompose
            windowId={'CallWindow'}
            messageManager={call.callMessageManager}
            onFocus={() => setCallWidgetHovered('ChatCompose')}
            onBlur={() => setCallWidgetHovered('ChatCompose', false)}
          />
          </Row>
       </> 
      )}
    </Column>
  ) : null;
};

export default observer(CallChatContainer);

const Separator = styled('div')(({ theme }) => ({
  minWidth: 1,
  width: 1,
  backgroundColor: theme.palette.text.primary,
  opacity: 0.1,
  minHeight: 28,
}));

import { Logger } from '@openteam/app-util';
import { observer } from 'mobx-react';
import React from 'react';
import { setDockItemSize, UIState } from '../../Data/UIState';
import { setThemeSetting, ThemeState } from '../../DesignSystem/DSTheme';
import { DSColumn, DSRow } from '../../DesignSystem';
import { DSH3, DSPrint } from '../../DesignSystem/DSText';
import { DS2Radio, DS2Slider, DS2Switch } from '../../DesignSystem/DS2';



export const DockSettings: React.FC<{}> = observer(({ }) => {

  return (
    <DSColumn spacing={12}>
      <>
        <DSH3>Size</DSH3>
        <DS2Slider
          style={{}}
          defaultValue={UIState.dockSelectedSize}
          min={52}
          max={100}
          step={4}
          onChange={(_, val) => setDockItemSize(val as number)}
        />
      </>

      <DSH3>Style</DSH3>
      <DSColumn>

        <DS2Radio
          label="System"
          checked={ThemeState.selectedTheme == 'SYSTEM'}
          onChange={enabled => enabled && setThemeSetting('SYSTEM')}
        //labelStyle={Styles.label}
        />
        <DS2Radio
          label="Dark Mode"
          checked={ThemeState.selectedTheme == 'DARK'}
          onChange={enabled => enabled && setThemeSetting('DARK')}
        />
        <DS2Radio
          label="Light Mode"
          checked={ThemeState.selectedTheme == 'LIGHT'}
          onChange={enabled => enabled && setThemeSetting('LIGHT')}
        />
      </DSColumn>

      <DSRow style={Styles.settingRow}>
        <DSPrint text="Auto hide dock" />
        <DS2Switch
          analyticsEvent={`DockSettingsDockAutoHide${UIState.dockAutoHide ? 'Off' : 'On'}`}
          checked={UIState.dockAutoHide}
          onChange={UIState.actions.setDockAutoHide}
        />
      </DSRow>

      <DSRow style={Styles.settingRow}>
        <DSPrint text="Show offline users in dock" />
        <DS2Switch
          analyticsEvent={`DockSettingsShowOffline${UIState.dockShowOffline ? 'Off' : 'On'}`}
          checked={UIState.dockShowOffline} onChange={UIState.actions.setDockShowOffline} />
      </DSRow>
    </DSColumn>
  );
});

const Styles = {
  heading: {
    marginTop: 12,
  },
  settingRow: {
    marginTop: 12,
    justifyContent: "space-between",
  },
  settingSubRow: {
    marginTop: 8,
    marginLeft: 10,
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px"
  },
  separator: {
    marginTop: 10,
    minHeight: 1,
  }

}

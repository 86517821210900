
import { Logger } from '@openteam/app-util';
import { animated, useTransition } from '@react-spring/web';
import { observer } from 'mobx-react';
import React, {CSSProperties, useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import type { IFaceDetect } from '../../Controllers/FaceDetection';

const logger = new Logger("CallVideo");


type Props = {
  track?: MediaStreamTrack;
  hasVideo?: boolean;
  flip?: boolean;
  faceDetect?: IFaceDetect | null
  outerStyle?: CSSProperties
  style?: CSSProperties
  blur?: boolean,
  version?: number,
};

export const CallVideo: React.FC<Props> = observer((
    { track, hasVideo=true, flip=false, faceDetect, outerStyle, style, blur}
  ) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [FDStyle, setFDStyle] = useState<CSSProperties>({transform: `scale(${flip ? -1 : 1}, 1)`})
  const {ref, width, height} = useResizeObserver()
  const [resizing, setResizing] = useState(false)

  useEffect(() => {
    if (videoRef.current) {
      const currentRef = videoRef.current;

      if (track) {
        logger.debug(`Setting video object to new stream`);
        currentRef.srcObject = new MediaStream([track]);
      } else {
        logger.debug(`No video track`);
      }

      return () => {
        currentRef.srcObject = null;
      };
    } else {
      logger.debug(`Unable to set track, no ref`);
    }
  }, [track])

  useEffect(() => {
    setResizing(true)
    const t = setTimeout(() => setResizing(false), 200)
    return () => clearTimeout(t)
  }, [width, height])

  useEffect(() => {
    const factor = blur ? 1.15 : 1.01
    const reflect = flip ? -factor : factor;
    let transform = `scale(${reflect}, ${factor})`;

    if (faceDetect && faceDetect.zoom && width && height) {
      const {zoom, centre} = faceDetect;

      const scale = Math.max(factor / zoom, factor);
      const scaleX = flip ? -scale : scale;
      const scaleY = scale;

      const maxTx = (width - (width * zoom)) / 2;
      const maxTy = (height - (height * zoom)) /2;

      let tX = (centre.x - 0.5) * width * -1;
      let tY = (centre.y - 0.5) * height * -1;

      tX = Math.max(-maxTx, Math.min(maxTx, tX));
      tY = Math.max(-maxTy, Math.min(maxTy, tY));

      transform = `scale(${scaleX}, ${scaleY}) translate(${tX}px, ${tY}px)`;
    }
    setFDStyle({
      transform,
      filter: blur ? "blur(15px)" : undefined,
      //transition: "all 1500ms ease-out"
    })
    //logger.debug(`flip ${flip} transform: ${transform}`)
  }, [flip, faceDetect, width, height, blur])

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        transition: "opacity ease-in 0.3s",
        backgroundColor: "black",
        opacity: hasVideo ? 1 : 0,
        ...outerStyle
      }}>
        <video
          key={track?.id}
          style={{
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: resizing ? undefined : 'all 1500ms ease-out',
            ...style,
            ...FDStyle
          }}
          autoPlay
          playsInline
          muted={true}
          ref={videoRef}
        />
    </div>
  );
});


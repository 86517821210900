import React, { useCallback, useEffect, useMemo, useRef, useState }  from "react";
import { observer } from "mobx-react";
import { DataState } from "../../../Data/DataState";

import { SubWindow, SubWindowCSS } from "../../SubWindow";
import { Logger } from "@openteam/app-util";
import { OTGlobals, OTUserInterface } from "@openteam/app-core";
import { runInAction } from "mobx";

const logger = new Logger("PluginPopout");

type Props = {
  pluginId: string;
  poppedOut: boolean;
  parentId: string
};

type Size = {
  x?: number
  y?:number
  aspectRatio?: number
  width: number
  height: number
}

const DEFAULT_AR = 1.33
const DEFAULT_WIDTH = 1024

export const CallPluginPopout: React.FC<Props> = observer(({ pluginId, poppedOut, parentId}) => {
  const id = useRef(`PopoutPlugin-${pluginId}`)
  const [focus, setFocus] = useState(false);
  const [soundPlayed, setSoundPlayed] = useState(false);
  const [windowSize, setWindowSize] = React.useState<Size>({
    width: DEFAULT_WIDTH,
    height: Math.round(DEFAULT_WIDTH/DEFAULT_AR)
  })

  const [workArea, setWorkArea] = useState<Electron.Rectangle | undefined>();

  const pluginDetails = DataState.activeCall?.plugins[pluginId];
  const pluginConfig = pluginDetails && OTGlobals.pluginConfigList[pluginDetails.pluginType];

  useEffect(() => {
    async function loadWorkArea() {
      setWorkArea(await window.Main.getWindowWorkArea(parentId))
    }
    loadWorkArea()
  }, [])

  const setSize = useCallback(() => {
    if (!focus && poppedOut) {
      if (OTGlobals.localUserSettings.screenSharePopoutSize === "MAXIMISE") {
        window.Main.maximiseWindow(id.current)
      } else if (workArea) {
        let scale = 1;
        setWindowSize({
          width: Math.floor(workArea.width / 2),
          height: Math.floor(workArea.height / 2),
        });
      }
      logger.debug(`Showing popout`);
      setFocus(true);
    }
  }, [setWindowSize, setFocus, workArea, focus, poppedOut, id]);

  useEffect(() => {
    if (!soundPlayed && poppedOut && true) {
      OTUserInterface.soundEffects.pluginOpened();
      setSoundPlayed(true);
      if (!pluginConfig?.component && pluginDetails?.args.url) {
        window.Main.shellOpenExternal(pluginDetails?.args.url).then(() => {
          runInAction(() => delete DataState.activeCall?.pluginPopouts[pluginId]);
        })
      }
    }
  }, [poppedOut, focus, soundPlayed, setSoundPlayed, pluginDetails])

  useEffect(() => logger.debug(`rendering plugin ${pluginId}`));

  return pluginConfig?.component ? (
    <SubWindow
        id={id.current}
        initialSize={windowSize}
        show={poppedOut}
        focus={focus}
        onCreated={() => setSize}
        onClose={() => DataState.activeCall?.actions.popoutPlugin(pluginId, false)}
        options={{
          frame: true,
          transparent: false,
          autoHideMenuBar: true,
          useContentSize: true,
          resizable: true,
          alwaysOnTop: false,
          skipTaskbar: false,
          focusable: true,
          maximizable: true,
          fullscreenable: true,
          hasShadow: true,
          closable: true,
          show: false,
        }}
      >
      <style type="text/css">{SubWindowCSS}</style>
      <div style={{height: "100%", width: "100%"}}>
       <pluginConfig.component
         key={pluginId}
         pluginDetails={pluginDetails}
         {...DataState.activeCall?.pluginCallbacks}
       />
      </div>
    </SubWindow>
  ) : null;
});

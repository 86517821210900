import { computed, makeObservable, observable } from "mobx";
import { AwaitLock, Logger } from "@openteam/app-util";
import { FireDb, CallRequestDb } from "../fire";
import { IAudioContainer, ICallRequest, ITeamCall, TCallRequestStatus, TReceiverStatus, TRoomMode } from "@openteam/models";
import { OTGlobals } from "../OTGlobals";
import { sendAction, recvAction } from "../Alert";
import { OTUITree } from "../OTUITree";
import { CallRequestUIState } from "./CallRequestUIState";
import { OTUserInterface } from "../OTUserInterface";
import { writeCallRequests, writeCallRequests2 } from "../UIDataState";
import { Database } from "firebase/database";
import { CallRequestDb2 } from "../fire/CallRequestDb2";
import { Firestore } from "firebase/firestore";

const logger = new Logger("CallRequestManager2");


export class CallRequestManager2 {
  fsDb: Firestore;
  userId: string;
  sessionToken: string;
  teamId: string;

  joinTeamRoom: (roomId: string | null, ignoreLock?: boolean) => Promise<void>;
  startUserRoom: (meetingName?: string, roomMode?: TRoomMode) => Promise<string>;
  unsubscribeIncoming?: () => void
  unsubscribeOutgoing?: () => void

  @observable incomingCallRequests: Record<string, ICallRequest> = {}
  @observable outgoingCallRequests: Record<string, ICallRequest> = {}

  @observable callRequests: Record<string, ICallRequest> = {} // used as an index
  _messageLock = new AwaitLock();

  constructor(
    fsDb: Firestore,
    userId: string,
    sessionToken: string,
    teamId: string,
    joinTeamRoom: (roomId: string | null, ignoreLock?: boolean) => Promise<void>,
    startUserRoom: (meetingName?: string, roomConfig?) => Promise<string>
  ) {
    makeObservable(this)

    this.fsDb = fsDb;
    this.userId = userId;
    this.sessionToken = sessionToken;
    this.teamId = teamId;
    this.joinTeamRoom = joinTeamRoom;
    this.startUserRoom = startUserRoom

  }

  start = () => {
    this.unsubscribeIncoming = CallRequestDb2.watchIncomingCallRequests(this.fsDb, this.teamId, this.userId, this.handleIncomingCallRequests);
    this.unsubscribeOutgoing = CallRequestDb2.watchOutgoingCallRequests(this.fsDb, this.teamId, this.userId, this.handleOutgoingCallRequests);
  };
  stop = () => {
    this.unsubscribeIncoming?.()
    this.unsubscribeOutgoing?.()
  };



  handleOutgoingCallRequests = async (added: ICallRequest[], edited: ICallRequest[], deleted: string[]) => {
    for (const reqId of deleted) {
      const receiverUserId = this.callRequests[reqId].receiverUserId
      if (this.outgoingCallRequests[receiverUserId].id == reqId) {
        delete this.outgoingCallRequests[receiverUserId]
      }
      delete this.callRequests[reqId]
    }
    
    for (const req of added) {
        this.outgoingCallRequests[req.receiverUserId] = req
        this.callRequests[req.id] = req
        await this.processOutgoing(req)
    }

    for (const req of edited) {
      this.outgoingCallRequests[req.receiverUserId] = req
      this.callRequests[req.id] = req
      await this.processOutgoing(req)
    }

    logger.debug("my outgoing call requests are", this.outgoingCallRequests)
    writeCallRequests2(this)
  }


  handleIncomingCallRequests = async (added: ICallRequest[], edited: ICallRequest[], deleted: string[]) => {
    for (const reqId of deleted) {
      const senderUserId = this.callRequests[reqId].senderUserId
      if (this.incomingCallRequests[senderUserId].id == reqId) {
        delete this.incomingCallRequests[senderUserId]
      }
      delete this.callRequests[reqId]
      logger.debug(`deleting reqId ${reqId} senderUserId ${senderUserId}`)

    }
    
    for (const req of added) {
        this.incomingCallRequests[req.senderUserId] = req
        this.callRequests[req.id] = req
        await this.processIncoming(req)

        if (req.senderActive && req.receiverActive) {
          OTUserInterface.soundEffects.knock();
        }
      logger.debug(`adding reqId ${req.id} senderUserId ${req.senderUserId}`)
        
    }

    for (const req of edited) {
      this.incomingCallRequests[req.senderUserId] = req
      this.callRequests[req.id] = req
      await this.processIncoming(req)
      logger.debug(`updating reqId ${req.id} senderUserId ${req.senderUserId}`)
    }

    logger.debug("my incoming call requests are", this.incomingCallRequests)
    writeCallRequests2(this)

  }

  processOutgoing = async (req: ICallRequest) => {
    await this._messageLock.acquireAsync();

    try {
      if (req.status == 'recvaccepted') {
        await this.sendStartCall(req.id)
      }
    } catch (e) {
      logger.error("failed to set IsTyping", e);
    } finally {
      this._messageLock.release();
    }
  }
  processIncoming = async (req: ICallRequest) => {
    if (req.status == 'callstarted') {
      await this.recvJoinCall(req.id, req.roomId)
    }
  }

  startRequest = async (userId: string, roomId?: string, roomMode?: TRoomMode) => {
    var teamData = OTGlobals.getTeamData(this.teamId);

    if (!roomId) {
      var roomId: string | undefined = teamData.currentRoomId;

      if (roomId && (!teamData.rooms[roomId]?.config?.call || teamData.rooms[roomId]?.config?.focusRoom)) {
        roomId = undefined;
      }
    }

    const id = await CallRequestDb2.startRequest(this.fsDb, this.teamId, this.userId, userId, roomId, roomMode)
    return id
  }

  setRequestMsg = async (requestId: string, msg: string) => {
    logger.debug("setting request msg", msg)
    await CallRequestDb2.setRequestMsg(this.fsDb, this.teamId, requestId, msg)
  }

  whenFreeRequest = (requestId: string) => {
    CallRequestDb2.whenFreeRequest(this.fsDb, this.teamId, requestId)
  }

  cancelRequest = (requestId: string) => {
    CallRequestDb2.cancelRequest(this.fsDb, this.teamId, requestId)
  }

  clearRequest = (requestId: string) => {
    CallRequestDb2.clearRequest(this.fsDb, this.teamId, requestId)
  }

  respondToRequestAccept = (requestId: string ) => {
    CallRequestDb2.respondToRequest(this.fsDb, this.teamId, requestId, 'recvaccepted')
  }

  respondToRequestLater = (requestId: string ) => {
    CallRequestDb2.respondToRequest(this.fsDb, this.teamId, requestId, 'recvlater')
  }

  respondToRequestHoldon = (requestId: string ) => {
    CallRequestDb2.respondToRequest(this.fsDb, this.teamId, requestId, 'recvholdon')
  }

  rerequestCall = async (requestId: string ) => {
    logger.debug("rerequestCall", requestId)
    CallRequestDb2.rerequestCall(this.fsDb, this.teamId, this.userId, requestId)
  }

  dismissCanceledRequest = (requestId: string ) => {
    CallRequestDb2.dismissCanceledRequest(this.fsDb, this.teamId, requestId)
  }


  sendStartCall = async (requestId: string) => {
    const req = this.callRequests[requestId]
    let roomId = req.roomId
    if (!roomId) {
      roomId = await this.startUserRoom(undefined, req.roomMode)
    }

    logger.debug(`starting call for requestId${requestId} in roomId ${roomId}`)

    await CallRequestDb2.callStarted(this.fsDb, this.teamId, requestId, roomId);

  };

  recvJoinCall = async (requestId, roomId) => {
    await CallRequestDb2.callAccepted(this.fsDb, this.teamId, requestId);
    OTGlobals.auth.userManager.setCurrentTeam(this.teamId);
    logger.debug(`joining call for requestId${requestId} in roomId ${roomId}`)

    await this.joinTeamRoom(roomId, true);
  };

}

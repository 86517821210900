import { Logger } from "@openteam/app-util"
import { IShowConfirm } from "@openteam/models";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import { DS2BaseButton, DS2DeleteButton, DS2RejectButton, DS2SaveButton } from "../../DesignSystem/DS2";
import { DSButton, DSOutlineButton } from "../../DesignSystem/DSButton";
import { DSColumn, DSPanel, DSPanelScrollable, DSRow, DSVSpacer } from "../../DesignSystem";
import { DSBody, DSH3 } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { Draggable } from "../Draggable";
import { DSModalPanel } from "../Modal/ModalLayout";
import { fmtResourceName } from "../Search/SearchControl";
import { DialogWindow } from "./DialogWindow";


interface Props extends IShowConfirm {
  show?: boolean
}

export const ConfirmDialog: React.FC<Props> = observer(
  ({
    parentId,
    title,
    message,
    actions = { 'Yes': true, 'No': false },
    buttonStyles = {},
    buttonProps,
    height = 350,
    width = 300,
    onClose,
    show = true,
    ...props
  }) => {


    const id = `confirm-${parentId}`;

    const fmtMessage = (message: string | React.ReactNode, index?: number) => (
      React.isValidElement(message) ? message : <DSBody key={index}>{message}</DSBody>
    );

    return (
      <DialogWindow id={id} parentId={parentId} show={show} height={height} width={width}>
        <DSModalPanel
          windowId={parentId ?? 'modal'}
          title={title}
          footer={
            <DSRow style={Styles.actions} spacing={8}>
              {
                Object.entries(actions).map(([title, action]) => {
                  const cb = typeof action === "function" ? action : (() => onClose?.(action))

                  if (title.toLowerCase() === 'cancel') {
                    return (
                      <DS2RejectButton
                        key={title}
                        title={title}
                        tabIndex={-1}
                        color='secondary'
                        style={{flexGrow: 1}}
                        {...buttonProps?.[title]}
                        onClick={cb}
                      />
                    )
                  } else if (title.toLowerCase() === 'delete') {
                    return (
                      <DS2DeleteButton
                        key={title}
                        tabIndex={-1}
                        title={title}
                        style={{flexGrow: 1}}
                        {...buttonProps?.[title]}
                        onClick={cb}
                      />
                    )
                  } else if (title.toLowerCase() === 'save') {
                    return (
                      <DS2SaveButton
                        key={title}
                        focusRipple={false}
                        title={title}
                        style={{flexGrow: 1}}
                        {...buttonProps?.[title]}
                        onClick={cb}
                      />
                    )
                  } else {
                    return (
                      <DS2BaseButton
                        key={title}
                        noBadge
                        color={action === true ? 'success' : 'secondary'}
                        tabIndex={action === true ? 1 : 2}
                        style={{
                          flexGrow: 1,
                          ...buttonStyles?.[title] || {}
                        }}
                        {...buttonProps?.[title]}
                        onClick={cb}
                      >
                        {title}
                      </DS2BaseButton>
                    )
                  }
                })
              }
            </DSRow>
          }
        >

          {Array.isArray(message) ? (
            message.map((m, index) => fmtMessage(m, index))
          ) : fmtMessage(message)
          }
          <DSVSpacer size={10} />


        </DSModalPanel>

      </DialogWindow>
    );
  }
);

class StylesClass {
  constructor() {
    makeAutoObservable(this);
  }

  get container(): CSSProperties {
    return {
      backgroundColor: DSTheme.PanelBackgroundColor,
      color: DSTheme.MainText,
      flexGrow: 1,
      paddingBottom: 10
    }
  }
  get header(): CSSProperties {
    return {
      minHeight: 42,
      paddingLeft: 16,
      paddingRight: 8,
      backgroundColor: DSTheme.PanelHeaderColor,
      alignItems: "center",
    };
  }
  panel: CSSProperties = {
    flexGrow: 1,
    justifyContent: "space-between",
    padding: 16
  }
  actions: CSSProperties = {
    justifyContent: "space-evenly",
    marginTop: 10,
  }
  button: CSSProperties = {
    padding: "10px 14px",
    borderRadius: 8,
    backgroundColor: 'white',
    color: "black"
  }
  get successButton(): CSSProperties {
    return {
      ...this.button,
      color: DSTheme.MainText,
      backgroundColor: DSTheme.SuccessColor
    }
  }
  get cancelButton(): CSSProperties {
    return {
      ...this.button,
      backgroundColor: DSTheme.WarningColor
    }
  }
}

const Styles = new StylesClass();

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { KSpaceId, KSpaceChannelId, IPinnedResource } from '@openteam/models';
import { DSColumn, DSRow } from '../../DesignSystem';
import { DSCircleIconButton } from '../../DesignSystem/DSButton';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Logger } from '@openteam/app-util';
import { DataState } from '../../Data/DataState';
import { toJS } from 'mobx';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { PinnedResources } from "./Resources/PinnedResources";
import { AllResources } from "./Resources/AllResources";
import { animated, useSpring } from '@react-spring/web';
import { OTGlobals, OTUserInterface } from "@openteam/app-core";
import { fmtResourceName } from '../Search/SearchControl';
import { FileIcon } from "../FavIcon";
import { DSBody } from '../../DesignSystem/DSText';
import { DS2IconActionButton } from '../../DesignSystem';

const logger = new Logger("RoomResourceContainer");

type Props = {
  spaceId: KSpaceId;
  channelId: KSpaceChannelId;
  windowId: string;
  sendURL: (text: string, systemMessage: string) => void;
  recentResources: Record<string, IPinnedResource>;
  pinnedResources: IPinnedResource[];
  deletedResources: string[];
  updateResource: (r: IPinnedResource, isPinned?: boolean, isResource?: boolean, name?: string) => Promise<void>,
};


const _RoomResourceContainer: React.FC<Props> = ({
  spaceId,
  channelId,
  windowId,
  sendURL,
  recentResources,
  deletedResources,
  pinnedResources,
  updateResource,
}) => {

  const [isOpen, setOpen] = useState<boolean>(false);
  const [isAnimating, setAnimating] = useState<boolean>(false);
  const [searchVersion, setSearchVersion] = useState<number>(0);

  const inCall = !!DataState.activeCall

  logger.debug("pinnedResources: ", toJS(pinnedResources));

  const togglePinnedResource = async (resource: IPinnedResource) => {
    const isPinned = !getPinnedObject(resource.objectID);
    logger.debug("togglePinnedResource: ", resource.objectID, isPinned);
    await updateResource(resource, isPinned)
  }

  const deleteResource = async (resource: IPinnedResource) => {
    logger.debug("deleteResource: resource: ", resource.objectID);

    const deleteResource = await OTUserInterface.showConfirm({
      parentId: windowId,
      title: "Delete Resource?",
      message: [
        `Are you sure you want to delete this resource?`,
        <DSRow style={{ marginLeft: 10, alignItems: "center" }}>
          <FileIcon hit={resource} size={30} />
          <DSColumn style={{ marginLeft: 10 }}>
            <DSBody style={{
              width: 150,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>{fmtResourceName(resource)}</DSBody>
          </DSColumn>
        </DSRow>
        ,
      ],
      actions: { 'Delete': true, "Cancel": false },
      buttonStyles: { "Delete": { backgroundColor: DSTheme.DangerColor } },
      height: 260,
    });

    if (deleteResource) {
      await updateResource(resource, false, false);
    }
  }

  const setResourceName = async (resource: IPinnedResource, name: string) => {
    logger.debug("setResourceName: ", resource.objectID, name);
    await updateResource(resource, undefined, undefined, name);
  }

  const getPinnedObject = (objectId: string) => pinnedResources.find((r) => r.objectID === objectId);

  const openResource = async (resource: IPinnedResource, openForEveryone?: boolean) => {
    if (inCall) {
      const callStateManager = OTGlobals.callStateManager!;
      const { pluginType, pluginArgs } = callStateManager.pluginManager.getResourceHandler(resource);

      if (pluginType) {
        let myOpenForEveryone = openForEveryone;

        if (myOpenForEveryone === undefined) {
          myOpenForEveryone = await OTUserInterface.showConfirm({
            parentId: windowId,
            title: "Open resource in call",
            message: [
              `We've detected that you're sharing a link.`,
              `Would you like to open it for everyone using the ${pluginType} plugin?`],
            actions: { 'Open for everyone': true, 'Open for me only': false },
            buttonStyles: {
              'Open for everyone': { backgroundColor: DSTheme.EmphasisColor, margin: "0 10" },
              'Open for me only': { margin: "0 10" }
            },
            width: 350,
          });
        };

        if (myOpenForEveryone) {
          callStateManager.callMessageManager.sendResource(resource);
          callStateManager.pluginManager.createPlugin(pluginType, resource.url, pluginArgs);
        } else {
          window.Main.shellOpenExternal(resource.url);
        }
      }
    } else {
      window.Main.shellOpenExternal(resource.url);
    }
  }

  const toggleDrawer = () => {
    setOpen(!isOpen);
    setSearchVersion((searchVersion) => searchVersion + 1);
  }

  const closedWidth = 50;
  const openWidth = 400;
  const borderWidth = 5;
  const scrollWidth = 10;

  const springStyle = useSpring({
    width: isOpen ? openWidth : closedWidth,
    onStart: () => setAnimating(true),
    onRest: () => setAnimating(false),
  });

  const pinnedStyle = useSpring({
    opacity: !isOpen ? 1 : 0,
  });
  const allStyle = useSpring({
    opacity: isOpen ? 1 : 0,
  });
  return (
    <>
      <DSColumn style={{ minWidth: closedWidth + borderWidth }} />
      {isOpen && <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.5,
          backgroundColor: "black",
          zIndex: 10
        }}
        onClick={() => toggleDrawer()} />
      }
      <div style={{ position: "absolute", top: 2, right: 2, margin: 0, zIndex: 11, padding: 8, }}>
      <DS2IconActionButton
        data-tooltip={isOpen ? "Close drive panel" : "Open drive panel"}
        data-tooltip-position="right"
        color='secondary'
        onClick={() => toggleDrawer()}
      >
        {isOpen ? <FaChevronRight/> : <FaChevronLeft />}
        </DS2IconActionButton>
      </div>
      <animated.div style={{
        position: "absolute",
        top: 50,
        right: 0,
        bottom: 0,
        zIndex: !isOpen || isAnimating ? 11 : -1,
        ...pinnedStyle
      }}>
        <PinnedResources
          parentWindowId={windowId}
          spaceId={spaceId}
          channelId={channelId}
          pinnedResources={pinnedResources}

          togglePinnedResource={togglePinnedResource}
          deleteResource={deleteResource}
          setResourceName={setResourceName}
          getPinnedObject={getPinnedObject}
          inCall={inCall}

          openResource={openResource}
          sendURL={sendURL}
          width={50}
        />
      </animated.div>

      <animated.div style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: DSTheme.PanelBackgroundColor,
        borderLeftWidth: borderWidth,
        borderLeftColor: DSTheme.PanelHeaderColor,
        borderLeftStyle: "solid",
        display: "flex",
        flexDirection: "row",
        zIndex: 10,

        ...springStyle,
      }}>
        <animated.div
          style={{
            display: isAnimating || isOpen ? "block" : "none",
            maxWidth: openWidth,
            overflow: 'hidden',
            width: "100%",
            ...allStyle
          }}
        >

          <AllResources
            spaceId={spaceId}
            channelId={channelId}
            windowId={windowId}
            recentResources={recentResources}
            togglePinnedResource={togglePinnedResource}
            deletedResources={deletedResources}
            deleteResource={deleteResource}
            setResourceName={setResourceName}
            getPinnedObject={getPinnedObject}
            openResource={openResource}
            inCall={inCall}
            width={openWidth - scrollWidth}
            searchVersion={searchVersion}
            isDisplayed={isOpen}
            animating={isAnimating}
          />
        </animated.div>
      </animated.div>
    </>
  );
};

const RoomResourceContainer = observer(_RoomResourceContainer);
export default RoomResourceContainer;

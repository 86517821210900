import { toArray } from "react-emoji-render";

export function parseEmojis(value: string): { value: any, isOnlyEmoji: boolean } {
  const emojisArray = toArray(value);

  var isOnlyEmoji = true

  // toArray outputs React elements for emojis and strings for other
  const newValue = emojisArray.reduce((previous, current: any) => {
    if (typeof current === "string") {
      if (current.replace(/\s/g, "")) {
        isOnlyEmoji = false
      }
      return previous + current;
    }
    return previous + current.props.children;
  }, "");

  return { value: newValue, isOnlyEmoji: isOnlyEmoji };
};

import { Logger } from "@openteam/app-util"
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { ISubWindowProps, ModalOptions, SubWindow, SubWindowCSS } from "../SubWindow";

const logger = new Logger("DialogWindow")

interface Props extends ISubWindowProps {
};

export const DialogWindow: React.FC<Props> = ({
  id,
  parentId,
  onClose,
  children,
  options,
  width=300,
  height=300,
  ...subwindowProps
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{x: number, y: number}>()
  const {show} = subwindowProps

  useLayoutEffect(() => {

    async function setPosFromBounds() {
      if (show) {
        let x, y;
        if (parentId) {
          const bounds: Electron.Rectangle | undefined = await window.Main.getWindowBounds(parentId);
          if (bounds) {
            x = bounds.x + bounds.width / 2 - width / 2;
            y = bounds.y + bounds.height / 2 - height / 2;
          }
        }
        setPosition({ x, y })
      } else {
        setPosition(undefined)
      }
    }
    setPosFromBounds()
  }, [show, parentId]);

  if (parentId) {
  }

  if (position) {
    return (
      <SubWindow
        id={id}
        show={show}
        focus={show}
        parentId={parentId}
        protectContent={true}
        initialPosition={position}
        initialSize={{width, height}}
        options={{
          ...ModalOptions,
          ...options
        }}
        {...subwindowProps}
      >
        <style type="text/css">{SubWindowCSS}</style>
        {children}
      </SubWindow>
    );
  } else {
    return null;
  }
};

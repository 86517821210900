import { OTGlobals, OTUITree } from '@openteam/app-core'
import { Logger } from '@openteam/app-util'
import { IUISpaceUser, KSpaceId } from '@openteam/models'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ToolTipComponent } from '../../../Controllers/ToolTips'
import { DataState } from '../../../Data/DataState'
import { addToRecentUsers, setDockShowOffline, showInviteUsers, UIState } from '../../../Data/UIState'
import { DS2Icons, DS2InviteIconButton, DS2SearchInput } from '../../../DesignSystem/DS2'
import { DSColumn, DSPanelScrollable, DSRow, DSWrappedRow, DS2Switch } from '../../../DesignSystem'
import { DSH2, DSH3, DSH4, DSPrint, DSSmallPrint } from '../../../DesignSystem/DSText'
import groupBy from '../../../Util/groupBy'
import PanelButton from '../../PanelButton'
import getUserTooltip from '../../User/getUserTooltip'
import UserIcon from '../../User/UserIcon'
import { DockUserPanel } from '../DockUserPanel'
import DockOverflowCall from './DockOverflowCall'
import { DockOverflowPanelProps } from './DockOverflowIcon'
import DockOverflowOverviewCall from './DockOverflowOverviewCall'
import DockOverflowPanel from './DockOverflowPanel'
import DockOverflowUser from './DockOverflowUser'

const logger = new Logger("DockOverflowUserPanel")

type Position = { x: number; y: number };


const DockOverflowUserPanel: React.FC<DockOverflowPanelProps> = (props) => {

  const space = DataState.spaces[props.spaceId]

  const [shown, setShown] = useState(false);
  const [searchString, setSearchString] = useState('')
  const searchRef = useRef<HTMLInputElement>(null);
  const [panelPosition, setPanelPosition] = useState<Position>()

  const setOverviewMode = useCallback((dockOverflowOverviewMode) => {
    OTGlobals.userSettingsManager.updateRemoteSettings({dockOverflowOverviewMode})
  }, [])

  const setGroupByTeam = useCallback((dockOverflowGroupByTeam) => {
    OTGlobals.userSettingsManager.updateRemoteSettings({dockOverflowGroupByTeam})
  }, [])

  const overviewMode = OTGlobals.remoteUserSettings.dockOverflowOverviewMode === true;
  const groupByTeam = OTGlobals.remoteUserSettings.dockOverflowGroupByTeam === true;

  let calls = overviewMode ? Object.values(space.calls) : [];
  const roomUsers = calls.reduce((users, call) => users.concat(Object.keys(call.participants)), [] as string[]);

  const sortFunc = groupByTeam ? sortbyOnlineTeamAndName : sortbyOnlineAndName
  let allUsers = Object.values(space.users)
    .filter((u) => u.id !== OTUITree.auth.userId)
    .filter((u) => !roomUsers.includes(u.id))
    .sort(sortFunc);

  if (searchString != "") {
    const lowercaseSearchString = searchString.toLowerCase();
    allUsers = allUsers.filter((user: IUISpaceUser) => user.name.toLowerCase().includes(lowercaseSearchString));
    calls = calls.filter((call) => call.name.toLowerCase().includes(lowercaseSearchString));
  }

  useEffect(() => {
    const offShow = window.Main.on(`window-show-${props.windowId}`, () => setShown(true))
    const offHide = window.Main.on(`window-hide-${props.windowId}`, () => setShown(false))
    const offMove = window.Main.on(`window-moved-${props.windowId}`, ({ bounds }) => setPanelPosition(bounds))

    return () => {
      offShow()
      offHide()
      offMove()
    }
  }, [props.windowId])

  useEffect(() => {
    if (searchRef.current && shown) {
      searchRef.current.focus()
    }
  }, [shown])

  const onUserClick = useCallback((userId) => {
    addToRecentUsers(props.spaceId, userId)
    props.closeWindow()
  }, [props.spaceId, props.closeWindow])



  const SectionComponent = overviewMode ? OverviewSection : ListviewSection;

  return (
    <DockOverflowPanel spacing={16}>
      <DSRow style={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
        <DSH2>Teammates</DSH2>
        <DS2InviteIconButton color='secondary' onClick={() => showInviteUsers()} />
      </DSRow>
      <DSRow>
        <DS2SearchInput value={searchString} onValueChange={setSearchString} ref={searchRef} />
      </DSRow>
      <DSRow style={{ justifyContent: 'space-evenly' }} spacing={12}>
        <DSRow style={{ alignItems: 'center' }} spacing={4}>
          <DS2Switch checked={overviewMode} onChange={setOverviewMode} label="Overview Mode"/>
        </DSRow>
        <DSRow style={{ alignItems: 'center' }} spacing={4}>
          <DS2Switch checked={groupByTeam} onChange={setGroupByTeam} label="Group by team"/>
        </DSRow>
      </DSRow>
      <DSPanelScrollable style={{ paddingRight: 8, marginRight: -8 }}>

        {
          calls.length ? (
            <DSColumn spacing={8} style={{ marginTop: 12 }} >
              <DSH4>Ongoing Calls</DSH4>
              <DSWrappedRow>
                {
                  calls.map((call, i) => (
                    <DockOverflowOverviewCall
                      key={`call-${call.id}-${i}`}
                      spaceId={props.spaceId}
                      callId={call.id}
                      windowId={props.windowId}
                      setDisableOnBlur={props.setDisableOnBlur}
                      closeWindow={props.closeWindow}
                    />
                  ))
                }
              </DSWrappedRow>
            </DSColumn>
          ) : null
        }


        {
          allUsers.length > 0 ? (
            Object.entries(
              groupBy(allUsers, (u) => u.status?.online ? 'Online' : 'Offline')
            ).map(([status, statusUsers]) => (

              <DSColumn style={{ marginTop: 12 }} spacing={8} key={`users-${status}`}>
                <DSH4>{status}</DSH4>

                {
                  groupByTeam ? (
                    Object.entries(
                      groupBy(statusUsers, (u) => u.team ?? 'Unassigned')
                    ).map(([team, users]) => (
                      <SectionComponent
                        key={`${status}-${team}`}
                        spaceId={props.spaceId}
                        panelPosition={panelPosition}
                        heading={team}
                        users={users}
                        shown={shown}
                        closeWindow={props.closeWindow}
                        windowId={props.windowId}
                        setDisableOnBlur={props.setDisableOnBlur}
                      />
                    ))
                  ) : (
                    <SectionComponent
                      spaceId={props.spaceId}
                      panelPosition={panelPosition}
                      users={statusUsers}
                      shown={shown}
                      closeWindow={props.closeWindow}
                      windowId={props.windowId}
                      setDisableOnBlur={props.setDisableOnBlur}
                    />
                  )
                }
              </DSColumn>
            ))

          ) : searchString != "" ? (
            <DSH3 wrap style={{ marginTop: 24, alignSelf: 'center', textAlign: 'center' }}>
              No teammates match your search.
            </DSH3>
          ) : (
            <DSH3 wrap style={{ marginTop: 24, alignSelf: 'center', textAlign: 'center' }}>
              There are no teammates in your workspace.<br /><br />
              Click on the {DS2Icons.invite} button above to invite your teammates.
            </DSH3>
          )
        }

      </DSPanelScrollable>

      <ToolTipComponent id='overflow-user' getContent={getUserTooltip} />
    </DockOverflowPanel>
  )
}

export default observer(DockOverflowUserPanel)


type SectionProps = {
  spaceId: KSpaceId
  panelPosition?: Position
  shown: boolean
  closeWindow: (DockOverflowPanelProps?: () => void) => void
  heading?: string
  users: IUISpaceUser[]
  windowId: string
  setDisableOnBlur: (value: boolean) => void
}

const ListviewSection: React.FC<SectionProps> = observer(({ users, heading, shown, ...props }) => (

  <DSColumn spacing={8}>

    {heading && <DSSmallPrint>{heading}</DSSmallPrint>}

    {
      users.map((user) => (
        <DockOverflowUser
          key={user.id}
          spaceId={props.spaceId}
          userId={user.id}
          shown={shown}
          closeWindow={props.closeWindow} />
      ))
    }
  </DSColumn>
))


const OverviewSection: React.FC<SectionProps> = observer(({ spaceId, users, heading, closeWindow, windowId, setDisableOnBlur}) => {

  const onClick = (userId) => {
    addToRecentUsers(spaceId, userId)
    closeWindow()
  }

  return (<DSColumn spacing={8}>

    {heading && <DSSmallPrint>{heading}</DSSmallPrint>}

    <DSWrappedRow>
      {
        users.map((user) =>
          <PanelButton
            key={user.id}
            id={user.id}
            parentId={windowId}
            onShowPanel={setDisableOnBlur}
            position={UIState.dockPosition.onLeft ? 'right' : 'left'}
            renderContents={() => (
              <DockUserPanel
                spaceId={spaceId}
                userId={user.id}
                closeWindow={closeWindow}
                isDockPanel={false}
              />
            )}
          >
            <UserIcon
              showTooltip
              data-tooltip={`${spaceId}:${user.id}`}
              data-tooltip-for='overflow-user'
              key={user.id}
              user={user}
              size={60}
              showCustomEmoji={user.status?.online === true}
              outerStyle={{ margin: 4 }}
            />
          </PanelButton>
        )
      }
    </DSWrappedRow>

  </DSColumn>
  )
})


const sortbyOnlineTeamAndName = (a: IUISpaceUser, b: IUISpaceUser) => {
  const aTeam = (a.team ?? 'zzzzz').toLowerCase();
  const bTeam = (b.team ?? 'zzzzz').toLowerCase();

  if (!a.status?.online && b.status?.online) {
    return 1;
  } else if (a.status?.online && !b.status?.online) {
    return -1;
  } else if (aTeam > bTeam) {
    return 1;
  } else if (aTeam < bTeam) {
    return -1;
  } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  };
  return 0;
}

const sortbyOnlineAndName = (a: IUISpaceUser, b: IUISpaceUser) => {
  const aTeam = (a.team ?? 'zzzzz').toLowerCase();
  const bTeam = (b.team ?? 'zzzzz').toLowerCase();

  if (!a.status?.online && b.status?.online) {
    return 1;
  } else if (a.status?.online && !b.status?.online) {
    return -1;
  } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  };
  return 0;
}

import React, { CSSProperties } from "react";

type Props = {
  size?: number
  style?: CSSProperties
  fillColor?: string
}

const ZoomIcon: React.FC<Props> = ({ size = 24, fillColor="#2196f3", style }) => (
  <div
    style={{
      height: size,
      width: size,
      alignItems: "center",
      justifyContent: "center",
      ...style
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width={size} height={size}>
      <circle cx="24" cy="24" r="24" fill={fillColor}/>
      <path fill="#fff" d="M29,31H14c-1.657,0-3-1.343-3-3V17h15c1.657,0,3,1.343,3,3V31z"/>
      <polygon fill="#fff" points="37,31 31,27 31,21 37,17"/>
    </svg>
  </div>
);

export default ZoomIcon

export const ZoomCamera: React.FC<Props> = ({ size = 24, style }) => (
  <div
    style={{
      height: size,
      width: size,
      alignItems: "center",
      justifyContent: "center",
      ...style
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 40 40" width={size} height={size}>
      <path fill="#fff" d="M29,31H14c-1.657,0-3-1.343-3-3V17h15c1.657,0,3,1.343,3,3V31z"/>
      <polygon fill="#fff" points="37,31 31,27 31,21 37,17"/>
    </svg>
  </div>
);

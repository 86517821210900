import React, { useRef, CSSProperties, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { IUISpaceUser, KSpaceId, KSpaceUserId } from "@openteam/models"
import { observer } from "mobx-react"
import { useTheme, keyframes, css, styled, Panel } from "@openteam/design-system";
import { DataState } from "../../Data/DataState"
import { getDummyUser } from "../../Data/tutorialDummy"
import { DockIconBase } from "./DockIconBase"
import { UserIcon } from "../User/UserIcon"
import * as Analytics from '../../Util/Analytics'
import { openUserMenu } from "../User/UserMenu";
import { DSPanel, DSRow } from "../../DesignSystem";
import { DockUserPanel } from "./DockUserPanel";
import { openUserChat, UIState } from "../../Data/UIState";
import { getQuietIncomingCall, getQuietOutgoingCall, playKnock, SoundEffectPlayer } from "../../Controllers/SoundEffects";
import { hideNotification, showNotification } from "../../Controllers/Notifications";
import { tutorialState } from "../../Controllers/TutorialService";
import { DockNotifyPanel } from "./DockNotifyPanel";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DS2AcceptButton, DS2Button, DS2CancelCallButton, DS2CloseButton, DS2HoldButton, DS2Icons, DS2RejectButton, DSHSpacer } from "../../DesignSystem/DS2";
import { CirclePosition } from "../../Util/CirclePosition";
import { SpaceIcon } from "../Space/SpaceIcon";
import { DSInput } from "../../DesignSystem/DSInput";
import { DSBody } from "../../DesignSystem/DSText";
import { setAlpha } from "@openteam/design-system";
import { Logger } from "@openteam/app-util";
import { toJS } from "mobx";
import { debounce } from "throttle-debounce";
import { DSSpinner } from "../../DesignSystem/DSSpinner";
import { FaCheck } from "react-icons/fa";


const logger = new Logger("DockUserIcon");

interface IDockUserIconProps {
  spaceId: KSpaceId
  userId: KSpaceUserId
  size: number
  showSpaceIcon?: boolean
}


export const DockUserIcon = observer((props: IDockUserIconProps) => {
  let incomingSound = useRef<SoundEffectPlayer | undefined>(undefined);
  let outgoingSound = useRef<SoundEffectPlayer | undefined>(undefined);

  const space = DataState.spaces[props.spaceId]
  const isTutorial = props.userId == 'dummy'
  const user = isTutorial ? getDummyUser(props.userId) : space.users[props.userId]

  const isOpen = UIState.openChats[props.spaceId] &&
    space.userChats[props.userId] &&
    space.userChats[props.userId].channelId in UIState.openChats[props.spaceId].channels;

  const numUnread = (space.userChats[props.userId]?.numUnread ?? 0)
    + (user.alerts?.knocked?.count ?? 0)
    + (user.alerts?.called?.count ?? 0);



  useEffect(() => {
    if (user.incoming?.calling && !user.incoming?.calling.holdon) {
      if (!incomingSound.current?.playing) {
        incomingSound.current = getQuietIncomingCall()
        incomingSound.current.play()

        showNotification({
          teamId: props.spaceId,
          title: "Incoming Call",
          text: `${user.name} is calling you`,
          imageUrl: user.imageUrl,
          id: "callfrom" + user.id
        })
      }
    } else {
      incomingSound.current?.pause()
    }
  }, [user.incoming, incomingSound.current, user.incoming?.calling?.holdon])


  useEffect(() => {
    if (user.outgoing?.calling) {
      if (!outgoingSound.current?.playing) {
        outgoingSound.current = getQuietOutgoingCall()
        outgoingSound.current.play()
      }
    } else {
      outgoingSound.current?.pause()
    }

  }, [user.outgoing, outgoingSound.current])

  useEffect(() => {

    return () => {
      hideNotification("callfrom" + user.id)

      outgoingSound.current?.pause();
      incomingSound.current?.pause();

    }
  }, [])

  const onDoubleClick = useCallback(() => {
    openUserChat(props.spaceId, props.userId)
    Analytics.logEvent("Button_DoubleClickDockUser", { userId: props.userId })
  }, [openUserChat, props.spaceId, props.userId])

  const onContextMenu = useCallback((e) => {
    e.preventDefault();
    openUserMenu(props.spaceId, props.userId);
    Analytics.logEvent("Button_RightClickDockUser", { userId: props.userId })
  }, [openUserMenu, props.spaceId, props.userId])

  const isActive = (
    user.alerts || 
    user.incoming?.calling ||
    user.outgoing?.calling || 
    user.outgoing?.knocking || 
    user.incoming?.callRequest?.receiverActive ||
    user.outgoing?.callRequest?.senderActive);

  const forceShow = !!(isActive || (isTutorial && tutorialState.showDummyUserPanel))

  return (
    <DockIconBase
      id={`user-${user.id}`}
      data-tooltip={`${props.spaceId}:${user.id}`}
      data-tooltip-for='dock-user'
      isOpen={isOpen}
      unread={numUnread > 0}
      forceShow={forceShow}
      size={props.size}
      //subwindowProps={{onBlur: () => null}}
      renderIcon={(showingWindow: boolean) => (
        <>
          <UserIcon
            key={user.id}
            user={user}
            badgeNum={numUnread}
            dotSize={Math.max(7, Math.round(props.size / 8))}
            badgeOverride={space.userChats[props.userId]?.hasMention ? '@' : undefined}
            showCustomEmoji={user.status?.online}
          />
          {
            props.showSpaceIcon ?
              <CirclePosition positionDegrees={225} offsetFactor={0.94} >
                <SpaceIcon spaceId={props.spaceId} tileSize={props.size / 5 + 8} />
              </CirclePosition>
              :
              null
          }
        </>

      )}
      renderContents={
        (
          closeWindow: (onCloseCallback?: () => void) => void,
          windowId: string,
          openWindow: () => void,
        ) => {
          if (user.outgoing?.callRequest?.senderActive) {
            return <OutgoingCallRequestPanel spaceId={props.spaceId} userId={props.userId} />
          } else if (user.incoming?.callRequest?.receiverActive) {
            return <IncomingCallRequestPanel spaceId={props.spaceId} userId={props.userId} />
          } else if (user.outgoing?.calling) {
            return <OutgoingCallPanel spaceId={props.spaceId} userId={props.userId} />
          } else if (user.incoming?.calling) {
            return <IncomingCallPanel spaceId={props.spaceId} userId={props.userId} />
          } else if (user.alerts?.called || user.alerts?.knocked) {
            return <AlertPanel user={user} openWindow={openWindow} />
          } else {
            return (
              <DockUserPanel
                spaceId={props.spaceId}
                userId={props.userId}
                closeWindow={closeWindow}
              />
            )
          }
        }}
      onContextMenu={onContextMenu}
      onDoubleClick={onDoubleClick}
    />
  )

})



const OutgoingCallRequestPanel: React.FC<{ spaceId: string, userId: string }> = observer((props) => {
  const space = DataState.spaces[props.spaceId]
  const isTutorial = props.userId == 'dummy'
  const user = isTutorial ? getDummyUser(props.userId) : space.users[props.userId]
  const [reqMsg, setReqMsg] = useState(user.outgoing?.callRequest?.msg || "");
  const [savedMessage, setSavedMessage] = useState(false);
  const theme = useTheme()

  const callRequest = user.outgoing?.callRequest

  logger.debug(`Call Request`, toJS(callRequest));

  if (callRequest) {

    const setCallReqMsgDebounce = useCallback(debounce(500, false, async (msg) => {
      await callRequest.setMsg(msg)
      setSavedMessage(true)
    }), [callRequest, setSavedMessage])

    const setCallReqMsg = (e) => {
      setSavedMessage(false)
      setReqMsg(e.target.value);
      setCallReqMsgDebounce(e.target.value)
    }


    const roomInvite = !!callRequest.roomId
    const receiverActive = callRequest.receiverActive
    const receiverLater = callRequest.status == 'recvlater'

    const icon = callRequest.roomMode === 'audio' ? DS2Icons.audio : DS2Icons.video;
    
    return (
      <DockNotifyPanel
        user={user}
        title={receiverLater ? `${user.name} can't talk right now...` : roomInvite? `Inviting ${user.name} to join...`: `Inviting ${user.name} to talk...` }
        desc={callRequest.holdon ? `Please hold on` : undefined}
        rightIcon={
          <Ringer animate={callRequest.receiverActive} >{icon}</Ringer>
        }
        buttons={

          <DSRow spacing={8} style={{ justifyContent: 'space-between'}}>
            {
              receiverActive ?

                <DSRow spacing={8} style={{justifyContent: 'space-between', width: '100%'}}>
                  {
                    !roomInvite ?
                      <DS2Button
                        noBadge
                        color="secondary"
                        style={{ flexGrow: 1 }}
                        title="Call me when you're free"
                        onClick={() => { callRequest.whenFree() }}
                      />
                      :
                      null
                  }
                  <DS2CancelCallButton
                    title="Cancel"
                    style={{ flexGrow: 1 }}
                    fullWidth={true}
                    onClick={() => { callRequest.cancel() }}
                  />
                </DSRow>
                :
                <DS2CloseButton
                  color="secondary"
                  style={{ flexGrow: 1 }}
                  title="Close"
                  onClick={() => { callRequest.clear() }}
                />
            }
          </DSRow>
        }
      >
        {
          !receiverLater ?
          <DSRow style={{alignItems: 'center'}}>
            <DSInput
              style={{
                flexGrow: 1,
                backgroundColor: 'transparent',
              }}
              outerStyle={{
                //backgroundColor: theme.palette.secondary.main,
                // color: theme.palette.secondary.contrastText,
                height: 28,
                flex: 1,
                }}
                value={reqMsg}
                placeholder={"What do you want to talk about?"}
                onChange={setCallReqMsg}
              />
              <DSRow style={{paddingLeft: 8, width: 25, alignItems: 'center', justifyContent: 'center'}}>
                {
                  reqMsg ?
                    savedMessage ?
                      <div style={{ borderRadius: 999, border: `1px solid ${theme.palette.success.main}`, padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <FaCheck size={10} color={theme.palette.success.main} />
                      </div>
                      :
                      <DSSpinner size={16} />
                    :
                    null

                }
              </DSRow>
            </DSRow>
            :
          undefined
        }
  

      </DockNotifyPanel>
    )
  } else {
    return null;
  }
})




const IncomingCallRequestPanel: React.FC<{ spaceId: string, userId: string }> = observer((props) => {
  const space = DataState.spaces[props.spaceId]
  const isTutorial = props.userId == 'dummy'
  const user = isTutorial ? getDummyUser(props.userId) : space.users[props.userId]

  const callRequest = user.incoming?.callRequest

  if (callRequest) {

    const senderActive = callRequest.senderActive
    const senderCancelled = callRequest.status == 'sendcancelled'
    const icon = callRequest.roomMode === 'audio' ? DS2Icons.audio : DS2Icons.video;

    return (
      <DockNotifyPanel
        user={user}
        title={senderActive ?
          callRequest.roomId
            ?
            `${user.name} is inviting you \n${space.calls[callRequest.roomId] ? ' to ' + space.calls[callRequest.roomId].name : ''}`
            :
            `${user.name} is inviting you to talk`
          :
          senderCancelled
            ?
            `${user.name} cancelled request to talk` 
            :
            `${user.name} invited you to talk`
        }
        desc={callRequest.holdon ? 'Hold on' : undefined}
        rightIcon={
          <Ringer animate={callRequest.senderActive} >{icon}</Ringer>
        }
        buttons={
              senderActive ?
                <DSRow spacing={8} style={{width: "100%"}}>
                  <DS2AcceptButton fullWidth title="Answer" onClick={user.incoming?.callRequest?.answer} />
                  <DS2HoldButton fullWidth onClick={user.incoming?.callRequest?.wait} disabled={callRequest.holdon}/>
                  <DS2RejectButton fullWidth title="Later" onClick={user.incoming?.callRequest?.later} />
                </DSRow>
                :
                senderCancelled ?
                  <DS2CloseButton
                    color="secondary"
                    onClick={user.incoming?.callRequest?.clear}
                  />
                  :
                  <DSRow spacing={8} style={{justifyContent: 'space-between'}}>
                    <DS2Button
                      color="secondary"
                      title="I'm free now"
                      onClick={user.incoming?.callRequest?.rerequestCall}
                    />
                    <DS2RejectButton title="Later" onClick={user.incoming?.callRequest?.clear} />
                  </DSRow>

            }
      >
        {
          callRequest.msg ?
            <DSPanel style={{
              alignItems: 'center',
              backgroundColor: setAlpha(DSTheme.DockPanelBackgroundColor, 0.2),
              borderRadius: 4,
              padding: 4,
            }}>
              <DSBody>
                {callRequest.msg}
              </DSBody>
            </DSPanel>
            :
            null
        }

      </DockNotifyPanel>
    )
  } else {
    return null;
  }
})



const OutgoingCallPanel: React.FC<{ spaceId: string, userId: string }> = observer((props) => {
  const space = DataState.spaces[props.spaceId]
  const isTutorial = props.userId == 'dummy'
  const user = isTutorial ? getDummyUser(props.userId) : space.users[props.userId]

  if (user.outgoing?.calling) {
    return (
      <DockNotifyPanel
        user={user}
        title={`Calling ${user.name}`}
        desc={user.outgoing.calling?.holdon ? `Please hold on` : undefined}
        rightIcon={
          <DSPanel style={{
            width: 20,
            height: 20,
            borderRadius: 4,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: DSTheme.SuccessColor,
            color: 'white'
          }} >{DS2Icons.call}</DSPanel>
        }
        buttons={
          <DSRow style={{ justifyContent: 'space-between' }}>
            <DS2CancelCallButton
              style={{ flexGrow: 1 }}
              fullWidth={true}
              onClick={() => { user.actions.callUser() }}
            />
          </DSRow>
        }
      />
    )
  } else {
    return null;
  }
})


const IncomingCallPanel: React.FC<{ spaceId: string, userId: string }> = observer((props) => {
  const space = DataState.spaces[props.spaceId]
  const isTutorial = props.userId == 'dummy'
  const user = isTutorial ? getDummyUser(props.userId) : space.users[props.userId]

  if (user.incoming?.calling) {
    return (
      <DockNotifyPanel
        user={user}
        title={user.roomId
          ? `${user.name} is inviting you \n${space.calls[user.roomId] ? ' to ' + space.calls[user.roomId].name : ''}`
          : `${user.name} is calling`
        }
        desc={user.incoming.calling.holdon ? 'Hold on' : undefined}
        rightIcon={
          <DSPanel style={{
            width: 20,
            height: 20,
            borderRadius: 4,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: DSTheme.SuccessColor,
            color: 'white'
          }} >{DS2Icons.call}</DSPanel>
        }
        buttons={
          <DSRow spacing={8}>

            <DS2AcceptButton onClick={user.incoming?.calling?.answer} />
            {
              !user.incoming.calling.holdon ?
                <DS2HoldButton onClick={user.incoming?.calling?.wait} />
                :
                undefined
            }
            <DS2RejectButton onClick={user.incoming?.calling?.cancel} />
          </DSRow>
        }
      />
    )
  } else {
    return null;
  }
})


type AlertPanelProps = {
  user: IUISpaceUser;
  openWindow: () => void
}


const AlertPanel: React.FC<AlertPanelProps> = ({ user, openWindow }) => {
  const alertType = user.alerts?.called ? 'called' : 'knocked';
  const alertTime = user.alerts?.called ? user.alerts?.called?.timestamp : user.alerts?.knocked?.timestamp;

  return (
    <DockNotifyPanel
      user={user}
      title={`${user.name} ${alertType}`}
      time={alertTime}
      buttons={
        <DSRow spacing={8}>
          <DS2Button
            color="success"
            style={{ flexGrow: 1 }}
            fullWidth={true}
            startIcon={DS2Icons.reply}
            onClick={() => {
              user.alerts?.clear();
              openWindow();
            }}>Reply</DS2Button>
          <DS2Button
            startIcon={DS2Icons.reject}
            style={{ flexGrow: 1 }}
            fullWidth={true}
            onClick={() => user.alerts?.clear()}
          >
            Dismiss
          </DS2Button >
        </DSRow>
      }

    />
  )
}


const Ringer = styled('div')<{animate: boolean}>(({theme, animate}) => ({
  width: 20,
  height: 20,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.success.main,
  color: 'white',
  animation: animate ? "ringing 2500ms ease-in-out infinite" : undefined,

  "@keyframes ringing": {
    "from, 0%, 20%, 40%, 60%, to": {
      transform: "scale(1)",
      boxShadow: 'none'
    },

    "10%, 30%, 50%": {
      transform: "scale(1.1)",
      boxShadow: "0 0 0 3em rgba(rgba(0,0,0, 1), 1)"
    },

  }

}))


import { KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import React from 'react';
import { DataState } from '../../Data/DataState';
import { DS2Switch, DSRow } from '../../DesignSystem';
import { DSH3, DSPrint } from '../../DesignSystem/DSText';


export const WorkspaceNotificationSettings: React.FC<{
  spaceId: KSpaceId
}> = observer(({ spaceId }) => {

  const space = DataState.spaces[spaceId];

  return (
    <div>
      <DSH3 style={{ marginBottom: 12 }}>Notify me when</DSH3>
      <DSRow style={Styles.settingRow}>
        <DSPrint>When a teammate comes online</DSPrint>
        <DS2Switch
          analyticsEvent={`DockSettingsNotifyUserOnline${space.settings.notifyUserOnline ? 'Off' : 'On'}`}
          checked={space.settings.notifyUserOnline} onChange={(checked) => space.actions.updateTeamOnlineNotify?.(checked)} />
      </DSRow>
      <DSRow style={Styles.settingRow}>
        <DSPrint>When a teammate joins a room</DSPrint>
        <DS2Switch
          analyticsEvent={`DockSettingsNotifyUserRoom${space.settings.notifyRoomJoin ? 'Off' : 'On'}`}
          checked={space.settings.notifyRoomJoin} onChange={(checked) => space.actions.updateRoomJoinNotify?.(checked)} />
      </DSRow>
    </div>
  );
});


const Styles = {
  settingRow: {
    marginTop: 12,
    justifyContent: "space-between",
  },
}

import React, { DragEventHandler, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { DSPanel, DSRow } from '../../DesignSystem';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { CallDetailsDb } from '@openteam/app-core';
import { H2, styled, VSpacer } from '@openteam/design-system';
import { IMessageManager, KSpaceId, ILinkPreview, IFile, ICallDetails } from '@openteam/models';
import { FaUpload } from 'react-icons/fa';

import { Logger } from '@openteam/app-util';
import { DataState } from '../../Data/DataState';
import { Droppable } from '../DragAndDrop';
import { DSSpinner } from '../../DesignSystem/DSSpinner';
import * as Fire from '../../Controllers/Fire';
import { getFriendlyDate } from '../../Util/DateFormat';

import { DSBody, DSPrint } from '../../DesignSystem/DSText';
import { SpacePanelCallIcon } from '../Space/SpacePanelIcons';
import { LightBoxEntry } from '../Lightbox/Lightbox';
import { ChatMessages } from '../Chat/ChatMessages';
import { ChatCompose } from '../Chat/ChatCompose';
import ChatComposeWrapper from '../Chat/ChatComposeWrapper';
import CallChatCompose from './CallChatCompose';
const logger = new Logger('RoomCallChat');

interface IRoomCallChatProps {
  spaceId: KSpaceId;
  callId: string;
  callMessageManager: IMessageManager;
  readOnly?: boolean;
  windowId?: string;
  showLightbox?: (items: LightBoxEntry[], initialSelected?: number) => void;
  sendURL?: (url: string, systemMessage: string) => void;
  setDisableOnBlur?: (disable: boolean) => void
}

const CallChat = (props: IRoomCallChatProps) => {
  const _chatRef = React.useRef<HTMLDivElement>(null);
  const _composeRef = React.useRef<{ focus: () => void }>(null);

  const space = DataState.spaces[props.spaceId];

  const focusInput = () => {
    _composeRef.current?.focus();
  };

  const [isOver, setIsOver] = useState<boolean>(false);
  const onDrop: DragEventHandler = (e) => {
    setIsOver(false);

    if (!e.dataTransfer.files) {
      return;
    }

    Object.keys(e.dataTransfer.files || {}).forEach((i) => {
      let file = e.dataTransfer.files[i];

      props.callMessageManager.addDraftFiles([file]);
    });
  };

  const getUser = (userId: string) => {
    return props.callMessageManager.users[userId];
  };

  const saveLinkToChat = async (id: string, linkPreview: ILinkPreview, linkId: string | undefined) => {
    await props.callMessageManager.saveLinkPreviewToChat(id, linkId);

    const systemMessage = props.callMessageManager.crDate
      ? `added from call @ ${getFriendlyDate(props.callMessageManager.crDate)}`
      : 'added from call';

    props.sendURL?.(linkPreview.url, systemMessage);
  };

  const saveAttachmentToChat = async (id: string, file: IFile, fileId: string) => {
    logger.debug('saveAttachmentToChat', file.url);
    await props.callMessageManager.saveAttachmentToChat(id, fileId);

    const systemMessage = props.callMessageManager.crDate
      ? `added from call @ ${getFriendlyDate(props.callMessageManager.crDate)}`
      : 'added from call';

    props.sendURL?.(file.url, systemMessage);
  };

  if (!props.callMessageManager.started) {
    return (
      <DSPanel
        ref={_chatRef}
        style={{
          flex: 1,
          minHeight: 0,
          minWidth: 0,
          color: DSTheme.MainText,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <DSSpinner />
        <VSpacer size={16} />
        <H2>Please wait, connecting to call chat</H2>
      </DSPanel>
    );
  }

  return (
    <DSPanel
      ref={_chatRef}
      style={{
        flex: 1,
        minHeight: 0,
        minWidth: 0,
        color: DSTheme.MainText,
        userSelect: 'text',
      }}
      disableDrag
    >
      <Droppable
        acceptItemType={'Files'}
        onDrop={onDrop}
        onDragOver={(e) => setIsOver(true)}
        onDragLeave={(e) => setIsOver(false)}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0
        }}
      >
        {isOver ? (
          <DragOverlay>
            <FaUpload size={40} style={{ color: 'red', pointerEvents: 'none' }} />
          </DragOverlay>
        ) : null}

        <DSPanel
          style={{
            position: 'relative',
            borderRadius: 0,
            flex: 1,
            minHeight: 0,
            minWidth: 0,
            maxWidth: '100%'
          }}
        >
          <ChatMessages
            spaceId={props.spaceId}
            messages={props.callMessageManager.messages}
            atStart={true}
            pendingMessages={props.callMessageManager.pendingMessages}
            header={
              <DSRow style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: 4 }}>
                <SpacePanelCallIcon size={12} />
                <DSBody style={{ marginLeft: 8 }}>
                  Messages in this chat will only be shown to members of this call.
                </DSBody>
              </DSRow>
            }
            messageId={props.callMessageManager.messageId}
            lastReadMessageId={props.callMessageManager.lastReadMessageId}
            messageNum={props.callMessageManager.messageNum}
            messageNumRead={props.callMessageManager.messageNumRead}
            numUnread={props.callMessageManager.numUnread}
            isWatching={props.callMessageManager.started}
            deleteMessage={props.callMessageManager.deleteChatMessage}
            editMessage={props.callMessageManager.editChatMessage}
            setReply={props.callMessageManager.setDraftReplyMessage}
            saveLinkToChat={saveLinkToChat}
            saveAttachmentToChat={saveAttachmentToChat}
            showLightbox={props.showLightbox}
            sendURL={props.sendURL}
            focusInput={focusInput}
            readOnly={props.readOnly}
            getUser={getUser}
          />
          {!props.readOnly ? (
            <CallChatCompose
              windowId={props.windowId}
              messageManager={props.callMessageManager}
              setDisableOnBlur={props.setDisableOnBlur}
            />
          ) : null}
        </DSPanel>
      </Droppable>
    </DSPanel>
  );
};

export default observer(CallChat);

const DragOverlay = styled('div')(() => ({
  position: 'absolute',
  inset: 0,
  backgroundColor: 'rgba(255,255,255,0.7)',
  zIndex: 9,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

import React, { memo, useState } from "react";
import { Logger } from "@openteam/app-util";
import { CSSProperties } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { isMacOs, isWindows } from "react-device-detect";
import { DSPanel } from "../DesignSystem";
import { observer } from "mobx-react";
import { DSTheme } from "../DesignSystem/DSTheme";
import { debounce } from "throttle-debounce";

const logger = new Logger("Draggable");

type Props = {
  windowId: string;
  disabled?: boolean;
  show?: boolean;
  style?: CSSProperties;
  dragbar?: JSX.Element;
  dragbarStyle?: CSSProperties;
  onMoving?: (boolean) => void;
  forceNative?: boolean
  forceNonNative?: boolean
};

export const Draggable: React.FC<Props> = ({ windowId, style, onMoving, forceNative = false, forceNonNative = false, children, ...props }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [moving, setMoving] = useState(false);
  const start = useRef<any>(null);
  const enabled = props.disabled !== true;

  const startMove = React.useCallback<React.PointerEventHandler<HTMLDivElement>>(
    (e) => {
      const isUs = e.currentTarget && e.currentTarget === ref.current;

      if (enabled && isUs && ((e.pointerType === "mouse" && e.button == 0) || e.pointerType === "touch")) {
        e.stopPropagation();
        const evRef = e.currentTarget as HTMLDivElement;
        //logger.debug(`startMove`, e.currentTarget, ref.current)
        evRef.setPointerCapture(e.pointerId);
        start.current = [e.clientX, e.clientY];
        logger.debug(`start Mov ${windowId} at ${e.screenX},${e.screenY} ${e.clientX},${e.clientY}`);
        setMoving(true);
      }
    },
    [enabled, windowId, setMoving, start]
  );

  const endMove = React.useCallback<React.PointerEventHandler<HTMLDivElement>>((e) => {
    if (ref.current?.hasPointerCapture(e.pointerId)) {
      ref.current.releasePointerCapture(e.pointerId);
    }
  }, []);

  const gotCapture = React.useCallback<React.PointerEventHandler<HTMLDivElement>>(
    (e) => {
      if (!windowId) {
        logger.warn(`window id not defined, unable to drag window`);
      }
    },
    [windowId, start, setMoving]
  );

  const lostCapture = React.useCallback<React.PointerEventHandler<HTMLDivElement>>(
    (e) => {
      start.current = null;
      logger.debug(`lost Capture ${windowId}`);
      setMoving(false);
    },
    [start, setMoving]
  );

  //const moveWindow = React.useCallback(debounce(10, (x, y) => window.Main.moveWindow(windowId, x, y)), [windowId])

  const pointerMove = React.useCallback<React.PointerEventHandler<HTMLDivElement>>(
    (e) => {
      if (start.current) {
        const [startX, startY] = start.current;
        if (Math.abs(startX - e.clientX) >= 1 || Math.abs(startY - e.clientY) >= 1) {
          window.Main.moveWindow(windowId, e.screenX - startX, e.screenY - startY);
        }
      }
    },
    [start, windowId]
  );

  useEffect(() => {
    onMoving && onMoving(moving);
  }, [moving, onMoving]);

  useEffect(() => {
    logger.debug(`${windowId} Dragging enabled ${enabled} props.enabled ${props.disabled}`)
  }, [enabled, props.disabled])

  const useBar = enabled && isWindows && (props.dragbarStyle || props.dragbar);
  if (useBar) {

    const dragStyle = enabled ? { cursor: "grab", WebkitAppRegion: "drag" } : null;
    return (
      <div
        style={{
          position: "relative",
          WebkitUserSelect: "none",
          ...style,
        }}
      >
        {props.dragbar ?? <DragBar style={{ ...dragStyle, ...props.dragbarStyle }} />}
        {children}
      </div>
    )
  } else if (!forceNonNative && (isMacOs || isWindows || forceNative)) {

    const dragStyle = enabled ? { cursor: "grab", WebkitAppRegion: "drag" } : null
    return (
      <div
        style={{
          ...dragStyle,
          WebkitUserSelect: "none",
          ...style,
        }}
      >
        {children}
      </div>
    );
  } else {
    const dragStyle = enabled ? { cursor: moving ? "grabbing" : "grab" } : null
    return (
      <div
        ref={ref}
        onPointerDown={startMove}
        onPointerUp={endMove}
        onPointerCancel={endMove}
        onPointerMove={pointerMove}
        onGotPointerCapture={gotCapture}
        onLostPointerCapture={lostCapture}
        style={{
          WebkitUserSelect: "none",
          ...dragStyle,
          ...style,
        }}
      >
        {children}
      </div>
    );
  }
};


const DragBar: React.FC<{ style?: CSSProperties }> = observer(({ style }) => (

  <DSPanel
    style={{
      position: "absolute",
      inset: 0,
      ...style,
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" >
      <defs>
        <pattern id="Pattern" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
          <circle cx="5" cy="5" r="3" fill="white" fill-opacity="0.35" />
        </pattern>
      </defs>
      <rect fill="url(#Pattern)" width="100%" height="100%" />
    </svg>

  </DSPanel>
));

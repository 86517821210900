import { OTUITree } from "@openteam/app-core";
import { observer } from "mobx-react-lite";
import React from "react";
import { zoomConfig } from "../../config";
import { darken } from "@mui/material";
import { DS2Button, DS2ButtonProps, styled } from "../../DesignSystem";
import { FaVideo } from "react-icons/fa";


const ZoomAuthButton: React.FC<DS2ButtonProps> = ({title="Connect", ...props}) => {

  const zoomManager = OTUITree.userManager.zoomManager;
  const authUrl = zoomManager.getAuthUrl({ ...zoomConfig, userId: OTUITree.auth.userId })

  return (
    <ZoomButton
      startIcon={<FaVideo />}
      onClick={() => window.Main.shellOpenExternal(authUrl)}
      {...props}
    >
     {title}
    </ZoomButton>
  )
}

export default observer(ZoomAuthButton)


const ZoomButton = styled(DS2Button)((theme) => ({
  backgroundColor: '#2196f3',
  padding: "4px 10px",
  ':hover': {
    backgroundColor: darken('#2196f3', 0.1)
  },
}))

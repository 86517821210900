import { Logger } from '@openteam/app-util';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ArcPosition } from '../../Util/CirclePosition';
import { toDeg } from '../../Util/degToPos';

const logger = new Logger('ArcSubTile');

interface ArcSubTileProps {
  userId: string;
  hovered: boolean;
  tileSize: number;
  itemSize: number;
}

const ArcSubTile: React.FC<ArcSubTileProps> = observer(({ userId, tileSize, itemSize, hovered, children }) => {
  const radius = tileSize / 2;

  let positionDegrees = 130;
  const hoveredClearAngle = toDeg(Math.acos((radius - 20 - itemSize / 2) / radius));
  const hoveredStart = 180 - hoveredClearAngle;

  if (hovered) {
    positionDegrees = Math.min(positionDegrees, hoveredStart);
  }

  logger.debug(`Rendering subtile for ${userId} at ${positionDegrees}deg`);
  return (
    <ArcPosition radius={radius} itemSize={itemSize} positionDegrees={positionDegrees} offsetFactor={0.8}>
      {children}
    </ArcPosition>
  );
});

export default ArcSubTile;

import { OTUITree } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { DSButton } from "../../DesignSystem/DSButton";
import { DSColumn, DSRow } from "../../DesignSystem";
import { DSH1, DSH3 } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { OnboardingHeader } from "./OnboardingHeader";
import * as Analytics from '../../Util/Analytics'

const logger = new Logger("ConnectContent")

export const ConnectContent = observer((props: React.PropsWithChildren<{}>) => {

  useEffect(() => {
    Analytics.logEvent("Screen_ConnectContent")
  }, [])


  const connect = () => {
    OTUITree.appHomeUI.start()
  }
  return (
    <OnboardingHeader>

      <DSColumn style={{ alignItems: 'center' }}>

        <DSH1 style={{ fontSize: 30, }}>
              Already logged in?
        </DSH1>

        <DSH3 wrap style={{ textAlign: "center", width: 250, marginTop: 70, marginBottom: 70 }}>
          Looks like you're logged in somewhere else
        </DSH3>

        <DSButton
          analyticsEvent={`loginConnect`}
          style={{
            backgroundColor: DSTheme.EmphasisColor,
            color: DSTheme.MainText,
            width: 120,
            height: 34,
            borderRadius: 6,
            marginBottom: 30
          }}
          onClick={connect}
          title="Continue here" />

      </DSColumn>
    </OnboardingHeader>
  );
});

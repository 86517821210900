import React, { useCallback, useEffect } from "react";
import { KSpaceId } from "@openteam/models"
import { observer } from "mobx-react"
import { openSearch, setDockComponentLength, UIState } from "../../Data/UIState";
import { FaPlus, FaSearch } from "react-icons/fa";
import { styled, DS2Icons } from "../../DesignSystem/DS2";
import { ButtonUnstyled, ButtonUnstyledProps } from '@mui/base';
import { OTUITree } from "@openteam/app-core";
import { DataState } from "../../Data/DataState";
import { DockIconBase } from "./DockIconBase";
import { Menu } from "../Menu/Menu";
import * as Analytics from '../../Util/Analytics'
import { ZoomIcon } from "../Zoom";
import { zoomConfig } from "../../config";
import { setAlpha } from "@openteam/design-system";
import DockControlButton, { DockControlTile } from "./DockControlButton";


interface IDockHomeIconProps {
  spaceId: KSpaceId
  horizontal: boolean;
}

export const DockHomeIcon = observer(({ spaceId, horizontal }: IDockHomeIconProps) => {
  const tileSizeRatio = 0.35;
  const itemLengthRatio = 1 / 2;

  const tileSize = Math.floor(tileSizeRatio * UIState.dockSize);
  const itemLength = Math.floor(itemLengthRatio * UIState.dockSize);
  const zoomManager = OTUITree.userManager.zoomManager;
  const zoomAuthUrl = zoomManager.getAuthUrl({ ...zoomConfig, userId: OTUITree.auth.userId })

  const space = DataState.spaces[spaceId]

  const menuItems = [
    {
      icon: DS2Icons.call,
      label: "Start a call",
      click: () => {
        space.actions.startUserRoom?.();
      },
    },
    {
      icon: DS2Icons.link,
      label: "Copy link",
      click: () => {
        const meetingLink = space.users[OTUITree.auth.userId].meetingLink

        if (meetingLink) {
          window.Main.clipboardWriteText(meetingLink)
        }
      },
    },
  ]

  if (zoomManager.isAuthorised) {
    menuItems.push({
      icon: <ZoomIcon />,
      label: 'Start Zoom Call',
      click: () => window.Main.shellOpenExternal(zoomManager.personalLink)
    })
  } else {
    menuItems.push({
      icon: <ZoomIcon />,
      label: 'Connect to Zoom ',
      click: () => window.Main.shellOpenExternal(zoomAuthUrl)
    })
  }

  useEffect(() => {
    setDockComponentLength(`DockDockIcon`, itemLengthRatio);
    return () => setDockComponentLength(`DockDockIcon`, 0);
  }, [itemLengthRatio]);


  return (
    <div style={{
      display: 'flex',
      width: horizontal ? itemLength : itemLength * 2,
      height: horizontal ? itemLength * 2 : itemLength,
      minWidth: horizontal ? itemLength : itemLength * 2,
      minHeight: horizontal ? itemLength * 2 : itemLength,
      alignItems: 'center',
      flexDirection: horizontal ? 'column' : 'row',
      justifyContent: 'center',
      position: 'relative',
      //padding: itemLength * 0.1,
      boxSizing: 'border-box'
    }}>


      <div style={{ display: 'flex' }}>
        <DockIconBase
          id={`home-${spaceId}`}
          size={tileSize}
          renderIcon={(showingWindow: boolean) => (
            <DockControlTile
              data-tooltip='New call'
              style={{
                width: horizontal ? tileSize : undefined,
                height: horizontal ? undefined : tileSize,
                aspectRatio: '1/1',
                padding: 0,
              }}
            >
              <FaPlus size="60%" />
            </DockControlTile>
          )}
          renderContents={() => <Menu template={menuItems} />}
        />
      </div>
      <div style={{ height: '10%', width: '10%' }} />

      <DockControlButton
        data-tooltip='Search'
        analyticsEvent="DockOpenSearch"
        onClick={openSearch}
        style={{
          width: horizontal ? tileSize : undefined,
          height: horizontal ? undefined : tileSize,
          aspectRatio: '1/1',
          padding: 0,
        }}
      >
        <FaSearch size="60%" />
      </DockControlButton>
    </div>
  )

})

import { action, reaction, runInAction, toJS } from "mobx";
import { UIState, getDockSize, TDockEdge, setDockAutoHide, setDockItemSize, calcDockLength, setDockHorizontal, setDockEdge, setDockPosition } from "../Data/UIState";

import { isMacOs, isWindows } from "react-device-detect";

import { AwaitLock, Logger} from "@openteam/app-util";
import { DSTheme, ThemeState } from "../DesignSystem/DSTheme";
import { applyObjectUpdate2 } from "@openteam/app-core";
import Sentry from "./Sentry";
import { debounce } from "throttle-debounce";
import { getDisplayEdges, getMaxDockLength, positionInDisplay } from "./DockWindowUtil";
import { systemPreferences } from "electron";
import { platform} from '../Util/System'

export const useWindowHide = isMacOs
export const dockWindowId = "__dock__"

const logger = new Logger("DockWindow");

const edgeLeeway = 10;

const _REACTION_DISPOSERS: (() => void)[] = [];

export async function initDock() {
  window.Main.setAppAlwaysOnTopFS(true)
  window.Main.setIgnoreMouseEvents(false)

  if (UIState.mainCapabilities.vibrancy && ThemeState.selectedTheme === 'SYSTEM') {
    window.Main.setDockVibrancy('hud')
  }

  _REACTION_DISPOSERS.forEach(f => f());
  _REACTION_DISPOSERS.length = 0;




  window.Main.on("display-added", (data) => {
    logger.debug("display-added")
    onDisplayChange();
  })
  window.Main.on("display-removed", (data) => {
    logger.debug("display-removed")
    onDisplayChange();
  })

  window.Main.on("display-metrics-changed", (data) => {
    logger.debug("display-metrics-changed")
    onDisplayChange();
  })


  _REACTION_DISPOSERS.push(reaction(() => [ThemeState.selectedTheme, UIState.dockShown], () => {
    logger.debug(`ThemeState.selectedTheme ${ThemeState.selectedTheme}`)
    if (UIState.mainCapabilities.vibrancy) {
      if (ThemeState.selectedTheme === 'SYSTEM' && UIState.dockShown) {
        window.Main.setDockVibrancy('hud')
      } else {
        window.Main.setDockVibrancy(null)
      }
    }
  }))


  _REACTION_DISPOSERS.push(reaction(() => UIState.dockShown, () => {
    logger.debug(`UIState.dockShown setdockShape ${UIState.dockShown}`)
    onDockHide()
  }))

  _REACTION_DISPOSERS.push(reaction(() => (
    [UIState.currentDisplay?.workArea ?? null]
  ), (newState) => {

    const [wa] = newState as [Electron.Rectangle | null];
    logger.debug(`display workArea.height ${wa?.height} dockHeight ${UIState.dockLength} `);

    calcDockLength(UIState.dockSelectedSize, true)
  }))

  await onDisplayChange();
  await setInitialPosition();
  window.Main.showWindow(dockWindowId)

  UIState.dockInited = true;
}

async function setInitialPosition() {
  if (UIState.displays) {
    let currDisplay = UIState.primaryDisplay || UIState.displays[0]

    const preferredDisplay = UIState.displays.find((d) => d.id === UIState.dockPosition.displayId);
    if (preferredDisplay) {
      currDisplay = preferredDisplay
    }

    logger.debug("setInitialPosition: currDisplay", toJS(currDisplay))


    const edges = getDisplayEdges(currDisplay)
    let newPos: any = {
      x: UIState.dockPosition.x,
      y: UIState.dockPosition.y
    }
    const isHorizontal = UIState.dockEdge === 'top' || UIState.dockEdge === 'bottom'

    logger.debug("setInitialPosition: UIState.dockEdge", UIState.dockEdge, "UIState.dockPosition", UIState.dockPosition.x, UIState.dockPosition.y)

    if (UIState.dockEdge) {

      if (UIState.dockEdge === 'left' && edges.leftEdge) {
        newPos.x = edges.leftEdge
      } else if (UIState.dockEdge === 'right' && edges.rightEdge) {
        newPos.x = edges.rightEdge
      } else if (UIState.dockEdge === 'top' && edges.topEdge) {
        newPos.y = edges.topEdge
      } else if (UIState.dockEdge === 'bottom' && edges.bottomEdge) {
        newPos.y = edges.bottomEdge
      }

      const bounds = getDockEdgePosition()

      newPos = { ...newPos, ...bounds }

    }

    logger.debug("setInitialPosition: isHorizontal", isHorizontal)
    logger.debug("setInitialPosition: New pos", newPos.x, newPos.y)

    const screenPos = positionInDisplay(newPos, currDisplay)
    logger.debug("setInitialPosition: positionInDisplay", screenPos.x, screenPos.y)

    await setDockBounds({
      ...newPos,
      ...screenPos,
      height: isHorizontal ? UIState.dockSize : UIState.dockLength,
      width: isHorizontal ? UIState.dockLength : UIState.dockSize
    })
  }
}


export function shutdownDock() {
  window.Main.setAppAlwaysOnTopFS(false)
  UIState.dockInited = false;
  _REACTION_DISPOSERS.forEach(f => f());
  _REACTION_DISPOSERS.length = 0;
  window.Main.hideWindow(dockWindowId)
}

export const onDockMoved = action((args) => {

  //logger.debug(`onDockMoved`, args.bounds)

  // Linux doesn't always report correct workArea offset with multiple displays
  // causing the dock window to be shifted down from where we expected.
  // So we should update the position even in docked mode.
  const { bounds, location } = args;

  const updatePosition = {
    ...UIState.dockPosition,
    x: bounds.x,
    y: bounds.y,
    onLeft: location.onLeftSide,
    onTop: location.onTopSide,
    displayId: location.displayId
  }

  setDockPosition(updatePosition)
  UIState.currentDisplay = UIState.displays?.find((display) => display.id === location.displayId);


  syncDockEdge()
  syncDockOrientation() //only change orientation on a pure move

  onDockMoveEnd()
})

export const onDockBounds = action((bounds, location) => {
  logger.debug("onDockBounds", bounds, location)

  const dockPosition = {
    x: bounds.x,
    y: bounds.y,
    width: bounds.width,
    height: bounds.height,
    onLeft: location.onLeftSide,
    onTop: location.onTopSide,
    displayId: location.displayId
  }

  setDockPosition(dockPosition)
  UIState.currentDisplay = UIState.displays?.find((display) => display.id === location.displayId);

  syncDockEdge()
  // need to update the dock shape on linux
  setDockShape()
})



export const onDockHide = action(() => {
  setDockShape()
})

const _displayChangeLock = new AwaitLock()

async function onDisplayChange() {
  await _displayChangeLock.acquireAsync();
  try {
    let currDisplay = await window.Main.getDisplayMatching(toJS(UIState.dockPosition));
    UIState.currentDisplay = currDisplay;

    UIState.displays = await window.Main.getAllDisplays();
    UIState.primaryDisplay = await window.Main.getPrimaryDisplay();

    logger.debug("getAllDisplays", toJS(UIState.displays))


    syncDockEdge();
  } finally {
    _displayChangeLock.release();
  }
}




export async function setDockShape(size?: { height: number, width: number }) {
  let currDisplay = await window.Main.getDisplayMatching(toJS(UIState.dockPosition));
  const { width: winWidth, height: winHeight } = UIState.dockPosition;
  const { width, height } = getDockSize()
  logger.debug(`setting Shape ${UIState.dockEdge} winHeight ${winHeight}, height ${height} winWidth ${winWidth} width ${width}`)

  if (UIState.dockShown) {

    if (isWindows) {
      window.Main.setDockShape([]);
    } else if (useWindowHide) {
      const bounds: any = {}

      if (UIState.dockEdge) {
        if (UIState.dockHorizontal) {
          bounds.y = UIState.dockEdge === 'top' ? currDisplay.workArea.y : currDisplay.workArea.y + currDisplay.workArea.height - UIState.dockSize
        } else {
          bounds.x = UIState.dockEdge === 'left' ? currDisplay.workArea.x : currDisplay.workArea.x + currDisplay.workArea.width - UIState.dockSize
        }

        setDockBounds(bounds)
      }

    } else {
      // Linux doesn't seem to support clearing the shape
      window.Main.setDockShape([{ x: 0, y: 0, width: winWidth, height: winHeight }])
    }
  } else {
    if (useWindowHide) {
      const bounds: any = {}
      if (UIState.dockEdge) {

        if (UIState.dockHorizontal) {
          bounds.y = UIState.dockEdge === 'top' ? currDisplay.workArea.y - (UIState.dockSize - Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)) : currDisplay.workArea.y + currDisplay.workArea.height - Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)
        } else {
          bounds.x = UIState.dockEdge === 'left' ? currDisplay.workArea.x - (UIState.dockSize - Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)) : currDisplay.workArea.x + currDisplay.workArea.width - Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)
        }

        await setDockBounds(bounds)
      }
    } else {
      window.Main.setDockShape([{
        x: UIState.dockEdge === "right" ? winWidth - width : 0,
        y: UIState.dockEdge === "bottom" ? winHeight - height : 0,
        width: Math.ceil(width),
        height: Math.ceil(height)
      }])
    }
  }
}



const syncDockEdge = async () => {
  const bounds = UIState.dockPosition


  const touching: TDockEdge[] = []
  const edges = getDisplayEdges(UIState.currentDisplay)

  if (UIState.dockHorizontal) {
    if (edges.topEdge != undefined && (bounds.y <= edges.topEdge + edgeLeeway)) {
      touching.push("top")
    }

    if (edges.bottomEdge != undefined && (bounds.y + bounds.height >= edges.bottomEdge - edgeLeeway)) {
      touching.push("bottom")
    }


  } else {
    if (edges.leftEdge != undefined && (bounds.x <= edges.leftEdge + edgeLeeway)) {
      touching.push("left")
    }

    if (edges.rightEdge != undefined && (bounds.x + bounds.width >= edges.rightEdge - edgeLeeway)) {
      touching.push("right")
    }
  }

  //logger.debug("syncDockEdge", edges, toJS(bounds), touching)


  if (UIState.dockEdge && !touching.includes(UIState.dockEdge) || !UIState.dockEdge && touching.length > 0) {
    const newDockEdge = touching.length == 0 ? undefined : touching[0]
    setDockEdge(newDockEdge)
    logger.debug(`dockEdge i'm on the ${newDockEdge}`)

    // if (newDockEdge) {
    //   logger.debug("turning off autohide due to new dockEdge", newDockEdge)
    //   setDockAutoHide(false)
    // }
  }


}

const syncDockOrientation = debounce(200, () => {

  if (UIState.dockEdge) {
    return
  }

  const bounds = UIState.dockPosition

  const edges = getDisplayEdges(UIState.currentDisplay)

  let isHorizontal = UIState.dockHorizontal

  if (UIState.dockHorizontal) {
    if (edges.leftEdge != undefined && (bounds.x <= edges.leftEdge + edgeLeeway)) {
      isHorizontal = false
    }

    if (edges.rightEdge != undefined && (bounds.x + (platform === 'linux' ? bounds.width : UIState.dockSize * 2) >= edges.rightEdge - edgeLeeway)) {
      isHorizontal = false
    }

  } else {

    if (edges.topEdge != undefined && (bounds.y <= edges.topEdge + edgeLeeway)) {
      isHorizontal = true
    }

    if (edges.bottomEdge != undefined && (bounds.y + UIState.dockSize * 2 >= edges.bottomEdge - edgeLeeway)) {
      isHorizontal = true
    }
  }

  if (UIState.dockHorizontal != isHorizontal) {
    setDockHorizontal(isHorizontal)
    logger.debug(`i'm ${UIState.dockHorizontal ? 'horizontal' : 'vertical'}`)
    calcDockLength(UIState.dockSelectedSize, true)
  }
})

// const onDockEdge = debounce(200, (isHorizontal: boolean) => {

//   if (UIState.dockHorizontal != isHorizontal) {
//     setDockHorizontal(isHorizontal)
//     logger.debug("im horizontal", UIState.dockHorizontal)
//     calcDockLength(UIState.dockSelectedSize, true)
//   }
// })

const onDockMoveEnd = debounce(500, () => {
  logger.debug(`onDockMovedEnd UIState.dockEdge: ${UIState.dockEdge} && UIState.dockShown: ${UIState.dockShown}, currDisplay:`, toJS(UIState.currentDisplay))

  if ((UIState.dockEdge || platform === 'linux')) {

    const pos = UIState.dockPosition
    const newPos = positionInDisplay(pos, UIState.currentDisplay, edgeLeeway)

    if (Object.keys(newPos).length > 0) {
      logger.debug("onDockMoveEnd off screen", newPos)
      const bounds = getDockEdgePosition(UIState.currentDisplay)

      setDockBounds({ ...newPos, ...bounds })
    }
  } else {
    const pos = { ...UIState.dockPosition }

    const controlsLength = Math.round(UIState.dockSize * DSTheme.DockControlsRatio)
    if (UIState.dockHorizontal) {
      pos.width = controlsLength
    } else {
      pos.height = controlsLength
    }
    const newPos = positionInDisplay(pos, UIState.currentDisplay, edgeLeeway)
    if (Object.keys(newPos).length > 0) {
      logger.debug("dock controls are off the screen, move them back on", newPos)
      setDockBounds(newPos)
    }
  }
})






const setDockBounds = async (bounds, hideMove: boolean = false, disableAnimation: boolean = false) => {
  if (Object.entries(bounds).filter(([k, v]) => v !== UIState.dockPosition[k]).length) {
    try {
      logger.debug(`setDockBounds:`, bounds)
      const newPosition = await window.Main.setDockWindowBounds(bounds, hideMove, disableAnimation);
      if (newPosition) {
        onDockBounds(newPosition.bounds, newPosition.location)
      }
    } catch (err) {
      Sentry.captureMessage(`setDockWindowBounds returned ${err}, bounds:`, bounds)
    }
    runInAction(() => UIState.dockScrollVersion++);
  }
}


export const setDockLength = action((length: number, size: number, force: boolean = false) => {

  if (!UIState.dockInited) {
    return
  }
  if (UIState.dockLength !== length || force || UIState.dockSize != size) {
    const isHorizontal = UIState.dockHorizontal
    const maxLength = getMaxDockLength(length, isHorizontal);

    if (UIState.dockLength != maxLength) {
      UIState.dockLength = maxLength
    }

    if (UIState.dockSize != size) {
      UIState.dockSize = size
    }

    logger.debug(`setDockLength UIState.currentDisplay ${UIState.currentDisplay?.id} newBounds `, { size: size, length: maxLength })

    const bounds = getDockEdgePosition()

    if (isHorizontal) {
      bounds.width = Math.floor(maxLength)
      bounds.height = Math.floor(size)
    } else {
      bounds.width = Math.floor(size)
      bounds.height = Math.floor(maxLength)
    }

    setDockBounds({
      ...bounds,
    }, false, force)

  }
})


export function getDockEdgePosition(display?: Electron.Display) {
  let currDisplay = display || UIState.currentDisplay
  const bounds: any = {}
  logger.debug(`getDockEdgePosition, UIState.dockShown ${UIState.dockShown}  UIState.dockEdge ${UIState.dockEdge} `)

  if (useWindowHide && currDisplay) {
    if (UIState.dockShown) {

      if (UIState.dockEdge) {
        if (UIState.dockHorizontal) {
          bounds.y = UIState.dockEdge === 'top' ? currDisplay.workArea.y : currDisplay.workArea.y + currDisplay.workArea.height - UIState.dockSize
        } else {
          bounds.x = UIState.dockEdge === 'left' ? currDisplay.workArea.x : currDisplay.workArea.x + currDisplay.workArea.width - UIState.dockSize
        }
      }

    } else {
      if (UIState.dockEdge) {

        if (UIState.dockHorizontal) {
          bounds.y = UIState.dockEdge === 'top' ? currDisplay.workArea.y - (UIState.dockSize - Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)) : currDisplay.workArea.y + currDisplay.workArea.height - Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)
        } else {
          bounds.x = UIState.dockEdge === 'left' ? currDisplay.workArea.x - (UIState.dockSize - Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)) : currDisplay.workArea.x + currDisplay.workArea.width - Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)
        }
      }
    }
  }
  return bounds
}

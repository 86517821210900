
import { OTGlobals, OTUITree, TestStream, updateDeviceSettings } from '@openteam/app-core';
import { Logger } from '@openteam/app-util';
import { IUIDeviceSettings, KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { DataState } from '../../Data/DataState';
import { DSColumn, DSPanel, DSRow } from '../../DesignSystem';
import { DSH3 } from '../../DesignSystem/DSText';
import { CallVideo } from '../Call/CallVideo';
import * as Analytics from '../../Util/Analytics'
import { CSSProperties } from 'react';
import { DS2Checkbox, DS2Radio, DS2Switch } from '../../DesignSystem';
import PermissionsOverlay from './PermissionsOverlay';


const logger = new Logger("DeviceSettings")
interface IDeviceSettingsProps {
  spaceId: KSpaceId
}

const VideoSettings = (props: IDeviceSettingsProps) => {
  const mounted = useRef<boolean>(false)
  const stream = useRef<TestStream | undefined>(undefined);
  const [streamVersion, setStreamVersion] = useState<number>(0);

  const [permission, setPermission] = useState<string | null>(null)
  const checkPermissionsInterval = useRef<ReturnType<typeof setInterval>>()
  const deviceSettings: IUIDeviceSettings = DataState.deviceSettings;
  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const selectDevice = (type: string, deviceId: string) => {
    logger.info("setting device", type, deviceId)
    updateDeviceSettings(type, { deviceId });

  };

  const selectQuality = (type: string, quality: string) => {
    logger.info("setting quality", type, quality)
    updateDeviceSettings(type, quality);
  };

  const enableCameraInCalls = (enabled) => {
    OTGlobals.userSettingsManager.updateRemoteSettings({
      callVideoEnabled: enabled
    })
  }

  const checkPermissions = useCallback(async () => {
    let permsOk = true;
    if (isMacOs) {
      const camera = await window.Main.getMediaAccessStatus('camera');
      if (camera !== 'granted') {
        //logger.debug(`Permissions ${camera}, ${microphone}`);
        setPermission(camera)
        permsOk = false;
      } else if (checkPermissionsInterval.current) {
        logger.debug('Permissions granted');
        setPermission(null);
        clearInterval(checkPermissionsInterval.current)
        stream.current?.reset();
      }
    }
    return permsOk;
  }, [stream])

  useEffect(() => {
    (async () => {
      if (!(await checkPermissions())) {
        checkPermissionsInterval.current = setInterval(checkPermissions, 500);
      }
    })();
    return () => {
      if (checkPermissionsInterval.current) {
        clearInterval(checkPermissionsInterval.current);
      }
    };
  }, [checkPermissions]);

  const videoQuality = deviceSettings.cameraQuality?.availableDevices || {}

  const initCamera = async () => {
    const newStream = new TestStream(props.spaceId, OTUITree.auth.userId, OTGlobals.mediaDevices);
    await newStream.enableVideo();

    newStream.on('trackadded', forceUpdate);

    if (mounted.current) {
      logger.debug("setting stream")
      stream.current = newStream;
      setStreamVersion(streamVersion + 1);
    } else {
      logger.debug("shutting down new stream")
      newStream.shutdown();
    }
  }

  const stop = () => {
    logger.debug("stopping");

    if (stream.current) {
      logger.debug("shutting down stream");
      stream.current.shutdown();
      stream.current = undefined;
    }
  }

  useEffect(() => {
    logger.debug("mounting");
    mounted.current = true;
    initCamera();
    // return cancel
    return () => {
      mounted.current = false;
      logger.debug("unmounted");
      stop();
    };
  }, [])

  useEffect(() => {
    stream.current && stream.current.updateSettings(OTGlobals.mediaDevices)

  }, [OTGlobals.mediaDevices.audio, OTGlobals.mediaDevices.video, OTGlobals.mediaDevices.audiooutput])

  return (
    <DSColumn style={{ position: 'relative' }} spacing={16}>
      <DSColumn spacing={8}>
        <DSH3>Video device</DSH3>
        <DSColumn>
          {
            deviceSettings.videoinput?.availableDevices.map((device) => (
              <DS2Radio
                key={device.deviceId}
                label={device.label}
                checked={device.deviceId === deviceSettings.videoinput?.activeDeviceId}
                onChange={() => {
                  selectDevice('videoinput', device.deviceId);
                  Analytics.logEvent("DeviceSettings_ChangeVideoDevice", { deviceLabel: device.label });
                }}
              />
            ))
          }
        </DSColumn>
      </DSColumn>
      <DSColumn spacing={8}>
        <DSH3>Camera Quality</DSH3>

        <DSColumn>

          {
            Object.keys(videoQuality).map((quality) => (
              <DS2Radio
                key={quality}
                label={videoQuality[quality].label}
                checked={quality === deviceSettings.cameraQuality?.activeDeviceId}
                onChange={() => {
                  selectQuality('cameraQuality', quality);
                  Analytics.logEvent("DeviceSettings_ChangeVideoQuality", { qualityLabel: videoQuality[quality].label });
                }}
              />
            ))
          }
        </DSColumn>

        <DSRow style={{ marginLeft: 30, }}>
          <DSPanel
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <DSPanel
              style={{
                backgroundColor: 'black',
                borderRadius: 999,
                overflow: 'hidden',
                position: 'relative',
                minWidth: 225,
                minHeight: 225,

              }}
            >
              {
                stream.current ? (
                  <CallVideo
                    version={streamVersion}
                    flip={true}
                    track={stream.current.stream.getVideoTracks()[0]}
                    hasVideo={true}
                    style={{ width: 225, height: 225 }}
                  />
                ) : null
              }
            </DSPanel>
          </DSPanel>
        </DSRow>
      </DSColumn>

      <DSColumn spacing={8}>
        <DSH3>Call Settings</DSH3>
        <DSColumn>
          <DS2Switch
            checked={OTGlobals.remoteUserSettings.callVideoEnabled !== false}
            onChange={enableCameraInCalls}
            label="Join calls with video enabled"
          />
        </DSColumn>
      </DSColumn>

      {permission === 'denied' ? <PermissionsOverlay mediaType="Camera" /> : null}
    </DSColumn >
  );
};

export default observer(VideoSettings)


const Styles: Record<string, CSSProperties> = {
  heading: {
    marginTop: 8
  },
  settingRow: {
    marginTop: 12,
    justifyContent: "space-between",
  },
  settingSubRow: {
    marginTop: 8,
    marginLeft: 10,
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px"
  },
  separator: {
    height: 0,
    width: "80%",
    borderTopWidth: 1,
    borderBottomWidth: 0,
    borderColor: "white",
    borderTopStyle: "solid",
    marginTop: 20,
    marginBottom: 20,
  }
}

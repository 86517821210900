import * as React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import DS2BaseButton, { DS2BaseButtonProps } from './DS2BaseButton';
import { ButtonGroup, styled } from '@mui/material';
import { OptionUnstyled, optionUnstyledClasses } from '@mui/base';
import DSButtonGroup from './DSButtonGroup';
import useClickable from './useClickable';

interface Option {
  value: any
  icon?: JSX.Element
  title: any
}

interface Props extends DS2BaseButtonProps {
  options: Option[]
  onClick: (value: any) => void
}

export const SplitButton: React.FC<Props> = ({color, options, onClick: _onClick, ...props}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = (_, value) => {
    _onClick?.(value);
  };

  const {onClick, onPointerDown, otherProps} = useClickable({onClick: handleClick, ...props})

  const handleMenuItemClick = (
    ev,
    key: any,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    onClick?.(ev, key, index)
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <StyledButtonGroup variant="contained" color={color} ref={anchorRef} aria-label="split button">
        <DS2BaseButton
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRightWidth: 1,
            borderRightColor: 'rgba(0,0,0, 0.2)',
            borderRightStyle:'solid',
            flex: 1
          }}
          onClick={onClick}
          onPointerDown={onPointerDown}
          startIcon={options[selectedIndex]['icon']}
          {...otherProps}
        >
          {options[selectedIndex]['title']}
        </DS2BaseButton>
        <DS2BaseButton
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          noBadge
          style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, flexGrow: 0}}
          {...otherProps}
        >
          <FaChevronDown />
        </DS2BaseButton>
      </StyledButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose} mouseEvent={'onMouseDown'}>
                <StyledListbox id="split-button-menu">
                  {options.map(({value, icon, title}, index) => (
                    <StyledOption
                      key={value}
                      onClick={(ev) => handleMenuItemClick(ev, value, index)}
                      onPointerDown={(ev) => ev.stopPropagation()}
                    >
                      {icon} {title}
                    </StyledOption>
                  ))}
                </StyledListbox>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}


const StyledButtonGroup = styled(ButtonGroup)(() => ({
  boxShadow: 'none'
}))

const StyledListbox = styled('ul')(
  ({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.875rem",
    boxSizing: "border-box",
    padding: 2,
    margin: "5px 0",
    minWidth: 50,
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: 4,
    color: theme.palette.text.primary,
    overflow: 'scrollY',
    maxHeight: 150,
    outline: 0,
  })
);

const StyledOption = styled('li')(
  ({ theme }) => `
  list-style: none;
  padding: 6px;
  margin: 2px;
  border-radius: 4px;
  cursor: default;
  max-height: 28px;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.primary.main}
    color: ${theme.palette.primary.contrastText};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.text.disabled};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.primary.light};
    color: ${theme.palette.primary.contrastText};
  }
  `,
);


import React, { useState, CSSProperties, ReactNode, useEffect } from 'react';
import { DSPrint } from '../../DesignSystem/DSText';
import { DSColumn, DSPanel, DSRow } from '../../DesignSystem';
import { FaChevronDown } from 'react-icons/fa';
import { DSButton, DSLongButton } from '../../DesignSystem/DSButton';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { useRef } from 'react';
import { PopupWindow } from '../Popup/PopupWindow';
import { SubWindowCSS } from '../SubWindow';
import { ToolTipContainer } from '../../Controllers/ToolTips';
import { Logger } from '@openteam/app-util';
import { PositionType } from '../../Controllers/calcWindowPosition';

const logger = new Logger('DropMenu');


export const DropMenu: React.FC<{
  windowId: string;
  parentId: string;
  options: [string, string][];
  onItemSelected: (item?: string) => void;
  width?: number;
  height?: number;
  value?: string;
  disabled?: boolean;
  undefinedLabel?: string;
  scrollVersion: number;
  textColor?: string
  buttonStyle?: CSSProperties
}> = ({
  parentId,
  options,
  onItemSelected,
  value,
  width,
  height,
  disabled = false,
  windowId,
  undefinedLabel = '',
  buttonStyle,
  scrollVersion,
  ...otherProps
}) => {

    const itemWidth = width || 160;
    const itemHeight = height || 30;
    const optionMap = Object.fromEntries(options);

    return (
      <DropMenuBase
        windowId={windowId}
        parentId={parentId}
        options={options.map(x => x[0])}
        onItemSelected={onItemSelected}
        scrollVersion={scrollVersion}
        buttonStyle={buttonStyle}
        width={width}
        height={itemHeight * (Math.max(options.length, 1) + (undefinedLabel ? 1 : 0))}
        disabled={disabled}
        undefinedLabel={undefinedLabel}
        renderNoOptions={(onClick) =>
          <DSLongButton
            key={`dropmenu-empty`}
            onClick={onClick}
            hoverStyle={{
              ...Styles.dropMenuItemHover,
            }}
            style={{
              width: itemWidth,
              height: itemHeight,
              ...Styles.dropMenuItem,
              borderRadius: 10,
            }}
          >
            <DSPrint>no options available</DSPrint>
          </DSLongButton>
        }
        renderUndefinedOption={(onClick) =>
          <DSLongButton
            key={`dropmenu-undefined`}
            onClick={onClick}
            hoverStyle={{
              ...Styles.dropMenuItemHover,
            }}
            style={{
              width: itemWidth,
              height: itemHeight,
              ...Styles.dropMenuItem,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <DSPrint wrap>
              {undefinedLabel}
            </DSPrint>
          </DSLongButton>
        }
        renderOption={(option, index, onClick) =>
          <DSLongButton
            key={`dropmenu-${option}-${index}`}
            onClick={() => onClick()}
            hoverStyle={{
              ...Styles.dropMenuItemHover,
            }}
            style={{
              width: itemWidth,
              height: itemHeight,
              ...Styles.dropMenuItem,
              borderTopLeftRadius: !undefinedLabel && index === 0 ? 10 : 0,
              borderTopRightRadius: !undefinedLabel && index === 0 ? 10 : 0,
              borderBottomLeftRadius: index === options.length - 1 ? 10 : 0,
              borderBottomRightRadius: index === options.length - 1 ? 10 : 0,
            }}
          >
            <DSPrint wrap style={{ textAlign: 'center' }}>
              {optionMap[option]}
            </DSPrint>
          </DSLongButton>
        }
        {...otherProps}
      >
        <DSRow style={{ alignItems: "center", flex: 1 }}>
          <DSColumn style={{ flex: 1, alignItems: "flex-start" }}>
            <DSPrint style={{ marginRight: 8 }}>{value && Object.fromEntries(options)[value] || undefinedLabel}</DSPrint>
          </DSColumn>
          <FaChevronDown size={16} />
        </DSRow>
      </DropMenuBase>
    );
  };

export const DropMenuBase: React.FC<{
  windowId: string;
  parentId: string;
  setHasFocus?: (hasFocus: boolean) => void;
  options: string[];
  onItemSelected: (item?: string) => void;
  width?: number;
  height: number;
  disabled?: boolean;
  undefinedLabel?: string;
  scrollVersion: number;
  buttonStyle?: CSSProperties;
  panelStyle?: CSSProperties;
  renderButton?: (onClick: () => void) => ReactNode;
  renderNoOptions?: (onClick: () => void) => ReactNode;
  renderUndefinedOption?: (onClick: () => void) => ReactNode;
  renderOption: (option: string, index: number, onClick: () => void) => ReactNode;
  children: ReactNode;
  popupPosition?: PositionType;
}> = ({
  parentId,
  options,
  onItemSelected,
  setHasFocus,
  height,
  width,
  disabled = false,
  windowId,
  undefinedLabel = '',
  buttonStyle,
  panelStyle,
  scrollVersion,
  renderButton,
  renderNoOptions,
  renderUndefinedOption,
  renderOption,
  children,
  popupPosition = 'top',
  ...otherProps
}) => {
    const [showMenu, setShowMenu] = useState<number | undefined>(undefined);

    useEffect(() => {
      logger.debug("setDisableOnBlur: ", setHasFocus, showMenu === scrollVersion)
      setHasFocus && setHasFocus(showMenu === scrollVersion);
      return () => {
        setHasFocus && setHasFocus(false);
      }
    }, [showMenu, scrollVersion])

    const toggleMenu = () => {
      setShowMenu(scrollVersion || (showMenu === undefined ? scrollVersion : undefined));
    };

    const _onItemSelected = (item?: string) => {
      onItemSelected(item);
      setShowMenu(scrollVersion);
    };

    const targetRef = useRef<HTMLDivElement>(null);
    const borderRadius = 10;
    //    const itemHeight = 30;

    return (
      <div ref={targetRef}>
        {renderButton ? (
          renderButton(toggleMenu)
        ) : (
          <DSButton
            onClick={toggleMenu}
            disabled={disabled}
            style={{
              ...Styles.dropMenuButton,
              ...buttonStyle,
            }}
            {...otherProps}
          >
            {children}
          </DSButton>
        )}

        <PopupWindow
          id={windowId}
          targetRef={targetRef}
          parentId={parentId}
          open={showMenu === scrollVersion}
          offset={5}
          width={(width || 160) + 12}
          height={height + 12}
          onClose={() => setShowMenu(undefined)}
          popupPosition={popupPosition}
          options={{
            transparent: true,
          }}
        >
          <style type="text/css">{SubWindowCSS}</style>
          <ToolTipContainer parentId={windowId} showWhenUnfocused={true} />
          <DSPanel
            style={{
              flex: 1,
              borderRadius,
              padding: 6,
              ...panelStyle,
            }}
          >
            <div style={Styles.dropMenuPanel}>
              {options.length === 0 && renderNoOptions && renderNoOptions(() => setShowMenu(undefined))}
              {undefinedLabel && renderUndefinedOption && renderUndefinedOption(() => _onItemSelected(undefined))}
              {options.map((option, index) => renderOption(option, index, () => _onItemSelected(option)))}
            </div>
          </DSPanel>
        </PopupWindow>
      </div>
    );

  }



const Styles = {
  dropMenuButton: {
    width: undefined,
    minWidth: 80,
    padding: 0,
    paddingLeft: 4,
    paddingRight: 4
  },
  dropMenuItem: {
    minWidth: 80,
    padding: 0,
  },
  dropMenuItemHover: {
    minWidth: 80,
    padding: 0,
    backgroundColor: DSTheme.EmphasisColor
  },
  dropMenuPanel: {
  }
};

import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from '../config';
import { Logger } from "@openteam/app-util";

const logger = new Logger("Sentry")

Sentry.init({
  dsn: SENTRY_DSN,
  beforeSend: (event, hint) => {
    if (hint) {
      const error = hint.originalException;

      logger.error("Unhandled Exception", error?.toString())
    }

    return event;
  },
});


export function setUser(user) {
  Sentry.setUser(user);
}

export default Sentry;
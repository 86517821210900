import { useTheme } from '@openteam/design-system'
import { OTUITree } from '@openteam/app-core'
import { KSpaceId, KSpaceChannelId } from "@openteam/models"
import { observer } from "mobx-react-lite"
import React from "react"
import { DataState } from "../../../Data/DataState"
import { openPod, openUserChat } from "../../../Data/UIState"
import { DS2PinButton, DS2IconActionButton, DS2Icons, DS2CopyLinkButton, DSWrappedRow } from "../../../DesignSystem"
import { DSRow, DSColumn } from "../../../DesignSystem"
import { DSH3, DSBody, DSPrint, DSSmallPrint } from "../../../DesignSystem/DSText"
import { DSTheme } from '../../../DesignSystem/DSTheme'
import { timeSince } from '../../../Util/DateFormat'
import { getStringBackground } from '../../../Util/StringColor'
import UserIconListPaged from '../../../Util/UserIconListPaged'
import RoomCallButton from '../../Room/RoomCallButton'
import { RoomIcon } from "../../Room/RoomIcon"
import { SpacePanelCallIcon } from '../../Space/SpacePanelIcons'

interface OverflowRoomProps {
  spaceId: KSpaceId
  callId: KSpaceChannelId
  closeWindow: (onCloseCallback?: () => void) => void
}

const OverflowCall: React.FC<OverflowRoomProps> = ({ spaceId, callId, closeWindow }) => {
  const space = DataState.spaces[spaceId]
  const call = space.calls[callId]
  const pod = call.channelId ? space.pods[call.channelId] : undefined;
  const room = call.channelId ? space.channelDirectory[call.channelId] : undefined;
  const channel = call.channelId ? space.channels[call.channelId] : undefined;

  const name = call.name ?? pod?.name ?? room?.name ?? '';
  const background = name ? getStringBackground(name) : DSTheme.DockCallBackgroundColor
  const participantList = Object.values(call?.participants || {});

  const togglePin = React.useCallback(() => {
    pod?.actions.setPinned(!pod?.pinned)
  }, [pod])

  const openChat = React.useCallback(() => {
    call.channelId && closeWindow(() => openPod(spaceId, call.channelId!))
  }, [spaceId, call.channelId])


  return (
    <DSColumn
      spacing={8}
      style={{
        padding: '6px 15px',
        borderRadius: 8,
        background,
        color: DSTheme.ColorContrastColor,
      }}>
      <DSRow
        spacing={8}
        style={{
          alignItems: "center",
          justifyContent: 'space-between'
        }}>

        <DSRow spacing={8}>
          <DSColumn style={{ justifyContent: 'center' }}>
            {pod ?
              <RoomIcon
                spaceId={spaceId}
                podId={pod?.id}
                name={pod?.name}
                size={40}
                showBorder={true}
              /> :
              <SpacePanelCallIcon size={12} />
            }
          </DSColumn>
          <DSColumn>
            <DSRow style={{ alignItems: 'baseline' }} spacing={4}>
              <DSH3 maxLines={1} data-tooltip={call.name}>{call.name}</DSH3>
              {pod ? <DS2PinButton pinned={pod?.pinned} onClick={togglePin} /> : null}
            </DSRow>
            <DSPrint maxLines={1} data-tooltip={pod?.desc}>
              {pod?.desc}
            </DSPrint>
          </DSColumn>
        </DSRow>

        {
          pod ?
            <DSRow spacing={4}>
              <DS2IconActionButton
                color='secondary'
                data-tooltip="Open Chat"
                onClick={openChat}
                analyticsEvent="RoomOverflowOpenChat"
                badgeNum={pod.numUnread}
              >
                {DS2Icons.chat}
              </DS2IconActionButton>

              <DS2CopyLinkButton
                buttonStyle='action'
                meetingLink={call.meetingLink}
              />

              <RoomCallButton
                spaceId={spaceId}
                podId={pod.id}
                callId={callId}
                buttonStyle='action'
                analyticsPrefix='RoomOverflow'
              />

            </DSRow>
            :
            <RoomCallButton
              spaceId={spaceId}
              callId={callId}
              buttonStyle='action'
              analyticsPrefix='RoomOverflow'
            />
        }
      </DSRow>

        <UserIconListPaged
          spaceId={spaceId}
          users={participantList}
          showName={false}
          numRows={5}
          numPerRow={10}
          tileSize={27}
          horizontalSpacing={2}
          onClick={(userId) => openUserChat(spaceId, userId)}
        />
      {call?.crDate ? <DSSmallPrint>Started {timeSince(new Date(call.crDate), ' ago')}</DSSmallPrint> : undefined}
    </DSColumn>
  )
}

export default observer(OverflowCall);

import { observer } from "mobx-react-lite";
import React from "react";
import { DataState } from "../../Data/DataState";
import { ArcPosition } from "../../Util/CirclePosition";
import { toDeg } from "../../Util/degToPos";
import { TileSettingsButton } from "./CallButtons";
import { MutedIcon, showMuted } from "./CallIndicators";
import { SignalStrength } from "./SignalStrength";

interface ArcIndicatorsProps {
  userId: string
  tileSize: number
  hovered: boolean
  resizing: boolean
}

export const ArcIndicators: React.FC<ArcIndicatorsProps> = observer(
  ({ userId, tileSize, hovered, resizing }) => {
    const iconSize = Math.max(10, Math.min(20, Math.floor(tileSize / 18)));
    const indicatorSize = iconSize * 1.6;
    const isMe = userId === DataState.activeCall!.myUserId;

    let startAngle = 35;
    // fudge offset to move indicators in when tile is small
    // to stop them getting pushed out of the window
    const offsetFactor = Math.min(0.98, 0.92 + (tileSize - 140) / 1500);

    const radius = tileSize / 2;
    const hoveredClearAngle = toDeg(Math.acos((radius - 44 - indicatorSize / 2) / radius));
    const hoveredStart = hoveredClearAngle;

    if (hovered) {
      startAngle = Math.max(startAngle, hoveredStart);
    }

    //useEffect(() => {
    //  logger.debug(
    //    `${userId}, Hovered clear angle ${hoveredClearAngle}, radius ${radius}, startAngle: ${startAngle}, offsetFactor ${offsetFactor}`
    //  );
    //}, [hoveredClearAngle, hoveredStart, startAngle, offsetFactor, tileSize]);

    return (
      <ArcPosition radius={radius} itemSize={indicatorSize} positionDegrees={startAngle} offsetFactor={offsetFactor}>
        <SignalStrength userId={userId} size={iconSize} hide={resizing} forceShow={hovered} />
        {showMuted(userId) && <MutedIcon userId={userId} size={iconSize} hide={resizing} />}
        {!isMe && (
          <TileSettingsButton
            userId={userId}
            size={iconSize}
            forceShow={hovered && !resizing}
            isFocused={true}
            //onMenuOpen={setMenuOpen}
            style={{ padding: 4 }}
          />
        )}
      </ArcPosition>
    );
  }
);


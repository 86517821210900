import React, { useCallback, useEffect, useState } from "react"
import { OTUserInterface } from "@openteam/app-core"
import { IShowConfirm } from "@openteam/models"
import { ConfirmDialog } from "./ConfirmDialog"


export const ConfirmService: React.FC = props => {
    const [confirms, setConfirms] = useState<any[]>([])

    const addConfirm = useCallback(async (data: IShowConfirm) => {
        const f = (resolve, reject) => {
            setConfirms(l => [...l, {...data, resolve, reject}])
        }
        return new Promise(f)
    }, [setConfirms])

    useEffect(() => {
       OTUserInterface.registerShowConfirm(addConfirm) 
    }, [addConfirm])

    const onResult = useCallback(result => {
        setConfirms(([{resolve, onClose}, ...rest]) => {
            resolve(result)
            onClose && onClose(result);
            return rest
        })
    }, [setConfirms])

    if (confirms.length) {
        const {resolve, reject, onClose, ...dialogProps} = confirms[0];
        return (
            <ConfirmDialog
                {...dialogProps}
                onClose={onResult}
            />
        )
    }
    return null
}

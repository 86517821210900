import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { Logger } from "@openteam/app-util";
import { observer } from "mobx-react";
import { DSAnimateListGrow } from "../../DesignSystem/DSAnimate";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { dockAnimatingStart, dockAnimatingStop, setDockComponentLength, UIState } from "../../Data/UIState";
import { DataState } from "../../Data/DataState";
import { autorun, makeAutoObservable } from "mobx";
import { DockSeparator } from "./DockSeparator";
import { tutorialState } from "../../Controllers/TutorialService";
import { TutorialStepHolder } from "../Tutorial/TutorialStepHolder";
import { DockUserIcon } from "./DockUserIcon";
import { ToolTipComponent } from "../../Controllers/ToolTips";
import { FaUserCircle } from "react-icons/fa";
import { DockOverflowIcon, DockOverflowUserPanel } from "./DockOverflow";
import getUserTooltip from "../User/getUserTooltip";

const logger = new Logger("DockUsers")

interface IDockUsersProps {
  spaceId: string
  frame?: HTMLDivElement | null
  adjustDockScroll?: (amount: number) => void
}

export const DockUsers = observer((props: IDockUsersProps) => {
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const itemLengthRatio = (1 - DSTheme.DockMargin * 2);
  const tileSizeRatio = DSTheme.DockUserRatio;

  const itemLength = Math.floor(itemLengthRatio * UIState.dockSize);
  const tileSize = Math.floor(tileSizeRatio * UIState.dockSize);

  logger.debug("tileSizeRatio", tileSizeRatio, "itemLengthRatio", itemLengthRatio)

  const { spaceId } = props;

  const space = DataState.spaces[spaceId];

  const [currentPinnedUsers, setCurrentPinnedUsers] = useState<string[]>([]);
  const [currentRecentUsers, setCurrentRecentUsers] = useState<string[]>([]);

  useEffect(() => autorun(() => {
    logger.debug(`dockDisplayedPinnedUsers[${spaceId}] ${UIState.dockDisplayedPinnedUsers[spaceId]}`)
    logger.debug(`dockDisplayedRecentUsers[${spaceId}] ${UIState.dockDisplayedRecentUsers[spaceId]}`)

    const displayedRecentUsers = tutorialState.active ? [] : UIState.dockDisplayedRecentUsers[spaceId];
    const displayedPinnedUsers = tutorialState.active ? tutorialState.showDummyUser ? ["dummy"] : [] : UIState.dockDisplayedPinnedUsers[spaceId];

    setCurrentPinnedUsers(displayedPinnedUsers);
    setCurrentRecentUsers(displayedRecentUsers);

    const numPinnedUsers = Object.keys(displayedPinnedUsers).length;
    const numRecentUsers = Object.keys(displayedRecentUsers).length;

    const pinnedLength = (
      (numPinnedUsers * itemLengthRatio)
    );

    const recentLegnth = (
      (numRecentUsers * itemLengthRatio)
    );

    setIsEmpty(
      (Object.keys(UIState.dockPinnedUsers[spaceId]).length + Object.keys(UIState.dockRecentUsers[spaceId]).length) == 0
    )

    setDockComponentLength('PinnedUsers', pinnedLength)
    setDockComponentLength('RecentUsers', recentLegnth)

    return () => {
      setDockComponentLength('PinnedUsers', 0);
      setDockComponentLength('RecentUsers', 0);
    };

  }), [spaceId, itemLengthRatio])

  const renderUser = useCallback(
    (userId: string) => (
      space.users[userId] ?
        <DockUserIcon
          key={userId}
          spaceId={spaceId}
          userId={userId}
          size={tileSize}
        />
        :
        userId == "dummy" ?
          <TutorialStepHolder
            parentId={"__dock__"}
            ids={["user-interact"]}
            position={UIState.dockPosition.onLeft ? 'right' : 'left'}
            offsetY={130}
            offsetX={UIState.dockPosition.onLeft ? 20 : -20}>
            <DockUserIcon
              key={userId}
              spaceId={spaceId}
              userId={userId}
              size={tileSize}
            />
          </TutorialStepHolder>
          :
          null
    ),
    [space.users, spaceId, tileSize, props.frame]
  );


  return (
    <>

      <DockSeparator />
      <DSAnimateListGrow
        label='DockPinnedUsers'
        width={UIState.dockHorizontal ? itemLength : UIState.dockSize}
        height={UIState.dockHorizontal ? UIState.dockSize : itemLength}
        spacing={(itemLength - tileSize) / 2}
        renderItem={renderUser}
        items={currentPinnedUsers}
        outerStyle={Styles.outer}
        horizontal={UIState.dockHorizontal}
        itemStyle={{
          position: 'relative',
        }}
        onListStart={() => dockAnimatingStart('dock-pinned-users')}
        onListRest={() => dockAnimatingStop('dock-pinned-users')}
      />

      {
        currentRecentUsers.length > 0 ?
          <DockSeparator />
          :
          null
      }

      <DSAnimateListGrow
        label='DockRecentUsers'
        width={UIState.dockHorizontal ? itemLength : UIState.dockSize}
        height={UIState.dockHorizontal ? UIState.dockSize : itemLength}
        spacing={(itemLength - tileSize) / 2}
        renderItem={renderUser}
        items={currentRecentUsers}
        outerStyle={Styles.outer}
        horizontal={UIState.dockHorizontal}
        itemStyle={{
          position: 'relative',
        }}

        onListStart={() => dockAnimatingStart('dock-recent-users')}
        onListRest={() => dockAnimatingStop('dock-recent-users')}
      />

      <DockOverflowUserIcon
        spaceId={props.spaceId}
        isEmpty={isEmpty}
      />

      <ToolTipComponent id='dock-user' getContent={getUserTooltip} />
    </>
  )
})


interface Props {
  spaceId: string
  isEmpty: boolean
}

const DockOverflowUserIcon: React.FC<Props> = observer(({ spaceId, isEmpty }) => {


  useEffect(() => {
    setDockComponentLength('UserOverflow', 0.5)
    return () => setDockComponentLength('UserOverflow', 0)
  }, [isEmpty])

  return (
    <DockOverflowIcon
      spaceId={spaceId}
      data-tooltip={isEmpty ? 'Add a teammate to your dock' : 'All Teammates'}
      size={UIState.dockSize * (UIState.dockComponentLengths.get('UserOverflow') ?? 0)}
      panel={DockOverflowUserPanel}
    >
      <FaUserCircle size={UIState.dockSize * 0.5 * .7} />
    </DockOverflowIcon>
  )
})

class StylesClass {
  constructor() {
    makeAutoObservable(this)
  }

  get outer (): CSSProperties {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }
}

const Styles = new StylesClass()


import { Logger } from '@openteam/app-util';
import React, { CSSProperties, PropsWithChildren, ReactElement, ReactNode, useState } from 'react';
import { DSButton } from './DSButton';
import { DSH3, DSH4 } from './DSText';
import { DSTheme } from './DSTheme';
import { DSRow, DSPanel } from '.';
import { DSBadgeView } from './DSBadge';
import { setCallWidgetHovered } from '@openteam/app-core';

const logger = new Logger("DSTabs");


const defaultTabStyle: CSSProperties = {
  marginLeft: 10,
  marginRight: 10,
  marginBottom: 2,
/*   padding: 5,
 */  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  opacity: 0.5,
  borderBottomColor: "white",

};
const defaultActiveTabStyle: CSSProperties = {
  opacity: 1,
  borderBottomColor: DSTheme.EmphasisColor
}


export const DSTabs: React.FC<{
  activeTabId: string;
  onChange: (tabId: string) => void;
  style?: CSSProperties;
  tabStyle?: CSSProperties;
  activeTabStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  activeButtonStyle?: CSSProperties;
  hoverButtonStyle?: CSSProperties;
}> = ({
  activeTabId,
  children,
  onChange,
  style,
  tabStyle = defaultTabStyle,
  buttonStyle = {},
  activeTabStyle = defaultActiveTabStyle,
  activeButtonStyle = {},
  hoverButtonStyle = {}
}) => {

    const handleChange = (tab: string) => {
      onChange(tab);
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          ...style
        }}>
        {
          React.Children.map(children, (child) => {
            const item = child as ReactElement<PropsWithChildren<IDSTab>>;
            // @ts-ignore-nex-line
            if (item?.type === DSTab || item?.type === DSRoundedTab) {
              return React.cloneElement(
                item,
                {
                  onChange: handleChange,
                  active: activeTabId === item.props.tabId,
                  tabStyle : {
                    ...tabStyle,
                    ...(activeTabId === item.props.tabId ? activeTabStyle : {})
                  },
                  buttonStyle,
                  activeButtonStyle,
                  hoverStyle: hoverButtonStyle
                }
              );
            } else {
              return item
            }
          })
        }
      </div>
    );
  }

interface IDSTab {
  name?: string;
  icon?: JSX.Element
  tabId: string;
  onChange?: (tabId: string) => void;
  active?: boolean;
  tabStyle?: CSSProperties;
  activeButtonStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  hoverStyle?: CSSProperties;
  badgeNum?: number;
};


export const DSTab: React.FC<IDSTab> = ({
  name,
  tabId,
  onChange,
  active = false,
  icon,
  tabStyle = defaultTabStyle,
  buttonStyle,
  activeButtonStyle,
  hoverStyle,
  children
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <DSButton
      onClick={() => onChange?.(tabId)}
      style={{
        ...buttonStyle,
        ...(hovered ? hoverStyle : {}),
        ...(active ? activeButtonStyle: {})
      }}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      {children ? children : (
        <>
          {icon}
          <DSH3 style={tabStyle}>{name}</DSH3>
        </>
      )
      }
    </DSButton >
  );
}

interface IDSRoundedTab extends IDSTab {
  isFirst?: boolean
  isLast?: boolean
}


export const DSRoundedTab: React.FC<IDSRoundedTab> = ({ name, tabId, onChange, active = false, icon, tabStyle, badgeNum, isFirst, isLast }) => {
  return (
    <DSButton
      onClick={() => onChange?.(tabId)}
      style={{ ...tabStyle }}
    >
      <DSRow style={{
        position: 'relative',
      }}>
        {
          !isFirst ?
            <DSPanel style={{
              position: "absolute",
              bottom: 0,
              left: -10,
              height: 10,
              width: 10,
              borderBottomRightRadius: 8,
              backgroundColor: 'transparent',
              boxShadow: active ? "5px 2px 0 0 " + DSTheme.PanelDarkerColor : undefined
            }} />
            :
            undefined
        }

        <DSRow style={{
          backgroundColor: active ? DSTheme.PanelDarkerColor : undefined,
          borderTopLeftRadius: DSTheme.BaseBorderRadius,
          borderTopRightRadius: DSTheme.BaseBorderRadius,
          height: 40,
          alignItems: 'center',
          paddingLeft: 12,
          paddingRight: 12,
        }}>

          {
            icon
          }
          <DSH4 style={{
            fontWeight: active ? 600 : 400
          }}>{name}</DSH4>
        </DSRow>
        <DSPanel style={{
          position: "absolute",
          bottom: 0,
          right: -10,
          height: 10,
          width: 10,
          borderBottomLeftRadius: 8,
          backgroundColor: 'transparent',
          boxShadow: active ? "-5px 2px 0px 0 " + DSTheme.PanelDarkerColor : undefined
        }} />

        <DSBadgeView badgeNum={badgeNum} badgePosition={"TopLeft"} />

      </DSRow>
    </DSButton>
  );
}

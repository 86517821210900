
import { Logger } from "@openteam/app-util";
import { OTUITree, FireAuth, UIDataStateReset, FireDb, ExternalMeeting, UIDataState } from '@openteam/app-core';
import { applyLoginToken } from "./Login";
import { DataState } from "../Data/DataState";

const logger = new Logger("LoginController");

export const externalMeeting = undefined

export async function setupDeeplink() {
  const deeplinkingUrl = await window.Main.getDeepLink()
  if (deeplinkingUrl) {
    doDeepLink(deeplinkingUrl)
  }
  window.Main.on("deeplinkingUrl", handleDeepLink);
}


export function handleDeepLink(args) {
  doDeepLink(args.deeplinkingUrl);
}

export async function doDeepLink(deeplinkingUrl) {
  logger.info(`deeplinkingUrl is `, deeplinkingUrl);
  const parsedUrl = new URL(deeplinkingUrl);
  logger.info("parsedUrl", parsedUrl);

  const path = parsedUrl.pathname;


  const token = parsedUrl.searchParams.get("token");
  if (token) {
    applyLoginToken(token)
  }


  const meetToken = parsedUrl.searchParams.get("meetToken");
  if (meetToken) {
    logger.debug("got meetToken", meetToken)

    UIDataState.actions.loadExternalMeeting(meetToken)

  }

  if (deeplinkingUrl) {
    window.Main.clearDeepLink()
  }

}

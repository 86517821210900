import React, { CSSProperties, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Logger } from "@openteam/app-util";
import { KCallId, KSpaceId, KSpacePodId, KSpaceUserId } from "@openteam/models"
import { observer } from "mobx-react"
import { DataState } from "../../Data/DataState"
import { getDummyCall, getDummyPod, isDummyPod } from "../../Data/tutorialDummy"
import { DockIconBase } from "./DockIconBase"
import * as Analytics from '../../Util/Analytics'
import { DSPanel, DSRow } from "../../DesignSystem";
import { OTUITree } from "@openteam/app-core";
import { DockRoomPanel } from "./DockRoomPanel";
import { closePod, openPod, UIState } from "../../Data/UIState";
import { openMenu } from "../Menu";
import { RoomIcon } from "../Room/RoomIcon";
import { CallDockComponent, getCallDockComponentLength } from "./DockRoomCallComponent";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DockCallIndicator } from "./DockIndicator";
import { getStringBackground, getStringColor } from "../../Util/StringColor";
import { tutorialState } from "../../Controllers/TutorialService";
import { DockNotifyPanel } from "./DockNotifyPanel";
import { runInAction } from "mobx";
import { playKnock } from "../../Controllers/SoundEffects";
import { DS2AcceptRequestButton, DS2HoldButton, DS2Icons, DS2RejectRequestButton } from "../../DesignSystem/DS2";
import DockZoomCallPanel from "./DockZoomCallPanel";
const logger = new Logger("DockRoomIcon");

export const getDockRoomLength = (spaceId: KSpaceId, callId: KSpacePodId | undefined, tileSize: number, itemLength: number) => {
  return (callId ? Math.max(getCallDockComponentLength(spaceId, callId, tileSize) + tileSize / 1.3 + (itemLength - tileSize), itemLength) : itemLength)
}

interface IDockRoomIconProps {
  spaceId: KSpaceId
  podId?: KSpacePodId
  callId?: KCallId
  allowMyCallDisplay?: boolean
}


export const DockRoomIcon = observer((props: IDockRoomIconProps) => {
  const tileSize = Math.floor(UIState.dockSize * DSTheme.DockRoomRatio);
  const itemLength = Math.floor(UIState.dockSize * (1 - DSTheme.DockMargin * 2))

  const myUserId = OTUITree.auth.userId;
  const space = DataState.spaces[props.spaceId]
  const isTutorial = props.podId ? isDummyPod(props.podId) : false
  const pod = props.podId ? isTutorial ? getDummyPod(props.podId) : DataState.spaces[props.spaceId].pods[props.podId] : undefined;
  const channelDirectory = props.podId ? DataState.spaces[props.spaceId].channelDirectory[props.podId] : undefined;
  const call = props.callId ? isTutorial ? getDummyCall(props.callId) : space.calls[props.callId] : undefined;
  const participants = call?.participants || {}
  const inCall = myUserId in participants
  const callOpen = participants[OTUITree.auth.userId]?.currentSessionToken == OTUITree.auth.sessionToken
  const roomLength = getDockRoomLength(props.spaceId, props.callId, tileSize, itemLength);
  const podName = pod?.name || channelDirectory?.name
  const name = podName || call?.name

  useLayoutEffect(() => logger.debug(`Rendering room icon ${props.podId}, height: ${roomLength}`))

  const isOpen = props.podId && UIState.openChats[props.spaceId]?.channels && props.podId in UIState.openChats[props.spaceId]?.channels || false;
  const backgroundColor = props.podId && podName ? getStringColor(podName) : DSTheme.DockCallBackgroundColor;
  const isRequesting = call?.requests?.[OTUITree.auth.userId]?.active

  const incomingRequests = Object.keys(call?.requests || {}).filter(userId => userId != OTUITree.auth.userId
    && inCall && callOpen && call?.requests?.[userId].active && call?.requests?.[userId].receiverStatus == 'waiting')

  const nextIncomingRequest = incomingRequests.length > 0 && call?.requests?.[incomingRequests[0]] ? call.requests[incomingRequests[0]] : undefined


  const externalMeetings = DataState.spaces[props.spaceId]?.externalMeetings
  const externalChannelRequests = props.podId && externalMeetings?.channels?.[props.podId]?.waiting || {}
  const externalChannelRequestKeys = Object.keys(externalChannelRequests).filter(userId => userId != OTUITree.auth.userId && inCall)

  const nextChannelRequest = externalChannelRequestKeys.length > 0 && externalChannelRequestKeys[0] ? externalChannelRequestKeys[0] : undefined

  console.log("call?.id", call?.id, "externalMeetings?.rooms", externalMeetings?.rooms)

  const externalRoomRequests = call?.id && externalMeetings?.rooms?.[call.id]?.waiting || {}
  const externalRoomRequestKeys = Object.keys(externalRoomRequests).filter(userId => userId != OTUITree.auth.userId && inCall)
  console.log("externalRoomRequestKeys", externalRoomRequestKeys, "externalRoomRequests", externalRoomRequests)

  const nextRoomRequest = externalRoomRequestKeys.length > 0 && externalRoomRequestKeys[0] ? externalRoomRequestKeys[0] : undefined

  const hasIncomingRequest = !!nextIncomingRequest || !!nextChannelRequest || !!nextRoomRequest
  console.log("UIState.dockCallHasAlert", UIState.dockCallHasAlert, "hasIncomingRequest", hasIncomingRequest, "nextChannelRequest", nextChannelRequest, "nextRoomRequest", nextRoomRequest)

  useEffect(() => {
    runInAction(() => {
      UIState.dockExistingCall = inCall &&
        participants[myUserId]?.currentSessionToken != OTUITree.auth.sessionToken

      console.log("UIState.dockCallHasAlert", UIState.dockCallHasAlert, "hasIncomingRequest", hasIncomingRequest, "nextChannelRequest", nextChannelRequest)

      if (UIState.dockCallHasAlert != hasIncomingRequest) {

        UIState.dockCallHasAlert = hasIncomingRequest

        if (hasIncomingRequest) {

          playKnock()
        }
      }
    })
  }, [participants, , hasIncomingRequest, inCall, UIState.dockCallHasAlert])


  const onContextMenu = useCallback((e) => {
    e.preventDefault();
    props.podId && openPodMenu(props.spaceId, props.podId);
    Analytics.logEvent("Button_RightClickDockRoom", { podId: props.podId })
  }, [openPodMenu, props.spaceId, props.podId])

  const onDoubleClick = useCallback(() => {
    props.podId && openPod(props.spaceId, props.podId)
    Analytics.logEvent("Button_DoubleClickDockRoom", { podId: props.podId })
  }, [openPod, props.spaceId, props.podId])

  if (!pod && !call) {

    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: "column",
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'grey',
          width: tileSize,
          height: roomLength,
          borderRadius: tileSize / 2,
        }} />
    )
  }


  if (!props.allowMyCallDisplay && inCall) {
    return null
  }



  return (
    <DockIconBase
      id={`room-${props.podId || props.callId}`}
      isOpen={isOpen}
      unread={(pod?.numUnread || 0) > 0}
      forceShow={isRequesting || hasIncomingRequest || (isTutorial && tutorialState.showDummyRoomPanel)}
      indicator={
        props.callId ?
          <DockCallIndicator tileSize={tileSize} color={backgroundColor} height={roomLength} />
          :
          undefined
      }
      size={tileSize}
      renderIcon={(showingWindow: boolean) => (
        <RoomIcon
          data-tooltip={props.podId ?? props.callId}
          data-tooltip-for={props.podId ? 'dock-room' : 'dock-call'}
          spaceId={props.spaceId}
          podId={props.podId}
          callId={props.callId}
          name={call?.name}
          size={tileSize}
          badgeNum={pod?.numUnread}
          badgeOverride={pod?.hasMention ? '@' : undefined}
        >
          {
            props.callId ?
              <CallDockComponent
                spaceId={props.spaceId}
                callId={props.callId}
                tileSize={tileSize}
              />
              :
              undefined
          }
        </RoomIcon>
      )}
      renderContents={(closeWindow: (onCloseCallback?: () => void) => void, windowId: string,) => (

        nextIncomingRequest && call ?
          <DockNotifyPanel
            user={{
              id: nextIncomingRequest.userId,
              name: nextIncomingRequest.name,
              imageUrl: nextIncomingRequest.imageUrl
            }}
            title={`${nextIncomingRequest.name} is requesting to join`
            }
            rightIcon={
              <DSPanel style={{
                width: 20,
                height: 20,
                borderRadius: 4,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: DSTheme.SuccessColor,
                color: 'white'
              }} >{DS2Icons.call}</DSPanel>
            }
            buttons={
              <DSRow style={{ justifyContent: 'space-evenly' }} spacing={8}>
                <DS2AcceptRequestButton
                  style={{ flexGrow: 1 }}
                  fullWidth={true}
                  onClick={() => {
                    call?.actions.respondRequestJoinCall(nextIncomingRequest.userId, "accepted")
                  }}
                />

                <DS2RejectRequestButton
                  style={{ flexGrow: 1 }}
                  fullWidth={true}
                  onClick={() => {
                    call?.actions.respondRequestJoinCall(nextIncomingRequest.userId, "rejected")
                  }}
                />
              </DSRow>
            }

          />
          :
          nextChannelRequest && call ?
            <DockNotifyPanel
              user={{
                id: nextChannelRequest,
                name: externalChannelRequests[nextChannelRequest].name,
                imageUrl: externalChannelRequests[nextChannelRequest].imageUrl
              }}
              title={`${externalChannelRequests[nextChannelRequest].name} is requesting to join ${name}`
              }
              rightIcon={
                <DSPanel style={{
                  width: 20,
                  height: 20,
                  borderRadius: 4,
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: DSTheme.SuccessColor,
                  color: 'white'
                }} >{DS2Icons.call}</DSPanel>
              }
              buttons={
                <DSRow style={{ justifyContent: 'space-evenly' }} spacing={8}>
                  <DS2AcceptRequestButton
                    style={{ flexGrow: 1 }}
                    fullWidth={true}
                    onClick={() => {
                      externalMeetings?.actions?.acceptReq(nextChannelRequest, call.id, props.podId)
                    }}
                  />
                  <DS2HoldButton
                    fullWidth
                    onClick={() => {
                      externalMeetings?.actions?.holdReq(nextChannelRequest, call.id, props.podId);
                    }}>
                  </DS2HoldButton>
                  <DS2RejectRequestButton
                    style={{ flexGrow: 1 }}
                    fullWidth={true}
                    onClick={() => {
                      externalMeetings?.actions?.rejectReq(nextChannelRequest, call.id, props.podId)
                    }}
                  />
                </DSRow>
              }

            />
            :
            nextRoomRequest && call ?
              <DockNotifyPanel
                user={{
                  id: nextRoomRequest,
                  name: externalRoomRequests[nextRoomRequest].name,
                  imageUrl: externalRoomRequests[nextRoomRequest].imageUrl
                }}
                title={`${externalRoomRequests[nextRoomRequest].name} is requesting to join ${call.name}`
                }
                rightIcon={
                  <DSPanel style={{
                    width: 20,
                    height: 20,
                    borderRadius: 4,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: DSTheme.SuccessColor,
                    color: 'white'
                  }} >{DS2Icons.call}</DSPanel>
                }
                buttons={
                  <DSRow style={{ justifyContent: 'space-evenly' }} spacing={8}>
                    <DS2AcceptRequestButton
                      style={{ flexGrow: 1 }}
                      fullWidth={true}
                      onClick={() => {
                        logger.debug("externalMeetings?.actions?.acceptReq", externalMeetings?.actions?.acceptReq, nextRoomRequest, call.id, call.channelId)
                        externalMeetings?.actions?.acceptReq(nextRoomRequest, call.id, call.channelId)
                      }}
                    />
                    <DS2HoldButton
                      fullWidth
                      onClick={() => {
                        externalMeetings?.actions?.holdReq(nextRoomRequest, call.id, call.channelId);
                      }}>
                    </DS2HoldButton>
                    <DS2RejectRequestButton
                      style={{ flexGrow: 1 }}
                      fullWidth={true}
                      onClick={() => {
                        externalMeetings?.actions?.rejectReq(nextRoomRequest, call.id, call.channelId)

                      }}
                    />
                  </DSRow>
                }

              />
              :
              call && call.platform === 'zoom' ? (
                <DockZoomCallPanel spaceId={props.spaceId} callId={call.id} />
              ) : (
                <DockRoomPanel
                  spaceId={props.spaceId}
                  podId={props.podId}
                  callId={props.callId}
                  closeWindow={closeWindow}
                />
              )
      )}
      onContextMenu={onContextMenu}
      onDoubleClick={onDoubleClick}
    />
  )

})


const openPodMenu = (spaceId: KSpaceId, podId: KSpacePodId) => {
  const space = DataState.spaces[spaceId]
  const pod = space.pods[podId]
  const isOpen = UIState.openChats[spaceId] && podId in UIState.openChats[spaceId].channels;

  const menuItems: any[] = [
    {
      label: pod.name,
      enabled: false,
    },
    {
      type: "separator"
    },

    {
      label: "Keep in dock",
      type: "checkbox",
      checked: pod.pinned,
      click: () => pod.actions.setPinned(!pod.pinned),
    },
    {
      type: "separator"
    },
  ]

  if (isOpen) {

    menuItems.push(
      {
        label: "Close Chat",
        click: () => closePod(spaceId, podId),
      }
    )
  } else {
    menuItems.push(
      {
        label: "Open Chat",
        click: () => openPod(spaceId, podId),
      }
    )
  }

  openMenu(menuItems)
};

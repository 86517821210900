import { AppHomeManager, FireAuth, OTAppCoreData, OTGlobals, OTUITree, OTUserInterface } from "@openteam/app-core";
import * as config from "../config";
import Sentry from "./Sentry";
import * as Fire from "./Fire"
import semver from "semver";

import { InfluxSender, Logger, setLogSender } from "@openteam/app-util";
import { IHideToast, IOTRemoteConfig, IShowToast } from "@openteam/models";
import { IdleTimer } from "./IdleTimer";
import { ShortcutManager } from "./ShortcutManager";
import { hideNotification, showNotification } from "./Notifications";
import { getActionTypeConfig } from "./Actions";
import { playAlert, playGlobalAlert, playKnock, playNewMessage, playPluginOpen, playScreenShareStart, playUnmute, playVideoBell } from "./SoundEffects";
import { parseEmojis } from "../Util/EmojiUtil";
import { openPod, UIState } from "../Data/UIState";
import * as Analytics from '../Util/Analytics'
import {version} from '../../../package.json';
import { appUpgrade, setupAutoUpdate } from "./AppUpgrade";
import { runInAction } from "mobx";
import { getLinkPreview } from "../Util/LinkPreview";
import { getPluginConfig } from "../Components/Call/Plugins";
import { v4 as uuidv4 } from "uuid";
import * as System from "../Util/System";

const logger = new Logger("app");

export let isWayland: boolean = false

let influxSender: InfluxSender;

if (config.INFLUX_APP_LOG_TOKEN) {
  influxSender = new InfluxSender(
    System.platform,
    config.INFLUX_APP_LOG_TOKEN,
    OTAppCoreData.deviceId
  );

  setLogSender(influxSender);
  console.log("setup InfluxSender");
}

const loadMainCapbilities = async () => {
  UIState.mainCapabilities = await window.Main.getCapabilities?.() || {}

}

export const setup = async () => {
  logger.info("app startup")
  Analytics.logEvent("AppStart")

  await loadMainCapbilities()

  loadRemoteConfig()
  setupAutoUpdate()

  window.Main.on("quit-main", () => {
    logger.info("app shutdown")
    Analytics.logEvent("AppClose")
  });



  OTAppCoreData.version = version; //app.getVersion();
  OTAppCoreData.deviceId = await window.Main.getMachineId();
  isWayland = await window.Main.getIsWayland();

  OTGlobals.createCache(OTAppCoreData.version!);
  OTGlobals.registerAnalytics({
    logEvent: Analytics.logEvent,
    identifyUser: (userId) => {
      Analytics.identifyUser(userId);

      if (influxSender) {
        influxSender.setUser(OTUITree.auth.sessionToken, userId);
      }
    }
  });
  OTGlobals.pluginConfigList = getPluginConfig();
  OTGlobals.actionTypeDetails = getActionTypeConfig();
  OTGlobals.registerSentry(Sentry);
  // OTGlobals.registerGetDisplayMedia(getDisplayMedia);
  OTGlobals.setConfig(config);
  OTGlobals.registerGetLinkPreview(getLinkPreview);

  // OTUserInterface.setRingtones({
  //   getIncoming: getIncomingRingtone,
  //   getOutgoing: getOutgoingRingtone,
  // });
  OTUserInterface.setSoundEffects({
    globalAlert: playGlobalAlert,
    alert: playAlert,
    videoBell: playVideoBell,
    knock: playKnock,
    newMessage: playNewMessage,
    unmute: playUnmute,
    screenShareStart: playScreenShareStart,
    pluginOpened: playPluginOpen,
  });
  OTUserInterface.registerForegroundService({ startCall: async () => { }, stopCall: async () => { }});
  OTUserInterface.registerParseEmojis(parseEmojis);
  OTUserInterface.registerPlatformUtils({
    Platform: "desktop",
    PlatformOS: System.platform,
    allowDisplaySleep: () => {},
    focusWindow: (subWin: Window, ref: string) => {},
    getOpenWindows: () => { return {} },
    openWindow: (url: string, ref: any, options: string) => { return null},
    preventDisplaySleep: () => { },
    createTray: () => {},
    destroyTray: () => {}
  });
  OTUserInterface.registerToastHandlers({ show: showToast, hide: () => {} });
  OTUserInterface.registerShowNotification(showNotification);
  OTUserInterface.registerHideNotification(hideNotification);
  OTUserInterface.registerNavigate(handleNavigate);

};

const handleNavigate = (page: string, args: Record<string, string>) => {
  if (page == 'Chat') {
    openPod(args.teamId, args.channelId)

  }
}

const showToast: IShowToast = (msg, toastType, desc, onPress) => {
  logger.debug(`Showing toast notification ${msg}, ${desc}`)
  const id = uuidv4();

  OTUserInterface.showNotification({
    id,
    teamId: OTUITree.userManager.currentTeamId!,
    title: msg ?? "",
    text: desc ?? "",
    onPress: onPress
  })

  setTimeout(() => {
    OTUserInterface.hideNotification(id)
  }, 5000)
}


const loadRemoteConfig = () => {

  Fire.watchConfig((data: IOTRemoteConfig) => {
    logger.info("loaded remoteConfig data=%o", data);
    if (data) {
      OTAppCoreData.remoteConfig = data;
      logger.info("OTAppData.version", OTAppCoreData.version);

      if (data.NudgeOSVersion && OTAppCoreData.version) {
        if (semver.gt(data.NudgeOSVersion, OTAppCoreData.version)) {
          UIState.webUpgradeAvailable = true;
        }
      }

      if (data.NudgeOSMinVersion && OTAppCoreData.version) {
        if (semver.gt(data.NudgeOSMinVersion, OTAppCoreData.version)) {
          UIState.webUpgradeForce = true;
            // if its a major upgrade then we need to disconnect everything if we're already conneected so lets
            appUpgrade()
        }
      }

      if (data.splashEnabled != UIState.splashEnabled) {
        UIState.splashEnabled = data.splashEnabled || false

        if (UIState.splashEnabled && !data.splashEnabled) {
          // reload after splash ends
          appUpgrade()
        }
      }
    }
  });
}

export const endActiveSession = async (reload: boolean = true) => {
  logger.debug("endActiveSession");

  await OTUITree.appHomeUI.stop();
  await OTUITree.userManager.stop();
  if (reload) {
    location.reload()
  }
};



export const startApp = async () => {

  logger.debug("startApp");

  if (!OTGlobals._appHomeManager) {

    const advancedMode = (
      OTUITree.auth.userManager.userDoc?.advancedMode === true ||
      OTUITree.auth.userManager.userDoc?.email.endsWith('@zoomappsec.us') === true ||
      OTUITree.auth.userManager.userDoc?.email.endsWith('@openteam.io') === true
    );

    window.Main.setAdvancedMode(advancedMode)

    logger.debug("creating appHomeManager");

    OTGlobals.registerAppHomeManager(
      new AppHomeManager(
        Fire.getRTDB(),
        Fire.getFirestore(),
        OTUITree.auth.userId,
        OTUITree.auth.sessionToken,
        endActiveSession,
        async () => { },
        async () => { },
        () => { },
        async () => { },
        IdleTimer,
        ShortcutManager,
      )
    );
    await OTUITree.appHomeUI.init();

    const sessionToken = OTUITree.userManager.userDoc!.status?.sessionToken;

    const isMe = sessionToken && sessionToken == OTUITree.auth.sessionToken;
    const isActiveSession =
      sessionToken &&
      sessionToken in (OTUITree.userManager.userDoc!.status?.activeSessions || {});

    if (isMe || !isActiveSession) {
      await OTUITree.appHomeUI.start()
    }

    runInAction(() => { UIState.appInited = true })
  }
}

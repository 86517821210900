import React, { Component } from 'react';
import ReactPlayer from 'react-player'
import { IPluginConfig } from "@openteam/app-core";
import { IPluginResource, IUActiveCallPluginCallbacks } from "@openteam/models";
import { FaPlayCircle } from 'react-icons/fa';
import { observer } from 'mobx-react';

interface IYoutubePluginDetails extends IPluginResource {
  args: IVideoPlayerArgs
}

interface IVideoPlayerArgs {
  videoUrl: string,
  position: number,
  playing?: boolean,
  lastUpdate?: number
}

interface IVideoPlayerProps  extends IUActiveCallPluginCallbacks {
  pluginDetails: IYoutubePluginDetails
  height: number
  width: number
}
interface IVideoPlayerState {
  urlText: string
}



@observer
export class VideoPlayer extends Component<IVideoPlayerProps, IVideoPlayerState> {
  player = React.createRef<ReactPlayer>()

  videoLoaded: boolean = false
  constructor(props) {
    super(props);

    this.state = {
      urlText: ''
    }
    console.log("creating YouTube")
  }

  componentDidUpdate(prevProps) {
    var prevArgs: IVideoPlayerArgs = prevProps.pluginDetails.args
    var args: IVideoPlayerArgs = this.props.pluginDetails.args
    this.syncVideo(prevArgs, args)
  }

  syncVideo = (prevArgs: IVideoPlayerArgs, args: IVideoPlayerArgs) => {
    if (!this.player.current) {
      return
    }

    if (
      prevArgs.videoUrl == args.videoUrl &&
      prevArgs.playing == args.playing &&
      prevArgs.position == args.position &&
      prevArgs.lastUpdate == args.lastUpdate
    ) {
      return
    }

    // if (prevArgs.videoUrl !== args.videoUrl) {
    //   this.videoLoaded = false
    //   this.player.current.loadVideoById(args.videoId);
    // }


    // if (!prevArgs.playing && args.playing) {
    //   console.log("playing video")
    //   this.player.current.playVideo();
    // }

    // if (prevArgs.playing && !args.playing) {
    //   console.log("pausing video")
    //   this.player.current.pauseVideo();
    // }

    this.syncTime()

  }

  syncTime = (force = false) => {
    // safe to call this as often as you like as it works
    // out the time difference since the last position

    var args: IVideoPlayerArgs = this.props.pluginDetails.args

    var currentTime = this.getCurrentTime()
    var remoteTime = args.position

    if (args.playing && args.lastUpdate) {
      // account for time elapsed
      remoteTime += (Date.now() - args.lastUpdate)

    }

    if (args.playing || force) {
      if (Math.abs(currentTime - remoteTime) > 500) {

        this.player.current?.seekTo(remoteTime / 1000);
      }
    }
  }

  getCurrentTime = () => {
    return this.player.current ? this.player.current.getCurrentTime() * 1000 : 0
  }

  render() {
    const opts = {
      width: '100%',
      height: '100%',
      // playerVars: {
      //   // https://developers.google.com/youtube/player_parameters
      //   autoplay: 1,
      // },
    };

    if (!this.props.pluginDetails.args.videoUrl) {
      return null
    }

    return (
      <ReactPlayer
        ref={this.player}
        width={"100%"}
        height={"100%"}
        key={`videoplayer`}
        id={`videoplayer-${this.props.pluginDetails.pluginId}`}
        url={this.props.pluginDetails.args.videoUrl}
        playing={this.props.pluginDetails.args.playing}
        controls={true}
        onReady={this._onReady}
        onPause={this._onPause}
        onPlay={this._onPlay}
        onSeek={this._onSeek}
      />

    )
  }


  setArgs = (args: {}) => {
    this.props.updatePluginArgs(
      this.props.pluginDetails.pluginId,
      {
        position: this.getCurrentTime(),
        lastUpdate: Date.now(),
        ...args,

      },
      true
    )
  }

  _onPlay = () => {

    if (!this.props.pluginDetails.args.playing) {
      this.setArgs({ playing: true })
    } else {
      this.syncTime(true) // hack to get around youtube initialisation time
    }
    this.props.onPlaying(this.props.pluginDetails.pluginId, true);
  }

  _onPause = () => {
    if (this.props.pluginDetails.args.playing) {
      this.setArgs({ playing: false })
    }
    this.props.onPlaying(this.props.pluginDetails.pluginId, false);
  }

  _onSeek = (seconds) => {

    if (seconds * 1000 != this.props.pluginDetails.args.position) {
      this.setArgs({ position: seconds * 1000 })
    }
  }

  _onReady = (event) => {
    // access to player in all event handlers via event.target
    // var pluginDetails = this.props.pluginDetails
    // var args = pluginDetails.args

    // this.syncVideo({
    //   videoUrl: args.videoUrl,
    //   playing: false,
    //   position: 0
    // }, args)
  }
}

function getArgsFromURL(url: string): IVideoPlayerArgs | undefined {
  if (ReactPlayer.canPlay(url)) {
    return {
      videoUrl: url,
      playing: false,
      position: 0,
    }
  }
}

export const pluginType = 'videoplayer'
export const pluginConfig: IPluginConfig = {
  name: 'Video Player',
  multi: true,
  component: VideoPlayer,
  icon: FaPlayCircle,
  webInline: true,
  backgroundColor: 'black',
  canHandleUrl: getArgsFromURL,
  urlPriority: 50
}
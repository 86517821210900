import React, { useState } from 'react';
import { IPinnedResource } from '@openteam/models';
import { DSColumn, DSPanel, DSPanelScrollable, DSRow } from '../../../DesignSystem';
import { DSButton, DSCircleIconButton } from '../../../DesignSystem/DSButton';
import { DSPrint } from '../../../DesignSystem/DSText';

import { Logger } from '@openteam/app-util';
import { DSTheme } from '../../../DesignSystem/DSTheme';
import { fmtResourceName } from '../../Search/SearchControl';
import { FileIcon } from "../../FavIcon";
import { useHoverIntent } from '../../../Util/HoverIntent';
import { animated, useSpring } from '@react-spring/web';
import { FaPlus } from 'react-icons/fa';
import { AddResourceWindow } from './AddResourceWindow';
import { ResourcePropertiesWindow } from './ResourcePropertiesWindow';
import { DS2CreateButton, DS2CreateIconButton } from '../../../DesignSystem/DS2';

const logger = new Logger("PinnedResources");

export const PinnedResources: React.FC<{
  parentWindowId: string,
  spaceId: string,
  channelId: string,
  pinnedResources: IPinnedResource[],
  width: number,

  togglePinnedResource: (resource: IPinnedResource) => Promise<void>,
  deleteResource: (resource: IPinnedResource) => Promise<void>,
  setResourceName: (resource: IPinnedResource, name: string) => Promise<void>,
  getPinnedObject: (objectId: string) => IPinnedResource | undefined,
  inCall: boolean,

  openResource: (resource: IPinnedResource) => Promise<void>,
  sendURL: (text: string, systemMessage: string) => void,
}> = ({
  parentWindowId,
  pinnedResources,
  channelId,
  width,
  deleteResource,
  togglePinnedResource,
  setResourceName,
  getPinnedObject,
  openResource,
  inCall,
  sendURL
}) => {

  const [showAddResource, setShowAddResource] = useState(false);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const handleScroll = (e) => {
    setScrollTop(e.target.scrollTop);
  }

  return (
    <div style={{
      position: "relative",
      height: "100%",
      width: width,
    }}>
      <DSPanelScrollable
        style={{
          height: "100%"
        }}
        className="noScrollBar"
        onScroll={handleScroll}>
        {pinnedResources.map((resource) =>
          <PinnedResource
            key={resource.objectID}
            parentWindowId={parentWindowId}
            resource={resource}
            offsetY={scrollTop}
            width={width}
            togglePinnedResource={togglePinnedResource}
            deleteResource={deleteResource}
            setResourceName={setResourceName}
            getPinnedObject={getPinnedObject}
            openResource={openResource}
            inCall={inCall} />
        )}

        <DSPanel
          style={{
            alignItems: "center",
            margin: 5,
          }}
        >
          <DS2CreateIconButton
            onClick={() => { setShowAddResource(true) }}
          />
          {showAddResource ?

            <AddResourceWindow
              parentWindowId={parentWindowId}
              onClose={() => { setShowAddResource(false) }}
              sendURL={sendURL}
            /> : null
          }
        </DSPanel>
      </DSPanelScrollable>
    </div>
  )
};


const PinnedResource: React.FC<{
  parentWindowId: string,
  resource: IPinnedResource,
  offsetY: number,
  width: number
  togglePinnedResource: (resource: IPinnedResource) => Promise<void>,
  deleteResource: (resource: IPinnedResource) => Promise<void>,
  setResourceName: (resource: IPinnedResource, name: string) => Promise<void>,
  getPinnedObject: (objectId: string) => IPinnedResource | undefined,
  openResource: (resource: IPinnedResource) => Promise<void>,
  inCall: boolean,
}> = ({
  resource,
  offsetY,
  width,
  deleteResource,
  togglePinnedResource,
  setResourceName,
  getPinnedObject,
  openResource,
  inCall,
  parentWindowId }) => {
  const size = 30;
  const border = 4;
  const [isButtonHovering, setIsButtonHovering] = useState<boolean>(false);
  const [showWindow, setShowWindow] = useState<boolean>(false);


  const pinnedObject = getPinnedObject(resource.objectID);

  return (
    <DSPanel
      style={{
        alignItems: "center",
        margin: 5,
      }}
    >
      <DSButton
        key={resource.objectID}
        style={{
          width: size,
          height: size,
        }}
        data-tooltip={resource.name}
        // onClick={() => openResource(resource)}
        onClick={(e) => setShowWindow(true)}
        onHovered={(isHovering) => setIsButtonHovering(isHovering)}
      >
        <FileIcon hit={resource} size={size} allowPreview={true} />
      </DSButton>
      {showWindow && (
        <ResourcePropertiesWindow
          windowId={`resource-properties-${resource.objectID}`}
          parentWindowId={parentWindowId}
          onClose={() => setShowWindow(false)}
          togglePinnedResource={togglePinnedResource}
          deleteResource={deleteResource}
          setResourceName={setResourceName}
          openResource={openResource}
          inCall={inCall}
          isPinned={!!pinnedObject}
          resource={resource}
          createdAt={pinnedObject?.createdAt}
          createdBy={pinnedObject?.createdBy} />
      )}
    </DSPanel>
  );

}

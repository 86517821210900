
import { Logger } from '@openteam/app-util';
import { observer } from 'mobx-react';
import React, { Component, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { DSButton } from '../../DesignSystem/DSButton';
import { DSInput } from '../../DesignSystem/DSInput';
import { DSPanel, DSWrappedRow } from '../../DesignSystem';
import { DSBody, DSPrint } from '../../DesignSystem/DSText';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { validate } from 'validate.js';
import { Chip } from '@mui/material';


const logger = new Logger("EmailChipInput")

const validationConstraints = {
  draftEmail: {
    email: {
      message: "^Please enter a valid email address"
    }
  },
};

interface IEmailChipInputProps {
  onEmailsChange?: (emails) => void
}

interface IEmailChipInputState {
  emails: string[]
  curEmail: string
  errors?: { [id: string]: string[] }
}

export const EmailChipInput = observer((props: IEmailChipInputProps) => {
  const [emails, setEmails] = useState<Array<string>>([]);
  const [draftEmail, setDraftEmail] = useState<string>("");
  const [errors, setErrors] = useState<Record<string, string[]>>({});

  const clear = () => {
    setEmails([])
  }

  const onChangeText = (text) => {
    setDraftEmail(text.trim())
    setErrors({})
  }


  const handleKeyPress = (evt) => {
    if (evt.key === "Backspace" && draftEmail === "") {
      evt.preventDefault();
      if (emails.length > 0) {
        let curEmail = emails.pop()
        updateEmails(emails, curEmail)
        return false;
      }
    } else if ((evt.key === " " || evt.key === "Enter")) {
      evt.preventDefault();
      if (draftEmail != "") {
        addEmail()
      }
    }
    return true
  }

  const deleteEmail = (email) => {
    setEmails((_emails) => _emails.filter(e => e !== email))
  }

  const updateEmails = (emails, curEmail) => {
    setDraftEmail(curEmail)
    setEmails(emails)
  }

  useEffect(() => props.onEmailsChange?.(emails), [emails])


  const addEmail = () => {

    const validationResult = validate({ draftEmail: draftEmail }, validationConstraints);

    if (validationResult && draftEmail != "") {
      setErrors(validationResult);
      return
    }

    if (draftEmail) {
      const newEmail = draftEmail
      updateEmails(
        [...emails, newEmail],
        ''
      )
      return newEmail

    }
    return
  }


  return (
    <DSPanel>
        <ul style={{
          listStyle: 'none',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          padding: 0,
          marginBlock: 0}}>
        {
          emails.map((email, index) => (
            <li style={{margin: 2}}>
            <Chip
              key={`${index}-${email}`}
              size='small'
              color='secondary'
              label={email}
              onDelete={() => deleteEmail(email)}
            />
            </li>
          ))
        }
        </ul>

        <DSInput outerStyle={{
          marginTop: 10
        }}
          placeholder="Enter email address"
          value={draftEmail}
          onBlur={addEmail}
          onKeyDown={handleKeyPress}
          onChange={(e) => onChangeText(e.target.value)} />

      {
        Object.keys(errors).length ?
          <DSPrint style={{ color: 'red', textAlign: 'center', paddingTop: 10, }}>
            {
              Object.values(errors).map((it: string[]) => it.join('\n')).join('\n')
            }
          </DSPrint>
          : <DSPrint style={{ color: DSTheme.DisabledColor , textAlign: 'center', paddingTop: 10 }}>
              {draftEmail.length ? 'Press enter when done': null}
            </DSPrint>
      }
    </DSPanel>
  )
})

import React, { forwardRef, useCallback, useEffect } from 'react';
import jsx from "refractor/lang/jsx";
import typescript from "refractor/lang/typescript";

import { Remirror, useRemirror, EditorComponent } from '@remirror/react';

import {
  BlockquoteExtension,
  BoldExtension,
  CodeBlockExtension,
  CodeExtension,
  LinkExtension,
  PlaceholderExtension,
  StrikeExtension,
  TrailingNodeExtension,
} from "remirror/extensions";

import {
  CustomKeymapExtension,
  EhloEmojiExtension
} from "../../Chat/MarkdownEditorExtensions"

import MentionComponent from '../../Chat/MentionComponent';
import { EhloItalicExtension, EhloMarkdownExtension, EhloMentionAtomExtension, EhloMentionAtomState } from '../../Chat/RemirrorExtensions';

import { Logger } from '@openteam/app-util';
import { RemirrorEventListener } from 'remirror';


const logger = new Logger("MarkdownEditor");

export interface MarkdownEditorProps {
  placeholder?: string;
  initialContent?: string;
  onChange: (value) => void
  mentionUsers?: { id: string, label: string }[]
  toolbarRight?: JSX.Element
  onSubmit?: () => void
  disableMenu?: boolean
}

const DockPanelChatCompose = forwardRef(({
  placeholder,
  initialContent,
  onChange: _onChange,
  mentionUsers,
  onSubmit,
}: MarkdownEditorProps, ref) => {

  const extensions = useCallback(
    () => [
      new PlaceholderExtension({ placeholder }),
      new BlockquoteExtension(),
      new EhloEmojiExtension(),
      new LinkExtension({ autoLink: true }),
      new BoldExtension(),
      new StrikeExtension(),
      new EhloItalicExtension(),
      new EhloMentionAtomExtension({
        matchers: [
          { name: 'at', char: '@', appendText: '  ' },
        ],
      }),
      new CodeExtension(),
      new CodeBlockExtension({ supportedLanguages: [typescript, jsx] }),
      new TrailingNodeExtension(),
      new EhloMarkdownExtension({ copyAsMarkdown: false }),
      ...onSubmit ? [
        new CustomKeymapExtension({ onSubmit: onSubmit })
      ] : []

    ],
    [placeholder]
  );

  const { manager, state, setState } = useRemirror({
    extensions,
    stringHandler: "markdown",
    content: initialContent,
  });

  const onChange: RemirrorEventListener<Remirror.Extensions> = useCallback(
    ({ helpers, state }) => {
      setState(state)

      const newMarkdown = helpers.getMarkdown()
      logger.debug("markdown now ", newMarkdown)
      _onChange(newMarkdown)
    }, [_onChange])

  useEffect(() => {
    if (!initialContent) {
      logger.debug(`Clearing state`)
      manager.view.updateState(manager.createState())
    }
  }, [initialContent, manager])

  return (
    <Remirror
      key="remirror-editor"
      manager={manager}
      autoFocus
      state={state}
      onChange={onChange}
    >
      <EditorComponent key="editor" />
      <MentionComponent />
    </Remirror>
  );

});

export default DockPanelChatCompose;

import { OTGlobals } from "@openteam/app-core";
import { observer } from "mobx-react";
import { IUCallParticipant } from "@openteam/models";
import React, { CSSProperties, useEffect } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { DataState } from "../../../Data/DataState";
import { DSButton, DSCircleIconButton } from "../../../DesignSystem/DSButton";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { openCallMenu } from "./callButtonUtil";
import { openUserChat } from "../../../Data/UIState";

interface TileSettingsButtonProps {
    userId: string;
    color?: string
    size: number;
    style?: CSSProperties;
    isFocused: boolean;
    forceShow: boolean;
    onMenuOpen?: (boolean) => void
  }

  export const TileSettingsButton: React.FC<TileSettingsButtonProps> = observer(
    ({ userId, color, size, style, isFocused, forceShow, onMenuOpen, ...props }) => {
      const call = DataState.activeCall;
      const [menuOpen, setMenuOpen] = React.useState(false);
      const participant = call?.participants[userId];

      const toggleMenu = React.useCallback(() => {

        if (call && !menuOpen) {
          const template = getUserSettingsMenu(userId, true)
          if (template) {
            openCallMenu(
              template,
              () => setMenuOpen(false)
            );

            setMenuOpen(true);
          }
        }
      }, [menuOpen, setMenuOpen, participant, isFocused, call?.actions]);

      useEffect(() => {
        onMenuOpen && onMenuOpen(menuOpen)
      }, [menuOpen])

      return (
        <DSCircleIconButton
          analyticsEvent="callUserSettings"
          analyticsArgs={{ userId: userId }}
          style={{
            display: (menuOpen || forceShow) ? "initial" : "none",
            padding: size * 0.3,
            ...style,
          }}
          icon={<FaEllipsisV size={size ?? 22} color={DSTheme.MainIcon} />}
          onClick={toggleMenu}
          {...props}
        />
      );
    }
  );

  export function getUserSettingsMenu(userId: string, includeTitle: boolean): Electron.MenuItemConstructorOptions[] | undefined {
    const call = DataState.activeCall;
    const participant = call?.participants[userId];

    if (call && participant) {
      const muted = participant.streams['camera']?.muted;
      const isFocused = call.participantList.indexOf(userId) < call.numBigTiles;
      const template: Electron.MenuItemConstructorOptions[] = [
        {
          label: "Open chat with user",
          click: () => {
            openUserChat(call.spaceId, userId)
          }
        },
        {
          type: "checkbox",
          label: "Stop user audio just for me",
          checked: participant?.isSoundDisabled,
          click: () =>
            call.actions.setUserSoundDisabled(userId, !participant?.isSoundDisabled),
        },
        {
          type: "checkbox",
          label: "We are in the same room",
          toolTip: "You won't hear this user from your computer",
          checked: participant?.isSameRoom,
          click: () => {
            if (!participant?.isSameRoom) {
              call.actions.setUserSameRoom(userId);
            } else {
              call.actions.setUserSameRoom(null);
            }
          },
        },
        {
          type: "checkbox",
          label: "Focus user",
          checked: isFocused,
          click: () => call?.actions.focusUser(userId, !isFocused)
        },
        {
          type: "checkbox",
          label: "Mute user's Microphone for everyone",
          checked: muted,
          click: () => call?.actions.setUserMuted(userId, !muted)
        },
        { type: "separator"},
        {
          label: "Remove user from call",
          click: () => call?.actions.removeUserFromCall(userId)
        }
      ];

      if (includeTitle) {
        template.unshift({
          label: `User controls: ${participant.name}`,
          enabled: false
        })
      }
      return template;
    }
  }

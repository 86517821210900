import React from 'react';
import {
  IOTChatMessage,
  KSpaceId,
  IPinnedResource,
  ILinkPreview,
  IPendingMessage,
  IMessage
} from '@openteam/models';
import { Logger } from '@openteam/app-util';
import { DSBody } from '../../DesignSystem/DSText';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { ChatMessage } from './ChatMessage';
import { ChatLinkPreview } from './ChatLinkPreview';
import { ChatMarkdownView } from './ChatMarkdownView';
import { observer } from 'mobx-react';
import ChatFiles from './ChatFiles';
import { LightBoxEntry } from '../Lightbox/Lightbox';

const logger = new Logger('ChatMessageBody');

type Props = {
  spaceId: KSpaceId;
  message: IOTChatMessage;
  isReply?: boolean;
  updateResource?: (r: IPinnedResource, isPinned?: boolean, isResource?: boolean, name?: string) => Promise<void>;
  pendingMessage?: IPendingMessage;
  saveLinkToChat?: (id: string, linkPreview: ILinkPreview, linkId: string | undefined) => Promise<void>;
  showLightbox?: (items: LightBoxEntry[], initialSelected?: number) => void;
};

export const ChatMessageBody = observer((props: Props) => {
  const { message, updateResource, saveLinkToChat} = props;

  const linkPreviews = message.linkPreviews ?? (message.linkPreview ? [undefined] : [])

  return (
    <>
      {message.replyMessage && !props.isReply ? (
        <ChatMessage
          spaceId={props.spaceId}
          message={message.replyMessage}
          isReply={true}
          updateResource={updateResource}
          showLightbox={props.showLightbox}
        />
      ) : null}

      <ChatFiles
        spaceId={props.spaceId}
        message={message}
        pendingMessage={props.pendingMessage}
        updateResource={props.updateResource}
        showLightbox={props.showLightbox}
        filterMedia={true}
      />

      <ChatFiles
        spaceId={props.spaceId}
        message={message}
        pendingMessage={props.pendingMessage}
        updateResource={props.updateResource}
        showLightbox={props.showLightbox}
        filterMedia={false}
      />

      {message.isSystem ? (
        <DSBody style={{ color: DSTheme.SystemMessageColor, fontStyle: 'italic' }}>{message.message}</DSBody>
      ) : (
        <ChatMarkdownView
          key={message.id}
          message={message.message + (message.editDate ? '\n #edited#' : '')}
          spaceId={props.spaceId}
        />
      )}
      {
        Object.keys(linkPreviews).map((linkid, index) => (
          <ChatLinkPreview
            key={index}
            spaceId={props.spaceId}
            message={message as unknown as IMessage}
            linkId={linkid}
            updateResource={updateResource}
            saveLinkToChat={saveLinkToChat}
          />
        ))
      }
    </>
  );
});

import { FireDb, OTUITree, UIDataState } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { IMeetingRequestStatus, IMeetingTokenDetails } from "@openteam/models";

import { observer } from "mobx-react";
import React, { useLayoutEffect } from "react";
import { DataState } from "../../Data/DataState";
import { getDockSize, joinCall, UIState } from "../../Data/UIState";
import { DSFilledButton } from "../../DesignSystem/DSButton";
import { DSImage } from "../../DesignSystem/DSImage";
import { DSColumn, DSPanel, DSRow } from "../../DesignSystem";
import { DSSeparator } from "../../DesignSystem/DSSeparator";
import { DSSpinner } from "../../DesignSystem/DSSpinner";
import { DSH2, DSH3, DSH4 } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSModalPanel } from "../Modal/ModalLayout";
import { DropMenu } from "../Settings/DropMenu";
import { ModalOptions, PopupOptions, SubWindow, SubWindowCSS } from "../SubWindow";
const logger = new Logger("MeetingCreateWindow")

type Props = {
  externalMeeting: {
    meetingToken: string,
    meetingData?: IMeetingTokenDetails,
    status?: IMeetingRequestStatus,
    loadFailed?: boolean
    actions: {
      requestJoin: (name: string) => void
    }
  }
  targetRef: React.MutableRefObject<HTMLElement | null>;
  onClose: () => void
}

export const MeetingConnectWindow: React.FC<Props> = observer((props) => {
  const [position, setPosition] = React.useState<{ x: number, y: number }>()
  const windowId = `external-meeting`
  let width = 240
  let height = 240

  const calcPosition = () => {
    const dockSize = getDockSize()

    const offsetY = Math.floor(
      (UIState.dockPosition.height / 2) - (Math.min(UIState.dockPosition.height, dockSize.height) / 2))
      + (UIState.dockComponentLengths.get('MeetingIcon') || 0)

    const x = UIState.dockPosition.onLeft ?
      UIState.dockPosition.x + UIState.dockPosition.width :
      UIState.dockPosition.x - (width)
    const y = UIState.dockPosition.y + offsetY
    return { x, y }
  }

  useLayoutEffect(() => {
    setPosition(calcPosition())
  }, [])

  let haveChannel: boolean = false
  let meetingStarted: boolean = false
  let isMyRoom: boolean = false
  let title: string = "Open"
  let onClick: any | undefined = undefined

  if (props.externalMeeting.meetingData) {
    const meetingData = props.externalMeeting.meetingData
    const spaceId = meetingData.teamId

    const space = DataState.spaces[spaceId]
    const channelId = meetingData.channelId

    haveChannel = channelId && channelId in space.channels || false

    isMyRoom = !channelId && meetingData.userId == OTUITree.auth.userId

    onClick = () => {
      joinCall(spaceId, meetingData.userId, channelId, meetingData.name, props.externalMeeting.meetingToken)
    }
    if (isMyRoom) {
      title = "Start"
    } else if (channelId && haveChannel) {
      title = "Join"
    }
  }

  return position ? (

    <SubWindow
      id={windowId}
      show={true}
      initialPosition={position}
      initialSize={{ width: width, height: height }}
      minHeight={height}
      minWidth={width}
      onClose={props.onClose}
      focus={true}
      options={PopupOptions}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <DSModalPanel
        windowId={windowId}
        onClose={props.onClose}
        title={
          props.externalMeeting.meetingData ?
            <DSRow style={{ flex: 1, paddingLeft: 16, justifyContent: 'center' }}>
              {
                props.externalMeeting.meetingData.team.iconImageUrl ?
                  <DSImage
                    url={props.externalMeeting.meetingData.team.iconImageUrl}
                    height={42}
                    width={42}
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                  :
                  <div ></div>
              }
            </DSRow>
            :
            props.externalMeeting.loadFailed ?
              <DSH2 wrap style={{ flex: 1, color: DSTheme.DangerColor }}>
                Failed to load meeting details
              </DSH2>
              :
            <DSH2 style={{ flex: 1 }}>
              Loading meeting details
            </DSH2>

        }
      >
        {!props.externalMeeting.meetingData ?
          <DSPanel style={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}>
            {
              props.externalMeeting.loadFailed ?
                <></>
                :
                <DSSpinner />

            }
          </DSPanel>
          :
          <DSPanel style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: -16
          }}>
            <DSColumn style={{ alignItems: 'center', flex: 1, }}>

              <DSH3 style={{ marginTop: 4 }}>
                {props.externalMeeting.meetingData.team.teamName}
              </DSH3>
              <DSSeparator style={{ margin: "8 2", backgroundColor: "white", minHeight: 1, width: 42 }} />
              <DSH3 wrap>
                {
                  props.externalMeeting.meetingData.channel ?
                    props.externalMeeting.meetingData.channel.name
                    :
                    props.externalMeeting.meetingData.user.name

                }
              </DSH3>
              <DSH3 style={{ marginTop: 12 }}>
                Meeting:
              </DSH3>
              <DSH3 >
                {
                  props.externalMeeting.meetingData.name

                }
              </DSH3>
            </DSColumn>
            {
              !props.externalMeeting.status ?
                <DSFilledButton
                  onClick={onClick}
                  title={title} />
                :

                props.externalMeeting.status === 'P' ?
                  <>
                    <DSSpinner />
                    <DSH4>Requesting Access</DSH4>
                  </>
                  :
                  props.externalMeeting.status === 'H' ?
                    <DSH4 wrap style={{ textAlign: 'center' }}>
                      Your host has noticed your request and will admit you shortly.
                    </DSH4 >
                    :
                    props.externalMeeting.status === 'R' ?
                      <DSH4 wrap style={{ textAlign: 'center', color: DSTheme.DangerColor }}>
                        Your request to join the meeting has been rejected. Please contact the person who invited you.
                      </DSH4>
                      :
                      <></>
            }
          </DSPanel>

        }
      </DSModalPanel>
    </SubWindow>
  ) : null
})

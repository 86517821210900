import {
  Row,
  styled,
  IconButton,
  Icons,
  IconActionButton,
  Column,
  useTheme,
  darken,
  VSpacer,
  WrappedRow,
  lighten
} from '@openteam/design-system';
import { IMessageManager } from '@openteam/models';
import { Logger } from '@openteam/app-util';
import { EditorComponent, useCommands, useActive } from '@remirror/react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { FaPaperclip, FaQuoteRight, FaRegSmile, FaStrikethrough } from 'react-icons/fa';
import ChatComposeWrapper, { ComposeRef } from '../Chat/ChatComposeWrapper';
import { EmojiPicker } from '../Chat/ChatEmojiPicker';
import ChatFormattingMenu from './ChatFormattingMenu';
import { WebFile } from '@openteam/app-core';
import { GifPicker } from '../Chat/ChatGifPicker';
import { ChatFileUpload } from '../Chat/ChatFileUpload';

const logger = new Logger('CallChatCompose');

interface Props {
  windowId?: string;
  messageManager: IMessageManager;
  setDisableOnBlur?: (disable: boolean) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const acceptedImageFiles = ['image/gif', 'image/png', 'image/jpeg', 'image/bmp'];

const CallChatCompose: React.FC<Props> = ({ windowId, messageManager, setDisableOnBlur, ...props }) => {
  const [assetPicker, _setAssetPicker] = useState<string>();
  const initialContent = useRef(messageManager.draftMessage);
  const composeRef = useRef<ComposeRef>(null);
  const focusRef = useRef<FocusRef>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const [key, setKey] = useState(0);
  const theme = useTheme();

  const sendMessage = useCallback(() => {
    if (messageManager.draftMessage || messageManager.draftFiles.length > 0) {
      messageManager.sendChatMessage(
        messageManager.draftMessage,
        messageManager.draftFiles,
        messageManager.draftReplyMessage,
        windowId
      );
      messageManager.resetDraft();

      initialContent.current = '';
      setKey((v) => v + 1);

      if (assetPicker) {
        setAssetPicker(undefined);
      }
    }
  }, [windowId, messageManager]);

  const setMessage = useCallback(
    (msg: string) => {
      messageManager.setDraftText(msg);
    },
    [messageManager]
  );

  const focus = useCallback(() => {
    focusRef.current && focusRef.current.focus();
  }, []);

  const setAssetPicker = useCallback(
    (value?: string) => {
      logger.debug(`setAssetPicker: ${value}`);
      _setAssetPicker((curr) => (curr === value ? undefined : value));
      if (value === 'file' && fileInput.current) {
        setDisableOnBlur?.(true);
        fileInput.current.click();
      } else {
        setDisableOnBlur?.(false);
        focus();
      }
    },
    [setDisableOnBlur]
  );

  const onPaste = useCallback(
    (pasteEvent: React.ClipboardEvent<HTMLDivElement>) => {
      for (const item of pasteEvent.clipboardData.files) {
        if (acceptedImageFiles.includes(item.type)) {
          messageManager.addDraftFiles([item]);
        }
      }
      return false;
    },
    [messageManager]
  );

  const addEmoji = useCallback(
    (emoji: string) => {
      composeRef.current && composeRef.current.insertText(emoji);
      setAssetPicker();
      focus();
    },
    [focus]
  );

  const addGif = useCallback(
    (title, url, size) => {
      const newFile = new WebFile({
        name: title,
        url: url,
        size: size,
        type: 'image/gif'
      });
      logger.debug('adding gif', title, url, size, newFile);
      messageManager.addDraftMessageFile(newFile);
    },
    [messageManager]
  );

  const onFiles = useCallback(
    (files: FileList | null) => {
      if (files) {
        messageManager.addDraftFiles(Object.values(files));
      }
    },
    [messageManager]
  );

  const canSend = messageManager.draftMessage.length > 0 || messageManager.draftFiles.length > 0;

  logger.debug(`assetPicker: ${assetPicker}`);

  return (
    <ChatComposeWrapper
      key={key}
      ref={composeRef}
      onChange={setMessage}
      onSubmit={sendMessage}
      initialContent={initialContent.current}
      {...props}
    >
      <Column
        style={{
          background: lighten(theme.palette.secondary.main, 0.1),
          borderRadius: 4,
          overflow: 'hidden',
          margin: 4,
          ...{ appRegion: 'no-drag' }
        }}
        onPaste={onPaste}
      >
        {assetPicker == 'emoji' ? <EmojiPicker tileSize={28} scrollHeight={200} onEmojiSelected={addEmoji} /> : null}
        {assetPicker == 'gif' ? <GifPicker onGifSelected={addGif} /> : null}

        <WrappedRow style={{ }}>
          {messageManager.draftFiles.map((uf, index) => (
            <ChatFileUpload
              key={`${index}-${uf.file.name}`}
              size={36}
              file={uf}
              onRemove={() => messageManager.removeDraftMessageFile(index)}
            />
          ))}
        </WrappedRow>

        {assetPicker == 'format' ? <ChatFormattingMenu /> : null}

        <VSpacer size={2} />

        <FocusComponent ref={focusRef} />

        <EditorComponent key="editor" />

        <Row style={{ justifyContent: 'space-between', padding: 2 }}>
          <Row>
            <IconButton onClick={() => setAssetPicker('file')}>
              <FaPaperclip />
              <input
                ref={fileInput}
                style={{ display: 'none' }}
                type="file"
                multiple
                onChange={(e) => onFiles(e.target.files)}
              />
            </IconButton>
            <IconButton onClick={() => setAssetPicker('emoji')}>
              <FaRegSmile />
            </IconButton>
            <IconButton onClick={() => setAssetPicker('gif')} style={{ fontSize: 14 }}>
              GIF
            </IconButton>

            <IconButton
              onClick={() => setAssetPicker('format')}
              style={{ textDecoration: assetPicker === 'format' ? 'underline' : undefined, fontSize: 14 }}
            >
              Aa
            </IconButton>
          </Row>

          <IconActionButton disabled={!canSend} color="primary" onClick={sendMessage}>
            {Icons.send}
          </IconActionButton>
        </Row>
      </Column>
    </ChatComposeWrapper>
  );
};

export default observer(CallChatCompose);

export const InlineChatCompose: React.FC<Props> = observer(({ windowId, messageManager, ...props }) => {
  const initialContent = useRef(messageManager.draftMessage);
  const composeRef = useRef<ComposeRef>(null);
  const focusRef = useRef<FocusRef>(null);
  const [key, setKey] = useState(0);
  const theme = useTheme();

  const sendMessage = useCallback(() => {
    if (messageManager.draftMessage || messageManager.draftFiles.length > 0) {
      messageManager.sendChatMessage(
        messageManager.draftMessage,
        messageManager.draftFiles,
        messageManager.draftReplyMessage,
        windowId
      );
      messageManager.resetDraft();

      initialContent.current = '';
      setKey((v) => v + 1);
    }
  }, [windowId, messageManager]);

  const setMessage = useCallback(
    (msg: string) => {
      messageManager.setDraftText(msg);
    },
    [messageManager]
  );

  const onPaste = useCallback(
    (pasteEvent: React.ClipboardEvent<HTMLDivElement>) => {
      for (const item of pasteEvent.clipboardData.files) {
        if (acceptedImageFiles.includes(item.type)) {
          messageManager.addDraftFiles([item]);
        }
      }
      return false;
    },
    [messageManager]
  );

  const canSend = messageManager.draftMessage.length > 0 || messageManager.draftFiles.length > 0;

  return (
    <ChatComposeWrapper
      key={key}
      ref={composeRef}
      onChange={setMessage}
      onSubmit={sendMessage}
      placeholder="Send quick message"
      initialContent={initialContent.current}
      {...props}
    >
      <Row
        className='inline'
        style={{
          background: lighten(theme.palette.secondary.main, 0.1),
          borderRadius: 4,
          overflow: 'hidden',
          margin: 0,
          flex: 1,
          maxHeight: 28,
          alignItems: 'flex-end'
        }}
        onPaste={onPaste}
      >
        <FocusComponent ref={focusRef} />

        <EditorComponent key="inline-editor" />

        <IconActionButton disabled={!canSend} color="primary" onClick={sendMessage}>
          {Icons.send}
        </IconActionButton>
      </Row>
    </ChatComposeWrapper>
  );
});

export type FocusRef = {
  focus: () => void;
};

const FocusComponent = forwardRef<FocusRef>((_, ref) => {
  const { focus } = useCommands();
  useImperativeHandle(ref, () => ({ focus }));
  return null;
});

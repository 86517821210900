import React from 'react';

import { Logger } from "@openteam/app-util";
import { observer } from 'mobx-react';
import { DSColumn, DSPanel, DSRow, DSVSpacer } from '../../DesignSystem';
import { DSTheme } from '../../DesignSystem/DSTheme';
import UserIcon from '../User/UserIcon';
import { IUIUserMinimal } from '@openteam/models';
import { DSBody, DSH3, DSH4, DSLargePrint, DSPrint } from '../../DesignSystem/DSText';
import { timeSince } from '../../Util/DateFormat';

const logger = new Logger("DockNotifyPanel");

interface IDockNotifyPanelProps {
  user: IUIUserMinimal
  title: string
  desc?: string
  time?: Date
  rightIcon?: JSX.Element
  buttons: JSX.Element
  closeWindow?: (onCloseCallback?: () => void) => void
}

export const DockNotifyPanel: React.FC<IDockNotifyPanelProps> = observer((props) => {

  return (
    <DSPanel style={{ padding: "12 12", minWidth: 300, color: DSTheme.MainText }}>
      <DSRow style={{ alignItems: 'center' }}>
        <UserIcon
          user={props.user}
          size={25}
          hideStatusDot={true}
        />
        <DSColumn style={{ marginLeft: 8, marginRight: 8, flex: 1 }}>
          <DSH3>
            {props.title}
          </DSH3>
          {
            props.desc ?
              <DSBody>
                {props.desc}
              </DSBody>
              :
              undefined
          }
        </DSColumn>

        {props.rightIcon ?
          props.rightIcon
          :
          props.time ?
            <DSLargePrint>
              {
                timeSince(props.time, " ago")
              }
            </DSLargePrint>
            : undefined}

      </DSRow>
      <DSVSpacer size={12} />

      {props.children}

      <DSVSpacer size={12} />
      {props.buttons}
    </DSPanel>
  );
})

import React, { CSSProperties, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Logger } from "@openteam/app-util";
import { observer } from "mobx-react";
import { DockSeparator } from "./DockSeparator";
import { DataState } from "../../Data/DataState";
import { OTUITree } from "@openteam/app-core";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { dockAnimatingStart, dockAnimatingStop, setDockComponentLength, UIState } from "../../Data/UIState";
import { autorun, makeAutoObservable } from "mobx";
import { DSAnimateListGrow } from "../../DesignSystem/DSAnimate";
import { IUCall } from "@openteam/models";
import { PodSettingsWindow } from "../Pod/PodSettingsWindow";
import { tutorialState } from "../../Controllers/TutorialService";
import { TutorialStepHolder } from "../Tutorial/TutorialStepHolder";
import { isDummyCall } from "../../Data/tutorialDummy";
import { hashObject } from "react-hash-string";
import { DockRoomIcon, getDockRoomLength } from "./DockRoomIcon";
import { ToolTipComponent } from "../../Controllers/ToolTips";
import { DSColumn, DSRow } from "../../DesignSystem";
import { DSH3, DSPrint, DSSmallPrint } from "../../DesignSystem/DSText";
import UserIcon from "../User/UserIcon";
import { FaPlus, FaThLarge } from "react-icons/fa";
import { DockOverflowIcon, DockOverflowRoomPanel } from "./DockOverflow";

const logger = new Logger("DockRooms")



interface IDockRoomsProps {
  spaceId: string
}

export const DockRooms = observer((props: IDockRoomsProps) => {
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const myUserId = OTUITree.auth.userId;
  const space = DataState.spaces[props.spaceId]
  const [currentPods, setCurrentPods] = useState<Record<string, number>>({});
  const [podCalls, setPodCalls] = useState<Record<string, IUCall>>({})
  const [isEmpty, setIsEmpty] = useState(false);


  const itemLengthRatio = (1 - DSTheme.DockMargin * 2);
  const roomSizeRatio = DSTheme.DockRoomRatio;
  const placeholderRatio = DSTheme.DockPlaceholderRatio

  const itemLength = Math.floor(itemLengthRatio * UIState.dockSize);
  const roomSize = Math.floor(roomSizeRatio * UIState.dockSize);
  const placeholderLength = Math.floor(placeholderRatio * UIState.dockSize);


  logger.debug("roomSizeRatio", roomSizeRatio, "itemLengthRatio", itemLengthRatio)

  useEffect(
    () =>
      autorun(() => {
        setPodCalls(
          Object.fromEntries(
            Object.values(space.calls)
              .filter((call) => call.channelId !== undefined)
              .map((call) => [call.channelId, call])
          )
        );
      }),
    []
  );


  useEffect(() => autorun(() => {
    //logger.debug(`in DockRooms autorun with ${Object.keys(podCalls).length} calls`);

    const isInteresting = (podId) => {
      const pod = space.pods[podId];
      const call = podCalls[podId];

      let interesting = pod.pinned ||
        (UIState.openChats[props.spaceId] && podId in UIState.openChats[props.spaceId].channels) ||
        (pod.numUnread ?? 0) > 0 ||
        (UIState.dockOpenRoomPanels[props.spaceId] && podId && podId in UIState.dockOpenRoomPanels[props.spaceId])
      ;

      if (call) {
        if (myUserId in call.participants) {
          interesting = false
        } else {
          interesting = true
        }
      }

      if (interesting)
        logger.debug(`${podId} is interesting, pinned ${pod.pinned}, unread ${pod.numUnread}, call ${call && myUserId in call.participants}`)
      return interesting;
    }

    let interestingPods = Object.keys(space.pods)
      .filter(isInteresting)
      .sort((a, b) => (space.pods[a].name.toLowerCase() > space.pods[b].name.toLowerCase()) ? 1 : -1);

    if (tutorialState.active) {
      interestingPods = []
    }

    if (tutorialState.showDummyRoom && !podCalls["tutorialroom"]) {
      interestingPods.unshift("tutorialroom")
    }
    if (tutorialState.showDummyRoomCalls) {
      interestingPods.unshift("tutorialcall2")
      interestingPods.unshift("tutorialcall1")
    }

    const podHeights = Object.fromEntries(
      interestingPods.map((podId, index) => (
        [podId, getDockRoomLength(props.spaceId, isDummyCall(podId) ? podId : podCalls[podId]?.id, roomSizeRatio, itemLengthRatio)])
      ));


    const height = Object.values(podHeights).reduce((a, b) => a + b, 0);

    setDockComponentLength('Pods', height);

    setIsEmpty(interestingPods.length === 0);

    setCurrentPods((_curr) => {
      if (hashObject(_curr) !== hashObject(podHeights)) {
        logger.debug(`Interesting rooms`, podHeights)
        return podHeights;
      }
      return _curr
    })

    return () => { setDockComponentLength('Pods', 0) }

  }), [props.spaceId, podCalls, space.pods])

  const renderRoom = useCallback((podId, index) => {
    //logger.debug(`renderpod ${podId}, ${index}/${numPods.current} atEnd ${atEnd}`)
    if ("tutorialroom" == podId) {
      return (
        <TutorialStepHolder
          key={podId}
          parentId={"__dock__"}
          ids={["room-interact"]}
          position={UIState.dockPosition.onLeft ? 'right' : 'left'}
          ready={!UIState.dockAnimating}
          offsetY={110}
          offsetX={UIState.dockPosition.onLeft ? 20 : -20}>
          <DockRoomIcon
            key={podId}
            spaceId={props.spaceId}
            podId={podId}
            callId={podCalls[podId]?.id}
          />
        </TutorialStepHolder>
      )
    } else if (["tutorialcall1", "tutorialcall2"].includes(podId)) {
      if ("tutorialcall1" == podId) {
        return (
          <TutorialStepHolder
            key={podId}
            parentId={"__dock__"}
            ids={["other-calls"]}
            position={UIState.dockPosition.onLeft ? 'right' : 'left'}
            offsetX={UIState.dockPosition.onLeft ? 20 : -20}>
            <DockRoomIcon
              key={podId}
              spaceId={props.spaceId}
              podId={podId}
              callId={podId}
            />
          </TutorialStepHolder>)
      }
      return (
        <DockRoomIcon
          key={podId}
          spaceId={props.spaceId}
          podId={podId}
          callId={podId}
        />
      )
    } else if (!space.pods[podId]) {
      return null
    } else {
      return (
        <DockRoomIcon
          key={podId}
          spaceId={props.spaceId}
          podId={podId}
          callId={podCalls[podId]?.id}
        />
      )
    }
  }, [props.spaceId, currentPods, podCalls, myUserId])

  const getPodSizeStyle = useCallback((podId, index) => {
    return {
      height: UIState.dockHorizontal ? UIState.dockSize : Math.floor(currentPods[podId] * UIState.dockSize),
      width: UIState.dockHorizontal ? Math.floor(currentPods[podId] * UIState.dockSize) : UIState.dockSize
    };
  }, [currentPods, UIState.dockHorizontal]);

  const fromStyle = {
    height: UIState.dockHorizontal ? undefined : 0,
    width: UIState.dockHorizontal ? 0 : undefined
  }
  const leaveStyle = {
    height: UIState.dockHorizontal ? undefined : 0,
    width: UIState.dockHorizontal ? 0 : undefined
  }

  const onAnimationStart = useCallback(() => dockAnimatingStart('dock-rooms'), []);
  const onAnimationStop = useCallback(() => dockAnimatingStop('dock-rooms'), []);

  const getRoomTooltip = useCallback((podId) => {
    const space = DataState.spaces[props.spaceId]
    const pod = space.pods[podId];
    const call = podCalls[podId];
    return (
      <DSColumn spacing={4}>
        <DSH3>
          {pod?.name ?? space.channelDirectory[podId]?.name ?? call?.name}
          {call ? (' - ongoing call') : null}
        </DSH3>
        {call ? (
          Object.values(call.participants).map(user => (
            <DSRow key={user.id} spacing={2}>
              <UserIcon user={user} showTooltip={false} showCustomEmoji={false} hideStatusDot={true} size={14} />
              <DSPrint>{user.name}</DSPrint>
            </DSRow>
          ))
        ) : pod?.desc ? <DSPrint>{pod.desc}</DSPrint> : null
        }
      </DSColumn>
    )
  }, [props.spaceId, podCalls])

  const getCallTooltip = useCallback((callId) => {
    const space = DataState.spaces[props.spaceId]
    const call = space.calls[callId];
    return (
      <DSColumn spacing={4}>
        <DSH3>
          {call?.name}
          {call ? (' - ongoing call') : null}
        </DSH3>
        {call ? (
          Object.values(call.participants).map(user => (
            <DSRow key={user.id} spacing={2}>
              <UserIcon user={user} showTooltip={false} showCustomEmoji={false} hideStatusDot={true} size={14} />
              <DSPrint>{user.name}</DSPrint>
            </DSRow>
          ))
        ) : null}
      </DSColumn>
    )
  }, [props.spaceId])

  //useLayoutEffect(() => logger.debug(`Rendering`))
  return (
    <>
      {
        Object.keys(currentPods).length > 0 ?
          <DockSeparator />
          :
          null
      }
      <DSAnimateListGrow
        label='DockRooms'
        width={UIState.dockHorizontal ? itemLength : UIState.dockSize}
        height={UIState.dockHorizontal ? UIState.dockSize : itemLength}
        horizontal={UIState.dockHorizontal}
        itemStyle={{
          position: 'relative',
        }}
        items={Object.keys(currentPods)}
        renderItem={renderRoom}
        fromStyle={fromStyle}
        leaveStyle={leaveStyle}
        initialStyle={getPodSizeStyle}
        enterStyle={getPodSizeStyle}
        outerStyle={Styles.outerStyle}
        onListStart={onAnimationStart}
        onListRest={onAnimationStop}
      />
      {showAdd ? (
        <PodSettingsWindow spaceId={props.spaceId} onClose={() => { setShowAdd(false) }} />
      ) : null}

      <DockOverflowRoomIcon spaceId={props.spaceId} isEmpty={isEmpty} />

      <ToolTipComponent id='dock-room' getContent={getRoomTooltip} />

      <ToolTipComponent id='dock-call' getContent={getCallTooltip} />

    </>
  )
})


interface Props {
  spaceId: string
  isEmpty: boolean
}

const DockOverflowRoomIcon: React.FC<Props> = observer(({ spaceId, isEmpty }) => {

  useEffect(() => {
    setDockComponentLength('RoomOverflow', isEmpty ? 1.2 : 0.5)
    return () => setDockComponentLength('RoomOverflow', 0)
  }, [isEmpty])

  return (
    <DockOverflowIcon
      spaceId={spaceId}
      data-tooltip={isEmpty ? 'Add a room to your dock' : 'All Rooms'}
      size={UIState.dockSize * (UIState.dockComponentLengths.get('RoomOverflow') ?? 0)}
      panel={DockOverflowRoomPanel}
    >
      {
        isEmpty ?
          <>
            <FaPlus size={UIState.dockSize * 0.5 * .5} />
            {UIState.dockSize > 75 ?
              <DSSmallPrint style={{ lineHeight: '110%' }} wrap>
                Add room
              </DSSmallPrint>
              : null
            }
          </>
          : null
      }
      <FaThLarge size={UIState.dockSize * 0.5 * .7} />
    </DockOverflowIcon>
  )
})

class StylesClass {

  constructor() {
    makeAutoObservable(this);
  }

  get outerStyle (): CSSProperties {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }

}

const Styles = new StylesClass();

import { OTUITree } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { UIState } from "../../Data/UIState";
import { DataState } from "../../Data/DataState";
import { Draggable } from "../Draggable";
import { PopupOptions, SubWindow, SubWindowCSS, SubWindowHandle } from "../SubWindow";
import { ConnectContent } from "./ConnectContent";
import { LoadingContent } from "./LoadingContent";
import { LoginContent } from "./LoginContent";
import { autorun, toJS } from "mobx";
import { CreateSpaceContent } from "./CreateSpaceContent";

const logger = new Logger("InitWindow")


type InitWindowProps = {
  stage: ReturnType<typeof getStage>
  onClose: () => void
};

export const getStage = () => {
  if (!OTUITree._auth)
    return "loading";

  if (!OTUITree.auth.isLoaded) {
    return "loading";
  }
  if (!OTUITree.auth.userId) {
    return "login";
  }
  if (!UIState.appInited) {
    return "initialising";
  }
  if (!OTUITree._appHomeUI?.started || !OTUITree.userManager.userTeamsIndexLoaded) {
    return "connecting";
  }
  //logger.debug("userTeamsIndex: ", toJS(OTUITree.userManager.userTeamsIndex));
  //logger.debug("OTUITree.userManager.currentTeamId: ", OTUITree.userManager.currentTeamId);

  if (Object.keys(OTUITree.userManager.userTeamsIndex).length === 0) {
    return "createSpace";
  }

  if (!OTUITree.userManager.currentTeamId || !DataState.spaces[OTUITree.userManager.currentTeamId!]) {
    return "loadSpace";
  }

  const ownerUserId = OTUITree.teamManagers[OTUITree.userManager.currentTeamId!]?._teamDoc?.ownerUserId;
  const isOwner = ownerUserId === OTUITree.auth.userId;
  //logger.debug(`currentTeamId: ${OTUITree.userManager.currentTeamId} userId: ${OTUITree.auth.userId} ownerUserId: ${ownerUserId} isOwner: ${isOwner} ehloOnboardingAddedTeamMembers ${OTGlobals.remoteUserSettings.ehloOnboardingAddedTeamMembers}`)

  //if (isOwner && !OTGlobals.remoteUserSettings.ehloOnboardingAddedTeamMembers && Object.keys(OTUITree.userManager.userTeamsIndex).length === 1) {
  //  return "addTeamMembers";
  //}

  return "ready";
}

export const InitWindow = observer((props: React.PropsWithChildren<InitWindowProps>) => {
  const id = "InitWindow"
  const title = `${UIState.productName}`;
  const { stage } = props;
  const subWindowRef = React.useRef<SubWindowHandle>(null);
  const [height, setHeight] = useState(675);
  const width = 460;

  useEffect(() => {
    (async () => {
      if (stage === 'loadSpace') {
        // don't change the height!
      } else {
        // const newHeight = ['loading', 'login', 'initialising', 'connecting'].includes(stage) ? 500 : 720;
        const newHeight = 675
        const { workAreaSize } = (await window.Main.getPrimaryDisplay());

        const x = workAreaSize.width > width ? (workAreaSize.width - width) / 2 : 0;
        const y = workAreaSize.height > newHeight ? (workAreaSize.height - newHeight) / 2 : 0;

        if (newHeight != height && subWindowRef?.current) {
          subWindowRef.current.setBounds({ x, y, width, height });
          setHeight(newHeight);
          logger.info(`setBounds: ${x} ${y} ${width} ${height}`);
        }
      }
    })();
  }, [
    stage,
    subWindowRef
  ])

  useEffect(() => {
    const offLogin = window.Main.on("open-login", () => {
      subWindowRef.current?.show(true, true)
    })

    return () => {
      offLogin()
    }
  }, [])

  logger.info(`stage: ${stage}`);

  return (
    <SubWindow
      ref={subWindowRef}
      id={id}
      height={height}
      width={width}
      minHeight={height}
      minWidth={width}
      maxHeight={height}
      maxWidth={width}
      focus={true}
      show={true}
      onClose={props.onClose}
      title={title}
      options={{
        ...PopupOptions,
        resizable: true,
        alwaysOnTop: false,
        skipTaskbar: false,
      }}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <Draggable
        windowId={id}
        style={{
          display: 'flex', flex: 1, overflow: 'hidden'
        }}>
        {
          stage === 'loading' ?
            <LoadingContent message="Loading" />
            :
            stage === 'login' ?
              <LoginContent />
              :
              stage === 'initialising' ?
                <LoadingContent message="Initialising" />
                :
                stage === 'connecting' ?
                  <ConnectContent />
                  :
                  stage === 'createSpace' ?
                    <CreateSpaceContent />
                    :
                    stage === 'loadSpace' ?
                      <LoadingContent message="Loading workspace" />
                      :
                      <LoadingContent message="Workspace Loaded" />
        }

      </Draggable>
    </SubWindow >
  );
});

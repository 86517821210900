import React, { useState, useEffect, useRef, useCallback, CSSProperties, ReactNode } from 'react';
import { observer } from 'mobx-react';

import { OTUITree, CallDetailsDb, searchIndexVersion } from '@openteam/app-core';
import { DataState } from '../..//Data/DataState';

import algoliasearch, { SearchIndex } from 'algoliasearch';
import { DSColumn, DSHSpacer, DSPanel, DSPanelScrollable, DSRow, DSWrappedRow } from '../../DesignSystem';
import { debounce } from 'throttle-debounce';


import { Logger } from '@openteam/app-util';
import { IFileHit, IMessageHit, IOTChatMessage, KSpaceId, ITeamUserHit, IChannelHit, ISearchFileRecord, IUISpaceUser } from '@openteam/models';
import { ChatMessage } from '../Chat/ChatMessage';
import { addToRecentUsers, openPod, openUserChat, UIState } from '../../Data/UIState';
import { DSButton } from '../../DesignSystem/DSButton';
import { autorun, runInAction } from 'mobx';
import DSSearchBox from '../../DesignSystem/DSSearch';
import { DSH3, DSPrint } from '../../DesignSystem/DSText';
import { FaSearch } from 'react-icons/fa';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { getFriendlyDate, getFullDate, isToday } from '../../Util/DateFormat';
import { DSTab, DSTabs } from '../../DesignSystem/DSTabs';
import UserIcon from '../User/UserIcon';
import { getStringBackground } from '../../Util/StringColor';
import { createNullCache } from "@algolia/cache-common"
import { SearchResponse, Hit } from "@algolia/client-search";
import { Draggable } from '../Draggable';
import * as Analytics from '../../Util/Analytics'

import * as Fire from "../../Controllers/Fire"
import { CallSummaryUser } from '../Chat/ChatCallSummary';
import { FileIcon } from '../FavIcon';
import { DS2CallButton, DS2CloseIconButton, DS2IconActionButton, DS2Icons, DS2LeaveCallButton, DS2PinButton } from '../../DesignSystem/DS2';

const logger = new Logger("SearchControl");


interface ISearchControlProps {
  windowId: string
  spaceId: string
  onClose: () => void
}


const hitsPerPage = 10;

export const SearchControl = observer((props: ISearchControlProps) => {
  const spaceId = OTUITree.userManager.currentTeamId!


  const [msgResults, setMsgResults] = useState<SearchResponse<IMessageHit> | undefined>(undefined);
  const [fileResults, setFileResults] = useState<SearchResponse<IFileHit> | undefined>(undefined);
  const [teamUserResults, setTeamUserResults] = useState<SearchResponse<ITeamUserHit> | undefined>(undefined);
  const [channelResults, setChannelResults] = useState<SearchResponse<IChannelHit> | undefined>(undefined);

  useEffect(() => {
    Analytics.logEvent("Screen_Search")
  }, [])

  const [tabId, setTabId] = useState<string>("all");
  const searchIndexes = useRef<{
    messages?: SearchIndex,
    files?: SearchIndex,
    teamUsers?: SearchIndex,
    channels?: SearchIndex
  } | undefined>(undefined);

  const setQ = (q: string) => {
    runInAction(() => {
      UIState.dockSearchTerm = q
    }
    );
    debouncedSearch(q);
  }

  const debouncedSearch = useCallback(debounce(500, (q: string) => executeSearch(q)), []);

  const executeSearchById = (q: string, tabId: string, page: number) => {

    Analytics.logEvent("SearchTerm", {
      query: q,
      tabId: tabId,
      page: page
    })


    if (searchIndexes.current) {
      if (tabId === "messages") {
        if (searchIndexes.current.messages) {
          searchIndexes.current.messages.search<IMessageHit>(
            q,
            {
              page,
              hitsPerPage,
            }
          ).then((res) =>
            setMsgResults(res)
          )
        }
      } else if (tabId === "files") {
        if (searchIndexes.current.files) {
          searchIndexes.current.files.search<IFileHit>(
            q,
            {
              filters: 'isResource:true',
              page,
              hitsPerPage,
            }
          ).then((res) =>
            setFileResults(res)
          )
        }
      } else if (tabId === "teamUsers") {
        if (searchIndexes.current.teamUsers) {
          searchIndexes.current.teamUsers.search<ITeamUserHit>(
            q,
            {
              page,
              hitsPerPage,
            }
          ).then((res) =>
            setTeamUserResults(res)
          )
        }
      } else if (tabId === "channels") {
        if (searchIndexes.current.channels) {
          searchIndexes.current.channels.search<IChannelHit>(
            q,
            {
              page,
              hitsPerPage,
            }
          ).then((res) => {
            const space = DataState.spaces[spaceId];
            res.hits = res.hits.filter((hit) => hit.channelId in space.pods)
            setChannelResults(res)
          }
          )
        }
      }
    }
  }

  const executeSearch = async (q: string) => {
    logger.debug("executeSearch:", q);
    if (q.length === 0) {
      setMsgResults(undefined);
      setFileResults(undefined);
      setChannelResults(undefined);
      setTeamUserResults(undefined);
    } else {
      executeSearchById(q, "messages", 0);
      executeSearchById(q, "teamUsers", 0);
      executeSearchById(q, "channels", 0);
      executeSearchById(q, "files", 0);
    };
  }
  const searchKeys = OTUITree.teamManagers[spaceId].searchKeys;

  useEffect(() => autorun(
    () => {
      if (searchKeys) {
        logger.debug(`searchKeys.appId: ${searchKeys.appId} searchKeys.files: ${searchKeys.files}`);

        const options = {
          responsesCache: createNullCache()
        }

        const getIndexName = (index: string) => `${index}-v${searchIndexVersion}`;
        const indexes = {
          messages: algoliasearch(searchKeys.appId, searchKeys.messages, options).initIndex(getIndexName("messages")),
          files: algoliasearch(searchKeys.appId, searchKeys.files).initIndex(getIndexName("files-pinnedAtDesc")),
          teamUsers: algoliasearch(searchKeys.appId, searchKeys.teamUsers).initIndex(getIndexName("teamUsers")),
          channels: algoliasearch(searchKeys.appId, searchKeys.channels).initIndex(getIndexName("channels")),
        };

        searchIndexes.current = indexes;

      } else {
        searchIndexes.current = undefined;
      }
      executeSearch(UIState.dockSearchTerm);
    }
  ), [spaceId, searchKeys]);

  return (
    <DSColumn style={{ height: "100%" }}>
      <Draggable windowId={props.windowId}>
        <DSRow style={{
          backgroundColor: DSTheme.PanelHeaderColor,
        }}>

          <DSColumn style={{
            flexGrow: 1,
            height: 50,
            paddingLeft: 20,
            paddingRight: 6,
            paddingBottom: 10,
            paddingTop: 0,
          }}>
            <DSSearchBox
              value={UIState.dockSearchTerm}
              handleChange={(event) => setQ(event.target.value)}
              innerStyle={{ flexGrow: 1 }}
              style={{ ...DSTheme.NoDrag, flexGrow: 1, paddingTop: 5, marginRight: 300 }}
              inputStyle={{ width: "100%" }}
              autoFocus={true}
              placeholder={"Type to search for a teammate, channel or file"}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  logger.debug("escape pressed")
                  e.preventDefault(); // Ensure it is only this code that runs

                  if (UIState.dockSearchTerm) {
                    UIState.dockSearchTerm = ''
                  } else {
                    props.onClose()
                  }
                }
              }}
              onKeyPress={
                (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Ensure it is only this code that runs
                    executeSearch(UIState.dockSearchTerm);
                  }
                }
              }
            />
          </DSColumn>

          <DSColumn style={{ paddingRight: 10, paddingTop: 10 }}>
            <DS2CloseIconButton onClick={props.onClose} />
          </DSColumn>

        </DSRow>
      </Draggable>

      <DSRow
        style={{
          backgroundColor: DSTheme.PanelHeaderColor,
        }}
      >
        <DSTabs
          activeTabId={tabId}
          onChange={(tabId: string) => setTabId(tabId)}
          style={{ paddingLeft: 10, paddingRight: 10 }}>
          <DSTab name="All" tabId="all" />
          <DSTab name="Teammate" tabId="teamUsers" />
          <DSTab name="Rooms" tabId="channels" />
          <DSTab name="Chat" tabId="messages" />
          <DSTab name="Drive" tabId="files" />
        </DSTabs>
      </DSRow>

      <SearchResults
        tabId={tabId}
        setTabId={setTabId}
        spaceId={spaceId}
        setPage={executeSearchById}
        results={{
          teamUsers: teamUserResults,
          channels: channelResults,
          files: fileResults,
          messages: msgResults,
        }}
      />
    </DSColumn >
  );
})


export const SearchResults: React.FC<{
  tabId: string,
  setTabId: (tabId: string) => void,
  spaceId: string,
  setPage: (q: string, tabId: string, page: number) => void,
  results: {
    teamUsers: SearchResponse<ITeamUserHit> | undefined,
    channels: SearchResponse<IChannelHit> | undefined,
    files: SearchResponse<IFileHit> | undefined,
    messages: SearchResponse<IMessageHit> | undefined,
  }
}> = ({ tabId, setTabId, spaceId, results, setPage }) => {
  const displayNames = {
    teamUsers: 'Teammate',
    channels: 'Rooms',
    files: 'Drive',
    messages: 'Chat',
  }
  const resultElements = {
    teamUsers: SearchResultUser,
    channels: SearchResultChannel,
    files: SearchResultFile,
    messages: SearchResultChat,
  }

  const displayOrder = ['teamUsers', 'channels', 'files', 'messages'];

  const hasResults = Object.values(results).some(x => x && x.hits.length > 0);

  const emptyPanel = () => <FaSearch size={128} style={{ color: DSTheme.PanelContrastColor, opacity: 0.2, margin: "auto", marginTop: 130 }} />

  const renderAll = () => (
    <div style={{
      display: "flex",
      flexDirection: "column",
      padding: "5 10",
    }}>
      {hasResults ? (
        displayOrder.map(
          (tabId) =>
            results[tabId] && results[tabId].hits.length > 0 ? (
              <DSColumn key={`tab-${tabId}`} style={{ padding: "5 10" }}>
                <SearchHeaderLine
                  title={displayNames[tabId]}
                  onShowAll={results[tabId].hits.length > 3 ? () => setTabId(tabId) : undefined}
                />
                {results[tabId].hits.slice(0, 3).map((hit) => (
                  React.createElement(resultElements[tabId], { hit, spaceId, key: `search-result-${hit.objectID}` })
                ))}
              </DSColumn>
            ) : null
        )
      ) : emptyPanel()}
    </div>
  )

  const renderTab = (tabId: string) => (
    <div style={{
      display: "flex",
      flexDirection: "column",
      padding: "5 10",
    }}>
      {results[tabId] && results[tabId].hits.length > 0 ? (
        <DSColumn style={{ padding: 10 }}>
          {results[tabId].nbPages > 1 && <SearchPager results={results[tabId]} setPage={(page: number) => setPage(results[tabId].query, tabId, page)} style={{ marginBottom: 10 }} />}
          {
            results[tabId].hits.map((hit, index) => (
              React.createElement(resultElements[tabId], { hit, spaceId, key: `search-result-${hit.objectID}` })
            ))
          }
          {results[tabId].nbPages > 1 && <SearchPager results={results[tabId]} setPage={(page: number) => setPage(results[tabId].query, tabId, page)} style={{ marginTop: 10 }} />}
        </DSColumn>
      ) : emptyPanel()}
    </div>
  );


  return (
    <DSPanelScrollable
      style={{
        width: "100%",
        height: "calc(100% - 92px)",

        background: `linear-gradient(${DSTheme.PanelBackgroundColor}  33%, rgba(35,41,43, 0)), linear-gradient(rgba(35,41,43, 0), ${DSTheme.PanelBackgroundColor} 66%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0,0,0, 0.5), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0, 0.5), rgba(0,0,0,0)) 0 100%`,
        backgroundColor: DSTheme.PanelBackgroundColor,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "local, local, scroll, scroll",
        backgroundSize: "100% 45px, 100% 45px, 100% 15px, 100% 15px",
        marginLeft: "calc(100vw - 100%)",
        overflowY: "scroll",
      }}>

      {tabId === "all" ?
        renderAll() :
        renderTab(tabId)
      }
    </DSPanelScrollable >
  );
}


export const SearchPager: (React.FC<{
  results: SearchResponse<ITeamUserHit | IChannelHit | IMessageHit | IFileHit>,
  setPage: (page: number) => void,
  style: CSSProperties,
}>) = ({ results, setPage, style }) => (
  <DSPanel
    style={{
      width: "100%",
      ...style,
    }}
  >
    <DSRow style={{ flex: 1 }}>
      <DSColumn style={{ flex: 1, alignItems: "flex-end" }}>
        <DSRow style={{ alignItems: 'center', paddingBottom: 2, flex: 1 }}>
          <DSColumn style={{ alignItems: 'center', }}>
            <DS2IconActionButton
              disabled={results.page === 0}
              style={{ marginLeft: 10, marginRight: 10, }}
              onClick={() => setPage(results.page - 1)}>
              {DS2Icons.prev}
            </DS2IconActionButton>
          </DSColumn>
          <DSColumn style={{ color: DSTheme.PanelContrastColor }}>
            <DSPrint>
              Page {results.page + 1} of {results.nbPages}
            </DSPrint>
          </DSColumn>
          <DSColumn>
            <DS2IconActionButton
              disabled={results.page === results.nbPages - 1}
              style={{ marginLeft: 10, marginRight: 10, }}
              onClick={() => setPage(results.page + 1)} >
              {DS2Icons.next}
            </DS2IconActionButton>
          </DSColumn>

        </DSRow>
      </DSColumn>
    </DSRow>
  </DSPanel>
)

export const SearchHeaderLine: React.FC<{
  title: string
  onShowAll?: () => void
}> = ({ title, onShowAll }) => (
  <DSPanel
    style={{
      width: "100%",
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: DSTheme.PanelContrastColor,
      marginBottom: 10,
    }}
  >
    <DSRow style={{ alignItems: 'flex-end', paddingBottom: 2, flex: 1 }}>
      <DSColumn style={{ flexGrow: 1 }}>
        <DSRow style={{ flexGrow: 1 }}>
          <DSH3 text={title} style={{ paddingBottom: 4, flexGrow: 1, color: DSTheme.PanelContrastColor }} />
          {onShowAll && (
            <DSButton
              onClick={onShowAll}
            >
              <DSH3 text={"Show all"} style={{ flex: 0, color: DSTheme.PanelContrastColor }} />
            </DSButton>
          )}
        </DSRow>
      </DSColumn>
    </DSRow>
  </DSPanel>
)



export const fmtResourceName = (resource: ISearchFileRecord) => {
  if (resource.name) return resource.name;
  else if (resource.url) return new URL(resource.url).hostname;
  else return 'unknown';
}

export const SearchResultFile: React.FC<{
  hit: Hit<IFileHit>,
  name?: string,
  style?: CSSProperties,
  onClick?: React.MouseEventHandler,
}> = ({ hit, style, name, onClick, children }) => {


  const getName = () => {
    if (name) return name;

    const highlightedName = getHighlightedValue(hit._highlightResult?.name);;

    if (highlightedName)
      return <HighlightedText text={highlightedName} />
    else
      return fmtResourceName(hit);
  }

  return (
    <SearchResultFileBase
      hit={hit}
      style={style}
      title={getName()}
      createdAt={hit.users[0].createdAt}
      createdBy={hit.users[0].name}
      onClick={onClick}
    >
      {children}
    </SearchResultFileBase>
  )
}


export const SearchResultFileBase: React.FC<{
  hit: ISearchFileRecord,
  style?: CSSProperties,
  createdBy?: string,
  createdAt?: number,
  title: ReactNode,
  onClick?: React.MouseEventHandler,
}> = ({ hit, style, children, title, createdAt, createdBy, onClick }) => {

  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(undefined);

  const dtCreatedAt = createdAt ? new Date(createdAt) : undefined;

  return (
    <DSButton
      analyticsEvent="SearchFileClick"
      style={{
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "column",
        alignItems: "stretch",
        width: "100%",
        border: '1px white solid',
        borderColor: "rgba(232, 232, 232, 0.13)",
        margin: "5 10",
        borderRadius: 8,
        backgroundColor,
        ...style
      }}
      onClick={(e) => onClick ? onClick(e) : window.Main.shellOpenExternal(hit.url)}
      onHovered={(isHovering) => setBackgroundColor(isHovering ? DSTheme.ChatMessageHoverTint : undefined)}
    >
      <DSRow style={{
        padding: 10,
        alignItems: "center",
      }}>
        <DSColumn style={{ width: 40 }}>
          <FileIcon hit={hit} allowPreview={true} />
        </DSColumn>
        <DSColumn style={{ flex: 1, marginLeft: 10, overflow: "hidden" }}>
          <DSRow>
            {typeof title !== 'string' ? title : (
              <DSPrint
                color={DSTheme.PanelContrastColor}
                wrap
              >
                {title}
              </DSPrint>
            )}

          </DSRow>
          {!!dtCreatedAt && !!createdBy && (
            <DSRow>
              <DSPrint wrap style={{ padding: "5 0", color: "rgb(171, 171, 173)" }}>
                by {createdBy} {isToday(dtCreatedAt) ? "at" : "on"} {getFriendlyDate(dtCreatedAt)}
              </DSPrint>
            </DSRow>
          )}
        </DSColumn>
        {children}
      </DSRow>
    </DSButton >
  )
}

const SearchResultChat: React.FC<{ hit: Hit<IMessageHit>, spaceId: KSpaceId }> = ({ hit, spaceId }) => {

  const space = DataState.spaces[spaceId];
  const user = space.users[hit.userId];

  const [name, setName] = useState<string | ReactNode | undefined>(undefined);

  const messageData: IOTChatMessage = {
    ...hit,
    id: hit.objectID,
    name: user?.name,
    userImageUrl: user?.imageUrl,
    crDate: new Date(hit.crDate),
    message: getHighlightedValue(hit._highlightResult?.message) || hit.message
  };


  const openChat = () => {
    if (hit.msgType === "CHAT") {

      const channel = space.channels[hit.channelId];

      logger.debug(`openChat chatType: ${channel.chatType} channelId: ${hit.channelId} messageId: ${hit.messageId}`);

      openPod(spaceId, hit.channelId, hit.messageId);

    } else if (hit.msgType === "CALL") {

      const callId = hit.call.callId;

      if (callId) {

        (async () => {
          const call = await CallDetailsDb.getCallDetails(
            Fire.getFirestore(),
            spaceId,
            callId);

          if (call) {
            const channelId = call.channelId;

            if (channelId) {
              openPod(spaceId, channelId, undefined, { callId });
            } else {
              logger.warn("call has no channelId, ", callId);
            }
          } else {
            logger.warn("missing call details, ", callId);
          }


        })();

      } else {
        logger.debug("missing callId");
      }
    }
  }


  useEffect(() => {

    if (hit.msgType === "CHAT") {
      logger.debug("hit: ", hit);
      const channel = space.channels[hit.channelId];

      let name = hit.channel.name || '';

      if (channel.chatType === 'chat') {
        const users = space.channels[hit.channelId].userIds?.filter((userId) => userId != OTUITree.auth.userId) || [];
        if (users.length == 1) {
          name = space.users[users[0]] ? space.users[users[0]].name : "unknown user";
        }
      } else {
        name = space.channels[hit.channelId].name || name;
      }
      setName(name);

    } else if (hit.msgType === 'CALL') {
      const callId = hit.call?.callId;

      if (callId) {
        (async () => {
          const callDetails = await CallDetailsDb.getCallDetails(
            Fire.getFirestore(),
            spaceId,
            callId);

          if (callDetails) {

            setName(
              <DSRow style={{
                alignItems: 'center'
              }}>
                <DSPrint style={{}}>
                  Call with
                </DSPrint>
                <DSWrappedRow>
                  {Object.keys(callDetails.users).map(userId =>
                    <CallSummaryUser
                      key={userId}
                      spaceId={spaceId}
                      userId={userId}
                      name={callDetails.users[userId].name}
                      imageUrl={callDetails.users[userId].imageUrl}
                    />
                  )}
                </DSWrappedRow>
                <DSPrint style={{ paddingLeft: 5 }}> on {getFullDate(callDetails.crDate)}</DSPrint>
              </DSRow>
            )
          } else {
            logger.warn(`call not found ${callId}`);
            setName("Call not found");
          }
        })();
      } else {
        logger.warn("callId not present on search result");
        setName("Unknown");
      }
    }
  }, [hit]);

  return (
    <DSButton
      style={{
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "column",
        alignItems: "stretch",
        width: "auto",
        border: '1px solid',
        borderColor: "rgba(232, 232, 232, 0.13)",
        margin: "5 10",
        borderRadius: 8,
      }}
      analyticsEvent="SearchOpenMessage"
      onClick={() => openChat()}
    >
      <DSPrint style={{ padding: "5 8", color: "rgb(171, 171, 173)" }}>{name || ""}</DSPrint>
      <ChatMessage spaceId={spaceId} message={messageData} canEdit={false} />
    </DSButton>
  )
}

const getHighlightedValue = (h: any) => h && h.matchLevel === "full" ? h.value : undefined;


const HighlightedText: React.FC<{ text: string }> = ({ text }) => {

  const emphasisRegex = /#mark#([^#]+)?#\/mark#/;

  var s = text;
  var children: any[] = [];
  var index = 0;

  while (true) {
    const m = s.match(emphasisRegex)

    if (!m) {
      children.push(s)
      break;

    } else {
      const pre = s.slice(0, m.index);
      const post = s.slice(m.index! + m[0].length);
      const highlight = m[1];

      if (pre.length)
        children.push(pre);

      children.push(<span key={`highlight-${index}`} style={{ backgroundColor: DSTheme.EmphasisBackgroundColor }}>{highlight}</span>);

      s = post;
    }
    index++;
  }
  return <span>{children}</span>;
}


const SearchResultUser: React.FC<{ hit: Hit<ITeamUserHit>, spaceId: KSpaceId }> = observer(({ hit, spaceId }) => {


  const space = DataState.spaces[spaceId];
  const user = space.users[hit.userId];

  if (!user) {
    logger.warn(`SearchResult returned non-existant user with id: ${hit.userId}`);
    return null;
  }

  const numUnread = space.userChats[user.id]?.numUnread ?? 0;

  const highlightedName = getHighlightedValue(hit._highlightResult?.name);;

  return (
    <DSRow style={{
      padding: 10,
      alignItems: "center",
    }}>
      <DSColumn style={{ width: 40 }}>
        <UserIcon user={user} size={40} />
      </DSColumn>
      <DSColumn style={{ flexGrow: 1 }}>
        <DSRow spacing={10}>
          <DSPrint
            color={DSTheme.PanelContrastColor}
            style={{ marginLeft: 10 }}
            wrap
          >
            {highlightedName ?
              <HighlightedText text={highlightedName} /> : hit.name
            }
          </DSPrint>
          <DS2PinButton
            pinned={user.pinned}
            onClick={user.actions.togglePinned}
            style={Styles.icon}
          />
        </DSRow>
        <DSRow>
          <DSPrint style={{ padding: "0 8", color: "rgb(171, 171, 173)" }} >
            {user.team}
          </DSPrint>
        </DSRow>
      </DSColumn>
      <DSColumn>
        <DSRow style={{ alignItems: "center" }} spacing={10}>
          <DS2IconActionButton
            analyticsEvent="SearchUserKnock"
            analyticsArgs={{ userId: user.id }}
            onClick={() => {
              if (user.alerts?.knocked?.count && user.alerts?.knocked?.count > 0) {
                user.alerts.clear()
              } else {
                user.actions.knockUser();
              }
              addToRecentUsers(spaceId, user.id)
            }}
            color={user.alerts?.knocked?.count  ? 'primary' : 'secondary'}
            badgeNum={user.alerts?.knocked?.count}
            disabled={!user.status?.online}
          >
            {DS2Icons.knock}
            </DS2IconActionButton>
          <DS2IconActionButton
            key={`chat - ${user.id}`}
            analyticsEvent="SearchUserOpenChat"
            analyticsArgs={{ userId: user.id }}
            onClick={() => openUserChat(spaceId, user.id)}
            badgeNum={numUnread}
            color='secondary'
            style={Styles.icon}
          >
            {DS2Icons.chat}
            </DS2IconActionButton>
          <DS2IconActionButton
            key={`call - ${user.id}`}
            analyticsEvent="SearchUserCall"
            analyticsArgs={{ userId: user.id }}
            badgeNum={user.alerts?.called?.count}
            style={Styles.icon}
            color={user.outgoing?.calling ? 'error' : 'success'}
            disabled={!user.status?.online}
            onClick={() => {
              if (user.alerts?.called?.count && user.alerts?.called?.count > 0) {
                user.alerts.clear()
              } else {
                user.actions.callUser();
              }
              addToRecentUsers(spaceId, user.id)
            }}
          >
            {DS2Icons.call}
            </DS2IconActionButton>
        </DSRow>
      </DSColumn>
    </DSRow >
  )
})


const SearchResultChannel: React.FC<{ hit: Hit<IChannelHit>, spaceId: KSpaceId }> = observer(({ hit, spaceId }) => {

  const space = DataState.spaces[spaceId];
  const pod = space.pods[hit.channelId];

  if (!pod)
    return null;

  const background = getStringBackground(pod.name, true);
  const dotSize = 40;

  const pinned = pod.pinned
  const togglePinned = () => pod.actions.setPinned(!pinned)

  const callIds = Object.keys(space.calls).filter(
    callId => space.calls[callId].channelId === hit.channelId)

  const call = callIds.length > 0 ? space.calls[callIds[0]] : undefined

  const myUserId = OTUITree.auth.userId;
  const inCall = call && myUserId in call.participants;
  const channel = space.channels[hit.channelId];

  const highlightedName = getHighlightedValue(hit._highlightResult?.name);;

  return (
    <DSRow style={{
      padding: 10,
      alignItems: "center",
    }}>
      <DSColumn >
        <div style={{ width: dotSize, height: dotSize, borderRadius: dotSize / 2, background }} />
      </DSColumn>
      <DSColumn style={{ padding: 10, flexGrow: 1, color: DSTheme.PanelContrastColor }}>
        <DSPrint>
          {highlightedName ?
            <HighlightedText text={highlightedName} /> : hit.name
          }
        </DSPrint>
      </DSColumn>
      <DSColumn style={{ flexGrow: 1, alignItems: "flex-end" }}>
        <DSRow spacing={10}>
          <DS2PinButton
            analyticsEvent="SearchRoomPin"
            analyticsArgs={{ channelId: hit.channelId }}
            pinned={pinned}
            onClick={togglePinned}
          />
          <DS2IconActionButton
            color='secondary'
            analyticsEvent="SearchRoomOpenChat"
            analyticsArgs={{ channelId: hit.channelId }}
            onClick={() => openPod(spaceId, pod.id)}
          >
            {DS2Icons.chat}
          </DS2IconActionButton>
          {call ?
            inCall ? (
              <DS2LeaveCallButton
                analyticsEvent="SearchRoomLeaveCall"
                analyticsArgs={{ channelId: hit.channelId }}
                onClick={() => call.actions.leaveCall?.()}
              />
            ) : (
              <DS2CallButton
                analyticsEvent="SearchRoomJoinCall"
                title="Enter"
                analyticsArgs={{ channelId: hit.channelId }}
                onClick={() => call.actions.joinCall()}
              />
            ) : (
              <DS2CallButton
                analyticsEvent="SearchRoomStartCall"
                title="Enter"
                analyticsArgs={{ channelId: hit.channelId }}
                onClick={() => channel.actions.startCall()}
              />
            )
          }
        </DSRow>
      </DSColumn>
    </DSRow >
  )
})

const Styles = {
  icon: {
    padding: 6,
    width: undefined,
    height: undefined,
  }
}

const ICON_SIZE = 16;

export default SearchControl;

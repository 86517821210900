import { Logger } from '@openteam/app-util';
import { useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { isWayland } from '../Controllers/App';

const logger = new Logger("HoverIntent")
const IS_WAYLAND = isWayland || isMacOs;
export interface optionType {
  ref?: React.Ref<HTMLElement | null>;
  timeout?: number;
  minVelocity?: number
  minDelay?: number
  stopPropagation?: boolean;
  label?: string;
  emulateLeave?: boolean
}

export type HoverIntentProps = optionType

export function useHoverIntent<T = HTMLElement>(
  options: HoverIntentProps
): [boolean, React.RefObject<HTMLElement & T>, (setDelay?: boolean) => void] {
  const {
    ref,
    timeout = 0,
    stopPropagation = false,
    minVelocity = 100,
    minDelay = 20,
    label,
    emulateLeave = IS_WAYLAND,
  } = options;

  const intentRef = useRef<HTMLElement & T>(null);
  const prevRef = useRef<HTMLElement & T | null>();

  const [isHovering, setIsHovering] = useState(false);
  const [delayFinished, setDelayFinished] = useState(false)
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const delayTimer = useRef<ReturnType<typeof setTimeout>>();
  const leaveTimer = useRef<ReturnType<typeof setTimeout>>();
  const leaving = useRef(false);
  const enteredAt = useRef<number>()
  const compareInterval = 100;

  const xy = useRef<{x: number, y: number, time: number}>()
  const pXY = useRef<{x: number, y: number, time: number}>()
  const dV = useRef<{x: number, y: number, f: number}>()

  const debug = useCallback((msg, ...args) => {
    label && logger.debug(`${msg} (${label})`, ...args)
  }, [label])

  const isRef = useCallback((e: MouseEvent) => {
    return e.target === intentRef.current
  }, [])

  const _clearHover = useCallback(() => {
     debug(`clearing hover`)
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }
    if (delayTimer.current) {
      clearTimeout(delayTimer.current);
      delayTimer.current = undefined;
    }
    setIsHovering(false);
    setDelayFinished(false);
  }, [setIsHovering, setDelayFinished, debug]);


  const setClearTimer = useCallback((timeoutUsed:number = 0) => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }

    if (leaving.current) {
      leaveTimer.current && clearTimeout(leaveTimer.current)
      leaveTimer.current = setTimeout(() => leaving.current = false, 100)
    }

    if (delayTimer.current) {
      _clearHover();
    } else {
      timer.current = setTimeout(() => _clearHover(), timeout - timeoutUsed);
    }
  }, [timeout, _clearHover])


  const compare = useCallback(() => {
    if (intentRef.current && dV.current && pXY.current) {
      const _interval = new Date().getTime() - pXY.current.time
      const {x: dX, y: dY, f } = dV.current;
      const {x: pX, y: pY } = pXY.current;

      const rect = intentRef.current.getBoundingClientRect();

      //const { clientLeft: cX, clientTop: cY, offsetLeft: oX, offsetTop: oY} =  intentRef.current
      //const { clientWidth: cW, clientHeight: cH} = intentRef.current;

      const x = pX + ((dX * f) * _interval/1000);
      const y = pY + ((dY * f) * _interval/1000);
      debug(`No mouse events in ${_interval}, projected xy ${x},${y}, factor ${f}, area ${rect.left},${rect.top} - ${rect.right},${rect.bottom}`);
      let departing = false;

      if (emulateLeave) {
        if (x < (rect.left) || x > (rect.right)) {
          debug(`departed X ${x}`)
          setClearTimer(_interval)
          departing = true
        } else if (y < rect.top || y > rect.bottom) {
          debug(`departed Y ${y}`)
          setClearTimer(_interval)
          departing = true
        }
      }

      if (!departing) {
        setIsHovering((_ishovering) => {
          if (!_ishovering) {
            debug(`hovering: from compare`)
          }
          return true
        });
      }
    }
    pXY.current = undefined;
  }, [setClearTimer, setIsHovering, emulateLeave, debug])

  const tracker = useCallback((e: PointerEvent) => {

    const [x, y, time] = [e.x, e.y, new Date().getTime()];
    debug(`mousemove pos: (${x},${y}), since: ${time - (pXY.current?.time ?? 0)}ms`);

    if (pXY.current && time - pXY.current.time < Math.min(10, minDelay)) {
      // Skip events that are too frequent to avoid bad velocity values
      return;
    }

    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }

    if (pXY.current && intentRef.current && time - pXY.current.time < 200) {
      const _interval = time - pXY.current.time;
      const vX = ((x - pXY.current.x) * 1000) / _interval;
      const vY = ((y - pXY.current.y) * 1000) / _interval;
      const velocity = Math.round(Math.sqrt(vX ** 2 + vY ** 2));

      let factor = 1;
      if (dV.current) {
        const pV = Math.sqrt(dV.current.x ** 2 + dV.current.y ** 2);
        factor = Math.max(velocity, 1) / Math.max(pV, 1);
      }

      dV.current = { x: vX, y: vY, f: factor };

      debug(`tracker velocity ${Math.round(velocity)}px/s, factor ${factor} interval ${_interval}`);

      if (leaving.current) {
        debug(`leaving`);
      } else if (velocity < minVelocity || (velocity < minVelocity * 4 && factor < 0.7)) {
        setIsHovering((_ishovering) => {
          if (!_ishovering) {
            debug(`is hovering: vel ${velocity}, factor ${factor}`);
          }
          return true;
        });
      }

      if (velocity > 0) {
        pXY.current = { x, y, time };
        timer.current = setTimeout(compare, compareInterval);
      }
      xy.current = undefined;
    } else {
      timer.current = setTimeout(compare, compareInterval);
      pXY.current = { x, y, time };
    }
  }, [setIsHovering, isRef, minVelocity, minDelay, debug])

  const dispatchOver = useCallback((e: MouseEvent) => {
    //debug(`mouseover isRef ${isRef(e)}`)
    if (stopPropagation) {
      e.stopPropagation();
    }

    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }

    if (leaving.current) {
      leaveTimer.current && clearTimeout(leaveTimer.current)
    }

    enteredAt.current = new Date().getTime();

    if (delayTimer.current) {
      clearTimeout(delayTimer.current)
    }
    delayTimer.current = setTimeout(() => {
      setDelayFinished(true)
      delayTimer.current = undefined;
    }, minDelay);
    
    timer.current = setTimeout(compare, compareInterval);

  }, [compare, isRef, debug, minDelay])

  const dispatchOut = useCallback((e: MouseEvent) => {
    debug(`mouseout, timeout ${timeout} isRef ${isRef(e)}`)
    if (isRef(e)) {
      setClearTimer()
    }
  }, [setClearTimer, isRef, debug])


  const addListeners = useCallback((currentRef?: HTMLElement | null) => {
      if (currentRef) {
        debug(`(re-)add events (on ref change)`)
        currentRef.addEventListener('pointerenter', dispatchOver, false);
        currentRef.addEventListener('pointerleave', dispatchOut, false);
        currentRef.addEventListener('pointermove', tracker, false);
      }
  }, [dispatchOver, dispatchOut, tracker, debug])

  const removeListeners = useCallback(
    (currentRef?: HTMLElement | null) => {
      if (currentRef) {
        debug(`remove events ${label}`);
        currentRef.removeEventListener('pointerenter', dispatchOver, false);
        currentRef.removeEventListener('pointerleave', dispatchOut, false);
        currentRef.removeEventListener('pointermove', tracker, false);
      }
    },
    [dispatchOver, dispatchOut, tracker, debug]
  );

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current)
      delayTimer.current && clearTimeout(delayTimer.current)
      leaveTimer.current && clearTimeout(leaveTimer.current)
    }
  }, [])

  const clearHover = useCallback((setDelay=false) => {
    debug(`force clearHover`)
    if (setDelay) {
      leaving.current = true;
    }
    _clearHover();
  }, [_clearHover, leaving, debug])

  useImperativeHandle(ref, () => intentRef.current, [intentRef]);

  useEffect(() => {
    debug(`hovering ${isHovering} (isHovering: ${isHovering}, delayFinished: ${delayFinished}) after ${new Date().getTime() - enteredAt.current!}`)
  }, [isHovering, delayFinished, debug])

  useEffect(() => {
    removeListeners(intentRef.current)
    addListeners(intentRef.current)
  }, [dispatchOver, dispatchOut, tracker])

  useEffect(() => {
    if (intentRef.current !== prevRef.current) {
      removeListeners(prevRef.current)
      addListeners(intentRef.current)
      prevRef.current = intentRef.current;
    }
  })

  return [isHovering && delayFinished, intentRef, clearHover];
}
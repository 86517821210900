import { CreateStyledComponent } from "@emotion/styled"
import { ButtonUnstyled } from "@mui/base"
import { setAlpha, styled, useClickable, ClickableProps } from "@openteam/design-system"
import React from "react"

const DockControlButton: React.FC<ClickableProps> = (props) => {

  const {onClick, otherProps} = useClickable(props)

  return <StyledButton onClick={onClick} {...otherProps} />
}


export default DockControlButton;

const StyledButton = styled(ButtonUnstyled)(({theme}) => ({
  backgroundColor: setAlpha(theme.palette.secondary.main, 0.5),
  color: theme.palette.secondary.contrastText,
  borderRadius: 4,
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',

  '&:hover': {
    backgroundColor: setAlpha(theme.palette.secondary.main, 0.9),
  }
}))

export const DockControlTile = styled('div')(({theme}) => ({
  backgroundColor: setAlpha(theme.palette.secondary.main, 0.5),
  color: theme.palette.secondary.contrastText,
  borderRadius: 4,
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',

  '&:hover': {
    backgroundColor: setAlpha(theme.palette.secondary.main, 0.9),
  }
}))

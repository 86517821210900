import { observer } from 'mobx-react';
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { FaThumbtack, FaVideo } from 'react-icons/fa';
import { DSColumn } from '../../DesignSystem';
import { DSTheme } from '../../DesignSystem/DSTheme';


export const SpacePanelCallIcon: React.FC<{ size?: number, style?: CSSProperties }> = observer(({ size, style }) =>
  <DSColumn
    style={{
      backgroundColor: "white",
      flexShrink: 0,
      borderRadius: 999,
      padding: Math.round((size ?? 8) / 2.5),
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,
      ...style
    }}
  >
    <FaVideo size={size ?? 8} style={{ color: DSTheme.EmphasisColor }} />
  </DSColumn>
)

export const SpacePanelPinnedIcon: React.FC<{size?: number, style?: CSSProperties }> = observer(({ size, style }) =>
  <DSColumn
    style={{
      backgroundColor: "rgba(0,0,0,0.5)",
      borderRadius: 999,
      padding: Math.round((size ?? 8) / 2.5),
      alignItems: "center",
      justifyContent: "center",
      ...style
    }}
  >
    <FaThumbtack size={size ?? 8} style={{ color: DSTheme.EmphasisColor }} />
  </DSColumn>
)
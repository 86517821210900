import { IconActionButton, IconActionButtonProps } from '@openteam/design-system';
import { observer } from 'mobx-react';
import React, { CSSProperties, forwardRef } from 'react';
import { FaComment } from 'react-icons/fa';

interface Props extends IconActionButtonProps {
  style?: CSSProperties;
  show?: boolean;
  chatOpen?: boolean;
  onChatOpen?: (open: boolean) => void;
}

export const ChatButton = forwardRef<HTMLButtonElement, Props>(({ size, style, show = true, chatOpen, onChatOpen, ...props }, ref) => {
  const opacity = show ? 1 : 0;
  const transition = opacity ? 'opacity 0.2s ease-out' : 'opacity 0.3s ease-in';

  return (
    <IconActionButton
      ref={ref}
      color="secondary"
      data-tooltip="Open chat"
      data-tooltip-position="bottom"
      analyticsEvent={chatOpen ? 'callChatClose' : 'callChatOpen'}
      outerStyle={{ width: 'fit-content' }}
      style={{ ...style, opacity, transition }}
      onClick={() => onChatOpen?.(!chatOpen)}
      {...props}
    >
      <FaComment />
    </IconActionButton>
  );
});

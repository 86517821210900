import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { DSColumn, DSRow } from "../../DesignSystem";
import { DSH1, DSH3, DSStrongPrint } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { Logger } from "@openteam/app-util";
import { DSButton } from "../../DesignSystem/DSButton";
import { APP_LOGIN_URL } from "../../config";
import { DSInput } from "../../DesignSystem/DSInput";
import { signInWithCustomToken } from "../../Controllers/Login";
import { OnboardingHeader } from "./OnboardingHeader";
import * as Analytics from '../../Util/Analytics'
import { DS2Button } from "../../DesignSystem/DS2";

const logger = new Logger("LoginContent")



type LoginContentProps = {
};

export const LoginContent = observer((props: React.PropsWithChildren<LoginContentProps>) => {
  logger.info("showing LoginContent")
  const [token, setToken] = useState("");

  useEffect(() => {
    Analytics.logEvent("Screen_LoginContent")
  }, [])


  const signInWithToken = () => {
    signInWithCustomToken(token)
  }
  const openLoginChrome = () => {
    window.Main.shellOpenExternal(APP_LOGIN_URL)

  }
  return (

    <OnboardingHeader>

      <DSColumn style={{ alignItems: 'center' }}>

        <DSH1 style={{ fontSize: 30 }}>
              Sign in
        </DSH1>
        <DSH3 wrap style={{ textAlign: "center", width: 250, marginTop: 70, marginBottom: 70 }}>
          This will open in your browser to verify your account
        </DSH3>
        <DS2Button onClick={openLoginChrome} > Sign In </DS2Button>

          {import.meta.env.DEV ?
          <DSRow style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: "center",
            padding: 8,
            backgroundColor: 'rgba(255,0,0,0.2)'
          }}>
              <DSInput
                value={token}
                onChange={(event) => setToken(event.target.value)}
              />

              <DSButton style={{ margin: 10 }} onClick={signInWithToken} title="Submit token" />
            </DSRow>

            : null}
      </DSColumn>
    </OnboardingHeader>
  );
});

import { Logger } from '@openteam/app-util';
import { Body, IconActionButton, Icons, styled } from '@openteam/design-system';
import { IMessage, IOTChatMessage } from '@openteam/models';
import { autorun, reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { DataState } from '../../Data/DataState';
import ChatFiles from '../Chat/ChatFiles';
import { ChatLinkPreview } from '../Chat/ChatLinkPreview';
import { ChatMarkdownView } from '../Chat/ChatMarkdownView';

const logger = new Logger('CallLastMessage');

type Props = {
  userId: string;
  circular: boolean;
};

const CallLastMessage: React.FC<Props> = ({ userId, circular }) => {
  const call = DataState.activeCall!;
  // const message = call.callMessageManager.userLastMessage[userId];
  const { ref, width = 100 } = useResizeObserver();
  const [hovered, setHovered] = useState(false);
  const [message, setMessage] = useState<IOTChatMessage>();

  useEffect(
    () =>
      autorun(() => {
        if (!hovered) {
          setMessage(call.callMessageManager.userLastMessage[userId]);
        }
      }),
    [hovered]
  );

  const linkPreviews = message?.linkPreviews ?? (message?.linkPreview ? [undefined] : []);

  const radius = width / 2;
  const maxWidth = circular ? Math.sqrt(radius ** 2 - (radius - 80) ** 2) * 2 - 20 : width - 100;
  return message ? (
    <Container ref={ref}>
      <div
        style={{ position: 'relative', maxHeight: '100%' }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <MessageBox style={{ maxWidth }}>
          <ChatFiles spaceId={call.spaceId} message={message} filterMedia={true} />
          <ChatFiles spaceId={call.spaceId} message={message} filterMedia={false} />

          {message.isSystem ? (
            <Body wrap>{message.message}</Body>
          ) : (
            <ChatMarkdownView message={message.message} spaceId={call.spaceId} />
          )}

          {Object.keys(linkPreviews).map((linkid, index) => (
            <ChatLinkPreview
              key={index}
              spaceId={call.spaceId}
              message={message as unknown as IMessage}
              linkId={linkid}
            />
          ))}
        </MessageBox>
        <IconActionButton
          color="secondary"
          noBadge
          onClick={() => call.callMessageManager.clearUserLastMessage(userId)}
          style={{ opacity: hovered ? 1 : 0, position: 'absolute', left: -15, top: -15 }}
        >
          {Icons.close}
        </IconActionButton>
      </div>
    </Container>
  ) : null;
};

export default observer(CallLastMessage);

const Container = styled('div')(() => ({
  position: 'absolute',
  inset: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingBottom: 80,
  paddingTop: 80,
  pointerEvents: 'none'
}));

const MessageBox = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: 10,
  borderRadius: 4,
  backgroundColor: theme.palette.secondary.main,
  maxHeight: '100%',
  overflowY: 'scroll',
  pointerEvents: 'initial'
}));

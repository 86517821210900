import React, { CSSProperties, useCallback, useEffect } from "react";
import { animated, useTransition } from '@react-spring/web';
import { Logger } from "@openteam/app-util";
import { PopupOptions, SubWindow, SubWindowCSS } from "../Components/SubWindow";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import { TutorialView } from "../Components/Tutorial/TutorialView";
import useResizeObserver from "use-resize-observer";
import { openMainSpace, closeMainSpace, UIState } from "../Data/UIState";
import { FireDb, OTGlobals, OTUITree, UIDataState } from "@openteam/app-core";
import { ITeamRoomConfig } from "@openteam/models";
import * as Fire from "./Fire"
import * as Analytics from '../Util/Analytics'

import { DSPanel, DSRow, DSVSpacer } from "../DesignSystem";
import { DSH3, DSH4 } from "../DesignSystem/DSText";
import { DSTheme } from "../DesignSystem/DSTheme";
import { DSButton, DSFilledButton } from "../DesignSystem/DSButton";
import { FaCog, FaHome, FaPhone, FaThumbtack, FaVideo } from "react-icons/fa";
import { DSCallUser, DSKnockUser, DSOpenChat } from "../DesignSystem/DSIconButtons";
import { DSSwitch } from "../DesignSystem/DSSwitch";
import { DS2Button, DS2IconActionButton, DS2Icons, DS2KnockButton } from "../DesignSystem/DS2";

const logger = new Logger("TutorialService");


type Position = { x: number; y: number };
let dockAutohideSavePoint: boolean = false

interface ITutorialPosition {
  x: number, y: number, position: 'left' | 'right', parentId: string
}

interface ITutorialState {
  originalSpaceId?: string
  active: boolean
  currentStep: number,
  stepLoaded: boolean,
  showDummyUser: boolean,
  showDummyUserPanel: boolean,
  showDummyRoom: boolean,
  showDummyRoomPanel: boolean,
  showDummyRoomCalls: boolean,
  showDock: boolean,
  showMainSpace: boolean,
  stepPosition: Record<string, ITutorialPosition>

}

export const tutorialState: ITutorialState = observable({
  active: false,
  currentStep: 0,
  stepLoaded: false,
  showDummyUser: false,
  showDummyUserPanel: false,
  showDummyRoom: false,
  showDummyRoomPanel: false,
  showDummyRoomCalls: false,
  showMainSpace: false,
  showDock: false,
  stepPosition: {}
})


export const startTutorial = action(() => {
  Analytics.logEvent(`TutorialStart`)
  dockAutohideSavePoint = UIState.dockAutoHide;
  UIState.actions.setDockAutoHide(false);

  tutorialState.active = true
  tutorialState.showDock = true
  tutorialState.currentStep = 0
  tutorialState.stepLoaded = true
  closeMainSpace();


})

export const doTutorialLater = action(() => {
  Analytics.logEvent(`TutorialLater`, {
    currentStep: tutorialState.currentStep
  })

  OTGlobals.userSettingsManager.updateRemoteSettings(
    { ehloTutorialSkipped: true }
  )

  stopTutorial();
})

export const stopTutorial = action(() => {

  Analytics.logEvent(`TutorialStop`)

  tutorialState.currentStep = 0
  tutorialState.stepLoaded = false
  tutorialState.active = false
  tutorialState.showDummyUser = false
  tutorialState.showDummyUserPanel = false
  tutorialState.showDummyRoom = false
  tutorialState.showDummyRoomPanel = false
  tutorialState.showDummyRoomCalls = false
  tutorialState.showDock = false
  tutorialState.showMainSpace = false

  UIState.actions.setDockAutoHide(dockAutohideSavePoint)
})

export const startTutorialCall = async () => {
  let roomConfigDoc: ITeamRoomConfig = {
    name: "Tutorial Call",
    channelId: "tutorialroom",
    topicId: "default",
    desc: "",
    enabled: true,
    call: true,
    permanent: false,
  };

  logger.debug("startRoomForChannel: creating room ", roomConfigDoc)

  let roomId = await FireDb.createRoom(
    Fire.getRTDB(),
    OTUITree.userManager.currentTeamId!,
    OTUITree.auth.userId,
    OTUITree.auth.sessionToken,
    roomConfigDoc,
  );
}


export const leaveTutorialCall = () => {
  const teamManager = OTUITree.teamManagers[OTUITree.userManager.currentTeamId!];
  teamManager.leaveTeamRoom()
}

export const progressTutorial = action(() => {

  if (tutorialState.active && tutorialState.stepLoaded) {
    const step = tutorialSteps[tutorialState.currentStep]
    step.onNext?.()

    tutorialState.stepLoaded = false
    if (tutorialState.currentStep + 1 >= tutorialSteps.length) {
      OTGlobals.userSettingsManager.updateRemoteSettings(
        { ehloTutorialDone: true }
      )
      stopTutorial()
      Analytics.logEvent(`TutorialFinished`)

    } else {
      tutorialState.currentStep += 1
      Analytics.logEvent(`TutorialNext`, {
        currentStep: tutorialState.currentStep
      })
    }
    setTimeout(() => {
      tutorialState.stepLoaded = true
    }, 100)

  }
})

export const setStepPosition = action((id: string, x: number, y: number, position: 'left' | 'right', parentId: string) => {
  logger.debug("setStepPosition", id, x, y, position)

  tutorialState.stepPosition[id] = {
    x,
    y,
    position,
    parentId
  }

})

export const unsetStepPosition = action((id) => {
  logger.debug("unsetStepPosition", id)

  delete tutorialState.stepPosition[id]
})

export const focusTutorial = () => {
  window.Main.showWindow("tutorial-window")
}

export interface ITutorialStep {
  id: string
  contents: () => JSX.Element
  onNext?: () => void
}

export const emojiStyle: CSSProperties = { fontSize: 18, fontWeight: 'normal' }

export const tutorialSteps: ITutorialStep[] = [
  {
    id: "intro",
    onNext: action(() => {
      // tutorialState.showMainSpace = true;
      openMainSpace();
    }),
    contents: observer(() => <DSPanel
      style={{
        alignItems: 'center',
        width: 240,
      }}>
      <DSH3 wrap style={{ textAlign: 'center' }}>
        The ehlo dock integrates into your desktop so your team is always a click away
        {<br />}
        {<br />}
        Let's open the workspace to customise your dock
      </DSH3>
      <DSVSpacer size={16} />
      <DS2Button
        allowDuringTutorial={true}
        onClick={progressTutorial}
        startIcon={DS2Icons.home}
      >
        Open Workspace
      </DS2Button>
    </DSPanel>
    )
  },
  {
    id: "mainspace-intro",
    onNext: action(() => {
      tutorialState.showDummyUser = true;
    }),
    contents: observer(() => <DSPanel
      style={{
        alignItems: 'center',
        width: 240,
      }}>
      <DSH3 wrap style={{ textAlign: 'center' }}>
        The workspace is where you can see all your teammates and rooms <span style={emojiStyle}>👀</span>
        {<br />}
        {<br />}
        Let's start personalising your dock by pinning Ehla
      </DSH3>
      <DSVSpacer size={16} />
      <DS2Button
        allowDuringTutorial={true}
        onClick={progressTutorial}
        startIcon={<FaThumbtack style={{ transform: "rotate(45deg)" }} />}
      >
        Pin Ehla
      </DS2Button>
    </DSPanel>
    )
  },
  {
    id: "pin-room",
    onNext: action(() => {
      tutorialState.showDummyRoom = true;
      tutorialState.showDummyUserPanel = true;
      tutorialState.showMainSpace = false;
      closeMainSpace();
    }),
    contents: observer(() => <DSPanel
      style={{
        alignItems: 'center',
        width: 240,
      }}>
      <DSH3 wrap style={{ textAlign: 'center' }}>
        Rooms are where you can discuss and share information on a specific topic <span style={emojiStyle}>💬</span>
        {<br />}
        {<br />}
        Join rooms to get updates on the conversation and pin them for quick access
      </DSH3>
      <DSVSpacer size={16} />
      <DS2Button
        startIcon={<FaThumbtack style={{ transform: "rotate(45deg)" }} />}
        allowDuringTutorial={true}
        onClick={progressTutorial}
      >
        Pin Tutorial Room
      </DS2Button>
    </DSPanel>
    )
  },
  {
    id: "user-interact",
    onNext: action(() => {
      tutorialState.showDummyUserPanel = false;
      tutorialState.showDummyRoomPanel = true;
    }),
    contents: observer(() => <DSPanel
      style={{
        alignItems: 'center',
        width: 240,
      }}>
      <DSH3 wrap style={{ textAlign: 'center' }}>
        You can interact with your teammates from the dock
      </DSH3>

      <DSVSpacer size={24} />
      <DSRow style={{ alignItems: 'center', width: 200 }}>
        <DS2IconActionButton color="secondary">{DS2Icons.knock}</DS2IconActionButton>
        <DSH3 wrap style={{ paddingLeft: 8 }}>
          Knock to get a teammate's attention
        </DSH3>
      </DSRow>
      <DSVSpacer size={16} />
      <DSRow style={{ alignItems: 'center', width: 200 }}>
        <DS2IconActionButton color="secondary">{DS2Icons.chat}</DS2IconActionButton>
        <DSH3 wrap style={{ paddingLeft: 8 }}>
          Chat to send messages
        </DSH3>
      </DSRow>
      <DSVSpacer size={16} />

      <DSRow style={{ alignItems: 'center', width: 200 }}>
        <DS2IconActionButton color="secondary">{DS2Icons.call}</DS2IconActionButton>
        <DSH3 wrap style={{ paddingLeft: 8 }}>
          Video call to talk face to face
        </DSH3>
      </DSRow>
      <DSVSpacer size={24} />

      <DS2Button
        allowDuringTutorial={true}
        onClick={progressTutorial}
      >
        Next
      </DS2Button>
    </DSPanel>
    )
  },
  {
    id: "room-interact",
    onNext: action(() => {
      tutorialState.showDummyRoomPanel = false;
      startTutorialCall();
    }),
    contents: observer(() => <DSPanel
      style={{
        alignItems: 'center',
        width: 240,
      }}>
      <DSH3 wrap style={{ textAlign: 'center' }}>
        You can also start calls in rooms, other members of the room will be notified <span style={emojiStyle}>🔔</span>
      </DSH3>
      <DSVSpacer size={16} />
      <DS2Button
        startIcon={DS2Icons.call}
        allowDuringTutorial={true}
        onClick={progressTutorial}
      >
          Start test call
      </DS2Button>
    </DSPanel>
    )
  },
  {
    id: "room-call-interface",
    onNext: action(() => {
      leaveTutorialCall();
      tutorialState.showDummyRoomCalls = true;
    }),
    contents: observer(() => <DSPanel
      style={{
        alignItems: 'center',
        width: 240,
      }}>
      <DSH3 wrap style={{ textAlign: 'center' }}>
        ehlo's lightweight call interface floats on top of other applications freeing up your desktop
      </DSH3>
      <DSVSpacer size={16} />
      <DS2Button
        startIcon={DS2Icons.leave}
        allowDuringTutorial={true}
        onClick={progressTutorial}
      >
          Leave call
      </DS2Button>
    </DSPanel>
    )
  },

  {
    id: "other-calls",
    onNext: action(() => {
      // UIState.actions.setDockMode(UIState.dockPosition.onLeft ? 'LEFT' : 'RIGHT');
      tutorialState.showDock = false;

      setTimeout(() => {
        UIState.actions.setDockAutoHide(true);
      }, 2000)
    }),
    contents: observer(() => <DSPanel
      style={{
        alignItems: 'center',
        width: 240,
      }}>
      <DSH3 wrap style={{ textAlign: 'center' }}>
        The dock keeps you informed of on-going calls that may be important to you <span style={emojiStyle}>📞</span>
      </DSH3>
      <DSVSpacer size={16} />
      <DS2Button
        allowDuringTutorial={true}
        onClick={progressTutorial}
      >
          Next
      </DS2Button>
    </DSPanel>
    )
  },
  {
    id: "dock-autohide",
    onNext: () => {
      UIState.actions.setDockAutoHide(false);
    },
    contents: observer(() => <DSPanel
      style={{
        alignItems: 'center',
        width: 240,
      }}>
      <DSH3 wrap style={{ textAlign: 'center' }}>
        Best of all, you can set ehlo to automatically hide whenever you are not using it <span style={emojiStyle}>🙈</span>
      </DSH3>
      <DSVSpacer size={16} />

      <DSPanel style={{
        backgroundColor: DSTheme.PanelDarkerColor,
        color: DSTheme.PanelContrastColor,
        borderRadius: 8,
        padding: 12,
        width: "100%"
      }}>
        <DSRow style={{
          alignItems: 'center'
        }}>
          {DS2Icons.settings}
          <DSH3 style={{ paddingLeft: 8 }}>
            Settings
          </DSH3>
        </DSRow>
        <DSVSpacer size={8} />

        <DSRow style={{
          alignItems: 'center'
        }}>
          <DSH4 style={{ flex: 1 }}>
            Autohide Mode
          </DSH4>
          <DSSwitch
            analyticsEvent={`DockSettingsDockAutoHide${UIState.dockAutoHide ? 'Off' : 'On'}`}
            allowDuringTutorial={true}
            disabled={!UIState.dockEdge}
            checked={UIState.dockAutoHide}
            onChange={(enabled) => UIState.actions.setDockAutoHide(enabled)}
          />
        </DSRow>
      </DSPanel>

      <DSVSpacer size={16} />


      <DSH3 wrap style={{ textAlign: 'center' }}>
        Even whilst hidden the edge of the dock still keeps you informed of what's going on
      </DSH3>
      <DSVSpacer size={16} />
      <DS2Button
        allowDuringTutorial={true}
        onClick={progressTutorial}
      >
          Next
      </DS2Button>
    </DSPanel>
    )
  },
  {
    id: "finish",
    contents: observer(() => <DSPanel
      style={{
        alignItems: 'center',
        width: 240,
      }}>
      <DSH3 wrap style={{ textAlign: 'center' }}>
        The tutorial is done! Go back to the workspace to set up your dock and enjoy ehlo <span style={emojiStyle}>⭐️</span>
      </DSH3>
      <DSVSpacer size={16} />
      <DS2Button
        allowDuringTutorial={true}
        onClick={progressTutorial}
      >
          Finish
      </DS2Button>
    </DSPanel>
    )
  },
];


export const addTutorialSpace = action(() => {

  tutorialState.originalSpaceId = OTUITree.userManager.currentTeamId


  UIDataState.spaces['tutorial'] = {
    details: {
      name: "Tutorial",
    },
    capabilities: {},
    myUserId: OTUITree.auth.userId,
    amAdmin: false,
    settings: {
      notifyUserOnline: false,
      notifyRoomJoin: true,
    },
    users: {

    },
    subTeams: {
      tutorial: "tutorial"
    },
    focusRooms: {},
    calls: {},
    pods: {
      tutorial: {
        id: "tutorial",
        name: "Tutorial room",
        symbol: "TR",
        desc: undefined,
        archived: false,
        private: false,
        teamDefault: false,
        numUnread: 0,
        hasMention: false,
        lastUpdate: new Date(),
        numUsers: 1,
        userIds: [OTUITree.auth.userId],
        pinned: false,
        actions: {
          setPinned: () => { },
          updatePod: (name: string, symbol: string, desc: string, isPrivate: boolean, teamDefault: boolean) => { },
          addUser: (channelId: string, userId: string) => { },
          archivePod: () => { },
        }
      }
    },
    channelDirectory: {},
    channels: {
      tutorial: {
        id: "tutorial",
        name: "Tutorial Room",
        chatType: "channel",
        muteNotify: false,
        messageId: 0,
        lastReadMessageId: 0,
        messageNum: 0,
        messageNumRead: 0,
        numUnread: 0,
        messages: {},
        usersTyping: {},
        userIds: [OTUITree.auth.userId],
        draftMessage: '',
        draftFiles: [],
        pendingMessages: {},
        isWatching: false,
        highlightMessageId: undefined,
        pinnedResources: [],
        deletedResources: [],
        recentResources: {},
        callSummaries: {},
        atStart: true,
        actions: {} as any
      }
    },
    userChats: {},
    externalMeetings: {},
    actions: {} as any
  }

  OTUITree.userManager.currentTeamId = 'tutorial'

})

export const removeTutorialSpace = action(() => {
  delete UIDataState.spaces['tutorial']
  OTUITree.userManager.currentTeamId = tutorialState.originalSpaceId

})

interface ITutorialServiceProps {

}

export const TutorialService = observer((props: ITutorialServiceProps) => {
  const [started, setStarted] = React.useState<boolean>(false)
  const [showWindow, setShowWindow] = React.useState<boolean>(false)
  const [showStep, setShowStep] = React.useState<number>(0)
  const [size, setSize] = React.useState<{ width: number, height: number }>({ width: 10, height: 10 })
  const { ref, width: refWidth = 10, height: refHeight = 10 } = useResizeObserver<HTMLDivElement>({ box: 'border-box' });

  const windowId = "tutorial-window"


  if (size.width != refWidth || size.height != refHeight) {
    logger.debug("tutorialWindow content size refWidth, refHeight", refWidth, refHeight)
    setSize({
      width: refWidth,
      height: refHeight,
    })
  }

  const step = tutorialSteps[showStep]

  const isLastStep = showStep + 1 >= tutorialSteps.length

  const stepPosition = tutorialState.stepPosition[step.id]

  let position: Position | undefined
  if (stepPosition) {
    position = {
      x: (stepPosition.x || 0) + (stepPosition.position == 'left' && size.width ? - size.width : 0),
      y: stepPosition.y
    }
  }

  logger.debug("i have position", position, stepPosition, size, step.id)

  const transitions = useTransition(position && tutorialState.stepLoaded ? [true] : [], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
    onStart: (v) => {
      logger.debug("onstart", v)

    },
    onRest: (v) => {
      if (v.finished) {
        logger.debug("onRest", position)

        if (position) {
          setShowWindow(true)
        } else {
          setShowWindow(false)
        }
        if (showStep != tutorialState.currentStep) {
          logger.debug("setting showStep to", tutorialState.currentStep)
          setShowStep(tutorialState.currentStep)
        }
      }
    }
  });

  if (!tutorialState.active) {
    return null
  }

  return (
    <SubWindow
      id={windowId}
      show={!!position || showWindow}
      width={size.width}
      height={size.height}
      {...position}
      focus={true}
      onCreated={() => { setStarted(true) }}
      alwaysOnTopLevel="pop-up-menu"
      options={{
        ...PopupOptions,
        transparent: true,
        hasShadow: true,
      }}
    >
      <style type="text/css">{SubWindowCSS}</style>

      {transitions((springStyles, item) => (
        <animated.div ref={ref} style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          overflow: 'visible',
          width: 'fit-content',
          height: 'fit-content',
          ...springStyles
        }} >

          {item && <TutorialView contents={step.contents} showLaterButton={!isLastStep} />}
        </animated.div>
      ))}
    </SubWindow >
  )
})

import { initializeApp, getApps, getApp } from 'firebase/app'
import { getFirestore as _getFirestore, initializeFirestore } from 'firebase/firestore';
import { getDatabase, onValue, ref } from 'firebase/database'

import { firebaseConfig } from '../config';

console.log("initialising firebase")
export const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

initializeFirestore(app, {
  ignoreUndefinedProperties: true
});

console.log('done initialising firebase');

export function getRTDB() {
  return getDatabase(app);
}

export function getFirestore() {
  return _getFirestore(app)
}

export function watchConfig(callback: (doc) => void) {
  onValue(
    ref(getRTDB(), '/config/'),
    (snapshot) => callback(snapshot.val())
  );
}

import { IUISpaceUser, KSpaceId, KSpaceUserId } from "@openteam/models";
import { DataState } from "../../Data/DataState";
import { UIState } from "../../Data/UIState";

export function getUserInteresting(spaceId: KSpaceId, userId: KSpaceUserId) {
  const space = DataState.spaces[spaceId]
  const spaceChats = UIState.openChats[spaceId]
  const user = DataState.spaces[spaceId].users[userId]

  if (!user) {
    return {
      hasAlert: false,
      hasMessage: false,
      openChat: false,
      pinned: false,
    }
  }
  const hasAlert = (
    user.alerts?.knocked ||
    user.alerts?.called ||
    user.incoming?.calling ||
    user.outgoing?.calling || 
    user.outgoing?.knocking || 
    user.incoming?.callRequest?.receiverActive ||
    user.outgoing?.callRequest?.senderActive) ? true : false


  const openChat = space.userChats[userId] &&
    spaceChats &&
    space.userChats[userId].channelId in spaceChats.channels;

  const hasMessage = (space.userChats[userId]?.numUnread || 0) > 0

  return {
    hasAlert,
    hasMessage,
    openChat,
    pinned: user.pinned,
  }
}
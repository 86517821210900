import { setCallWidgetHovered } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { action } from "mobx";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { MdOutlineScreenShare } from "react-icons/md";
import { DataState } from "../../../Data/DataState";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { CallScreenShareWindow } from "./ScreenSharePicker";
import { isMacOs } from "react-device-detect";
import { showNotification } from "../../../Controllers/Notifications";
import { UIState } from "../../../Data/UIState";
import { openSystemPreferences } from "../../../Util/OpenSystemPreferences";
import { ICapturerSource } from "@openteam/models";
import { observer } from "mobx-react";
import { FaDesktop } from "react-icons/fa";
import { IconActionButtonProps, IconActionButton } from "@openteam/design-system";

const logger = new Logger("ScreenShareButton")

interface ScreenShareButtonProps extends IconActionButtonProps {
  show?: boolean;
  parentId: string
}

type ScreenShare = {
  stream: MediaStream;
  source: ICapturerSource;
};
export const ScreenShareButton: React.FC<ScreenShareButtonProps> = observer((props) => {
  const ref = useRef(null)
  const [showPicker, setShowPicker] = useState(false);
  const [screenShare, setScreenShare] = useState<ScreenShare | null>(null);
  const [tooltip, setTooltip] = useState<string>()

  const { style, size, color, show = true, parentId, ...otherProps } = props;


  const call = DataState.activeCall;

  const sharing = call?.sharingScreen ?? false;
  const openPlugins = Object.keys(call?.plugins ?? {})

  const onStart = useCallback((stream, source) => {
    if (DataState.activeCall) {
      setScreenShare({ stream, source });
      DataState.activeCall?.actions.shareScreen(stream);
    }
  }, [DataState.activeCall?.actions.shareScreen]);

  const onStop = useCallback(() => {
    setScreenShare(null);
    DataState.activeCall?.actions.shareScreen();
  }, [DataState.activeCall?.actions.shareScreen]);

  const onClick = useCallback(async () => {
      setShowPicker(v => !v)
  }, [sharing, setShowPicker, onStop]);

  useEffect(action(() => {
    setCallWidgetHovered("screenSharePicker", showPicker);
    setTooltip(showPicker ? undefined : "Share")
  }), [showPicker])

  const opacity = show ? 1 : 0;
  const transition = opacity ? "opacity 0.2s ease-out" : "opacity 0.3s ease-in";

  return (
    <>
      <div ref={ref} style={{ opacity, transition, display: "flex", }}>
        <IconActionButton
          data-tooltip={tooltip}
          analyticsEvent="CallScreenShare"
          color='secondary'
          onMouseDown={!showPicker ? onClick : undefined}
          badgeNum={openPlugins.length}
          {...otherProps}
        >
          <FaDesktop />
        </IconActionButton>
      </div>
      <CallScreenShareWindow
        targetRef={ref}
        parentId={parentId}
        show={showPicker}
        amSharing={sharing}
        onClose={() => setShowPicker(false)}
        onStart={onStart}
        onStop={onStop}
        onError={(e) => logger.error(`Failed to get screen share: ${e}`, e)}
      />
    </>
  );
});

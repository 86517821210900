import { createTheme, PaletteMode, ThemeOptions } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
const black = 'rgb(0,0,0)';
const white = 'rgb(255,255,255)';

const getTheme = (mode: PaletteMode): ThemeOptions => ({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: "#FF0A00",
          padding: '0px 5px',
          borderRadius: 999,
          minWidth: '16px',
          height: '16px',
          fontSize: '11px',
          fontWeight: 700
        }
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        variant: 'contained',
        disableRipple: true
      },
      styleOverrides: {
        grouped: {
          minWidth: 26,
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        textSecondary : {
          color: mode == 'light' ? '#333' : white,
        },
        sizeSmall: {
          fontSize: 12,
          minWidth: 28,
        },
        sizeMedium: {
          fontSize: 14,
        },
      }
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          padding: 3
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          appRegion: 'no-drag',
          pointerEvents: 'initial',
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none'
          },
          ':active': {
            boxShadow: 'none'
          }
        },
        sizeSmall: {
          minHeight: 26,
          height: 'auto',
          width: 'auto',
          borderRadius: 4,
          padding: '4px 6px',
          fontSize: 16
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        'root': {
          marginLeft: 0,
        },
        'label': {
          fontSize: 12,
          fontWeight: 400,
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,

          ':hover': {
            backgroundColor: mode === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.15)'
          }
        },
        sizeSmall: {
          fontSize: 16,
          padding: 6
        }
      }
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          padding: 3
        }
      }
    },
  },
  palette: {
    mode,
    primary: {
      main: 'rgba(255, 159, 0, 1)',
      contrastText: white
    },
    secondary:
      mode === 'light'
        ? {
            //main: 'rgba(235, 235, 240, 1)'
            main: white,
          }
        : {
            main: 'rgba(68, 68, 70, 1)'
            //main: black
          },
    success: {
      main: 'rgba(81, 185, 86, 1)',
      contrastText: white,
    },
    warning: {
      ...(mode === 'light' ? {
        main: 'rgba(247, 209, 85, 1)',
      } : {
        main: 'rgba(247, 209, 85, 1)'
      }),
      contrastText: '#333'
    },
    error: {
      main: 'rgba(255, 81, 71, 1)',
      contrastText: white
    },
    info: {
      main: 'rgba(62, 139, 255, 1)'
    },
    background:
      mode === 'light'
      ? {
        default: '#F1F3F4',
      } : {
        default: 'rgba(35, 41, 43, 1)',
      }
    ,
    text: (mode === 'light' ?
      {
      primary: '#333',
      //secondary: '#333',
      //disabled: white,
    } : {
      primary: white
    }),

    tonalOffset: 0.15
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
    button: {
      textTransform: 'none',
      fontFamily: 'Manrope',
      fontWeight: 700
    }
  }
});

export const lightTheme = createTheme(getTheme('light'));
export const darkTheme = createTheme(getTheme('dark'));

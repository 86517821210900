import { setCallWidgetHovered } from '@openteam/app-core';
import { IconButton, Icons, lighten, Row, styled, useTheme } from '@openteam/design-system';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { FaPaperclip } from 'react-icons/fa';
import useResizeObserver from 'use-resize-observer';
import { DataState } from '../../Data/DataState';
import { CamButton, ChatButton, MicButton, SettingsButton } from './CallButtons';
import { InlineChatCompose } from './CallChatCompose';
import { CallChatWindow } from './CallChatWindow';
import { ScreenShareButton } from './ScreenShare';

interface Props {
  hidden: boolean;
  parentId: string;
  style?: CSSProperties;
}

const CallFooter: React.FC<Props> = ({ parentId, hidden, ...props }) => {
  const { ref: chatRef, width, height } = useResizeObserver();
  const call = DataState.activeCall!;
  const showChatButton = call.mode !== 'large';
  const [show, setShow] = useState(true);
  // const showTimer = useRef<ReturnType<typeof setTimeout>>();
  const [showChatWindow, setShowChatWindow] = useState(false);
  const targetRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const callMessageManager = call.callMessageManager

  const showCompose = (width ?? 0) >= 180;

  const style = showChatButton
    ? { padding: 8, opacity: hidden ? 0 : 1, transition: hidden ? 'all 0.1s ease-in' : 'all 0.1s ease-out' }
    : { marginBottom: 16, marginTop: 8, justifyContent: 'center' };
  //useEffect(() => {
  //  if (hidden) {
  //    showTimer.current = setTimeout(() => setShow(false), 1000);
  //    return () => {
  //      showTimer.current && clearTimeout(showTimer.current)
  //      setShow(true)
  //    }
  //  }
  //}, [hidden])
  //
  //
  const toggleChatWindow = useCallback(() => {
    setShowChatWindow((v) => !v);
  }, []);

  const chatWindowClose = useCallback(() => {
    setShowChatWindow(false)
  }, [])

  return (
    <>
      {show ? (
        <Row style={{ ...style, ...props.style }} separator={<Separator />} spacing={6}>
          <Row spacing={6}>
            <MicButton />
            <CamButton />
            <ScreenShareButton parentId={parentId} />
            <SettingsButton />
          </Row>
          {showChatButton ? (
            <Row
              ref={chatRef}
              style={{
                flex: 1,
                height: 28,
                alignItems: 'center',
                justifyContent: showCompose ? 'flex-end' : 'flex-start'
              }}
            >
              {showCompose ? (
                <Row
                  style={{
                    flex: 1,
                    borderRadius: 4,
                    maxWidth: 300,
                    backgroundColor: lighten(theme.palette.secondary.main, 0.1),
                  }}
                  disableDrag
                >
                  <ChatButton 
                    ref={targetRef} 
                    onClick={toggleChatWindow}
                    badgeNum={callMessageManager?.numUnread}
                  />
                  {showChatWindow ? null : (
                    <>
                      <Separator />
                      {call.callMessageManager.draftFiles.length ? (
                        <IconButton
                          badgeNum={call.callMessageManager.draftFiles.length}
                          badgeColor="primary"
                          onClick={toggleChatWindow}
                        >
                          <FaPaperclip />
                        </IconButton>
                      ) : null}
                      <InlineChatCompose
                        windowId={parentId}
                        messageManager={call.callMessageManager}
                        onFocus={() => setCallWidgetHovered('ChatCompose')}
                        onBlur={() => setCallWidgetHovered('ChatCompose', false)}
                        />
                    </>
                  )}
                </Row>
              ) : (
                <ChatButton 
                ref={targetRef} 
                onClick={toggleChatWindow}
                badgeNum={callMessageManager?.numUnread}
                 />
              )}
            </Row>
          ) : null}
        </Row>
      ) : null}
      <CallChatWindow
        targetRef={targetRef}
        parentId={parentId}
        show={showChatWindow}
        onClose={chatWindowClose}
      />
    </>
  );
};

export default observer(CallFooter);

const Separator = styled('div')(({ theme }) => ({
  minWidth: 1,
  width: 1,
  backgroundColor: theme.palette.text.primary,
  opacity: 0.1,
  minHeight: 28,
  height: 28
}));

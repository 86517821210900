import { Logger } from '@openteam/app-util';
import { Panel, Row } from '@openteam/design-system';
import { KSpaceUserId } from '@openteam/models';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { DataState } from '../../Data/DataState';
import CallRoomTile from './CallRoomTile';

const logger = new Logger('CallRoomContent');

type Props = {
  callHovered: boolean
};

const CallTileLayout: React.FC<Props> = ({callHovered}) => {
  const tileAr = 1;
  const call = DataState.activeCall!;
  const { width = 0, height = 0, ref } = useResizeObserver();
  const [layout, setLayout] = useState({ rows: 1, columns: 1 });
  const [rowHeight, setRowHeight] = useState(0);
  const [participantTiles, setParticipantTiles] = useState<KSpaceUserId[]>([]);
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    logger.debug(`width: ${width}, height: ${height}`);
  }, [width, height]);

  useEffect(() => {
    if (width && height) {
      const numTiles = participantTiles.length;

      let [rows, columns] = [1, numTiles];

      while (Math.round(width / columns / tileAr) < Math.round(height / (rows + 1))) {
        rows += 1;
        columns = Math.ceil(numTiles / rows);
      }
      const rowHeight = Math.min(width / columns / tileAr, height / rows);

      logger.debug(`Recalc: ${width}x${height}, ${numTiles} tiles, rowHeight ${rowHeight}, layout ${rows},${columns}`);
      setLayout({ rows, columns });
      setRowHeight(rowHeight);
    }
  }, [participantTiles, width, height]);

  useEffect(() => {
    if (rowHeight >= 180) {
      setShowChat(true)
      call.callMessageManager.focusChat('callTile', true);
    } else {
      setShowChat(false)
      call.callMessageManager.unfocusChat('callTile');
    }
    return () => call.callMessageManager.unfocusChat('callTile');
  }, [rowHeight])

  useEffect(
    () =>
      autorun(() => {
        if (DataState.activeCall) {
          const tiles = [...DataState.activeCall!.participantList];
          if (!DataState.activeCall?.minimizeSelf || DataState.activeCall?.sharingScreen) {
            tiles.unshift(DataState.activeCall?.myUserId);
          }
          setParticipantTiles(tiles);
        }
      }),
    []
  );

  const tileSize = rowHeight - 12;

  return (
    <Panel
      ref={ref}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        //width: 'calc(100% - 8px)',
        overflow: 'hidden',
        margin: 4
      }}
    >
      <Row
        style={{
          ...Styles.content,
          //height: layout.rows * rowHeight,
          width: layout.columns * rowHeight * tileAr
        }}
      >
        {participantTiles.map((userId) => {
          let streamType: 'camera' | 'screen' = 'camera';
          return (
            <div
              key={`${userId}-${streamType}`}
              style={{
                position: 'relative',
                height: rowHeight,
                aspectRatio: `${tileAr}`,
                boxSizing: 'border-box',
                padding: 3,
                //transition: 'height 0.2s ease'
              }}
            >
              <CallRoomTile
                userId={userId}
                streamType={streamType}
                hovered={callHovered}
                tileSize={tileSize}
                aspectRatio="1"
                showChat={showChat}
              />
            </div>
          );
        })}
      </Row>
    </Panel>
  );
};

const Styles: Record<string, CSSProperties> = {
  content: {
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  },
  topIndicators: {
    position: 'absolute',
    top: '3%',
    right: '3%'
  },
  bottomIndicators: {
    position: 'absolute',
    top: '3%',
    right: '3%'
  },
  tileContainer: {}
};

export default observer(CallTileLayout);

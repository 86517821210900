import { KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import React from 'react';
import { DataState } from '../../Data/DataState';
import { Logger } from '@openteam/app-util';
import { DSBody, DSH2, DSH3, DSH4, DSPrint } from '../../DesignSystem/DSText';
import { DS2Switch, DSColumn, DSHSpacer, DSPanel, DSRow } from '../../DesignSystem';
import { GoogleCalendarAuth } from '../Calendar/GoogleCalendarAuth';
import { GoogleCalendarIcon } from '../Calendar/GoogleCalendarLogo';
import { OTUITree } from '@openteam/app-core';
import { DropMenu } from './DropMenu';
import { DSSpinner } from '../../DesignSystem/DSSpinner';

const logger = new Logger("GoogleCalendarSettings")

export const GoogleCalendarSettings: React.FC<{
  parentWindowId: string,
  spaceId: KSpaceId,
  scrollVersion: number,
}> = observer(({ parentWindowId, spaceId, scrollVersion }) => {

  const calendarManager = OTUITree.userManager.calendarManager;
  const cals = calendarManager.selectedCalendars;
  const myCalendars = calendarManager.myCalendars;
  const otherCalendars = calendarManager.otherCalendars;
  const { isBusy, connecting, disconnecting, loaded } = OTUITree.userManager.calendarManager;

  const getCalendar = (calId: string) => calendarManager.calendarsById[cals[calId].id];

  const setCalendarNotify = (calId: string, value: boolean) => {
    calendarManager.setNotify(calId, value);
  }
  const setCalendarIsPublic = (calId: string, value: boolean) => {
    calendarManager.setIsPublic(calId, value);
  }

  const isPublicOptions: [string, string][] = [
    ["Public", "Show meeting name"],
    ["Private", "Hide meeting name"],
  ]

  logger.debug(`isBusy ${isBusy}, connecting: ${connecting}, loaded: ${loaded}`);

  const renderCalendarSetting = (calId: string, index: number) => (
    <DSRow key={`calendar-setting-${calId}`} style={{ margin: 10, ...Styles.settingRow }}>
      <DSColumn style={{ flex: 1 }}>
        <DSPrint>{getCalendar(calId).summary}</DSPrint>
      </DSColumn>
      <DSColumn style={{ paddingRight: 20 }}>
        <DS2Switch
          onChange={(checked) => setCalendarNotify(calId, checked)}
          checked={!!cals[calId].notifyEvents}
        />
      </DSColumn>
      <DSColumn style={{ width: 140 }}>
        <DropMenu
          windowId={`workspaceIntegrationSettingsCalId-${index}`}
          parentId={'global-settings'}
          options={isPublicOptions}
          onItemSelected={(item) => setCalendarIsPublic(calId, item === "Public")}
          value={!!cals[calId].isPublic ? "Public" : "Private"}
          width={160}
          scrollVersion={scrollVersion}
        />
      </DSColumn>
    </DSRow>
  );

  return (
    <div>

      <DSRow style={Styles.header} spacing={16}>
        <GoogleCalendarIcon size={54} />
        <DSColumn style={{ flex: 1 }}>
          <DSBody wrap={true} >
            Connect your google account to sync your meetings with ehlo and share your status with your teammates.
          </DSBody>
        </DSColumn>
        <DSColumn style={{ alignItems: "center", minWidth: 100 }}>
          <GoogleCalendarAuth />
        </DSColumn>
      </DSRow>

      {
        calendarManager.isAuthorised ? (
          <>
            <DSRow style={Styles.settingRow}>
              <DSColumn style={{ flex: 1, alignItems: "flex-end" }}>
                <DSPrint>Connected as: {calendarManager.email}</DSPrint>
              </DSColumn>
            </DSRow>
            {
              connecting || !loaded ? (
                <DSColumn style={{ marginTop: 40, alignItems: 'center' }} spacing={8}>
                  <DSH2>Loading Calendars</DSH2>
                  <DSSpinner />
                </DSColumn>
              ) : !isBusy ? (
                <>
                  {
                    myCalendars.length + otherCalendars.length > 0 && (
                      <DSPanel style={{ margin: 15 }}>
                        <DSRow style={Styles.settingRow}>
                          <DSColumn style={{ flex: 1 }}>
                            <DSH3>Meeting notifications</DSH3>
                          </DSColumn>
                        </DSRow>
                        <DSRow style={Styles.settingRow}>
                          <DSColumn style={{ flex: 1 }}>
                            <DSBody>
                              To be notified of specific meetings, select the calendars that are of interest to you.
                            </DSBody>
                          </DSColumn>
                        </DSRow>
                        {
                          myCalendars.length > 0 && (
                            <>
                              <DSHSpacer size={10} />
                              <DSRow style={Styles.settingRow}>
                                <DSColumn style={{ flex: 1 }}>
                                  <DSH3>My calendars</DSH3>
                                </DSColumn>
                              </DSRow>
                              {myCalendars.map((calId, index) => renderCalendarSetting(calId, index))}
                            </>
                          )
                        }
                        {
                          otherCalendars.length > 0 && (
                            <>
                              <DSHSpacer size={10} />
                              <DSRow style={Styles.settingRow}>
                                <DSColumn style={{ flex: 1 }}>
                                  <DSH3>Other calendars</DSH3>
                                </DSColumn>
                              </DSRow>
                              {otherCalendars.map((calId, index) => renderCalendarSetting(calId, index + myCalendars.length))}
                            </>
                          )
                        }
                      </DSPanel>
                    )
                  }
                </>
              ) : null
            }
          </>

        ) : null
      }
    </div >
  );
});

const Styles = {
  header: {
    marginTop: 24,
  },
  settingRow: {
    marginTop: 12,
    justifyContent: "space-between",
    //alignItems: 'center'
  },
}

import { OTUITree } from "@openteam/app-core";
import { Logger } from "@openteam/app-util"
import { observer } from "mobx-react";
import React, { useLayoutEffect, useState } from "react";
import { getDockSize, UIState } from "../../Data/UIState";
import { ModalOptions, SubWindow, SubWindowCSS } from "../SubWindow";
import { isMacOs } from "react-device-detect";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { ToolTipContainer } from "../../Controllers/ToolTips";
import { action } from "mobx";
import { DSPanel, DSRow, DSHSpacer, DSColumn } from "../../DesignSystem";
import { DSImage } from "../../DesignSystem/DSImage";
import { DSVideo } from "../../DesignSystem/DSVideo";
import { MimeTypeIcon } from "../../Util/MimeTypeIcon";
import { DSPdf } from "../../DesignSystem/DSPdf";
import { DSNext, DSPrev, DSDownload, DSOpenExternal, DSClose } from "../../DesignSystem/DSIconButtons";
import { DSH3, DSH4, DSSmallPrint } from "../../DesignSystem/DSText";
import { DSFilledButton } from "../../DesignSystem/DSButton";
import { IUIUserMinimal } from "@openteam/models";
import UserIcon from "../User/UserIcon";
import { getFriendlyDate } from "../../Util/DateFormat";
import { DS2Button, DS2CloseIconButton, DS2DownloadIconButton, DS2IconActionButton, DS2Icons, DS2OpenExtIconButton } from "../../DesignSystem/DS2";

const logger = new Logger("LightBox")

export interface LightBoxEntry {
  user: IUIUserMinimal
  crDate?: Date

  url: string
  type: string
}

type Props = {
  items: LightBoxEntry[]
  initialSelected?: number
  onClose: () => void
}


export const LightBox: React.FC<Props> = observer((props) => {

  const [curIndex, setCurIndex] = useState(props.initialSelected || 0);
  const [isDownloading, setIsDownloading] = useState(false);

  if (!props.items || props.items.length === 0) {
    return null
  }

  const item = props.items[curIndex]

  const onPrev = (e) => {
    e.stopPropagation()

    setCurIndex((val) => Math.max(val - 1, 0))
    setIsDownloading(false)
  }

  const onNext = (e) => {
    e.stopPropagation()
    setCurIndex((val) => Math.min(val + 1, props.items.length - 1))
    setIsDownloading(false)
  }

  const onDownload = () => {
    if (item.url) {
      window.Main.downloadUrl(item.url)
      setIsDownloading(true)

      setTimeout(() => setIsDownloading(false), 2000)
    }
  }

  const onOpenExternal = () => {
    item.url && window.Main.shellOpenExternal(item.url)

  }

  const onClose = () => {
    props.onClose()
    setCurIndex(0)
    setIsDownloading(false)
  }


  let filename: any = '';
  try {
    filename = new URL(item.url).pathname.replaceAll("%2F", "/").split('/').pop();
  } catch (e) {
    console.error(e);
  }

  return (
    <DSPanel
      onClick={onClose}
      style={{
        position: 'absolute',
        zIndex: 999,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 30,
        paddingTop: 40,
        backgroundColor: DSTheme.OverlayBackgroundColor,
        color: DSTheme.OverlayTextColor,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <DSRow
        style={{ padding: 5, position: 'absolute', top: 0, right: 0, left: 0 }}
      >


        <DSRow
          style={{ alignItems: 'center', paddingLeft: 16 }}
          onClick={(e) => e.stopPropagation()}
        >
          {
            item.user ?
              <UserIcon
                user={item.user}
                size={33}
              />
              :
              undefined
          }
          <DSHSpacer size={10} />
          <DSColumn style={{
            overflow: 'hidden',
          }}>
            {
              item.user ?
                <DSRow style={{ alignItems: 'center' }}>
                  <DSH3 style={{
                    whiteSpace: 'nowrap',
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}>
                    {item.user.name}
                  </DSH3>
                  <DSSmallPrint style={{ color: DSTheme.SecondaryText, paddingLeft: 8 }}>
                    {getFriendlyDate(item.crDate)}
                  </DSSmallPrint>
                </DSRow>
                :
                undefined
            }
            <DSSmallPrint style={{ color: DSTheme.SecondaryText }}>
              {filename}
            </DSSmallPrint>
          </DSColumn>

        </DSRow>
        <DSRow style={{ flex: 1 }} />
        <DSRow
          style={{ alignItems: 'center', }}
          onClick={(e) => e.stopPropagation()}
          spacing={6}>
          <DS2DownloadIconButton onClick={onDownload} />
          <DS2OpenExtIconButton onClick={onOpenExternal} />
          <DS2CloseIconButton onClick={onClose} />
        </DSRow>
      </DSRow>
      <DSRow style={{
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
      }}>
        {
          props.items.length > 1 ?
            <DSPanel
              style={{ position: 'absolute', left: 30 }}
              onClick={(e) => e.stopPropagation()}
            >
              <DS2IconActionButton
                color='secondary'
                onClick={onPrev}
                disabled={ curIndex === 0 }
              >
                  {DS2Icons.prev}
              </DS2IconActionButton>
            </DSPanel>
            :
            undefined
        }
        <DSPanel style={{
          maxWidth: "100%",
          maxHeight: "100%",
          overflow: 'hidden',
        }}>
          {
            item.type.startsWith("image") ?
              <DSImage
                key={item.url}
                url={item.url}
                resizeMode="none"
                maxHeight={"100%"}
                maxWidth={"100%"}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                showLoadingSpinner={true}
                style={{
                  alignSelf: 'center',
                  borderRadius: undefined,
                  width: "fit-content",
                  height: "fit-content",
                  objectFit: 'contain',
                  overflow: "hidden"
                }} />
              :
              item.type.startsWith("video") ?
                <DSVideo url={item.url} />
                :
                <DSPanel
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    width: 212,
                    minHeight: 182,
                    borderRadius: 6,
                    backgroundColor: "#F7F7F7",
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <DSPanel style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 20
                  }}>
                    <MimeTypeIcon mimeType={item.type} size={72} color={DSTheme.SecondaryText} />
                    <DSSmallPrint wrap style={{ color: 'black', marginTop: 6, textAlign: 'center' }}>
                    {filename}
                    </DSSmallPrint>
                    <DS2Button
                      startIcon={DS2Icons.download}
                      outerStyle={{ marginTop: 12 }}
                      onClick={onDownload}
                    >
                      Download
                    </DS2Button>
                  </DSPanel>
                </DSPanel>
          }
        </DSPanel>
        {
          props.items.length > 1 ?
            <DSPanel
              style={{ position: 'absolute', right: 30 }}
              onClick={(e) => e.stopPropagation()}
            >
              <DS2IconActionButton
                color='secondary'
                onClick={onNext}
                disabled={ curIndex >= props.items.length - 1 }
              >
                  {DS2Icons.next}
              </DS2IconActionButton>
            </DSPanel>
            :
            undefined
        }
      </DSRow>

      {
        isDownloading ?
          <DSPanel
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              position: 'absolute',
              left: 30,
              bottom: 30,
              width: 140,
              minHeight: 50,
              borderRadius: 6,
              backgroundColor: "#F7F7F7",
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <DSH4 style={{ color: 'black', }}>
              Download started
            </DSH4>
          </DSPanel>
          :
          undefined
      }

    </DSPanel >
  )
})

import { CloudUpload, FireDb, OTAppCoreData, OTUITree } from '@openteam/app-core';
import { IMessageFile } from '@openteam/models';
import { observer } from 'mobx-react';
import React, { useState, DragEventHandler, useEffect } from 'react';
import { DSButton } from '../../DesignSystem/DSButton';
import { DSTextArea } from '../../DesignSystem/DSInput';
import { DSBody, DSH3, DSPrint } from '../../DesignSystem/DSText';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DSColumn, DSPanel, DSRow, DSWrappedRow } from '../../DesignSystem';
import { ChatFileUpload } from '../Chat/ChatFileUpload';
import { Droppable } from '../DragAndDrop';
import { DSModalPanel } from '../Modal/ModalLayout';
import { FaPaperclip } from "react-icons/fa";
import { DSSpinner } from '../../DesignSystem/DSSpinner';
import { DataState } from '../../Data/DataState';
import * as Analytics from '../../Util/Analytics'
import { DS2Button, DS2IconActionButton, DS2Icons } from '../../DesignSystem';
import { useTheme } from '@openteam/design-system';
import { UIState } from '../../Data/UIState';
import { appUpgrade } from '../../Controllers/AppUpgrade';

const acceptedImageFiles = ['image/gif', 'image/png', 'image/jpeg', 'image/bmp']

interface IFeedbackFormProps {
  spaceId: string
  onClose?: () => void
}

export const FeedbackForm = observer((props: IFeedbackFormProps) => {
  const spaceId = OTUITree.userManager.currentTeamId!
  const space = DataState.spaces[spaceId];

  const [sending, setSending] = useState<boolean>(false);
  const [message, setMessage] = useState<undefined | string>(undefined);
  const [messageFiles, setMessageFiles] = useState<IMessageFile[]>([])
  const [isOver, setIsOver] = useState<boolean>(false);
  let _fileInput = React.createRef<HTMLInputElement>();
  const theme = useTheme()


  useEffect(() => {
    Analytics.logEvent("Screen_FeedbackForm")
    return () => Analytics.logEvent("Screen_FeedbackFormClosed")
  }, [])

  const onPaste = (
    // view,
    pasteEvent: React.ClipboardEvent<HTMLTextAreaElement>
  ) => {
    Object.keys(pasteEvent.clipboardData.files).forEach((i) => {
      let item = pasteEvent.clipboardData.files[i];

      if (acceptedImageFiles.includes(item.type)) {
        // var blob = item.getAsFile() as File;
        console.log("got pasted image,");

        const uploadFile = new CloudUpload(spaceId!, undefined, OTUITree.auth.userId, "feedback", item)

        setMessageFiles((mfiles) => [...mfiles, uploadFile])

      }
    })

    return false;
  };

  const removeFile = (index) => {
    setMessageFiles((mfiles) => {
      const newFiles = [...mfiles]
      newFiles.splice(index, 1)

      return newFiles
    })

  }

  const onFiles = (files: FileList | null) => {
    if (!files) {
      return;
    }

    Object.keys(files || {}).forEach((i) => {
      let file = files[i];

      const uploadFile = new CloudUpload(spaceId!, undefined, OTUITree.auth.userId, "feedback", file)

      setMessageFiles((mfiles) => [...mfiles, uploadFile])
    });
  }

  const onDrop: DragEventHandler = (e) => {
    setIsOver(false);

    if (!e.dataTransfer.files) {
      return;
    }

    Object.keys(e.dataTransfer.files || {}).forEach((i) => {
      let file = e.dataTransfer.files[i];

      const uploadFile = new CloudUpload(spaceId!, undefined, OTUITree.auth.userId, "feedback", file)

      setMessageFiles((mfiles) => [...mfiles, uploadFile])
    });
  }

  const sendFeedback = async () => {
    setSending(true)

    if (messageFiles) {
      await Promise.all(messageFiles.map((cu) => cu.complete()));
    }

    const userDoc = OTUITree.userManager.userDoc!

    const body = `${message} \n\n\n Attachments \n\n ${messageFiles.map(file => file.downloadUrl).join("\n\n")}`

    const result = await FireDb.sendAdminEmail(
      `Feedback from ${userDoc?.name} - ${space.details.name}`,
      body,
      userDoc.email
    )

    setSending(false)
    if (result) {
      setMessage('');
      setMessageFiles([])
      props.onClose?.()
    }
  }

  const reset = () => {
    setMessage('');
    setMessageFiles([])
  }

  const upgradeAvailable = UIState.mainUpgradeAvailable || UIState.webUpgradeAvailable;

  return (
    <DSColumn style={{ position: 'relative' }} spacing={32}>
      <Droppable
        acceptItemType={"Files"}
        onDrop={onDrop}
        onDragOver={(e) => setIsOver(true)}
        onDragLeave={(e) => setIsOver(false)}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
        }}
      >

        <DSColumn style={{ position: 'relative' }} spacing={16}>

          <DSH3 >Send us feedback or report a bug</DSH3>
          <DSTextArea
            placeholder="Type Message and drag files here"
            style={{
              color: theme.palette.text.primary,
              paddingLeft: 8,
              minHeight: 120,
              resize: "vertical",
              verticalAlign: 'center',
            }}
            autoFocus={true}
            value={message}
            onPaste={
              onPaste
            }
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />


          <DSRow
            style={{ justifyContent: "space-between" }}
            spacing={16}
          >
            <DSWrappedRow style={{ alignItems: 'center', flex: 1 }}>

              {
                messageFiles.map((uf, index) => (
                  <DSPanel style={{
                    marginLeft: 5, marginRight: 5,
                  }}>
                    <ChatFileUpload
                      key={`${index}-${uf.file.name}`}
                      file={uf}
                      onRemove={() => removeFile(index)}
                    />
                  </DSPanel>
                ))
              }
              {
                false && messageFiles.length === 0 ? (
                  <DSPrint color={theme.palette.text.disabled} style={{ fontWeight: 600 }}>
                    Drag files here to attach
                  </DSPrint>
                ) : null}
            </DSWrappedRow>
            <DSRow spacing={8} style={{ height: 'fit-content' }}>

              <DS2Button
                onClick={() => { _fileInput.current?.click() }}
                analyticsEvent="feedbackInputSelectFile"
                color="secondary"
                startIcon={<FaPaperclip />}
              >
                Attach file
                <input
                  ref={_fileInput}
                  style={{ display: "none" }}
                  type="file"
                  multiple
                  onChange={(e) => onFiles(e.target.files)}
                />
              </DS2Button>
              <DS2Button
                analyticsEvent='FeedbackFormSend'
                onClick={sendFeedback}
                color="primary"
                startIcon={sending ? <DSSpinner size={14} /> : undefined}
                disabled={sending || (message?.length ?? 0) == 0}
              >
                Send
              </DS2Button>
            </DSRow>
          </DSRow>
        </DSColumn>
      </Droppable>
      <DSColumn spacing={16}>

        <DSH3>Feature request </DSH3>
        <DSBody
          wrap
          color={theme.palette.text.primary}
          onClick={() => window.Main.shellOpenExternal("https://ehlo.canny.io")}
          style={{ cursor: 'pointer' }}
        >
          If you'd like to request a feature or add your vote to those that others have requested,
          check out our canny board at <span style={{ color: theme.palette.info.main }}>ehlo.canny.io</span>
        </DSBody>
      </DSColumn>
      <DSColumn spacing={16}>
        <DSH3>Support email </DSH3>
        <DSBody
          wrap
          color={theme.palette.text.primary}
          onClick={() => window.Main.shellOpenExternal("mailto:support@ehlo.space")}
          style={{ cursor: 'pointer' }}
        >
          You can also email us for support at <span style={{ color: theme.palette.info.main }}>support@ehlo.space</span>
        </DSBody>
      </DSColumn>
      <DSColumn spacing={16} style={{}}>
      <DSH3>Upgrade </DSH3>

        <DSRow style={{justifyContent: 'space-between'}}>

        <DSPrint style={{}}>renderer version: {OTAppCoreData.version}</DSPrint>
            <DS2Button
              disabled={!upgradeAvailable}
              style={{width: 'fit-content'}}
              startIcon={DS2Icons.upgrade}
              onClick={appUpgrade}
            >
              {upgradeAvailable? 'Restart & Upgrade' : "You are up to date"}
            </DS2Button>
        </DSRow>
      </DSColumn>
    </DSColumn >
  );
})

export default FeedbackForm

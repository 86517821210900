import React, { useCallback, useEffect } from "react";

import { observer } from "mobx-react";
import { DSTheme, ThemeState } from "../../DesignSystem/DSTheme";

import { Logger } from "@openteam/app-util";
import { OTGlobals, OTUITree } from '@openteam/app-core';
import { setDockComponentLength, UIState } from "../../Data/UIState";
import { getSignalStatusIcon } from "../Call/SignalStrength";
import { DSSmallPrint } from "../../DesignSystem/DSText";
import { TutorialStepHolder } from "../Tutorial/TutorialStepHolder";
import { DockControls } from "./DockControls";
import { DockUsers } from "./DockUsers";
import { DockCurrentUserIcon } from "./DockCurrentUserIcon";
import { DockRooms } from "./DockRooms";
import { DockCalls } from "./DockCalls";
import { DockHomeIcon } from "./DockHomeIcon";
import { SearchWindow } from "../Search/SearchWindow";
import { DockOtherAlerts } from "./DockOtherAlerts";
import { WaitingUsersDockIcon } from "./WaitingUsersDockIcon";
import DockNextEvent from "./DockNextEvent";
import * as Analytics from '../../Util/Analytics'
import DockOnboardingButton from "./DockOnboardingButton";
import { DSPanel } from "../../DesignSystem";
import DockActiveCall from "./DockActiveCall";

const logger = new Logger("DockContainer")

interface IDockContainerProps {
  hovered: boolean;
  clearDockHover?: () => void;
}

export const DockContainer = observer((props: IDockContainerProps) => {

  const spaceId = OTUITree.userManager.currentTeamId


  const dockRadius = 8

  useEffect(() => {
    setDockComponentLength(`DockFooter`, DSTheme.DockMargin);
    return () => setDockComponentLength(`DockFooter`, 0);
  }, [DSTheme.DockMargin]);

  useEffect(() => {
    Analytics.setProperties();
  }, [spaceId]);

  const backgroundColor = !UIState.dockShown ? DSTheme.DockBackgroundTransparentColor : UIState.mainCapabilities.vibrancy && ThemeState.selectedTheme == 'SYSTEM' ? 'transparent' : props.hovered ? DSTheme.DockBackgroundHoverColor : DSTheme.DockBackgroundColor;
  return (

    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: UIState.dockHorizontal ? "row" : "column",
        alignItems: "center",
        width: UIState.dockHorizontal ? undefined : UIState.dockSize,
        height: UIState.dockHorizontal ? UIState.dockSize : undefined,
        backgroundColor,
        // backgroundColor: UIState.dockShown ? DSTheme.DockBackgroundColor : DSTheme.DockBackgroundTransparentColor,
        transition: UIState.dockShown ? "background-color 0.2s ease-out" : "background-color 0.7s ease-in",
        borderRadius: dockRadius,
        paddingBottom: UIState.dockHorizontal ? undefined : Math.floor(UIState.dockSize * DSTheme.DockMargin),
        paddingRight: UIState.dockHorizontal ? Math.floor(UIState.dockSize * DSTheme.DockMargin) : undefined,
      }}>
      <TutorialStepHolder
        parentId="__dock__"
        ids={["intro", "dock-autohide", "dock-edge", "finish"]}
        position={UIState.dockPosition.onLeft ? 'right' : 'left'}
        offsetX={UIState.dockPosition.onLeft ? 10 : -10}
        style={{
        }}>
        <DockControls clearDockHover={props.clearDockHover} hide={!props.hovered} />
      </TutorialStepHolder>

      <DockHomeIcon spaceId={spaceId!} horizontal={UIState.dockHorizontal}/>

      <DockNextEvent />

      <DockActiveCall />

      <DockCurrentUserIcon spaceId={spaceId!} />

      <WaitingUsersDockIcon spaceId={spaceId!} />

      <DockOtherAlerts spaceId={spaceId!} />

      <DockUsers spaceId={spaceId!} />

      <DockCalls spaceId={spaceId!} />

      <DockRooms spaceId={spaceId!} />

      <DockOnboardingButton spaceId={spaceId!} />

      {
        UIState.dockSearchOpen ?
          <SearchWindow spaceId={spaceId!} onClose={() => UIState.dockSearchOpen = false} />

          :
          null
      }

      {
        !OTGlobals.isConnected ?
          <DSPanel style={{
            backgroundColor: 'rgba(35, 41, 43, 0.8)',
            color: DSTheme.MainText,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: 'center',
            zIndex: 20,
            paddingTop: 16,
          }}>
            <DSPanel
              style={{
                transition: "opacity 0.3s",
                borderRadius: "50%",
                zIndex: 20,
                padding: 2,
                boxShadow: `1px 1px 1px 1px ${DSTheme.CallShadowColor}`,
                backgroundColor: DSTheme.CallIndicatorBackgroundColor,
              }}>
              {getSignalStatusIcon("disconnect", 16).icon}
            </DSPanel>
            <DSSmallPrint wrap style={{ marginTop: 16, textAlign: 'center' }}>
              You appear to be offline.
            </DSSmallPrint>
            <DSSmallPrint wrap style={{ marginTop: 16, textAlign: 'center' }}>
              Check your connection.
            </DSSmallPrint>
          </DSPanel>
          :
          null
      }
    </div>
  )
})

import React, { useCallback, useEffect, useState } from 'react';

import { Logger } from "@openteam/app-util";
import { IChatMessage, KSpaceChannelId, KSpaceId, KSpaceUserId } from '@openteam/models';
import { observer } from 'mobx-react';
import { addToRecentUsers, openUserChat, setUserPanelOpen } from '../../Data/UIState';
import { DataState } from '../../Data/DataState';
import { getDummyUser } from '../../Data/tutorialDummy';
import UserIcon from '../User/UserIcon';
import { DSBody, DSH3, DSPrint, DSSmallPrint } from '../../DesignSystem/DSText';
import { DSSeparator } from '../../DesignSystem/DSSeparator';
import { getUserCustomStatusEmoji, getUserCustomStatusText, getUserMeetingStatusText, getUserStatus } from '../User/StatusUtil';
import StatusDot from '../User/StatusDot';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DS2CallButton, DS2ChatButton, DS2IconButton, DS2KnockButton, DS2PinButton, DSColumn, DSRow, DSVSpacer, styled } from '../../DesignSystem/DS2';
import { formatInTimeZone } from 'date-fns-tz'

import UserCallButton from '../User/UserCallButton';
import { GoogleCalendarIcon } from '../Calendar/GoogleCalendarLogo';
import DockUserPanelChat from './DockPanelChat/DockUserPanelChat';
import { useTheme } from '@openteam/design-system';
import DockPanelEmojiSender from './DockPanelChat/DockPanelEmojiSender';
import DockPanelChat from './DockPanelChat/DockPanelChat';

const logger = new Logger("DockUserPanel");

interface IDockUserPanelProps {
  spaceId: KSpaceId
  userId: KSpaceUserId
  closeWindow?: (onCloseCallback?: () => void) => void
  isDockPanel?: boolean
}



export const DockUserPanel: React.FC<IDockUserPanelProps> = observer(({isDockPanel=true, ...props}) => {
  const space = DataState.spaces[props.spaceId]
  const isTutorial = props.userId == 'dummy'
  const user = isTutorial ? getDummyUser(props.userId) : space.users[props.userId]
  const theme = useTheme()

  const call = user.roomId && space.calls[user.roomId]

  const emoji = getUserCustomStatusEmoji(user);
  const customStatusText = getUserCustomStatusText(user);

  const [channelId, setChannelId] = useState<KSpaceChannelId>()
  const [lastMessage, setLastMessage] = useState<IChatMessage>()


  useEffect(() => {
    if (isDockPanel) {
      setUserPanelOpen(props.spaceId, props.userId, true);
      return () => setUserPanelOpen(props.spaceId, props.userId, false);
    }
  }, [props.spaceId, props.userId])

  useEffect(() => {
    (async () => {
      const space = DataState.spaces[props.spaceId];


      let channelId = space.userChats[props.userId]?.channelId;

      if (!channelId) {
        const user = space.users[props.userId];
        channelId = await user.actions.getChannel();
      }

      setChannelId(channelId)

    })()
  }, [props.spaceId, props.userId])

  logger.debug(`user ${user.name} - tz ${user.status?.timezone}`)

  const openChat = React.useCallback(() => {
    openUserChat(props.spaceId, props.userId)
  }, [props.spaceId, props.userId])

  const sendEmoji = useCallback(async (emoji) => {
    if (channelId) {
      const chat = space.channels[channelId]
      setLastMessage(await chat.actions.sendMessage(emoji));
    }
  }, [channelId])

  return (
    <DSColumn
      style={{ padding: "12 12", minWidth: 300, color: DSTheme.MainText }}
      separator={<DSSeparator style={{ opacity: 0.2, margin: "12 0", minHeight: 1 }} />}
    >

      <DSRow style={{ alignItems: 'center' }}>
        <UserIcon
          key={user.id}
          user={user}
          size={60}
        />

        <DSColumn style={{ paddingLeft: 12, flex: 1 }}>
          <DSRow spacing={8} style={{ alignItems: 'center' }}>
            <DSH3>
              {user.name}
            </DSH3>
            <DS2PinButton pinned={user.pinned} onClick={user.actions.togglePinned} />
          </DSRow>
          <DSBody>
            {user.team}
          </DSBody>
        </DSColumn>


      </DSRow>

      <DSColumn spacing={4}>
        <DSRow style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <DSRow style={{ alignItems: 'center' }} spacing={8}>
            <StatusDot
              user={user}
              showCustomStatusEmoji={false}
              size={10}
            />
            <DSPrint>
              {getUserStatus(user)}
            </DSPrint>
          </DSRow>
          <DSPrint>
            {user.status?.timezone && formatInTimeZone(new Date(), user.status.timezone, 'HH:mm (zzz)')}
          </DSPrint>
        </DSRow>

        {
          emoji || customStatusText ?
            <DSRow style={{ alignItems: 'center' }} spacing={8}>
              {
                emoji ? <DSPrint>{emoji}</DSPrint> : null
              }

              {
                customStatusText ? <DSPrint>{customStatusText}</DSPrint> : null
              }
            </DSRow>
            : null
        }

        {
          user.status?.meetingStatus && (
            <DSRow style={{ alignItems: 'center', paddingLeft: 0 }} spacing={8}>
              <GoogleCalendarIcon size={14} />
              <DSPrint>
                {getUserMeetingStatusText(user)}
              </DSPrint>
            </DSRow>
          )
        }

        {
          call && call.id !== DataState.activeCall?.id ?
            <>
              <DSVSpacer size={8} />
               
              <UserCallButton spaceId={props.spaceId} userId={props.userId} fullWidth />
            </>
            :
            undefined
        }

      </DSColumn>

      <DSColumn spacing={12}>

        <DSRow style={{ justifyContent: 'space-evenly' }} spacing={12}>
          <DS2ChatButton
            fullWidth
            analyticsEvent="openChatUser"
            analyticsArgs={{ userId: user.id }}
            onClick={openChat}
            badgeNum={space.userChats[props.userId]?.numUnread}
            badgeOverride={space.userChats[props.userId]?.hasMention ? '@' : undefined}
          />

          {/*<DS2CallButton
            key={`call-request-${props.userId}`}
            analyticsEvent={`callRequest`}
            analyticsArgs={{ userId: user.id }}
            title={DataState.activeCall ? "Invite" : `Request to talk`}
            onClick={() => {
              props.closeWindow?.();
              user.actions.startCallRequest('video');
            }}
          />*/}

          {/* <DS2KnockButton
            fullWidth
            analyticsEvent="KnockUser"
            analyticsArgs={{ userId: user.id }}
            disabled={!user.status?.online || call !== undefined}
            onClick={() => {
              if (user.alerts?.knocked?.count && user.alerts?.knocked?.count > 0) {
                user.alerts.clear()
              } else {
                user.actions.knockUser();
              }
              addToRecentUsers(props.spaceId, user.id)
            }}
            badgeNum={user.alerts?.knocked?.count}
          />
          */}
          
          {
           !call ? <UserCallButton spaceId={props.spaceId} userId={props.userId} fullWidth /> : null
          }
        </DSRow>
        <DockPanelEmojiSender sendEmoji={sendEmoji} />
      </DSColumn>

      {
        channelId ? (
          <DockPanelChat
            spaceId={props.spaceId}
            channelId={channelId}
            openChat={openChat}
            lastMessage={lastMessage}
          />
        ) : null
      }

    </DSColumn>
  );
})

// import * as GoogleSlidesPresent from './GoogleSlidesPresent'

import { IPluginConfig } from "@openteam/app-core";
import * as LinkSharer from "./LinkSharer";
import * as Youtube from "./Youtube";
import * as VideoPlayer from "./VideoStreamPlayer";

export const getPluginConfig = (): Record<string, IPluginConfig> => ({
  // 'googleslidespresent': GoogleSlidesPresent.pluginConfig,
  // 'vscodeshare': VSCodeShare.pluginConfig,
//  [GoogleDrive.pluginType]: GoogleDrive.pluginConfig,
  [LinkSharer.pluginType]: LinkSharer.pluginConfig,
//  [MiroBoard.pluginType]: MiroBoard.pluginConfig,
//  [Trello.pluginType]: Trello.pluginConfig,
//  [VSCodeShare.pluginType]: VSCodeShare.pluginConfig,
//  [Witeboard.pluginType]: Witeboard.pluginConfig,
  [Youtube.pluginType]: Youtube.pluginConfig,
  [VideoPlayer.pluginType]: VideoPlayer.pluginConfig,
});

import React, { useCallback, useState } from 'react';
import { IChatFileHit, IPinnedResource } from '@openteam/models';
import { DSColumn, DSPanel, DSPanelScrollable, DSRow } from '../../../DesignSystem';
import { DSCircleIconButton } from '../../../DesignSystem/DSButton';
import { FaCopy, FaEdit, FaThumbtack, FaTrash } from 'react-icons/fa';
import { DSH2, DSH4, DSPrint } from '../../../DesignSystem/DSText';
import DSSearchBox from '../../../DesignSystem/DSSearch';
import { Hit } from "@algolia/client-search";
import { createNullCache } from "@algolia/cache-common"

import { debounce } from 'throttle-debounce';
import { Logger } from '@openteam/app-util';
import { autorun, toJS } from 'mobx';
import { useRef } from 'react';
import algoliasearch, { SearchIndex } from 'algoliasearch';
import { useEffect } from 'react';
import { OTUITree, searchIndexVersion } from '@openteam/app-core';
import { DSTheme } from '../../../DesignSystem/DSTheme';
import { fmtResourceName, SearchResultFileBase } from '../../Search/SearchControl';
import { RoomResourceEditWindow } from './RoomResourceModal';
import { observer } from 'mobx-react';
import { DSTab, DSTabs } from '../../../DesignSystem/DSTabs';
import { ResourcePropertiesWindow } from './ResourcePropertiesWindow';
import { DSSeparator } from '../../../DesignSystem/DSSeparator';


const logger = new Logger("AllResources");

const hitsPerPage = 20;

export const AllResources: React.FC<{
  spaceId: string,
  channelId: string,
  windowId: string,
  recentResources: Record<string, IPinnedResource>,
  togglePinnedResource: (resource: IPinnedResource) => Promise<void>,
  deletedResources: string[],
  deleteResource: (resource: IPinnedResource) => Promise<void>,
  setResourceName: (resource: IPinnedResource, name: string) => Promise<void>,
  getPinnedObject: (objectId: string) => IPinnedResource | undefined,
  openResource: (resource: IPinnedResource, openForEveryone?: boolean) => Promise<void>,
  inCall: boolean,
  width: number,
  searchVersion: number,
  isDisplayed: boolean
  animating: boolean
}> = observer(({
  spaceId,
  channelId,
  windowId,
  recentResources,
  togglePinnedResource,
  deletedResources,
  deleteResource,
  setResourceName,
  getPinnedObject,
  openResource,
  inCall,
  width,
  searchVersion,
  isDisplayed,
  animating
}) => {

  //logger.debug("recentResources: ", toJS(recentResources));
  //logger.debug("deletedResources: ", toJS(deletedResources));

  const [hits, setHits] = useState<Array<Hit<IChatFileHit>>>([]);
  const [q, _setQ] = useState<string>("");
  const searchIndex = useRef<SearchIndex | undefined>(undefined);
  const [recents, setRecents] = useState<IPinnedResource[]>([]);
  const [page, setPage] = useState<number>(0);
  const [atEnd, setAtEnd] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [tabId, setTabId] = useState<string>("all");
  const recentSearchTimer = useRef<ReturnType<typeof setTimeout> | undefined>();

  const setQ = (q: string) => {
    _setQ(q);
    setIsSearching(true);
    debouncedSearch(q, tabId);
  }

  const debouncedSearch = useCallback(
    debounce(500, (q: string, tabId: string) => executeSearch(q, tabId)),
    [searchIndex, recentResources]
  );

  const tabFilter = (tabId: string) => {
    setIsSearching(false);

    if (tabId === "media") {
      return 'fileCategory:media'
    } else if (tabId === "files") {
      return 'fileCategory:file'
    } else if (tabId === 'links') {
      return 'fileCategory:link';
    }
  }

  const debouncedRecentSearch = useCallback(
    debounce(500, (recentResources: Record<string, IPinnedResource>, pollCount?: number) => executeRecentSearch(recentResources, pollCount)),
    [searchIndex, recentResources]
  );

  const executeRecentSearch = async (recentResources: Record<string, IPinnedResource>, pollCount?: number) => {
    logger.debug("executeRecentSearch")
    if (searchIndex.current && Object.keys(recentResources).length > 0) {
      const filters = [
        `channel.channelId:${channelId}`,
        'isResource:true',
      ];
      logger.debug("executing pinnedSearch");
      searchIndex.current.search<Hit<IChatFileHit>>(
        q,
        {
          filters: filters.filter(x => !!x).join(' AND '),
          page: 0,
          hitsPerPage: 100,
        }
      ).then((res) => {
        const allHitObjectIds = res.hits.map(x => x.objectID);
        const myRecents = Object.values(recentResources).filter(x => !allHitObjectIds.includes(x.objectID));
        setRecents(myRecents);

        //logger.debug("recentHits: ", toJS(allHitObjectIds))
        //logger.debug("recentResources: ", toJS(Object.values(recentResources).map(x => x.objectID)))
        logger.debug("recents: ", myRecents.length, " pollCount: ", pollCount);

        if (myRecents.length > 0) {
          if (isDisplayed && (pollCount === undefined || pollCount < 10)) {
            const timerId = setTimeout(
              () => { debouncedRecentSearch(recentResources, (pollCount || 0) + 1); },
              1000
            );
            recentSearchTimer.current = timerId;
          }
        } else {
          // the results of the main search will have changed, because the index
          // has changed, so redo the main search
          if (pollCount && pollCount < 10)
            executeSearch(q, tabId)
        }
      });
    }
  }

  const executeSearch = async (q: string, tabId: string, page: number = 0) => {
    if (isSearching) {
      logger.debug("already searching, ignoring")
      return
    }

    if (searchIndex.current) {
      setIsSearching(true);

      const filters = [
        `channel.channelId:${channelId}`,
        'isResource:true',
        tabFilter(tabId)
      ];

      logger.debug("searchFilter: ", filters);

      searchIndex.current.search<Hit<IChatFileHit>>(
        q,
        {
          filters: filters.filter(x => !!x).join(' AND '),
          page,
          hitsPerPage,
        }
      ).then((res) => {
        if (res.page + 1 >= res.nbPages)
          setAtEnd(true);
        else
          setAtEnd(false);

        const newHits = page > 0 ? [...hits, ...res.hits] : [...res.hits];

        logger.debug("hits: ", newHits);
        setHits(newHits);
        setPage(page);
        setIsSearching(false);
      });
    }
  }

  const handleScroll = (e) => {
    const t = e.target;

    const loadNextPage = t.scrollHeight - t.scrollTop <= t.clientHeight * 2;

    if (loadNextPage) {
      if (!atEnd) {
        logger.debug("load next page pageNum: ", page + 1)
        executeSearch(q, tabId, page + 1);
      }
    }
  }

  const searchKeys = OTUITree.teamManagers[spaceId].searchKeys;

  useEffect(() => {
    const disposer = autorun(
      () => {
        if (searchKeys) {
          const options = {
            responsesCache: createNullCache()
          }
          logger.debug(`searchKeys.appId: ${searchKeys.appId} searchKeys.files: ${searchKeys.files}`);
          searchIndex.current = algoliasearch(searchKeys.appId, searchKeys.files, options).initIndex(`files-pinnedAtDesc-v${searchIndexVersion}`);

          debouncedRecentSearch(recentResources);
          executeSearch(q, tabId);

        } else {
          searchIndex.current = undefined;
        }
      });

    return () => {
      if (recentSearchTimer.current) clearTimeout(recentSearchTimer.current);
      disposer();
    }
  }, [spaceId, searchKeys, searchVersion, recentResources, isDisplayed]);

  const displayedHits = hits.filter(x => !deletedResources.includes(x.objectID));

  let wasLastPinned: boolean | undefined;

  return (
    <DSColumn style={{ marginLeft: 8, height: "100%" }}>
      <DSRow style={{
        alignItems: "center",
        margin: 4,
        height: 40,
        position: 'relative'
      }}>
        <DSH2 style={{}}>Drive</DSH2>
        <DSColumn style={{ flex: 1, alignItems: 'center', position: 'absolute', left: 0, right: 0 }}>
          <DSSearchBox
            value={q}
            handleChange={(event) => setQ(event.target.value)}
            innerStyle={{ flex: 1 }}
            style={{ flexGrow: 1, paddingTop: 5, marginTop: 0, marginLeft: 5, width: 200 }}
            inputStyle={{ width: "100%" }}
            autoFocus={true}
            placeholder={"Type to search"}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                logger.debug("escape pressed")
                e.preventDefault(); // Ensure it is only this code that runs
                if (q) {
                  setQ('');
                }
              }
            }}
            onKeyPress={
              (e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Ensure it is only this code that runs
                  executeSearch(q, tabId);
                }
              }
            }
          />
        </DSColumn>
      </DSRow>
      <DSRow>
        <DSColumn style={{
          flex: 1,
          alignItems: "center"
        }}>



          <div style={{
            backgroundColor: DSTheme.BoxDarkBackground,
            marginBottom: 8,
            padding: 3,
            borderRadius: 16
          }}>
            <DSTabs
              activeTabId={tabId}
              onChange={(tabId: string) => { setTabId(tabId), executeSearch(q, tabId) }}
              style={{}}
              activeButtonStyle={{
                backgroundColor: DSTheme.EmphasisColor
              }}
              buttonStyle={{
                borderRadius: 16,
                padding: "4px 16px",
                margin: "0px 4px",
              }}
              tabStyle={{ borderBottomWidth: 0, color: "rgba(255,255,255,0.5)", fontSize: '12px' }}
              activeTabStyle={{ color: 'white' }}
            >
              <DSTab name="All" tabId="all" />
              <DSTab name="Media" tabId="media" />
              <DSTab name="Files" tabId="files" />
              <DSTab name="Links" tabId="links" />
            </DSTabs>
          </div>
        </DSColumn>
      </DSRow>


      <DSPanelScrollable style={{ flex: 1, paddingRight: 8 }} onScroll={handleScroll} >
        {q.length === 0 && recents.length > 0 && !isSearching && (
          <>
            {recents.map(resource => (
              <SearchResultResource
                key={resource.objectID}
                resource={resource}
                togglePinnedResource={togglePinnedResource}
                deleteResource={deleteResource}
                setResourceName={setResourceName}
                windowId={windowId}
                getPinnedObject={getPinnedObject}
                openResource={openResource}
                inCall={inCall} />
            ))
            }
            {displayedHits.length > 0 && (
              <hr
                style={{
                  height: 0,
                  width: "80%",
                  borderTopWidth: 1,
                  borderBottomWidth: 0,
                  borderColor: "white",
                  borderTopStyle: "solid",
                }}
              />
            )}
          </>
        )}
        {displayedHits.length > 0 ? (
          displayedHits.map((hit: Hit<IChatFileHit>) => {

            const pinnedObject = getPinnedObject(hit.objectID);
            const useSeperator = wasLastPinned !== undefined && wasLastPinned !== !!pinnedObject;
            wasLastPinned = !!pinnedObject;

            return (
              <>
                {useSeperator && (
                  <hr
                    style={{
                      height: 0,
                      width: "80%",
                      borderTopWidth: 1,
                      borderBottomWidth: 0,
                      borderColor: "white",
                      borderTopStyle: "solid",
                    }}
                  />
                )}
                <SearchResult
                  key={hit.objectID}
                  hit={{ ...hit, name: recentResources[hit.objectID]?.name || hit.name }}
                  togglePinnedResource={togglePinnedResource}
                  deleteResource={deleteResource}
                  deletedResources={deletedResources}
                  setResourceName={setResourceName}
                  windowId={windowId}
                  getPinnedObject={getPinnedObject}
                  openResource={openResource}
                  inCall={inCall} />
              </>
            )
          })
        ) : (recents.length === 0 && !animating) ? (
          <DSRow style={{ flex: 1, margin: 10, marginBottom: 30, alignItems: "center" }}>
            <DSColumn style={{ alignItems: "center" }}>
              <DSH4
                wrap style={{ width: "60%", textAlign: "center" }}>
                Any media, links or files shared in the
                room chat will appear here.
              </DSH4>
            </DSColumn>
          </DSRow>
        ) : null}
      </DSPanelScrollable>
    </DSColumn >
  );
})


const SearchResult: React.FC<{
  hit: Hit<IChatFileHit>,
  togglePinnedResource: (resource: IPinnedResource) => Promise<void>,
  deletedResources: string[],
  deleteResource: (resource: IPinnedResource) => Promise<void>,
  setResourceName: (resource: IPinnedResource, name: string) => Promise<void>,
  windowId: string,
  getPinnedObject: (objectId: string) => IPinnedResource | undefined,
  openResource: (resource: IPinnedResource, openForEveryone?: boolean) => Promise<void>,
  inCall: boolean,
}> = ({
  hit,
  getPinnedObject,
  deleteResource,
  togglePinnedResource,
  setResourceName,
  windowId,
  openResource,
  inCall
}) => {

    // remove unnecessary junk.
    const { _highlightResult, users, channel, ..._resource } = hit;
    const resource = _resource as IPinnedResource;

    const [showWindow, setShowWindow] = useState<boolean>(false);
    const pinnedObject = getPinnedObject(resource.objectID);

    return (
      <DSRow style={{
        alignItems: "center",
        margin: 2,
        border: "1px solid rgba(167,167,167,0.1)",
        borderRadius: DSTheme.BaseBorderRadius
      }} >

        <SearchResultFileBase
          title={
            <>
              <DSPrint style={{ overflow: 'hidden', textOverflow: "ellipsis" }}>{fmtResourceName(resource)}</DSPrint>
              {pinnedObject && (
                <FaThumbtack
                  size={10}
                  style={{
                    flexShrink: 0,
                    transition: "all 0.075s ease-in",
                    color: DSTheme.EmphasisColor,
                    paddingLeft: 5,
                    paddingTop: 2,
                  }}
                />
              )}
            </>
          }
          createdAt={hit.users[0].createdAt}
          createdBy={hit.users[0].name}
          hit={resource}
          style={{ flex: 1, margin: 0, border: 0/* , backgroundColor: "red" */ }}
          onClick={(e) => setShowWindow(true)}
        />

        {showWindow && (
          <ResourcePropertiesWindow
            windowId={`resource-properties-${resource.objectID}`}
            parentWindowId={windowId}
            onClose={() => setShowWindow(false)}
            togglePinnedResource={togglePinnedResource}
            deleteResource={deleteResource}
            setResourceName={setResourceName}
            openResource={openResource}
            inCall={inCall}
            isPinned={!!pinnedObject}
            resource={resource}
            createdAt={hit.users[0].createdAt}
            createdBy={hit.users[0].name}
          />
        )}
      </DSRow >
    );
  }

const SearchResultResource: React.FC<{
  resource: IPinnedResource,
  togglePinnedResource: (resource: IPinnedResource) => Promise<void>,
  deleteResource: (resource: IPinnedResource) => Promise<void>,
  setResourceName: (resource: IPinnedResource, name: string) => Promise<void>,
  windowId: string,
  getPinnedObject: (objectId: string) => IPinnedResource | undefined,
  openResource: (resource: IPinnedResource, openForEveryone?: boolean) => Promise<void>,
  inCall: boolean,
}> = ({
  resource,
  deleteResource,
  togglePinnedResource,
  setResourceName,
  windowId,
  getPinnedObject,
  openResource,
  inCall,
}) => {
    const pinnedObject = getPinnedObject(resource.objectID);
    const [showWindow, setShowWindow] = useState<boolean>(false);

    return (
      <DSRow style={{ alignItems: "center", margin: 0 }} >
        <SearchResultFileBase
          title={
            <>
              <DSPrint>{fmtResourceName(resource)}</DSPrint>
              {pinnedObject && (
                <FaThumbtack
                  size={10}
                  style={{
                    flexShrink: 0,
                    transition: "all 0.075s ease-in",
                    color: DSTheme.EmphasisColor,
                    paddingLeft: 5,
                    paddingTop: 2,
                  }}
                />
              )}
            </>
          }
          hit={resource}
          style={{ flex: 1, margin: 0, border: 0/* , backgroundColor: "red" */ }}
          onClick={(e) => setShowWindow(true)}
        />
        {showWindow && (
          <ResourcePropertiesWindow
            windowId={`resource-properties-${resource.objectID}`}
            parentWindowId={windowId}
            onClose={() => setShowWindow(false)}
            togglePinnedResource={togglePinnedResource}
            deleteResource={deleteResource}
            setResourceName={setResourceName}
            openResource={openResource}
            inCall={inCall}
            isPinned={!!pinnedObject}
            resource={resource}
            createdAt={resource.createdAt}
            createdBy={resource.createdBy} />
        )}
      </DSRow >
    );
  }

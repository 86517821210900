import React, { CSSProperties, RefObject, useRef, useState } from "react";

export const Autocomplete: React.FC<{
  getItems: (q: string) => any[],
  renderItem: (item: any) => React.ReactNode,
  onItemSelected: (item: any) => void,
  width: number,
  style?: CSSProperties,
  itemStyle?: CSSProperties,
  activeItemStyle?: CSSProperties,
  noResultsString?: string,
}> = ({ getItems, renderItem, onItemSelected, width, style, itemStyle, activeItemStyle, noResultsString }) => {

  const [activeSuggestion, setActiveSuggestion] = useState<number | undefined>(undefined);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);
  const [_userInput, setUserInput] = useState<string>("");
  const refs = useRef<RefObject<HTMLLIElement>[]>([]);
  const refScroll = useRef<HTMLDivElement>(null);

  const onChange = (e: any) => {
    const userInput = e.currentTarget.value;

    if (userInput.trim().length > 0) {
      const newItems = getItems(userInput.trim());
      refs.current = newItems.map(() => React.createRef<HTMLLIElement>());
      setItems(newItems);
      setShowSuggestions(true);
    } else {
      setItems([]);
      setShowSuggestions(false);
    }
    setActiveSuggestion(undefined);
    setUserInput(userInput);
  };

  const onClick = (e: any) => {
    setActiveSuggestion(undefined);
    setShowSuggestions(false);
  };

  const scrollIntoView = (target: HTMLLIElement) => {
    target.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' })
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setUserInput("");
      setActiveSuggestion(undefined);
      setShowSuggestions(false);

    } else if (e.key === 'Enter') {
      if (activeSuggestion !== undefined) {
        onItemSelected(items[activeSuggestion]);
        setActiveSuggestion(undefined);
        setShowSuggestions(false);
        setUserInput("");
      }

    } else if (e.key === 'ArrowUp') {
      e.preventDefault();

      if (activeSuggestion === 0 || activeSuggestion === undefined) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);

      const ref = refs.current[activeSuggestion - 1];
      if (ref?.current)
        scrollIntoView(ref.current);

    } else if (e.key === 'ArrowDown') {
      e.preventDefault();

      let nextActiveSuggestion = activeSuggestion;
      if (activeSuggestion === undefined) {
        nextActiveSuggestion = 0;
      } else if (activeSuggestion + 1 === items.length) {
        return;
      } else {
        nextActiveSuggestion = activeSuggestion + 1
      }
      setActiveSuggestion(nextActiveSuggestion)

      const ref = refs.current[nextActiveSuggestion];
      if (ref?.current)
        scrollIntoView(ref.current);

    } else if (e.key === "Escape") {
      setShowSuggestions(false);
    }
  };

  const renderItems = () => (
    <ul style={{
      padding: 0,
      margin: 0,
      listStyle: "none",
      backgroundColor: "white",
      color: "black",
    }}>
      {items.length > 0 ? (
        items.map((item, index) => (
          <AutoCompleteItem
            ref={refs.current[index]}
            key={`ac-${index}`}
            item={item}
            isActive={index === activeSuggestion}
            itemStyle={itemStyle}
            activeItemStyle={activeItemStyle}
            onClick={(e) => { onItemSelected(item); onClick(e); }}
            onMouseOver={() => setActiveSuggestion(index)}
            renderItem={renderItem}
          />
        ))
      ) : (
        <li style={{ padding: 10 }}>
          {noResultsString ? noResultsString : "No items found."}
        </li>
      )}
    </ul>
  );

  return (
    <div style={{ width, ...style }}>
      <AddUserInput
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={_userInput}
        inputStyle={!!showSuggestions ? ({ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }) : undefined}
        width={width}
      />
      {
        showSuggestions &&
        <div
          className="dark-scrollbar"
          ref={refScroll}
          style={{
            position: "absolute",
            width: width,
            padding: 0,
            margin: 0,
            zIndex: 100,
            overflowY: "auto",
            maxHeight: 200,
            borderBottomRightRadius: 4,
            borderBottomLeftRadius: 4,
            paddingBottom: 4,
            backgroundColor: "white",
          }}>
          {renderItems()}
        </div>
      }
    </div >
  );
}

const AutoCompleteItem = React.forwardRef<HTMLLIElement, {
  isActive: boolean,
  item: any,
  renderItem: (item: any) => React.ReactNode,
  onClick: (e: any) => void,
  itemStyle?: CSSProperties,
  activeItemStyle?: CSSProperties,
  onMouseOver: React.MouseEventHandler<HTMLLIElement>,
}>(({ isActive, item, renderItem, onClick, itemStyle, activeItemStyle, onMouseOver }, ref) => {

  return (
    <li
      ref={ref}
      style={{
        padding: 10,
        cursor: "pointer",
        ...itemStyle,
        ...(isActive ? activeItemStyle : {}),
      }}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      {renderItem(item)}
    </li>
  );
});


const AddUserInput: React.FC<{
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  onKeyDown: React.KeyboardEventHandler,
  placeholder?: string,
  value: string,
  style?: CSSProperties,
  inputStyle?: CSSProperties,
  width: number,
  padding?: number,
}> = ({ onChange, onKeyDown, placeholder, value, style, inputStyle, width, padding = 10 }) => (
  <div
    className="addUserInput"
    style={{
      margin: 0,
      ...style
    }}
  >
    <input
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder || "Type name to add to room"}
      style={{
        boxSizing: "content-box",
        width: width - 2 * padding,
        backgroundColor: "white",
        color: "black",
        border: "none",
        outline: "none",
        padding,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        ...inputStyle
      }} />
  </div>
);


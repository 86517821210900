import { KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { DataState } from '../../Data/DataState';
import { Logger } from '@openteam/app-util';
import { DSBody, DSH1, DSH3, DSPrint } from '../../DesignSystem/DSText';
import { EditSubTeamWindow } from './EditSubTeamWindow';
import { OTUserInterface } from '@openteam/app-core';
import { DSColumn, DSRow, DS2EditIconButton, DSHSpacer, DS2DeleteIconButton, DS2CreateButton } from '../../DesignSystem';

const logger = new Logger("WorkspaceSettings")

export const WorkspaceTeamsSettings: React.FC<{
  parentWindowId: string,
  spaceId: KSpaceId
}> = observer(({ parentWindowId, spaceId }) => {

  const space = DataState.spaces[spaceId];

  const [showSubTeam, setShowSubTeam] = useState<boolean>(false);
  const [editSubTeam, setEditSubTeam] = useState<string | undefined>(undefined);

  const deleteTeam = async (subTeamId: string) => {

    const doDelete = await OTUserInterface.showConfirm({
      parentId: parentWindowId,
      title: "Delete Team?",
      message: [
        'Are you sure you want to delete this team?',
      ],
      actions: { "Cancel": false, 'Delete': true, },
      height: 240,
    });
    if (doDelete) {
      space.actions?.deleteSubTeam?.(subTeamId!);
    }
  };

  const doEditSubTeam = (subTeamId: undefined | string) => {
    setEditSubTeam(subTeamId);
    setShowSubTeam(true);
  }

  return (
    <DSColumn>
      <DSH3 style={{ marginBottom: 12 }}>
        All teams
      </DSH3>

      {Object.entries(space.subTeams).map(([subTeamId, subTeamName]) => (
        <DSRow key={`subteam-${subTeamId}`} style={{ marginTop: 12, marginBottom: 6, alignItems: 'center' }}>
          <DSPrint style={{ flexGrow: 1 }}>{subTeamName}</DSPrint>
          <DS2EditIconButton onClick={() => { doEditSubTeam(subTeamId) }} />
          <DSHSpacer size={8} />
          <DS2DeleteIconButton onClick={() => deleteTeam(subTeamId)} />
        </DSRow>
      ))}

      {showSubTeam && (
        <EditSubTeamWindow
          spaceId={spaceId}
          subTeamId={editSubTeam}
          onClose={() => {
            setShowSubTeam(false);
          }}
        />
      )}
      <DSRow>
      <DS2CreateButton
        sx={{mt: 1}}
        title={'Create new Team'}
        onClick={() => { doEditSubTeam(undefined) }}
        />
        </DSRow>
    </DSColumn>
  );
});


// console.log(`NUDGE_ENV ${process.env.NUDGE_ENV}`)

export var WEB_URL = 'https://ehlo.space'
export var APP_LOGIN_URL = 'https://auth.ehlo.space/applogin'
export var MEET_URL = 'https://meet.ehlo.space/meet'
export var FEEDBACK_URL = 'https://trello.com/invite/b/zJQ1KRPH/d1fa43b72f4879515ac252e1f3a095d2/openteam-feedback'
export var SENTRY_DSN: string | undefined = "https://5ccf29b566fc4118a8ec2a1835aa3508@o395118.ingest.sentry.io/5889569"
export var IMAGECDN = "https://ik.imagekit.io/openteam"
export var IMAGEFILECDN = "https://ik.imagekit.io/openteam/teamfiles"
export var LINKPROTOCOL = 'ehlo'
//export var LOGGLY_CUSTOMER_TOKEN = "851a5b46-474e-4ce5-843f-f7433162bf8d"
export var MIXPANELID = "9973ef48f2963a0115c7800e30db6635"
export var TEAM_STORAGE_BUCKET_PATH = "openteamfiles"
export var TEAM_STORAGE_BUCKET = `gs://${TEAM_STORAGE_BUCKET_PATH}`
export var INFLUX_APP_LOG_TOKEN: string | undefined = "OMhrNbjUXY7GHXWsKmENXRBqzYqg6t54Ct_C7HtmvgthP17lDKRzVIixO2Gj6-EN-mY4WWKlvAUpDxkvryoakg=="

export var firebaseConfig = {
  apiKey: "AIzaSyBfyupdj9gdDENYXjlszBIM8z6pQrtO0zs",
  authDomain: "auth.openteam.space", //"openteam-12bd3.firebaseapp.com",
  databaseURL: "https://openteam-12bd3.firebaseio.com",
  projectId: "openteam-12bd3",
  storageBucket: "openteam-12bd3.appspot.com",
  messagingSenderId: "588101274675",
  appId: "1:588101274675:web:ba6838d33dcf8d767fa746",
  measurementId: "G-LWJMZM1NQ5"
};

export var googleConfig = {
  clientId:'588101274675-0gm6v1qrknu0vmags9d7j053kinhd1pk.apps.googleusercontent.com',
  apiKey: 'AIzaSyBfyupdj9gdDENYXjlszBIM8z6pQrtO0zs',
}

export const zoomConfig = {
  clientId:  import.meta.env.DEV ? "19tEXHx0RzGgpf5krQWOUA" : "s1qfv7KWSd6xOZ_HvhQgww",
  functionsHost: import.meta.env.DEV ? 'api-dev.ehlo.space' : 'api.ehlo.space'
}

export const RTCConfig = {
  iceServers: [
    { urls: 'stun:stun.l.google.com:19302' },
    { urls: 'stun:turn.openteam.space:443' },
    {
      urls: [
        'turn:turn.openteam.space:443',
        'turn:turn.openteam.space:443?transport=tcp',
        'turns:turn.openteam.space:443?transport=tcp'
      ],
      username: 'openteam',
      credential: 'openteamkey1',
      credentialType: "password"
    },
  ],
  iceCandidatePoolSize: 10,
};

export const mediasoup = {
  forceH264: true,
  forceVP9: false
}

if (import.meta.env.DEV || import.meta.env.VITE_NUDGE_ENV == 'DEV') {

  INFLUX_APP_LOG_TOKEN = undefined
  MEET_URL = 'http://localhost:3001/meet'
  APP_LOGIN_URL = "https://ehlo-dev.firebaseapp.com/applogin"
  IMAGECDN = "https://ik.imagekit.io/openteam/dev"
  IMAGEFILECDN = "https://ik.imagekit.io/openteam/teamfilesdev"
  MIXPANELID = "36059823f138fac9726951cc71b27ea5"
  // WEB_URL = "http://localhost:19006"
  WEB_URL = "https://elho-dev.firebaseapp.com"
  SENTRY_DSN = undefined
  LINKPROTOCOL = 'ehlodev'
//  LOGGLY_CUSTOMER_TOKEN = "0ed8051c-fd34-4bde-94fe-47b1fa69bf9f"
  TEAM_STORAGE_BUCKET_PATH = "openteamfilesdev"
  TEAM_STORAGE_BUCKET = `gs://${TEAM_STORAGE_BUCKET_PATH}`

  firebaseConfig = {
    apiKey: "AIzaSyDhHwtr9TWQwzM8nW18_KG1OAgwqIlgIlM",
    authDomain: "openteam-dev.firebaseapp.com",
    databaseURL: "https://openteam-dev.firebaseio.com",
    projectId: "openteam-dev",
    storageBucket: "openteam-dev.appspot.com",
    messagingSenderId: "1069925529607",
    appId: "1:1069925529607:web:34f9b54b2bba964bcb12a2",
    measurementId: "G-5QCTHK6VFR"
  };

  googleConfig = {
    clientId:'1069925529607-4ouureodva6512bi8fcv9og4sp54ku51.apps.googleusercontent.com',
    apiKey: 'AIzaSyDhHwtr9TWQwzM8nW18_KG1OAgwqIlgIlM'
  }
}

import React, { CSSProperties } from 'react';
import { DSSmallPrint } from './DSText'
import { DSTheme } from './DSTheme';
import { DSPanel } from '.';
import { observer } from 'mobx-react';
import { FaSearch } from 'react-icons/fa';


interface IDSSearchBoxProps {
  handleChange: (event) => void
  value: string
  style?: CSSProperties
  innerStyle?: CSSProperties
  inputStyle?: CSSProperties
  autoFocus?: boolean
  placeholder?: string
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export const DSSearchBox = observer((props: IDSSearchBoxProps) => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'flex-start',
      margin: "8 0 8 0",
      ...props.style
    }}>
      <div style={{
        display: 'flex',
        borderBottom: "1px solid",
        borderColor: DSTheme.MainIcon,
        padding: "6 0",
        ...props.innerStyle
      }}>
        <FaSearch size={14} style={{
          color: DSTheme.MainIcon
        }} />
        <input
          value={props.value}
          onChange={props.handleChange}
          placeholder={props.placeholder || "Type to search"}
          autoFocus={!!props.autoFocus}
          onKeyPress={props.onKeyPress}
          onKeyDown={props.onKeyDown}
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            color: DSTheme.MainText,
            paddingLeft: 6,
            ...props.inputStyle
          }} />
      </div>
    </div>
  )
})

export default DSSearchBox
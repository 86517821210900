import { OTUITree, teamNameConstraints } from "@openteam/app-core";
import { Logger } from "@openteam/app-util"
import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { isMacOs } from "react-device-detect";
import { getRTDB } from "../../Controllers/Fire";
import { showNotification } from "../../Controllers/Notifications";
import { DataState } from "../../Data/DataState";
import { showAddSpace, UIState } from "../../Data/UIState";
import { DS2Button } from "../../DesignSystem/DS2";
import { DSButton } from "../../DesignSystem/DSButton";
import { DSInput } from "../../DesignSystem/DSInput";
import { DSRow } from "../../DesignSystem";
import { DSH3 } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSModalPanel } from "../Modal/ModalLayout";
import { ModalWindow } from "../Modal/ModalWindow";
import { SubWindow, SubWindowCSS } from "../SubWindow";
import validate from "validate.js";
const logger = new Logger("AddSpaceWindow")

type Props = {
}

export const AddSpaceWindow: React.FC<Props> = observer((props) => {
  const [name, setName] = useState<string>('')
  const windowId = "add-space"
  let width = 328
  let height = 220

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const createSpace = useCallback(async (name) => {
    try {
      setIsSubmitted(true);
      await validate.async({ teamName: name }, teamNameConstraints);
      const newSpaceId = await DataState.actions.createTeam(name)
      setName('')
      showAddSpace(false);
    } catch (err) {
      logger.error(`Error creatig space`, err)
      showNotification({
        teamId: OTUITree.userManager.currentTeamId!,
        title: "Error creating Workspace",
        text: `${err}`
      })
    } finally {
      setIsSubmitted(false);
    }
  }, [DataState.actions.createTeam])


  const onClose = useCallback(() => showAddSpace(false), [])

  return (UIState.addSpaceShown) ? (
    <SubWindow
      id={windowId}
      show={true}
      initialSize={{ width: width, height: height }}
      title={'Create Room - Ehlo'}
      minHeight={height}
      minWidth={width}
      onClose={onClose}
      focus={true}
      options={
        {
          alwaysOnTop: false,
          hasShadow: true,
          frame: false,
          transparent: isMacOs ? true : false,
          titleBarStyle: isMacOs ? "hidden" : "default",
          trafficLightPosition: {
            x: 10,
            y: 16
          },
          autoHideMenuBar: true,
          useContentSize: true,
          resizable: true,
          skipTaskbar: false,
          maximizable: true,
          focusable: true,
          fullscreenable: false,
          backgroundColor: undefined,
          webPreferences: {
            backgroundThrottling: false
          }
        }
      }
    >
      <style type="text/css">{SubWindowCSS}</style>
      <DSModalPanel
        windowId={windowId}
        title="Create a new Workspace"
        onClose={onClose}
        topLevel
        footer={
          <DSRow
            style={{
              justifyContent: "flex-end",
            }}
          >
            <DS2Button
              disabled={isSubmitted}
              onClick={() => createSpace(name)} >
              Create Workspace
            </DS2Button>
          </DSRow>
        }

      >
        <DSH3 style={{ marginBottom: 6 }}>Name</DSH3>
        <DSInput
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DSModalPanel>
    </SubWindow>
  ) : null;
})

import { observer } from "mobx-react";
import { OTUITree } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { KSpaceChannelId, KSpaceId, KSpaceUserId } from "@openteam/models";
import React, { useState, useRef, useEffect } from "react";
import { FaCheck, FaClock, FaComment, FaHandRock, FaTimes, FaUser, FaVideo } from "react-icons/fa";
import { DataState } from "../../Data/DataState";
import { openUserChat } from "../../Data/UIState";
import { DSButton, DSCircleIconButton, DSExpandButton } from "../../DesignSystem/DSButton";
import { DSColumn, DSPanelScrollable, DSRow } from "../../DesignSystem";
import { DSBody, DSH3, DSLargePrint, DSPrint } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { timeSince } from "../../Util/DateFormat";
import { PopupWindow } from "../Popup/PopupWindow";
import { SubWindowCSS } from "../SubWindow";
import UserIcon from "../User/UserIcon";
import Select, { components } from "react-select";
import { DSModalPanel } from "../Modal/ModalLayout";
import { Autocomplete } from "../Autocomplete";
import * as Analytics from '../../Util/Analytics'
import { DS2CloseIconButton, DS2DeleteIconButton } from "../../DesignSystem/DS2";
import { ToolTipContainer } from "../../Controllers/ToolTips";

const logger = new Logger("ChatUserList")


interface Props {
  spaceId: KSpaceId;
  parentId?: string;
  channelId: KSpaceChannelId;
  targetRef: React.MutableRefObject<HTMLElement | null>;
  show: boolean;
  canDelete?: boolean;
  onClose: () => void;
}

export const ChatUserList = observer((props: Props) => {
  const { canDelete = true } = props;

  const myUserId = OTUITree.auth.userId;
  const panelId = `chatUserList-${props.spaceId}-${props.channelId}`;
  const space = DataState.spaces[props.spaceId];

  useEffect(() => {
    if (props.show) {
      Analytics.logEvent("Screen_ChatUserList")
    }
  }, [props.show])

  const getUserIds = () => {
    const userIds = space.channels[props.channelId]?.userIds || space.channelDirectory[props.channelId]?.userIds || [];

    if (userIds.includes(myUserId)) {
      return [myUserId, ...userIds.filter(x => x !== myUserId)]
    } else {
      return userIds;
    }
  }

  const userIds = getUserIds();

  const chat = space.channels[props.channelId];

  const queryUnsubscribedUsers = (query: string) =>
    query.trim().length > 0 ?
      Object.values(space.users)
        .filter((item) => item.name.toLowerCase().includes(query))
        .filter(item => !(userIds || []).includes(item.id))
        .sort() : [];

  const renderAddUserItem = (item: any) => (
    <DSRow style={{ alignItems: 'center' }}>
      <UserIcon
        size={24}
        hideStatusDot={true}
        user={{ id: item.id, name: item.label, imageUrl: item.imageUrl }} />
      <DSLargePrint style={{ marginLeft: 8 }}>
        {item.name}
      </DSLargePrint>
    </DSRow>
  )

  const onItemSelected = (item: any) => {
    DataState.spaces[props.spaceId].pods[props.channelId].actions.addUser(props.channelId, item.id)
    Analytics.logEvent("ChatUserListAddUser", { channelId: props.channelId, userId: item.id })
  }

  return (
    <PopupWindow
      id={panelId}
      targetRef={props.targetRef}
      parentId={props.parentId ?? `chat-${props.spaceId}-${props.channelId}`}
      open={props.show}
      offset={5}
      width={320}
      height={400}
      onClose={props.onClose}
      options={{
        transparent: true,
        //hasShadow: true,
      }}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <ToolTipContainer parentId={panelId} />
      <DSColumn
        style={{
          flexGrow: 1,
          height: "100%",
          borderRadius: 10,
          overflow: 'hidden',
          backgroundColor: DSTheme.PanelHeaderColor,

        }}
      >
        <DSRow
          key={'add-user'}
          style={{
            alignItems: 'center',
            marginTop: 4,
            marginBottom: 4,
            width: "100%",
            height: 40,
          }}>
          <DSColumn
            style={{
              paddingLeft: 10,
              flexGrow: 1,
            }}
          >
            <Autocomplete
              width={200}
              getItems={queryUnsubscribedUsers}
              renderItem={renderAddUserItem}
              onItemSelected={onItemSelected}
              style={{ flexGrow: 1, }}
              itemStyle={{
              }}
              activeItemStyle={{
                backgroundColor: "#f1dfc1",
              }}
              noResultsString={"No matches"}
            />
          </DSColumn>
          <DSColumn
            style={{ paddingRight: 10 }}
          >
            <DS2CloseIconButton
              onClick={props.onClose} />
          </DSColumn>

        </DSRow>
        <DSPanelScrollable style={{
          padding: 16,
          flexGrow: 1,
          backgroundColor: DSTheme.PanelBackgroundColor,
        }}>
          {
            userIds?.map((userId, index) => {
              const user = space.users[userId]

              if (!user) {
                return
              }

              return (
                <DSRow
                  key={userId}
                  style={{
                    alignItems: 'center',
                    marginTop: 4,
                    marginBottom: 4,
                    width: "100%",
                  }}>
                  <UserIcon
                    key={user.id}
                    user={user}
                    size={30}
                    style={{
                    }} />
                  <DSPrint style={{
                    flex: 1,
                    marginLeft: 12,
                    marginRight: 8,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: DSTheme.PanelContrastColor
                  }}>
                    {user.id === myUserId ? "Me" : user.name}
                  </DSPrint>
                  {(canDelete && chat) ?
                    <DS2CloseIconButton
                      data-tooltip={`Remove ${user.name} from ${chat.name}`}
                      color='error'
                      analyticsEvent="removePodUser"
                      onClick={() => chat.actions.removeChannelUser(userId)}
                    />
                    : null}
                </DSRow>
              )
            })
          }
        </DSPanelScrollable>
      </DSColumn>

    </PopupWindow>
  )
})


export const ImageOption = (props) => (
  <components.Option {...props}>
    <DSRow style={{ alignItems: "center" }}>
      <UserIcon size={30} user={props.data.user} />
      <DSH3 style={{ marginLeft: 10, fontSize: 14, flex: 1, }}>
        {props.data.user.name}
      </DSH3>
    </DSRow>
  </components.Option>
);

export const ImageMultiValue = (props) => (
  <components.MultiValue {...props}>
    <DSRow style={{ alignItems: "center" }}>
      <UserIcon size={30} user={props.data.user} />
      <DSH3 style={{ marginLeft: 10, fontSize: 14, flex: 1, }}>
        {props.data.user.name}
      </DSH3>
    </DSRow>
  </components.MultiValue>
);

import React, { useCallback, useEffect, useMemo, useRef, useState }  from "react";
import { observer } from "mobx-react";
import { DataState } from "../../../Data/DataState";

import { SubWindow, SubWindowCSS, SubWindowHandle } from "../../SubWindow";
import CallVideo from "../CallVideo";
import { Logger } from "@openteam/app-util";
import { OTGlobals, OTUserInterface } from "@openteam/app-core";
import { v1 as uuidv1 } from "uuid";

const logger = new Logger("ScreenSharePopout");

type Props = {
  userId: string;
  poppedOut: boolean;
  parentId: string
};

type Size = {
  x?: number
  y?:number
  aspectRatio?: number
  width: number
  height: number
}

const DEFAULT_AR = 1.33
const DEFAULT_WIDTH = 1024


export const CallScreenSharePopout: React.FC<Props> = observer(({ userId, poppedOut, parentId }) => {
  const ref = useRef<SubWindowHandle>(null)
  const [focus, setFocus] = useState(false);
  const [soundPlayed, setSoundPlayed] = useState(false);
  const [workArea, setWorkArea] = useState<Electron.Rectangle>();
  const [initialSize, setInitialSize] = useState<{width: number, height: number}>()
  const [titleExtra, setTitleExtra] = React.useState<string>('')
  const id = useRef(uuidv1())
  const participant = DataState.activeCall?.participants[userId];
  const { stream } = participant?.streams["screen"] ?? {};

  useEffect(() => {
    logger.debug(`Creating screenshare popout for ${userId} parent ${parentId}}`)
    async function loadWorkArea() {
      const wa = await window.Main.getWindowWorkArea(parentId);
      logger.debug(`Got workarea for ${parentId}`, wa)
      setWorkArea(wa)
    }
    loadWorkArea()
    return () => logger.debug(`Destroying screenshare popout for ${userId} parent ${parentId}}`);
  }, [])

  const checkTrack = useCallback((stream, forceShow=true) => {
    if (stream) {
      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack) {
        const settings = videoTrack.getSettings();

        if (!focus && workArea && poppedOut && (settings.width || forceShow)) {
          let {aspectRatio, width=workArea.width, height=workArea.height} = settings;

          width && setTitleExtra(` - ${width}x${height}`);

          if (OTGlobals.localUserSettings.screenSharePopoutSize === 'MAXIMISE') {
            setInitialSize({ width, height });
            window.Main.maximiseWindow(`PopoutScreenShare-${userId}`);
          } else {
            let scale = 1;

            if (OTGlobals.localUserSettings.screenSharePopoutSize === 'HALF_SCREEN') {
              if (width && height) {
                scale = Math.max(2*width/workArea.width, 2*height/workArea.height);
              } else {
                width = workArea.width /2;
                height = workArea.height /2;
              }
            }
            width = Math.floor(width / scale);
            height = Math.floor(height / scale);
            const x = workArea.x + (workArea.width - width) / 2;
            const y = workArea.y + (workArea.height - height) / 2;
            setInitialSize({ width, height });
          }
          //logger.debug(`Showing popout, workarea`, workArea)
          setFocus(true);
        }
      } else {
        logger.debug(`No screenshare track...`)
      }
    } else {
      logger.debug(`No stream...`)
    }
  }, [setTitleExtra, setFocus, workArea, focus, poppedOut])

  useEffect(() => {
    if (!soundPlayed && (!poppedOut || (poppedOut && focus)) ) {
      OTUserInterface.soundEffects.screenShareStart();
      setSoundPlayed(true);
    } else if (poppedOut && !focus) {
      logger.debug(`Popped out, checking track`)
      checkTrack(stream, true)
    }
  }, [poppedOut, focus, soundPlayed, stream, setSoundPlayed, checkTrack])

  useEffect(() => {
    const interval = setInterval(() => checkTrack(stream), 1000);
    return () => clearInterval(interval);
  }, [stream, checkTrack])

  useEffect(() => {
    logger.debug(`stream: ${stream}, initialSize: ${initialSize}`)
  }, [stream, initialSize])

  return stream && initialSize ? (
    <SubWindow
      ref={ref}
      id={`PopoutScreenShare-${userId}`}
      title={`${participant!.name} - Screen share${titleExtra}`}
      initialSize={initialSize}
      show={poppedOut && focus}
      focus={focus}
      preservePosition={true}
      onCreated={() => checkTrack(stream, false)}
      onClose={() => {
        DataState.activeCall?.actions.popoutScreenShare(userId, false)
        setFocus(false)
      }}
       options={{
         frame: true,
         transparent: false,
         autoHideMenuBar: true,
         useContentSize: true,
         resizable: true,
         alwaysOnTop: false,
         skipTaskbar: false,
         focusable: true,
         maximizable: true,
         fullscreenable: false,
         hasShadow: true,
         closable: true,
         show: false,
       }}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <CallVideo track={stream.getVideoTracks()[0]} style={{objectFit: "contain"}}/>
    </SubWindow>
  ) : null;
});

import { IUISpaceUser, IUserMeetingStatus, TUserStatus } from "@openteam/models";
import { timeSince } from "../../Util/DateFormat";
import { format } from 'date-fns';
import { ZoomIcon } from "../Zoom";
import React from "react";
import { Logger } from "@openteam/app-util";
import { toJS } from "mobx";

const logger = new Logger('StatusUtil')

export const meetingEmoji = '📅';
const hasCustomStatusEmoji = '💬';
const customStatusEmojis = {
  'DND': "⛔"
}
export interface IStatusMin extends Partial<IUISpaceUser> { };


export const systemStatuses: IStatusMin[] = [
  { status: { online: true } },
  { status: { online: true, customStatus: 'AWAY' } },
  { status: { online: true, customStatus: 'DND' } }
]


export const customStatuses: IStatusMin[] = [
  { status: { online: true, customStatusEmoji: '📅', customStatusText: 'In a meeting' } },
  { status: { online: true, customStatusEmoji: '🤒', customStatusText: 'Out sick' } },
  { status: { online: true, customStatusEmoji: '🌴', customStatusText: 'Out of office' } },
]

export const isInMeeting = (status: IUserMeetingStatus | null | undefined) => {
  if (!status)
    return false;

  const now = Math.floor(Date.now() / 1000);

  if (status.start < now && now < status.end)
    return true;

  return false;
}

export const getUserStatus = (user: IStatusMin) => {

  const userStatus = user.status?.customStatus || user.status?.status
  let statusText = user.status?.online ?
    (
      user.status?.inCall ?
        "In a call"
        : getZoomStatus(user) ?
          "In a Zoom meeting"
          : userStatus == 'AWAY' ?
            'Away'
            : userStatus == 'DND' ?
              'Do not disturb'
              : "Online"
    )
    : user.lastOnline ?
      `Last seen ${timeSince(new Date(user.lastOnline!), ' ago')}`
      : `Never seen online`

  return statusText
}

export const getCombinedStatus = getUserStatus

export const getUserCustomStatusEmoji = (user: IStatusMin) => {
  const { customStatus, customStatusEmoji, customStatusText, meetingStatus } = user?.status ?? {};

  //logger.debug(`Getting custom status for ${user.email}`, toJS(user))
  //const zoomStatus = getZoomStatus(user);

  if (customStatusEmoji) {
    return customStatusEmoji;

  } else if (customStatusText) {
    return hasCustomStatusEmoji;

    //} else if (zoomStatus) {
    //  return zoomStatus.icon;
  } else if (isInMeeting(meetingStatus)) {
    return meetingEmoji;

  } else if (customStatus) {
    return customStatusEmojis[customStatus];
  }
}

function isInZoomMeeting (user: IStatusMin) {

}
export const getUserCustomStatusText = (user: IStatusMin) => {
  const { customStatusText, meetingStatus } = user?.status ?? {};

  if (customStatusText) {
    return customStatusText;
  } else {
    const inMeeting = isInMeeting(meetingStatus);
    //const zoomStatus = getZoomStatus(user);
    //if (zoomStatus) {
    //  return 'In a Zoom meeting'
    //}
    if (inMeeting) {
      let s = 'In a meeting';

      if (meetingStatus?.summary)
        s += ` '${meetingStatus?.summary}'`;

      return s;

    } else {
      return undefined;
    }
  }
}

export const getUserMeetingStatusText = (user: IUISpaceUser) => {
  if (user.status?.meetingStatus) {
    const { meetingStatus } = user.status;

    if (isInMeeting(meetingStatus)) {
      const end = new Date(meetingStatus.end * 1000);
      return `In ${meetingStatus?.summary ? "'" + meetingStatus?.summary + "'" : 'a meeting'} until ${format(end, 'HH:mm')}`
    } else {
      const start = new Date(meetingStatus.start * 1000);
      return `Free until ${format(start, 'HH:mm')}`
    }
  }
}


export function getZoomStatus (user: IUISpaceUser | IStatusMin) {
  const { presence, meeting } = user.status?.zoomStatus ?? {};

  //logger.debug(`${user.name} zoomStatus `, toJS(user.status?.zoomStatus))

  if (meeting || presence === 'In_Meeting') {
    return { icon: <ZoomIcon />, status: 'In a zoom meeting' }
  }
  return undefined
}

import React, { Component, useEffect } from "react";
import { setDockComponentLength, UIState } from "../../Data/UIState";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";


interface IDockSeparatorProps extends React.HTMLAttributes<HTMLSpanElement> {

}

export const DockSeparator = observer((props: IDockSeparatorProps) => {
  const uuid = React.useRef<string>(uuidv4())

  useEffect(() => {
    setDockComponentLength(`DockSeparator-${uuid.current}`, 1 / 60 + (1 / 10) * 2);
    return () => setDockComponentLength(`DockSeparator-${uuid.current}`, 0);
  }, []);

  const separatorSize = Math.max(1, Math.floor(UIState.dockSize / 60));
  const separatorMargin = Math.floor(UIState.dockSize / 10);

  const separatorLength = Math.floor(UIState.dockSize * 2 / 3)

  const { style, ...otherProps } = props
  return (
    <div
      style={{
        width: UIState.dockHorizontal ? separatorSize : separatorLength,
        height: UIState.dockHorizontal ? separatorLength : separatorSize,
        minWidth: separatorSize,
        minHeight: separatorSize,
        borderRadius: 2,
        backgroundColor: DSTheme.DockSeparatorColor,
        opacity: 0.25,
        margin: separatorMargin
      }}
    />
  );
})


import { Logger } from "@openteam/app-util";
import React, { CSSProperties, useCallback, useRef, useState } from "react";
import { FaChevronDown, FaRegSmile } from "react-icons/fa";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { DS2Button, DS2IconActionButton } from "../../DesignSystem/DS2";
import DSButtonGroup from "../../DesignSystem/DS2/DSButtonGroup";
import { DSButton, DSLongButton } from "../../DesignSystem/DSButton";
import { DSPanel } from "../../DesignSystem";
import { DSText } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { EmojiPickerWindow } from "../Chat/ChatEmojiPickerWindow";

const logger = new Logger('EmojiButton')

interface Props {
  parentId: string;
  addEmoji: (emoji: string) => void;
  value?: string;
  size?: number;
  outerStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  onShowPicker?: (showing: boolean) => void
}

export const EmojiButton: React.FC<Props> = (props) => {
  const ref = useRef(null)
  const [showPicker, setShowPicker] = useState(false);

  const addEmoji = (emoji: string) => {
    props.addEmoji?.(emoji);
    setShowEmojiPicker(false);
  }

  const setShowEmojiPicker = (show: boolean) => {
    logger.debug(`setShowEmojiPicker ${show}`)
    if (show) {
      props.onShowPicker?.(show)
    } else {
      setTimeout(() => props.onShowPicker?.(show), 50)
    }
    setShowPicker(show);
  }

  logger.debug(`value ${props.value}`)

  return (
    <>
      <DSPanel
        ref={ref}
        style={props.outerStyle}
      >
        <DS2Button
          color="secondary"
          noBadge
          startIcon={props.value ?? <MdOutlineEmojiEmotions />}
          endIcon={<FaChevronDown size={12} />}
          style={{ minWidth: 56, padding: 4, fontSize: 16, ...props.buttonStyle, fontWeight: 'unset' }}
          onClick={() => setShowEmojiPicker(!showPicker)}
        />
      </DSPanel>
      <EmojiPickerWindow
        parentId={props.parentId}
        targetRef={ref}
        show={showPicker}
        addEmoji={addEmoji}
        onClose={() => { setShowEmojiPicker(false) }}
      />
    </>

  );
};

import React, { CSSProperties, ReactNode } from "react";
import { isMacOs } from "react-device-detect";
import { FaTimes } from "react-icons/fa";
import { UIState } from "../../Data/UIState";
import { DS2CloseIconButton } from "../../DesignSystem/DS2";
import { DSButton, DSCircleIconButton } from "../../DesignSystem/DSButton";
import { DSRow, DSColumn, DSPanelScrollable } from "../../DesignSystem";
import { DSH2, DSH3 } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { Draggable } from "../Draggable";
import { WindowControls } from "../WindowControls";


interface ModalPanelProps {
  windowId: string
  title: string | JSX.Element
  topLevel?: boolean
  onClose?: () => void
  style?: CSSProperties
  panelStyle?: CSSProperties
  footer?: JSX.Element
}

export const DSModalPanel: React.FC<ModalPanelProps> = ({ children, footer, topLevel = false, ...props }) => {
  return (
    <DSColumn style={{
      flexGrow: 1,
      height: "100%",
      //borderRadius: "10px",
      backgroundColor: DSTheme.PanelBackgroundColor,
      color: DSTheme.MainText,
      overflow: 'hidden',
      justifyContent: 'space-between',
      padding: 16,
      ...props.style
    }}>
      <Draggable windowId={props.windowId}>
        {topLevel ?
          <TopLevelHeader title={props.title} windowId={props.windowId} />
          :
          <DSModalHeader title={props.title} onClose={props.onClose} />
        }
      </Draggable>
      <DSPanelScrollable style={{ paddingTop: 16, paddingBottom: 16, flex: 1, ...props.panelStyle }}>
        {children}
      </DSPanelScrollable>
      {footer}
    </DSColumn>
  )
}

interface ModalHeaderProps {
  title: string | JSX.Element
  onClose?: () => void
}


const DSModalHeader = (props: ModalHeaderProps) => {
  return (
    <DSRow
      style={{
        minHeight: 42,
        paddingBottom: 4,
      }}>
      {
        (typeof props.title === 'string' || props.title instanceof String) ?
          <DSH2 style={{ color: DSTheme.MainText, flex: 1 }}>
            {props.title}
          </DSH2>
          :
          props.title
      }

      {
        props.onClose ?
          <DSColumn style={{ height: "100%", marginTop: -4 }}>
            <DS2CloseIconButton onClick={props.onClose} />
          </DSColumn>
          :
          null
      }
    </DSRow>

  )
}
interface TopLevelHeaderProps {
  title: string | JSX.Element
  windowId: string
}

const TopLevelHeader = (props: TopLevelHeaderProps) => {
  return (
    <DSRow
      style={{
        minHeight: 42,
        paddingBottom: 4,
        paddingLeft: isMacOs ? 64 : 0,
      }}>
      {
        (typeof props.title === 'string' || props.title instanceof String) ?
          <DSH2 style={{ color: DSTheme.MainText, flex: 1 }}>
            {props.title}
          </DSH2>
          :
          props.title
      }
      {!isMacOs ?
        <WindowControls parentId={props.windowId} outerStyle={{ marginTop: -14 }} hideMaximise hideMinimise/> : null
      }
    </DSRow>
  )
}


import { Logger } from '@openteam/app-util';
import { observer } from 'mobx-react';
import React from 'react';
import { DSPanel, DSRow, DSVSpacer } from '../../DesignSystem';
import { DSH2} from '../../DesignSystem/DSText';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { openSystemPreferences } from '../../Util/OpenSystemPreferences';
import { DS2Button } from '../../DesignSystem';

const PermissionsPanel: React.FC<{ mediaType: 'Camera' | 'Microphone' }> = (props) => {
  return (
    <DSPanel
      style={{
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        background: DSTheme.BackgroundColorShade,
        width: '100%',
        height: '100%'
      }}
    >
      <DSH2 wrap color={DSTheme.DangerColor} style={{ textAlign: 'center' }}>
        Ehlo does not have permission to access your {props.mediaType}.
      </DSH2>
      <DSVSpacer size={10} />
      {/*<DSH3 wrap color={DSTheme.DangerColor} style={{ textAlign: 'center' }}>
      Please grant permission in System Preferences
  </DSH3>*/}
      <DSVSpacer size={20} />
      <DS2Button
        onClick={() => openSystemPreferences('security', `Privacy_${props.mediaType}`)}
      >
        Open {props.mediaType} Permissions
      </DS2Button>
    </DSPanel>
  )
}

export default observer(PermissionsPanel)

import { Logger } from "@openteam/app-util";
import { observer } from "mobx-react";
import React from "react";
import { DSSpinner } from "../../DesignSystem/DSSpinner";
import { DSPanel, DSRow } from "../../DesignSystem";
import { DSH1, DSH2 } from "../../DesignSystem/DSText";
import { OnboardingHeader } from "./OnboardingHeader";

const logger = new Logger("LoadingContent")

type LoadingContentProps = {
  message: string
};

export const LoadingContent = observer((props: React.PropsWithChildren<LoadingContentProps>) => {

  return (
    <OnboardingHeader>
      <DSRow style={{ alignItems: 'center' }}>
        <DSPanel style={{ flex: 1, padding: 30, paddingLeft: 16, height: "100%", alignItems: 'center' }}>

          <DSH1 wrap style={{
            fontSize: 30,
            textAlign: "center", width: 360, marginBottom: 40
          }}>
            Please wait while we get ehlo ready for you
          </DSH1>

          <DSSpinner size={100} />
          <DSH2 wrap style={{ textAlign: "center", paddingTop: 12 }}>
            {props.message}
          </DSH2>
        </DSPanel>
      </DSRow>
    </OnboardingHeader>
  );
});

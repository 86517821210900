import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { DataState } from "../../Data/DataState";
import { Logger } from "@openteam/app-util";
import { IUIWebcamStream } from "@openteam/models";
import { TrackFaceDetection } from "../../Controllers/FaceDetection";
import { ErrorBoundary } from "@sentry/react";
import { CallScreenSharePopout } from "./ScreenShare";
import { CallPluginPopout } from "./Plugins/PluginPopout";
import { ScreenShareWarning } from "./ScreenShare/ScreenShareWarning";
import CallAudio from "./CallAudio";
import CallWindow from "./CallWindow";

const logger = new Logger(`CallWindow`)

const Call: React.FC = () => {
  const fdStartupTimer = useRef<ReturnType<typeof setTimeout>>()
  const [allowFD, setAllowFD] = useState(false)

  const call = DataState.activeCall;

  const myWebcamStream = call?.participants[call?.myUserId]?.streams["camera"];

  const onFaceDetect = React.useCallback(
    (faceDetect?) => {
      call?.actions.onFaceDetect(faceDetect);
    },
    [call?.actions]
  );

  useEffect(() => {
    if (myWebcamStream?.hasVideo) {
      fdStartupTimer.current = setTimeout(() => setAllowFD(true), 10000);
      return () => {
        if (fdStartupTimer.current) {
          clearTimeout(fdStartupTimer.current);
        }
        setAllowFD(false);
      };
    }
  }, [myWebcamStream?.hasVideo]);

  useEffect(() => {
    if (allowFD && myWebcamStream && myWebcamStream.hasVideo && call?.faceTrackingEnabled) {
      const track = myWebcamStream.stream.getVideoTracks()[0];
      if (track) {
        TrackFaceDetection.start(myWebcamStream.stream.getVideoTracks()[0]);
        TrackFaceDetection.on("detection", onFaceDetect);
        return () => {
          TrackFaceDetection.stop();
          TrackFaceDetection.off("detection", onFaceDetect);
        };
      }
    } else if (allowFD) {
      onFaceDetect();
    }
  }, [myWebcamStream?.hasVideo, call?.faceTrackingEnabled, allowFD, onFaceDetect]);

  useEffect(() => {
    logger.debug(`rendering call ${call?.windowId}`);
  });

  //if (call && !showWidget && !chatIsOpen(call.spaceId, call.channelId || "adhoc-call")) {
  //  logger.debug(`Opening chat for ${call.spaceId}, ${call.channelId || "adhoc-call"}`);
  //  try {
  //    openPod(call.spaceId, call.channelId || "adhoc-call");
  //  } catch (err) {
  //    logger.warn(`Failed to open chat ${err}`)
  //  }
  //}

  return call ? (
    <ErrorBoundary>

      <CallWindow />

      {Object.entries(call.screenShares).map(([userId, poppedOut]) => {
        logger.debug(`Rendering screenshare popout for ${userId}, poppedOut ${poppedOut}`)
        return <CallScreenSharePopout
          key={`screenshare-${userId}`}
          userId={userId}
          poppedOut={poppedOut}
          parentId={call!.windowId}
        />
      })}

      {Object.entries(call.pluginPopouts).map(([pluginId, poppedOut]) => (
        <CallPluginPopout
          key={`plugin-${pluginId}`}
          pluginId={pluginId}
          poppedOut={poppedOut}
          parentId={call!.windowId}
        />
      ))}

      {call.participantList.map((userId) => {
        const callUser = call?.participants[userId];
        const webcamStream = callUser?.streams["camera"] as IUIWebcamStream;
        const muted =
          callUser !== undefined &&
          (callUser.isSoundDisabled || callUser.isSameRoom || webcamStream?.muted);

        return callUser && webcamStream && !call?.isCallPaused ? (
          <CallAudio
            key={`${userId}-audio`}
            stream={webcamStream.stream}
            muted={muted}
            volume={callUser.volumeFactor}
          />
        ) : null;
      })}
      {call.sharingScreen && <ScreenShareWarning parentId={call!.windowId} />}
    </ErrorBoundary>
  ) : null;
}

export default observer(Call)

import { OTGlobals } from "@openteam/app-core"
import { KSpaceId, KSpaceChannelId, KSpaceUserId, IUCallParticipant } from "@openteam/models"
import { observer } from "mobx-react-lite"
import React from "react"
import { DataState } from "../../../Data/DataState"
import { UIState } from "../../../Data/UIState"
import { DSRow, DSColumn } from "../../../DesignSystem"
import { DSH3 } from "../../../DesignSystem/DSText"
import { DSTheme } from '../../../DesignSystem/DSTheme'
import { getStringBackground } from '../../../Util/StringColor'
import UserIconListPaged from '../../../Util/UserIconListPaged'
import PanelButton from "../../PanelButton"
import RoomCallButton from "../../Room/RoomCallButton"
import { RoomIcon } from "../../Room/RoomIcon"
import { SpacePanelCallIcon } from '../../Space/SpacePanelIcons'
import UserIcon from "../../User/UserIcon"
import { DockUserPanel } from "../DockUserPanel"

interface Props {
  spaceId: KSpaceId
  callId: KSpaceChannelId
  windowId: string
  onUserClick?: (userId: KSpaceUserId) => void
  setDisableOnBlur: (val: boolean) => void
  closeWindow: () => void
}

const DockOverflowOverviewCall: React.FC<Props> = ({ spaceId, callId, ...props }) => {
  const space = DataState.spaces[spaceId]
  const call = space.calls[callId]
  const pod = call.channelId ? space.pods[call.channelId] : undefined;
  const room = call.channelId ? space.channelDirectory[call.channelId] : undefined;

  const name = call.name ?? pod?.name ?? room?.name ?? '';
  const background = name ? getStringBackground(name) : DSTheme.DockCallBackgroundColor
  const participantList = Object.values(call?.participants || {});


  return (
    <DSColumn
      spacing={8}
      style={{
        margin: 4,
        padding: '6px 15px',
        borderRadius: 8,
        background,
        color: DSTheme.ColorContrastColor,
      }}>
      <DSRow
        spacing={8}
        style={{
          alignItems: "center",
          justifyContent: 'space-between'
        }}>

        <DSRow spacing={8}>
          <DSColumn style={{ justifyContent: 'center' }}>
            {
              pod ?
                <RoomIcon
                  spaceId={spaceId}
                  podId={pod?.id}
                  name={pod?.name}
                  size={20}
                  showBorder={true}
                /> :
                <SpacePanelCallIcon size={12} />
            }
          </DSColumn>
          <DSH3 maxLines={1} data-tooltip={call.name}>{call.name}</DSH3>
        </DSRow>
        {
          pod ?
            <DSRow spacing={4}>
              <RoomCallButton
                spaceId={spaceId}
                podId={pod.id}
                callId={callId}
                buttonStyle='action'
                analyticsPrefix='RoomOverflow'
              />
            </DSRow>
            : null
        }

      </DSRow>

      <DSRow
        style={{
          alignItems: "center",
          justifyContent: 'space-between'
        }}
      >
        <UserIconListPaged
          spaceId={spaceId}
          users={participantList}
          showName={false}
          numRows={1}
          numPerRow={4}
          tileSize={38}
          horizontalSpacing={2}
          data-tooltip-for='overflow-user'
          renderUser={(user) => (
            <PanelButton
              key={user.id}
              id={user.id}
              parentId={props.windowId}
              onShowPanel={props.setDisableOnBlur}
              position={UIState.dockPosition.onLeft ? 'right' : 'left'}
              disabled={user.id === OTGlobals.auth.userId}
              renderContents={() => (
                <DockUserPanel
                  spaceId={spaceId}
                  userId={user.id}
                  closeWindow={props.closeWindow}
                  isDockPanel={false}
                />
              )}
            >
              <UserIcon
                showTooltip
                data-tooltip={`${spaceId}:${user.id}`}
                data-tooltip-for='overflow-user'
                key={user.id}
                user={user}
                size={38}
                showCustomEmoji={user.status?.online === true}
                outerStyle={{ margin: 4 }}
              />
            </PanelButton>
          )}
        />
      </DSRow>
    </DSColumn>
  )
}

export default observer(DockOverflowOverviewCall);

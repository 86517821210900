import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Logger } from "@openteam/app-util";
import { DSH5 } from '../../DesignSystem/DSText'
import { DataState } from '../../Data/DataState';
import { KCallId, KSpaceId } from '@openteam/models';
import { DSTheme } from '../../DesignSystem/DSTheme';

import UserIcon from '../User/UserIcon';
import { OTUITree } from '@openteam/app-core';
import { openCallWindow, openPod, UIState } from '../../Data/UIState';
import { observer } from 'mobx-react';
import { DSAnimateListGrow } from '../../DesignSystem/DSAnimate';
import { autorun, runInAction, toJS } from 'mobx';
import { DSColumn, DSPanel, DSRow, DSVSpacer } from '../../DesignSystem';
import { playKnock } from '../../Controllers/SoundEffects'
import { getDummyCall, isDummyCall } from '../../Data/tutorialDummy';
import { DockUserIcon } from './DockUserIcon';
import { DockMyUserIcon } from './DockCurrentUserIcon';
const logger = new Logger("CallDockComponent")

interface ICallDockComponentProps {
  spaceId: KSpaceId
  callId: KCallId
  tileSize: number
  onSelfClick?: () => void
}


const verticalMargin = 5;

export const CallDockComponent = observer((props: ICallDockComponentProps) => {

  const isTutorial = isDummyCall(props.callId)

  const call = isTutorial ? getDummyCall(props.callId) : DataState.spaces[props.spaceId].calls[props.callId];

  const participants = call?.participants || {}

  const space = DataState.spaces[props.spaceId];

  const myUserId = OTUITree.auth.userId;
  const [currentBigUsers, setCurrentBigUsers] = useState<string[]>([]);
  const [currentSmallUsers, setCurrentSmallUsers] = useState<string[]>([]);

  const isOwner = call?.ownerId == OTUITree.auth.userId

  const inCall = myUserId in participants

  const incomingRequests = Object.keys(call?.requests || {}).filter(userId => userId != OTUITree.auth.userId
    && call?.requests?.[userId].active && call?.requests?.[userId].receiverStatus == 'waiting')

  const hasIncomingRequests = inCall && isOwner && incomingRequests.length > 0

  logger.debug("call", toJS(call), "isOwner", isOwner, "incomingRequests", incomingRequests)

  useEffect(() => autorun(() => {

    const { bigUsers, smallUsers } =
      filterParticipants(props.spaceId, participants, isTutorial);

    setCurrentBigUsers((_prev) => {
      if (_prev.join(':') !== bigUsers.join(':')) {
        return bigUsers
      }
      return _prev
    })

    setCurrentSmallUsers((_prev) => {
      if (_prev.join(':') !== smallUsers.join(':')) {
        return smallUsers;
      }
      return _prev;
    });

  }), [call?.participants, call?.participants.incoming, call?.requests, call?.actions.joinCall])

  logger.debug("inCall: ", inCall, "props.callId", props.callId, "currentBigUsers", currentBigUsers);
  const pod = call?.channelId ? DataState.spaces[props.spaceId].pods[call.channelId] : undefined


  const renderUser = useCallback((userId, isHovering) => {

    if (userId == OTUITree.auth.userId) {
      return <DockMyUserIcon
        key={userId}
        spaceId={props.spaceId}
        size={Math.floor(UIState.dockSize * DSTheme.DockCallUserRatio)}
        />
    } else if (space.users[userId]) {
      return <DockUserIcon
        key={userId}
        spaceId={props.spaceId}
        userId={userId}
        size={Math.floor(UIState.dockSize * DSTheme.DockCallUserRatio)}
      />

    } else {
      return <UserIcon
        user={participants[userId]}
      />
    }

  }, [props.spaceId, props.tileSize])


  const bigUsersOuterStyle = useMemo(
    () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
    []
  )

  return (
    <>
      {
        currentBigUsers.length > 0 ?
          <DSAnimateListGrow
            horizontal={UIState.dockHorizontal}
            // width={UIState.dockSize}
            // height={props.tileSize}

            width={UIState.dockHorizontal ? props.tileSize : UIState.dockSize}
            height={UIState.dockHorizontal ? UIState.dockSize : props.tileSize}
            spacing={(props.tileSize * (1 - DSTheme.DockMargin) - props.tileSize * DSTheme.DockUserRatio) / 2}
            renderItem={(userId: string) => renderUser(userId, false)}
            items={currentBigUsers}
            itemStyle={{
              position: 'relative',
            }}
            outerStyle={bigUsersOuterStyle as any}
          />
          :
          null
      }
      {currentSmallUsers.length > 0 ?
        <DSRow style={{
          height: props.tileSize / 2,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div style={{
            borderRadius: "50%",
            width: Math.floor(UIState.dockSize * DSTheme.DockOtherUsersRatio),
            height: Math.floor(UIState.dockSize * DSTheme.DockOtherUsersRatio),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: DSTheme.UserBlankColor,
            zIndex: 1,
          }}>
            <DSH5 style={{}}>+{currentSmallUsers.length}</DSH5>
          </div>
        </DSRow>
        :
        null}
    </>
  );
})

export const getCallDockComponentLength = (spaceId: KSpaceId, callId: KCallId, tileSize: number) => {
  const myUserId = OTUITree.auth.userId;

  const isTutorial = isDummyCall(callId)

  const call = isTutorial ? getDummyCall(callId) : DataState.spaces[spaceId].calls[callId];

  if (!call) {
    return 0;
  }
  const pod = call?.channelId ? DataState.spaces[spaceId].pods[call.channelId] : undefined;
  const participants = call.participants || {};
  const inCall = myUserId in participants;

  const { bigUsers, smallUsers } = filterParticipants(spaceId, participants, isTutorial);

  const isOwner = call?.ownerId == OTUITree.auth.userId
  const incomingRequests = Object.keys(call?.requests || {}).filter(userId => userId != OTUITree.auth.userId
    && call?.requests?.[userId].active && call?.requests?.[userId].receiverStatus == 'waiting')

  return calcCallDockComponentHeight(
    tileSize,
    smallUsers.length,
    bigUsers.length,
  )
};

const calcCallDockComponentHeight = (
  tileSize: number,
  numSmallUsers: number,
  numBigUsers: number,
) => {
  let height = 0;

  if (numBigUsers > 0)
    height += numBigUsers * tileSize;

  if (numSmallUsers > 0) {
    height += tileSize / 2;
  }



  return height;
}


export function filterParticipants(spaceId, participants, isTutorial = false) {
  const bigUsers: string[] = [];
  const smallUsers: string[] = [];
  const myUserId = OTUITree.auth.userId;

  if (myUserId in participants) {
    bigUsers.push(myUserId);
  }

  Object.keys(participants).forEach((userId) => {
    if (userId === myUserId) {
      // this is done outside the loop to make sure myUser is first
    } else if (userIsInteresting(spaceId, userId) || isTutorial) {
      bigUsers.push(userId);
    } else {
      smallUsers.push(userId);
    }
  });

  if (bigUsers.length == 0 && smallUsers.length > 0) {
    const upgradeUser = smallUsers.shift()
    if (upgradeUser) {
      bigUsers.push(upgradeUser)
    }
  }
  return { bigUsers, smallUsers };
}

function userIsInteresting(spaceId, userId) {
  const space = DataState.spaces[spaceId];
  const user = space.users[userId];
  let interesting: boolean = false;

  if (user) {
    const openChat = UIState.openChats[spaceId] && space.userChats[userId] &&
      space.userChats[userId].channelId in UIState.openChats[spaceId].channels;

    const hasAlert =
      user.alerts?.knocked ||
        user.alerts?.called ||
        user.incoming ||
        user.outgoing ||
        space.userChats[userId]?.numUnread
        ? true
        : false;

    const userPinned = user.pinned ? true : false;
    const userRecent = !!UIState.dockRecentUsers[spaceId][userId];

    interesting = openChat || hasAlert || userPinned || userRecent;
  }
  return interesting;
}

import { FireDb, OTUITree } from '@openteam/app-core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { DSBody, DSH3, DSPrint, DSSmallPrint } from '../../DesignSystem/DSText';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DSColumn, DSRow } from '../../DesignSystem';
import { EmailChipInput } from './EmailChipInput';
import { DSModalPanel } from '../Modal/ModalLayout';
import * as Analytics from '../../Util/Analytics'
import { FaCopy, FaLink, FaRegCopy } from 'react-icons/fa';
import { DSSpinner } from '../../DesignSystem/DSSpinner';
import { DS2Button, DS2Icons } from '../../DesignSystem/DS2';


interface IInviteUsersProps {
  windowId: string
  spaceId: string
  topLevel?: boolean
  onClose: () => void
}

export const InviteUsers = observer((props: IInviteUsersProps) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [loadingLink, setLoadingLink] = useState<boolean>(false);
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  useEffect(() => {
    Analytics.logEvent("Screen_InviteUsers")
  }, [])

  const sendInviteEmails = () => {
    if (emails.length > 0) {
      emails.forEach(email => {
        FireDb.inviteUser(props.spaceId, email)
      })
    }

    props.onClose()
  }

  const getInviteLink = async () => {
    setLinkCopied(false)
    setLoadingLink(true);
    const url = await FireDb.getInviteLink(props.spaceId);
    window.Main.clipboardWriteText(url)
    setLoadingLink(false)
    setLinkCopied(true)
  }

  return (
    <DSModalPanel
      windowId={props.windowId}
      title="Invite Teammates" onClose={props.onClose}
      panelStyle={{ flex: 1 }}
      topLevel={props.topLevel}
      footer={
        <DSRow style={{ alignItems: 'center', paddingBottom: 6 }}>
          <DSColumn style={{ flex: 1, }}>
            <DSH3 style={{ color: DSTheme.PanelContrastColor }}>
              Invite by link
            </DSH3>
            <DSSmallPrint style={{ color: DSTheme.SecondaryText }}>
              (Valid for 48 hrs)
            </DSSmallPrint>
          </DSColumn>


          {linkCopied ?
            <DSPrint style={{ paddingRight: 8 }}>
              Copied!
            </DSPrint>
            :
            undefined}
          {

            <DS2Button
              startIcon={loadingLink ? <DSSpinner size={16} /> : DS2Icons.copy}
              onClick={getInviteLink}
              disabled={loadingLink}
            >
              {loadingLink ? 'Generating' : 'Copy Link'}
            </DS2Button>
          }
        </DSRow>
      }
    >
      <DSH3 style={{ marginBottom: 6 }}>Enter emails</DSH3>
      <EmailChipInput
        onEmailsChange={(newEmails) => {
          setEmails(newEmails);
        }}
      />

      <DSRow
        style={{
          justifyContent: "flex-end",
        }}
      >

        <DS2Button
          analyticsEvent='InviteUsersSend'
          disabled={emails.length == 0}
          onClick={sendInviteEmails}
        >
          Send Invite
        </DS2Button>
      </DSRow>


    </DSModalPanel>
  );
})

export default InviteUsers

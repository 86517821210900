import React, { useEffect, useRef, useState } from 'react';
import { KSpaceId } from '@openteam/models';
import { DataState } from '../../Data/DataState';
import { observer } from 'mobx-react';
import { autorun, runInAction } from 'mobx';
import { setDockComponentLength, UIState } from '../../Data/UIState';
import { UIDataState } from '@openteam/app-core';
import { Logger } from '@openteam/app-util';
import { MeetingConnectWindow } from '../Meeting/MeetingConnectWindow';
import { WaitingUsersPanel } from './WaitingUsersPanel';
import { DockIconBase } from './DockIconBase';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { useSpring, animated } from '@react-spring/web';
import { FaUserFriends } from 'react-icons/fa';
import { DSCircleIconButton } from '../../DesignSystem/DSButton';

const logger = new Logger("WaitingUsersDockIcon");

interface IMeetingDockIconProps {
  spaceId: KSpaceId;
}

export const WaitingUsersDockIcon = observer((props: IMeetingDockIconProps) => {
  const iconRef = useRef<HTMLDivElement>(null)

  const externalMeetings = DataState.spaces[props.spaceId].externalMeetings

  const waitingUsers = externalMeetings.waitingUsers || {}

  const tileSizeRatio = DSTheme.DockWaitingUsersIconRatio;
  const itemLengthRatio = (1 - DSTheme.DockMargin * 2);
  const tileSize = Math.floor(tileSizeRatio * UIState.dockSize);
  const itemLength = Math.floor(itemLengthRatio * UIState.dockSize);

  const hasWaitingUsers = Object.values(waitingUsers)
    .length > 0;


  useEffect(() => {
    runInAction(() => {

      UIState.dockHasWaitingUsers = hasWaitingUsers;
      setDockComponentLength("WaitingUsersIcon", hasWaitingUsers ? itemLengthRatio : 0, true)

      return () => setDockComponentLength("WaitingUsersIcon", 0, true)
    })
  }, [hasWaitingUsers])

  return (
    <DockIconBase
      id={`panel-waiting-users-icon`}
      forceShow={hasWaitingUsers}
      renderContents={
        (closePanel, windowId) =>
          <WaitingUsersPanel
            parentWindowId={windowId}
            spaceId={props.spaceId}
            closePanel={closePanel}
          />
      }
      renderIcon={(showingWindow) => (
        <>
          <WaitingUsersIcon
            hasWaitingUsers={UIState.dockHasWaitingUsers}
            badgeNum={Object.keys(waitingUsers).length}
            tileSize={tileSize}
            itemLength={itemLength}
          />
          {
            UIDataState.externalMeeting ?
              <MeetingConnectWindow
                externalMeeting={UIDataState.externalMeeting}
                targetRef={iconRef}
                onClose={() => { UIDataState.actions.clearExternalMeeting() }}
              />
              :
              undefined
          }
        </>
      )}
      withPanel={false}
    />
  )
})


const WaitingUsersIcon: React.FC<{
  tileSize: number,
  itemLength: number,
  hasWaitingUsers: boolean,
  badgeNum: number,
}> = ({ hasWaitingUsers, tileSize, itemLength, badgeNum }) => {

  const [displayed, setDisplayed] = useState<boolean>(false);

  const springStyle = useSpring({
    opacity: hasWaitingUsers ? 1 : 0,
    height: hasWaitingUsers ? itemLength : 0,
    onRest: () => !hasWaitingUsers ? setDisplayed(false) : null,
    onStart: () => hasWaitingUsers ? setDisplayed(true) : null,
  });

  if (!displayed)
    return null;

  return (
    <animated.div
      style={{
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        ...springStyle
      }}>
      <DSCircleIconButton
        data-tooltip="Waiting to join"
        icon={
          <FaUserFriends
            size={tileSize}
            style={{
              color: DSTheme.ButtonIconColor
            }}
          />
        }
        style={{
          width: tileSize,
          height: tileSize,
          backgroundColor: DSTheme.EmphasisBackgroundColor,
        }}
        badgeNum={badgeNum}
      />
    </animated.div>
  );
}


import { useTheme } from '@openteam/design-system'
import { KSpaceId, KSpaceChannelId } from "@openteam/models"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { DataState } from "../../../Data/DataState"
import { addToRecentUsers, openUserChat } from "../../../Data/UIState"
import { DS2PinButton, DS2IconActionButton, DS2Icons } from "../../../DesignSystem"
import { DSRow, DSColumn } from "../../../DesignSystem"
import { DSH3, DSPrint } from "../../../DesignSystem/DSText"
import StatusDot from '../../User/StatusDot'
import { getUserCustomStatusEmoji, getUserCustomStatusText, getUserStatus } from '../../User/StatusUtil'
import UserIcon from '../../User/UserIcon'
import UserCallButton from '../../User/UserCallButton'
import { formatInTimeZone } from 'date-fns-tz'

interface OverflowUserProps {
    spaceId: KSpaceId
    userId: KSpaceChannelId
    shown: boolean
    closeWindow: (onCloseCallback?: () => void) => void
}

const OverflowUser: React.FC<OverflowUserProps> = ({ spaceId, userId, shown, closeWindow }) => {
    const space = DataState.spaces[spaceId]
    const user = space.users[userId]
    const theme = useTheme()
    const online = user.status?.online === true;
    const call = user.roomId && space.calls[user.roomId]
    const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

    const openChat = React.useCallback(() => {
        openUserChat(spaceId, userId)
    }, [spaceId, userId])

    const knockUser = React.useCallback(() => {
        if (user.alerts?.knocked?.count && user.alerts?.knocked?.count > 0) {
            user.alerts.clear()
        } else {
            user.actions.knockUser();
        }
        addToRecentUsers(spaceId, user.id)
    }, [user])

    useEffect(() => {
        if (shown) {
            const itvl = setInterval(() => forceUpdate(), 10 * 1000);
            return () => clearInterval(itvl);
        }
    }, [shown])

    return user ? (
        <DSRow
            style={{
                padding: '6px 15px',
                height: 65,
                borderRadius: 8,
                backgroundColor: theme.palette.background.paper,
                alignItems: "center",
                justifyContent: 'space-between'
            }}
            spacing={8}

        >
            <DSRow spacing={8}>
                <DSColumn style={{ justifyContent: 'center' }}>
                    <UserIcon user={user} size={44} />
                </DSColumn>
                <DSColumn>
                    <DSRow style={{ alignItems: 'baseline' }} spacing={4}>
                        <DSH3 maxLines={1} data-tooltip={user.name} style={{ lineHeight: "110%" }}>{user.name}</DSH3>
                        <DS2PinButton pinned={user.pinned} onClick={user.actions.togglePinned} style={{ marginTop: -3, marginBottom: -3 }} />
                    </DSRow>

                    <DSRow spacing={4} style={{ alignItems: 'center' }}>
                        <StatusDot user={user} size={10} />
                        <DSPrint color={theme.palette.text.secondary} style={{ lineHeight: "110%" }}>
                            {getUserStatus(user)}
                        </DSPrint>
                    </DSRow>

                    <DSRow spacing={4} style={{ alignItems: 'center' }}>
                        <DSPrint style={{ lineHeight: "110%" }}>
                            {getUserCustomStatusEmoji(user)}
                        </DSPrint>
                        <DSPrint color={theme.palette.text.secondary} style={{ lineHeight: "110%" }} maxLines={1} data-tooltip={getUserCustomStatusText(user)} >
                            {getUserCustomStatusText(user)}
                        </DSPrint>
                    </DSRow>
                </DSColumn>
            </DSRow>
            <DSColumn style={{ alignItems: 'flex-end' }} spacing={4}>
                <DSPrint>
                    {user.status?.timezone && formatInTimeZone(new Date(), user.status?.timezone, 'HH:mm (zzz)') }
                </DSPrint>
                {online ?
                    <DSRow spacing={4}>
                        <DS2IconActionButton
                            color='secondary'
                            data-tooltip="Open Chat"
                            onClick={openChat}
                            analyticsEvent="OverflowOpenUserChat"
                            badgeNum={space.userChats[userId]?.numUnread}
                            badgeOverride={space.userChats[userId]?.hasMention ? '@' : undefined}
                        >
                            {DS2Icons.chat}
                        </DS2IconActionButton>

                        <DS2IconActionButton
                            color='secondary'
                            onClick={knockUser}
                            badgeNum={user.alerts?.knocked?.count}
                            data-tooltip={`Knock on user`}
                            analyticsEvent="KnockUser"
                            analyticsArgs={{ userId: user.id }}
                        >
                            {DS2Icons.knock}
                        </DS2IconActionButton>
                        <UserCallButton spaceId={spaceId} userId={userId} buttonStyle='action' />
                    </DSRow>
                    :
                    <DS2IconActionButton
                        color='secondary'
                        data-tooltip="Open Chat"
                        onClick={openChat}
                        analyticsEvent="OverflowOpenUserChat"
                    //badgeNum={user.numUnread}
                    >
                        {DS2Icons.chat}
                    </DS2IconActionButton>
                }
            </DSColumn>
        </DSRow>
    ) : null
}

export default observer(OverflowUser)

import React, { ReactNode, useState } from "react";
import { Logger } from "@openteam/app-util";
import { OTUITree, OTUserInterface, CALENDAR_AUTH_SCOPES } from "@openteam/app-core";
import { observer } from "mobx-react";
import { DSSpinner } from "../../DesignSystem/DSSpinner";
import { GAPIAuth } from "./GAPIAuth";
import { DS2BaseButton, DS2Button, DS2Icons } from "../../DesignSystem";
import { GoogleLogoIcon } from "./GoogleLogo";
import { ButtonUnstyled } from "@mui/base";
import {styled } from "../../DesignSystem";



// https://developers.google.com/identity/sign-in/web/server-side-flow
const logger = new Logger("GoogleCalendarAuth");


export const GoogleCalendarAuth: React.FC<{
  renderConnectButton?: (onClick: () => void) => ReactNode;
  renderDisconnectButton?: (onClick: () => void) => ReactNode;
  onConnected?: () => void;
}> = observer(({
  renderConnectButton,
  renderDisconnectButton,
  onConnected
}) => {

  const [authLoaded, setAuthLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleAuthError = ({ error, details }) => {
    logger.error("handleError: error=%s, details=%s", error, details);
    setError("Error initialising gapi: " + error);
  }

  const handleAuthLoaded = () => {
    setAuthLoaded(true);
  }

  const handleAuth = () => {
    logger.debug("handleAuth");

    const onSuccess = () => {
      OTUserInterface.toastHandlers.show("Connected to Google calendar ", "success");
      onConnected && onConnected();
    }
    const onError = (err: Error) => {
      OTUserInterface.toastHandlers.show("Failed to connect to Google calendar ", "error");
      onConnected && onConnected();
    }
    gapi.auth2
      .getAuthInstance()
      .grantOfflineAccess()
      .then(async (authResult) => {
        logger.debug("authResult:", authResult);

        await OTUITree.userManager.calendarManager.startCalendar(
          authResult["code"],
          onSuccess,
          onError
        );

        logger.debug("authResult set");
      })
      .catch((err) => {
      onConnected && onConnected();
        logger.error("Signin in error", err);
      });
  };

  const handleSignOut = () => {
    logger.debug("handleSignOut");
    gapi.auth2.getAuthInstance().signOut();

    const onSuccess = () => {
      OTUserInterface.toastHandlers.show("Disconnected from Google calendar ", "success");
      logger.debug(OTUITree.userManager.calendarManager.doc?.auth?.tokens);
    }

    const onError = (err: Error) =>
      OTUserInterface.toastHandlers.show("Failed to disconnect from Google calendar ", "error");

    (async () => await OTUITree.userManager.calendarManager.stopCalendar(onSuccess, onError))();

    logger.debug("signOut done");
  };

  const {isBusy, disconnecting, isAuthorised} = OTUITree.userManager.calendarManager;

  return (
    <>
      <GAPIAuth
        scopes={CALENDAR_AUTH_SCOPES}
        onError={handleAuthError}
        onSuccess={handleAuthLoaded} />
      {!authLoaded ? (
        <DSSpinner size={24} />
      ) : isAuthorised ? (
        renderDisconnectButton ? (

          renderDisconnectButton(() => handleSignOut())

          ) : (

          <DS2Button
            color='error'
            analyticsEvent="GCalDisconnect"
            startIcon={disconnecting ? <DSSpinner size={16}/> : DS2Icons.close}
            disabled={isBusy}
            onClick={() => handleSignOut()}
          >
            Disconnect
          </DS2Button>
        )
      ) : (
        renderConnectButton ? (
          renderConnectButton(() => handleAuth())
        ) : (
          <GoogleSignInButton
            disabled={isBusy}
            analyticsEvent="GCalSignIn"
            onClick={() => handleAuth()}
          />
        )
      )}
      {error && <div>{error}</div>}
    </>
  );
});


const StyledButton = styled(DS2Button)(({theme}) => ({
  fontFamily: theme.typography.fontFamily,
  backgroundColor: 'white',
  padding: "4px 10px",
  color: 'black',
  //border: `1px solid ${theme.palette.secondary.dark}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 4,
  ':hover': {
    //border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: 'white',
  },
  //':active': {
  //  border: `1px solid ${theme.palette.secondary.light}`,
  //}

}))

const GoogleSignInButton = (props) => (
    <StyledButton
      startIcon={<GoogleLogoIcon size={14} />}
      {...props}
    >
      Sign in with Google
    </StyledButton>
)


export const isSameDay = (d1: Date, d2: Date) => d1.toDateString() === d2.toDateString();

export const toDay = (dt: Date) => new Date(dt.toDateString());

export function getFriendlyDate(date?: Date) {

  if (!date || !(date instanceof Date)) {
    return ""
  }

  const today = new Date()

  let dateString = ""

  if (isSameDay(today, date)) {
    dateString = date.toLocaleString('en-GB', { hour: "numeric", minute: "numeric" })

  } else {
    dateString = date.toLocaleString('en-GB', { month: 'short', day: 'numeric', hour: "numeric", minute: "numeric" })
  }

  return dateString
}

export const isToday = (dt: Date) => isSameDay(new Date(), dt);

export function timeSince(date: Date, postfix = "") {


  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);

  let interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + "d" + postfix;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + "h" + postfix;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + "m" + postfix;
  }
  return "just now";
}

export function getDuration(fromDate: Date, toDate: Date, roundDown: boolean = true) {
  let distance = Math.abs(fromDate.getTime() - toDate.getTime());

  let durationString = ""

  const hours = Math.floor(distance / 3600000);

  if (hours > 0) {
    durationString += `${hours}h `
  }
  distance -= hours * 3600000;
  const minutes = roundDown ? Math.floor(distance / 60000) : Math.ceil(distance / 60000);
  durationString += `${minutes}m`

  return durationString;
}

export function getFullDate(date?: Date, includeTime: boolean = true) {

  if (!date || !(date instanceof Date)) {
    return ""
  }

  const today = new Date()

  let dateString = ""

  const dateFormat: any = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' }

  if (includeTime) {
    dateFormat.hour = "numeric";
    dateFormat.minute = "numeric";
  }

  if (today.getFullYear() !== date.getFullYear()) {
    dateFormat.year = 'numeric'
  }
  dateString = date.toLocaleString('en-GB', dateFormat)

  return dateString
}


export function weekDayShort(date?: Date) {
  if (!date || !(date instanceof Date)) {
    return ""
  }
  const dateFormat: any = { weekday: "short" };

  return date.toLocaleString('en-GB', dateFormat)
}
export function monthShort(date?: Date) {
  if (!date || !(date instanceof Date)) {
    return ""
  }
  const dateFormat: any = { month: "short" };

  return date.toLocaleString('en-GB', dateFormat)
}

export function monthDayNum(date?: Date) {
  if (!date || !(date instanceof Date)) {
    return ""
  }
  const dateFormat: any = { day: "numeric" };

  return date.toLocaleString('en-GB', dateFormat)
}
export function get24Time(date?: Date) {
  if (!date || !(date instanceof Date)) {
    return ""
  }
  const dateFormat: any = { hour: "numeric", minute: "numeric", hour12: false };

  return date.toLocaleString('en-GB', dateFormat)
}

export function getFriendlyTime(date?: Date) {

  if (!date || !(date instanceof Date)) {
    return ""
  }

  const dateFormat: any = { hour: "numeric", hour12: false };

  if (date.getMinutes() !== 0 || date.getHours() === 0)
    dateFormat.minute = "numeric";

  return date.toLocaleString('en-GB', dateFormat)
}

import { Logger } from "@openteam/app-util"
import { observer } from "mobx-react";
import React, { useLayoutEffect } from "react";
import { ModalWindow } from "../Modal/ModalWindow";
import { ModalOptions, SubWindow, SubWindowCSS } from "../SubWindow";
import { InviteUsers } from "./InviteUsers";
const logger = new Logger("InviteUsersWindow")
import { getDockSize, openMainSpace, UIState } from "../../Data/UIState";
import { getGenericWindowPosition } from "../../Controllers/DockWindowUtil";

type Props = {
  spaceId: string
  parentId?: string
  onClose: () => void
}

export const InviteUsersWindow: React.FC<Props> = observer((props) => {
  const [position, setPosition] = React.useState<{ x: number, y: number }>()
  const windowId = `invite-users-${props.spaceId}`
  let width = 328
  let height = 320

  logger.debug("rendering")

  const calcPosition = () => {
    const pos = getGenericWindowPosition(width, height)

    logger.debug(`Positioning to ${pos.x},${pos.y}`)

    return pos
  }

  useLayoutEffect(() => {
    if (!props.parentId) {
      setPosition(calcPosition())
    }
  }, [props.parentId])

  if (!props.parentId && !position) {
    return null
  }

  return (
    <SubWindow
      id={windowId}
      parentId={props.parentId ? props.parentId : "space-main"}
      initialPosition={position}
      show={true}
      initialSize={{ width: width, height: height }}
      minHeight={height}
      minWidth={width}
      onClose={props.onClose}
      focus={true}
      options={ModalOptions}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <InviteUsers windowId={windowId} spaceId={props.spaceId} onClose={props.onClose} />
    </SubWindow>

  )
})

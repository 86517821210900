import React, { DragEventHandler, useEffect, useCallback, useState, useLayoutEffect } from 'react';
import { observer } from 'mobx-react';
import { DSPanel, DSRow } from '../../DesignSystem';
import { ChatMessages } from './ChatMessages';
import { ChatCompose } from './ChatCompose';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { KSpaceChannelId, KSpaceId, KSpaceUserId } from '@openteam/models';
import { FaUpload } from 'react-icons/fa';

import { Logger } from "@openteam/app-util";
import { DataState } from '../../Data/DataState';
import { ChatAlerts } from './ChatAlerts';
import { Droppable } from '../DragAndDrop';
import { RemirrorCompactCSS, RemirrorCSS } from './MarkdownEditor';
import { ChatMarkdownCSS } from './ChatMarkdownView';
import * as Analytics from '../../Util/Analytics'
import { OTUITree } from '@openteam/app-core';
import { DSBody, DSH2, DSH3 } from '../../DesignSystem/DSText';
import { LightBoxEntry } from '../Lightbox/Lightbox';
const logger = new Logger("Chat")


interface IChatProps {
  spaceId: KSpaceId
  channelId: KSpaceChannelId
  userId?: KSpaceUserId
  title?: string
  loadCallChat?: (callId: string) => void
  showLightbox?: (items: LightBoxEntry[], initialSelected?: number) => void
  messagesStyle?: React.CSSProperties,
}

export const Chat = observer((props: IChatProps) => {
  const _chatRef = React.useRef<HTMLDivElement>(null)
  const _composeRef = React.useRef<{ focus: () => void }>(null)


  const space = DataState.spaces[props.spaceId]

  if (!space.channels) {
    return null
  }

  useEffect(() => {
    Analytics.logEvent("Screen_Chat", { channelId: props.channelId })

    return () => Analytics.logEvent("Screen_ChatClosed", { channelId: props.channelId })
  }, [])


  const chat = space.channels[props.channelId]

  let userId: KSpaceUserId | undefined = props.userId;

  if (!userId && chat.chatType == 'chat') {
    const users = chat.userIds?.filter((userId) => userId != OTUITree.auth.userId) || [];

    if (users.length == 1) {
      userId = users[0];
    }
  }

  const focusInput = useCallback(() => {
    _composeRef.current?.focus()
  }, [])

  const [isOver, setIsOver] = useState<boolean>(false);
  const onDrop: DragEventHandler = (e) => {
    setIsOver(false);

    if (!e.dataTransfer.files) {
      return;
    }

    Object.keys(e.dataTransfer.files || {}).forEach((i) => {
      let file = e.dataTransfer.files[i];

      if (file.type) {
        chat.actions.addFile(file)
      }

    });

    Analytics.logEvent("ChatFileDropped")

  }

  const sendMessage = useCallback(() => {

    const _chat = DataState.spaces[props.spaceId].channels[props.channelId]

    logger.debug("sendMessage got _chat.draftMessage", _chat.draftMessage, "_chat.draftFiles", _chat.draftFiles)

    if (_chat.draftMessage || _chat.draftFiles.length > 0) {
      _chat.actions.sendMessage(_chat.draftMessage, _chat.draftFiles, _chat.draftReply)
      return true
    }
    return false

  }, [space.channels[props.channelId]])

  //useLayoutEffect(() => logger.debug(`rendering chat`));

  return (
    <DSPanel
      ref={_chatRef}
      style={{
        flex: 1,
        minHeight: 0,
        minWidth: 0,
        color: DSTheme.MainText,
        position: 'relative'
      }}>
      <Droppable
        acceptItemType={"Files"}
        onDrop={onDrop}
        onDragOver={(e) => setIsOver(true)}
        onDragLeave={(e) => setIsOver(false)}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
        }}
      >
        {isOver &&
          <div
            style={{
              position: "absolute",
              top: 0, left: 0, bottom: 0, right: 0,
              backgroundColor: 'rgba(255,255,255,0.7)',
              zIndex: 9,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaUpload size={40} style={{ color: 'red', pointerEvents: 'none' }} />
          </div>
        }

        <DSPanel style={{
          position: 'relative',
          borderRadius: 0,
          flex: 1,
          minHeight: 0,
          minWidth: 0,
          maxWidth: "100%",
        }}>
          <ChatMessages
            spaceId={props.spaceId}
            messages={chat.messages}
            atStart={chat.atStart}
            pendingMessages={chat.pendingMessages}
            callSummaries={chat.callSummaries}
            header={
              userId ?
                <DSPanel style={{ justifyContent: 'center', width: "100%", padding: "8 24" }}>
                  <DSBody style={{}}>
                    You can chat, call and share resources with <DSH3>{props.title}</DSH3> here.
                  </DSBody>
                </DSPanel>

                :
                <DSPanel style={{ justifyContent: 'center', width: "100%", padding: "8 24" }}>
                  <DSH2>Welcome to {props.title || chat.name}</DSH2>
                  {chat.desc ? <DSBody>{chat.desc}</DSBody> : undefined}
                  <DSBody style={{}}>
                    From this room you can start a call, share resources in the drive and send messages.
                </DSBody>
              </DSPanel>
            }

            messageId={chat.messageId}
            lastReadMessageId={chat.lastReadMessageId}
            messageNum={chat.messageNum}
            messageNumRead={chat.messageNumRead}
            numUnread={chat.numUnread}

            highlightMessageId={chat.highlightMessageId}
            isWatching={chat.isWatching}

            loadCallSummary={chat.actions.loadCallSummary}
            loadMoreMessages={chat.actions.loadMoreMessages}
            restart={chat.actions.restart}
            deleteMessage={chat.actions.deleteMessage}
            editMessage={chat.actions.editMessage}
            setReply={chat.actions.setReply}
            focusInput={focusInput}

            updateResource={chat.actions.updateResource}
            loadCallChat={props.loadCallChat}

            sendURL={chat.actions.sendURL}
            showLightbox={props.showLightbox}

            style={props.messagesStyle}

          />
          {
            userId ?
              <ChatAlerts spaceId={props.spaceId} userId={userId} />
              :
              null
          }
          <ChatCompose
            ref={_composeRef}
            spaceId={props.spaceId}
            draftMessage={chat.draftMessage}
            setMessage={(value) => {
              chat.actions.setMessage(value)
            }}
            draftFiles={chat.draftFiles}
            addFile={chat.actions.addFile}
            addMessageFile={chat.actions.addMessageFile}
            removeFile={chat.actions.removeFile}
            draftReply={chat.draftReply}
            setReply={chat.actions.setReply}
            userIds={chat.userIds || []}
            usersTyping={chat.usersTyping}
            sendMessage={sendMessage}
            resetDraft={chat.actions.resetDraft}
          />
        </DSPanel>
      </Droppable>
      <style type="text/css">{RemirrorCSS}</style>
      <style type="text/css">{ChatMarkdownCSS}</style>

    </DSPanel>
  )

})

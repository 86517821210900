import React, { CSSProperties, useCallback } from "react";
import { observer } from "mobx-react";
import { IUIWebcamStream } from "@openteam/models";
import { DataState } from "../../Data/DataState";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { FaMicrophoneSlash, FaUsers, FaVolumeMute, FaMicrophone, FaDesktop, FaPause } from "react-icons/fa";
import { DSButton } from "../../DesignSystem/DSButton";
import { Logger } from "@openteam/app-util";
import { ArcPosition, ArcPositionProps, CirclePosition, CirclePositionProps } from "../../Util/CirclePosition";
import { UIState, focusScreenShareWindow } from "../../Data/UIState";
import { SignalStrength } from "./SignalStrength";
const logger = new Logger("CallIndicators");

interface CallIndicatorProps {
  style?: React.CSSProperties;
};

export const CallIndicator: React.FC<CallIndicatorProps> = observer((props) => {
  const {style: _style  } = props;

  const innerStyle = {
    ...Styles.IndicatorStyle,
    ..._style,
    backgroundColor: DSTheme.CallIndicatorBackgroundColor,
    boxShadow: `1px 1px 1px 1px ${DSTheme.CallShadowColor}`,
  }

  return (
      <div style={innerStyle}>
        {props.children}
      </div>
  );
});

const Styles = {
  IndicatorStyle: {
    transition: "opacity 0.3s",
    borderRadius: "50%",
    zIndex: 20,
  } as CSSProperties,
};

interface CallIndicatorIconProps extends CallIndicatorProps {
  userId: string;
  size: number;
  //positionDegrees: number;
  hide?:boolean
}

export const MutedIcon: React.FC<CallIndicatorIconProps> = observer(({ userId, style, hide, ...props }) => {
  const participant = DataState.activeCall?.participants[userId];
  const webcamStream = participant?.streams["camera"] as IUIWebcamStream;

  let icon: JSX.Element | null = null;

  if (false && participant?.isPaused) {
    icon = <FaPause size={props.size} color={DSTheme.CallMutedColor} />;
  } else if (webcamStream?.muted) {
    icon = <FaMicrophoneSlash size={props.size} color={DSTheme.CallMutedColor} />;
  } else if (participant?.isSameRoom) {
    icon = <FaUsers size={props.size} color={DSTheme.CallMutedColor} />;
  } else if (participant?.isSoundDisabled) {
    icon = <FaVolumeMute size={props.size} color={DSTheme.CallMutedColor} />;
  }
  const _style = (icon && hide !== true)
    ? { opacity: 1, transition: "opacity 0.2s" }
    : { opacity: 0, transition: "opacity 0.1s" };

  return (
    <CallIndicator style={{padding: props.size*0.3, ..._style, ...style}} {...props}>
      {icon ?? <FaMicrophone size={props.size} color={DSTheme.CallIndicatorBackgroundColor} />}
    </CallIndicator>
  );
});

export const showMuted = (userId) => {
  const participant = DataState.activeCall?.participants[userId];
  const webcamStream = participant?.streams["camera"] as IUIWebcamStream;
  return webcamStream?.muted || participant?.isSameRoom || participant?.isSoundDisabled || false;
}

export const ScreenShareIcon: React.FC<CallIndicatorIconProps> = observer(
  ({ userId, ...props }) => {
    if (!DataState.activeCall) {
      return null;
    }
    const {screenShares, actions } = DataState.activeCall
    const hasScreensShare = userId in screenShares;


    let icon: JSX.Element | null = null;

    if (screenShares[userId]) {
      icon = <FaDesktop size={props.size} color={DSTheme.BackgroundColor} />;
    } else if (hasScreensShare) {
      icon = <FaDesktop size={props.size} color={DSTheme.EmphasisColor} />;
    } else {
      icon = <FaDesktop size={props.size} color={DSTheme.CallIndicatorBackgroundColor} />;
    }

    const style = hasScreensShare
      ? { opacity: 1, transition: "opacity 0.2s" }
      : { opacity: 0, transition: "opacity 0.1s" };

    return (
      <CallIndicator style={{padding: props.size*0.3, ...style}} {...props}>
        <DSButton onClick={() => focusScreenShareWindow(userId)}>
          {icon}
        </DSButton>
      </CallIndicator>
    );
  }
);

//interface CallTileIndicatorsProps extends Omit<ArcPositionProps, 'itemSize'>, CallIndicatorIconProps {
//
//}
//
//
//export const CallTileIndicators: React.FC<CallTileIndicatorsProps> = observer((
//  {userId, size, hide, style, ...arcProps}
//  ) => {
//  const callUser = DataState.activeCall?.participants[userId];
//  const isMe = userId === DataState.activeCall!.myUserId;
//  const hasScreenShare = !isMe && callUser?.streams["screen"] !== undefined;
//
//  return (
//    <ArcPosition
//      itemSize={size * 1.6}
//      {...arcProps}
//    >
//      <SignalStrength userId={userId} size={size} hide={hide} style={style}/>
//      {hasScreenShare && 
//        <ScreenShareIcon userId={userId} size={size} hide={hide} style={style}/>
//      }
//      <MutedIcon userId={userId}  size={size} hide={hide} style={style}/>
//    </ArcPosition>
//  )
//})
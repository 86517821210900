import React, { memo } from "react";
import { DS2Button, DS2IconButton, DSRow, styled, useTheme } from "../../../DesignSystem";

const emojis = [
  '👏',
  '🎉',
  '👍',
  '😂',
  '😀',
  '⭐'
];

type Props = {
  sendEmoji: (emoji: string) => void
}
const DockPanelEmojiSender: React.FC<Props> = ({ sendEmoji }) => {
  const theme = useTheme()

  return (
    <DSRow
      style={
        {
          width: '100%',
          padding: 0,
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 4,
          justifyContent: 'space-evenly',
        }
      }>
      {
        emojis.map((emoji, index) => (
          <EmojiButton
            key={`emoji-${index}`}
            onClick={() => sendEmoji(emoji)}
            variant="text"
          >
            {emoji}
          </EmojiButton>
        ))
      }
    </DSRow>
  )
}

export default memo(DockPanelEmojiSender);



const EmojiButton = styled(DS2Button)(({ theme }) => (
  {
    fontSize: 16,
    padding: "0px 6px",
    height: '29px',
    fontWeight: 'unset',

    ':hover': {
      backgroundColor: theme.palette.mode === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.15)'
    }
  }
))

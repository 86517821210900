function groupBy<T>(list: T[], f: (any) => string): Record<string, T[]> {
  return list.reduce((prev, curr) => {
    const key = f(curr);
    return {
      ...prev,
      [key]: [...(prev[key] || []), curr]
    };
  }, {});
}

export default groupBy;

import { toggleCallSelfMinimized } from '@openteam/app-core';
import { Logger } from '@openteam/app-util';
import { IUIWebcamStream } from '@openteam/models';
import { observer } from 'mobx-react';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DataState } from '../../Data/DataState';
import { DS2CloseIconButton, DSColumn, DSVSpacer } from '../../DesignSystem';
import { TileSettingsButton } from './CallButtons';
import { MutedIcon, showMuted } from './CallIndicators';
import CallVideo from './CallVideo';
import { SignalStrength } from './SignalStrength';
import * as CallUtil from './CallTileUtil';
import { DSStop } from '../../DesignSystem/DSIconButtons';
import ScreenShareTile from './ScreenShareTile';
import { ArcPosition } from '../../Util/CirclePosition';
import { toDeg } from '../../Util/degToPos';
import ArcSubTile from './ArcSubTile';
import { ArcIndicators } from './ArcIndicators';
import { Print, useTheme } from '@openteam/design-system';
import { last } from 'remirror';
import CallLastMessage from './CallLastMessage';

const logger = new Logger('CallRoomTile');

type Props = {
  userId: string;
  streamType: 'camera' | 'screen';
  tileSize: number;
  hovered: boolean;
  aspectRatio?: string;
  style?: CSSProperties;
  onHovered?: (hovered: boolean) => void;
  showChat?: boolean;
};

const CallRoomTile: React.FC<Props> = ({
  userId,
  streamType,
  tileSize,
  hovered: callHovered,
  aspectRatio = '1',
  style,
  onHovered,
  showChat = false
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [tileHovered, setTileHovered] = useState(false);

  const call = DataState.activeCall!;
  const callUser = call.participants[userId];
  const isMe = userId === call.myUserId;
  const isCamera = streamType === 'camera';
  const flip = isMe && isCamera;
  const hasScreenShare = callUser?.streams['screen'] !== undefined;
  const stream = callUser?.streams[streamType] as IUIWebcamStream;
  const borderWidth = Math.max(2, Math.min(4, Math.round(tileSize / 100)));
  const iconSize = Math.max(10, Math.min(20, Math.floor(tileSize / 30)));

  useEffect(() => onHovered?.(tileHovered), [tileHovered]);

  const showInitials = !(stream?.hasVideo || callUser?.imageUrl);
  const circular = call.mode === 'medium';
  const lastMessage = call.callMessageManager.userLastMessage[userId];

  useEffect(() => {
    if (lastMessage) {
      logger.debug(`lastMessage for ${userId}`, lastMessage)
    }
  }, [lastMessage])
  return (
    <>
      <CallUtil.TileWrapper
        callHovered={callHovered}
        tileSize={tileSize}
        circular={circular}
        backgroundImage={(isCamera && callUser?.imageUrl) || undefined}
        muted={stream?.muted}
        speaking={stream?.speaking}
        volume={stream?.volume}
        onTileHovered={setTileHovered}
        //onDoubleClick={isMe ? toggleCallSelfMinimized : undefined}
        style={style}
      >
        {stream && (
          <CallVideo
            key={`${userId}-${streamType}`}
            track={stream.videoTrack}
            hasVideo={stream.hasVideo}
            flip={flip}
            faceDetect={isCamera ? callUser?.faceDetect : undefined}
            blur={isCamera && call?.isCallPaused}
            style={{ objectFit: isCamera ? 'cover' : 'contain' }}
          />
        )}

        {showInitials && <CallUtil.Initials userId={userId} tileSize={tileSize} />}

        {isCamera ? (
          <>
            {isMe ? (
              <CallUtil.PauseButton tileHovered={tileHovered} tileSize={tileSize} />
            ) : (
              <CallUtil.TileHover userId={userId} tileHovered={tileHovered} />
            )}
          </>
        ) : null}
      </CallUtil.TileWrapper>
      {
        showChat ?
          <CallLastMessage userId={userId} circular={circular} />
          :
          null
      }
      {circular ? (
        <>
          <ArcIndicators userId={userId} tileSize={tileSize} hovered={callHovered} resizing={false} />

          {hasScreenShare && (
            <ArcSubTile userId={userId} tileSize={tileSize} itemSize={tileSize / 4} hovered={callHovered}>
                <ScreenShareTile userId={userId} size={tileSize / 4} />
            </ArcSubTile>
          )}
        </>
      ) : (
        <>
          <div style={Styles.topLeft}>
            <SignalStrength userId={userId} size={iconSize} forceShow={tileHovered} popupOnLeft={false} />
          </div>

          <DSColumn style={Styles.topRight}>
            {showMuted(userId) && (
              <>
                <MutedIcon userId={userId} size={iconSize} />
                <DSVSpacer size={iconSize / 2} />
              </>
            )}
            {!isMe && <TileSettingsButton userId={userId} size={iconSize} forceShow={callHovered} isFocused={true} />}
          </DSColumn>

          {hasScreenShare && (
            <div style={Styles.bottomRight}>
                <ScreenShareTile userId={userId} size={Math.min(200, Math.max(tileSize / 4, 30))} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default observer(CallRoomTile);

const Styles: Record<string, CSSProperties> = {
  absolute: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  },
  topLeft: {
    position: 'absolute',
    top: '3%',
    left: '3%',
    alignItems: 'self-start'
  },
  topRight: {
    position: 'absolute',
    top: '3%',
    right: '3%',
    alignItems: 'self-end'
  },
  bottomLeft: {
    position: 'absolute',
    bottom: '3%',
    left: '3%',
    alignItems: 'self-start'
  },
  bottomRight: {
    position: 'absolute',
    bottom: '3%',
    right: '3%',
    alignItems: 'self-end'
  },
  tileContainer: {}
};

import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { Logger } from "@openteam/app-util";
import { DSPanel } from "../../DesignSystem";
import { DSH3 } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { focusTutorial, setStepPosition, tutorialState, tutorialSteps, unsetStepPosition } from "../../Controllers/TutorialService";
import { UIState } from "../../Data/UIState";
import { observer } from "mobx-react";
import { reaction } from "mobx";
const logger = new Logger("TutorialStepHolder");

type Position = { x: number; y: number };

type Rect = {
  x: number
  y: number
  width: number
  height: number
}

interface ITutorialStepHolderProps {
  parentId: string
  ids: string[]
  position: 'left' | 'right'
  style?: CSSProperties
  ready?: boolean
  offsetX?: number
  offsetY?: number
  showOverLay?: boolean
  overlayStyle?: CSSProperties
}


export const TutorialStepHolder: React.FC<ITutorialStepHolderProps> = observer((props) => {

  return (
    tutorialState.active ?
      <TutorialStep {...props}>
        {props.children}
      </TutorialStep>
      :
      <div style={{
        ...props.style
      }}>
        {props.children}
      </div>
  )
})

export const TutorialStep: React.FC<ITutorialStepHolderProps> = observer((props) => {

  const ref = React.useRef<HTMLDivElement>(null)
  const currentStep = React.useRef<string>()

  const [parentPosition, setParentPosition] = useState<Position>();
  const [workArea, setWorkArea] = useState<Electron.Rectangle>();
  const [componentPosition, setComponentPosition] = useState<Rect>();
  const { showOverLay = false } = props

  const rect = ref.current?.getBoundingClientRect()


  const isActive = tutorialState.currentStep != undefined && props.ids.includes(tutorialSteps[tutorialState.currentStep].id)

  // hack to make sure we get a rerender everytime the rect might have changed

  if (!UIState.dockAutoHide && !UIState.dockAnimating && rect && (
    rect.x != componentPosition?.x ||
    rect.y != componentPosition?.y ||
    rect.width != componentPosition?.width ||
    rect.height != componentPosition?.height
  )) {
    logger.debug("componentPosition", componentPosition, "new", rect, props.ids)

    setComponentPosition(rect)
  }


  const getWindowPosition = useCallback(
    async () => {

      const windowPosition = await window.Main.getWindowBounds(props.parentId)
      const workAreaPosition = await window.Main.getWindowWorkArea(props.parentId)
      logger.debug("windowPosition", windowPosition, "workAreaPosition", workAreaPosition)
      setParentPosition(windowPosition);
      setWorkArea(workAreaPosition);
    },
    [setParentPosition, setWorkArea]
  );

  useEffect(() => {
    const unsubscribe: (() => void)[] = []
    if (props.parentId === '__dock__') {
      unsubscribe.push(reaction(
        () => ({ ...UIState.dockPosition }),
        (bounds) => {

          setParentPosition(bounds)
        })
      )


    } else {
      unsubscribe.push(window.Main.on(`window-moved-${props.parentId}`, ({ bounds }) => {
        setParentPosition(bounds)
      }));

      unsubscribe.push(window.Main.on(`window-focused-${props.parentId}`, focusTutorial));
    }

    getWindowPosition()
    return () => {
      unsubscribe.forEach(f => f());
    };
  }, [props.parentId, getWindowPosition]);

  useEffect(() => {

  })


  useEffect(() => {
    const step = tutorialSteps[tutorialState.currentStep]

    logger.debug("setting stepPosition", {
      currentStep: tutorialState.currentStep,
      active: tutorialState.active,
      dockAnimating: UIState.dockAnimating,
      parentPosition, componentPosition
    })
    if (tutorialState.active && !UIState.dockAnimating && UIState.dockShown && parentPosition && componentPosition) {

      if (props.ids.includes(step.id) && (props.ready ?? true)) {
        logger.debug("parentPosition componentPosition", parentPosition, componentPosition, step.id)

        setStepPosition(
          step.id,
          parentPosition.x + componentPosition.x + (props.position === 'right' ? componentPosition.width : 0) + (props.offsetX || 0),
          parentPosition.y + componentPosition.y + (props.offsetY || 0),
          props.position,
          props.parentId
        )
      }

      if (step.id != currentStep.current) {

        if (currentStep.current) {
          unsetStepPosition(currentStep.current)
        }
        currentStep.current = step.id
      }

    }
  }, [
    parentPosition, workArea, componentPosition, tutorialSteps, tutorialState.currentStep, tutorialState.stepLoaded, tutorialState.active, props.ids, UIState.dockAnimating
  ])


  return <div ref={ref} style={{
    ...props.style
  }}>
    {props.children}
    {
      isActive && showOverLay ?
        <div className="ovelay" style={{
          pointerEvents: "none",
          zIndex: 99,
          position: "absolute",
          top: componentPosition?.y,
          left: componentPosition?.x,
          width: componentPosition?.width,
          height: componentPosition?.height,
          boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.5)",
          borderRadius: 15,
          ...props.overlayStyle
        }} />
        :
        undefined
    }

  </div>
})

const highlightStyle: CSSProperties = {
  zIndex: 99,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: "radial-gradient(circle, transparent 160px, rgba(0, 0, 0, 0.5) 200px)"
}
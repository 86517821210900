import React, { useEffect, useRef, useState } from "react";
import { Icons, Button, ButtonProps } from "@openteam/design-system"
import { Logger } from "@openteam/app-util";
import { DataState } from '../../Data/DataState';

const logger = new Logger('DS2Buttons');

export const DS2ChatButton: React.FC<ButtonProps> = (
  { title = 'Chat', ...props }) => {

  return (
    <Button
      startIcon={Icons['chat']}
      analyticsEvent="userChat"
      color="secondary"
      title={title}
      {...props}
    />
  )
}

export const DS2KnockButton: React.FC<ButtonProps> = (
  { title = 'Knock', ...props }) => {

  return (
    <Button
      startIcon={Icons['knock']}
      analyticsEvent="userKnock"
      color="secondary"
      title={title}
      noBadge
      {...props}
    />
  )
}

export const DS2CallButton: React.FC<ButtonProps> = (
  { title, ...props }) => {

  return (
    <Button
      startIcon={Icons['call']}
      analyticsEvent="userCall"
      color="success"
      title={title ?? (DataState.activeCall ? "Invite" : "Call")}
      noBadge
      {...props}
    />
  )
}

export const DS2AcceptButton: React.FC<ButtonProps> = ({ title = 'Accept', ...props }) => {
  return (
    <Button
      startIcon={Icons['answer']}
      analyticsEvent="acceptRequestToJoin"
      color="success"
      title={title}
      noBadge
      {...props}
    />
  )
}

export const DS2HoldButton: React.FC<ButtonProps> = ({ title = 'Hold on', ...props }) => {
  return (
    <Button
      startIcon={Icons['hold']}
      analyticsEvent="holdRequestToJoin"
      color="secondary"
      title={title}
      noBadge
      {...props}
    />
  )
}

export const DS2AcceptRequestButton: React.FC<ButtonProps> = (props) => {
  return <DS2AcceptButton analyticsEvent="acceptRequestToJoin" {...props} />
}

export const DS2HoldOnButton: React.FC<ButtonProps> = ({ title = 'Hold on', ...props }) => {
  return (
    <Button
      startIcon={Icons['hold']}
      color={"secondary"}
      title={title}
      noBadge
      {...props}
    />
  )
}

export const DS2RejectButton: React.FC<ButtonProps> = ({ title = 'Reject', ...props }) => {
  return (
    <Button
      startIcon={Icons['reject']}
      analyticsEvent="reject"
      color="error"
      title={title}
      noBadge
      {...props}
    />
  )
}

export const DS2RejectRequestButton: React.FC<ButtonProps> = (props) => {
  return <DS2RejectButton analyticsEvent="rejectRequestToJoin" {...props} />
}

export const DS2LeaveCallButton: React.FC<ButtonProps> = ({ title = 'Leave', ...props }) => {
  return (
    <Button
      startIcon={Icons['leave']}
      analyticsEvent="leaveCall"
      color="error"
      title={title}
      noBadge
      {...props}
    />
  )
}

export const DS2CancelCallButton: React.FC<ButtonProps> = ({ title = 'Cancel Call', ...props }) => {
  return <DS2RejectButton analyticsEvent="cancelCall" title={title} {...props} />
}


export const DS2SaveButton: React.FC<ButtonProps> = ({ title = 'Save', ...props }) => {
  return (
    <Button
      startIcon={Icons['save']}
      analyticsEvent="saveRoomSettings"
      title={title}
      noBadge
      {...props}
    />
  )
}

export const DS2DeleteButton: React.FC<ButtonProps> = ({ title = 'Delete', ...props }) => {
  return (
    <Button
      startIcon={Icons['delete']}
      analyticsEvent="deleteRoom"
      color="error"
      title={title}
      noBadge
      {...props}
    />
  )
}

export const DS2SearchButton: React.FC<ButtonProps> = ({ title = 'Search', ...props }) => {
  return (
    <Button
      startIcon={Icons['search']}
      analyticsEvent="openSearch"
      color="secondary"
      title={title}
      noBadge
      {...props}
    />
  )
}

export const DS2SFeedBackButton: React.FC<ButtonProps> = ({ title = 'Feedback', ...props }) => {
  return (
    <Button
      startIcon={Icons['feedback']}
      analyticsEvent="openFeedback"
      color="secondary"
      title={title}
      noBadge
      {...props}
    />
  )
}

export const DS2UpgradeButton: React.FC<ButtonProps> = ({ title = 'Upgrade', ...props }) => {
  return (
    <Button
      startIcon={Icons['upgrade']}
      analyticsEvent="upgrade"
      color="warning"
      title={title}
      {...props}
    />
  )
}

export const DS2CreateButton: React.FC<ButtonProps> = ({ title = 'Create', ...props }) => {
  return (
    <Button
      startIcon={Icons['create']}
      analyticsEvent="create"
      title={title}
      {...props}
    />
  )
}

export const DS2JoinButton: React.FC<ButtonProps> = ({ title = 'Join', ...props }) => {
  return (
    <Button
      color="secondary"
      analyticsEvent="joinRoom"
      title={title}
      {...props}
    />
  )
}

export const DS2SubscribeButton: React.FC<ButtonProps> = ({ title = 'Subscribe', ...props }) => {
  return (
    <Button
      color="secondary"
      startIcon={Icons.subscribe}
      analyticsEvent="subscribeRoom"
      title={title}
      {...props}
    />
  )
}

interface DS2CopyLinkButtonProps extends ButtonProps {
  meetingLink?: string
}

const COPY_LINK = "Copy link";
const LINK_COPIED = "Link copied!";

export const DS2CopyLinkButton: React.FC<DS2CopyLinkButtonProps> = ({ meetingLink, title = 'Link', ...props }) => {

  const tooltipTimeout = useRef<ReturnType<typeof setTimeout>>()
  const [copyLinkTooltip, setCopyLinkToolTip] = useState(COPY_LINK);


  const copyLink = React.useCallback(async () => {
    if (meetingLink) {
      window.Main.clipboardWriteText(meetingLink)
      setCopyLinkToolTip(LINK_COPIED);
      tooltipTimeout.current = setTimeout(() => setCopyLinkToolTip(COPY_LINK), 2000)
    }
  }, [meetingLink])


  useEffect(() => {
    return () => tooltipTimeout.current && clearTimeout(tooltipTimeout.current);
  }, [])


  return (
    <Button
      color="secondary"
      startIcon={Icons.link}
      onClick={copyLink}
      disabled={!meetingLink}
      data-tooltip={copyLinkTooltip}
      data-tooltip-show={copyLinkTooltip === COPY_LINK ? undefined : true}
      analyticsEvent="linkRoom"
      noBadge
      title={title}
      {...props}
    />

  )
}

export const DS2CloseButton: React.FC<ButtonProps> = ({ title = 'Close', ...props }) => {
  return (
    <Button
      color="error"
      startIcon={Icons.close}
      analyticsEvent="close"
      title={title}
      {...props}
    />
  )
}


export const DS2MenuButton: React.FC<ButtonProps> = (
  { ...props }) => {

  return (
    <Button
      analyticsEvent="menuButton"
      color="secondary"
      title={props.title}
      startIcon={props.startIcon}
      {...props}
    />
  )
}

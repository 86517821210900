import React, { CSSProperties } from 'react';
import { DSTheme } from '../DesignSystem/DSTheme';
import { FaVideo } from 'react-icons/fa';
import { observer } from 'mobx-react';
import { DSColumn } from '../DesignSystem';


export const CamIcon: React.FC<{ style: CSSProperties; }> = observer(({ style }) => <DSColumn
  style={{
    backgroundColor: "white",
    padding: 4,
    borderRadius: 999,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
    ...style
  }}
>
  <FaVideo size={11} style={{ color: DSTheme.EmphasisColor }} />
</DSColumn>
);

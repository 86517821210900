import { KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { DataState } from '../../Data/DataState';
import { Logger } from '@openteam/app-util';
import { DSBody, DSH3, DSPrint } from '../../DesignSystem/DSText';
import { DSTrash, DSUserPlus } from '../../DesignSystem/DSIconButtons';
import { InviteUsersWindow } from '../Invite/InviteUsersWindow';
import * as Analytics from '../../Util/Analytics'
import UserIcon from '../User/UserIcon';
import DSSearchBox from '../../DesignSystem/DSSearch';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { OTUITree, OTUserInterface } from '@openteam/app-core';
import { DropMenu } from './DropMenu';
import { DSRow, DS2InviteIconButton, DSColumn, DS2DeleteIconButton } from '../../DesignSystem';

const logger = new Logger("WorkspaceUsersSettings")

export const WorkspaceUsersSettings: React.FC<{
  parentWindowId: string,
  spaceId: KSpaceId,
  scrollVersion: number,
}> = observer(({ parentWindowId, spaceId, scrollVersion }) => {

  const space = DataState.spaces[spaceId];

  const [showInvite, setShowInvite] = useState<boolean>(false);
  const [q, setQ] = useState<string>("");

  const doFilter = (name: string) => q.length === 0 || name.toUpperCase().includes(q.toUpperCase());

  const deleteUserFromTeam = async (userId: string) => {
    const doDelete = await OTUserInterface.showConfirm({
      parentId: parentWindowId,
      title: "Delete User?",
      message: [
        'Are you sure you want to remove this user from your workspace?',
      ],
      actions: { "Cancel": false,  'Delete': true, },
      height: 200,
    });
    if (doDelete) {
      space.actions.removeUserFromTeam?.(userId)
    }
  }

  const setAdminUser = async (userId: string, isAdmin: boolean) => {

    if (!isAdmin) {
      const doUpdate = await OTUserInterface.showConfirm({
        parentId: parentWindowId,
        title: "Change permission?",
        message: [
          'Are you sure you want to change this user to non-admin?',
        ],
        actions: { "Cancel": false , 'Change permission': true},
        buttonProps: { "Change permission": { color: 'primary' } },
        height: 240,
      });
      if (doUpdate) {
        space.actions.setAdminUser?.(userId, isAdmin)
      }
    } else {
      space.actions.setAdminUser?.(userId, isAdmin)
    }
  }

  return (
    <div>
      <DSRow style={{ alignItems: "center", marginBottom: 12, }}>
        <DSH3 style={{ marginRight: 12 }}>
          User list
        </DSH3>
        <DS2InviteIconButton
          analyticsEvent="OpenInviteUsers"
          onClick={() => { setShowInvite(true); }} />
      </DSRow>

      <DSSearchBox
        value={q}
        handleChange={(event) => setQ(event.target.value)}
        innerStyle={{ flexGrow: 1 }}
        style={{ flexGrow: 1, paddingTop: 5, marginRight: 140 }}
        inputStyle={{ width: "100%" }}
        autoFocus={true}
        placeholder={"Search for a user"}
      />

      {showInvite ? (
        <InviteUsersWindow
          spaceId={spaceId}
          parentId={'global-settings'}
          onClose={() => {
            setShowInvite(false);
            Analytics.logEvent("InviteUsersClosed")
          }}
        />
      ) : null}
      {Object.values(space.users)
        .filter(user => doFilter(user.name))
        .sort((a, b) => {
          const nameA = a.name?.toUpperCase();
          const nameB = b.name?.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
        .map((user) => (
          <DSRow key={`user-${user.id}`} style={{ marginTop: 12, marginBottom: 6, alignItems: 'center' }}>
            <UserIcon user={user} hideStatusDot={true} size={45} />

            <DSColumn style={{ marginLeft: 10, flexGrow: 1 }}>
              <DSPrint>{user.name}</DSPrint>
            </DSColumn>

            <DSColumn style={{ marginRight: 40 }}>
              <DropMenu
                windowId={`workspaceUserSettingsSubTeamId-${user.id}`}
                parentId={'global-settings'}
                data-tooltip={'Change team'}
                onItemSelected={(subTeamId?: string) => { space.actions.updateTeamUserSubTeam?.(subTeamId || null, user.id) }}
                options={Object.entries(space.subTeams)}
                undefinedLabel={"Unassigned"}
                value={user.teamId || ''}
                width={160}
                scrollVersion={scrollVersion}
              />
            </DSColumn>

            <DSColumn style={{ marginRight: 40 }}>
              <DropMenu
                windowId={`workspaceUserSettingsIsAdmin-${user.id}`}
                parentId={'global-settings'}
                data-tooltip={'Change role'}
                onItemSelected={(item?: string) => { setAdminUser(user.id, item === 'admin') }}
                options={[
                  ['admin', "Admin"],
                  ['user', 'User'],
                ]}
                value={user.isAdmin ? "admin" : "user"}
                scrollVersion={scrollVersion}
              />
            </DSColumn>

            {
              user.id != OTUITree.auth.userId ?
                <DS2DeleteIconButton onClick={() => deleteUserFromTeam(user.id)} />
                :
                null
            }

          </DSRow>
        ))}
    </div>
  );
});

import { OTUITree } from '@openteam/app-core';
import { Logger } from '@openteam/app-util';
import { KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { DataState } from '../../Data/DataState';
import { DSConfirmedInput, DSInput } from '../../DesignSystem/DSInput';
import { DSColumn, DSPanel, DSRow } from '../../DesignSystem';
import { DSBody, DSH3, DSPrint, DSSmallPrint } from '../../DesignSystem/DSText';
import { DSTheme, ThemeState } from '../../DesignSystem/DSTheme';
import { EmojiButton } from './EmojiButton';
import StatusDot from './StatusDot';
import { customStatuses, getUserStatus, systemStatuses, IStatusMin, getCombinedStatus, isInMeeting, getZoomStatus } from './StatusUtil';
import * as Analytics from '../../Util/Analytics'
import { DSSeparator } from '../../DesignSystem/DSSeparator';
import UserIcon from './UserIcon';
import { DS2Button, DS2Icons, DS2Option, DS2Select } from '../../DesignSystem/DS2'
import { CalendarSchedule } from '../Calendar/CalendarSchedule';
import { useTheme } from '@openteam/design-system';
import { action, toJS } from 'mobx';
import { GoogleCalendarIcon } from '../Calendar/GoogleCalendarLogo';
import { SpaceIcon } from '../Space/SpaceIcon';
import { showAddSpace } from '../../Data/UIState';
import { formatInTimeZone } from 'date-fns-tz'
import enGB from 'date-fns/locale/en-GB'
import { DSButton } from '../../DesignSystem/DSButton';


const logger = new Logger("UserProfileMenu")
interface IUserProfileMenuProps {
  windowId: string
  spaceId: KSpaceId
  setDisableOnBlur: (disable: boolean) => void
  onClose: () => void
}

export const UserProfileMenu = observer((props: IUserProfileMenuProps) => {
  const space = DataState.spaces[props.spaceId];
  const myUserId = OTUITree.auth.userId;
  const user = space.users[myUserId];
  const { customStatus, customStatusEmoji, customStatusText } = user.status ?? {};

  const [customEmojiValue, setCustomEmojiValue] = useState(customStatusEmoji);
  const [customTextValue, setCustomTextValue] = useState(customStatusText);
  const zoomManager = OTUITree.userManager.zoomManager;


  const theme = useTheme();

  const onClose = () => {
    props.onClose?.()
  }

  const setCustomStatus = useCallback((customStatusText) => {

    space.actions.setCustomStatus(
      customStatus,
      customEmojiValue,
      customStatusText,
    );

    Analytics.logEvent("setCustomStatus", {
      customStatusEmoji: customEmojiValue,
      customStatusText: customStatusText,
    })
  }, [props.spaceId, customStatus, customEmojiValue])

  const isCurrentStatus = (statusUser: IStatusMin) => (
    !(statusUser.status?.status != user.status?.status ||
      statusUser.status?.customStatus != user.status?.customStatus ||
      statusUser.status?.customStatusEmoji != user.status?.customStatusEmoji ||
      statusUser.status?.customStatusText != user.status?.customStatusText)
  )

  const statusOptions = systemStatuses; //.concat(customStatuses);

  const setStatus = useCallback((customStatus) => {
    const statusUser = systemStatuses.find(su => su.status?.customStatus === customStatus) ?? systemStatuses[0]
    logger.debug(`setStatus ${customStatus}`, statusUser)

    space.actions.setCustomStatus(
      statusUser.status?.customStatus,
      statusUser.status?.customStatusEmoji ?? customStatusEmoji,
      statusUser.status?.customStatusText ?? customStatusText,
    );
  }, [props.spaceId, customStatusEmoji, customStatusText])

  const clearStatus = () => {
    space.actions.setCustomStatus();
    setCustomEmojiValue(undefined);
    setCustomTextValue(undefined);
  }

  const clearMeetingStatus = () => {
  }

  const currentStatus = getUserStatus(user);

  const hasCustomStatus = (
    user.status?.customStatus ||
    user.status?.customStatusEmoji ||
    user.status?.customStatusText
  );

  const zoomStatus = getZoomStatus(user);

  logger.debug(`currentStatus ${currentStatus}, emoji ${customEmojiValue}`, toJS(user.status))

  return (
    <DSColumn
      style={{ padding: "12 12", minWidth: 400, color: DSTheme.MainText }}
      separator={<DSSeparator style={{ opacity: 0.2, margin: "12 0" }} />}
    >
      <DSRow style={{ justifyContent: 'space-between', alignItems: 'center' }} spacing={8}>
        <DSRow style={{ alignItems: 'center' }}>
          <UserIcon
            showCustomEmoji
            key={user.id}
            user={user}
            size={60}
          />

          <DSColumn style={{ paddingLeft: 12, flex: 1 }}>
            <DSRow spacing={6}>
              <DSH3>{user.name}</DSH3>
              <DSBody wrap={false}>(me)</DSBody>
            </DSRow>

            {user.team && <DSBody> {user.team} </DSBody>}

            {
              user.status?.timezone && (
                <DSPrint>
                  {formatInTimeZone(new Date(), user.status.timezone, 'HH:mm (zzz)', { locale: enGB })}
                </DSPrint>
              )
            }
          </DSColumn>
        </DSRow>

        <DS2Select
          value={props.spaceId}
          onChange={action((_, spaceId) => {
            if (spaceId === 'new-space') {
              showAddSpace()
            } else {
              OTUITree.userManager.setCurrentTeam(spaceId as string);
            }
          })}
          slotProps={{ root: { style: { height: 'fit-content' } } }}
        >
          {
            Object.entries(DataState.spaces).map(([_spaceId, _space]) => (
              <DS2Option value={_spaceId} key={_spaceId}>
                <DSRow spacing={6} style={{ alignItems: 'center' }}>
                  <SpaceIcon
                    spaceId={_spaceId}
                    tileSize={14}
                    borderRadius={999}
                  />
                  {_space.details.name}
                </DSRow>
              </DS2Option>
            ))
          }
          <DS2Option value={'new-space'} key={'new-space'}>
            <DSRow spacing={6} style={{ alignItems: 'center' }}>
              {DS2Icons.create}
              {'Create Team'}
            </DSRow>
          </DS2Option>
        </DS2Select>
      </DSRow>

      <DSColumn spacing={14}>
        <DSRow style={{ justifyContent: 'space-between' }}>
          <DS2Select
            value={user.status?.customStatus ?? 'ONLINE'}
            onChange={(_, customStatus) => setStatus(customStatus)}
          >
            {statusOptions.map((su, index) => (
              <DS2Option key={`option-${index}`} value={su.status?.customStatus ?? 'ONLINE'}>
                <DSRow spacing={4}>
                  <StatusDot
                    user={su}
                    showCustomStatusEmoji={false}
                    size={12}
                  />
                  <DSPrint>{getUserStatus(su)}</DSPrint>
                </DSRow>
              </DS2Option>
            ))}
          </DS2Select>
          <DS2Button
            variant='text'
            color='secondary'
            disabled={!hasCustomStatus}
            onClick={clearStatus}
          >
            Clear Status
          </DS2Button>
        </DSRow>

        <DSRow>
          <DSConfirmedInput
            containerStyle={{ borderRadius: 4, paddingLeft: 0 }}
            style={{ paddingLeft: 5, width: 150 }}
            placeholder='Enter custom status'
            value={customStatusText ?? ''}
            changed={(customEmojiValue !== customStatusEmoji)}
            onReset={() => setCustomEmojiValue(customStatusEmoji)}
            onUpdate={(value) => setCustomStatus(value)}
            onHasChange={props.setDisableOnBlur}
            autofocus
            left={
              <EmojiButton
                parentId={props.windowId}
                value={customEmojiValue}
                addEmoji={(emoji) => { setCustomEmojiValue(emoji) }}
                buttonStyle={{ minHeight: 28 }}
                onShowPicker={(shown: boolean) => props.setDisableOnBlur(shown)}
              />
            }
          />
        </DSRow>

      </DSColumn>
      {
        zoomStatus ? (
          <DSRow style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <DSRow style={{ alignItems: 'center' }} spacing={8}>

              {zoomStatus.icon}
              {zoomStatus.status}
            </DSRow>
            <DS2Button variant="text" color='secondary' onClick={zoomManager.clearStatus} endIcon={DS2Icons.hide}>
              Clear Zoom status
            </DS2Button>
          </DSRow>
        ) : null
      }
      <CalendarSchedule
        spaceId={props.spaceId}
        numDays={1}
        editMeeting={() => null}
      />
    </DSColumn>
  )
})


const Styles = {
  heading: {
    marginTop: 12,
  },
  settingRow: {
    marginTop: 12,
    justifyContent: "space-between",
    alignItems: 'center'
  },
  settingSubRow: {
    marginTop: 8,
    justifyContent: "space-between",
    alignItems: 'center'
  },
  label: {
    fontSize: "12px"
  },
  separator: {
    marginTop: 16,
    minHeight: 1,
  }

}

import { styled } from "../../../DesignSystem";

type DockOverflowPanelProps = { spacing?: number };


const DockOverflowPanel = styled('div')<DockOverflowPanelProps>(({ theme, spacing }) => ({
  padding: 15,
  width: 380,
  height: '100vh',
  overflow: 'hidden',
  boxSizing: 'border-box',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',

  ...(spacing ? {
    '& > :not(:first-of-type)': {
      marginTop: spacing,
    }
  } : null)
}))

export default DockOverflowPanel

import React, { CSSProperties, InputHTMLAttributes, useEffect, useRef } from "react";

import { Logger } from "@openteam/app-util"
import { animated, useSpring } from "@react-spring/web";
import { useState } from "react";
import { useCallback } from "react";
import { FaPen } from "react-icons/fa";
import { DSColumn, DSHSpacer, DSRow } from ".";
import { DSTheme } from "./DSTheme";
import { DSButton } from './DSButton';
import { DS2AcceptIconButton, DS2RejectIconButton, DS2RevertIconButton } from "./DS2";
import { styled, useTheme } from "@openteam/design-system"

const logger = new Logger("DSInput")

export interface DSInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (newValue: string) => string | void
  left?: JSX.Element
  resetOnBlur?: boolean
  outerStyle?: CSSProperties
}

export const DSInput = React.forwardRef<HTMLInputElement, DSInputProps>((props, ref) => {
  const { style, outerStyle, onValueChange, onChange, value: _value, resetOnBlur = false, left, children, ...otherProps } = props
  const [value, setValue] = React.useState(_value as string)
  const [hovered, setHovered] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const theme = useTheme();

  useEffect(() => setValue(_value as string), [_value])

  return (
    <div
      onMouseMove={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      style={{
        display: "flex",
        flexDirection: "row",
        //flex: 1,
        padding: '0 5',
        borderRadius: 4,
        border: `1px solid`,
        borderColor: hovered ? theme.palette.secondary.dark : focused ? theme.palette.secondary.dark : 'transparent',
        backgroundColor: DSTheme.InputColor,
        ...outerStyle
      }}
    >
      {left}
      <StyledInput
        ref={ref}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (onValueChange) {
              const newValue = onValueChange(value);
              newValue !== undefined && setValue(newValue);
            }
          }
        }}
        onFocus={() => setFocused(true)}
        onChange={(ev) => {
          setValue(ev.target.value);
          onChange && onChange(ev);
        }}
        onBlur={() => {
          setValue(_value as string);
          setFocused(false);
        }}
        value={value}
        {...otherProps}
        style={{
          display: 'flex',
          flex: 1,
          // fontFamily: 'Inter, sans-serif',
          fontSize: 14,
          outline: 'none',
          margin: "4 0",
          borderRadius: 6,
          border: 'none',
          backgroundColor: 'transparent',
          ...style
        }}
      />
      {children}
    </div>
  );
})

const StyledInput = styled('input')(({theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  '::placeholder': {
    opacity: 0.7,
    fontWeight: 500,
  },
  ':hover': {
    outline: 'none'
  }
}))

const StyledTextarea = styled('textarea')(({theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  border: '1px solid transparent',
  resize: 'none',
  '::placeholder': {
    opacity: 0.7,
    fontWeight: 500,
  },
  ':hover': {
    borderColor: theme.palette.secondary.dark
  },
  ':focus-visible': {
    borderColor: theme.palette.secondary.dark
  }
}))


interface PropsTextArea extends InputHTMLAttributes<HTMLTextAreaElement> {
}

export const DSTextArea = React.forwardRef<HTMLTextAreaElement, PropsTextArea>((props, ref) => {
  const { style, ...otherProps } = props
  return (
    <StyledTextarea
      ref={ref}
      {...otherProps}
      style={{
        flex: 1,
        // fontFamily: 'Inter, sans-serif',
        fontSize: 14,
        outline: 'none',
        padding: "8 5",
        borderRadius: 4,
        backgroundColor: DSTheme.InputColor,
        color: DSTheme.MainText,
        ...style
      }} />

  )
})
interface EditableProps extends InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (newValue: string) => void
  onEdit?: (boolean) => void
  align?: "left" | "right"
  showIcon?: boolean
  fontSize?: number
}

export const DSEditable = React.forwardRef<HTMLInputElement, EditableProps>((props, ref) => {
  const { style, onValueChange, onEdit, onChange, align, value: _value, showIcon, fontSize = 14, ...otherProps } = props
  const [editing, setEditing] = React.useState(false)
  const [hovered, setHovered] = React.useState(false)
  const [value, setValue] = React.useState(_value as string)

  useEffect(() => {
    logger.debug(`Editing ${editing}`)
    onEdit && onEdit(editing)
  }, [editing, onEdit])

  return (
    <>
      {(showIcon ?? props.disabled === false) &&
        <FaPen fontSize={fontSize} style={{ visibility: hovered || editing ? "visible" : "hidden", marginRight: 5 }} />
      }
      <input
        ref={ref}
        onMouseEnter={(ev) => { setHovered(true) }}
        onMouseLeave={(ev) => { setHovered(false) }}
        onFocus={() => setEditing(true)}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            setEditing(false)
            onValueChange && onValueChange(value)
          }
        }}
        onChange={(ev) => {
          setValue(ev.target.value)
          onChange && onChange(ev)
        }}
        onBlur={() => { setValue(_value as string); setEditing(false) }}
        value={value}
        {...otherProps}
        style={{
          flex: 1,
          // fontFamily: 'Inter, sans-serif',
          fontSize: fontSize,
          outline: 'none',
          border: 'none',
          textAlign: editing ? "left" : align,
          cursor: editing ? "text" : "default",
          borderRadius: 6,
          padding: "8 5",
          backgroundColor: editing ? "rgba(0,0,0,0.3)" : "transparent",
          ...style
        }} />
    </>
  )
})

interface Editable2Props extends InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (newValue: string) => void
  onEdit?: (boolean) => void
  fontSize?: number
  maxWidth: number
}
export const DSEditable2 = React.forwardRef<HTMLInputElement, Editable2Props>((props, ref) => {
  const { style, onValueChange, onEdit, onChange, maxWidth, value: _value, fontSize = 14, ...otherProps } = props
  const [editing, setEditing] = React.useState(false)
  const [value, setValue] = React.useState(_value as string)

  useEffect(() => {
    logger.debug(`Editing ${editing}`)
    onEdit && onEdit(editing)
  }, [editing, onEdit])

  if (!editing) {

    return (
      <DSButton
        onClick={() => setEditing(true)}
        style={{ fontSize: fontSize, paddingTop: 2, paddingBottom: 2 }}>
        <DSColumn>
          <DSRow>
            <span
              style={{
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: maxWidth - fontSize - 8,
                whiteSpace: "nowrap"
              }}>
              {value}
            </span>
            <DSColumn>
              <FaPen
                fontSize={fontSize}
                style={{ marginLeft: 8 }}
              />
            </DSColumn>
          </DSRow>
        </DSColumn>
      </DSButton>
    )
  } else {
    return (
      <input
        ref={ref}
        onFocus={() => setEditing(true)}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            setEditing(false)
            onValueChange && onValueChange(value)
          }
        }}
        onPointerDown={(e) => e.stopPropagation()}
        onChange={(ev) => {
          setValue(ev.target.value)
          onChange && onChange(ev)
        }}
        onBlur={() => { setValue(_value as string); setEditing(false) }}
        value={value}
        {...otherProps}
        style={{
          flex: 1,
          fontFamily: 'Inter, sans-serif',
          fontSize: fontSize,
          outline: 'none',
          border: 'none',
          borderRadius: 6,
          padding: "8 5",
          backgroundColor: "white",
          maxWidth,
          ...DSTheme.NoDrag,
          ...style
        }} />
    )
  }
})

export const DSConfirmedInput: React.FC<{
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  onHasChange?: (hasChange: boolean) => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>,
  onEnter?: (email: string) => void,
  onChangeValid?: (isValidEmail: boolean) => void,
  placeholder?: string,
  value: string,
  style?: React.CSSProperties,
  containerStyle?: React.CSSProperties,
  width?: number,
  onUpdate?: (value: string) => void,
  onReset?: () => void,
  changed?: boolean
  left?: JSX.Element
  autofocus?: boolean
}> = (props) => {
  const { changed = false, onReset } = props;
  const ref = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string>(props.value);
  const theme = useTheme();

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setValue(e.target.value);
    props.onChange && props.onChange(e);
  }, [props.value, props.onChange]);

  const commitChange = useCallback(() => {
    props.onUpdate && props.onUpdate(value);
  }, [value, props.onUpdate]);

  const revertChange = useCallback(() => {
    logger.debug("revertChange");
    setValue(props.value);
    onReset && onReset();
  }, [props.value]);

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback((e) => {

    if (e.key === "Enter") {
      commitChange();

    } else if (e.key === "Escape") {
      revertChange();
    }

  }, [commitChange, revertChange]);

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    props.autofocus === true && ref.current && ref.current.focus()
  }, [props.autofocus])

  const hasChanged = value !== props.value || changed;

  useEffect(() => {
    props.onHasChange && props.onHasChange(hasChanged);
  }, [hasChanged])

  const springStyle = useSpring({
    opacity: hasChanged ? 1 : 0,
  })

  return (
    <DSInput
      ref={ref}
      width={props.width}
      style={{
        flexGrow: 1,
        backgroundColor: 'transparent',
        ...props.style,
      }}
      outerStyle={{
        paddingRight: 0,
        width: "100%",
        //backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        height: 28,
        ...props.containerStyle
      }}
      value={value}
      placeholder={props.placeholder}
      onChange={onChange}
      onKeyDown={onKeyDown}
      left={props.left}
    >
      {props.children}
      <animated.div style={{ display: "flex", flexDirection: "row", ...springStyle }}>
        <DS2AcceptIconButton style={{ ...(hasChanged ? {} : { display: "none" }) }} onClick={() => commitChange()} />
        <DSHSpacer size={8} />
        <DS2RevertIconButton style={{ ...(hasChanged ? {} : { display: "none" }) }} onClick={() => revertChange()} />
      </animated.div>
    </DSInput>
  );
}

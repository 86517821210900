import { FireUtils, OTUITree, OTUserInterface } from '@openteam/app-core';
import { Logger } from '@openteam/app-util';
import { KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import React, { CSSProperties, useCallback, useMemo, useRef, useState } from 'react';
import { DataState } from '../../Data/DataState';
import { DSAccept, DSImageUpload, DSReject, DSSelfieButton } from '../../DesignSystem/DSIconButtons';
import { DSImage } from '../../DesignSystem/DSImage';
import { DSConfirmedInput } from '../../DesignSystem/DSInput';
import { DSSpinner } from '../../DesignSystem/DSSpinner';
import { DSH1, DSH3 } from '../../DesignSystem/DSText';
import { getStringColor } from '../../Util/StringColor';
import { getInitials } from '../../Util/UserInitials';
import { SelfieCam } from '../User/Selfie';
import * as Analytics from '../../Util/Analytics'
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DS2AcceptIconButton, DS2BaseButton, DS2CaptureIconButton, DS2IconActionButton, DS2IconButton, DS2RejectButton, DS2RejectIconButton, DS2UploadIconButton, DSHSpacer, DSPanel, DSRow } from '../../DesignSystem/DS2';
import { FaCamera } from 'react-icons/fa';


const logger = new Logger("UserProfileSettings")

export const WorkspaceUserProfileSettings: React.FC<{ 
  parentWindowId: string,
  spaceId: KSpaceId
 }> = observer((props) => {
  const fileInput = useRef<HTMLInputElement>(null)

  const space = DataState.spaces[props.spaceId]
  const tileSize = 225
  const myUserId = OTUITree.auth.userId

  const user = space.users?.[myUserId]

  const [isLeavingTeam, setIsLeavingTeam] = useState<boolean>(false);

  const [showSelfieCam, setShowSelfieCam] = useState<boolean>(false);
  const [showUploadedImage, setShowUploadedImage] = useState<boolean>(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>(user.name);
  const [uploading, setUploading] = useState<boolean>(false);
  const initials = useMemo(() => getInitials(user.name), [user.name])
  const backgroundColor = useMemo(() => getStringColor(user.name), [user.name])

  const updateName = useCallback((newName) => {
    logger.debug("setting name to ", newName);
    space.actions.updateTeamUserName?.(newName)
    setName(newName)
    Analytics.logEvent("ProfileUpdateName")

  }, [setName, space.actions.updateTeamUserName])

  const onFiles = useCallback((e) => {
    const files = e.target.files;
    const file = files[0];

    if (!file) {
      return;
    }
    logger.debug("uploading new profile image", file)

    setUploading(true)
    FireUtils.uploadUserFile(props.spaceId, OTUITree.auth.userId, "profile", file, (url) => {
      setUploading(false);
      setUploadedImageUrl(url);
      setShowUploadedImage(true);
      setShowSelfieCam(false);
    });
  }, [setUploading])

  const saveUploadedImage = () => {
    logger.debug("uploaded new profile image", uploadedImageUrl)

    if (!uploadedImageUrl) {
      logger.error("no uploaded image url");
    } else {
      space.actions.updateTeamUserImageUrl?.(uploadedImageUrl)
      Analytics.logEvent("ProfileUpdateImage")
    }
    setShowUploadedImage(false);
  }


  const leaveTeam = async () => {
    const doLeaveTeam = await OTUserInterface.showConfirm({
      parentId: props.parentWindowId,
      title: "Leave workspace?",
      message: [
        'Are you sure you want to leave this workspace?',
      ],
      buttonProps: {
        'Leave': {
          color: 'error'
        }
      },
      actions: { "Cancel": false,  'Leave': true, },
      height: 200,
    });
    if (doLeaveTeam) {
      setIsLeavingTeam(true)
      await space.actions.leaveTeam?.()
      setIsLeavingTeam(false)
    }
  }



  return (
    <div>
      <DSRow style={{ marginBottom: 8 }}>
        <DSH3>Name</DSH3>
      </DSRow>
      <DSConfirmedInput
        style={{
          padding: "2 5",
          borderRadius: 4,
        }}
        containerStyle={{
          flex: "0 0 400px",
        }}
        value={name}
        onUpdate={(value) => updateName(value)}
      />
      <hr style={Styles.separator} />

      <DSRow style={{ marginTop: 12, marginBottom: 6, alignItems: 'center' }}>
        <DSH3 style={{ marginRight: 14 }}>
          Profile Image
        </DSH3>

        {showSelfieCam ? (
          <DS2RejectButton
            onClick={() => setShowSelfieCam(false)}
            title="Cancel"
          />
        ) : (
          <DS2CaptureIconButton
            data-tooltip="Take a selfie"
            onClick={() => {
            setShowSelfieCam(true)
            Analytics.logEvent("ProfileOpenSelfieCam");
          }}/>
        )}
        <DSHSpacer size={8} />
        <input
          ref={fileInput}
          style={{ display: 'none' }}
          type="file"
          onChange={onFiles}
          accept="image/x-png,image/gif,image/jpeg" />

        {!showSelfieCam && (
          uploading ?
            <DSSpinner size={20} />
            :
            <DS2UploadIconButton
              data-tooltip={"Upload a new profile image"}
              disabled={showSelfieCam}
              onClick={() => {
                fileInput.current?.click();
                Analytics.logEvent("ProfileOpenFilePicker");
              }} />
          )
        }
      </DSRow>
      <DSRow style={{
        marginTop: 24,
        marginLeft: 30,
      }}>

        {
          showSelfieCam ?
            <SelfieCam
              key={"selfieCam"}
              spaceId={props.spaceId}
              size={tileSize}
              onClose={() => setShowSelfieCam(false)}
            />
            :
            (showUploadedImage && uploadedImageUrl) ? (
              <DSPanel
                style={{
                  position: 'relative',
                  borderRadius: DSTheme.BaseBorderRadius,
                  width: tileSize,
                  height: tileSize,
                  overflow: 'hidden',
                }}>

                <DSImage
                  url={uploadedImageUrl}
                  style={{
                    borderRadius: '50%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    margin: 0,
                    backgroundColor: backgroundColor,
                    objectFit: 'cover',
                    overflow: 'hidden',
                  }}
                  height={tileSize}
                  width={tileSize}
                />

                <DSRow style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 12,
                  justifyContent: 'center'
                }}>
                  <DSRow>
                    <DS2AcceptIconButton onClick={() => saveUploadedImage()} />
                    <DSHSpacer size={12} />
                    <DS2RejectIconButton onClick={() => setShowUploadedImage(false)} />
                  </DSRow>
                </DSRow>
              </DSPanel>

            ) :
              <DSRow style={{
                borderRadius: "50%",
                height: tileSize,
                width: tileSize,
                backgroundColor: user.imageUrl ? undefined : backgroundColor,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                {user.imageUrl ?
                  <DSImage
                    url={user.imageUrl}
                    height={tileSize}
                    width={tileSize}
                    style={{
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      margin: 0,
                      backgroundColor: backgroundColor,
                      objectFit: 'cover',
                      overflow: 'hidden',
                    }}
                  />
                  :
                  <DSH1 strong>{initials}</DSH1>
                }
              </DSRow>
        }
      </DSRow>
      
      <DSRow style={{
        marginTop: 40,
      }}>
        <DS2BaseButton
          size='medium'
          color="error"
          analyticsEvent="leaveTeam"
          disabled={isLeavingTeam}
          onClick={() => leaveTeam()}
        >
          Leave Team
        </DS2BaseButton>
      </DSRow>
    </div>
  )
})



const Styles: Record<string, CSSProperties> = {
  separator: {
    height: 0,
    width: "80%",
    borderTopWidth: 1,
    borderBottomWidth: 0,
    borderColor: "white",
    borderTopStyle: "solid",
    marginTop: 20,
  },
}

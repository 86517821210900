import { Logger } from "@openteam/app-util";
import emojiRegex from 'emoji-regex';
import { observer } from "mobx-react";
import React, { useLayoutEffect } from "react";
import { getDockSize, openMainSpace, UIState } from "../../Data/UIState";
import { DSButton } from "../../DesignSystem/DSButton";
import { DSImage } from "../../DesignSystem/DSImage";
import { DSColumn, DSPanel, DSRow } from "../../DesignSystem";
import { DSPromptButton } from "../../DesignSystem/DSPromptButton";
import { DSH2, DSPrint } from "../../DesignSystem/DSText";
import { DSModalPanel } from "../Modal/ModalLayout";
import { ModalOptions, SubWindow, SubWindowCSS } from "../SubWindow";
import { DataState } from '../../Data/DataState';
import { getStringBackground, getStringColor } from '../../Util/StringColor';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { parseEmojis } from "../../Util/EmojiUtil";
import { getDummyPod, isDummyPod } from "../../Data/tutorialDummy";
import { DSBadgeView } from "../../DesignSystem/DSBadge";
import { ZoomIcon } from "../Zoom";
const logger = new Logger("PodIcon")

type Props = {
  spaceId: string
  podId?: string
  callId?: string
  name?: string
  size?: number
  showBorder?: boolean
  badgeNum?: number;
  background?: string;
  symbol?: string;
  badgeOverride?: string | JSX.Element
}

export const RoomIcon: React.FC<Props> = observer(({size=28, badgeNum, badgeOverride, ...props}) => {

  const space = DataState.spaces[props.spaceId]
  const isTutorial = props.podId ? isDummyPod(props.podId) : false
  const pod = props.podId ? isTutorial ? getDummyPod(props.podId) : space.pods[props.podId] : undefined;
  const channelDirectory = props.podId ? space.channelDirectory[props.podId] : undefined;
  const call = props.callId ? space.calls[props.callId] : undefined;

  const podName = pod?.name ?? channelDirectory?.name;
  const name = podName ?? props.name
  const background = props.background ?? pod?.color ?? (props.podId && podName ? getStringBackground(podName, UIState.dockPosition.onLeft) : DSTheme.DockCallBackgroundColor);

  const borderRadius = size / 6
  const borderWidth = Math.max(2, size / 18)


  const symbol = props.symbol ?? pod?.symbol ?? name?.substring(0, 1) ?? "R"

  const isEmoji = symbol.match(emojiRegex())
  const icon = call?.platform === 'zoom' ? <ZoomIcon size={size/1.5}/> : undefined
  const fontSize = isEmoji ? size / 1.5 : Math.max(18, size / 2)

  const tipPosition = UIState.dockHorizontal ? UIState.dockPosition.onTop ?? UIState.dockEdge === 'top' ? 'bottom' : 'top' : UIState.dockPosition.onLeft ? 'right' : 'left'

  return (
    <DSPanel
      className={'room-icon-panel'}
      style={{
        height: UIState.dockHorizontal ? size : 'fit-content',
        width: UIState.dockHorizontal ? 'fit-content' : size,
        flexDirection: UIState.dockHorizontal ? 'row' : 'column',
        minWidth: size,
        minHeight: size,
        background,
        borderRadius: borderRadius,
        ...props.showBorder && {
          borderWidth: borderWidth,
          borderRadius: borderRadius + 2,
          borderStyle: "solid",
          borderColor: "rgba(255,255,255,0.3)",
        }
      }}
    >
      <DSPanel style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: UIState.dockHorizontal ? 'row' : 'column'
      }} >
        <DSPanel style={{
          height: UIState.dockHorizontal ? '100%' : size / 1.3,
          width: UIState.dockHorizontal ? size / 1.3 : '100%',
          alignItems: 'center',
          justifyContent: 'center',
          aspectRatio: '1'
        }}
          data-tooltip-position={tipPosition}
          data-tooltip={props['data-tooltip']}
          data-tooltip-for={props['data-tooltip-for']}
        >

          {
            icon ?
              icon :
              isEmoji ?
                <span
                  style={{
                    fontSize
                  }}>
                  {symbol}
                </span>
                :
                <DSH2
                  style={{
                    fontSize,
                    color: DSTheme.ColorContrastColor
                  }}>
                  {symbol}
                </DSH2>
          }
        </DSPanel>
        {props.children}
      </DSPanel>
      <DSPanel style={{ position: 'absolute', left: UIState.dockSize * 0.08, top: UIState.dockSize * 0.03 }}>
        <DSBadgeView
          badgeNum={badgeNum}
          badgeOverride={badgeOverride}
          scaleToDock
        />
      </DSPanel>
    </DSPanel >
  )
})

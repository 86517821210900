import React, { useState } from "react";
import { IMessageFile,  } from '@openteam/models';
import { DSColumn, DSPanel } from "../../DesignSystem";
import { DSButton } from "../../DesignSystem/DSButton";
import { FaCheck, FaCheckCircle, FaChevronDown, FaChevronUp, FaFile, FaTimesCircle } from "react-icons/fa";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSImage } from "../../DesignSystem/DSImage";
import { DSProgressBar } from "../../DesignSystem/DSProgressBar";
import { observer } from "mobx-react";
import { DSCancelRequest, DSTrash } from "../../DesignSystem/DSIconButtons";
import { DSH5 } from "../../DesignSystem/DSText";
import { ChatFile } from "./ChatFile";


type Props = {
  file: IMessageFile
  onRemove?: () => void
  textColor?: string
  size?: number
}

export const ChatFileUpload: React.FC<Props> = observer((props) => {

  const uploadFile = props.file

  const previewURL = uploadFile.downloadUrl || uploadFile.getPreviewURL()

  return <ChatFile
    key={`compose-file-${uploadFile.file.name}`}
    file={uploadFile.file}
    uploading={!uploadFile.completed}
    progress={uploadFile.progress}
    url={previewURL}
    size={props.size || 50}
    previewUrl={previewURL}
    onRemove={props.onRemove}

  />

  return (
    <DSColumn style={{
      position: 'relative',
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      maxWidth: props.size || 60
    }}>
      <DSPanel style={{
        position: 'relative',
        borderRadius: DSTheme.BaseBorderRadius,
        overflow: 'hidden'
      }}>
      {
        uploadFile.file.type.includes("image") ?
          <DSImage
            url={previewURL}
            maxHeight={props.size || 60}
              maxWidth={props.size || 60}
              dummyColor="transparent"
          />
          :
          <DSColumn
            style={{
              height: props.size || 60,
              width: props.size || 60,
              borderRadius: DSTheme.BaseBorderRadius,
              backgroundColor: DSTheme.DummyColor,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
              <FaFile size={30} style={{ color: DSTheme.MainIcon }} />
          </DSColumn>
        }

        {
          !uploadFile.completed ?
            <DSPanel style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
              <DSProgressBar progress={uploadFile.progress} height={8} />
            </DSPanel>
            :
            <DSPanel style={{ position: 'absolute', bottom: 0, left: 0, right: 0, top: 0, alignItems: 'center', justifyContent: 'center' }}>
              <FaCheckCircle size={20} style={{ color: DSTheme.SuccessColor, backgroundColor: 'white', borderRadius: "50%" }} />
            </DSPanel>
        }

      </DSPanel>

      {
        props.onRemove ?
          <DSCancelRequest
            size={10}
            analyticsEvent="cancelChatFileUpload"
            onClick={props.onRemove}
            outerStyle={{ position: 'absolute', right: 0, top: 0 }}
            style={{
              backgroundColor: DSTheme.DangerColor,
              width: undefined,
              padding: 4,
            }}
          />
          :
          null
      }
      <DSH5 style={{
        marginTop: 2,
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: props.textColor ?? DSTheme.InputTextColor
      }}>
        {uploadFile.file.name}
      </DSH5>
    </DSColumn>

  )
})

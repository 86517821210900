import { Logger } from "@openteam/app-util";
import { ILinkPreview } from "@openteam/models";
const logger = new Logger(`LinkPreview`)
export async function getLinkPreview(url): Promise<ILinkPreview | null> {
    try {
        const preview: any = await window.Main.getLinkPreview(url);

        const linkPreview: ILinkPreview = {
            mediaType: preview?.mediaType || null,
            contentType: preview?.contentType || null,
            title: preview?.title || null,
            originalName: preview?.title || null,
            description: preview?.description || null,
            image: preview?.image ||  preview?.images?.[0] ||null,
            favicons: preview?.favicons || null,
            favicon: preview?.favicons[0] || null,
            url: url,
        }
        return linkPreview;

    } catch (err) {
        logger.info(`Error generating link preview ${err}, (${url})`)
        return null
    }
}
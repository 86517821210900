
import { Row, styled, useTheme, darken } from '@openteam/design-system';
import { useCommands, useActive } from '@remirror/react';
import React, { useCallback } from 'react';
import { FaBold, FaCode, FaItalic, FaQuoteRight, FaStrikethrough } from 'react-icons/fa';
import { DS2ToggleButton, DS2ToggleButtonGroup } from '../../DesignSystem';

const ChatFormattingMenu: React.FC = () => {
  const theme = useTheme();
  const {
    toggleBold,
    toggleItalic,
    toggleStrike,
    toggleCode,
    focus,
    toggleCodeBlock,
    toggleBlockquote,
    //toggleBulletList,
    //toggleOrderedList
  } = useCommands();
  const active = useActive();

  const handleChange = useCallback(
    (_, value) => {
      if (value == 'bold') {
        toggleBold();
      } else if (value == 'italic') {
        toggleItalic();
      } else if (value == 'strike') {
        toggleStrike();
      } else if (value == 'code') {
        toggleCode();
      } else if (value == 'quote') {
        toggleBlockquote();
      } else if (value == 'codeblock') {
        toggleCodeBlock({});
      }
      focus();
    },
    [toggleBold, toggleItalic, toggleStrike, toggleCode, toggleCodeBlock, toggleBlockquote]
  );

  return (
    <Row style={{ background: darken(theme.palette.secondary.main, 0.05) }}>
      <Row style={{ flex: 1, alignItems: 'center', paddingLeft: 0 }}>
        <FormatGroup size="small" color="secondary" onChange={handleChange}>
          <FormatButton data-tooltip="Bold [^b]" data-tooltip-position="top" value="bold" selected={active.bold()}>
            <FaBold />
          </FormatButton>
          <FormatButton
            data-tooltip="Italic [^i]"
            data-tooltip-position="top"
            value="italic"
            selected={active.italic()}
          >
            <FaItalic />
          </FormatButton>
          <FormatButton
            data-tooltip="Strikethrough [^d]"
            data-tooltip-position="top"
            value="strike"
            selected={active.strike()}
          >
            <FaStrikethrough />
          </FormatButton>
          <FormatButton data-tooltip="Code [^`]" data-tooltip-position="top" value="code" selected={active.code()}>
            <FaCode />
          </FormatButton>
          <FormatButton
            data-tooltip="Quote [>]"
            data-tooltip-position="top"
            value="quote"
            selected={active.blockquote()}
          >
            <FaQuoteRight />
          </FormatButton>
          <FormatButton
            data-tooltip="Codeblock [```]"
            data-tooltip-position="top"
            value="codeblock"
            selected={active.codeBlock()}
          >
            {'{ }'}
          </FormatButton>
        </FormatGroup>
      </Row>
    </Row>
  );
};

export default ChatFormattingMenu;

const FormatGroup = styled(DS2ToggleButtonGroup)`
  padding: 0px;
`;

const FormatButton = styled(DS2ToggleButton)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 14,
  fontFamily: theme.typography.fontFamily,
  padding: '4px 7px',
  border: 'none',

  '&:hover': {
    backgroundColor: theme.palette.secondary.light
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  }
}));

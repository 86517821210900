import { Logger } from "@openteam/app-util";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { UIState } from "../../Data/UIState";
import { DSTheme } from "../../DesignSystem/DSTheme"

const logger = new Logger("DockCallIndicator")

interface DockIndicatorProps {
  tileSize: number
  show: boolean
  color: string
  height: number
  atTop?: boolean
  animate?: boolean
}

export const DockIndicator: React.FC<DockIndicatorProps> = observer(
  ({ tileSize, show: _show, color, height, atTop = false, animate = false }) => {

    const offset = 0;
    const show = _show && UIState.dockShown === false;

    const edgeSize = Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)
    const atTopOffset = (tileSize - height) / 2 + Math.round(UIState.dockSize * DSTheme.DockMargin)

    return (
      <div
        style={{
          display: "flex",
          position: "absolute",
          alignItems: atTop ? undefined : "center",
          justifyContent: "center",
          top: UIState.dockHorizontal ? UIState.dockPosition.onTop ? undefined : offset : atTop ? atTopOffset : 0,
          bottom: UIState.dockHorizontal ? UIState.dockPosition.onTop ? offset : undefined : 0,
          left: UIState.dockHorizontal ? atTop ? atTopOffset : undefined : UIState.dockPosition.onLeft ? undefined : offset,
          right: UIState.dockHorizontal ? undefined : UIState.dockPosition.onLeft ? offset : undefined,
          opacity: 0.75
        }}
      >
        <div
          style={{
            width: UIState.dockHorizontal ? height : edgeSize,
            height: UIState.dockHorizontal ? edgeSize : height,
            backgroundColor: color,
            borderRadius: 3,
            opacity: show ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
            ...animate && { animation: "pulse 2.5s infinite" }
          }}
        />
      </div>
    );
  }
);


export const DockCallIndicator = (props: Omit<DockIndicatorProps, "show">) => (
  <DockIndicator show={true} {...props} />
)

export const DockChatMessageIndicator = observer((props: Omit<DockIndicatorProps, "color" | "height">) => (
  <DockIndicator atTop={true} color={DSTheme.BadgeColor} height={30} {...props} />
))
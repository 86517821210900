import { Logger } from "@openteam/app-util";
import React from "react";
import { DataState } from "../../Data/DataState";
import { DSColumn, DSRow } from "../../DesignSystem";
import { DSH3, DSPrint } from "../../DesignSystem/DSText";
import StatusDot from "./StatusDot";
import { getUserCustomStatusEmoji, getUserCustomStatusText, getUserStatus } from "./StatusUtil";

const logger = new Logger('getUserTooltip')

export default function getUserTooltip (key: string) {
  const [spaceId, userId] = key.split(':')

  if (!(spaceId && userId)) {
    logger.error(`Bad tooltip value ${key}, missing spaceId ${spaceId} or userId ${userId}`)
    return key;
  }

  const space = DataState.spaces[spaceId];
  const user = space.users[userId];
  //logger.debug(`tooltip spaceId: ${spaceId}, userid: ${userId}`)

  const customStatus = [
    getUserCustomStatusEmoji(user),
    getUserCustomStatusText(user),
  ].filter(x => !!x) as string[];

  //logger.debug("meetingStatus: ", user.status?.meetingStatus);
  return (
    <DSColumn spacing={4}>
      <DSRow spacing={8}>
        <DSH3> {user.name} </DSH3>
      </DSRow>
      <DSRow spacing={2} style={{ alignItems: 'center' }}>
        <StatusDot
          user={user}
          showCustomStatusEmoji={false}
          size={10}
        />
        <DSPrint>
          {getUserStatus(user)}
          {customStatus.length ? (` -  ${customStatus.join(' ')}`) : null}
        </DSPrint>
      </DSRow>
    </DSColumn>
  )
}

import React  from 'react';
import { IPluginResource } from '@openteam/models';
import { IPluginConfig, PluginManager } from '@openteam/app-core';
import { FaLink } from 'react-icons/fa';


interface IShareLinkPluginDetails extends IPluginResource {
  args: IShareLinkArgs
}

interface IShareLinkProps {
  pluginManager: PluginManager
  pluginDetails: IShareLinkPluginDetails
  height: number
  width: number
}
interface IShareLinkState { }

interface IShareLinkArgs {
  url: string,
}

export const pluginType = 'link'
export const pluginConfig: IPluginConfig = {
  name: 'Share Link',
  multi: true,
  icon: props => <FaLink {...props} />,
  iconColour: 'white',
  webInline: false,
  canHandleUrl: url => { return { url } },
  urlPriority: 0
}
import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { KSpaceId, KSpaceChannelId, KSpaceUserId } from '@openteam/models';
import { DSColumn, DSHSpacer, DSPanel, DSRow } from '../../DesignSystem';
import { DSBody, DSH1, DSH2, DSH3, DSH4, DSPrint, DSSmallPrint } from '../../DesignSystem/DSText';
import { DataState } from '../../Data/DataState';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { getStringBackground } from '../../Util/StringColor';
import { isMacOs } from 'react-device-detect';
import { FaUser } from 'react-icons/fa';
import { ChatUserList } from '../Chat/ChatUserList';
import UserIcon from '../User/UserIcon';
import { getUserStatus } from '../User/StatusUtil';
import { addToRecentUsers, UIState } from '../../Data/UIState';
import UserIconList from '../../Util/UserIconList';
import { timeSince } from '../../Util/DateFormat';
import { RoomIcon } from './RoomIcon';
import { PodSettingsWindow } from '../Pod/PodSettingsWindow';
import { CamIcon } from '../CamIcon';
import { WindowControls } from '../WindowControls';
import { DS2CallButton, DS2CancelCallButton, DS2IconButton, DS2KnockButton, DS2LeaveCallButton, DS2NotifyButton, DS2PinButton, DS2SettingsButton } from '../../DesignSystem/DS2';
import { Logger } from '@openteam/app-util';
import { DSSeparatorVertical } from '../../DesignSystem/DSSeparator';
import RoomCallButton from './RoomCallButton';
import UserCallButton from '../User/UserCallButton';

const logger = new Logger('RoomHeader')

type Props = {
  spaceId: KSpaceId;
  channelId: KSpaceChannelId;
  parentId: string
  callId?: string
  inCall: boolean;
  userId?: KSpaceUserId;
}

const RoomHeader: React.FC<Props> = (props) => {
  const { spaceId, channelId, callId, inCall, userId } = props;

  const [showSettings, setShowSettings] = useState<boolean>(false);

  const space = DataState.spaces[spaceId]
  const chat = space.channels[channelId]
  const pod = space.pods[channelId]
  const user = userId ? space.users[userId] : undefined
  const call = callId ? space.calls[callId] : undefined

  const isOnline = user?.status?.online
  let pinned: boolean | undefined = undefined
  let togglePinned: (() => void) | undefined = undefined

  if (user) {
    pinned = user.pinned
    togglePinned = user.actions.togglePinned
  } else if (pod) {
    pinned = pod.pinned
    togglePinned = () => {
      logger.debug(`Setting ${pod.name} to ${pinned ? 'unpinned' : 'pinned'}`)
      pod.actions.setPinned(!pinned)
    }
  }

  const userListRef = React.useRef<HTMLDivElement>(null)
  const [showUsersList, setShowUsersList] = useState<boolean>(false);

  const tileSize = 36

  let background: string = DSTheme.ChatHeaderColor
  let color: string = DSTheme.PanelContrastColor

  if (pod) {
    background = getStringBackground(pod.name)
    color = DSTheme.ColorContrastColor
  } else if (call) {
    background = DSTheme.DockCallBackgroundColor
    color = DSTheme.ColorContrastColor
  }

  const knockEnabled = (isOnline && !user.status?.inCall);

  return (
    <DSRow style={{
      ...Styles.header,
      height: 50,
      background: background,
      alignItems: 'center',
      maxWidth: "100vw",
      overflow: 'hidden'
    }}>

      {
        user ?
          <>
            <UserIcon
              user={user}
              size={tileSize}
              stopPropagation={{onPointerDown: false}}
            />
            <DSHSpacer size={10} />
            <DSColumn style={{
              overflow: 'hidden',
            }}>
              <DSH3 style={{
                color: color,
                whiteSpace: 'nowrap',
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}>
                {user.name} {user.team ? "|" : null} <DSBody>{user.team}</DSBody>
              </DSH3>
              <DSPrint style={{
                color: color,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
                {
                  user.status?.customStatusEmoji ?
                    <DSPrint style={{ marginRight: 6 }}>
                      {user.status?.customStatusEmoji}
                    </DSPrint>
                    :
                    undefined
                }
                {getUserStatus(user)}
              </DSPrint>
            </DSColumn>
          </>
          :
          <>
            <RoomIcon
              spaceId={spaceId}
              podId={channelId}
              size={28}
              showBorder={true}
            />
            <DSHSpacer size={8} />
            <DSH3 style={{
              color: color,
              whiteSpace: 'nowrap',
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center",
            }}>
              {pod ? pod.name : call ? call.name : "Room"}</DSH3>
            <DSHSpacer size={8} />
            {pod ?
              <>
                <div ref={userListRef}>
                  <DS2IconButton
                    data-tooltip="Room members"
                    onClick={() => setShowUsersList(true)}
                  >
                    <DSH4 style={{ marginRight: 2 }}>{pod?.numUsers ?? ''}</DSH4>
                    <FaUser size={14} />
                  </DS2IconButton>
                </div>

                <ChatUserList
                  parentId={props.parentId}
                  targetRef={userListRef}
                  spaceId={spaceId}
                  channelId={channelId}
                  show={showUsersList}
                  onClose={() => {
                    setShowUsersList(false)
                  }}
                />
              </> :
              undefined
            }

          </>
      }
      <DSRow style={{ flex: 1, justifyContent: 'center' }}>
        {
          callId && !inCall ?
            <RoomHeaderCall spaceId={spaceId} callId={callId} inCall={inCall} />
            :
            undefined
        }
      </DSRow>
      <DSRow style={{ alignItems: 'center', flex: 1, justifyContent: 'flex-end'}}>
        {
          user ?
              (!user.status?.inCall ?
                <>
                  <DSHSpacer size={10} />
                  <UserCallButton spaceId={spaceId} userId={user.id} analyticsPrefix='RoomHeader'/>
                  {/* user.outgoing?.calling ?
                    <DS2CancelCallButton
                      onClick={() => user.actions.callUser()} />
                    :
                    <DS2CallButton
                      data-tooltip={`Call ${user.name}`}
                      key={`call-${props.userId}`}
                      disabled={!isOnline}
                      onClick={() => {
                        if (user.alerts?.called?.count && user.alerts?.called?.count > 0) {
                          user.alerts.clear()
                        } else {
                          user.actions.callUser();
                        }
                        addToRecentUsers(props.spaceId, user.id)
                      }}
                      badgeNum={user.alerts?.called?.count}
                      style={{
                        ...(user.alerts?.called?.count) ? { backgroundColor: DSTheme.EmphasisColor } : {}
                      }}
                    />
                  */}

                </>
                : null
              )
            :
            <>
              <DSHSpacer size={10} />
              <RoomCallButton spaceId={spaceId} podId={channelId} callId={callId} analyticsPrefix='RoomHeader'/>
            </>
        }


        <DSHSpacer size={16} />
        <DSSeparatorVertical style={{ height: 30, backgroundColor: DSTheme.MainText, opacity: 0.5 }} />
        {
          togglePinned ?
            <>
              <DSHSpacer size={8} />
              <DS2PinButton
                data-tooltip={pinned ? "Unpin from dock" : "Pin to dock"}
                onClick={togglePinned}
                pinned={pinned}
              />
            </>
            :
            undefined
        }

        {
          chat ? <>
            <DSHSpacer size={8} />
            <DS2NotifyButton
              data-tooltip={chat.muteNotify ? "Enable Notifications" : "Mute Notifications"}
              enabled={chat.muteNotify}
              onClick={() => chat.actions.muteNotify(!chat.muteNotify)}
            />
          </>
            :
            undefined
        }

        {
          pod ? <>
            <DSHSpacer size={8} />

            <DS2SettingsButton
              data-tooltip='Room Settings'
              analyticsEvent='RoomHeaderSettings'
              analyticsArgs={{ podId: props.channelId, callId: props.callId }}
              onClick={() => setShowSettings(true)}
            />

            {showSettings ? (
              <PodSettingsWindow
                parentId={props.parentId}
                spaceId={props.spaceId}
                podId={props.channelId}
                onClose={() => {
                  setShowSettings(false);
                }}
              />
            ) : null}
          </>
            :
            undefined
        }

      </DSRow>

      {!isMacOs && (togglePinned || chat || pod) ?
        <>
          <DSHSpacer size={8} />
          <DSSeparatorVertical style={{ height: 30, backgroundColor: DSTheme.MainText, opacity: 0.5 }} />
        </> : null

      }

      {
        !isMacOs ?  <WindowControls parentId={props.parentId} /> : undefined
      }

    </DSRow>
  )
};

interface IRoomHeaderCall {
  spaceId: KSpaceId;
  callId: string
  inCall: boolean;
}

export const RoomHeaderCall: React.FC<IRoomHeaderCall> = observer((props) => {
  const space = DataState.spaces[props.spaceId];
  const call = space.calls[props.callId]

  return (
    <DSPanel style={{
      borderRadius: 8,
      padding: 5,
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: 'rgba(0,0,0,0.5)'
    }}>
      <DSRow style={{ alignItems: 'center' }}>
        <CamIcon
          style={{
            border: `1px solid rgba(0,0,0,0.2)`,
          }}
        />
        <DSHSpacer size={12} />
        <UserIconList
          spaceId={props.spaceId}
          users={Object.values(call.participants)}
          overlap={5}
          tileSize={30}
          wrap={false} />
        <DSHSpacer size={12} />

        {
          call.crDate ?
            <DSSmallPrint
              style={{
                color: DSTheme.ColorContrastColor,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Started {timeSince(new Date(call.crDate), " ago")}
            </DSSmallPrint>
            :
            undefined
        }
        <DSHSpacer size={12} />
        <DS2CallButton onClick={call.actions.joinCall} title="Enter" />


      </DSRow>
    </DSPanel>
  )
})

const Styles = {
  header: {
    paddingLeft: isMacOs ? 76 : 24,
    paddingRight: 8,
    alignItems: "center",
  }
}

export default observer(RoomHeader);

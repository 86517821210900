import { DeviceType, IUIDeviceTypeSettings } from "@openteam/models";
import { setCallWidgetHovered } from "@openteam/app-core";
import { DataState } from "../../../Data/DataState";
import { IDSButtonProps } from "../../../DesignSystem/DSButton";
import { MenuTemplate, openMenu } from "../../Menu";

export interface ButtonProps extends IDSButtonProps {
  size?: number;
  color?: string;
}

export function openCallMenu(template: MenuTemplate, onClose?: () => void) {
  openMenu(template, () => {
    setCallWidgetHovered("menu", false)
    onClose && onClose();
  });

  setCallWidgetHovered("menu", true)
}

export function getDeviceOptions(type: DeviceType): Electron.MenuItemConstructorOptions[] {
  const deviceSettings: IUIDeviceTypeSettings = DataState.deviceSettings[type]!;

  const selectDevice = (deviceId: string) => {
    DataState.activeCall?.actions.updateDeviceSettings(type, { deviceId });
  };

  return deviceSettings.availableDevices.map((device) => ({
    label: device.label ?? "Unnamed device",
    type: "radio",
    checked: device.deviceId === deviceSettings.activeDeviceId,
    click: () => selectDevice(device.deviceId),
  }));
}

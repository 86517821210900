
import { OTGlobals } from "@openteam/app-core";
import { action } from "mobx";
import { Logger } from '@openteam/app-util';
import { observer } from "mobx-react";
import React, { CSSProperties, useCallback, useRef, useState } from "react";
import { FaChevronDown, FaRegSmile, FaTimes } from "react-icons/fa";
import { DataState } from "../../../Data/DataState";
import { DSButton, DSCircleIconButton, DSFilledButton, DSLongButton } from "../../../DesignSystem/DSButton";
import { DSInput } from "../../../DesignSystem/DSInput";
import { DSColumn, DSHSpacer, DSPanel, DSRow, DSVSpacer } from "../../../DesignSystem";
import { DSBody, DSH2, DSH4, DSPrint, DSSmallPrint, DSText } from "../../../DesignSystem/DSText";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { DSImage } from "../../../DesignSystem/DSImage";
import { DS2Button } from "../../../DesignSystem/DS2";
const logger = new Logger('ShareResourcePicker');

interface Props {
}

const shareLogos = {
  'trello': {
    url: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Ftrello.png?alt=media&token=28b5a4b3-7210-4c3b-bf92-08acd0bac81a"
  },


  'googledrive': {
    url: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fgoogledrive.png?alt=media&token=162127b7-d73c-43ca-b47f-5ec5692afabb"
  },
  'figma': {
    url: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Ffigma.png?alt=media&token=2be5e7b6-9b22-4025-8316-6079e496a1b4"
  },
  'youtube': {
    url: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fyoutube.png?alt=media&token=1752b656-9c19-4050-aeac-56469c1750ec"
  },
  'vscode': {
    url: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fvscode.png?alt=media&token=d5f6c628-aed6-43a7-b81e-bb186e7c1287"
  },
  'miro': {
    url: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fmiro.png?alt=media&token=fe6208c7-2314-4c14-b984-b805f2977886"
  },
}

export const ShareResourcePicker: React.FC<Props> = observer((props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [inputData, setInputData] = useState('');
  const call = DataState.activeCall;
  const openPlugins = Object.keys(call?.plugins ?? {})
  const sendChatMessage = useCallback(
    (message) => {
      /*       if (message && call?.actions.sendURL) {
              call?.actions.sendURL(message);
            }
       */
      if (message && call?.callMessageManager.sendChatMessage) {
        call?.callMessageManager.sendChatMessage(message, [], undefined, undefined, true);
      }
      setInputData("");
    },
    [setInputData, call?.actions]
  );

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event): void => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      // @ts-ignore -- TS doesn't think 'value' exists
      sendChatMessage(event.target.value);
    }
  };

  return (
    <DSPanel style={{
      backgroundColor: DSTheme.PanelDarkerColor,
      padding: "12 16",
      borderRadius: 8,
      width: 240,
      minHeight: "calc(100vh - 14px - 14px)",
      justifyContent: 'flex-start'
    }}>

      <DSRow
        style={{
          justifyContent: "space-between",
          padding: 0,
          minHeight: 30,
        }}
      >
        <DSH2 style={{ cursor: "inherit" }}>Tools</DSH2>

      </DSRow>

      <DSRow style={{ alignItems: 'center' }}>

        <DSInput
          placeholder="Paste link here"
          value={inputData}
          onChange={(event) => setInputData(event.target.value)}
          onKeyDown={onKeyDown}
          outerStyle={{ borderRadius: 999 }}
          style={{ width: 150 }}
        >

        </DSInput>
        <DSHSpacer size={4} />
        <DS2Button
          onClick={() => sendChatMessage(inputData)}
        >Open</DS2Button>
      </DSRow>
      <DSVSpacer size={8} />
      {
        openPlugins.length === 0 ? <DSPanel style={{ flex: 1, alignItems: 'center', paddingTop: 50 }}>
          <DSH4 wrap style={{ width: 100, textAlign: 'center' }}>
            Directly share media, links and files from here
            {<br />}
            {<br />}
            Integrations will automatically be used when available
          </DSH4>
        </DSPanel> :
          <DSColumn style={{ flex: 1 }}>

            {openPlugins.map((pluginId) => (
              <OpenResource key={pluginId} pluginId={pluginId} />
            ))}

          </DSColumn>
      }
      <DSH4 style={{ marginBottom: 10 }}>Works with:</DSH4>
      <DSRow style={{ alignItems: 'center', justifyContent: 'center' }}>
        {Object.keys(shareLogos).map(key =>
          <DSImage
            key={key}
            url={shareLogos[key].url}
            height={24}
            width={24}
            dummyColor="transparent"
            borderRadius={2}
            style={{ margin: "0 4" }} />
        )}
      </DSRow>
    </DSPanel>

  );
});



const OpenResource: React.FC<{ pluginId: string }> = observer(({ pluginId }) => {
  const plugin = DataState.activeCall!.plugins[pluginId];
  const pluginConfig = plugin && OTGlobals.pluginConfigList[plugin.pluginType];
  const poppedOut = DataState.activeCall?.pluginPopouts[pluginId] === true;


  const popOut = React.useCallback(
    action((popout) => {
      if (DataState.activeCall) {
        if (popout) {
          logger.debug(`Popping out`);
          DataState.activeCall.pluginPopouts[pluginId] = true;
        } else {
          logger.debug(`Popping in`);
          delete DataState.activeCall?.pluginPopouts[pluginId];
        }
      }
    }),
    [DataState.activeCall?.pluginPopouts]
  );

  return pluginConfig ? (
    <DSRow style={{ justifyContent: 'space-between', margin: "4 0", alignItems: 'flex-start' }}>
      <DSButton
        key={plugin.pluginId}
        onClick={popOut}
        style={{ margin: 4 }}
      >
        {plugin.linkPreview?.favicon ? (
          <DSImage height={20} width={20} url={plugin.linkPreview?.favicon} style={{ borderRadius: 4 }} dummyColor={"transparent"} />
        ) : (
          pluginConfig?.icon({ color: pluginConfig.iconColour, size: 20 })
        )}
        <DSColumn>
          <DSPrint wrap style={{ marginLeft: 12 }}>{plugin.linkPreview?.title || pluginConfig.name}</DSPrint>
          <DSSmallPrint>

          </DSSmallPrint>
        </DSColumn>

      </DSButton>
      <DSHSpacer size={6} />
      <DSCircleIconButton
        style={{
          padding: 4,
          marginTop: 2,
        }}
        icon={<FaTimes size={12} style={{ color: DSTheme.MainIcon }} />}
        onClick={() => DataState.activeCall?.pluginCallbacks.closePlugin(pluginId)}
      />
    </DSRow>
  ) : null;
});

import React, { useEffect, useImperativeHandle, useMemo } from "react";
import ReactDOM from "react-dom";

import { Logger } from "@openteam/app-util";
import { useCallback } from "react";
import { UIState } from "../Data/UIState";
import { ErrorBoundary } from "@sentry/react";
import { isMacOs } from "react-device-detect";
import createCache, { EmotionCache, Options as CacheOptions } from '@emotion/cache'
import { CacheProvider } from "@emotion/react";
import { observable, reaction, toJS } from "mobx";
import { ThemeState } from "../DesignSystem/DSTheme";

const logger = new Logger("SubWindow");


export interface ISubWindowProps {
  id: string;
  windowType?: string;
  parentId?: string;
  title?: string;
  height?: number;
  width?: number;
  x?: number;
  y?: number;
  show?: boolean;
  focus?: boolean;
  initialPosition?: { x?: number, y?: number }
  initialSize?: { width: number, height: number }
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  aspectRatio?: number;
  onResize?: (IBounds: any) => void;
  onMove?: (IBounds: any) => void;
  onCreated?: () => void;
  onClose?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onShow?: () => void;
  onHide?: () => void;
  onRestore?: () => void;
  onMinimize?: () => void;
  onMaximize?: () => void;
  onUnMaximize?: () => void;
  closeOnBlur?: boolean;
  closeOnError?: boolean
  eventListeners?: Record<string, () => void>;
  documentListeners?: Record<string, () => void>;
  protectContent?: boolean;
  alwaysOnTopLevel?: string;
  visibleOnAllWorkspaces?: boolean;
  preservePosition?: boolean;
  ignoreMouseEvents?: boolean;
  options?: Electron.BrowserWindowConstructorOptions
}

export interface IBounds {
  x?: number;
  y?: number;
  width: number;
  height: number;
}

const BOUNDS_FIELDS = ["x", "y", "width", "height"];

export interface SubWindowHandle {
  setBounds: (bounds: Partial<IBounds>) => void
  getBounds: () => Promise<IBounds>
  //setAspectRatio: (aspectRatio: number) => void
  setMinimumSize: (width: number, height: number) => void
  setMaximumSize: (width: number, height: number) => void
  configureWindow: (config: WindowConfig) => void
  show: (show: boolean, focus?: boolean) => void
}

type WindowConfig = {
  minimumSize?: { width: number; height: number };
  maximumSize?: { width: number; height: number };
  bounds?: Partial<Electron.Rectangle>;
  aspectRatio?: number
};


export const SubWindow = React.forwardRef<SubWindowHandle, React.PropsWithChildren<ISubWindowProps>>((props, ref) => {
  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const nativeWindowObject = React.useRef<Window | null>(null);
  const creatingWindow = React.useRef<boolean>(false);
  const bounds = React.useRef<IBounds | null>(null);
  const prevProps = React.useRef<ISubWindowProps | null>(null);
  const minSize = React.useRef<Partial<IBounds>>({ width: undefined, height: undefined });
  const maxSize = React.useRef<Partial<IBounds>>({ width: undefined, height: undefined });
  const aspectRatio = React.useRef<number | undefined>(undefined);
  const mounted = React.useRef<boolean>(false);


  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      logger.info(`Unmounted window ${props.id}`)
    }
  }, [props.id])

  const createWindow = useCallback(async () => {

    if (creatingWindow.current) {
      return
    } else {
      creatingWindow.current = true
    }

    const { id, windowType: _windowType, title, x, y, width: _width, height: _height, initialPosition, initialSize, options } = props;
    const windowType = _windowType ?? id;
    let left = initialPosition?.x ?? x;
    let top = initialPosition?.y ?? y;
    const width = initialSize?.width ?? _width;
    const height = initialSize?.height ?? _height;

    if (left === undefined && top === undefined && width && height) {
      const workArea = await window.Main.getWindowWorkArea(props.parentId ?? "__dock__");
      if (workArea) {
        left = workArea.x + (workArea.width - width!) / 2;
        top = workArea.y + (workArea.height - height!) / 2;
      }
    }

    let optionParams = {
      __openteam_frameName: id,
      __openteam_parentName: props.parentId,
      __openteam_windowType: windowType,
      __openteam_protectContent: props.protectContent,
      __openteam_alwaysOnTopLevel: props.alwaysOnTopLevel,
      __openteam_visibleOnAllWorkspaces: props.visibleOnAllWorkspaces,
      __openteam_ignoreMouseEvents: props.ignoreMouseEvents,
      title: title ?? UIState.productName,
      left,
      top,
      width,
      height,
    }

    if (props.preservePosition === true && bounds.current) {
      optionParams.left = bounds.current.x
      optionParams.top = bounds.current.y
      optionParams.width = bounds.current.width
      optionParams.height = bounds.current.height
    }

    if (windowType && options && window.Main.registerWindowOptions) {
      await window.Main.registerWindowOptions(windowType, toJS(options))
    } else if (options) {
      optionParams = {
        ...optionParams,
        ...options
      }
    }

    const optionStr = Object.entries(optionParams).map(entry => entry.join('=')).join(',')

    if (mounted.current) {

      logger.debug(`${id} creating ${optionStr}`);

      const newWindow = window.open(undefined, undefined, optionStr)

      // logger.debug(`${id}.newWindow`, newWindow, options);
      bounds.current = { x, y, width: optionParams.width ?? 100, height: optionParams.height ?? 100 };

      if (props.eventListeners) {
        Object.entries(props.eventListeners).forEach(([event, listener]) => {
          newWindow?.addEventListener(event, listener)
        })
      }

      if (props.documentListeners) {
        Object.entries(props.documentListeners).forEach(([event, listener]) => {
          newWindow?.document.addEventListener(event, listener)
        })
      }

      newWindow && injectGlobalStyle(newWindow);

      nativeWindowObject.current = newWindow;
      forceUpdate()
    }

    creatingWindow.current = false

  }, [props])


  useEffect(() => {
    // createWindow()

    return () => {
      logger.debug(`${props.id} unloading...`);
      window.Main.closeWindow(props.id);
    };
  }, []);

  useEffect(() => {
    if (props.show && !nativeWindowObject.current && !creatingWindow.current) {
      logger.debug(`Recreating window ${props.id}`);
      createWindow();
    }
  }, [props.show, nativeWindowObject.current, creatingWindow.current])


  const _onClosed = useCallback(() => {
    logger.debug(`${props.id} closing`);

    if (nativeWindowObject.current && props.eventListeners) {
      Object.entries(props.eventListeners).forEach(([event, listener]) => {
        nativeWindowObject.current?.removeEventListener(event, listener);
      });
    }

    window.Main.closeWindow(props.id);
    nativeWindowObject.current = null;

    //toolTips.stop()

    props.onClose && props.onClose();

  }, [props.onClose, nativeWindowObject.current, props.id]);

  const _onCreated = useCallback(() => {
    // if onCreated exists in needs to trigger a re-render to show the content
    // else we do it ourselves

    props.onCreated ? props.onCreated() : forceUpdate();

    //if (nativeWindowObject.current) {
    //  toolTips.setBody(nativeWindowObject.current.document.body as HTMLBodyElement);
    //}

  }, [props.onCreated, props.show, props.id]);

  const _onResized = useCallback(
    ({ bounds: newBounds }) => {
      bounds.current = newBounds;
      setTimeout(() => props.onResize && props.onResize({ bounds: newBounds }), 0);
    },
    [props.onResize]
  );

  const _onMoved = useCallback(
    ({ bounds: newBounds }) => {
      if (newBounds?.width === 0 && newBounds?.height === 0) {
        logger.debug("got move event that makes window 0x0, ignoring.")
      } else {
        bounds.current = newBounds;
        setTimeout(() => props.onMove && props.onMove({ bounds: newBounds }), 0);
      }
    },
    [props.onMove]
  );

  const _onBlur = useCallback(() => {
    props.onBlur && props.onBlur()

    if (props.closeOnBlur) {
      window.Main.closeWindow(props.id)
    }

  }, [props.onBlur, props.closeOnBlur])

  const _onFocus = useCallback(() => {
    props.onFocus && props.onFocus()
  }, [props.onFocus])

  const _onShow = useCallback(() => {
    props.onShow ? props.onShow() : forceUpdate();
  }, [props.onShow])

  const _onHide = useCallback(() => {
    props.onHide && props.onHide();
  }, [props.onHide])

  const onError = useCallback(() => {
    if (props.onClose && props.closeOnError !== false) {
      props.onClose()
    }
  }, [props.closeOnError, props.onClose])

  useEffect(() => {
    const id = props.id;

    if (nativeWindowObject.current) {
      const currentRef = nativeWindowObject.current;

      window.addEventListener("unload", _onClosed);
      const offResize = window.Main.on(`window-resized-${id}`, _onResized);
      const offMoved = window.Main.on(`window-moved-${id}`, _onMoved);
      const offCreated = window.Main.on(`window-created-${id}`, _onCreated);
      const offBlured = window.Main.on(`window-blurred-${id}`, _onBlur);
      const offFocused = window.Main.on(`window-focused-${id}`, _onFocus);
      const offShow = window.Main.on(`window-show-${id}`, _onShow);
      const offHide = window.Main.on(`window-hide-${id}`, _onHide);
      const offRestore = window.Main.on(`window-restore-${id}`, () => props.onRestore?.());
      const offMinimize = window.Main.on(`window-minimize-${id}`, () => props.onMinimize?.());
      const offMaximize = window.Main.on(`window-maximize-${id}`, () => props.onMaximize?.());
      const offUnMaximize = window.Main.on(`window-unmaximize-${id}`, () => props.onUnMaximize?.());

      currentRef.onunload = () => _onClosed();

      return () => {
        window.removeEventListener("unload", _onClosed);
        offResize();
        offMoved();
        offCreated();
        offBlured();
        offFocused();
        offShow();
        offHide();
        offRestore();
        offMinimize();
        offMaximize();
        offUnMaximize();
        currentRef.onunload = null;
      };
    }
  }, [nativeWindowObject.current, _onResized, _onMoved, _onCreated, _onClosed]);

  useEffect(() => {
    if (nativeWindowObject.current) {
      const config: any = {}

      let changed = BOUNDS_FIELDS.filter(
        (prop) => props[prop] !== undefined && props[prop] !== prevProps.current?.[prop]
      );

      if (changed.length) {
        config.bounds = Object.fromEntries(
          changed.map((prop) => [prop, Math.floor(props[prop])])
        );
      }

      if (props.minWidth !== minSize.current.width || props.minHeight !== minSize.current.height) {
        minSize.current = { width: props.minWidth, height: props.minHeight };
        logger.info(`minHeight : ${props.minHeight}`)
        config.minimumSize = minSize.current
        if ((config.bounds?.width || prevProps.current?.width) && config.minimumSize.width > (config.bounds?.width ?? prevProps.current?.width)) {
          if (!config.bounds) {
            config.bounds = {}
          }
          config.bounds.width = config.minimumSize.width
        }
        if ((config.bounds?.height || prevProps.current?.height) && config.minimumSize.height > (config.bounds?.height ?? prevProps.current?.height)) {
          if (!config.bounds) {
            config.bounds = {}
          }
          config.bounds.height = config.minimumSize.height
        }
      }

      if (props.maxWidth !== maxSize.current.width || props.maxHeight !== maxSize.current.height) {
        maxSize.current = { width: props.maxWidth, height: props.maxHeight };
        config.maximumSize = maxSize.current
      }

      if (props.aspectRatio !== aspectRatio.current) {
        aspectRatio.current = props.aspectRatio;
        config.aspectRatio = props.aspectRatio ?? null;
      }

      if (Object.keys(config).length) {
        logger.debug("configureWindow", props.id, config, "prev", prevProps.current)
        window.Main.configureWindow(props.id, config);
      }

      prevProps.current = props;
    }
  }, [props, bounds, minSize, aspectRatio, nativeWindowObject.current]);


  const showWindow = useCallback((show, focus) => {
    if (nativeWindowObject.current) {
      if (show == true) {
        logger.debug(`${props.id} showing ${focus ? 'focused' : 'unfocused'}`);
        window.Main.showWindow(props.id, focus == true);
      } else if (show == false) {
        logger.debug(`${props.id} hiding`);
        window.Main.hideWindow(props.id);
      }
    }
  }, [nativeWindowObject.current])


  useImperativeHandle(ref, () => ({
    setBounds: (bounds) => window.Main.setWindowBounds(props.id, bounds),
    getBounds: async () => await window.Main.getWindowBounds(props.id),
    //setAspectRatio: (aspectRatio) => window.Main.setWindowAspectRatioById(props.id, aspectRatio),
    setMinimumSize: (width, height) => window.Main.setWindowMinimumSize(props.id, width ?? 0, height ?? 0),
    setMaximumSize: (width, height) => window.Main.setWindowMaximumSize(props.id, width, height),
    configureWindow: (config) => window.Main.configureWindow(props.id, config),
    show: (show, focus = false) => showWindow(show, focus),
  }));


  useEffect(() => {
    if (nativeWindowObject.current) {
      window.Main.setWindowTitle(props.id, props.title ?? "Ehlo");
    }
  }, [nativeWindowObject.current, props.title]);

  useEffect(() => {
    showWindow(props.show, props.focus == true);

  }, [nativeWindowObject.current, props.show, props.focus]);

  if (nativeWindowObject.current && nativeWindowObject.current.document) {
    return (
      <ErrorBoundary onError={onError}>
        <div onPointerDown={e => e.stopPropagation()} onClick={e => e.stopPropagation()} onDoubleClick={e => e.stopPropagation()}>
          {ReactDOM.createPortal(
            <SubWindowContainer cacheKey={props.id ?? props.windowType} document={nativeWindowObject.current.document}>
              {props.children}
            </SubWindowContainer>,
            nativeWindowObject.current.document.body
          )}
        </div>
      </ErrorBoundary>
    )
  }
  return null;
});


type SubWindowContainerProps = {
  cacheKey: string
  document: Document
}

const SubWindowContainer: React.FC<SubWindowContainerProps> = ({ cacheKey, document, children }) => {

  const cache = useMemo(() => {
    return createCache({
      key: cacheKey.toLowerCase().replaceAll(/[0-9_]/g, ''),
      container: document.head
    })
  }, [document])

  return cache ? (
    <CacheProvider value={cache}>
      {children}
    </CacheProvider>
  ) : null;

}

function injectGlobalStyle (win: Window) {
  //logger.debug(`injecting global style`)
  Array.from(document.head.querySelectorAll('style[data-styled]'))
    .forEach(style => {
      logger.debug(`inserting stylesheet:`, style)
      win.document.head.appendChild(
        style.cloneNode(true)
      );
    });
}

export const SubWindowCSS = `
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Manrope:wght@300;400;500;600;700&display=swap');
html {
  font-size: 14px;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::placeholder {
  color: #C4C4C4;
}
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

.noScrollBar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.dark-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}

::-webkit-scrollbar-track-piece
{
   display:none;
}
.scrollbar-hidden::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
svg {
  outline: none;
}
@keyframes spinner {
  to {
    stroke-dashoffset: 0;
  }
}
`;

export const PopupOptions: Electron.BrowserWindowConstructorOptions = {
  transparent: isMacOs ? true : false,
  hasShadow: true,
  alwaysOnTop: true,
  backgroundColor: undefined,
  frame: false,
  useContentSize: true,
  resizable: false,
  closable: true,
  skipTaskbar: true,
  focusable: true,
  maximizable: false,
  fullscreenable: false,
  autoHideMenuBar: true,
  show: false,
  vibrancy: undefined,
  visualEffectState: undefined,
};


export const ModalOptions: Electron.BrowserWindowConstructorOptions = {
  ...PopupOptions,
  hasShadow: true,
  alwaysOnTop: false,
  modal: true,
};

export const ToolTipOptions: Electron.BrowserWindowConstructorOptions = {
  ...PopupOptions,
  transparent: true,
  hasShadow: false,
  focusable: false,
  roundedCorners: true,
};

export const PanelOptions: Electron.BrowserWindowConstructorOptions = observable({
  transparent: true,
  alwaysOnTop: true,
  frame: false,
  hasShadow: false,
  roundedCorners: true,
  useContentSize: true,
  resizable: false,
  skipTaskbar: true,
  focusable: true,
  maximizable: false,
  fullscreenable: false,
  show: false,
  closable: true,
  vibrancy: undefined,
  visualEffectState: 'active',
})

export const CallWindowOptions: Electron.BrowserWindowConstructorOptions = observable({
  transparent: true,
  hasShadow: false,
  alwaysOnTop: true,
  frame: false,
  useContentSize: true,
  closable: true,
  resizable: true,
  skipTaskbar: false,
  focusable: true,
  maximizable: true,
  minimizable: true,
  show: false,
  fullscreenable: true,
  vibrancy: undefined,
  visualEffectState: 'active',
})

reaction(
  () => (UIState.mainCapabilities.vibrancy && ThemeState.selectedTheme),
  (isVibrant) => {
    PanelOptions.vibrancy = isVibrant ? 'hud' : undefined
    CallWindowOptions.vibrancy = isVibrant ? 'hud' : undefined
    PopupOptions.vibrancy = isVibrant ? 'hud' : undefined
  }
)

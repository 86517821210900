import React, { useEffect } from "react";
import Call from "./Components/Call/Call";
import Call2 from "./Components/Call2/Call";
import { OTGlobals, OTUITree, OTAuth } from "@openteam/app-core";
import { Logger, initLoggerApp } from "@openteam/app-util";
import { Dock } from "./Components/Dock/Dock";
import { UIState } from "./Data/UIState";
import { initLogin, signOut } from "./Controllers/Login";
import { observer } from "mobx-react";
import { getRTDB, getFirestore } from "./Controllers/Fire";
import { setup, startApp } from "./Controllers/App";
import { shutdownDock } from "./Controllers/DockWindow";
import { autorun } from "mobx";
import { getStage, InitWindow } from "./Components/Login/InitWindow";
import { ConfirmService } from './Components/Dialog/ConfirmService';
import { RoomOpened } from "./Components/Room/RoomOpened";
import { GlobalSettingsWindow } from "./Components/Settings/GlobalSettingsWindow";
import { setupDeeplink } from "./Controllers/DeepLink";
import { lightTheme, darkTheme } from './DesignSystem/DS2'
import { ThemeState } from "./DesignSystem/DSTheme";
import InviteUsersWrapper from "./Components/Invite/InviteUsersWrapper";
import AddPodWrapper from "./Components/Pod/AddPodWrapper";
import { AddSpaceWindow } from "./Components/Space/AddSpaceWindow";
import PodSettingsWrapper from "./Components/Pod/PodSettingsWrapper";
import OnboardingWindow from "./Components/Onboarding/OnboardingWindow";
import { CssBaseline, GlobalStyles, ThemeProvider } from "@openteam/design-system";
import { useGlobalShortcut } from "./Util/useGlobalShortcut";

initLoggerApp("desktop");

const logger = new Logger("MainApp")

await setup()
// setupDemo()

logger.debug(
  "isDevelopment",
  import.meta.env.DEV
)



const App = observer(() => {

  const [stage, setStage] = React.useState<ReturnType<typeof getStage>>(getStage());

  if (!OTGlobals._auth) {
    // the check on OTGlobals._auth is for hot reloading

    logger.debug("creating auth")
    const auth = new OTAuth(getRTDB(), getFirestore(), startApp);
    logger.debug("created auth")
    auth.on("signOut", signOut);
    OTGlobals.registerAuth(auth);
    logger.debug("registered auth")

  }

  useEffect(() => {

    if (UIState.mainCapabilities.loginState) {
      window.Main.setMainLoginState({loggedIn: false})
    }

    initLogin()
    setupDeeplink()
    shutdownDock()
    // startSimulation()
  }, [])


  useEffect(() => autorun(() => {
    logger.debug("OTUITree.auth.userId", OTUITree.auth.userId)
    logger.debug("OTUITree.appHomeUI.started", OTUITree._appHomeUI?.started)
    setStage(getStage());
  }), [])

  const closeApp = () => {
    logger.info("closing app")
    window.Main.appClose()
  }

  // useGlobalShortcut("Ctrl+Option+Space", ()=> {logger.debug("got unmute")})

  const ready = OTUITree.auth.userId &&  stage === "ready";

  useEffect(() => {
    logger.debug(`App stage: ${stage} ready: ${ready}`);
  }, [stage, ready])

  return (
    <ThemeProvider theme={ThemeState.currentTheme === 'LIGHT' ? lightTheme : darkTheme}>
      <CssBaseline />
      <GlobalStyles styles={{ body: { backgroundColor: "transparent" } }} />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: undefined,
          justifyContent: undefined,
          pointerEvents: "none",
        }}
      >

        {!ready ? (
          <InitWindow stage={stage} onClose={closeApp} />
        ) : (
          <>

            {
              !UIState.dockModeSwitching ?
                <Dock />
                :
                undefined
            }

            <RoomOpened />

            <Call2 />

            <ConfirmService />

            {/* <TutorialService /> */}

            <GlobalSettingsWindow />

            <InviteUsersWrapper />

            <AddPodWrapper />

            <PodSettingsWrapper />

            <AddSpaceWindow />

            <OnboardingWindow />

          </>
        )}
      </div>
    </ThemeProvider>
  );
})

export default App;

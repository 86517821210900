import { Logger } from "@openteam/app-util"
import { v4 as uuidv4 } from "uuid";

const logger = new Logger("menu");

export type MenuTemplate = Electron.MenuItemConstructorOptions[]
type callbackType = () => void

const clickBackRegistry: Record<string, Record<string, callbackType>> = {}


export const handleMenuPress = (params: {menuId: string, cbid: string}) => {
  console.log("handleMenuPress", params)
  clickBackRegistry[params.menuId]?.[params.cbid]()
}
export const handleMenuClose = (params: { menuId: string }) => {
  console.log("handleMenuClose", params)
  clickBackRegistry[params.menuId]["onClose"]?.()
  delete clickBackRegistry[params.menuId]
}

window.Main.on("menu-click", handleMenuPress);
window.Main.on("menu-close", handleMenuClose);


export const openMenu = (template: MenuTemplate, onClose?: () => void) => {
  const clickBacks: Record<string, callbackType> = {}

  if (onClose) {
    clickBacks["onClose"] = onClose
  }

  const menuItems = convertMenu(template, clickBacks)

  const menuId = uuidv4()

  clickBackRegistry[menuId] = clickBacks

  const params = {
    menuId: menuId,
    menuItems: menuItems
  }

  window.Main.openMenu(params)
}


export const convertMenu = (template: MenuTemplate, clickBacks: Record<string, callbackType>) => {
  const menuItems: any = []

  for (const {click, submenu, ...item} of template) {

    const newItem: any = item
    if (click) {
      const cbid = uuidv4()
      clickBacks[cbid] = click as any
      newItem.clickBack = {cbid: cbid}
    }

    if (submenu) {
      const subMenuItems = convertMenu(submenu as any, clickBacks)
      newItem.submenu = subMenuItems
    }

    menuItems.push(newItem)

  }

  return menuItems

 }
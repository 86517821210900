import React, { CSSProperties } from 'react';
import { DSColumn, DSHSpacer, DSPanel, DSRow } from '../DesignSystem';
import { DSButton, DSCircleIconButton } from '../DesignSystem/DSButton';
import { VscChromeMinimize, VscChromeMaximize, VscChromeClose } from "react-icons/vsc";
import { DSTheme } from '../DesignSystem/DSTheme';

interface Props {
    parentId: string
    outerStyle?: CSSProperties
    hideMinimise?: boolean
    hideMaximise?: boolean
}

export const WindowControls = (props: Props) => {

    const iconSize = 18
    const spacerSize = 6

    const buttonStyle= {
        padding: 2,
        margin: 4,
        backgroundColor: 'transparent'
    }

    return (
        <DSRow style={{ marginLeft: 12, ...props.outerStyle }}>
 {
                !props.hideMinimise ?
                    <>
                        <DSCircleIconButton
                            style={buttonStyle}
                            onClick={() => {
                                window.Main.minimiseWindow(props.parentId)
                            }} >

                            <VscChromeMinimize size={iconSize} style={{ color: DSTheme.ButtonIconColor }} />
                        </DSCircleIconButton>
                        <DSHSpacer size={spacerSize} />
                    </>
                    : undefined
            }
            {
                !props.hideMaximise ?
                    <>
                        <DSCircleIconButton
                            style={buttonStyle}
                            onClick={() => {
                                window.Main.maximiseToggleWindow(props.parentId)
                            }} >

                            <VscChromeMaximize size={iconSize} style={{ color: DSTheme.ButtonIconColor }} />
                        </DSCircleIconButton>
                        <DSHSpacer size={spacerSize} />
                    </>
                    : undefined
            }
            <DSCircleIconButton
                style={buttonStyle}
                onClick={() => {
                    window.Main.closeWindow(props.parentId)
                }} >

                <VscChromeClose size={iconSize} style={{ color: DSTheme.ButtonIconColor }} />
            </DSCircleIconButton>
        </DSRow>
    )
}
import { CircularProgress, darken, styled, Tab, Tabs, useTheme } from '@openteam/design-system';
import { FireDb, OTGlobals, OTUITree, UIDataState } from '@openteam/app-core';
import { Logger } from '@openteam/app-util';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { isMacOs } from 'react-device-detect';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import { ToolTipContainer } from '../../Controllers/ToolTips';
import { openSettingsAt, setDockItemSize, UIState } from '../../Data/UIState';
import { DS2Button, DS2CopyLinkButton, DS2Icons, DS2Option, DS2Select, DS2Slider, DS2Switch, DSColumn, DSPanel, DSRow, DSVSpacer } from '../../DesignSystem';
import { DSBadgeText, DSBody, DSH1, DSH2, DSH3, DSPrint, DSSmallPrint } from '../../DesignSystem/DSText';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { GoogleCalendarAuth } from '../Calendar/GoogleCalendarAuth';
import { GoogleCalendarIcon } from '../Calendar/GoogleCalendarLogo';
import { Draggable } from '../Draggable';
import { SubWindowHandle, SubWindow, PopupOptions, SubWindowCSS } from '../SubWindow';
import { WindowControls } from '../WindowControls';
import { ZoomAuthButton, ZoomIcon } from '../Zoom';
import * as ics from 'ics'
import { DSImage } from '../../DesignSystem/DSImage';
import OnboardingService from '../../Controllers/OnboardingService';

const logger = new Logger('OnboardingWindow')


const ASSETS: Record<string, string> = {
  invite: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fonboarding%2Finviteteammates.png?alt=media&token=3d481a4f-d9ea-46c5-ac8d-01590434f1a0",
  gcal: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fonboarding%2Fgooglecalendar.png?alt=media&token=c4c0d6df-52f1-4e4a-9bf1-ffebdf5bec35",
  zoom: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fonboarding%2Fzoom.png?alt=media&token=03dbbb45-c1ae-457d-af02-a4884103427d",
  schedule: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fonboarding%2FSchedule.png?alt=media&token=0960da32-7be4-4b1d-be95-16a3814defe3",
}

type Props = {
  windowId: string
}

const OnboardingContent: React.FC<Props> = ({windowId}) => {
  const theme = useTheme();
  const [tab, setTab] = useState()
  const [link, setLink] = useState<string>();
  const spaceId = OTUITree.userManager.currentTeamId!
  const space = UIDataState.spaces[spaceId];
  const calendarManager = OTUITree.userManager.calendarManager;
  const zoomManager = OTUITree.userManager.zoomManager;

  const { steps, numSteps, numDone, nextStep} =  OnboardingService;

  const doneIcon = <FaCheckCircle size={20} color={theme.palette.success.main} />
  const todoIcon = <FaRegCircle size={20} />

  useEffect(() => {
    let mounted = true;

    (async () => {
      const _link = await FireDb.getInviteLink(spaceId);
      mounted && setLink(_link);
    })();

    return () => { mounted = false }
  }, [spaceId])


  const openSettings = useCallback((tabId) => {
    openSettingsAt({ domain: 'global', tabId })
  }, [])

  const addToCalendar = useCallback(async (_, cal) => {
    const invitees = (await space.actions?.userInvitees?.()) ?? [];

    OTGlobals.userSettingsManager.updateRemoteSettings({
      onboardingStepsComplete: {
        ...OTGlobals.remoteUserSettings.onboardingStepsComplete,
        sched: true
      }
    });

    if (cal === 'google') {
      const params = new URLSearchParams({
        action: "TEMPLATE",
        text: "Let's meet in ehlo 😎"
      });

      logger.debug(`Invitees`, invitees)
      if (invitees.length) {
        params.append('add', invitees.join(','))
      }

      window.Main.shellOpenExternal(`https://calendar.google.com/calendar/event?${params.toString()}`)
    } else if (cal === 'outlook') {
      const params = new URLSearchParams({
        subject: "Let's meet in ehlo 😎",
        path: '/calendar/action/compose',
        rru: 'addevent'
      });
      window.Main.shellOpenExternal(`https://outlook.office.com/calendar/0/deeplink/compose?${params.toString()}`)
    } else if (cal === 'ics') {
      const params = {
        title: "Let's meet in ehlo 😎",
        start: [2022, 12, 1, 11, 30],
        duration: { minutes: 30 },
        organizer: { email: OTUITree.userManager.userDoc?.email },
        attendees: invitees.map(email => ({ email, role: 'OPT-PARTICIPANT' }))
      }
      const { error, value: ev } = ics.createEvent(params as any);
      if (error) {
        logger.error(`Error creating ICS file`, error)
      }

      if (ev) {
        const blob = new Blob([ev], { type: "text/calendar" })
        const url = URL.createObjectURL(blob);
        logger.debug(`ics url is`, url, await blob.text())
        window.Main.downloadUrl(url);
      }

    }
  }, [])

  useEffect(() => {
    if (tab == 'dock') {
      OTGlobals.userSettingsManager.updateRemoteSettings({
        onboardingStepsComplete: {
          ...OTGlobals.remoteUserSettings.onboardingStepsComplete,
          dock: true
        }
      });
    }
  }, [tab])

  const hideOnboarding = action(() => {
    OTGlobals.userSettingsManager.updateRemoteSettings({ onboardingHidden: true });
    UIState.showOnboarding = false
  })

  const activeTab = tab ?? nextStep;

  return (
      <DSRow style={{ backgroundColor: DSTheme.BackgroundColor, color: theme.palette.text.primary, width: "100%", height: "100%" }}>

        <DSColumn style={{ backgroundColor: DSTheme.SpacePanelRoomBackground, minWidth: 250, width: 250 }} >
          <Header windowId={windowId} showControls={false} >
            <DSH1>Setup</DSH1>
            <Counter count={numDone} total={numSteps} />
          </Header>

          <DS2Tabs orientation='vertical' value={activeTab} onChange={(_, val) => setTab(val)} style={{ marginLeft: 32 }}>
            <DS2Tab icon={steps.invite ? doneIcon : todoIcon} iconPosition="start" value="invite" label="Invite Teammates" />
            <DS2Tab icon={steps.gcal ? doneIcon : todoIcon} iconPosition="start" value="gcal" label="Connect Google Calendar" />
            <DS2Tab icon={steps.zoom ? doneIcon : todoIcon} iconPosition="start" value="zoom" label="Connect Zoom" />
            <DS2Tab icon={steps.dock ? doneIcon : todoIcon} iconPosition="start" value="dock" label="Setup Dock" />
            <DS2Tab icon={steps.sched ? doneIcon : todoIcon} iconPosition="start" value="schedule" label="Schedule a time to test ehlo with your Teammates" />
          </DS2Tabs>
          <DSVSpacer size={16} style={{ flex: 1 }} />
          <DS2Button variant="text" color="secondary" size='small' style={{ marginLeft: 32 }} onClick={hideOnboarding}>Don't show this again</DS2Button>
        </DSColumn>

        <TabPanel value='invite' activeValue={activeTab} >
          <Header windowId={windowId}><DSH1>Invite Teammates</DSH1></Header>
          <DSColumn style={{ padding: "0px 24px", flex: 1 }} spacing={16}>

            <DSBody>
              Invite your teammates by sending them the link below. This link is valid for 48hr.
            </DSBody>
            <DSRow style={{ justifyContent: 'flex-end' }}>
              <DS2CopyLinkButton
                color="primary"
                meetingLink={link}
                title="Copy link"
                startIcon={DS2Icons.copy}
                analyticsEvent="OnboardingInviteTeammatesCopyLink"
              />
            </DSRow>
            <DSRow style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>
              {ASSETS.invite ? <DSImage url={ASSETS.invite} width={370} height={250} resizeMode="none" style={{objectFit: 'cover', objectPosition: 'top left'}} /> : null}
            </DSRow>
          </DSColumn>
        </TabPanel>

        <TabPanel value='gcal' activeValue={activeTab} >
          <Header windowId={windowId}><DSH1>Connect Google Calendar</DSH1></Header>
          {
            calendarManager.isAuthorised && false
              ? (
                <DSColumn style={{ padding: "0px 24px" }} spacing={16}>
                  <DSRow spacing={16}>
                    <GoogleCalendarIcon size={32} />
                    <DSBody wrap={true} >
                      Your Google Calendar is connected to ehlo, open settings to configure.
                    </DSBody>
                  </DSRow>
                  <DSRow style={{ justifyContent: 'flex-end' }}>
                    <DS2Button
                      startIcon={DS2Icons.settings}
                      color="secondary"
                      onClick={() => openSettings("google calendar")}
                    >
                      Calendar Settings
                    </DS2Button>
                  </DSRow>
                </DSColumn>
              ) : (
                <DSColumn style={{ padding: "0px 24px" }} spacing={16}>
                  <DSRow spacing={16}>
                    <GoogleCalendarIcon size={32} />
                    <DSBody wrap={true} >
                      Connect your google account to sync your meetings with ehlo and share your status with your teammates.
                    </DSBody>
                  </DSRow>
                  <DSRow style={{ justifyContent: 'flex-end' }}>
                    <GoogleCalendarAuth />
                  </DSRow>
                </DSColumn>
              )
          }
          <DSRow style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>
            {ASSETS.gcal ? <DSImage url={ASSETS.gcal} width={370} height={230} resizeMode="none" style={{objectFit: 'cover', objectPosition: 'top left'}} /> : null}
          </DSRow>
        </TabPanel>

        <TabPanel value='zoom' activeValue={activeTab} >
          <Header windowId={windowId}><DSH1>Connect Zoom</DSH1></Header>
          {
            zoomManager.isAuthorised ? (
              <DSColumn style={{ padding: "0px 24px" }} spacing={16}>
                <DSRow spacing={16}>
                  <ZoomIcon size={32} />
                  <DSBody wrap={true} >
                    Your Zoom account is connected to ehlo.
                    <p>Remember to use the Zoom desktop or phone app logged into your Zoom account.</p>
                  </DSBody>
                </DSRow>
                <DSRow style={{ justifyContent: 'flex-end' }}>
                  <DS2Button
                    startIcon={DS2Icons.settings}
                    color="secondary"
                    onClick={() => openSettings("zoom")}
                  >
                    Zoom Settings
                  </DS2Button>
                </DSRow>
              </DSColumn>
            ) : (
              <DSColumn style={{ padding: "0px 24px" }} spacing={16}>
                <DSRow spacing={16}>
                  <ZoomIcon size={32} />
                  <DSBody wrap={true} >
                    Connect your Zoom account so your teammates can see when you are on a Zoom call .
                  </DSBody>
                </DSRow>
                <DSRow style={{ justifyContent: 'flex-end' }}>
                  <ZoomAuthButton />
                </DSRow>
              </DSColumn>
            )
          }
          <DSRow style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>
            {ASSETS.zoom ? <DSImage url={ASSETS.zoom} width={370} height={230} resizeMode="none" style={{objectFit: 'cover', objectPosition: 'top left'}} /> : null}
          </DSRow>
        </TabPanel>

        <TabPanel value='dock' activeValue={activeTab} >
          <Header windowId={windowId}><DSH1>Setup Dock</DSH1></Header>
          <DSColumn style={{ padding: "0px 24px" }} spacing={16}>
            <DSColumn spacing={6}>
              <DSH3>Dock Size</DSH3>
              <DSPrint>Choose the dock size that works best for your screen</DSPrint>
              <DS2Slider
                style={{}}
                defaultValue={UIState.dockSelectedSize}
                min={52}
                max={100}
                step={4}
                onChange={(_, val) => setDockItemSize(val as number)}
              />
            </DSColumn>
            <DSColumn spacing={6}>
              <DSH3>Autohide</DSH3>
              <DSPrint wrap>
                Set the dock to autohide to make the most efficient use of your screen space.
                You will be notified when you are being contacted by a teammate or being knocked on.
              </DSPrint>
              <DSRow style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <DSPrint text="Auto hide dock" />
                <DS2Switch
                  analyticsEvent={`DockSettingsDockAutoHide${UIState.dockAutoHide ? 'Off' : 'On'}`}
                  checked={UIState.dockAutoHide}
                  onChange={UIState.actions.setDockAutoHide}
                />
              </DSRow>
            </DSColumn>
          </DSColumn>
        </TabPanel>

        <TabPanel value='schedule' activeValue={activeTab} >
          <Header windowId={windowId}><DSH1 wrap>Schedule a time to test ehlo with your Teammates</DSH1></Header>
          <DSColumn style={{ padding: "0px 24px" }} spacing={16}>
            <DSPrint wrap>
              The best way to experience ehlo is to talk with teamamtes. Put a reminder on everyone’s calendar to get everyone online at the same time.
            </DSPrint>
            <DSRow style={{ justifyContent: 'flex-end' }}>
              <DS2Select defaultValue={''} value='' onChange={addToCalendar}>
                <DS2Option disabled value=''>Add to Calendar</DS2Option>
                <DS2Option value='google'>Google Calendar</DS2Option>
                <DS2Option value='ics'>Apple (.ics)</DS2Option>
                <DS2Option value='outlook'>Outlook Calendar</DS2Option>
              </DS2Select>
            </DSRow>
            <DSRow style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>
              {ASSETS.schedule ? <DSImage url={ASSETS.schedule} width={370} height={230} resizeMode="none" style={{objectFit: 'cover', objectPosition: 'left top'}}/> : null}
            </DSRow>
          </DSColumn>
        </TabPanel>
      </DSRow>
  )
}

export default observer(OnboardingContent)

const Header = ({ windowId, showControls = true, children }) =>
  <Draggable windowId={windowId} style={{ justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'row', display: 'flex' }}>
    <DSRow style={{ justifyContent: 'space-between', padding: "24px 24px", flex: 1 }}>
      {children}
    </DSRow>
    {
      !isMacOs && showControls ? <WindowControls parentId={windowId} hideMaximise hideMinimise outerStyle={{ margin: 8 }} /> : undefined
    }
  </Draggable>

const DS2Tabs = styled(Tabs)(() => ({}))
const DS2Tab = styled(Tab)(({ theme }) => ({
  padding: 0,
  minHeight: 44,
  justifyContent: 'flex-start',
  textAlign: 'start',
  '&.Mui-selectedxxx': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '& .MuiTab-iconWrapper': {
    minWidth: 20
  }
}))


type TabPanelProps = {
  value: string | number;
  activeValue?: string | number;
}

const TabPanel: React.FC<TabPanelProps> = memo(({ value, activeValue, children }) => {
  const visible = value === activeValue;
  return (
    <DSPanel style={{ height: '100%' }} hidden={!visible}>
      {visible ? children : null}
    </DSPanel>
  )
})

export const Counter: React.FC<{ count: number, total: number }> = observer(({ count, total }) => {

  const val = Math.round(100 * count / total);

  return (
    <div style={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size="30" variant="determinate" value={val} />
      <div style={{ position: 'absolute', inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <DSBadgeText>{`${val}%`}</DSBadgeText>
      </div>
    </div>
  )
})

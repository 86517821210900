import { action, autorun, IReactionDisposer, observable, reaction, runInAction, toJS } from 'mobx';
import { KSpaceChannelId, KSpaceId, KSpacePodId, KSpaceUserId } from '@openteam/models';
import { DataState } from './DataState';
import { AwaitLock, Logger } from '@openteam/app-util';
import { DSTheme } from '../DesignSystem/DSTheme';
import { setDockLength } from '../Controllers/DockWindow';
import { OTGlobals, OTUITree } from '@openteam/app-core';
import { getUserInteresting } from '../Components/User/UserUtil';
import { applyObjectUpdate2 } from '@openteam/app-core';
import { getMaxDockSize } from '../Controllers/DockWindowUtil';
import { MEET_URL } from '../config';

const logger = new Logger('UIState');

export type TDockEdge = 'top' | 'bottom' | 'left' | 'right';
interface IUIDockPosition {
  x: number;
  y: number;
  width: number;
  height: number;
  onLeft: boolean;
  onTop?: boolean;
  displayId: number | null;
}

interface IOpenSpaceChats {
  channels: Record<KSpaceChannelId, { showCallSummary?: { callId: string; loadAtMessageId?: string } }>;
}



interface IUIState {
  productName: string;
  mainCapabilities: Record<string, boolean>;

  mainUpgradeAvailable: boolean;
  webUpgradeAvailable: boolean;
  webUpgradeForce: boolean;
  splashEnabled: boolean;

  appInited: boolean;

  dockInited: boolean;
  dockModeSwitching: boolean;

  dockAutoHide: boolean;
  dockPosition: IUIDockPosition;
  dockSelectedSize: number;
  dockSize: number;
  dockHorizontal: boolean;
  dockEdge?: TDockEdge;
  dockAnimating: boolean;
  dockAnimatingCount: number;

  dockScrollVersion: number;
  displays?: Electron.Display[];
  currentDisplay?: Electron.Display;
  primaryDisplay?: Electron.Display;

  dockMainSpaceOpen: boolean;
  dockMainSpaceFocused: boolean;
  dockMainSpaceWindowOpen: Record<string, boolean>;

  dockSettingsOpen: boolean;
  settingsTab: SettingsTabState;
  dockSearchOpen: boolean;
  dockSearchTerm: string;
  dockPanelShown: boolean;
  dockExistingCall: boolean;
  dockHasUserAlert: boolean;
  dockHasWaitingUsers: boolean;
  dockHasMeetingAlert: boolean;
  dockOtherHasAlert: boolean;
  dockCallHasAlert: boolean;
  dockShown: boolean;
  dockAlerts: Record<string, boolean>;

  dockLength: number;
  dockComponentLengths: Map<string, number>;

  dockShowOffline: boolean;

  openChats: Record<KSpaceId, IOpenSpaceChats>;
  openRoomSettings: Record<KSpaceId, Record<KSpacePodId, boolean>>;
  inviteUsersShown: boolean;
  createRoomShown: boolean;
  addSpaceShown: boolean;

  dockPinnedUsers: Record<KSpaceId, KSpaceUserId[]>;
  dockRecentUsers: Record<KSpaceId, Record<KSpaceUserId, number>>;

  dockDisplayedPinnedUsers: Record<KSpaceId, KSpaceUserId[]>;
  dockDisplayedRecentUsers: Record<KSpaceId, KSpaceUserId[]>;

  dockRecentUserVersion: Record<KSpaceId, number>;
  dockHighlightUserTriggers: Record<KSpaceId, Record<KSpaceUserId, { id: string; trigger: () => void }>>;

  dockOpenUserPanels: Record<KSpaceId, Record<KSpaceUserId, true>>;
  dockOpenRoomPanels: Record<KSpaceId, Record<KSpacePodId, true>>;

  focusRoomOpen: boolean;
  focusRoomPlayBackground: boolean;

  isDragging: boolean;

  showOnboarding: boolean;
  tutorialState: {
    done: boolean;
  };

  actions: {
    setDockItemSize: (size: number) => void;
    setDockAutoHide: (autoHide: boolean) => void;
    setDockShowOffline: (show: boolean) => void;
  };
}

export type SettingsTabState = { domain: string; tabId: string; spaceId?: string };

const baseUIState: IUIState = observable({
  productName: window.Main.productName,
  mainCapabilities: {},
  mainUpgradeAvailable: false,
  webUpgradeAvailable: false,
  webUpgradeForce: false,
  splashEnabled: false,

  appInited: false,

  dockInited: false,
  dockModeSwitching: false,
  dockAutoHide: localStorage.getItem('dockAutohide') === 'true',
  dockPosition: JSON.parse(localStorage.getItem('dockPosition') ?? 'null') || {
    x: 0,
    y: 0,
    height: 100,
    width: 80,
    onLeft: true,
    displayId: null
  },
  dockHorizontal: localStorage.getItem('dockHorizontal') === 'true',
  dockSelectedSize: parseInt(localStorage.getItem('dockSelectedSize') ?? '80'),
  dockSize: parseInt(localStorage.getItem('dockSelectedSize') ?? '80'),
  dockEdge: JSON.parse(localStorage.getItem('dockEdge') ?? 'null'),
  workArea: undefined,
  dockAnimating: false,
  dockAnimatingCount: 0,
  dockScrollVersion: 0,

  dockPanelShown: false,
  dockExistingCall: false,
  dockMainSpaceOpen: false,
  dockMainSpaceWindowOpen: {},
  dockMainSpaceFocused: false,
  dockSettingsOpen: false,
  settingsTab: { domain: 'global', tabId: 'dock' },

  dockSearchOpen: false,
  dockSearchTerm: '',
  dockHasUserAlert: false,
  dockHasMeetingAlert: false,
  dockHasWaitingUsers: false,
  dockOtherHasAlert: false,
  dockCallHasAlert: false,
  dockShown: true,

  dockLength: DSTheme.DockMinLength,
  dockComponentLengths: new Map<string, number>([]),

  dockShowOffline: true,

  openChats: {},
  openRoomSettings: {},
  inviteUsersShown: false,
  createRoomShown: false,
  addSpaceShown: false,

  dockPinnedUsers: {},
  dockDisplayedPinnedUsers: {},

  dockRecentUsers: {},
  dockDisplayedRecentUsers: {},

  dockRecentUserVersion: {},

  dockHighlightUserTriggers: {},

  dockOpenUserPanels: {},
  dockOpenRoomPanels: {},

  dockAlerts: {},

  isDragging: false,


  focusRoomOpen: false,
  focusRoomPlayBackground: true,

  showOnboarding: true,

  tutorialState: {
    done: false
  },

  actions: {
    setDockItemSize: (size) => setDockItemSize(size),
    setDockAutoHide: (autoHide) => setDockAutoHide(autoHide),
    setDockShowOffline: (show) => setDockShowOffline(show)
  }
});

const _reactions: Record<string, IReactionDisposer> = {};
let _initAutorun: IReactionDisposer | undefined = undefined;

const initUIState = () => {
  const dockShowOffline = localStorage.getItem('dockShowOffline');
  setDockShowOffline((dockShowOffline && JSON.parse(dockShowOffline)) ?? true, false);

  if (!_initAutorun) {
    _initAutorun = autorun(
      () => {
        const firstLoad = OTUITree.unsafeUserManager?.firstLoad;
        const isAuth = OTGlobals._auth && !!OTUITree.auth.userId;

        if (!isAuth) {
          Object.keys(_reactions).forEach((x) => {
            _reactions[x]();
            delete _reactions[x];
          });
        } else if (firstLoad && !_reactions['onChangeSpace']) {
          _reactions['onChangeSpace'] = reaction(
            () => OTUITree.userManager.currentTeamId,
            (spaceId?: KSpaceId) => {
              if (spaceId) logger.debug('reaction: onChangeSpace: ', spaceId, OTGlobals.getUnsafeTeamData(spaceId));
              onChangeSpace(spaceId);
            },
            { name: 'onChangeSpace', fireImmediately: true }
          );
        }
      },
      { name: 'initUIState' }
    );
  }
};

interface IDockUserMinimal {
  online: boolean;
  inRoom: boolean;
  hasAlert: boolean;
  hasMessage: boolean;
  openChat: boolean;
  pinned: boolean | undefined;
}

const onChangeSpace = (spaceId?: KSpaceId) => {
  logger.debug('change space: ', spaceId);

  if (_reactions['dockPinnedUsers']) _reactions['dockPinnedUsers']();

  if (_reactions['dockUsersRecentUsers']) _reactions['dockUsersRecentUsers']();

  if (!spaceId) return;

  runInAction(() => {
    if (!UIState.dockPinnedUsers[spaceId]) UIState.dockPinnedUsers[spaceId] = [];

    if (!UIState.dockRecentUsers[spaceId]) UIState.dockRecentUsers[spaceId] = {};

    if (!UIState.dockDisplayedRecentUsers[spaceId]) UIState.dockDisplayedRecentUsers[spaceId] = [];

    if (!UIState.dockDisplayedPinnedUsers[spaceId]) UIState.dockDisplayedPinnedUsers[spaceId] = [];

    UIState.showOnboarding = !OTGlobals.remoteUserSettings.onboardingHidden
  });

  _reactions['dockPinnedUsers'] = reaction(
    () => OTGlobals.getUnsafeTeamData(spaceId)?.preferences?.pinnedUserIds,
    (_pinnedUsers, _prev) => {
      //logger.debug("reaction: _pinnedUsers: ", _pinnedUsers, OTGlobals.getUnsafeTeamData(spaceId));
      const myUserId = OTUITree.auth.userId;

      const pinnedUsers = (_pinnedUsers || [])
        .filter((userId) => userId in (DataState.spaces[spaceId]?.users || {}))
        .filter((userId) => userId !== myUserId);

      const prev = UIState.dockPinnedUsers[spaceId];

      const removed = prev.filter((userId) => !pinnedUsers.includes(userId));
      const added = pinnedUsers.filter((userId) => !prev.includes(userId));

      if (added.length) logger.debug(`added: ${added} to ${spaceId}`);
      if (removed.length) logger.debug(`removed: ${removed} from ${spaceId}`);

      runInAction(() => {
        UIState.dockPinnedUsers[spaceId] = pinnedUsers;

        //removed.forEach(
        //  userId => UIState.dockRecentUsers[spaceId][userId] = Date.now()
        //);
        added.forEach((userId) => delete UIState.dockRecentUsers[spaceId][userId]);

        if (!UIState.dockRecentUserVersion[spaceId]) UIState.dockRecentUserVersion[spaceId] = 1;
        else UIState.dockRecentUserVersion[spaceId]++;
      });
    },
    { name: 'dockPinnedUsers', fireImmediately: true }
  );

  _reactions['dockUsersRecentUsers'] = reaction(
    () => ({
      spaceId: spaceId,
      pinnedUserIds: UIState.dockPinnedUsers[spaceId],
      dockRecentUserVersion: UIState.dockRecentUserVersion[spaceId],
      spaceUserList: Object.entries(DataState.spaces[spaceId]?.users || []).map(([userId, user]) => [
        userId,
        {
          ...getUserInteresting(spaceId, userId),
          online: !!user.status?.online,
          inRoom: (user.roomId !== undefined && !DataState.spaces[spaceId].focusRooms?.[user.roomId]) || user.status?.zoomStatus?.meeting !== undefined
        }
      ]) as [string, IDockUserMinimal][],
      showOffline: UIState.dockShowOffline
    }),
    ({ spaceId, pinnedUserIds, dockRecentUserVersion, spaceUserList, showOffline }) => {
      //logger.debug("spaceuserList: ", spaceUserList);
      const space = DataState.spaces[spaceId];

      const spaceUsers = Object.fromEntries(spaceUserList);

      const isAlert = ({ hasAlert, openChat, hasMessage, inRoom }) => {
        return (hasAlert || openChat || hasMessage) && !inRoom;
      };

      const alertUsers = spaceUserList
        .filter(([_, vals]) => isAlert(vals))
        .map(([userId, _]) => userId)
        .filter((userId) => !pinnedUserIds.includes(userId));

      const myRecentUsers = UIState.dockRecentUsers[spaceId] || {};

      const recentUsers = Object.keys(myRecentUsers)
        .filter((userId) => !alertUsers.includes(userId))
        .sort((a, b) => myRecentUsers[b] - myRecentUsers[a]);

      // the complete set of users
      const allUsers = [...alertUsers, ...recentUsers.slice(0, 3 - alertUsers.length)];

      // currentUsers minus the recent users...
      const currentDisplayedUsers = UIState.dockDisplayedRecentUsers[spaceId];

      // the ones that are missing from current users
      const toAdd = allUsers.filter((userId) => !currentDisplayedUsers.includes(userId));

      // add all alert users, then any remaining from unpinned
      // in order of last activity
      const newCurrentRecentUsers = [
        ...(currentDisplayedUsers
          .map((userId) => (allUsers.includes(userId) ? userId : toAdd.shift()))
          .filter((x) => x !== undefined) as string[]),
        ...toAdd
      ].filter((userId) => spaceUsers[userId] && !spaceUsers[userId].inRoom);

      const isVisible = (user: any) =>
        !user.inRoom && (showOffline || user.online);

      const pinnedUsers = pinnedUserIds.map((userId) => ({
        userId,
        visible: spaceUsers[userId] && isVisible(spaceUsers[userId])
      }));

      runInAction(() => {
        UIState.dockHasUserAlert = spaceUserList.some(([_, x]) => x.hasAlert);

        const newPinnedUsers = pinnedUsers.filter((x) => x.visible).map((x) => x.userId);
        if (UIState.dockDisplayedPinnedUsers[spaceId].join(':') !== newPinnedUsers.join(':')) {
          UIState.dockDisplayedPinnedUsers[spaceId] = newPinnedUsers;
        }
        if (UIState.dockDisplayedRecentUsers[spaceId].join(':') !== newCurrentRecentUsers.join(':')) {
          UIState.dockDisplayedRecentUsers[spaceId] = newCurrentRecentUsers;
        }

        const retainUsers = recentUsers.slice(0, 3 - alertUsers.length);

        const newRecentUsers = Object.fromEntries(
          Object.entries(UIState.dockRecentUsers[spaceId]).filter(([userId, _]) => retainUsers.includes(userId))
        );

        UIState.dockRecentUsers[spaceId] = applyObjectUpdate2(newRecentUsers, UIState.dockRecentUsers[spaceId]);
      });
    },
    { name: 'dockUsersRecentUsers', fireImmediately: true }
  );
};

export const removeDockRecentUser = (spaceId: KSpaceId, userId: KSpaceUserId) => {
  runInAction(() => {
    delete UIState.dockRecentUsers[spaceId][userId];
    UIState.dockRecentUserVersion[spaceId]++;
  });
};

export const addToRecentUsers = action((spaceId: KSpaceId, userId: string) => {
  if (!UIState.dockRecentUsers[spaceId]) UIState.dockRecentUsers[spaceId] = {};

  if (!UIState.dockPinnedUsers[spaceId]?.includes(userId) && userId !== OTUITree.auth.userId) {
    UIState.dockRecentUsers[spaceId][userId] = Date.now();
    UIState.dockRecentUserVersion[spaceId]++;
  }
  logger.debug(`Recent users now`, toJS(UIState.dockRecentUsers[spaceId]))
});

export const setDockEdge = action((dockEdge: TDockEdge | undefined, save: boolean = true) => {
  UIState.dockEdge = dockEdge;

  if (save) {
    if (!dockEdge) {
      localStorage.removeItem('dockEdge');
    } else {
      localStorage.setItem('dockEdge', JSON.stringify(dockEdge));
    }
  }
});

export const setDockHorizontal = action((horizontal: boolean, save: boolean = true) => {
  UIState.dockHorizontal = horizontal;
  save && localStorage.setItem('dockHorizontal', JSON.stringify(horizontal));
});

export const setDockAutoHide = action((autoHide: boolean, save: boolean = true) => {
  UIState.dockAutoHide = autoHide;
  save && localStorage.setItem('dockAutohide', JSON.stringify(autoHide));
});

export const setDockItemSize = action((size: number, save: boolean = true) => {
  UIState.dockSelectedSize = size;

  calcDockLength(size, true);
  save && localStorage.setItem('dockSelectedSize', JSON.stringify(size));
});

export const setDockShowOffline = action((show: boolean, save: boolean = true) => {
  UIState.dockShowOffline = show;
  save && localStorage.setItem('dockShowOffline', JSON.stringify(show));
});

export const setDockPosition = action((dockPosition: IUIDockPosition, save: boolean = true) => {
  //logger.debug(`Saving dockPosition:`, dockPosition, save);
  applyObjectUpdate2(dockPosition, UIState.dockPosition);
  save && localStorage.setItem('dockPosition', JSON.stringify(dockPosition));
});

export const loadPod = action(
  (
    spaceId: KSpaceId,
    podId: KSpacePodId,
    loadAtMessageId?: number,
    showCallSummary?: { callId: string; loadAtMessageId?: string },
    attempt: number=5
  ) => {
    logger.debug('loadPod', spaceId, podId);

  if (DataState.spaces[spaceId].channels[podId] || podId == 'adhoc-call') {
      logger.debug('loadPod DataState.spaces[spaceId].channels[podId] exists', spaceId, podId);
      UIState.openChats[spaceId].channels[podId] = { showCallSummary };

      DataState.spaces[spaceId].channels[podId]?.actions.loadChat(loadAtMessageId);
    } else if (attempt) {
      const to = 200 * (5-attempt)
      logger.debug(
        `loadPod channel not loaded into DataState.spaces[spaceId].channels, retrying in ${to}ms`,
        spaceId,
        podId
      );
      setTimeout(() => loadPod(spaceId, podId, loadAtMessageId, showCallSummary, attempt-1), to);
    }
  }
);

export const openPod = action(
  (
    spaceId: KSpaceId,
    podId: KSpacePodId,
    loadAtMessageId?: number,
    showCallSummary?: { callId: string; loadAtMessageId?: string }
  ) => {
    logger.debug('openPod', spaceId, podId);

    if (!podId) {
      logger.warn(`Bad channelId ${podId} in openPod`);
      return;
    }

    if (!UIState.openChats[spaceId]) {
      UIState.openChats[spaceId] = {
        channels: {}
      };
    }

    const windowId = `chat-${spaceId}-${podId}`;

    if (!UIState.openChats[spaceId].channels[podId]) {
      logger.debug('loading chat', spaceId, podId);
      loadPod(spaceId, podId, loadAtMessageId, showCallSummary);

      //DataState.spaces[spaceId].channels[podId]?.actions.openCallSummary(callId, messageId);
    } else {
      if (
        loadAtMessageId !== undefined &&
        DataState.spaces[spaceId].channels[podId]?.highlightMessageId !== loadAtMessageId
      ) {
        DataState.spaces[spaceId].channels[podId]?.actions.restart(loadAtMessageId);
      } else if (loadAtMessageId === undefined && !DataState.spaces[spaceId].channels[podId]?.isWatching) {
        DataState.spaces[spaceId].channels[podId]?.actions.restart();
      }
      logger.debug('focusing ', windowId);
      window.Main.showWindow(windowId, true);
      UIState.openChats[spaceId].channels[podId] = { showCallSummary };
    }

    logger.debug(`opening channel, name: ${DataState.spaces[spaceId].channels[podId]?.name}, window ${windowId}`);
    return podId;
  }
);

export const closePod = action((spaceId: KSpaceId, podId: KSpacePodId) => {
  logger.debug('closing pod', spaceId, podId);
  if (UIState.openChats[spaceId]) {
    delete UIState.openChats[spaceId].channels[podId];
    DataState.spaces[spaceId].channels[podId]?.actions.closeChat();
    return true;
  }
  return false;
});

export const joinPod = action((spaceId: KSpaceId, podId: KSpacePodId, pin: boolean = true) => {
  OTUITree.chatUIStates[spaceId].joinChannel(podId);
  if (pin) {
    OTUITree.teamManagers[spaceId].setPinPod(podId, true);
  }
});

export const leavePod = action((spaceId: KSpaceId, podId: KSpacePodId) => {
  OTUITree.chatUIStates[spaceId].closeChannel(podId);
  OTUITree.chatUIStates[spaceId].removeChannelUser(podId, OTUITree.auth.userId);
});

export const openPodSettings = action((spaceId: KSpaceId, podId: KSpacePodId) => {
  if (!UIState.openRoomSettings[spaceId]) {
    UIState.openRoomSettings[spaceId] = {};
  }
  UIState.openRoomSettings[spaceId][podId] = true;
})

export const closePodSettings = action((spaceId: KSpaceId, podId: KSpacePodId) => {
  if (UIState.openRoomSettings[spaceId]) {
    delete UIState.openRoomSettings[spaceId][podId]
  }
})

export const openCallWindow = () => {
  logger.debug(`OpenCallWindow`);
  DataState.activeCall && window.Main.showWindow(DataState.activeCall.windowId, true);
};

//const _openChatLock = new AwaitLock();

export const openUserChat = action(async (spaceId: KSpaceId, userId: KSpaceUserId, loadAtMessageId?: number) => {
  //await _openChatLock.acquireAsync();
  //try {
    const space = DataState.spaces[spaceId];

    if (OTUITree.auth.userId == userId) {
      return;
    }

    const user = space.users[userId];

    let channelId = space.userChats[userId]?.channelId;

    if (!channelId) {
      channelId = await user.actions.getChannel();
    }

    openPod(spaceId, channelId, loadAtMessageId);

    user.actions.updateLastInteracted();
    addToRecentUsers(spaceId, user.id);

    return channelId;
  //} finally {
  //  _openChatLock.release();
  //}
});

export const closeUserChat = action(async (spaceId: KSpaceId, userId: KSpaceUserId) => {
  const space = DataState.spaces[spaceId];
  const user = space.users[userId];

  if (UIState.openChats[spaceId]) {
    let channelId = space.userChats[userId]?.channelId;

    if (!channelId) {
      channelId = await user.actions.getChannel();
    }

    if (closePod(spaceId, channelId)) {
      user.actions.updateLastInteracted();
      addToRecentUsers(spaceId, user.id);
    }
  }
});

export const chatIsOpen = (spaceId: KSpaceId, podId: KSpacePodId) =>
  UIState.openChats[spaceId] && podId in UIState.openChats[spaceId].channels;

export const togglePinnedUser = action((spaceId: KSpaceId, userId: KSpaceUserId) => {
  DataState.spaces[spaceId].users[userId].actions.togglePinned();
});

export const isInCall = (spaceId: KSpaceId, channelId?: KSpaceChannelId) =>
  DataState.activeCall && spaceId === DataState.activeCall.spaceId && channelId === DataState.activeCall.channelId;

export const joinCall = (
  spaceId: KSpaceId,
  userId: KSpaceUserId,
  channelId?: KSpaceChannelId,
  meetingName?: string,
  meetingToken?: string
) => {
  let haveChannel: boolean = false;
  let isMyRoom: boolean = false;

  const space = DataState.spaces[spaceId];
  const myUser = OTUITree.auth.userManager.userDoc!;

  haveChannel = (channelId && channelId in space.channels) || false;

  isMyRoom = !channelId && userId == OTUITree.auth.userId;

  if (space) {
    if (channelId && haveChannel) {
      space.channels[channelId].actions.startCall(meetingName);
      DataState.actions.clearExternalMeeting();
    } else if (isMyRoom) {
      space.actions.startUserRoom?.(meetingName);
      DataState.actions.clearExternalMeeting();
    } else if (DataState.externalMeeting) {
      DataState.externalMeeting.actions.requestJoin(myUser.name);
    }
  } else if (meetingToken) {
    window.Main.shellOpenExternal(`${MEET_URL}/${meetingToken}?mode=ext`);
  }
};

const _DOCKPANELS = new Map<string, boolean>();
export const dockPanelOpened = action((key) => {
  if (!_DOCKPANELS.has(key)) {
    _DOCKPANELS.set(key, true);
  }

  if (!UIState.dockPanelShown) {
    logger.debug(`dockPanelShown start`, key);
    UIState.dockPanelShown = true;
  }
});

export const dockPanelClosed = action((key) => {
  if (!_DOCKPANELS.delete(key)) {
    logger.debug(`dockPanelClosed tried to delete non-existant key ${key}`);
  }

  UIState.dockPanelShown = _DOCKPANELS.size > 0;

  if (!UIState.dockPanelShown) {
    logger.debug(`dockPanelShown stop`, key);
  } else {
    logger.debug(`dockPanelShown`, Array.from(_DOCKPANELS.keys()));
  }
});

const _ANIMATORS = new Map<string, boolean>();
export const dockAnimatingStart = action((key) => {
  if (!_ANIMATORS.has(key)) {
    _ANIMATORS.set(key, true);
  }

  if (!UIState.dockAnimating) {
    logger.debug(`Animating start`, key);
    UIState.dockAnimating = true;
  }
});

export const dockAnimatingStop = action((key) => {
  if (!_ANIMATORS.delete(key)) {
    logger.debug(`DockAnimationStop tried to delete non-existant key ${key}`);
  }

  UIState.dockAnimating = _ANIMATORS.size > 0;

  if (!UIState.dockAnimating) {
    logger.debug(`Animating stop`, key);
    calcDockLength(UIState.dockSelectedSize, true);
  } else {
    logger.debug(`Animators`, Array.from(_ANIMATORS.keys()));
  }
});

export const dragStart = action(() => {
  UIState.isDragging = true;
});

export const dragEnd = action(() => {
  UIState.isDragging = false;
});

export const setDockComponentLength = action((component: string, length: number, allowReduce: boolean = false) => {
  if (UIState.dockComponentLengths.get(component) !== length) {
    logger.debug(
      `setDockComponentLength ${component} height ${UIState.dockComponentLengths.get(component)} -> ${length}`
    );
    UIState.dockComponentLengths.set(component, length);
    calcDockLength(UIState.dockSelectedSize, allowReduce);
  }
});

export const clearDockComponentLengths = action(() => {
  UIState.dockComponentLengths.clear();
});

export const calcDockLength = (dockSize: number, allowReduce: boolean = false) => {
  logger.debug(
    'calcDockLength',
    Array.from(UIState.dockComponentLengths.keys()).map((k) => [
      k,
      Math.floor(UIState.dockComponentLengths.get(k)! * dockSize)
    ])
  );

  const dockLengthRatio = Array.from(UIState.dockComponentLengths.values()).reduce((a, b) => a + b, 0);

  const maxDockSize = getMaxDockSize(dockLengthRatio, UIState.dockHorizontal);

  const calcDockSize = Math.min(maxDockSize, dockSize);
  // const dockLength = Math.ceil(dockLengthRatio * calcDockSize)
  const actualDockSize = Math.floor(calcDockSize);

  const dockLength = Array.from(UIState.dockComponentLengths.values())
    .map((r) => Math.floor(r * actualDockSize))
    .reduce((a, b) => a + b, 0);

  logger.debug(
    `dockLength ${UIState.dockLength} -> ${dockLength}, dockSize ${actualDockSize} allowReduce: ${allowReduce}`
  );

  if (allowReduce || dockLength > UIState.dockLength || UIState.dockSize != actualDockSize) {
    setDockLength(Math.max(dockLength, DSTheme.DockMinLength), actualDockSize, allowReduce);
  }
};

export const getDockSize = () => {
  return {
    height: UIState.dockHorizontal
      ? UIState.dockShown
        ? UIState.dockSize
        : Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)
      : UIState.dockLength,
    width: UIState.dockHorizontal
      ? UIState.dockLength
      : UIState.dockShown
      ? UIState.dockSize
      : Math.round(UIState.dockSize * DSTheme.DockAutoHideRatio)
  };
};

export const dockComponentHeights = (): number[] => {
  // const components = [
  //   'SpaceDockIcon',
  //   'SpaceOtherIcons',
  //   'CurrentDockUserIcon',
  //   'TopSeparator',
  //   'MeetingIcon',
  //   'OtherUsers',
  //   'PinnedUsers',
  //   'RecentUsers',
  //   'Calls',
  //   'Pods',
  //   'Spacer'
  // ];
  // logger.debug(`component heights`, components.map(c => UIState.dockComponentHeights.get(c) ?? 0), Array.from(UIState.dockComponentHeights.values()))
  // return components.map(c => UIState.dockComponentHeights.get(c) ?? 0) as number[];
  return Object.values(UIState.dockComponentLengths);
};

export const openMainSpace = action(() => {
  if (!UIState.dockMainSpaceOpen) {
    UIState.dockMainSpaceOpen = true;
  } else {
    window.Main.showWindow(`space-main`, true);
  }
});

export const closeMainSpace = action(() => {
  if (UIState.dockMainSpaceOpen) {
    UIState.dockMainSpaceOpen = false;
    UIState.dockMainSpaceFocused = false;
  }
});

export const openSettings = action(() => {
  if (!UIState.dockSettingsOpen) {
    UIState.dockSettingsOpen = true;
  } else {
    window.Main.showWindow(`global-settings`, true);
  }
});

export const openSettingsAt = action((tabState?: SettingsTabState) => {
  if (tabState) {
    UIState.settingsTab = tabState;
  }
  if (!UIState.dockSettingsOpen) {
    UIState.dockSettingsOpen = true;
  } else {
    window.Main.showWindow(`global-settings`, true);
  }
});

export const openSearch = action(() => {
  if (!UIState.dockSearchOpen) {
    UIState.dockSearchOpen = true;
  } else {
    window.Main.showWindow(`search-window`, true);
  }
});

export const openFocusRoomWindow = action(() => {
  if (!UIState.focusRoomOpen) {
    UIState.focusRoomOpen = true;
  } else {
    window.Main.showWindow(`focus-room-window`, true);
  }
});

export const setFocusRoomPlayBackground = action((play: boolean, save: boolean = true) => {
  save && localStorage.setItem('focusRoomPlayBackground', JSON.stringify(play));
  UIState.focusRoomPlayBackground = play;
});

export const focusScreenShareWindow = (userId) => {
  if (DataState.activeCall) {
    if (DataState.activeCall.screenShares[userId]) {
      window.Main.showWindow(`PopoutScreenShare-${userId}`, true);
    } else {
      DataState.activeCall.actions.popoutScreenShare(userId, true);
    }
  }
};

export const openOnboarding = action(() => {
    UIState.showOnboarding = true;
});

export const registerHighlightUserTrigger = (spaceId: KSpaceId, userId: string, id: string, trigger: () => void) => {
  if (!UIState.dockHighlightUserTriggers[spaceId]) {
    UIState.dockHighlightUserTriggers[spaceId] = {};
  }
  UIState.dockHighlightUserTriggers[spaceId][userId] = { id, trigger };
};

export const unregisterHighlightUserTrigger = (spaceId: KSpaceId, userId: string, id: string) => {
  if (
    UIState.dockHighlightUserTriggers[spaceId] &&
    UIState.dockHighlightUserTriggers[spaceId][userId] &&
    UIState.dockHighlightUserTriggers[spaceId][userId].id === id
  )
    delete UIState.dockHighlightUserTriggers[spaceId][userId];
};

export const showInviteUsers = action((show = true) => {
  if (show && UIState.inviteUsersShown) {
    const spaceId = OTUITree.userManager.currentTeamId;
    window.Main.showWindow(`invite-users-${spaceId}`, true);
  } else {
    UIState.inviteUsersShown = show;
  }
});

export const showCreateRoom = action((show = true) => {
  if (show && UIState.createRoomShown) {
    const spaceId = OTUITree.userManager.currentTeamId;
    window.Main.showWindow(`create-room-${spaceId}`, true);
  } else {
    UIState.createRoomShown = show;
  }
});

export const showAddSpace = action((show = true) => {
  if (show && UIState.addSpaceShown) {
    window.Main.showWindow(`add-space`, true);
  } else {
    UIState.addSpaceShown = show;
  }
});

export const setUserPanelOpen = action((spaceId: KSpaceId, key: KSpaceUserId, open=true) => {

  if (open) {

    if (!UIState.dockOpenUserPanels[spaceId]) {
      UIState.dockOpenUserPanels[spaceId] = {};
    }

    addToRecentUsers(spaceId, key);
    UIState.dockOpenUserPanels[spaceId][key] = true;

  } else {

    delete UIState.dockOpenUserPanels[spaceId]?.[key];

    if (Object.keys(UIState.dockOpenUserPanels[spaceId]).length == 0) {
      delete UIState.dockOpenUserPanels[spaceId]
    }
  }
});

export const setRoomPanelOpen = action((spaceId: KSpaceId, key: KSpacePodId, open=true) => {

  if (open) {

    if (!UIState.dockOpenRoomPanels[spaceId]) {
      UIState.dockOpenRoomPanels[spaceId] = {};
    }

    UIState.dockOpenRoomPanels[spaceId][key] = true;

  } else {

    delete UIState.dockOpenRoomPanels[spaceId]?.[key];

    if (Object.keys(UIState.dockOpenRoomPanels[spaceId]).length == 0) {
      delete UIState.dockOpenRoomPanels[spaceId]
    }
  }
});

export const UIState = baseUIState;

export const reset = () => {
  Object.keys(baseUIState).forEach((key) => {
    UIState[key] = baseUIState[key];
  });
};
initUIState();

logger.debug(
  'uistate initial: UIState.dockEdge',
  UIState.dockEdge,
  'UIState.dockPosition',
  UIState.dockPosition.x,
  UIState.dockPosition.y
);

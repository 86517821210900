import { OTUserInterface } from "@openteam/app-core"
import { DataState } from "../../Data/DataState"
import { closeUserChat, openUserChat, removeDockRecentUser, togglePinnedUser, UIState } from "../../Data/UIState"
import { DSTheme } from "../../DesignSystem/DSTheme"
import { MenuTemplate, openMenu } from "../Menu"

export const openUserMenu = (spaceId: string, userId: string, windowId?: string) => {
  const space = DataState.spaces[spaceId]

  const user = space.users[userId]
  const isOpen = UIState.openChats[spaceId] &&
    space.userChats[userId] &&
    space.userChats[userId].channelId in UIState.openChats[spaceId].channels;


  const deleteUserFromTeam = async (userId: string) => {
    const doDelete = await OTUserInterface.showConfirm({
      parentId: windowId,
      title: "Delete User?",
      message: [
        'Are you sure you want to remove this user from your workspace?',
      ],
      actions: { 'Delete': true, "Cancel": false },
      buttonStyles: { "Delete": { backgroundColor: DSTheme.DangerColor } },
      height: 200,
    });
    if (doDelete) {
      space.actions.removeUserFromTeam?.(userId)
    }
  }


  const menuItems: MenuTemplate = [
    {
      label: user.name,
      sublabel: user.team,
      enabled: false,
    },
    {
      type: "separator"
    },
  ]

  if (userId !== space.myUserId) {
    menuItems.push({
      label: "Pin Teammate in dock",
      type: "checkbox",
      checked: user.pinned,
      click: () => togglePinnedUser(spaceId, userId),
    })
  }

  if (UIState.dockRecentUsers[spaceId] && UIState.dockRecentUsers[spaceId][userId]) {
    menuItems.push(
      {
        label: "Remove from dock",
        click: () => {
          removeDockRecentUser(spaceId, userId)
        },
      }
    )
  }
  if (userId !== space.myUserId) {
    menuItems.push({
      type: "separator"
    })

    if (isOpen) {

      menuItems.push(
        {
          label: "Close Chat",
          click: () => closeUserChat(spaceId, userId),
        }
      )
    } else {
      menuItems.push(
        {
          label: "Open Chat",
          click: () => openUserChat(spaceId, userId),
        }
      )
    }
  }
  if (space.amAdmin) {
    menuItems.push({
      type: "separator"
    })

    menuItems.push({
      label: "Administrator options",
      enabled: false
    })

    menuItems.push({
      type: "separator"
    })

    menuItems.push({
      label: "Set team",
      submenu: [
        ...Object.entries(space.subTeams).map(([teamId, name]) => ({
          label: name,
          type: "radio",
          checked: user.teamId === teamId,
          click: () => space.actions.updateTeamUserSubTeam?.(teamId, userId),
        })),
        {
          label: "Unassigned",
          type: "radio",
          checked: user.teamId === undefined,
          click: () => space.actions.updateTeamUserSubTeam?.(null, userId)
        }
      ] as MenuTemplate,
    });

    menuItems.push(
      {
        label: "Is Administrator",
        type: "checkbox",
        checked: user.isAdmin,
        click: () => space.actions.setAdminUser?.(userId, !user.isAdmin)
      }
    )

    menuItems.push({
      label: "Remove Teammate",
      enabled: true,
      click: () => deleteUserFromTeam(userId)
    })
  }

  openMenu(menuItems)
}

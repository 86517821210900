import { Logger } from "@openteam/app-util"
import { observer } from "mobx-react";
import React, { useLayoutEffect } from "react";
import { ModalOptions, SubWindow, SubWindowCSS } from "../SubWindow";
import PodSettings from "./PodSettings";
import { getGenericWindowPosition } from "../../Controllers/DockWindowUtil";

const logger = new Logger("AddPodWindow")

type Props = {
  spaceId: string
  podId?: string
  parentId?: string
  onClose: () => void
}

export const PodSettingsWindow: React.FC<Props> = observer((props) => {
  const [position, setPosition] = React.useState<{ x: number, y: number }>()
  const windowId = `add-pod-${props.spaceId}`
  let width = 320
  let height = 640

  logger.debug("rendering")

  const calcPosition = () => {
    const pos = getGenericWindowPosition(width, height)

    logger.debug(`Positioning to ${pos.x},${pos.y}`)

    return pos
  }

  useLayoutEffect(() => {
    if (!props.parentId) {
      setPosition(calcPosition())
    }
  }, [props.parentId])

  if (!props.parentId && !position) {
    return null
  }


  return (
    <SubWindow
      id={windowId}
      parentId={props.parentId}
      initialPosition={position}
      show={true}
      initialSize={{ width: width, height: height }}
      minHeight={height}
      minWidth={width}
      onClose={props.onClose}
      focus={true}
      options={ModalOptions}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <PodSettings windowId={windowId} spaceId={props.spaceId} podId={props.podId} onClose={props.onClose} />
    </SubWindow>

  )
})

import { observer } from "mobx-react";
import { IUIWebcamStream } from "@openteam/models";
import React, { useEffect, useState } from "react";
import { FaChevronDown, FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import { DataState } from "../../../Data/DataState";
import { DSPillButton, IDSPillButtonProps } from "../../../DesignSystem/DSButton";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { ButtonProps, getDeviceOptions, openCallMenu } from "./callButtonUtil";
import { isMacOs } from "react-device-detect";
import { openSystemPreferences } from "../../../Util/OpenSystemPreferences";
import { DS2BaseButton, DS2Button, DS2ButtonGroup } from "../../../DesignSystem/DS2";
import { MediaDeviceManager, OTUserInterface } from "@openteam/app-core";


interface MicButtonProps extends Omit<IDSPillButtonProps, 'onMenuClick'> {
  vertical?: boolean
  size?: number
}

export const MicButton: React.FC<MicButtonProps> = observer(
  ({ style, size, color, button1Props, button2Props, ...otherProps }) => {
    const call = DataState.activeCall;
    const me = call?.participants[call.myUserId];
    const myStream = me?.streams["camera"] as IUIWebcamStream;
    const hasAudio = myStream?.muted === false;
    const [hasPerm, setHasPerm] = useState(!isMacOs)

    const checkPerm = React.useCallback(async () => {
      if (isMacOs) {
        if (await window.Main.getMediaAccessStatus('microphone') === 'granted') {
          setHasPerm(true);
        } else {
          setTimeout(checkPerm, 1000);
        }
      }
    }, [])

    useEffect(() => {
      checkPerm();
    }, [checkPerm])

    const toggleAudio = async () => {
      if (!hasPerm) {
        if (await window.Main.getMediaAccessStatus('microphone') !== 'granted') {
          if (await OTUserInterface.showConfirm({
            parentId: call!.windowId,
            title: 'Open System Preferences?',
            message: [
              'Ehlo requires Microphone permission to enable audio in a call.',
              'Do you want to open System Preferences to grant ths permission'
            ]
          })) {
            await openSystemPreferences("security", "Privacy_Microphone");
          }
          return;
        } else {
          setHasPerm(true);
          call?.actions.toggleAudio(true);
        }
      } else {
        call?.actions.toggleAudio();
      }
    }

    const openMenu = React.useCallback(() => {
      MediaDeviceManager.updateMediaDevices();

      openCallMenu([
        { enabled: false, label: `Select a Microphone` },
        ...getDeviceOptions("audioinput"),
        { type: "separator" },
        { enabled: false, label: `Select a Speaker` },
        ...getDeviceOptions("audiooutput"),
      ]);
    }, []);

    const notMuted = hasAudio && hasPerm

    if (call && call.participants[call.myUserId]) {

      return (
        <DS2ButtonGroup variant="contained" color="secondary">
          <DS2BaseButton
            size="small"
            style={{paddingLeft: 8, paddingRight: 8}}
            onClick={toggleAudio}
            noBadge
            data-tooltip={notMuted ? "Mute" : 'UnMute'}
            analyticsEvent={notMuted ? "callMute" : "callUnmute"}
            color="secondary"
            {...button1Props}
          >
            {notMuted ?
              <FaMicrophone size={16}  color={DSTheme.SuccessColor} /> :
              <FaMicrophoneSlash size={16} color={DSTheme.DangerColor}/>
            }
          </DS2BaseButton>
          <DS2BaseButton
            size="small"
            onClick={openMenu}
            style={{padding: 6}}
            noBadge
            data-tooltip="Audio settings"
            analyticsEvent="CallMicSettings"
            color="secondary"
            {...button2Props}
          >
            <FaChevronDown size={DSTheme.IconSize} />
          </DS2BaseButton>

        </DS2ButtonGroup>
      )
    } else {
      return null;
    }
  }
);

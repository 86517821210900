import { observer } from 'mobx-react';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { MdSignalWifi1Bar, MdSignalWifi2Bar,  MdSignalWifi3Bar, MdSignalWifi4Bar, MdSignalWifiOff} from "react-icons/md";
import { Logger } from '@openteam/app-util';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DataState } from '../../Data/DataState';
import { IUIWebcamStream } from '@openteam/models';
import { CallIndicator } from './CallIndicators';
import { DSSmallPrint } from '../../DesignSystem/DSText';
import { DSColumn, DSRow } from '../../DesignSystem';
import { toJS } from 'mobx';
import { useCallback } from 'react';
import { DSButton } from '../../DesignSystem/DSButton';

const logger = new Logger('SignalStrength')

export interface ISignalStrengthProps {
    userId: string
    size: number
    forceShow?: boolean
    style?: React.CSSProperties
    hide?: boolean
    popupOnLeft?: boolean
}

export interface ISignalStrengthState {
    isMenuOpen: boolean
}


export function getSignalStatusIcon(status: string, sz: number) {

    switch (status) {
        case 'ok':
            return {
                icon: <MdSignalWifi4Bar name="wifi" size={sz} color={DSTheme.SignalGood} />,
                shadow: DSTheme.SignalBG,
                tip: "Network Good",
            }
        case 'bandwidth-low':
            return {
                icon: <MdSignalWifi1Bar name="wifi" size={sz} color={DSTheme.SignalPoor} />,
                shadow: DSTheme.SignalBG,
                tip: "Low bandwidth"
            }
        case 'disconnect':
            return {
                icon: <MdSignalWifiOff name="wifi" size={sz} color={DSTheme.SignalBad} />,
                shadow: DSTheme.SignalBG,
                tip:  "Not connected"
            }
        case 'unstable':
            return {
                icon: <MdSignalWifiOff name="wifi" size={sz} color={DSTheme.SignalBad} />,
                shadow: DSTheme.SignalBG,
                tip:  "Network connection unstable"
            }
        case 'score-0':
        case 'score-1':
        case 'score-2':
        case 'score-3':
        case 'score-4':
            return {
                icon: <MdSignalWifi1Bar name="wifi" size={sz} color={DSTheme.Signal1Bar} />,
                shadow: DSTheme.SignalBG,
                tip: "Network signal poor",
            }
        case 'score-5':
        case 'score-6':
            return {
                icon: <MdSignalWifi2Bar name="wifi" size={sz} color={DSTheme.Signal2Bar} />,
                shadow: DSTheme.SignalBG,
                tip: "Network ok",
            }
        case 'score-7':
        case 'score-8':
            return {
                icon:  <MdSignalWifi3Bar name="wifi" size={sz} color={DSTheme.Signal3Bar} />,
                shadow: DSTheme.SignalBG,
                tip: "Network ok",
            }
        default:
            return {
                icon: <MdSignalWifi4Bar name="wifi" size={sz} color={DSTheme.SignalGood} />,
                shadow: DSTheme.SignalBG,
                tip: "Network Good",
            }
    }
}

export const SignalStrength: React.FC<ISignalStrengthProps> = observer((props) => {
    const ref = useRef<HTMLDivElement>(null)
    const [menuOpen, setMenuOpen] = useState(false)
    const call = DataState.activeCall!;
    const participant = call.participants[props.userId]
    const {popupOnLeft = true} = props

    useEffect(() => {
        if (call?.actions) {
            if (menuOpen) {
                call.actions.requestStats(props.userId)
            } else {
                call.actions.cancelStats(props.userId)
            }
            return () => {
                call.actions?.cancelStats && call.actions.cancelStats(props.userId)
            }
        }
    }, [menuOpen, call.actions])

    const toggleMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(e => {
        if (ref.current) {
            if (!menuOpen) {
                const rect = ref.current.getBoundingClientRect();
                logger.debug(`pointer ${e.clientX},${e.clientY}, rect`,rect)
                //setPosition({top: e.clientY -rect.top, left: e.clientX -rect.left})
            }
        }
        setMenuOpen(!menuOpen)
    }, [ref, menuOpen, setMenuOpen])

    let icon;
    let show = true;

    if (!participant || !participant.connected) {
        icon = getSignalStatusIcon('disconnect', props.size)
    } else {
        const status = participant.networkStatus?.status ?? 'disconnect';
        const score = participant.networkStatus?.strength ?? 0;
        const { hasAudio, hasVideo } = (participant.streams['camera'] ?? {}) as IUIWebcamStream

        if (status !== 'ok') {
            icon = getSignalStatusIcon(status, props.size)
        } else if (!(hasAudio || hasVideo)) {
            icon = getSignalStatusIcon('ok', props.size);
            show = !hasVideo;
        } else {
            icon =  getSignalStatusIcon(`score-${score}`, props.size);
            show = score < 8 || !hasVideo;
        }
    }


    let style = show ? styles.show : styles.fade

    if (props.hide) {
        style = styles.hide
    } else if (props.forceShow) {
        style = styles.hover
    }

    const popupStyle: CSSProperties = {
        top: props.size * 1.5
    }
    if (popupOnLeft) {
        popupStyle.right = props.size * 1.5
    } else {
        popupStyle.left = props.size * 1.5
    }

    return icon ? (
        <CallIndicator
            style={{
                ...style,
                ...props.style,
                padding: Math.round(props.size*.3),
                position: "relative"
            }}
        >
            <DSButton onClick={toggleMenu} analyticsEvent="CallSignal">
                {icon.icon}
            </DSButton>
            <DumbPopup open={menuOpen} style={popupStyle} >
                <StatsPopup  userId={props.userId} tip={icon.tip}/>
            </DumbPopup>
        </CallIndicator>
        ) : null;
})

type DumbPopupProps = {
    open: boolean
    style: CSSProperties
}

const DumbPopup: React.FC<DumbPopupProps> = (props) => {
    return props.open ? (
        <div style={{position: "absolute", zIndex: 10, ...props.style}}>
            {props.children}
        </div>
    ): null
}

type StatsPopupProps = {
    userId: string
    tip?: string
}

const StatsPopup: React.FC<StatsPopupProps> = observer(({userId, tip}) => {
    const participant = DataState.activeCall?.participants[userId]
    const stats = participant?.streams['camera']?.stats;

    //useEffect(() => {
    //    logger.debug(`Rendering stats`, toJS(stats))
    //})
    if (!stats || Object.keys(stats).length == 0) {
        return (
            <div style={styles.popover}>
                <DSSmallPrint style={{marginTop: 5}}>
                    {tip ?? "No stats"}
                </DSSmallPrint>
            </div>
        )
    } else {
        return (
          <div style={styles.popover}>
            {tip ? (
              <div style={{ display: "flex", marginTop: 5, justifyContent: "center" }}>
                <DSSmallPrint strong={true} style={styles.warn}>{tip}</DSSmallPrint>
              </div>
            ) : null}

            {Object.entries(stats ?? {}).map(([kind, statsList]) => (
              <DSRow key={kind} style={{marginTop: 5}}>

                <DSColumn style={{ alignItems: "flex-end", marginRight: 5 }}>
                  <DSSmallPrint strong>{kind} :</DSSmallPrint>
                  {statsList?.[0]?.layer ? <DSSmallPrint>layer :</DSSmallPrint> : null}
                  {statsList?.[0]?.resolution ? <DSSmallPrint>resolution :</DSSmallPrint> : null}
                  <DSSmallPrint>score (0-10) :</DSSmallPrint>
                  <DSSmallPrint>jitter :</DSSmallPrint>
                  <DSSmallPrint>bitrate (kb/s) :</DSSmallPrint>
                  <DSSmallPrint>rtt (ms) :</DSSmallPrint>
                </DSColumn>

                {statsList && statsList.map((stats) => {
                  if (!stats) {
                      return null;
                  }
                  let style: any = null;
                  if (stats.enabled === false) {
                    style = styles.disabled;
                  }

                  return (
                    <DSColumn
                      key={stats.layer || "layer"}
                      style={{ alignItems: "flex-start", marginRight: 5 }}
                    >
                      <DSSmallPrint strong style={style}>{stats.codec}</DSSmallPrint>
                      {stats.layer ?  <DSSmallPrint style={style}>{stats.layer}</DSSmallPrint> : null}
                      {stats.resolution ?  <DSSmallPrint style={style}>{stats.resolution.height}p</DSSmallPrint> : null}
                      <DSSmallPrint style={style}>{stats.score}</DSSmallPrint>
                      <DSSmallPrint style={style}>{stats.jitter}</DSSmallPrint>
                      <DSSmallPrint style={style}> {Math.round(stats.bitrate / 1024)} </DSSmallPrint>
                      <DSSmallPrint style={style}> {stats.roundTripTime ? Math.round(stats.roundTripTime) : ""} </DSSmallPrint>
                    </DSColumn>
                  );
                })}
              </DSRow>
            ))}
          </div>
        );
    }
})



const styles = {
    hover: {
        opacity: 1,
        transition: "opacity 0.3s",
    },
    show: {
        opacity: 0.9,
        transition: "opacity ease-in 2s",
    },
    hide: {
        opacity: 0,
        transition: "opacity ease-out 0.1s",
    },
    fade: {
        opacity: 0,
        transition: "opacity 1s ease-out 1s",
    },
    widget: {
        height: 15,
        width: 14,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        opacity: 0.75
    },
    bar: {
        // borderColor: 'white',
        // borderWidth: 1,
        width: 4,
    },
    //good: {
    //    backgroundColor: DSTheme.MainColour,
    //},
    popover: {
        backgroundColor: 'black',
        opacity: 0.9,
        padding: 4,
        borderRadius: 5,
        lineHeight: "100%",
    },
    warn: {
        color: DSTheme.SignalPoor
    },
    disabled: {
        color: 'grey'
    }
}
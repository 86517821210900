import React, { Component } from 'react';
import Youtube from 'react-youtube'
import { IPluginConfig, PluginManager } from "@openteam/app-core";
import { IPluginResource, IUActiveCallPluginCallbacks } from "@openteam/models";
import { observer } from 'mobx-react';
import { Logger } from '@openteam/app-util';
import { FaYoutube } from 'react-icons/fa';

const logger = new Logger("OTYouTube");

interface IYoutubePluginDetails extends IPluginResource {
  args: IOTYoutubeArgs
}

interface IOTYoutubeArgs {
  videoId: string,
  url,
  position: number,
  playing?: boolean,
  lastUpdate?: number
}

interface IOTYoutubeProps extends IUActiveCallPluginCallbacks {
  pluginDetails: IYoutubePluginDetails
  height: number
  width: number
}

interface IOTYoutubeState {
  urlText: string
}

@observer
export class OTYoutube extends Component<IOTYoutubeProps, IOTYoutubeState> {
  player: any
  videoLoaded: boolean = false
  constructor(props) {
    super(props);

    this.state = {
      urlText: ''
    }
    console.log("creating YouTube")
  }

  componentDidUpdate(prevProps) {
    var prevArgs: IOTYoutubeArgs = prevProps.pluginDetails.args
    var args: IOTYoutubeArgs = this.props.pluginDetails.args
    this.syncVideo(prevArgs, args)
  }

  syncVideo = (prevArgs, args) => {
    if (!this.player) {
      return
    }

    if (
      prevArgs.videoId == args.videoId &&
      prevArgs.playing == args.playing &&
      prevArgs.position == args.position &&
      prevArgs.lastUpdate == args.lastUpdate
    ) {
      return
    }

    if (prevArgs.videoId !== args.videoId) {
      this.videoLoaded = false
      this.player.loadVideoById(args.videoId);
    }


    if (!prevArgs.playing && args.playing) {
      console.log("playing video")
      this.player.playVideo();
    }

    if (prevArgs.playing && !args.playing) {
      console.log("pausing video")
      this.player.pauseVideo();
    }

    this.syncTime()

  }

  syncTime = (force = false) => {
    // safe to call this as often as you like as it works
    // out the time difference since the last position

    var args: IOTYoutubeArgs = this.props.pluginDetails.args

    var currentTime = this.getCurrentTime()
    var remoteTime = args.position

    if (args.playing && args.lastUpdate) {
      // account for time elapsed
      remoteTime += (Date.now() - args.lastUpdate)
    }


    if (args.playing || force) {
      if (Math.abs(currentTime - remoteTime) > 500) {
        logger.debug(`syncing to ${remoteTime}`)
        this.player.seekTo(remoteTime / 1000, true);
      }
    }
  }

  getCurrentTime = () => {
    return this.player ? this.player.getCurrentTime() * 1000 : 0
  }

  render() {
    const opts = {
      width: '100%',
      height: '100%',
      // playerVars: {
      //   // https://developers.google.com/youtube/player_parameters
      //   autoplay: 1,
      // },
    };

    if (!this.props.pluginDetails.args.videoId) {
      return null
    }

    return (
      <Youtube
        containerClassName="pluginfill"
        key={`reactyoutube`}
        id={`reactyoutube-${this.props.pluginDetails.pluginId}`}
        videoId={this.props.pluginDetails.args.videoId}
        opts={opts}
        onReady={this._onReady}
        onPause={this._onPause}
        onPlay={this._onPlay} />

    )
  }

  setArgs = (args: {}, sync = true) => {
    this.props.updatePluginArgs(
      this.props.pluginDetails.pluginId,
      {
        ...args,
        position: this.getCurrentTime(),
        lastUpdate: Date.now()
      },
      sync
    )
  }

  _onPlay = () => {
    if (!this.videoLoaded) {
      this.videoLoaded = true
      this.syncTime(true) // hack to get around youtube initialisation time
    }
    // only sync if we're initiating the play, ie it wasn't requested from args
    this.setArgs({ playing: true }, this.props.pluginDetails.args.playing != true)
    this.props.onPlaying(this.props.pluginDetails.pluginId, true);
  }

  _onPause = () => {
    if (this.props.pluginDetails.args.playing) {
      this.setArgs({ playing: false })
    }
    this.props.onPlaying(this.props.pluginDetails.pluginId, false);
  }

  _onReady = (event) => {
    // access to player in all event handlers via event.target
    this.player = event.target
    var pluginDetails = this.props.pluginDetails
    var args = pluginDetails.args

    this.syncVideo({
      videoId: args.videoId,
      playing: false,
      position: 0
    }, args)
  }
}


function getArgsFromURL(url: string): IOTYoutubeArgs | undefined {

  var matches = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);

  if (matches) {
    const videoId = matches[1];
    return {
      videoId,
      url,
      position: 0,
      playing: false
    }
  }
}

export const pluginType = 'youtube'
export const pluginConfig: IPluginConfig = {
  name: 'YouTube',
  multi: true,
  component: OTYoutube,
  icon: props => <FaYoutube {...props} />,
  iconColour: '#EA3423',
  webInline: true,
  canHandleUrl: url => getArgsFromURL(url),
  urlPriority: 100
}
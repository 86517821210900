import React, { forwardRef, useCallback, useEffect, useImperativeHandle, PropsWithChildren } from 'react';
import jsx from "refractor/lang/jsx";
import typescript from "refractor/lang/typescript";

import { Remirror, useRemirror, EditorComponent } from '@remirror/react';

import {
  BlockquoteExtension,
  BoldExtension,
  CodeBlockExtension,
  CodeExtension,
  LinkExtension,
  PlaceholderExtension,
  StrikeExtension,
  TrailingNodeExtension,
} from "remirror/extensions";

import {
  CustomKeymapExtension,
  EhloEmojiExtension
} from "./MarkdownEditorExtensions"

import MentionComponent from './MentionComponent';
import {
  EhloItalicExtension,
  EhloMarkdownExtension,
  EhloMentionAtomExtension,
  EhloMentionAtomState
} from './RemirrorExtensions';

import { Logger } from '@openteam/app-util';
import { RemirrorEventListener } from 'remirror';


const logger = new Logger("ChatComposeWrapper");

interface Props {
  placeholder?: string;
  initialContent?: string;
  onChange: (value: string) => void
  onSubmit?: () => void
  onFocus?: () => void
  onBlur?: () => void
}

export type ComposeRef = {
  insertText: (text: string) => void;
};

const ChatComposeWrapper = forwardRef<ComposeRef, PropsWithChildren<Props>>(
  ({
    placeholder,
    initialContent,
    onChange: _onChange,
    onSubmit,
    children,
    ...props
  }, ref) => {

    const extensions = useCallback(
      () => [
        new PlaceholderExtension({ placeholder }),
        new BlockquoteExtension(),
        new EhloEmojiExtension(),
        new LinkExtension({ autoLink: true }),
        new BoldExtension(),
        new StrikeExtension(),
        new EhloItalicExtension(),
        new EhloMentionAtomExtension({
          matchers: [
            { name: 'at', char: '@', appendText: '  ' },
          ],
        }),
        new CodeExtension(),
        new CodeBlockExtension({ supportedLanguages: [typescript, jsx] }),
        new TrailingNodeExtension(),
        new EhloMarkdownExtension({ copyAsMarkdown: false }),
        ...onSubmit ? [
          new CustomKeymapExtension({ onSubmit: onSubmit })
        ] : []

      ],
      [placeholder]
    );

    const { manager, state, setState } = useRemirror({
      extensions,
      stringHandler: "markdown",
      content: initialContent,
    });

    useImperativeHandle(ref, () => ({
      insertText: (text: string) => manager.store.commands.insertText(text)
    } as ComposeRef));


    const onChange: RemirrorEventListener<Remirror.Extensions> = useCallback(
      ({ helpers, state }) => {
        setState(state)

        const newMarkdown = helpers.getMarkdown()
        _onChange(newMarkdown)
      }, [_onChange])

    useEffect(() => {
      if (!initialContent) {
        //logger.debug(`Clearing state`)
        manager.view.updateState(manager.createState())
      }
    }, [initialContent, manager])

    return (
      <Remirror
        key="remirror-editor"
        manager={manager}
        autoFocus
        state={state}
        onChange={onChange}
        initialContent={initialContent}
        {...props}
      >
        {children}
      </Remirror>
    );

  });

export default ChatComposeWrapper;

import { child, Database, onDisconnect, onValue, ref, serverTimestamp, set, update } from "firebase/database";

import { Logger } from "@openteam/app-util";
import { OTGlobals } from "../OTGlobals";
import { OTAppCoreData } from "../OTAppCoreData";
import { OTUserInterface } from "../OTUserInterface";
import { unWatch, watch } from "./FireWatch";

const logger = new Logger("FireSession");

export class SessionDb {
  static hasSetupAppPresence: boolean = false;
  static isWatchingAppSession: boolean = false;

  static setupAppPresence = (
    fbDb: Database,
    userId: string,
    sessionToken: string,
    endActiveSessionCallback: (reload: boolean) => Promise<void>
  ) => {
    var statusRef = ref(fbDb, `/users/${userId}/status`)
    var sessionRef = child(child(statusRef, "activeSessions"), sessionToken!);

    // const device = {
    //   manufacturer: Device.manufacturer,
    //   modelName: Device.modelName,
    //   deviceName: Device.deviceName,
    //   osName: Device.osName,
    //   osVersion: Device.osVersion,
    // };

    // logger.debug("device details", device);

    const isOfflineForDatabase = {
      last_changed: serverTimestamp(),
    };

    const isOnlineForDatabase = {
      state: null, // clear previous status info
      last_changed: serverTimestamp(),
      sessionToken: sessionToken,
      appVersion: OTAppCoreData.version,
      platform: OTUserInterface.platformUtils.PlatformOS,
      userAgent: navigator.userAgent || null,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      // device: device,
    };

    watch(fbDb, ".info/connected", function (connected) {
      OTGlobals.fbConnected = connected
      logger.info("OTGlobals.fbConnected", OTGlobals.fbConnected);

      if (OTGlobals.fbConnected) {
        OTGlobals.appHomeManager?.heartbeatManager?.doHeartbeat();
      }

      if (connected == false) {
        return;
      }

      onDisconnect(sessionRef)
        .set(null)
        .then(function () {
          set(sessionRef, true);
        });

      onDisconnect(sessionRef)
        .update(isOfflineForDatabase)
        .then(function () {
            update(statusRef, isOnlineForDatabase)
            .then(() =>
              SessionDb.watchAppSession(fbDb, userId, sessionToken, endActiveSessionCallback)
            );
        });
    });

    SessionDb.hasSetupAppPresence = true;
  };

  static watchAppSession = (
    fbDb: Database,
    userId: string,
    sessionToken: string,
    endActiveSessionCallback: (reload: boolean) => Promise<void>
  ) => {
    if (SessionDb.isWatchingAppSession) {
      return;
    }
    SessionDb.isWatchingAppSession = true;

    const path = `/users/${userId}/status`;
    watch(fbDb, path, (doc) => {
      if (sessionToken != doc.sessionToken) {
        endActiveSessionCallback(true);
      }
    });
  };

  static unwatchAppSession = (fbDb: Database, userId: string) => {
    if (!SessionDb.isWatchingAppSession) {
      return;
    }
    const path = `/users/${userId}/status`;
    unWatch(fbDb, path);
  };

  static removeAppSession = async (
    fbDb: Database,
    userId: string,
    sessionToken: string,
    teamIds: string[]
    //teamIdList: Record<string, boolean>
  ) => {
    if (!SessionDb.hasSetupAppPresence) {
      return;
    }

    unWatch(fbDb, ".info/connected");

    await Promise.all(
      teamIds.map((teamId) => {
        const statusRef = ref(fbDb, `/teams/${teamId}/users/${userId}/status`)
        const sessionRef = child(child(statusRef, "activeSessions"), sessionToken!);

        onDisconnect(statusRef).cancel();
        onDisconnect(sessionRef).cancel();

        return set(sessionRef, null);
      })
    );

    SessionDb.unwatchAppSession(fbDb, userId);

    const appStatusRef = ref(fbDb, `/users/${userId}/status`)
    const appSessionRef = child(child(appStatusRef, "activeSessions"), sessionToken!);

    await set(appSessionRef, null);
    onDisconnect(appStatusRef).cancel();
    onDisconnect(appSessionRef).cancel();

    SessionDb.hasSetupAppPresence = false;
  };

  static setupTeamPresence = (
    fbDb: Database,
    userId: string,
    sessionToken: string,
    teamId: string,
    appCapabilities?: any
  ) => {
    var statusRef = ref(fbDb, `/teams/${teamId}/users/${userId}/status`)
    var sessionRef = child(child(statusRef, "activeSessions"), sessionToken!);

    var isOfflineForDatabase = {
      last_changed: serverTimestamp(),
    };

    onValue(ref(fbDb, ".info/connected"), function (snapshot) {
      if (snapshot.val() == false) {
        return;
      }

      onDisconnect(sessionRef)
        .set(null)
        .then(function () {
          set(sessionRef, true);
        });

      onDisconnect(statusRef)
        .update(isOfflineForDatabase)
        .then(function () {
          update(statusRef, {
            state: null,
            idle: OTGlobals.isIdle || null,
            last_changed: serverTimestamp(),
            sessionToken: sessionToken,
            appVersion: OTAppCoreData.version,
            appCapabilities: appCapabilities,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            // currentRoom: null
          });
        });
    });
  };
}

import { Button, Column, H3, Print, Row, styled, useTheme } from '@openteam/design-system';
import { Logger } from '@openteam/app-util';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { cloneElement, useCallback, useState } from 'react';
import { useEffect } from 'react';
import { DataState } from '../../Data/DataState';
import { setDockComponentLength, UIState } from '../../Data/UIState';
import { IUIWebcamStream } from '@openteam/models';
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash } from 'react-icons/fa';
import { DockCallIndicator } from './DockIndicator';
import { ToolTipComponent } from '../../Controllers/ToolTips';

const logger = new Logger('DockActiveCall');

const DockActiveCall: React.FC = () => {
  const call = DataState.activeCall;
  const show = call !== undefined;
  const me = call?.participants[call.myUserId];
  const myStream = me?.streams['camera'] as IUIWebcamStream;
  const hasVideo = myStream?.hasVideo === true;
  const hasAudio = myStream?.muted === false;
  const [boop, setBoop] = useState(false);

  const theme = useTheme();

  const videoIcon = hasVideo ? <FaVideo /> : <FaVideoSlash color={theme.palette.secondary.dark} />;
  const audioIcon = hasAudio ? <FaMicrophone /> : <FaMicrophoneSlash color={theme.palette.secondary.dark} />;

  const ttVideoIcon = hasVideo ? <FaVideo color={theme.palette.success.main}/> : <FaVideoSlash color={theme.palette.error.main} />;
  const ttAudioIcon = hasAudio ? <FaMicrophone color={theme.palette.success.main} /> : <FaMicrophoneSlash color={theme.palette.error.main} />;
  
  useEffect(() => {
    if (show) {
      setDockComponentLength('ActiveCall', 0.36);
      return () => setDockComponentLength('ActiveCall', 0, true);
    }
  }, [show]);

  const restore = useCallback(
    action(() => {
      if (call) call.isMinimized = false;
      window.Main.showWindow('CallWindow');
    }),
    [call]
  );

  useEffect(() => {
    if (call?.isMinimized || call?.isHidden) {
      setBoop(true);
      setTimeout(() => setBoop(false), 500)
    }
  }, [call?.isMinimized || call?.isHidden])

  logger.debug(`Show: ${show}`, call?.mode);
  const width = UIState.dockHorizontal ? UIState.dockSize * 0.36 : UIState.dockSize * 0.75;
  const height = UIState.dockHorizontal ? UIState.dockSize * 0.75 : UIState.dockSize * 0.36;
  const tipPosition = UIState.dockHorizontal ? UIState.dockPosition.onTop ?? UIState.dockEdge === 'top' ? 'bottom' : 'top' : UIState.dockPosition.onLeft ? 'right' : 'left'

  return show ? (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        width: UIState.dockHorizontal ? undefined : '100%',
        height: UIState.dockHorizontal ? '100%' : undefined,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      { (call.isMinimized || call.isHidden) ? <DockCallIndicator tileSize={height} color={theme.palette.success.main} height={height} /> : null }
      <StyledButton
        color="success"
        onClick={restore}
        data-tooltip='${hasAudio}:${hasVideo}' 
        data-tooltip-for='dock-active-call' 
        data-tooltip-position={tipPosition}
        noBadge
        style={{
          width: width,
          height: height,
          flexDirection: UIState.dockHorizontal ? 'column' : 'row',
          transform: boop ? 'scale(1.1)' : 'scale(1)',
          transition: 'transform 0.25s ease-in-out .25s',
        }}
      >
        {cloneElement(audioIcon, { size: (UIState.dockSize / 2) * 0.4 })}
        {cloneElement(videoIcon, { size: (UIState.dockSize / 2) * 0.4 })}
      </StyledButton>
      <ToolTipComponent id={'dock-active-call'}>
        <Column spacing={4}>
          <H3>Open Call Window</H3>
          <Row spacing={8}>{ttAudioIcon}<Print>Mic {hasAudio ? 'enabled' : 'disabled'}</Print></Row>
          <Row spacing={8}>{ttVideoIcon}<Print>Camera {hasVideo ? 'enabled' : 'disabled'}</Print></Row>
        </Column>
      </ToolTipComponent>
    </div>
  ) : null;
};

export default observer(DockActiveCall);

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  borderRadius: 8,
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly'
}));

import { Logger } from '@openteam/app-util';
import React, { useCallback, useEffect, useState } from 'react';
import calcWindowPosition, { AlignType, PositionType } from '../../Controllers/calcWindowPosition';
import { ISubWindowProps, PopupOptions, SubWindow, SubWindowCSS } from '../SubWindow';

const logger = new Logger('PopupWindow');

interface Props extends ISubWindowProps {
  targetRef: React.MutableRefObject<Element | HTMLElement | HTMLDivElement | null>;
  open: boolean;
  offset?: number;
  width: number;
  height: number;
  popupAlign?: AlignType;
  popupPosition?: PositionType;
  avoidParent?: boolean;
  disableOnBlur?: boolean;
}

export const PopupWindow: React.FC<Props> = ({
  id,
  parentId,
  open,
  onClose,
  targetRef,
  offset = 0,
  children,
  options,
  popupPosition,
  popupAlign,
  avoidParent,
  disableOnBlur=false,
  ...subwindowProps
}) => {
  const [position, setPosition] = useState<{ x: number; y: number } | undefined>();
  const show = position !== undefined;

  const close = useCallback(() => {
    setPosition((was) => {
      if (was) {
        logger.debug(`Closing window ${id}`);
        onClose && setTimeout(() => onClose(), 250);
      }
      return undefined;
    });
  }, [setPosition]);

  const focus = useCallback(() => {
    if (open) {
      //mainFuncs.showWindowById(id, true)
    }
  }, [id, open]);

  useEffect(() => {
    const offWindowMoved = window.Main.on(`window-moved-${parentId}`, close);
    const offWindowFocused = window.Main.on(`window-focused-${parentId}`, focus);
    return () => {
      offWindowMoved();
      offWindowFocused();
    };
  }, [parentId, close]);

  const calcPosition = useCallback(async () => {
    if (targetRef.current && parentId) {
      const triggerPosition = targetRef.current.getBoundingClientRect();
      const parentBounds: Electron.Rectangle = await window.Main.getWindowBounds(parentId);
      const workArea: Electron.Rectangle = await window.Main.getWindowWorkArea(parentId);

      if (parentBounds && workArea) {
        const result = calcWindowPosition({
          windowSize: { width: subwindowProps.width, height: subwindowProps.height },
          parentBounds,
          triggerPosition,
          workArea,
          position: popupPosition ?? 'bottom',
          align: popupAlign,
          offset,
          avoidParent
        });

        result && setPosition({x: result.bounds.x, y: result.bounds.y})
      }
    }
  }, [parentId]);

  useEffect(() => {
    if (open && targetRef?.current) {
      calcPosition();
    } else {
      close();
    }
  }, [open, close, calcPosition]);

  const onBlur = useCallback(() => {
    logger.debug(`onBlur, close on blur ${disableOnBlur ? 'disabled' : 'enabled'}`)
    if (!disableOnBlur) {
      close()
    }
  }, [disableOnBlur, close]);

  useEffect(() => {
    logger.debug(`showing subwindow: ${id} disableOnBlur: ${disableOnBlur}`);
  }, [show, disableOnBlur]);

  if (show) {
    return (
      <SubWindow
        id={id}
        show={show}
        focus={show}
        {...position}
        parentId={parentId}
        onBlur={onBlur}
        protectContent={true}
        alwaysOnTopLevel={'pop-up-menu'}
        options={{
          ...PopupOptions,
          ...options
        }}
        {...subwindowProps}
      >
        <style type="text/css">{SubWindowCSS}</style>
        {children}
      </SubWindow>
    );
  } else {
    return null;
  }
};

import { Logger } from "@openteam/app-util"
import { observer } from "mobx-react";
import React from "react";
import { ModalWindow } from "../Modal/ModalWindow";
import { SubWindowCSS } from "../SubWindow";
import { EditSubTeam } from "./EditSubTeam";

const logger = new Logger("EditSubTeamWindow")

type Props = {
  spaceId: string
  subTeamId?: string
  onClose: () => void
}

export const EditSubTeamWindow: React.FC<Props> = observer((props) => {
  const windowId = "add-pod"
  let width = 240
  let height = 250

  return (
    <ModalWindow
      id={windowId}
      parentId={'global-settings'}
      show={true}
      initialSize={{ width: width, height: height }}
      minHeight={height}
      minWidth={width}
      onClose={props.onClose}
      focus={true}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <EditSubTeam windowId={windowId} spaceId={props.spaceId} subTeamId={props.subTeamId} onClose={props.onClose} />
    </ModalWindow>

  )
})

import { H2, H3, Row } from "@openteam/design-system";
import { observer } from "mobx-react-lite";
import React from "react";
import { DataState } from "../../Data/DataState";


const CallViewMini: React.FC = () => {
  const call = DataState.activeCall!;
  const numVideo = Object.values(call.participants).filter(p => p.id != call.myUserId && p.streams.camera?.hasVideo).length;
  const numAudio = Object.values(call.participants).filter(p => p.id != call.myUserId && p.streams.camera?.hasAudio).length;

  return (
    <Row style={{justifyContent: 'start', flex: 1, margin: 8}}>
      <H3>In {numVideo ? '' : '🎧'} call with {call.participantList.length || 'no'} others{numVideo ? ` (📹 ${numVideo})` : ''}</H3>
    </Row>
  )
}

export default observer(CallViewMini);

import { Logger } from "@openteam/app-util";
import { UIState } from "../Data/UIState";
import { DSTheme } from "../DesignSystem/DSTheme";
import { platform } from '../Util/System'

const logger = new Logger('DockWindowUtil')

export interface IRect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export function getMaxDockSize(lengthRatio, isHorizontal) {
  let maxSize = DSTheme.DockMinSize

  if (UIState.currentDisplay?.workArea) {
    if (isHorizontal) {
      maxSize = UIState.currentDisplay?.workArea.width / lengthRatio
    } else {
      maxSize = UIState.currentDisplay?.workArea.height / lengthRatio
    }
  }

  return Math.max(maxSize, DSTheme.DockMinSize);
}

export function getMaxDockLength(length, isHorizontal) {
  let maxLength = 0
  if (isHorizontal) {
    maxLength = Math.min(length, UIState.currentDisplay?.workArea.width ?? length);
  } else {
    maxLength = Math.min(length, UIState.currentDisplay?.workArea.height ?? length);
  }
  return maxLength;
}


export function positionInDisplay(bounds: IRect, curDisp: Electron.Display | undefined, edgeLeeway: number = 0) {
  const newPos: any = {}


  if (curDisp) {

    if (bounds.x < curDisp.workArea.x + edgeLeeway) {
      newPos.x = curDisp.workArea.x
    }

    if (bounds.x + bounds.width > curDisp.workArea.x + curDisp.workArea.width - edgeLeeway) {
      newPos.x = Math.max(curDisp.workArea.x, curDisp.workArea.x + curDisp.workArea.width - bounds.width)
    }

    if (bounds.y < curDisp.workArea.y + edgeLeeway) {
      newPos.y = curDisp.workArea.y
    }

    if (bounds.y + bounds.height > curDisp.workArea.y + curDisp.workArea.height - edgeLeeway) {
      newPos.y = Math.max(curDisp.workArea.y, curDisp.workArea.y + curDisp.workArea.height - bounds.height)
    }

    if (platform === 'linux' && curDisp.id == UIState.primaryDisplay?.id &&
      curDisp.bounds.y > 0 && curDisp.bounds.y === curDisp.workArea.y) {
      // Linux seems to missreport the workarea on vertically arranged displays so we might need to shift the dock down
      // to prevent the drag bar being hidden under the header panel.
      if (newPos.y ?? bounds.y < curDisp.workArea.y + 27) {
        logger.info(`Shifting dock down to avoid gnome header`)
        newPos.y = curDisp.workArea.y + 27;
      }
    }
  }

  return newPos
}




export function getDisplayEdges(currentDisplay?: Electron.Display) {
  let leftEdge: number | undefined
  let rightEdge: number | undefined
  let topEdge: number | undefined
  let bottomEdge: number | undefined


  if (currentDisplay) {

    const curLeft = currentDisplay.bounds.x;
    const curRight = currentDisplay.bounds.x + currentDisplay.bounds.width;
    const curTop = currentDisplay.bounds.y;
    const curBottom = currentDisplay.bounds.y + currentDisplay.bounds.height;


    leftEdge = currentDisplay.workArea.x
    rightEdge = currentDisplay.workArea.x + currentDisplay.workArea.width
    topEdge = currentDisplay.workArea.y
    bottomEdge = currentDisplay.workArea.y + currentDisplay.workArea.height

    if (leftEdge != curLeft) {
      leftEdge = undefined
    }

    if (rightEdge != curRight) {
      rightEdge = undefined
    }

    if (bottomEdge != curBottom) {
      bottomEdge = undefined
    }

    for (const display of UIState.displays || []) {

      if (display.id != currentDisplay.id) {
        const dispLeft = display.bounds.x;
        const dispRight = display.bounds.x + display.bounds.width;
        const dispTop = display.bounds.y;
        const dispBottom = display.bounds.y + display.bounds.height;

        // check left edge
        if (curLeft === dispRight && dispTop < curBottom && dispBottom > curTop) {
          leftEdge = undefined
        }

        // check right edge
        if (curRight === dispLeft && dispTop < curBottom && dispBottom > curTop) {
          rightEdge = undefined
        }

        // check top edge
        if (curTop === dispBottom && dispLeft < curRight && dispRight > curLeft) {
          topEdge = undefined
        }

        // check bottom edge
        if (curBottom === dispTop && dispLeft < curRight && dispRight > curLeft) {
          topEdge = undefined
        }

      }
    }
  }

  return {
    leftEdge,
    rightEdge,
    topEdge,
    bottomEdge,
  }
}


export function getGenericWindowPosition(width, height) {
  const offset = 8
  const x = UIState.dockHorizontal ?
    UIState.dockPosition.x
    :
    UIState.dockPosition.onLeft ?
      UIState.dockPosition.x + UIState.dockPosition.width + offset
      :
      UIState.dockPosition.x - width - offset

  const y = UIState.dockHorizontal ?
    UIState.dockPosition.onTop ?? true ?
      UIState.dockPosition.y + UIState.dockPosition.height + offset
      :
      UIState.dockPosition.y - height - offset
    :
    UIState.dockPosition.y


  const screenPos = positionInDisplay({ width, height, x, y }, UIState.currentDisplay)

  return { x, y, ...screenPos }
}

import { Logger } from "@openteam/app-util";
import React, { useEffect, useMemo, useState } from "react";
import { FaArrowsAlt } from "react-icons/fa";
import { DataState } from "../../../Data/DataState";
import { DS2Button, DS2Icons } from "../../../DesignSystem/DS2";
import { DSButton } from "../../../DesignSystem/DSButton";
import { DSHSpacer, DSRow } from "../../../DesignSystem";
import { DSBody, DSH2, DSH3, DSPrint } from "../../../DesignSystem/DSText";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { Draggable } from "../../Draggable";
import { SubWindow, SubWindowCSS } from "../../SubWindow";
const logger = new Logger("ScreenShareWarning");


type Props = {
  parentId: string
}

type Pos = {
  x: number,
  y: number
}

export const ScreenShareWarning: React.FC<Props> = ({parentId}) => {
  const id = "ScreenShareWarning";
  const [show, setShow] = useState(false);
  const [pos, setPos] = useState < { x: number, y: number } | undefined>(undefined)

  useEffect(() => {
    async function loadWorkArea() {
      let workArea = await window.Main.getWindowWorkArea(parentId)
      if (!workArea) {
        workArea = (await window.Main.getPrimaryDisplay()).workArea
      }
      if (workArea) {
        setPos({
          x: (workArea.x + workArea.width - 380) /2,
          y: workArea.y + workArea.height - 30
        })
        setShow(true)
      }
    }
    loadWorkArea()
  }, [])

  useEffect(() => logger.debug(`${id} show ${show} at ${pos?.x},${pos?.y}`), [show, pos]);

  return (show ?
    <SubWindow
      id={id}
      key={id}
      initialPosition={pos}
      width={440}
      height={36}
      show={show}
      onCreated={() => setShow(true)}
      protectContent={true}
      alwaysOnTopLevel="status"
      options={{
        transparent: true,
        alwaysOnTop: true,
        frame: false,
        useContentSize: true,
        closable: true,
        resizable: false,
        skipTaskbar: true,
        focusable: true,
        maximizable: false,
        fullscreenable: false,
      }}
    >
      <style type="text/css">{SubWindowCSS}</style>

      <Draggable
        windowId={id}
        style={{
            width: "100%",
            height: "100%",
            backgroundColor: DSTheme.DockBackgroundColor,
            color: DSTheme.MainText,
            borderRadius: 4,
            padding: "0px 5px",
        }}>
        <DSRow
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
            }}>
          <FaArrowsAlt size={20}/>
          <DSBody style={{marginLeft: 15, marginRight: 5, fontWeight: 'bold'}}>
              You are currently sharing your screen
          </DSBody>
          <DSHSpacer size={8} />
          <DS2Button
            color='error'
            startIcon={DS2Icons.close}
            onClick={() => DataState.activeCall?.actions.shareScreen()}
          >
            Stop
          </DS2Button>
          <DSHSpacer size={8} />
          <DS2Button
            color="secondary"
            onClick={() => setShow(false)}
          >
            Hide
          </DS2Button>
        </DSRow>
      </Draggable>
    </SubWindow>
    : null
  );
};

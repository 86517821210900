import React, { Component, useEffect, useState, useRef } from 'react';

import * as Analytics from '../../Util/Analytics'

import { OTGlobals, OTUITree, WebcamStream } from "@openteam/app-core";

import { Logger } from '@openteam/app-util';
import { DSModalPanel } from '../Modal/ModalLayout';
import { DSImage } from '../../DesignSystem/DSImage';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DSHSpacer, DSPanel, DSRow } from '../../DesignSystem';
import { DSButton } from '../../DesignSystem/DSButton';
import { DSText } from '../../DesignSystem/DSText';
import { DataState } from '../../Data/DataState';
import { CallVideo } from '../Call/CallVideo';
import { DSSpinner } from '../../DesignSystem/DSSpinner';
import { DS2AcceptIconButton, DS2CaptureIconButton, DS2RejectIconButton } from '../../DesignSystem/DS2';

const logger = new Logger("Selfie")

interface ISelfieProps {
  spaceId: string
  size: number
  onClose: () => void
}

export const SelfieCam = (props: ISelfieProps) => {
  const space = DataState.spaces[props.spaceId]
  const _countdownSecs = 3;
  // @ts-ignore
  const imageCapture = useRef<ImageCapture>();
  const countdownTimer = useRef<ReturnType<typeof setTimeout>>();
  const [capturing, setCapturing] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>();
  const stream = useRef<WebcamStream | undefined>(undefined);
  const [streamVersion, setStreamVersion] = useState<number>(0);

  const [image, setImage] = useState<Blob>();
  const mounted = useRef<boolean>(false);
  // @ts-ignore

  useEffect(() => {
    mounted.current = true;
    initCamera()
    // return cancel
    return () => { mounted.current = false; cancel(); }
  }, [])

  const initCamera = async () => {
    logger.debug("init camera")
    const newStream = new WebcamStream(props.spaceId, OTUITree.auth.userId, OTGlobals.mediaDevices);
    await newStream.enableVideo();

    if (mounted.current) {
      const videoTrack = newStream.stream.getVideoTracks()[0];
      // @ts-ignore
      imageCapture.current = new ImageCapture(videoTrack);
      imageCapture.current.takePhoto()

      logger.debug("setting stream")
      stream.current = newStream;

      setStreamVersion(streamVersion + 1);

    } else {
      logger.debug("shutting down new stream");
      newStream.shutdown();
    }
  }

  const stop = () => {
    logger.debug("stopping")

    if (stream.current) {
      logger.debug("shutting down stream");
      stream.current.shutdown();
      stream.current = undefined;
    }

    if (countdownTimer.current) {
      clearTimeout(countdownTimer.current)
      countdownTimer.current = undefined
    }
    props.onClose()
  }

  const cancel = () => {
    stop()
    Analytics.logEvent("selfie_cancelled")
  }

  const startTimer = () => {
    setCountdown(_countdownSecs)
    setImage(undefined)
    countdownTimer.current = setInterval(() => setCountdown(curCountdown => countdownDec(curCountdown)), 1000)

  }

  const countdownDec = (curCountdown) => {
    const newCountdown = curCountdown - 1

    if (newCountdown == 0) {
      if (countdownTimer.current) {
        clearTimeout(countdownTimer.current)
        countdownTimer.current = undefined
      }
      _takePhoto()
    }

    return newCountdown
  }


  const clearPhoto = () => {
    setImage(undefined)
  }

  const _takePhoto = async () => {

    try {

      if (!stream || !imageCapture.current) {
        throw Error("No stream")
      }
      setCountdown(undefined)
      setCapturing(true)
      logger.debug("Capturing photo")


      let blob = await imageCapture.current.takePhoto()
      setCapturing(false)
      setImage(blob)

      Analytics.logEvent("selfie_taken")
    } catch (err) {
      logger.warn("Error capturing photo", err)
    }
  }

  const savePhoto = async () => {
    try {
      if (!image) {
        throw Error("No image")
      }

      await space.actions.updateTeamUserImage?.(
        image
      );
      Analytics.logEvent("selfie_saved")
    } catch (err) {
      logger.warn("Error saving photo", err)
    } finally {
      stop()
    }

  }

  return (
    <DSPanel style={{
      position: 'relative',
      borderRadius: DSTheme.BaseBorderRadius,
      width: props.size,
      height: props.size,
      overflow: 'hidden',
    }}>
      {
        image ?
          <DSImage url={URL.createObjectURL(image)}
            style={{
              borderRadius: DSTheme.BaseBorderRadius,
              overflow: 'hidden',
              objectFit: 'cover',
              width: props.size,
              height: props.size,
            }}
          />
          :
          <DSPanel style={{
            backgroundColor: 'black',
            borderRadius: DSTheme.BaseBorderRadius,
            overflow: 'hidden',
            position: 'relative'
          }}>
            {stream.current ?
              <CallVideo
                version={streamVersion}
                flip={true}
                track={stream.current.stream.getVideoTracks()[0]}
                hasVideo={true}
                style={{ width: props.size, height: props.size }}
              />
              :
              null
            }
          </DSPanel>
      }
      <div
        style={{
          content: '',
          position: "absolute",
          left: 0,
          top: 0,
          borderRadius: "50%",
          width: props.size, height: props.size,
          boxShadow: "0px 0px 0px 2000px rgba(255,255,255,0.3)"
        }}
      />
      {
        countdown != undefined ?
          <DSPanel style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            {
              capturing ?
                <DSSpinner size={80} />
                :
                countdown > 0 ?
                  <DSText style={{ color: "rgba(255,255,255,0.9)", fontSize: 80, fontWeight: 'bold' }}>{countdown}</DSText>
                  :
                  null
            }
          </DSPanel>
          :
          null
      }


      <DSRow style={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 12,
        justifyContent: 'center'
      }}>
        {
          image ?
            <DSRow>
              <DS2RejectIconButton onClick={clearPhoto} />
              <DSHSpacer size={12} />
              <DS2AcceptIconButton onClick={savePhoto} />
            </DSRow>
            :
            capturing || countdown ?
              <DSSpinner />
              :
              <DS2CaptureIconButton color='primary'
                onClick={startTimer}
              />
        }
      </DSRow>
    </DSPanel>
  )

}

import events from 'events'
import { IIdleTimer } from '@openteam/models';


var IDLETIME = 60 * 5

export class IdleTimerClass extends events.EventEmitter implements IIdleTimer {
  constructor() {
    super();
    this.start();
  }

  start = () => {
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);

    setInterval(this.checkIdle, 5 * 1000);
  };

  checkIdle = async () => {
    const idleTime = await window.Main.getSystemIdleTime();
    if (idleTime > IDLETIME) {
      this.emit("idle", true);
    } else {
      this.emit("idle", false);
    }
  };

  onFocus = () => {
    this.emit("present", true);
  };

  onBlur = () => {
    this.emit("present", false);
  };
}

export const IdleTimer = new IdleTimerClass();
import { useTransition, animated } from '@react-spring/web';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useCallback } from 'react';
import { FaPause } from 'react-icons/fa';
import { DataState } from '../../../Data/DataState';
import { DSCircleIconButton } from '../../../DesignSystem/DSButton';
import { DSTheme } from '../../../DesignSystem/DSTheme';
import HoverShade from './HoverShade';

type PauseButtonProps = {
  tileHovered: boolean;
  tileSize: number;
};

const PauseButton: React.FC<PauseButtonProps> = ({ tileHovered, tileSize }) => {

  const call = DataState.activeCall!;
  const onClick = useCallback(() => {
    call?.actions.setCallPaused(!call.isCallPaused)
  }, [])

  return (
    <HoverShade tileHovered={tileHovered}>
      <DSCircleIconButton
        icon={<FaPause color={call?.isCallPaused ? DSTheme.WarningColor : 'white'} size={Math.max(tileSize / 6, 20)} />}
        onClick={onClick}
        stopPropagation={{ onClick: true }}
        analyticsEvent="CallPauseSelf"
        style={{
          backgroundColor: tileHovered ? 'rgba(0,0,0,0.5)' : undefined,
          width: Math.max(tileSize / 3, 40),
          aspectRatio: '1 / 1'
        }}
      />
    </HoverShade>
  );
};

export default observer(PauseButton);

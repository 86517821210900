import React from 'react';

import { Logger } from "@openteam/app-util";
import { KSpaceId, KSpaceUserId } from '@openteam/models';
import { observer } from 'mobx-react';
import { DataState } from '../../Data/DataState';
import { getDummyUser } from '../../Data/tutorialDummy';
import { DS2ButtonProps, DS2CallButton, DS2CancelCallButton } from '../../DesignSystem';
import { OTUITree } from '@openteam/app-core';
import { Icons, SplitButton } from '@openteam/design-system';
import { addToRecentUsers } from '../../Data/UIState';

const logger = new Logger('UserCallButton');


interface Props extends DS2ButtonProps {
  spaceId: KSpaceId;
  userId: KSpaceUserId;
  addToRecent?: boolean
  analyticsPrefix?: string
}

const UserCallButton: React.FC<Props> = (
  { spaceId, userId, addToRecent = true, analyticsPrefix='', ...props }
) => {
  const space = DataState.spaces[spaceId]
  const isTutorial = userId == 'dummy'
  const user = isTutorial ? getDummyUser(userId) : space.users[userId]
  const call = user.roomId ? space.calls[user.roomId] : undefined;

  const callUser = React.useCallback((callMode) => {
    logger.debug(`Requesting ${callMode} call`)
    if (user.alerts?.called?.count && user.alerts?.called?.count > 0) {
      user.alerts.clear()
    } else {
      user.actions.callUser();
    }
    addToRecent && addToRecentUsers(spaceId, userId)

  }, [spaceId, userId, addToRecent, user.alerts?.called?.count])

  const callRequestUser = React.useCallback((callMode) => {
    logger.debug(`Requesting ${callMode} call`)
    if (user.alerts?.called?.count && user.alerts?.called?.count > 0) {
      user.alerts.clear()
    } else {
      user.actions.startCallRequest(callMode);
    }
    addToRecent && addToRecentUsers(spaceId, userId)

  }, [spaceId, userId, addToRecent, user.alerts?.called?.count])

  logger.debug(`Rendering callbutton, call.channelId: ${call?.channelId}, buttonStyle: ${props.buttonStyle}`, call);
  if (call) {
    if (call.id === DataState.activeCall?.id) {
      return null;
    } else if (call.channelId && space.pods[call.channelId]) {
      // User is in a call in a room
      return (
        <DS2CallButton
          key={`join-call-${call.id}`}
          analyticsEvent={`${analyticsPrefix}JoinCall`}
          analyticsArgs={{ userId: user.id, callId: call.id }}
          title={`Enter ${call.name}`}
          onClick={call.actions.joinCall}
          {...props}
        />
      )
    } else if (call.requests?.[OTUITree.auth.userId]?.active) {
      return (
        <DS2CancelCallButton
          key={`cancel-call-${user.roomId}`}
          analyticsEvent={`${analyticsPrefix}RequestCancel`}
          analyticsArgs={{ callId: call.id }}
          title="Cancel request to join"
          onClick={call.actions.cancelRequestJoinCall}
          {...props}
        />
      )
    } else {
      // User is in an ad-hoc call
      return (
        <DS2CallButton
          key={`request-to-join-${user.roomId}`}
          analyticsEvent={`${analyticsPrefix}RequestToJoinCall`}
          analyticsArgs={{ userId: user.id, callId: call.id }}
          title="Request to Join"
          onClick={call.actions.requestJoinCall}
          {...props}
        />
      )
    }
  } else if (user.outgoing?.calling) {
    return (
      <DS2CancelCallButton
        key={`cancel-call-${userId}`}
        analyticsEvent={`${analyticsPrefix}CancelCallUser`}
        analyticsArgs={{ userId: user.id }}
        title="Cancel"
        onClick={user.actions.callUser}
        {...props}
      />
    )
  } else if (user.outgoing?.callRequest?.senderActive) {
    return (
      <DS2CancelCallButton
        key={`cancel-call-request-${userId}`}
        analyticsEvent={`${analyticsPrefix}CancelCallRequestUser`}
        analyticsArgs={{ userId: user.id }}
        title="Cancel"
        onClick={user.outgoing?.callRequest?.cancel}
        {...props}
      />
    )
  } else if (user.status?.appCapabilities?.callRequest2) {

    if (DataState.activeCall) {
      return (
        <DS2CallButton
          key={`invite-to-join-${user.roomId}`}
          analyticsEvent={`${analyticsPrefix}RequestToJoinCall`}
          analyticsArgs={{ userId: user.id, callId: DataState.activeCall.id }}
          title="Invite to Join"
          onClick={() => callRequestUser(DataState.activeCall?.roomMode)}
          {...props}
        />
      )
    } else if ((props.buttonStyle ?? 'default') === 'default') {
      const {buttonStyle, ...otherProps} = props
      logger.debug(`Rendering SplitButton ${props.buttonStyle ?? 'default'}`)
      return (
        <SplitButton
          key={`call-${userId}`}
          analyticsEvent={`${analyticsPrefix}CallUser`}
          color='success'
          analyticsArgs={{ userId: user.id }}
          onClick={callRequestUser}
          badgeNum={user.alerts?.called?.count}
          options={[
            { value: "audio", icon: Icons.audio, title: "Request Audio Call" },
            { value: "video", icon: Icons.video, title: "Request Video Call" },
          ]}
          {...otherProps}
        />
      )
    } else {
     return (
      <DS2CallButton
        key={`call-${userId}`}
        analyticsEvent={`${analyticsPrefix}CallUser`}
        analyticsArgs={{ userId: user.id }}
        disabled={!user.status?.online}
        onClick={callRequestUser}
        badgeNum={user.alerts?.called?.count}
        {...props}
      />
    )
    }
  } else {
    return (
      <DS2CallButton
        key={`call-${userId}`}
        analyticsEvent={`${analyticsPrefix}CallUser`}
        analyticsArgs={{ userId: user.id }}
        disabled={!user.status?.online}
        onClick={callUser}
        badgeNum={user.alerts?.called?.count}
        {...props}
      />
    )
  }

}

export default observer(UserCallButton);

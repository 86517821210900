import { Logger } from "@openteam/app-util"
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { SubWindow, SubWindowCSS } from "../SubWindow";
import { closePodSettings, UIState } from "../../Data/UIState";
import { OTUITree } from "@openteam/app-core";
import { isMacOs } from "react-device-detect";
import PodSettings from "./PodSettings";

const logger = new Logger("AddPodWrapper")

type Props = {
}

const PodSettingsWrapper: React.FC<Props> = (props) => {
  const spaceId = OTUITree.userManager.currentTeamId

  let width = 320
  let height = 640


  const onClose = useCallback((podId: string) => closePodSettings(spaceId!, podId), [])

  return (spaceId && UIState.openRoomSettings[spaceId]) ? (
    <>
      {
        Object.keys(UIState.openRoomSettings[spaceId]).map((podId) => {
          const windowId = `room-settings-${spaceId}-${podId}`
          return (
            <SubWindow
              id={windowId}
              key={windowId}
              show={true}
              initialSize={{ width: width, height: height }}
              title={'Room Settings - Ehlo'}
              minHeight={height}
              minWidth={width}
              onClose={() => onClose(podId)}
              focus={true}
              options={
                {
                  alwaysOnTop: false,
                  hasShadow: true,
                  frame: false,
                  transparent: isMacOs ? true : false,
                  titleBarStyle: isMacOs ? "hidden" : "default",
                  trafficLightPosition: {
                    x: 10,
                    y: 16
                  },
                  autoHideMenuBar: true,
                  useContentSize: true,
                  resizable: false,
                  skipTaskbar: false,
                  maximizable: true,
                  focusable: true,
                  fullscreenable: false,
                  backgroundColor: undefined,
                  webPreferences: {
                    backgroundThrottling: false
                  }
                }
              }
            >
              <style type="text/css">{SubWindowCSS}</style>
              <PodSettings windowId={windowId} spaceId={spaceId} podId={podId} onClose={() => onClose(podId)} topLevel />
            </SubWindow>
          )
        })
      }
    </>
  ) : null
}

export default observer(PodSettingsWrapper);

import React from 'react';
import { IUISpaceUser, IUIUserMinimal, IUserMeetingStatus, TUserStatus } from '@openteam/models';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { CSSProperties } from 'react';
import { observer } from 'mobx-react';
import { DSText } from '../../DesignSystem/DSText';
import { DSPanel } from '../../DesignSystem';
import { getUserCustomStatusEmoji } from './StatusUtil';


interface IStatusDotProps {
  user: Partial<IUISpaceUser>
  style?: CSSProperties
  size?: number
  showCustomStatusEmoji?: boolean
}

export const StatusDot: React.FC<IStatusDotProps> = observer(({ user, style, size = 8, showCustomStatusEmoji = false }) => {
  let statusColor = DSTheme.OnlineColor;

  const userStatus = user.status?.customStatus || user.status?.status

  if (!user.status?.online) {
    statusColor = DSTheme.OfflineColor;
  } else if (userStatus == "AWAY") {
    statusColor = DSTheme.AwayColor;
  } else if (userStatus == "DND") {
    statusColor = DSTheme.BusyColor;
  }
  if (!user.status) {
    return null;
  } else {
    const emoji = getUserCustomStatusEmoji(user);
    if (emoji && showCustomStatusEmoji) {
      return <DSPanel style={{ height: size + 4, width: size + 3, alignItems: 'center', justifyContent: 'center' }}>
        <DSText style={{ fontSize: size }}>
          {emoji}
        </DSText>
      </DSPanel>
    }
    const borderWidth = Math.round(size *0.2)
    return (
      <div
        style={{
          backgroundColor: statusColor,
          height: size,
          width: size,
          borderRadius: 999,
          borderWidth,
          borderColor: DSTheme.BackgroundColor,
          borderStyle: 'solid',
          boxSizing: "content-box",
          ...style,
        }}
      />
    );
  }
})

export default StatusDot;

import React, { CSSProperties, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Logger } from "@openteam/app-util";
import { observer } from "mobx-react";
import { DataState } from "../../Data/DataState";
import { OTUITree } from "@openteam/app-core";
import { getUserInteresting } from "../User/UserUtil";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSAnimateListGrow } from "../../DesignSystem/DSAnimate";
import { makeAutoObservable } from "mobx";
import { DockUserIcon } from "./DockUserIcon";
import { dockAnimatingStart, dockAnimatingStop, UIState } from "../../Data/UIState";

const logger = new Logger("DockOtherSpaceAlerts")


interface IDockOtherSpaceAlertsProps {
  spaceId: string
  userIds: string[]
  frame?: HTMLDivElement | null
}

export const DockOtherSpaceAlerts = observer((props: IDockOtherSpaceAlertsProps) => {
  const itemLength = Math.floor(UIState.dockSize * (1 - DSTheme.DockMargin * 2))
  const tileSize = Math.floor(UIState.dockSize * DSTheme.DockUserRatio)

  const renderUser = useCallback((userId) => DataState.spaces[props.spaceId].users[userId] && <DockUserIcon
    key={`dock-user-${props.spaceId}-${userId}`}
    spaceId={props.spaceId}
    userId={userId}
    size={tileSize}
    showSpaceIcon={true}
  />, [tileSize, props.frame, props.spaceId])

  //useLayoutEffect(() => logger.debug(`Rendering`))
  return (
    <DSAnimateListGrow
      label="DockOtherSpaceAlerts"
      horizontal={UIState.dockHorizontal}
      width={UIState.dockHorizontal ? itemLength : UIState.dockSize}
      height={UIState.dockHorizontal ? UIState.dockSize : itemLength}
      spacing={(itemLength - tileSize) / 2}
      renderItem={renderUser}
      items={props.userIds}
      outerStyle={Styles.outerStyle}
      itemStyle={{
        position: 'relative',
      }}
      onListStart={() => dockAnimatingStart('dock-other-users')}
      onListRest={() => dockAnimatingStop('dock-other-users')}
    />
  );
})

class StylesClass {
  constructor() {
    makeAutoObservable(this)
  }
  get outerStyle(): CSSProperties {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }
}

const Styles = new StylesClass()
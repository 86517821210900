import { Logger } from "@openteam/app-util";
import { IconActionButton, IconActionButtonProps } from "@openteam/design-system";
import React from "react";
import { FaPhone } from "react-icons/fa";
import { DataState } from "../../../Data/DataState";
import { useHoverIntent } from "../../../Util/HoverIntent";
const logger = new Logger('LeaveButton');


export const LeaveButton: React.FC<IconActionButtonProps> = ({ style, size, color, ...otherProps }) => {
  //const [hovered, setHovered] = React.useState(false)
  const [hovered, ref] = useHoverIntent<HTMLButtonElement>({})

  return (
    <IconActionButton
      ref={ref}
      color='error'
      data-tooltip="Leave Call"
      analyticsEvent="callLeave"
      onClick={() => {
        DataState.activeCall?.actions.leaveCall();
      }}
      {...otherProps}
    >
      <FaPhone style={{ transition: 'transform 300ms ease', transform: hovered ? 'rotate(-135deg)' : 'rotate(0deg)' }} />
    </IconActionButton>
  );
};

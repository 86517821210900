import React, { CSSProperties } from 'react';

import { IUISpaceUser, IUIUserMinimal, KSpaceId, KSpaceUserId } from '@openteam/models';
import { observer } from 'mobx-react';
import { DataState } from '../Data/DataState';
import { DSPanel, DSRow, DSWrappedRow } from '../DesignSystem';
import UserIcon from '../Components/User/UserIcon';
import { DSLargePrint, DSSmallPrint } from '../DesignSystem/DSText';
import { DSTheme } from '../DesignSystem/DSTheme';
import { DSButton } from '../DesignSystem/DSButton';

interface IUserIconListProps {
  spaceId: KSpaceId
  users: IUIUserMinimal[]
  showName?: boolean
  highlightUserIds?: KSpaceUserId[]
  maxIcons?: number
  tileSize?: number
  overlap?: number
  outerStyle?: CSSProperties
  before?: JSX.Element
  onUserClick?: (userId: string) => void;
  onClickRemainder?: () => void;
  wrap?: boolean
}

export const userIconListWidth = (numUsers: number, maxIcons: number, tileSize: number = 60, scaling: number = 1, overlap = 10) => {
  const numIcons = Math.min(numUsers, maxIcons + 1);
  return numIcons * (tileSize * scaling) - ((numIcons - 1) * overlap);
}

export const UserIconList = observer((props: IUserIconListProps) => {

  const {
    tileSize = 24,
    overlap = 10,
    maxIcons,
    users,
    showName,
    outerStyle,
    before,
    onUserClick,
    wrap = true
  } = props;

  const limitUsers = maxIcons ? users.slice(0, maxIcons) : users;
  const remainder = users.length - limitUsers.length


  return (
    <DSRow className="userIconRow" style={{ alignItems: 'center', ...wrap ? { flexWrap: 'wrap' } : {}, ...outerStyle }}>
      {before}
      {
        limitUsers.map((user, index) => (
          user && <DSPanel
            key={`userlist-${props.spaceId}-${user.id}`}
            style={{
              marginRight: (limitUsers.length - 1) > index || remainder ? -overlap : undefined,
              flexDirection: "column",
              width: tileSize
            }}
          >
            <UserIcon
              key={user.id}
              size={tileSize}
              user={user}
              onClick={onUserClick ? () => onUserClick(user.id) : undefined}
              hideStatusDot={true}
              outerStyle={{
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "transparent",
                ...props.highlightUserIds?.includes(user.id) && {
                  borderColor: DSTheme.EmphasisColor
                }
              }}
            />
            {showName ? <DSLargePrint style={{
              whiteSpace: 'nowrap',
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center",
              width: tileSize,
            }} >
              {user.name}
            </DSLargePrint> : undefined}
          </DSPanel>))
      }
      {
        remainder ?
          <DSButton
            onClick={props.onClickRemainder}
            style={{
            borderRadius: "50%",
            width: tileSize,
            height: tileSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: DSTheme.UserBlankColor,
            zIndex: 1,
          }}>
            <DSSmallPrint style={{}}>+{remainder}</DSSmallPrint>
          </DSButton>
          :
          null
      }
    </DSRow>
  )
})

export default UserIconList
import { Database, serverTimestamp, ref, set, get, push, orderByChild, equalTo, query, child, update, onValue, onDisconnect, onChildAdded, onChildRemoved, remove, runTransaction} from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions'

import {
  IMeetingTokenDetails,
  IOTUser,
  ITeamCall,
  ITeamDoc,
  ITeamRoom,
  ITeamRoomConfig,
  ITeamRoomUser,
  ITeamRoomUserRequest,
  ITeamUserPrefs,
  ITeamUserPrefsPod,
  ITeamUserPrefsUser,
  IUserDoc,
  IUserTeamReq,
  KSpaceUserId,
  TActionType,
  TReceiverStatus,
} from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { OTGlobals } from "../OTGlobals";
import { OTAppCoreData } from "../OTAppCoreData";
import { FireUtils } from "./FireUtils";
import { ExternalMeetingDb, OTUITree } from "..";
import validate from "validate.js";
import { unWatch, unWatchQuery, watch, watchQuery } from './FireWatch';


const logger = new Logger("FireDb");

export class FireDb {
  static disconnectLeeway = 10 * 1000;


  static watchConfig = (fbDb: Database, callback: (doc) => void) => {
    onValue(
      ref(fbDb, '/config/'),
      (snapshot) => callback(snapshot.val())
    );
  }


  static createTeam = async (name: string) => {
    const _createTeam = httpsCallable(getFunctions(), "createTeam");
    const result = await _createTeam({ teamName: name })

    return (result.data as any).teamId;
  }

  static getMeetingLink = async (name: string, teamId: string, channelId?: string) => {
    const _getMeetingLink = httpsCallable(getFunctions(), "getMeetingLink");
    const result = await _getMeetingLink({ teamId, channelId, name })

    return (result.data as any).url;
  }

  static loadMeetingToken = async (meetToken: string) => {
    const _loadMeetingToken = httpsCallable(getFunctions(), "loadMeetingToken");
    const result = await _loadMeetingToken({ meetingToken: meetToken })

    const data = result.data as IMeetingTokenDetails;

    logger.debug("loaded meeting details: ", data);
    return data;
  }

  static getInviteLink = async (teamId: string) => {
    var getInviteLink = httpsCallable(getFunctions(), "getInviteLink");
    var result = await getInviteLink({ teamId: teamId });

    return (result.data as any).url;
  };

  static inviteUser = async (teamId: string, email: string) => {
    var inviteByEmailToken = httpsCallable(getFunctions(), "inviteByEmailToken");
    var result = await inviteByEmailToken({ teamId: teamId, email: email });

    return (result.data as any).success;
  };

  static sendAdminEmail = async (subject: string, text: string, replyTo?: string) => {
    var sendAdminEmail = httpsCallable(getFunctions(), "sendAdminEmail");
    var result = await sendAdminEmail({ subject, text, replyTo });

    return (result.data as any).success;
  };

  static watchTeamUserExternal = (
    fbDb: Database,
    teamId: string,
    userId: string,
    callback: (doc) => void
  ) => {
    const path = `/teamExternal/${teamId}/users/${userId}`;
    watch(fbDb, path, callback);
  };

  static unwatchTeamUserExternal = (
    fbDb: Database,
    teamId: string,
    userId: string,
  ) => {
    const path = `/teamExternal/${teamId}/users/${userId}`;
    unWatch(fbDb, path);
  };


  static watchTeamRoomsExternal = (
    fbDb: Database,
    teamId: string,
    callback: (doc) => void
  ) => {
    const path = `/teamExternal/${teamId}/rooms`;
    watch(fbDb, path, callback);
  };

  static unwatchTeamRoomsExternal = (
    fbDb: Database,
    teamId: string,
  ) => {
    const path = `/teamExternal/${teamId}/rooms`;
    unWatch(fbDb, path);
  };




  static watchTeamChannelsExternal = (
    fbDb: Database,
    teamId: string,
    callback: (doc) => void
  ) => {
    const path = `/teamExternal/${teamId}/channels`;
    watch(fbDb, path, callback);
  };

  static unwatchTeamChannelsExternal = (
    fbDb: Database,
    teamId: string,
  ) => {
    const path = `/teamExternal/${teamId}/channels`;
    unWatch(fbDb, path);
  };



  static watchTeamChannelExternal = (
    fbDb: Database,
    teamId: string,
    channelId: string,
    callback: (doc) => void
  ) => {
    const path = `/teamExternal/${teamId}/channels/${channelId}`;
    watch(fbDb, path, callback);
  };

  static unwatchTeamChannelExternal = (
    fbDb: Database,
    teamId: string,
    channelId: string,
  ) => {
    const path = `/teamExternal/${teamId}/channels/${channelId}`;
    unWatch(fbDb, path);
  };



  static watchTeamExternalAddress = (
    fbDb: Database,
    teamId: string,
    address: string | undefined,
    callback: (doc) => void
  ) => {
    const path = `/teamExternal/${teamId}/address/${address}`;
    watch(fbDb, path, callback);
  };

  static unwatchTeamExternalAddress = (
    fbDb: Database,
    teamId: string,
    address: string | undefined
  ) => {
    const path = `/teamExternal/${teamId}/address/${address}`;
    unWatch(fbDb, path);
  };

  static getRoom = async (fbDb: Database, teamId: string, roomId: string) => {
    const doc = await get(ref(fbDb, `/teams/${teamId}/rooms/${roomId}`))

    return doc.val() as ITeamRoom;
  };

  static createRoom = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    sessionId: string,
    roomConfigDoc: ITeamRoomConfig,
    roomId?: string
  ) => {
    logger.debug(
      `creating room userId=${userId}, teamId=${teamId}, roomConfigDoc=${roomConfigDoc}`
    );
    const useSFU = OTAppCoreData.useSFU;

    roomConfigDoc.crDate = serverTimestamp();
    roomConfigDoc.useSFU = roomConfigDoc.call && useSFU || false;
    roomConfigDoc.ownerUserId = userId;

    const teamData = OTGlobals.getTeamData(teamId);
    const user = FireDb.getRoomUser(teamData.getTeamUser(userId));

    const roomDoc = {
      crDate: serverTimestamp(),
      config: roomConfigDoc,
      users: { [userId]: { ...user, currentSessionToken: sessionId } },
    };
    logger.debug("createRoom with roomDoc=%o", roomDoc);

    if (!roomId) {
      roomId = push(ref(fbDb, "/teams/" + teamId + "/rooms/")).key!;
    }

    await set(ref(fbDb, `/teams/${teamId}/rooms/${roomId}`), roomDoc);

    await FireDb.joinTeamRoom(fbDb, teamId, userId, sessionId, roomId, user);

    OTGlobals.analytics?.logEvent("fire__create_room");
    return roomId;
  };

  static joinTeamRoom = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string | null,
    roomUser: ITeamRoomUser,
    skipLeave: boolean = false
  ) => {
    logger.info(`user ${userId} joining room ${teamId}:${roomId}`);

    // This query will fail with an index error if forced to run against
    // the server. Therefore it can only be run whilst the team document is watched.

    if (!skipLeave) {
      var snap = await get(
        query(
          ref(fbDb, `/teams/${teamId}/rooms`),
          orderByChild(`users/${userId}/id`),
          equalTo(userId)
        ))

      var roomIds: string[] = [];
      snap.forEach((doc) => {
        roomIds.push(doc.key as string);
      });

      for (const docKey of roomIds) {
        logger.info(`checking room ${docKey}`);

        if (docKey != roomId) {
          logger.info(`leaving room ${docKey}`);
          await FireDb.leaveTeamRoom(fbDb, teamId, userId, sessionToken, docKey!);
        }
      }

    }

    if (roomId) {
      roomUser.crDate = serverTimestamp();

      roomUser.currentSessionToken = sessionToken;

      const roomRef = ref(fbDb, `/teams/${teamId}/rooms/${roomId}`)
     
      //try {
      //  await runTransaction(roomRef, (roomUsersDoc) => {
      //    if (roomUsersDoc) {
      //      roomUsersDoc[userId] = roomUser
      //    }
      //    return roomUsersDoc;
      //  })
      //} catch (err) {
      //  logger.warn(`Error joining room`, err);
      //}

      const snapshot = await get(roomRef);
      const roomDoc = snapshot.val();

      if (roomDoc) {
        await set(child(roomRef, `/users/${userId}`), roomUser);
      }
    }
  };


  static requestJoinTeamRoom = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string | null,
    roomUser: ITeamRoomUser
  ) => {
    logger.info(`request joining room ${teamId}:${roomId}:${userId}`);

    const requestDoc: ITeamRoomUserRequest = {
      userId: userId,
      name: roomUser.name,
      imageUrl: roomUser.imageUrl,
      crDate: serverTimestamp(),
      active: true,
      senderStatus: 'pending',
      senderSessionToken: sessionToken,
      receiverStatus: 'waiting'

    }
    logger.debug("requestJoinTeamRoom userId=%s roomUser=%o", userId, requestDoc);

    const roomRef = ref(fbDb, `/teams/${teamId}/rooms/${roomId}`)
    await set(child(roomRef, `/requests/${userId}`), requestDoc);

  };

  static cancelRequestJoinTeamRoom = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    roomId: string | null
  ) => {
    logger.info(`cancel request joining room ${teamId}:${roomId}:${userId}`);

    const roomRef = ref(fbDb, `/teams/${teamId}/rooms/${roomId}`)
    await set(child(roomRef, `/requests/${userId}`), null);
  };

  static respondRequestJoinCall = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    roomId: string | null,
    response: TReceiverStatus
  ) => {
    logger.info(`respond to request joining room ${teamId}:${roomId}:${userId}`);

    const roomRef = ref(fbDb, `/teams/${teamId}/rooms/${roomId}`)

    const requestDoc = {
      receiverUserId: OTUITree.auth.userId,
      receiverStatus: response
    }

    // if (response == 'rejected') {
    //   requestDoc["active"] = false
    // }
    await update(child(roomRef, `/requests/${userId}`), requestDoc);

    if (response == 'accepted') {
      await set(child(roomRef, `/invites/${userId}`), true)
    }
  };

  static roomPresenceCallbacks = {};

  static setupRoomPresence = (
    fbDb: Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string
  ) => {
    var key = `${teamId}:${roomId}`;
    FireDb.roomPresenceCallbacks[key] = (snapshot) =>
      FireDb.setRoomSession(fbDb, teamId, userId, sessionToken, roomId, snapshot);

    onValue(ref(fbDb, ".info/connected"), FireDb.roomPresenceCallbacks[key]);
  };

  static setRoomSession = (
    fbDb: Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string,
    snapshot
  ) => {
    var statusRef = ref(fbDb, `/teams/${teamId}/rooms/${roomId}/users/${userId}/status`)
    var sessionRef = child(child(statusRef, "activeSessions"), sessionToken!);

    var isOfflineForDatabase = {
      last_changed: serverTimestamp(),
    };

    var isOnlineForDatabase = {
      last_changed: serverTimestamp(),
      sessionToken: sessionToken,
    };

    if (snapshot.val() == false) {
      return;
    }

    onDisconnect(sessionRef)
      .set(null)
      .then(function () {
        set(sessionRef, true);
      });

    onDisconnect(statusRef)
      .update(isOfflineForDatabase)
      .then(function () {
        update(statusRef, isOnlineForDatabase);
      });
  };

  static leaveTeamRoom = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string
  ) => {
    //await FireDb.removeRoomPresence(fbDb, teamId, userId, sessionToken, roomId);
    await set(ref(fbDb, `/teams/${teamId}/rooms/${roomId}/users/${userId}`), null);
  };

  /* static removeRoomPresence = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string
  ) => {
    const key = `${teamId}:${roomId}`;

    logger.debug(
      "removeRoomPresence, teamId=%s, roomId=%s, callback=%o",
      teamId,
      roomId,
      FireDb.roomPresenceCallbacks[key]
    );

    if (FireDb.roomPresenceCallbacks[key]) {
      fbDb.ref(".info/connected").off("value", FireDb.roomPresenceCallbacks[key]);
      delete FireDb.roomPresenceCallbacks[key];
    }

    var statusRef = ref(fbDb, `/teams/${teamId}/rooms/${roomId}/users/${userId}/status`)
    var sessionRef = child(statusRef, "activeSessions").child(sessionToken!);

    statusRef.onDisconnect().cancel();
    sessionRef.onDisconnect().cancel();
  };
 */
  static getRoomUser = (user: IOTUser) => ({
    id: user.userId,
    name: user.name,
    imageUrl: user.imageUrl || null,
  });

  static inviteTeamRoom = async (
    fbDb: Database,
    teamId: string,
    roomId: string,
    userId
  ) => {
    await set(ref(fbDb, `/teams/${teamId}/rooms/${roomId}/invites/${userId}`), true);
  };

  static acceptTeamExternalAddressWaiting = async (
    fbDb: Database,
    teamId,
    address,
    userId,
    roomId
  ) => {
    var addressRef = ref(fbDb, `/teamExternal/${teamId}/address/${address}`)
    var waitingRef = child(addressRef, `waiting/${userId}`);

    await update(waitingRef, {
      roomId: roomId,
      status: "A",
    });
  };

  static holdTeamExternalAddressWaiting = async (
    fbDb: Database,
    teamId,
    address,
    userId
  ) => {
    var addressRef = ref(fbDb, `/teamExternal/${teamId}/address/${address}`)
    var waitingRef = child(addressRef, `waiting/${userId}`);

    await update(waitingRef, {
      status: "H",
    });
  };

  static rejectTeamExternalAddressWaiting = async (
    fbDb: Database,
    teamId,
    address,
    userId
  ) => {
    var addressRef = ref(fbDb, `/teamExternal/${teamId}/address/${address}`)
    var waitingRef = child(addressRef, `waiting/${userId}`);

    await update(waitingRef, {
      status: "R",
    });
  };


  static updateTeamExternalUserWaiting = async (
    fbDb: Database,
    teamId,
    myUserId,
    userId,
    status,
    roomId?: string
  ) => {
    var addressRef = ref(fbDb, `/teamExternal/${teamId}/users/${myUserId}`)
    var waitingRef = child(addressRef, `waiting/${userId}`);

    await update(waitingRef, {
      status: status,
      roomId: roomId || null,

    });
  };

  static updateTeamExternalChannelWaiting = async (
    fbDb: Database,
    teamId,
    channelId,
    userId,
    status,
    roomId?: string
  ) => {

    var addressRef = ref(fbDb, `/teamExternal/${teamId}/channels/${channelId}`)
    var waitingRef = child(addressRef, `waiting/${userId}`);

    await update(waitingRef, {
      status: status,
      roomId: roomId || null,

    });
  };


  static updateTeamExternalRoomWaiting = async (
    fbDb: Database,
    teamId,
    roomId,
    userId,
    status,
  ) => {

    var addressRef = ref(fbDb, `/teamExternal/${teamId}/rooms/${roomId}`)
    var waitingRef = child(addressRef, `waiting/${userId}`);

    await update(waitingRef, {
      status: status,
      roomId: roomId || null,

    });
  };

  static watchTeamAccessRequests = (
    fbDb: Database,
    teamId: string,
    callback: (doc) => void
  ) => {
    const path = `/teamAccessReq/${teamId}`;
    watchQuery(
      query(ref(fbDb, path), orderByChild("status"), equalTo("waiting")),
      callback
    )
  };

  static unwatchTeamAccessRequests = (fbDb: Database, teamId) => {
    const path = `/teamAccessReq/${teamId}`;
    unWatchQuery(query(ref(fbDb, path), orderByChild("status"), equalTo("waiting")))
  };

  static approveTeamAccessReq = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    userDoc: IUserTeamReq
  ) => {
    var teamUserDoc = {
      id: userId,
      dateJoined: serverTimestamp(),
      email: userDoc.email,
      name: userDoc.name,
      imageUrl: userDoc.imageUrl || null,
    };

    await set(ref(fbDb, `/teams/${teamId}/users/${userId}`), teamUserDoc);
    await FireDb.respondToTeamAccessReq(fbDb, teamId, userId, "accepted");
  };

  static rejectTeamAccessReq = async (
    fbDb: Database,
    teamId: string,
    userId: string
  ) => {
    await FireDb.respondToTeamAccessReq(fbDb, teamId, userId, "rejected");
  };

  static respondToTeamAccessReq = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    status: "accepted" | "rejected"
  ) => {
    await set(ref(fbDb, `/teamAccessReq/${teamId}/${userId}/status`), status);
  };

  static startCall = async (
    fbDb: Database,
    teamId: string,
    myUserId: string,
    mySessionToken: string,
    userId,
    currentRoomId
  ) => {
    var snapshot = await get(ref(fbDb, "/teams/" + teamId + "/calls/" + userId))
    var callData: ITeamCall = snapshot.val();

    if (callData.userId != myUserId) {
      return;
    }

    if (!currentRoomId) {
      logger.debug(`creating room with userId=${callData.userId}`);

      var roomConfigDoc: ITeamRoomConfig = {
        name: "Meeting Room",
        desc: "",
        enabled: true,
        call: true,
        permanent: false,
      };

      currentRoomId = await FireDb.createRoom(
        fbDb,
        teamId,
        callData.userId,
        mySessionToken,
        roomConfigDoc
      );
    }

    var callDoc = {
      senderStatus: "started",
      roomId: currentRoomId,
    };
    logger.debug("roomId is", currentRoomId);

    await update(ref(fbDb, "/teams/" + teamId + "/calls/" + userId), callDoc);

    logger.debug("set room id on call", callDoc);
  };

  static watchSubscriptions: (() => void)[] = [];

  static watchAlerts = (
    fbDb: Database,
    myUserId: string,
    teamId: string,
    callback: (snapshot) => void,
    removedCallback: (snapshot) => void
  ) => {
    FireDb.watchSubscriptions.push(
      onChildAdded(ref(fbDb, "/alerts/" + teamId + "/" + myUserId), (doc) => {
        callback({ id: doc.key, ...doc.val() });
      })
    );

    FireDb.watchSubscriptions.push(
      onChildRemoved(ref(fbDb, "/alerts/" + teamId + "/" + myUserId), (doc) => {
        removedCallback({ id: doc.key, ...doc.val() });
      })
    );
  };

  static unwatchAlerts = (fbDb: Database, myUserId: string, teamId: string) => {
    FireDb.watchSubscriptions.forEach(f => f())
    FireDb.watchSubscriptions = []
  };

  static addAlert = (
    fbDb: Database,
    myUserId: string,
    teamId: string,
    userId: string,
    actionType: TActionType,
    sendTime?: number
  ) => {
    var msg = {
      fromUserId: myUserId,
      toUserId: userId,
      crDate: sendTime || serverTimestamp(),
      actionType: actionType,
    };

    push(ref(fbDb, "/alerts/" + teamId + "/" + userId), msg);
  };

  static removeAlert = (
    fbDb: Database,
    myUserId: string,
    teamId: string,
    alertId: string
  ) => {
    remove(child(ref(fbDb, `/alerts/${teamId}/${myUserId}`), alertId));
  };

  static removeUserAlerts = async (
    fbDb: Database,
    myUserId: string,
    teamId: string,
    userId: string
  ) => {
    const snap = await get(query(
      ref(fbDb, `/alerts/${teamId}/${myUserId}`),
      orderByChild("fromUserId"),
      equalTo(userId)
    ));

    snap.forEach((doc) => {

      remove(doc.ref)
    });
  };

  static removeAllAlerts = (
    fbDb: Database,
    myUserId: string,
    teamId: string,
    alertIds: string[]
  ) => {
    var updates = {};
    alertIds.forEach((id) => (updates[id] = null));

    update(ref(fbDb, `/alerts/${teamId}/${myUserId}`), updates);
  };

  static updateTeamUserPreference = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    key: keyof ITeamUserPrefs,
    value
  ) => {
    await update(ref(fbDb, `/teams/${teamId}/users/${userId}/preferences/${key}`), value);
  };

  static setTeamUserPreference = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    key: keyof ITeamUserPrefs,
    value
  ) => {
    await set(ref(fbDb, `/teams/${teamId}/users/${userId}/preferences/${key}`), value);
  };

  static updateTeamUserContactPreferences = async (
    fbDb: Database,
    teamId: string,
    myUserId: string,
    userId: string,
    value: ITeamUserPrefsUser
  ) => {
    await update(ref(fbDb, `/teams/${teamId}/users/${myUserId}/preferences/users/${userId}`), value);
  };

  static updateTeamUserPodPreferences = async (
    fbDb: Database,
    teamId: string,
    myUserId: string,
    podId: string,
    value: ITeamUserPrefsPod
  ) => {
    await update(ref(fbDb, `/teams/${teamId}/users/${myUserId}/preferences/pods/${podId}`), value);
  };

  static updateTeamUserImage = async (
    fbDb: Database,
    userId: string,
    teamId: string,
    file
  ) => {
    FireUtils.uploadUserFile(teamId, userId, "profile", file, (url) => {
      var userObj = {
        imageUrl: url,
      };

      update(ref(fbDb, `/teams/${teamId}/users/${userId}`), userObj);
    });
  };

  static checkTeamRoomAccess = async (
    fbDb: Database,
    teamId: string,
    roomId: string
  ) => {
    var roomDoc = undefined;
    try {
      var snapshot = await get(ref(fbDb, `/teams/${teamId}/rooms/${roomId}`))
      roomDoc = snapshot.val();

      logger.debug("checkTeamRoomAccess", teamId, roomId, roomDoc);
    } catch (error) {
      logger.debug("error couldn't access room", teamId, roomId);
    }

    return roomDoc != undefined;
  };

  static updateRoomConfig = async (
    fbDb: Database,
    teamId: string,
    roomId: string,
    roomConfigDoc: Partial<ITeamRoomConfig>
  ) => {
    await update(ref(fbDb, `/teams/${teamId}/rooms/${roomId}/config`), roomConfigDoc);
  };

  static closeRoom = async (fbDb: Database, teamId: string, roomId: string) => {
    logger.info("deleting room", roomId);

    remove(ref(fbDb, `/teams/${teamId}/rooms/${roomId}`));
    OTGlobals.analytics?.logEvent("fire__close_room");
  };

  static checkTeamAccess = async (
    fbDb: Database,
    userId: string,
    teamId: string
  ) => {
    var teamName = undefined;
    try {
      var snapshot = await get(ref(fbDb, `/teams/${teamId}/users/${userId}`))
      var teamUserDoc = snapshot.val();

      logger.debug("checkTeamAccess", teamId, teamUserDoc);

      teamName = teamUserDoc?.name;
    } catch (error) {
      logger.debug("error couldn't access team", teamId);
    }

    return teamName != undefined;
  };

  static getTeamPathId = async (fbDb: Database, teamPath: string) => {
    var doc = await get(ref(fbDb, `/teamPath/${teamPath}`))
    var teamId = doc.val();
    return teamId;
  };

  // static generateTeamPath = async (fbDb, teamName: string, maxTries: number = 100, randMax: number = 1000) => {
  //   const basePath = teamName.replace(/[^a-z_\-A-Z0-9]/g, "").toLowerCase();
  //   const i = 0;
  //   let teamPath = basePath;
  //   const history = {};

  //   const getRandomInt = (max: number) => Math.floor(Math.random() * Math.floor(max));

  //   const nextInt = () => {
  //     let n = getRandomInt(randMax)

  //     while (n in history)
  //       n = getRandomInt(randMax)

  //     history[n] = true;
  //     return n;
  //   }

  //   while (i < maxTries) {
  //     const teamId = await FireDb.getTeamPathId(fbDb, teamPath);
  //     if (!teamId) {
  //       return teamPath;
  //     }
  //     const n = nextInt();
  //     teamPath = `${basePath}${n}`;
  //   }
  //   throw Error(`failed to generate teamPath (maxTries exceeded)`);
  // }


  // static createTeam = async (
  //   fbDb: Database,
  //   teamName: string,
  //   ownerUserId: KSpaceUserId,
  //   ownerUser: IUserDoc
  // ) => {

  //   logger.debug(`createTeam teamName: ${teamName} ownerUserId: ${ownerUserId}`);
  //   await validate.async({ teamName }, teamNameConstraints);

  //   const teamPath = await FireDb.generateTeamPath(fbDb, teamName)

  //   logger.info(`generateTeamPath: teanName: ${teamName} teamPath: ${teamPath}`);

  //   const teamObj: ITeamDoc = {
  //     teamName: teamName,
  //     teamPath: teamPath,
  //     crDate: serverTimestamp(),
  //     ownerUserId,
  //     admin: {
  //       [ownerUserId]: {
  //         id: ownerUserId,
  //       },
  //     },
  //     calls: {},
  //     hideStaticRooms: true,
  //     users: {
  //       [ownerUserId]: {
  //         id: ownerUserId,
  //         dateJoined: serverTimestamp(),
  //         email: ownerUser.email,
  //         name: ownerUser.name,
  //         imageUrl: ownerUser.imageUrl || null,
  //       },
  //     },
  //   };
  //   const teamId = push(ref(fbDb, "/teams"), teamObj).key!;
  //   await set(ref(fbDb, `/teamPath/${teamPath}`), teamId);
  //   await set(ref(fbDb, `/users/${ownerUserId}/teams/${teamId}`), teamPath);
  //   return teamId;
  // };

  // static adminRemoveUserFromTeam = async (
  //   fbDb: Database,
  //   teamId: string,
  //   userId: KSpaceUserId,
  // ) => {

  //   var snap = await get(query(
  //     ref(fbDb, `/teams/${teamId}/rooms`),
  //     orderByChild(`users/${userId}/id`),
  //     equalTo(userId)
  //   ));

  //   var roomIds: string[] = []
  //   snap.forEach((doc) => {
  //     roomIds.push(doc.key as string)
  //   })

  //   for (const roomId of roomIds) {
  //     await ExternalMeetingDb.removeTeamRoomUser(fbDb, teamId, roomId, userId);
  //   }

  //   remove(ref(fbDb, `/teams/${teamId}/admin/${userId}`))
  //   remove(ref(fbDb, `/teams/${teamId}/users/${userId}`))
  // }

  static userInvitees = async (
    fbDb: Database,
    teamId: string,
    userId: KSpaceUserId,
  ) => {
    const snap = await get(ref(fbDb, `/teamInvite/${teamId}`))

    const invites: string[] = [];
    snap.forEach((doc) => {
      const data = doc.val()
      if (data?.invitedBy === userId && data.email) {
        invites.push(data.email)
      }
    })
    return invites;
  }
}

export const teamNameConstraints = {
  teamName: {
    presence: {
      allowEmpty: false,
      message: "^Please enter a space name",
    },
    format: {
      pattern: /^[a-zA-Z0-9_ \-]*$/,
      message: "can only contain alphanumeric digits, underscores, dashes and spaces",
    },
  },
};

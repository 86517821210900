import React from "react";
import { OTUITree } from "@openteam/app-core";
import { FaMinus, FaTimes } from "react-icons/fa";
import { observer } from "mobx-react";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSPanel, DSColumn, DSRow } from "../../DesignSystem";
import { DSImage } from "../../DesignSystem/DSImage";
import { DSButton } from "../../DesignSystem/DSButton";
import { signOut } from "../../Controllers/Login";
import { DSH4, DSPrint } from "../../DesignSystem/DSText";
import { WindowControls } from "../WindowControls";
import { DS2Button } from "../../DesignSystem/DS2";


export const OnboardingPager: React.FC<{ pageNum: number, numPages: number, size?: number }> = ({ pageNum, numPages, size = 14 }) => (
  <DSRow>
    {[...Array(numPages)].map((_, i) =>
      <div
        key={`page-${i}`}
        style={{
          backgroundColor: DSTheme.EmphasisColor,
          opacity: pageNum === i ? 1 : 0.5,
          height: size,
          width: size,
          borderRadius: 999,
          boxSizing: "content-box",
          margin: "0 7"
        }}
      />
    )}
  </DSRow>
);


export const OnboardingHeader = observer(
  ({ numPages, pageNum, children }:
    { numPages?: number, pageNum?: number, children: React.ReactNode }) => {

    const email = OTUITree.unsafeUserManager?.userDoc?.email;
    //const user = space.users[myUserId]


    return (
      <DSPanel
        style={{
          backgroundColor: DSTheme.PanelBackgroundColor,
          color: DSTheme.MainText,
          flex: 1,
          maxWidth: "100vw",
        }}>
        <DSRow style={{ height: 90 }}>
          <DSColumn style={{ paddingTop: 24, paddingLeft: 24, flexGrow: 1 }}>
            <DSImage
              url={
                "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fehlologo.png?alt=media&token=c37e796d-eb4c-41fa-b84a-fc4139de325a"
              }
              height={47}
              width={113}
              style={{
                backgroundColor: 'transparent'
              }}
            />
          </DSColumn>
          <DSColumn style={{
            paddingTop: 10,
            paddingRight: 10,
            alignItems: 'flex-end'
          }}>
              <WindowControls parentId="InitWindow" hideMaximise={true}/>
            {OTUITree.auth.isLoaded && OTUITree.auth.userId && (
              <DSColumn style={{
                paddingRight: 6,
                alignItems: 'flex-end'
              }}>
                <DS2Button onClick={() => signOut()} >
                    Sign out
                  </DS2Button>
                {email && (
                  <DSH4>{email}</DSH4>
                )}
              </DSColumn>
            )}
          </DSColumn>
        </DSRow>
        <DSPanel style={{
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 'calc(100% - 94px - 50px)',
        }}>
          {children}

        </DSPanel>
        {pageNum != undefined && numPages != undefined && (
          <DSRow >

            <DSColumn style={{ flexGrow: 1, alignItems: "center" }}>
              <div style={{ position: "absolute", bottom: 30 }}>
                <OnboardingPager numPages={numPages} pageNum={pageNum} />
              </div>
            </DSColumn >
          </DSRow >
        )}

      </DSPanel>
    );
  })

import * as React from "react";
import { IconButton, IconButtonProps, styled } from "@mui/material";


const StyledButton = styled(IconButton)`
  opacity: 0.7;
  border-radius: 4px;
  padding: 1px;
  margin: 4px;
  font-size: 12px;

  :hover {
    opacity: 1;
  }
`

export const DS2DockControlButton: React.FC<IconButtonProps> = (props) => {
  return <StyledButton {...props} />
}

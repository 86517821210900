import React, { useEffect, useState } from "react";
import { Logger } from "@openteam/app-util";
import { observer } from "mobx-react";
import { DataState } from "../../Data/DataState";
import { OTUITree } from "@openteam/app-core";
import { getUserInteresting } from "../User/UserUtil";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { autorun, runInAction } from "mobx";
import { setDockComponentLength, UIState } from "../../Data/UIState";
import { DSColumn } from "../../DesignSystem";
import { KSpaceId, KSpaceUserId } from "@openteam/models";
import { DockOtherSpaceAlerts } from "./DockOtherSpaceAlerts";
import { DockSeparator } from "./DockSeparator";

const logger = new Logger("DockOtherAlerts")


interface IDockOtherAlertsProps {
  spaceId: string
}

export const DockOtherAlerts = observer((props: IDockOtherAlertsProps) => {
  const itemLengthRatio = (1 - DSTheme.DockMargin * 2);

  const [currentShowUsers, setCurrentShowUsers] = useState<Record<KSpaceId, KSpaceUserId[]>>({});

  useEffect(() => autorun(() => {

    const showUsers: Record<KSpaceId, KSpaceUserId[]> = {}
    const myUserId = OTUITree.auth.userId;
    let dockOtherHasAlert: boolean = false;


    for (let spaceId in DataState.spaces) {
      if (spaceId == props.spaceId) {
        continue
      }

      const spaceUsers: string[] = []

      for (let userId in DataState.spaces[spaceId].users) {
        const user = DataState.spaces[props.spaceId].users[userId]

        if (userId != myUserId) {
          const { hasAlert, openChat, pinned, hasMessage } = getUserInteresting(spaceId, userId)

          dockOtherHasAlert = dockOtherHasAlert || hasAlert //|| hasMessage

          if (hasAlert || hasMessage) {
            spaceUsers.push(userId)
          } else if (openChat) {
            spaceUsers.push(userId)
          }
        }
      }
      if (spaceUsers.length > 0) {
        showUsers[spaceId] = spaceUsers
      }

    }

    const height = Object.values(showUsers).map(userIds => userIds.length).reduce((prev, cur) => prev + cur, 0) * itemLengthRatio;
    setDockComponentLength('OtherUsers', height);

    if (UIState.dockOtherHasAlert !== dockOtherHasAlert) {
      runInAction(() => {
        logger.debug(`Updating DockOtherAlerts`)
        UIState.dockOtherHasAlert = dockOtherHasAlert
      })
    }

    setCurrentShowUsers((_curr) => {
      if (JSON.stringify(_curr) !== JSON.stringify(showUsers)) {
        return showUsers;
      }
      return _curr
    })

  }), [props.spaceId])

  return (
    <>
      {
        (Object.keys(currentShowUsers).length > 0) ?
          <DockSeparator />
          :
          null
      }
      {
        Object.keys(currentShowUsers).map(spaceId =>
          <DockOtherSpaceAlerts
            key={spaceId}
            spaceId={spaceId}
            userIds={currentShowUsers[spaceId]}
          />)
      }

    </>
  )
})
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Logger } from '@openteam/app-util';
import { DSH4, DSPrint } from '../../DesignSystem/DSText';
import { DSColumn, DSHSpacer, DSRow } from '../../DesignSystem';
import { OTUITree, toDate, getIntervalTree, parseMeetingUrl, b64encode, getStartOfDay, toSecs } from "@openteam/app-core";
import { get24Time, getFullDate, isSameDay, timeSince, toDay } from '../../Util/DateFormat';
import { ICalEvent, CalEvent, IMeetingTokenDetails, KSpaceId } from '@openteam/models';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DSJoinConference, DSJoinMeeting } from '../Calendar/DSJoinMeeting';
import { useHoverIntent } from '../../Util/HoverIntent';
import { MEET_URL } from '../../config';
import { useSpring, animated } from "@react-spring/web";
import { DSSpinner } from '../../DesignSystem/DSSpinner';
import { isInCall, openSettings, openSettingsAt } from '../../Data/UIState';
import { FaCalendarAlt } from 'react-icons/fa';
import { DS2BusyDisabledButton, DS2Button, DS2EditIconButton, DS2ExternalIconButton, DS2EyeIconButton, DS2EyeSlashIconButton, DS2Icons } from '../../DesignSystem/DS2';
import { GoogleCalendarIcon } from './GoogleCalendarLogo';
import { hashObject } from 'react-hash-string';

const logger = new Logger("CalendarSchedule")

export const fmtTime = (ev: ICalEvent, startSecs: number) =>
  CalEvent.isAllDayEvent(ev) ? "All Day" : get24Time(toDate(startSecs));


type IEv = { start: number, origStart?: number, end?: number, isCurrentEvent: boolean, ev: ICalEvent };


export const CalendarSchedule: React.FC<{
  spaceId: KSpaceId,
  numDays?: number,
  editMeeting: (evId: string, meetingDetails: IMeetingTokenDetails) => void;
}> = observer(({ spaceId, editMeeting, numDays = 4 }) => {

  const [events, setEvents] = useState<Record<number, IEv[]>>();

  const calendarManager = OTUITree.userManager.calendarManager;
  const currentEvent = calendarManager.currentEvent;
  const nextEvent = calendarManager.nextEvent;


  const runSchedule = useCallback(() => {
    if (calendarManager.schedule) {
      const dtNow = new Date();
      const timeMin = toSecs(dtNow);
      const timeMax = getStartOfDay(dtNow) + (numDays * 24 * 60 * 60);

      const isCurrentEv = (ev: ICalEvent, start: number) =>
        !!currentEvent && start === currentEvent.start && CalEvent.isEqual(ev, currentEvent.event);
      const isNextEv = (ev: ICalEvent, start: number) =>
        !!nextEvent && start === nextEvent.start && CalEvent.isEqual(ev, nextEvent.event);

      const visibleCals = Object.values(calendarManager.notifyCalendars)
        .filter(setting => setting.visible)
        .map(setting => setting.id);

      const [byId, tree] = calendarManager.schedule;

      const _events = tree.search(timeMin, timeMax);

      const events: Record<number, IEv[]> = {};

      // split out all day events into constituent days for display
      for (var i = 0; i < _events.length; i++) {
        const { evId, start } = _events[i];
        const ev = byId[evId];
        const isCurrentEvent = isCurrentEv(ev, start);
        const isNextEvent = isNextEv(ev, start);

        if (visibleCals.includes(ev.meta.calendarId) || isCurrentEvent || isNextEvent) {

          if (CalEvent.isAllDayEvent(ev)) {
            const start = CalEvent.getStart(ev);
            const end = CalEvent.getEnd(ev);

            for (var j = start; j < end && j < timeMax; j += (24 * 60 * 60)) {
              const d = new Date(toDate(j).toDateString()).getTime() / 1000;
              const dEnd = d + (24 * 60 * 60);
              if (dEnd < timeMin)
                continue;
              events[d] = [...events[d] || [], { ev, start, isCurrentEvent: false }];
            }

          } else {
            // even though this isn't an 'all day' event, it could still span
            // multiple days

            const end = start + CalEvent.getEnd(ev) - CalEvent.getStart(ev);

            for (var j = start; j < end && j < timeMax; j += (24 * 60 * 60)) {

              const dStart = new Date(toDate(j).toDateString()).getTime() / 1000;
              const dEnd = new Date(toDate(j + (24 * 60 * 60)).toDateString()).getTime() / 1000;

              if (dEnd < timeMin)
                continue;

              events[dStart] = [...events[dStart] || [], {
                ev,
                start: Math.max(dStart, start),
                origStart: start,
                isCurrentEvent,
                end: Math.min(end, dEnd)
              }];
            }
          }
        }
      }

      setEvents((curEvents) => {
        if (hashObject(curEvents) !== hashObject(events)) {
          return events
        }
        return curEvents;
      });

    }
  }, [
    calendarManager.isAuthorised,
    calendarManager.isBusy,
    calendarManager.events,
    calendarManager.selectedCalendars,
  ])

  useEffect(() => {
    runSchedule()

    const clockTimer = setInterval(runSchedule, 1000);
    return () => clearInterval(clockTimer);
  }, [runSchedule])



  useEffect(() => events && logger.debug(`Have ${Object.keys(events).length} events in ${numDays} days`))

  if (!calendarManager.isAuthorised || calendarManager.isBusy) {

    return (
      <DSRow key={"title"} style={{ marginBottom: 5, alignItems: "flex-start", opacity: 0.8 }} spacing={8} >
        <DSColumn style={{ flex: 1 }}>
          <DS2Button
            color='secondary'
            startIcon={<GoogleCalendarIcon size={20} />}
            onClick={() => openSettingsAt({ domain: 'global', tabId: 'integrations' })}
            fullWidth
          >
            Configure Google Calendar
          </DS2Button>
        </DSColumn>
      </DSRow>
    )

  } else {

    const dtNow = new Date();

    const loading = events === undefined;
    const todayIsEmpty = loading || !events[new Date(dtNow.toDateString()).getTime() / 1000];
    const allPrimaryCalendarsDisplayed = Object.values(calendarManager.selectedCalendars).every(
      setting => setting.notifyEvents ? setting.visible : true
    );

    return (
      <div >
        {!allPrimaryCalendarsDisplayed && (
          <DSRow style={{ marginTop: 5, marginBottom: 20, height: 26, alignItems: "center" }}>
            <DSColumn style={{ width: 270 }}>
              <DSPrint wrap style={{ lineHeight: 1.6 }}>
                Some meetings might not be displayed.<br />
                Click on <FaCalendarAlt size={12} /> to view other calendars.
              </DSPrint>
            </DSColumn>
          </DSRow>
        )}


        {todayIsEmpty && (
          <DSRow key={"title"} style={{ marginBottom: 5, alignItems: "flex-start", opacity: 0.8 }} spacing={8} >
            <GoogleCalendarIcon size={20} />
            <DSColumn>
              {
                loading ? (
                  <DSH4>Loading events</DSH4>
                ) : (
                  <>
                    <DSH4>Today's Events</DSH4>
                    <DSPrint>No more meetings today.</DSPrint>
                  </>
                )}
            </DSColumn>
          </DSRow>
        )}

        {
          events && Object.entries(events).map(([today, evs], index) => {
            const isToday = isSameDay(new Date(dtNow), new Date(Number(today) * 1000));
            return (
              <div key={`ev-${index}}`}>
                {index !== 0 && (
                  <hr style={{
                    flex: 1,
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderLeftWidth: 0,
                    borderBottomWidth: 1,
                    borderBlockEndStyle: "solid",
                    color: DSTheme.EmphasisColor,
                    margin: 15,
                  }} />
                )}
                <DSRow key={"title"} style={{ marginBottom: 5, height: 26, alignItems: "center" }} spacing={8}>
                  {isToday && <GoogleCalendarIcon size={20} />}
                  {isToday ?
                    <DSH4>Today's Events</DSH4>
                    :
                    <DSH4>{getFullDate(new Date(Number(today) * 1000), false)}</DSH4>
                  }
                </DSRow>
                {evs.map(r =>
                  <CalendarScheduleEvent
                    spaceId={spaceId}
                    key={`event-${CalEvent.key(b64encode(r.ev.meta.calendarId), r.ev.id!)}`}
                    ev={r.ev}
                    start={r.start}
                    origStart={r.origStart}
                    end={r.end}
                    dtNowSecs={toSecs(dtNow)}
                    editMeeting={editMeeting} />
                )}
              </div>
            )
          })
        }

      </div>
    );
  }
});

const isEvNotifiable = (ev: ICalEvent) => ev.meta.isNotifiable !== false

const CalendarScheduleEvent: React.FC<{
  spaceId: KSpaceId,
  ev: ICalEvent,
  start: number,
  origStart?: number,
  end?: number,
  dtNowSecs: number,
  editMeeting: (evKey: string, meetingDetails: IMeetingTokenDetails) => void;
}> = observer(({ spaceId, ev, origStart, start, end, dtNowSecs, editMeeting }) => {

  const calendarManager = OTUITree.userManager.calendarManager;

  const getCalendar = (event: ICalEvent) => calendarManager.calendarsById[event.meta.calendarId!];

  const [isHovering, hoverRef] = useHoverIntent<HTMLDivElement>({ timeout: 100 });
  const [token, setToken] = useState<string>();
  const [meetingDetails, setMeetingDetails] = useState<IMeetingTokenDetails>();

  const dayStart = getStartOfDay(new Date(start * 1000));
  const dayEnd = dayStart + (24 * 60 * 60);


  const isWholeDay = CalEvent.isAllDayEvent(ev) || (start === dayStart && end === dayEnd);

  const isCurrentEvent = !isWholeDay && start < dtNowSecs && dtNowSecs < end!;

  useEffect(() => {
    if (ev.location) {
      try {
        const token = parseMeetingUrl(ev.location, [MEET_URL]);
        setToken(token);
        if (token) {
          calendarManager.parseMeetingToken(token)
            .then(meetingDetails => {
              setMeetingDetails(meetingDetails);
            })
        }
      } catch (_e) {
        const e = _e as Error;
        logger.debug("failed to parse meeting url", ev.location, e.message, MEET_URL);
      }
    }
  }, [ev.location]);

  const canEditMeeting = (meetingDetails: IMeetingTokenDetails) => {
    if (meetingDetails.teamId !== spaceId)
      return false;

    if (meetingDetails.userId !== OTUITree.userManager.userId)
      return false;

    return true
  }

  const calendarColor = getCalendar(ev)?.backgroundColor || "#fff";

  const springHover = useSpring({
    backgroundColor: isCurrentEvent ? calendarColor : "transparent",
    color: isCurrentEvent ? DSTheme.ColorContrastColor : DSTheme.MainText,
    filter: isHovering ? "brightness(120%)" : "brightness(100%)",
    fontWeight: isCurrentEvent ? "bold" : DSTheme.EmphasisColor
  });
  const springButtons = useSpring({
    opacity: (isHovering || isCurrentEvent) ? 1 : 0,
  });
  const springNotifyButtons = useSpring({
    opacity: (isHovering || isCurrentEvent || !isEvNotifiable(ev)) ? 1 : 0,
  });
  return (
    <animated.div
      ref={hoverRef}
      key={`event-${ev.meta.calendarId}-${ev.id!}`}
      style={{
        alignItems: "center",
        borderRadius: 6,
        padding: 2,
        marginBottom: 2
      }}>

      <DSRow style={{
        padding: 2,
        width: "100%",
        height: 26,
        alignItems: "center",
      }}>
        <DSColumn style={{ padding: 0 }}>
          <CalendarStatusDot
            colour={isCurrentEvent ? DSTheme.ColorContrastColor : calendarColor}
            summary={getCalendar(ev)?.summary || "Unknown"}
            filled={CalEvent.isAttendingEv(ev)}
            opacity={isEvNotifiable(ev) ? 1 : 0.6}
          />
        </DSColumn>
        <DSColumn style={{ width: 'auto', padding: 5 }}>
          <DSPrint
            style={{
              textAlign: "center",
              opacity: isEvNotifiable(ev) ? 1 : 0.6
            }}
            data-tooltip={isWholeDay ? undefined : end === dayEnd ? 'all day' : `until ${fmtTime(ev, end!)}`}
          >
            {isWholeDay ? "All day" : `${get24Time(toDate(start))} - ${get24Time(toDate(end!))}`}
          </DSPrint>
        </DSColumn>
        <DSColumn style={{ padding: 5, flex: 1 }}>
          <DSPrint
            style={{
              width: 135,
              overflow: "hidden",
              textOverflow: "ellipsis",
              opacity: isEvNotifiable(ev) ? 1 : 0.6
            }}
            data-tooltip={ev.summary || "Busy"}
            data-tooltip-align="cursor"
          >
            {ev.summary || "Busy"}
          </DSPrint>
        </DSColumn>

        <DSColumn style={{ padding: 0, flex: 0 }} >
          <animated.div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
              ...springButtons
            }}>

            {token ?
              !meetingDetails ? (
                <DSSpinner size={20} />
              ) : (
                <>
                  {!(isCurrentEvent && isInCall(meetingDetails.teamId, meetingDetails?.channelId === null ? undefined : meetingDetails.channelId)) ? (
                    <>
                      <>
                        <DSJoinMeeting meetingDetails={meetingDetails} />
                        <DSHSpacer size={10} />
                      </>
                      {canEditMeeting(meetingDetails) ? (
                        <DS2EditIconButton
                          data-tooltip="Edit Meeting"
                          disabled={!meetingDetails}
                          onClick={() => editMeeting(CalEvent.key(b64encode(ev.meta.calendarId), ev.id!), meetingDetails)}
                        />
                      ) : (
                        <DS2ExternalIconButton
                          data-tooltip="Open in calendar"
                          onClick={() => window.Main.shellOpenExternal(ev.htmlLink!)}
                        />
                      )}
                    </>

                  ) : (
                    <DS2ExternalIconButton
                      data-tooltip="Open in calendar"
                      onClick={() => window.Main.shellOpenExternal(ev.htmlLink!)}
                    />
                  )}
                </>
              ) : (
                <>
                  {ev.conferenceData && (
                    <>
                      <DSJoinConference conferenceData={ev.conferenceData} />
                      <DSHSpacer size={10} />
                    </>
                  )}
                  <DS2ExternalIconButton
                    data-tooltip="Open in calendar"
                    onClick={() => window.Main.shellOpenExternal(ev.htmlLink!)}
                  />
                </>
              )}

          </animated.div>
        </DSColumn>
        <DSColumn style={{ padding: 0, paddingLeft: 5, flex: 0 }} >
          <animated.div
            style={{
              display: "flex",
              width: "100%",
              ...springNotifyButtons
            }}>
            <DS2EvNotifiableButton ev={ev} />
          </animated.div>
        </DSColumn>
      </DSRow>
      {
        isCurrentEvent && (
          <DSRow reverse={true} style={{
            width: "100%",
            padding: 0,
          }}>
            <DSColumn style={{ padding: 5 }}>
              <DSPrint>Started {timeSince(toDate(origStart!), " ago")}</DSPrint>
            </DSColumn>
          </DSRow>
        )
      }
    </animated.div >
  )
});

const DS2EvNotifiableButton: React.FC<{
  ev: ICalEvent
}> = ({ ev }) => {
  const calendarManager = OTUITree.userManager.calendarManager;

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const setEventNotifiable = async (event: ICalEvent, notifiable: boolean) => {
    setIsBusy(true);

    await calendarManager.setEventNotifiable(
      event.meta.calendarId,
      event.id!,
      notifiable
    );

    setIsBusy(false);
  }

  if (isBusy)
    return <DS2BusyDisabledButton />

  else if (isEvNotifiable(ev))
    return <DS2EyeIconButton
      data-tooltip="Hide"
      onClick={() => setEventNotifiable(ev, false)}
    />

  else
    return <DS2EyeSlashIconButton
      data-tooltip="Show"
      onClick={() => setEventNotifiable(ev, true)}
    />;

};


export const CalendarStatusDot: React.FC<{
  colour: string,
  summary: string,
  filled: boolean
  size?: number,
  borderWidth?: number
  opacity?: number
}> = observer(({ colour, summary, filled, size = 8, borderWidth = 2, opacity = 1 }) => (
  /*  <div
     style={{
       height: size + borderWidth * 2,
       width: size + borderWidth * 2,
       borderRadius: size + borderWidth * 2,
       borderWidth: borderWidth,
       borderStyle: "solid",
       borderColor: DSTheme.PanelBackgroundColor,
       boxSizing: "content-box",
     }}> */
  <div
    data-tooltip={summary}
    style={{
      backgroundColor: !filled ? "transparent" : colour,
      height: size,
      width: size,
      borderRadius: size,
      borderWidth: borderWidth,
      borderStyle: "solid",
      borderColor: colour,
      boxSizing: "content-box",
      opacity,
    }}
  />
/*   </div>
 */));

import { Logger } from '@openteam/app-util'
import { IChannel, KSpaceId } from '@openteam/models'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { DataState } from '../../../Data/DataState'
import { showCreateRoom } from '../../../Data/UIState'
import { DS2CreateIconButton, DS2Icons, DS2SearchInput, styled } from '../../../DesignSystem/DS2'
import { DSColumn, DSPanelScrollable, DSRow } from '../../../DesignSystem'
import { DSH2, DSH3, DSH4 } from '../../../DesignSystem/DSText'
import groupBy from '../../../Util/groupBy'
import DockOverflowCall from './DockOverflowCall'
import { DockOverflowPanelProps } from './DockOverflowIcon'
import DockOverflowPanel from './DockOverflowPanel'
import DockOverflowRoom from './DockOverflowRoom'

const logger = new Logger('DockOverflowRoomPanel')



const DockOverflowRoomPanel: React.FC<DockOverflowPanelProps> = (props) => {
  const space = DataState.spaces[props.spaceId]
  let calls = Object.values(space.calls);
  const callChannels = new Set(calls.map((c) => c.channelId).filter(x => !!x));

  let rooms = Object.values(space.channelDirectory).filter((r) => !callChannels.has(r.id));

  const [shown, setShown] = useState(false);
  const [searchString, setSearchString] = useState('')
  const searchRef = useRef<HTMLInputElement>(null);

  if (searchString != "") {
    const lowercaseSearchString = searchString.toLowerCase();
    rooms = rooms.filter((room) => room.name!.toLowerCase().includes(lowercaseSearchString));
    calls = calls.filter((call) => call.name.toLowerCase().includes(lowercaseSearchString));
  }

  const sortbyState_Name = (a: IChannel, b: IChannel) => {
    const aState = space.pods[a.id] ? 0 : 1;
    const bState = space.pods[b.id] ? 0 : 1;

    if (aState != bState) {
      return aState - bState;
    } else if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
      return 1;
    } else if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
      return -1
    }
    return 0
  }

  useEffect(() => {
    const offShow = window.Main.on(`window-show-${props.windowId}`, () => setShown(true))
    const offHide = window.Main.on(`window-hide-${props.windowId}`, () => setShown(false))

    return () => {
      offShow()
      offHide()
    }
  }, [props.windowId])

  useEffect(() => {
    if (searchRef.current && shown) {
      searchRef.current.focus()
    }
  }, [shown])

  rooms.sort(sortbyState_Name);

  logger.debug(`have ${calls.length} calls`);

  const groupedRooms = groupBy(rooms, (room) => callChannels.has(room.id) ? 'Ongoing Calls' : space.pods[room.id] ? 'Subscribed' : 'Unsubscribed')

  return (
    <DockOverflowPanel spacing={16}>
      <DSRow style={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
        <DSH2>Rooms</DSH2>
        <DS2CreateIconButton color='secondary' onClick={() => showCreateRoom()} data-tooltip="Create a Room"/>
      </DSRow>
      <DSRow>
        <DS2SearchInput ref={searchRef} value={searchString} onValueChange={setSearchString} />
      </DSRow>
      <DSPanelScrollable style={{ paddingRight: 8, marginRight: -8 }}>
        {
          calls.length ? (
            <DSColumn spacing={8} style={{ marginTop: 12 }} >
              <DSH4>Ongoing Calls</DSH4>
              {
                calls.map((call) => (
                  <DockOverflowCall
                    key={`call-${call.id}`}
                    spaceId={props.spaceId}
                    callId={call.id}
                    closeWindow={props.closeWindow} />
                ))
              }
            </DSColumn>
          ) : null
        }
        {
          rooms.length > 0 ? (
            Object.entries(groupedRooms).map(
              ([heading, rooms]) => (
                <DSColumn key={`${heading}-rooms`} spacing={8} style={{ marginTop: 12 }} >
                  <DSH4>{heading}</DSH4>
                  {rooms.map((room) => (
                    <DockOverflowRoom
                      key={`room-${room.id}`}
                      spaceId={props.spaceId}
                      roomId={room.id}
                      closeWindow={props.closeWindow} />
                  )
                  )}
                </DSColumn>
              ))
          ) : null
        }
        {
          rooms.length + calls.length === 0 ? (
            searchString !== '' ? (
              <DSH3 wrap style={{ marginTop: 24, alignSelf: 'center', textAlign: 'center' }}>
                No rooms match your search.
              </DSH3>
            ) : (
              <DSH3 wrap style={{ marginTop: 24, alignSelf: 'center', textAlign: 'center' }}>
                There are no rooms in your workspace.<br />
                Click on the {DS2Icons.create} button above to create a room.
              </DSH3>
            )
          ) : null
        }
      </DSPanelScrollable>
    </DockOverflowPanel>
  )
}

export default observer(DockOverflowRoomPanel)

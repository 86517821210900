import React, {useMemo } from 'react';
import { observer } from 'mobx-react';
import { Logger } from '@openteam/app-util';
import { getStringColor } from '../../Util/StringColor';
import { DSH1 } from '../../DesignSystem/DSText';
import { DSImage } from '../../DesignSystem/DSImage';
import { DSRow } from '../../DesignSystem';
import { DataState } from '../../Data/DataState';

const logger = new Logger("UserIcon")

interface Props {
  spaceId: string
  tileSize: number
  borderRadius?: number
  style?: any
}

export const SpaceIcon = observer((props: Props) => {
  const space = DataState.spaces[props.spaceId]

  const backgroundColor = useMemo(() => getStringColor(space.details.name), [space.details.name])


  return (
    <DSRow
      style={{
        borderRadius: props.borderRadius || "50%",
        height: props.tileSize || 20,
        width: props.tileSize || 20,
        backgroundColor: !space.details.icon ? backgroundColor : undefined,
        alignItems: 'center',
        justifyContent: 'center',
        ...props.style
      }}>
      {space.details.icon ?
        <DSImage
          url={space.details.icon}
          height={props.tileSize || 20}
          width={props.tileSize || 20}
          style={{
            borderRadius: props.borderRadius || "50%",
          }}
        />
        :
        <DSH1 strong style={{fontSize: props.tileSize /1.5}}>{space.details.name[0].toUpperCase()}</DSH1>
      }
    </DSRow>
  )
})

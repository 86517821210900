import { Logger } from "@openteam/app-util";
import { H2, IconActionButton, IconButton, Row, useTheme } from "@openteam/design-system"
import { observer } from "mobx-react-lite"
import React, { CSSProperties, useEffect, useRef, useState } from "react"
import { FaCompressAlt, FaExpandAlt, FaPhone, FaRegWindowMinimize, FaWindowMinimize } from "react-icons/fa";
import { DataState } from "../../Data/DataState";
import { DS2CopyLinkButton } from "../../DesignSystem";
import { RoomIcon } from "../Room/RoomIcon";
import { SpacePanelCallIcon } from "../Space/SpacePanelIcons";
import { LeaveButton } from "./CallButtons";


const logger = new Logger('CallHeader');


interface Props {
  onMinimize: () => void
  onCollapse: () => void
  onExpand: () => void
  style?: CSSProperties
}

const CallHeader: React.FC<Props> = ({onMinimize, onCollapse, onExpand, style}) => {
  const call = DataState.activeCall!;
  const space = DataState.spaces[call.spaceId]
  const pod = call.channelId ? space.pods[call.channelId] : undefined;
  const [show, setShow] = useState(true);
  const showTimer = useRef<ReturnType<typeof setTimeout>>()
  const theme = useTheme();
  const meetingLink = call.meetingLink ?? space.calls[call.id]?.meetingLink;

  //useEffect(() => {
  //  if (hidden) {
  //    showTimer.current = setTimeout(() => setShow(false), 1000);
  //    return () => {
  //      showTimer.current && clearTimeout(showTimer.current)
  //      setShow(true)
  //    }
  //  }
  //}, [hidden])

  logger.debug(`Meeting link: ${meetingLink}`)

  return show ? (
    <Row style={{
        justifyContent: 'space-between',
        width: '100%',
        background: call.mode === 'large' ? theme.palette.secondary.main : undefined,
        padding: 8,
        //transition: hidden ? 'all 0.1s ease' : 'all 0.1s ease'
        ...style
      }}>
      <Row spacing={4} style={{ alignItems: 'center' }}>
        {
          pod ?
            <RoomIcon
              spaceId={call.spaceId}
              podId={pod?.id}
              name={pod?.name}
              size={28}
              showBorder={true}
            /> :
            <SpacePanelCallIcon size={12} />
        }
        <H2 maxLines={1} data-tooltip={call.name}>{call.name}</H2>
        <DS2CopyLinkButton
          buttonStyle='icon'
          color={undefined}
          meetingLink={meetingLink}
        />
      </Row>
        <Row spacing={4}>
          <IconButton onClick={onMinimize}><FaRegWindowMinimize /></IconButton>
          {call.mode == 'small'
            ? <IconButton onClick={onExpand}><FaExpandAlt /></IconButton>
            : <IconButton onClick={onCollapse}><FaCompressAlt /></IconButton> }
          <LeaveButton />
        </Row>
    </Row>
  ) : null;
}

export default observer(CallHeader)

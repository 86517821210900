import React, { useState, CSSProperties, useCallback } from 'react';
import { observer } from 'mobx-react';
import { DSColumn, DSPanel, DSRow } from '../../DesignSystem';
import { KSpaceId, KSpaceChannelId, IMessageManager } from '@openteam/models';
import RoomResourceContainer from './RoomResourceContainer';
import { DSH4 } from '../../DesignSystem/DSText';
import { Chat } from '../Chat/Chat';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DSTabs, DSTab, DSRoundedTab } from '../../DesignSystem/DSTabs';
import { RoomCallChat } from '../Chat/RoomCallChat';
import { DSButton, DSLongButton } from '../../DesignSystem/DSButton';
import { FaChevronRight, FaTimes } from 'react-icons/fa';
import { DataState } from '../../Data/DataState';
import { Logger } from '@openteam/app-util';
import { CallMessageManager, OTUITree } from '@openteam/app-core';
import * as Fire from "../../Controllers/Fire"
import { useEffect } from 'react';
import { UIState } from '../../Data/UIState';
import { toJS } from 'mobx';
import { CamIcon } from '../CamIcon';
import { LightBox, LightBoxEntry } from '../Lightbox/Lightbox';

const logger = new Logger("RoomChatContainer")


type Props = {
  spaceId: KSpaceId;
  channelId: KSpaceChannelId;
  windowId: string;
  callId?: string;
  chatOpen: boolean;
  windowFocused: boolean;
  title?: string
};

const RoomChatContainer: React.FC<Props> = (props) => {
  const { spaceId, channelId, windowId, chatOpen, callId, windowFocused, title } = props;
  const curWindowFocused = React.useRef<boolean>(false)

  const [lightboxItems, setLightboxItems] = useState<LightBoxEntry[] | undefined>();

  const [lightboxInitialSelected, setLightboxInitialSelected] = useState<number>(0)

  const showLightbox = (items: LightBoxEntry[], initialSelected?: number) => {
    setLightboxInitialSelected(initialSelected || 0)
    setLightboxItems(items)
  }

  const [activeTabId, setActiveTabId] = useState<string>("");
  const callSummaryMessageManager = React.useRef<CallMessageManager | undefined>()

  useEffect(() => {
    if (curWindowFocused.current !== windowFocused) {
      if (windowFocused) {
        onFocus()
      } else {
        onBlur()
      }
      curWindowFocused.current = windowFocused
    }

  }, [windowFocused, curWindowFocused.current])

  const loadCallChat = useCallback(
    (callId: string) => {
      logger.debug('loading call chat for', callId);

      UIState.openChats[spaceId].channels[channelId].showCallSummary = { callId };
      //setCallSummaryChatId(callId)
      setActiveTabId('summary');
    },
    [setActiveTabId]
  );

  useEffect(() => {
    const callId = UIState.openChats[spaceId].channels[channelId].showCallSummary?.callId;

    if (callId) {
      logger.debug("opening call summary for", callId);
      if (callId != callSummaryMessageManager.current?.roomId) {
        callSummaryMessageManager.current = new CallMessageManager(
          Fire.getFirestore(),
          spaceId,
          OTUITree.auth.userId,
          callId,
        )
      }
      setActiveTabId('summary')

    } else {
      if (callSummaryMessageManager.current) {
        callSummaryMessageManager.current.stop()
        callSummaryMessageManager.current = undefined
      }
    }

  }, [UIState.openChats[spaceId].channels[channelId].showCallSummary])


  useEffect(() => {
    return () => { callSummaryMessageManager.current?.stop() }
  }, [])


  const space = DataState.spaces[spaceId]
  const pod = space.pods[channelId]
  const chat = space.channels[props.channelId]

  const showCallSummary = UIState.openChats[spaceId].channels[channelId].showCallSummary;
  logger.debug("showCallSummmary: ", toJS(showCallSummary));
  logger.debug("callSummaryMessageManager: ", callSummaryMessageManager.current);

  interface ITab {
    title: string,
    id: string,
    icon?: JSX.Element,
    component: JSX.Element
    tabStyle?: CSSProperties
    badgeNum?: number,
    onFocus?: () => void,
    onBlur?: () => void
  }
  const tabs: Record<string, ITab> = {}

  if (chat && channelId !== 'adhoc-call') {
    tabs["channel"] = {
      title: `${pod?.name || "Main"}  Chat`,
      id: "channel",
      component: <Chat
        spaceId={spaceId}
        channelId={channelId}
        title={title}
        showLightbox={showLightbox}
        loadCallChat={loadCallChat} />,
      badgeNum: chat.numUnread,
      onFocus: () => {
        chat?.actions.focusChat();
        chat?.actions.markChatRead();
      },
      onBlur: () => {
        chat?.actions.unfocusChat();
      }
    }
  }

  if (showCallSummary && callSummaryMessageManager.current) {
    tabs["summary"] = {
      title: `Close Call Summary`,
      icon: <FaTimes style={{ paddingRight: 8 }} />,
      id: "summary",
      component: (
        <RoomCallChat
          spaceId={spaceId}
          callId={showCallSummary.callId}
          callMessageManager={callSummaryMessageManager.current}
          showLightbox={showLightbox}
          sendURL={chat?.actions.sendURL}
          readOnly={true} />
      ),
      tabStyle: {
        flex: 1,
        justifyContent: 'flex-end'
      }
    }
  }
  const firstTab = tabs["ongoing"] ? "ongoing" : "channel"
  const actualTab = tabs[activeTabId] ? activeTabId : firstTab
  const hasMultTabs = Object.keys(tabs).length > 1
  const changeTab = (tab) => {
    if (tabs[actualTab]) {
      tabs[actualTab]?.onBlur?.()
    }

    if (tabs[tab]) {
      tabs[tab]?.onFocus?.()
    }

    if (showCallSummary) {
      UIState.openChats[spaceId].channels[channelId].showCallSummary = undefined;
    }
    setActiveTabId(tab);
  }




  const onFocus = () => {
    tabs[actualTab].onFocus?.()
  }
  const onBlur = () => {
    tabs[actualTab].onBlur?.()

  }



  return chatOpen ? (
    <DSRow style={{ position: "relative", flexBasis: 400, flexGrow: 1 }}>
      <DSColumn style={{ flex: 1 }}>

        {
          hasMultTabs ?
            <DSTabs
              activeTabId={actualTab}
              onChange={changeTab}
              style={{
                marginTop: 20,
              }}
              activeTabStyle={{}}
              tabStyle={{}}
            >
              {
                Object.values(tabs).map((tab, index) => {
                  return <DSRoundedTab
                    key={tab.id}
                    name={tab.title}
                    icon={tab.icon}
                    tabId={tab.id}
                    tabStyle={tab.tabStyle}
                    badgeNum={tab.badgeNum}
                    isFirst={index == 0}
                    isLast={index == Object.values(tabs).length - 1}
                  />
                })
              }

            </DSTabs>
            :
            undefined
        }

        <DSPanel
          style={{
            flex: 1,
            backgroundColor: hasMultTabs ? DSTheme.PanelDarkerColor : undefined,
            paddingTop: 4,
            minHeight: 0,
            minWidth: 0,
          }}
        >
          {tabs[actualTab]?.component}

        </DSPanel>
      </DSColumn>
      {
        (chat && channelId !== 'adhoc-call') ?
          <RoomResourceContainer
            windowId={windowId}
            spaceId={spaceId}
            channelId={channelId}
            sendURL={chat.actions.sendURL}
            recentResources={chat.recentResources}
            pinnedResources={chat.pinnedResources}
            deletedResources={chat.deletedResources}
            updateResource={chat.actions.updateResource}
          />
          :
          undefined
      }
      {
        lightboxItems ?
          <LightBox
            onClose={() => setLightboxItems(undefined)}
            initialSelected={lightboxInitialSelected}
            items={lightboxItems} />
          :
          null
      }

    </DSRow>
  ) : null
};

export default observer(RoomChatContainer);

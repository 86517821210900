import React from "react";
import Script from "react-load-script";
import { Logger } from "@openteam/app-util";

import { googleConfig } from '../../config';

// https://developers.google.com/identity/sign-in/web/server-side-flow
const logger = new Logger("GoogleCalendarAuth");

const G_API_JS_URL = "https://apis.google.com/js/api.js";
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];


export const GAPIAuth: React.FC<{
  scopes: string[];
  onError: (err: { error: string, details: string }) => void;
  onSuccess: (auth: gapi.auth2.GoogleAuthBase) => void;
}> = ({ scopes, onSuccess, onError }) => {

  const { clientId, apiKey } = googleConfig

  const onApiLoad = () => {
    gapi.load("auth2", initAuth);
    gapi.load("client", initClient);
  };

  const initAuth = async () => {
    logger.debug("initAuth");

    gapi.auth2.init({ client_id: clientId, scope: scopes.join(" ") }).then(
      (auth) => {
        onSuccess(auth);
      },
      ({ error, details }) => {
        logger.error("Error initialising gapi.auth2: ", error, details);
        onError({ error, details });
      }
    );
  };

  const initClient = () => {
    logger.debug("init client");

    gapi.client
      .init({
        apiKey,
        clientId,
        discoveryDocs: DISCOVERY_DOCS,
        scope: scopes.join(" "),
      })
      .catch(({ error, details }) => {
        logger.error("Error initialising gapi.client: ", error, details);
        onError({ error, details });
      });
  };
  return (
    <Script url={G_API_JS_URL} onLoad={onApiLoad} />
  );
}

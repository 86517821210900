import React, { Component } from "react";
import { firebaseConfig, IMAGECDN, IMAGEFILECDN, TEAM_STORAGE_BUCKET_PATH } from "../config";
import { DSSpinner } from "./DSSpinner";
import { DSTheme } from "./DSTheme";


interface IDSImageProps extends React.HTMLAttributes<HTMLDivElement> {
  url: string
  width?: number | string
  height?: number | string
  maxWidth?: number | string
  maxHeight?: number | string
  reqHeight?: number
  reqWidth?: number
  borderRadius?: number
  dummyColor?: string
  resizeMode?: "auto" | "none"
  showLoadingSpinner?: boolean
}

export function DSImage(props: IDSImageProps) {
  const { style, width, height, maxWidth, maxHeight, reqWidth, reqHeight, resizeMode = "auto", borderRadius, dummyColor, showLoadingSpinner = false, ...otherProps } = props

  const [loaded, setLoaded] = React.useState<boolean>(false)

  let url = props.url

  const bucketURL = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}`
  const teamFilesURL = `https://firebasestorage.googleapis.com/v0/b/${TEAM_STORAGE_BUCKET_PATH}`

  if (url && (url.startsWith(bucketURL) || url.startsWith(teamFilesURL))) {

    if (url.startsWith(bucketURL)) {
      url = url.replace(bucketURL, IMAGECDN)
    } else {
      url = url.replace(teamFilesURL, IMAGEFILECDN)
    }
    let urlObj = new URL(url);

    let reqSize: string[] = []
    if (reqWidth) {
      reqSize.push(`w-${props.reqWidth}`)
    }
    if (reqHeight) {
      reqSize.push(`h-${props.reqHeight}`)
    }

    if (reqSize.length > 0) {
      urlObj.searchParams.set('tr', reqSize.join(","));
    } else if (resizeMode === "auto") {
      urlObj.searchParams.set('tr', `w-${200}`);
    }

    url = urlObj.toString()

  }
  return (
    url ?
      <>
      <img
        draggable={false}
          onLoad={() => setLoaded(true)}
        {...otherProps}
        src={url || undefined}
        style={{
          boxSizing: 'border-box',
          borderRadius: borderRadius ?? DSTheme.BaseBorderRadius,
          backgroundColor: dummyColor,
          width: width || "auto",
          height: height || "auto",
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          ...style
        }}
        />
        {
          !loaded && showLoadingSpinner ?
            <DSSpinner />
            :
            null
        }
      </>
      :
      <div
        style={{
          backgroundColor: DSTheme.DummyColor,
          width: width,
          height: height,
          ...style

        }}
      />

  );
}

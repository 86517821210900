import { OTUITree } from "@openteam/app-core";
import { Logger } from "@openteam/app-util"
import { observer } from "mobx-react";
import React, { useEffect, useLayoutEffect } from "react";
import { UIState } from "../../Data/UIState";
import {  SubWindow, SubWindowCSS } from "../SubWindow";
import { GlobalSettings } from "./GlobalSettings";
import { ToolTipContainer } from "../../Controllers/ToolTips";
import { action } from "mobx";
import { getGenericWindowPosition } from "../../Controllers/DockWindowUtil";

const logger = new Logger("GlobalSettingsWindow")

type Props = {
}

type Position = {
  x: number
  y: number
}

export const GlobalSettingsWindow: React.FC<Props> = observer((props) => {
  const windowId = 'global-settings'

  let width = 860
  let height = 620
  const spaceId = OTUITree.userManager.currentTeamId
  const [position, setPosition] = React.useState<Position>();

  const calcPosition = () => {

    const pos = getGenericWindowPosition(width, height)

    logger.debug(`Positioning to ${pos.x},${pos.y}`)

    return pos
  }

  useEffect(()=> {
    return () => {
      if (UIState.dockSettingsOpen) {
        UIState.dockSettingsOpen = false
      }
    }
  }, [])

  useLayoutEffect(() => {
    if (UIState.dockSettingsOpen) {
      logger.debug(`Calculating position`)
      setPosition(calcPosition())
    }

  }, [UIState.dockSettingsOpen])

  const onClose = action(() => {
    UIState.dockSettingsOpen = false
  })

  return (position && spaceId) ? (
    <SubWindow
      id={windowId}
      show={UIState.dockSettingsOpen}
      initialPosition={position}
      initialSize={{ width: width, height: height }}
      title={`Settings`}
      minHeight={2 * height / 3}
      minWidth={2 * width / 3}
      onClose={onClose}
      focus={true}
      options={{
        alwaysOnTop: false,
        hasShadow: true,
        frame: false,
        transparent: false,
        trafficLightPosition: {
          x: 10,
          y: 16
        },
        autoHideMenuBar: true,
        useContentSize: true,
        resizable: true,
        skipTaskbar: false,
        maximizable: true,
        focusable: true,
        fullscreenable: false,
        backgroundColor: undefined
      }}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <ToolTipContainer parentId={windowId} />
      <GlobalSettings windowId={windowId} onClose={onClose} />
    </SubWindow>

  ) : null

})

import { Logger } from '@openteam/app-util'
import { KSpaceId } from '@openteam/models'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import { UIState } from '../../../Data/UIState'
import { DSColumn, DSPanel, DSRow } from '../../../DesignSystem'
import { DockIconBase } from '../DockIconBase'
import { DockControlTile } from '../DockControlButton'

const logger = new Logger('DockOverflowIcon')
interface DockOverflowProps {
  spaceId: KSpaceId
  size: number
  panel: FunctionComponent<DockOverflowPanelProps>
}

export interface DockOverflowPanelProps {
  spaceId: KSpaceId
  windowId: string
  closeWindow: (DockOverflowPanelProps?: () => void) => void
  setDisableOnBlur: (value: boolean) => void
}

const DockOverflowIcon: React.FC<DockOverflowProps> = ({spaceId, size, panel : Panel, ...props}) => {
  const margin = UIState.dockSize *.05;

  const Inner = UIState.dockHorizontal ? DSRow : DSColumn;

  logger.debug(`Overflow size ${size}`)

  return (
    <DockIconBase
      id={`room-overflow`}
      savePanel
      subwindowProps={{ height: 620 }}
      renderIcon={() => (
        <DockControlTile
          style={{
            margin,
            width: UIState.dockHorizontal ? size - margin * 2 : UIState.dockSize *.8,
            height: UIState.dockHorizontal ? UIState.dockSize * 0.8 : size - margin *2,
            padding: 4
          }}
        >
          {props.children}
        </DockControlTile>
      )}
      renderContents={(closeWindow: (onCloseCallback?: () => void) => void, windowId: string, toggleWindow, setDisableOnBlur) => (
        <Panel spaceId={spaceId} windowId={windowId} closeWindow={closeWindow} setDisableOnBlur={setDisableOnBlur}/>
      )}
      {...props}
    />
  )
}

export default observer(DockOverflowIcon)

import { OTGlobals, OTUITree, UIDataState } from '@openteam/app-core';
import { computed, makeAutoObservable } from 'mobx';

class OnboardingService {
  _invites_sent = false;

  constructor() {
    makeAutoObservable(this);
  }

  init = async () => {
    const spaceId = OTUITree.userManager.currentTeamId;
    const space = spaceId && UIDataState.spaces[spaceId];
    if (space && (await space.actions.userInvitees?.())?.length) {
      this._invites_sent = true
    }
  }

  @computed
  get steps() {
    const spaceId = OTUITree.userManager.currentTeamId;
    const space = spaceId && UIDataState.spaces[spaceId];
    if (space) {
      return {
        invite: this._invites_sent || Object.keys(space.users).length > 1,
        gcal: OTUITree.userManager.calendarManager.isAuthorised,
        zoom: OTUITree.userManager.zoomManager.isAuthorised,
        dock: OTGlobals.remoteUserSettings.onboardingStepsComplete?.dock === true,
        sched: OTGlobals.remoteUserSettings.onboardingStepsComplete?.sched === true,
      };
    }
    return {};
  }

  @computed
  get nextStep() {
    for (const key in this.steps) {
      if (!this.steps[key]) {
        return key
      }
    }
    return
  }

  @computed
  get numSteps() {
    return Object.keys(this.steps).length
  }

  @computed
  get numDone() {
    return Object.values(this.steps).filter(v => v).length;
  }

}

export default new OnboardingService();

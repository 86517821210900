import React from 'react';

import { IMeetingTokenDetails } from "@openteam/models";
import { observer } from "mobx-react";
import { IDSCircleIconButtonProps } from "../../DesignSystem/DSButton";
import { Logger } from '@openteam/app-util';
import { joinCall } from '../../Data/UIState';
import type { calendar_v3 } from "googleapis";
import { DSImage } from '../../DesignSystem/DSImage';
import { DSSpinner } from '../../DesignSystem/DSSpinner';
import { DataState } from '../../Data/DataState';
import { DS2ButtonProps, DS2CallButton } from '../../DesignSystem/DS2';

const logger = new Logger("DSJoinMeeting");

interface IDSJoinMeetingProps extends DS2ButtonProps {
  meetingDetails?: IMeetingTokenDetails;
}
export const DSJoinMeeting: React.FC<IDSJoinMeetingProps> = observer(
  ({ style, size, meetingDetails, ...props }) => {

    const joinMeeting = (details: IMeetingTokenDetails | undefined) => {
      if (!details)
        return;

      const { teamId, userId, channelId, name } = details;

      logger.debug("joiningMeeting", details);

      joinCall(teamId, userId!, channelId, name)
    }


    logger.debug("meetingDetails: ", meetingDetails?.channelId, DataState.activeCall?.channelId);

    return (meetingDetails ? (
      <DS2CallButton
        title="Join"
        data-tooltip="Join with ehlo"
        onClick={() => joinMeeting(meetingDetails)}
        style={style}
        {...props}
      />
    ) : (
      <DSSpinner size={20} />
    )
    );
  }
);

interface IDSJoinConferenceProps extends IDSCircleIconButtonProps {
  size?: number;
  color?: string;
  conferenceData: calendar_v3.Schema$ConferenceData;
}
export const DSJoinConference: React.FC<IDSJoinConferenceProps> = observer(
  ({ style, size = 20, color, conferenceData, ...props }) => {

    if (!conferenceData)
      throw new Error("No conferenceData details");

    if (!conferenceData.entryPoints)
      return null;

    const entryPoint = conferenceData.entryPoints.find(x => x.entryPointType === 'video');

    if (!entryPoint)
      return null;

    const icon = conferenceData.conferenceSolution?.iconUri;
    const name = conferenceData.conferenceSolution?.key?.type === 'hangoutsMeet' ? "Google Meet" : "";

    logger.debug("conferenceData: ", conferenceData.conferenceSolution?.key);

    const joinConference = () => {
      window.Main.shellOpenExternal(entryPoint.uri!)
    }

    return (
      <DS2CallButton
        startIcon={
          icon ? <DSImage height={size} width={size} url={icon} /> : undefined
        }
        title="Join"
        data-tooltip={`Join with ${name}`}
        onClick={joinConference}
        style={style}
      />
    );
  }
);

import React, { useCallback, useState, useRef, useEffect } from 'react';

import { Logger } from "@openteam/app-util";
import { IChatMessage, KCallId, KSpaceId, KSpacePodId } from '@openteam/models';
import { observer } from 'mobx-react';
import { joinPod, openPod, openPodSettings, openUserChat, setRoomPanelOpen } from '../../Data/UIState';
import { DSColumn, DSPanel, DSRow } from '../../DesignSystem';
import { DataState } from '../../Data/DataState';
import { getDummyCall, getDummyPod, isDummyCall, isDummyPod } from '../../Data/tutorialDummy';
import { DSBody, DSH3, DSSmallPrint } from '../../DesignSystem/DSText';
import { DSSeparator } from '../../DesignSystem/DSSeparator';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { OTUITree } from '@openteam/app-core';
import { RoomIcon } from '../Room/RoomIcon';
import UserIconListPaged from '../../Util/UserIconListPaged';
import { DS2ChatButton, DS2IconButton, DS2Icons, DS2CopyLinkButton, DS2PinButton, DS2SubscribeButton } from '../../DesignSystem/DS2';
import { timeSince } from '../../Util/DateFormat';
import RoomCallButton from '../Room/RoomCallButton';
import DockPanelChat from './DockPanelChat/DockPanelChat';
import { useTheme } from '@openteam/design-system';
import DockPanelEmojiSender from './DockPanelChat/DockPanelEmojiSender';

const logger = new Logger("DockRoomPanel");

interface IDockRoomPanelProps {
  spaceId: KSpaceId
  podId?: KSpacePodId
  callId?: KCallId
  closeWindow?: (onCloseCallback?: () => void) => void
}


export const DockRoomPanel = observer((props: IDockRoomPanelProps) => {

  const space = DataState.spaces[props.spaceId]
  const channel = props.podId ? space.channels[props.podId] : undefined
  const [lastMessage, setLastMessage] = useState<IChatMessage>()
  const theme = useTheme()

  const pod = props.podId ? isDummyPod(props.podId) ? getDummyPod(props.podId) : DataState.spaces[props.spaceId].pods[props.podId] : undefined;
  const call = props.callId ? isDummyCall(props.callId) ? getDummyCall(props.callId) : space.calls[props.callId] : undefined;
  const participants = call?.participants || {}
  const callOpen = participants[OTUITree.auth.userId]?.currentSessionToken == OTUITree.auth.sessionToken
  const participantList = Object.values(call?.participants || {});

  const podName = call?.name || pod?.name
  const inCall = OTUITree.auth.userId in participants;

  useEffect(() => {
    if (props.podId) {
      setRoomPanelOpen(props.spaceId, props.podId, true);
      return () => setRoomPanelOpen(props.spaceId, props.podId!, false);
    }
  }, [props.spaceId, props.podId])

  const openChat = useCallback(() => {
    props.podId && openPod(props.spaceId, props.podId)
  }, [props.spaceId, props.podId])

  const sendEmoji = useCallback(async (emoji: string) => {
    if (pod) {
      const chat = space.channels[pod.id]
      setLastMessage(await chat.actions.sendMessage(emoji))
    }
  }, [pod?.id]);

  return (
    <DSColumn
      style={{ padding: "15 15", minWidth: 300, maxWidth: 350, color: DSTheme.MainText }}
      separator={<DSSeparator style={{ opacity: 0.2, margin: "12 0", minHeight: 1 }} />}
    >

      <DSRow>
        <RoomIcon
          spaceId={props.spaceId}
          podId={props.podId}
          name={call?.name}
          size={60}
        />

        <DSColumn style={{ paddingLeft: 12, flex: 1, justifyContent: 'center' }}>
          <DSRow spacing={4} style={{ alignItems: 'center' }}>

            <DSH3 maxLines={2}> {podName} </DSH3>

            {pod ? <DS2PinButton pinned={pod?.pinned} onClick={() => pod?.actions.setPinned(!pod?.pinned)} /> : null}

          </DSRow>

          {pod?.desc ? <DSBody> {pod?.desc} </DSBody> : null}
          {call?.crDate ? <DSSmallPrint>Started {timeSince(new Date(call.crDate), ' ago')}</DSSmallPrint> : null}

        </DSColumn>

        {
          props.podId ?
            <DS2IconButton style={{ alignSelf: 'flex-start' }} onClick={() => openPodSettings(props.spaceId, props.podId!)}>
              {DS2Icons.settings}
            </DS2IconButton>
            : null
        }
      </DSRow>

      <DSColumn spacing={12}>
        <RoomCallButton
          spaceId={props.spaceId}
          podId={props.podId}
          callId={props.callId}
          fullWidth
          analyticsPrefix='RoomPanel'
        />

        <DSRow style={{ justifyContent: 'space-evenly' }} spacing={12}>
          {pod ?
            <DS2ChatButton
              analyticsEvent="RoomPanelOpenChat"
              analyticsArgs={{ podId: props.podId }}
              fullWidth={true}
              onClick={openChat}
              badgeNum={pod?.numUnread}
              badgeOverride={pod?.hasMention ? '@' : undefined}
            />
            : undefined
          }
          {channel?.meetingLink ?
            <DS2CopyLinkButton
              fullWidth
              meetingLink={channel?.meetingLink}
            />
            :
            undefined
          }

        </DSRow>

        {
          pod ? <DockPanelEmojiSender sendEmoji={sendEmoji} /> : null
        }

        {
          (props.podId !== undefined && !pod) ? (
            <DSColumn spacing={12}>
              <DSSmallPrint style={{ alignSelf: 'center' }} wrap>
                You are not subscribed to this room.
              </DSSmallPrint>
              <DS2SubscribeButton
                onClick={() => joinPod(props.spaceId, props.podId!)}
              />
            </DSColumn>
          ) : undefined}
      </DSColumn>

      {
        participantList.length > 0 ?
          <UserIconListPaged
            spaceId={props.spaceId}
            users={participantList}
            showName={true}
            numRows={5}
            numPerRow={5}
            tileSize={46}
            onClick={(userId) => openUserChat(props.spaceId, userId)}
          />
          :
          undefined
      }


      {
        pod ?
          <DockPanelChat
            spaceId={props.spaceId}
            channelId={pod.id}
            openChat={openChat}
            lastMessage={lastMessage}
          />
          : null
      }

    </DSColumn>
  );
})

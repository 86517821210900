import { useTheme } from '@openteam/design-system'
import { KSpaceId, KSpaceChannelId } from "@openteam/models"
import { observer } from "mobx-react-lite"
import React from "react"
import { DataState } from "../../../Data/DataState"
import { joinPod, openPod } from "../../../Data/UIState"
import { DS2PinButton, DS2IconActionButton, DS2Icons, DS2SubscribeButton, DS2CopyLinkButton } from "../../../DesignSystem"
import { DSRow, DSColumn } from "../../../DesignSystem"
import { DSH3, DSPrint } from "../../../DesignSystem/DSText"
import RoomCallButton from '../../Room/RoomCallButton'
import { RoomIcon } from "../../Room/RoomIcon"

interface OverflowRoomProps {
  spaceId: KSpaceId
  roomId: KSpaceChannelId
  closeWindow: (onCloseCallback?: () => void) => void
}

const OverflowRoom: React.FC<OverflowRoomProps> = ({ spaceId, roomId, closeWindow }) => {
  const space = DataState.spaces[spaceId]
  const channel = space.channels[roomId]
  const room = space.channelDirectory[roomId]
  const pod = space.pods[roomId];
  const theme = useTheme()

  const togglePin = React.useCallback(() => {
    pod?.actions.setPinned(!pod?.pinned)
  }, [pod])

  const openChat = React.useCallback(() => {
    openPod(spaceId, roomId)
    closeWindow()
  }, [spaceId, roomId])

  const subscribe = React.useCallback(() => {
    joinPod(spaceId, roomId)
  }, [spaceId, roomId])


  return (
    <DSRow
      style={{
        padding: '6px 15px',
        height: 57,
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
        alignItems: "center",
        justifyContent: 'space-between'
      }}
      spacing={8}
    >
      <DSRow spacing={8}>

        <DSColumn style={{ justifyContent: 'center' }}>
          <RoomIcon
            spaceId={spaceId}
            podId={pod?.id}
            name={room.name || undefined}
            size={40}
          />
        </DSColumn>

        <DSColumn>

          <DSRow style={{ alignItems: 'baseline' }} spacing={4}>
            <DSH3 maxLines={1} data-tooltip={pod?.name ?? room.name}>{pod?.name ?? room.name}</DSH3>
            {pod ? <DS2PinButton pinned={pod.pinned} onClick={togglePin} data-tooltip={pod.pinned ? 'Unpin Room' : 'Pin Room to Dock'} /> : null}
          </DSRow>

          <DSPrint maxLines={1} data-tooltip={pod?.desc} color={theme.palette.text.secondary}>
            {pod?.desc}
          </DSPrint>

        </DSColumn>

      </DSRow>

      {
        pod ?
          <DSRow spacing={4}>

            <DS2IconActionButton
              color='secondary'
              data-tooltip="Open Chat"
              onClick={openChat}
              analyticsEvent="RoomOverflowOpenChat"
              badgeNum={pod.numUnread}
            >
              {DS2Icons.chat}
            </DS2IconActionButton>

            <DS2CopyLinkButton
              buttonStyle='action'
              meetingLink={channel.meetingLink}
            />

            <RoomCallButton
              spaceId={spaceId}
              podId={pod.id}
              buttonStyle='action'
              analyticsPrefix='RoomOverflow'
            />
          </DSRow>
          :
          <DS2SubscribeButton onClick={subscribe}/>
      }
    </DSRow>
  )
}

export default observer(OverflowRoom)

import { OTGlobals, OTUITree } from "@openteam/app-core";
import { Logger} from "@openteam/app-util";
import { UIState } from "../Data/UIState";
const logger = new Logger("AppUpgrade");

let autoUpdateSetup = false;

export function setupAutoUpdate() {
  if (autoUpdateSetup) {
    logger.error("setupAutoUpdate already run, returning");
    return;
  }
  autoUpdateSetup = true;

  // autoUpdater.logger = console;

  window.Main.on("update-downloaded", () => {
    logger.info("downloaded update in update-downloaded");
    UIState.mainUpgradeAvailable = true;
  });

  setInterval(
    autoUpgrade,
    1000 * 60 * 60 * 3 // every 3 hours
  );
}

function autoUpgrade() {
  logger.info("checking autoUpgrade");

  if (UIState.mainUpgradeAvailable || UIState.webUpgradeAvailable) {
    // i have an upgrade
    logger.info("upgrade available");
    if (!OTUITree._appHomeUI?.inCall && OTGlobals.isIdle) {
      // user not using the app
      logger.info("user not in call and is either idle or window isn't focused, auto upgrading");
      appUpgrade();
    }
  }
}

export function appUpgrade() {
  if (UIState.mainUpgradeAvailable) {
    window.Main.appUpgrade()
  } else {
    window.Main.appReload()
  }
}


import { OTGlobals, OTUITree, TestStream, updateDeviceSettings } from '@openteam/app-core';
import { Logger } from '@openteam/app-util';
import { IUIDeviceSettings, KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { FaVolumeUp } from 'react-icons/fa';
import { playTestAudio } from '../../Controllers/SoundEffects';
import { DataState } from '../../Data/DataState';
import { DSColumn, DSPanel, DSRow, DSVSpacer } from '../../DesignSystem';
import { DSH2, DSH3, DSPrint } from '../../DesignSystem/DSText';
import { DSVolumeBar } from '../../DesignSystem/DSVolumeBar';
import * as Analytics from '../../Util/Analytics'
import { CSSProperties } from 'react';
import { DS2BaseButton, DS2Button, DS2Checkbox, DS2Radio, DS2Switch } from '../../DesignSystem';
import PermissionsOverlay from './PermissionsOverlay';


const logger = new Logger("DeviceSettings")
interface Props {
  spaceId: KSpaceId
}

const AudioSettings: React.FC<Props> = (props) => {
  const mounted = useRef<boolean>(false)
  const stream = useRef<TestStream | undefined>(undefined);
  const [streamVersion, setStreamVersion] = useState<number>(0);

  const [volume, setVolume] = useState<number>(0);
  const [permission, setPermission] = useState<string | null>(null)
  const checkPermissionsInterval = useRef<ReturnType<typeof setInterval>>()
  const deviceSettings: IUIDeviceSettings = DataState.deviceSettings;
  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const selectDevice = (type: string, deviceId: string) => {
    logger.info("setting device", type, deviceId)
    updateDeviceSettings(type, { deviceId });
  };

  const enableMicInCalls = (enabled) => {
    logger.debug(`Updating callAudioEnabled: ${enabled}`)
    OTGlobals.userSettingsManager.updateRemoteSettings({
      callAudioEnabled: enabled
    })
  }

  const checkPermissions = useCallback(async () => {
    let permsOk = true;
    if (isMacOs) {
      const microphone = await window.Main.getMediaAccessStatus('microphone');
      if (microphone !== 'granted') {
        //logger.debug(`Permissions ${camera}, ${microphone}`);
        setPermission(microphone);
        permsOk = false;
      } else if (checkPermissionsInterval.current) {
        logger.debug('Permissions granted');
        setPermission(null);
        clearInterval(checkPermissionsInterval.current)
        stream.current?.reset();
      }
    }
    return permsOk;
  }, [stream])

  useEffect(() => {
    (async () => {
      if (!(await checkPermissions())) {
        checkPermissionsInterval.current = setInterval(checkPermissions, 500);
      }
    })();
    return () => {
      if (checkPermissionsInterval.current) {
        clearInterval(checkPermissionsInterval.current);
      }
    };
  }, [checkPermissions]);


  const initStream = async () => {
    const newStream = new TestStream(props.spaceId, OTUITree.auth.userId, OTGlobals.mediaDevices);
    await newStream.enableAudio();

    newStream.on("volume_change", (volume) => { setVolume(volume) })
    newStream.on('trackadded', forceUpdate);

    if (mounted.current) {
      logger.debug("setting stream")
      stream.current = newStream;
      setStreamVersion(streamVersion + 1);
    } else {
      logger.debug("shutting down new stream")
      newStream.shutdown();
    }
  }

  const stop = () => {
    logger.debug("stopping");

    if (stream.current) {
      logger.debug("shutting down stream");
      stream.current.shutdown();
      stream.current = undefined;
    }
  }

  useEffect(() => {
    logger.debug("mounting");
    mounted.current = true;
    initStream();
    // return cancel
    return () => {
      mounted.current = false;
      logger.debug("unmounted");
      stop();
    };
  }, [])

  useEffect(() => {
    stream.current && stream.current.updateSettings(OTGlobals.mediaDevices)

  }, [OTGlobals.mediaDevices.audio, OTGlobals.mediaDevices.video, OTGlobals.mediaDevices.audiooutput])

  return (
    <DSColumn separator={<hr style={Styles.separator} />}>
      <DSColumn spacing={16}>
        <DSColumn style={{ position: 'relative' }} spacing={8}>
          <DSH3>Microphone device</DSH3>
          <DSColumn>

            {deviceSettings.audioinput?.availableDevices.map((device) => (
              <DS2Radio
                key={device.deviceId}
                label={device.label}
                checked={device.deviceId === deviceSettings.audioinput?.activeDeviceId}
                onChange={() => {
                  selectDevice('audioinput', device.deviceId);
                  Analytics.logEvent("DeviceSettings_ChangeAudioDevice", { deviceLabel: device.label });
                }}
              />
            ))}
          </DSColumn>
        </DSColumn>

        <DSColumn spacing={8}>
          <DSH3>Test Audio</DSH3>
          <DSPrint style={{ marginBottom: 8, marginTop: 4 }} text="Speak to test the audio" />
          <DSVolumeBar volume={volume} />
          <DSVSpacer size={6} />

          {permission === 'denied' ? <PermissionsOverlay mediaType="Microphone" /> : null}
        </DSColumn>

        <DSColumn spacing={8}>
          <DSH3>Call Settings</DSH3>
          <DSColumn>
            <DS2Switch
              checked={OTGlobals.remoteUserSettings.callAudioEnabled !== false}
              onChange={enableMicInCalls}
              label="Join calls with microphone enabled"
              />
          </DSColumn>
        </DSColumn>
      </DSColumn>

      <DSColumn spacing={16}>
        <DSColumn spacing={8}>

          <DSH3>Speaker device</DSH3>
          <DSColumn>
            {deviceSettings.audiooutput?.availableDevices.map((device) => (
              <DS2Radio
                key={device.deviceId}
                label={device.label}
                checked={device.deviceId === deviceSettings.audiooutput?.activeDeviceId}
                onChange={() => {
                  selectDevice('audiooutput', device.deviceId);
                  Analytics.logEvent("DeviceSettings_ChangeOutputDevice", { deviceLabel: device.label });
                }}
              />
            ))}
          </DSColumn>
        </DSColumn>

        <DSColumn spacing={16}>
          <DSH3>Test Speakers</DSH3>
          <DS2BaseButton
            startIcon={<FaVolumeUp />}
            onClick={playTestAudio}
            analyticsEvent="PlayTestSound"
          >
            Play test sound
          </DS2BaseButton>
          <DSVSpacer size={40} />
        </DSColumn>
      </DSColumn>
    </DSColumn >
  );
};

export default observer(AudioSettings)


const Styles: Record<string, CSSProperties> = {
  heading: {
    marginTop: 8
  },
  settingRow: {
    marginTop: 12,
    justifyContent: "space-between",
  },
  settingSubRow: {
    marginTop: 8,
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px"
  },
  separator: {
    height: 0,
    width: "80%",
    borderTopWidth: 1,
    borderBottomWidth: 0,
    borderColor: "white",
    borderTopStyle: "solid",
    marginTop: 20,
    marginBottom: 20,
  }
}


import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

var global

if (global === undefined) {
  global = window;
}

const $style = document.createElement("style");
document.head.appendChild($style);
$style.innerHTML = `
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Manrope:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#app {
  display: flex;
  flex-direction: column;
  text-align: center;
  pointer-events: none;
  height: 100vh;
  width: 100%;
}

::placeholder {
  color: 'rgba(0,0,0,0.5)';
}

body {pointer-events: none}
* {pointer-events: all;}
.draggable {-webkit-app-region: drag;}


.noScrollBar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

:not(input):not(textarea),
:not(input):not(textarea)::after,
:not(input):not(textarea)::before {
    -webkit-user-select: none;
    user-select: none;
}

@keyframes spinner {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes pulse {
	0% {
		transform: scale(.50);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(.50);
	}
}

`;

ReactDOM.render(
  <App />,
  document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import React from 'react';

import { Logger } from '@openteam/app-util';
import { ZoomIcon, ZoomAuthButton } from '../Zoom';
import { DS2Switch, DSColumn, DSRow, DS2Button, DS2Icons } from '../../DesignSystem';
import { DSBody, DSPrint } from '../../DesignSystem/DSText';
import { OTUITree } from '@openteam/app-core';
import { zoomConfig } from '../../config';

const logger = new Logger("GoogleCalendarSettings")


export const ZoomSettings: React.FC<{
  parentWindowId: string,
  spaceId: KSpaceId,
  scrollVersion: number,
}> = observer(({ parentWindowId, spaceId, scrollVersion }) => {

  const zoomManager = OTUITree.userManager.zoomManager;
  const authUrl = zoomManager.getAuthUrl({...zoomConfig, userId: OTUITree.auth.userId})

  React.useEffect(() => logger.debug(`Zoom isAuthorised: ${zoomManager.isAuthorised}`), [])

  return (
    <DSColumn spacing={12} >

      <DSRow spacing={16} style={{ marginTop: 24, alignItems: 'center' }}>
        <ZoomIcon size={60} />
        <DSBody wrap>
          Connect your Zoom account to ehlo so your teammates can see when you are busy in a Zoom meeting.
        </DSBody>
        {
          !zoomManager.isAuthorised ?
            <ZoomAuthButton />
            :
            <DSRow spacing={12}>
              <DS2Switch checked={zoomManager.enabled} onClick={() => zoomManager.setEnabled(!zoomManager.enabled)} label={<DSBody wrap={false} style={{ fontWeight: 700 }}>Enable</DSBody>}/>
            </DSRow>
        }
      </DSRow >
      {zoomManager.isAuthorised ? (
        <DSColumn style={{ alignSelf: 'end', alignItems: 'flex-end'}} spacing={12}>
          <DS2Button
            startIcon={DS2Icons.close}
            color='error'
            onClick={() => window.Main.shellOpenExternal("https://marketplace.zoom.us/user/installed")}>
            Disconnect
          </DS2Button>
          <DSPrint>
            Connected as: {zoomManager.email}
          </DSPrint>

        </DSColumn>
      ) : null}
    </DSColumn>
  )
})

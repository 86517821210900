import { IUCall, IUISpacePod, IUISpaceUser } from '@openteam/models';




const dummyUser: IUISpaceUser = {
  id: "ehla",
  name: 'Ehla',
  isAdmin: false,
  email: "ehla@ehlo.space",
  imageUrl: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Ftutorial%2Fehlaprofile.png?alt=media&token=9317c59a-1430-4e8e-b3db-e07cd7ef8305",
  alerts: undefined,
  status: {
    online: true
  },
  actions: {
    togglePinned: () => { },
    updateLastInteracted: () => { },
    knockUser: () => { },
    callUser: () => { },
    getChannel: async () => { return ""; }
  },
};

export const isDummyUser = (userId: string) => {
  return userId == "dummy"
}
export const getDummyUser = (userId: string): IUISpaceUser => {
  return dummyUser
}

const dummyPodBase: IUISpacePod = {
  id: "tutorial",
  name: "Tutorial room",
  symbol: "TR",
  desc: undefined,
  archived: false,
  private: false,
  teamDefault: false,
  numUnread: 0,
  hasMention: false,
  lastUpdate: new Date(),
  numUsers: 1,
  userIds: [],
  pinned: false,
  actions: {
    togglePinned: () => { },
    updatePod: (name: string, symbol: string, desc: string, isPrivate: boolean, teamDefault: boolean) => { },
    addUser: (channelId: string, userId: string) => { },
    archivePod: () => { },
  }
};

const dummyPods: Record<string, IUISpacePod> = {
  tutorialroom: {
    ...dummyPodBase,
    name: "Tutorial room",
    symbol: "TR",
  },
  tutorialcall1: {
    ...dummyPodBase,
    name: "Board",
    symbol: "B",
  },
  tutorialcall2: {
    ...dummyPodBase,
    name: "Break",
    symbol: "☕️",
  },
}

export const isDummyPod = (podId: string | undefined) => {
  return podId ? ["tutorialcall1", "tutorialcall2", "tutorialroom"].includes(podId) : false
}

export const getDummyPod = (podId: string): IUISpacePod => {
  return dummyPods[podId]
}

const dummyCallBase: IUCall = {
  name: "Tutorial Call Other",

  id: "tutorialcall1",
  spaceId: "",
  participants: {
    "1": {
      id: "1",
      name: "Jane",
      imageUrl: "",
      streams: {},
      isSameRoom: false,
      isSoundDisabled: false,
      isPaused: false,
      connected: false,
      dispOrder: 0
    },
    "2": {
      id: "1",
      name: "Rob",
      imageUrl: "",
      streams: {},
      isSameRoom: false,
      isSoundDisabled: false,
      isPaused: false,
      connected: false,
      dispOrder: 0
    },
  },
  actions: {
    joinCall: () => { },
    requestJoinCall: () => { },
    cancelRequestJoinCall: () => { },
    respondRequestJoinCall: () => { }
  }
};

const dummyCallUserBase = {
  id: "1",
  name: "Rob",
  imageUrl: "",
  streams: {},
  isSameRoom: false,
  isSoundDisabled: false,
  isPaused: false,
  connected: false,
  dispOrder: 0
}

const dummyCalls: Record<string, IUCall> = {
  tutorialcall1: {
    ...dummyCallBase,
    name: 'Board Room',
    participants: {
      "1": {
        ...dummyCallUserBase,
        id: "1",
        name: 'Mary',
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Ftutorial%2Fcauley.png?alt=media&token=eec64209-775a-47a0-b01b-fdada5393c87"
      },
      "2": {
        ...dummyCallUserBase,
        id: "2",
        name: 'Mark',
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Ftutorial%2Fmark.png?alt=media&token=329349be-a2eb-4c7f-bfd0-a5a27a23ea88"
      },
    }
  },
  tutorialcall2: {
    ...dummyCallBase,
    name: 'Break Room',
    participants: {
      "1": {
        ...dummyCallUserBase,
        id: "1",
        name: 'Chris',
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Ftutorial%2Fchris.png?alt=media&token=b65d562c-7ca4-473e-b5d8-3b1ef0a8dfc5"
      },
      "2": {
        ...dummyCallUserBase,
        id: "2",
        name: 'Sami',
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Ftutorial%2Fsami.png?alt=media&token=53fba032-218e-4a75-b3ff-0e94c10c79c2"
      }
    }
  },
}



export const isDummyCall = (callId: string) => {
  return ["tutorialcall1", "tutorialcall2"].includes(callId)
}

export const getDummyCall = (callId: string): IUCall => {
  return dummyCalls[callId]
}
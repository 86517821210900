import React, { useCallback, useState, useEffect } from 'react';

import { debounce } from 'throttle-debounce';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { DSButton } from '../../DesignSystem/DSButton';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { FaTimes } from 'react-icons/fa';
import { DSPanel, DSPanelScrollable, DSRow, DSWrappedRow } from '../../DesignSystem';
import { DSInput } from '../../DesignSystem/DSInput';
import { DS2DeleteIconButton } from '../../DesignSystem';
const gf = new GiphyFetch('dGsWybul6wfMJflcssZYGvviVPFINYDR');

const giphyLogo =
  'https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fgiphypower.png?alt=media&token=8d9ef4ea-4db2-4ef8-957e-d4eafc2abfcd';

interface IGifCellProps {
  gif: IGif;
  onClick: () => void;
}

const GifCell: React.FC<IGifCellProps> = ({ gif, onClick }) => (
  <DSButton onClick={onClick} style={{ margin: 5 }}>
    <div
      key={`${gif.id}`}
      style={{
        borderRadius: DSTheme.BaseBorderRadius,
        margin: '5',
        width: parseInt(gif.images.fixed_height_small.width as any),
        height: parseInt(gif.images.fixed_height_small.height as any),
        backgroundImage: `url("${gif.images.fixed_height_small.url}")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    />
  </DSButton>
);

interface IGifPickerProps {
  onGifSelected: (title: string, url: string, size?: string) => void;
}

export const GifPicker: React.FC<IGifPickerProps> = ({ onGifSelected }) => {
  const [trending, setTrending] = useState<IGif[]>([]);
  const [searchResults, setSearchResults] = useState<IGif[] | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    async function fetchTrending() {
      const { data: gifs } = await gf.trending({ limit: 24 });
      setTrending(gifs);
      console.log('trending gifs: ', gifs);
    }
    fetchTrending();
  }, []);

  const doSearch = useCallback(
    debounce(500, (text: string) => {
      gf.search(text, {
        sort: 'relevant',
        lang: 'en',
        limit: 24,
        type: 'gifs'
      }).then(({ data: gifs }) => {
        setSearchResults(gifs);
      });
    }),
    []
  );

  const onSearchChanged = (text: string) => {
    setSearchQuery(text);

    if (text) {
      doSearch(text);
    } else {
      setSearchResults(undefined);
    }
  };

  return (
    <DSPanel
      style={{
        height: 200
      }}
    >
      <DSRow
        style={{
          margin: 10,
          padding: '0 0',
          borderRadius: 4,
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <DSInput
          placeholder={'Search GIFs'}
          value={searchQuery}
          onChange={(event) => onSearchChanged(event.target.value)}
          outerStyle={{ flex: 1, paddingRight: 0, paddingLeft: 0, minHeight: 28 }}
        >
          {searchQuery ? <DS2DeleteIconButton onClick={() => setSearchQuery('')} /> : null}
        </DSInput>

        <div
          style={{
            borderRadius: 4,
            width: 75,
            height: 26,
            marginLeft: 5,
            backgroundImage: `url("${giphyLogo}")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        />
      </DSRow>

      <DSPanelScrollable>
        <DSWrappedRow>
          {(searchResults || trending).map((item) => (
            <GifCell
              key={item.id}
              gif={item}
              onClick={() => {
                onGifSelected(item.title, item.images.fixed_height.url, item.images.fixed_height.size);
              }}
            />
          ))}
        </DSWrappedRow>
      </DSPanelScrollable>
    </DSPanel>
  );
};

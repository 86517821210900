import { ToggleButton, ToggleButtonProps } from "@mui/material"
import React from "react"
import useClickable from "./useClickable"


const DS2ToggleButton: React.FC<ToggleButtonProps> = (props) => {

  // @ts-ignore
  const {otherProps, onClick, onPointerDown } = useClickable(props);

  // @ts-ignore
  return <ToggleButton onClick={onClick} onPointerDown={onPointerDown} {...otherProps}/>
}

export default DS2ToggleButton;

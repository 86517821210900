import React from 'react';
import { IChatMessage, ILinkPreview, IMessage, IPinnedResource, ISearchFileRecord } from '@openteam/models';
import { DSBody, DSH3 } from '../../DesignSystem/DSText';
import { DSColumn, DSPanel, DSRow } from '../../DesignSystem';
import { DSButton } from '../../DesignSystem/DSButton';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DSImage } from '../../DesignSystem/DSImage';
import { FileIcon } from '../FavIcon';
import { observer } from 'mobx-react';
import { createResourceFromLink } from '@openteam/app-core';
import { DS2PinButton, DS2SaveButton } from '../../DesignSystem';
import { DataState } from '../../Data/DataState';

type Props = {
  spaceId: string;
  message: IMessage;
  linkId?: string;
  updateResource?: (r: IPinnedResource, isPinned?: boolean, isResource?: boolean, name?: string) => Promise<void>;
  saveLinkToChat?: (messageId: string, linkPreview: ILinkPreview, linkId?: string) => void;
};

export const ChatLinkPreview: React.FC<Props> = observer(
  ({ spaceId, message, linkId, updateResource, saveLinkToChat }) => {
    const space = DataState.spaces[spaceId];

    const user = space.users[message.userId] || {
      id: message.userId,
      name: 'Unknown User'
    };

    const linkPreview = linkId ? message.linkPreviews![linkId] : message.linkPreview!;

    const saveResource = (updateResource && message['channelId'])
      ? (linkPreview: ILinkPreview, linkId: string | undefined, isResource: boolean, isPinned: boolean) => {
          const l = linkPreview;
          const m = message as any as IChatMessage;


          if (!l) throw new Error('Link preview is not available');
          
          const resource = createResourceFromLink(
            m.channelId,
            m.id,
            linkId,
            l,
            user.name,
            m.crDate.getTime()
          );

          return updateResource(resource, isPinned, isResource);
        }
      : undefined;

    const openLink = () => {
      window.Main.shellOpenExternal(linkPreview.url);
    };

    const dummyResource: ISearchFileRecord = createResourceFromLink('dummy', message.id, linkId, linkPreview);

    return (
      <DSPanel style={{ alignItems: 'flex-start' }}>
        <DSRow
          style={{
            marginTop: 10,
            padding: 12,
            borderRadius: DSTheme.BaseBorderRadius,
            backgroundColor: DSTheme.BoxDarkBackground,
            position: 'relative',
            ...({ WebkitAppRegion: undefined } as any)
          }}
        >
          <FileIcon hit={dummyResource} />
          <DSColumn
            style={{
              marginLeft: 12
            }}
          >
            <DSButton onClick={openLink} href={linkPreview.url}>
              <DSH3
                wrap
                style={{
                  fontWeight: 'bold',
                  color: DSTheme.ChatLinkColor,
                  cursor: 'pointer'
                }}
              >
                {linkPreview.title || linkPreview.url}
              </DSH3>
            </DSButton>

            <DSBody style={{}}>{linkPreview.description}</DSBody>
            {linkPreview.image ? (
              <DSImage
                key={`${linkPreview.image}`}
                url={linkPreview.image}
                maxWidth={150}
                maxHeight={150}
                style={{
                  marginTop: 5,
                  marginBottom: 5
                }}
              />
            ) : null}

            {
              <DSRow
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0
                }}
              >
                {
                  !linkPreview.isResource ? (
                    saveResource ? (
                      <DS2SaveButton
                        color="secondary"
                        buttonStyle="action"
                        onClick={() => saveResource(linkPreview, linkId, true, false)}
                      />
                    ) : null
                  ) : saveLinkToChat && !linkPreview.savedToChat ? (
                    <DS2SaveButton
                      color="secondary"
                      buttonStyle="action"
                      onClick={() => saveLinkToChat(message.id, linkPreview, linkId)}
                    />
                  ) : saveResource ? (
                    <DS2PinButton
                      pinned={linkPreview.isPinned}
                      onClick={() => saveResource(linkPreview, linkId, true, !linkPreview.isPinned)}
                    />
                  ) : null
                  // <DSInfo
                  //   onClick={() => { }}
                  //   size={12}
                  //   style={{
                  //     width: undefined,
                  //     padding: 4
                  //   }}
                  // />
                }
              </DSRow>
            }
          </DSColumn>
        </DSRow>
      </DSPanel>
    );
  }
);

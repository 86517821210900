import { OTUITree, UIDataState } from "@openteam/app-core";
import { KCallId, KSpaceId } from "@openteam/models";
import { observer } from "mobx-react-lite";
import React from "react";
import { DS2Button, DS2CopyLinkButton, DS2Icons } from "../../DesignSystem";
import { DSColumn, DSRow } from "../../DesignSystem";
import { DSSeparator } from "../../DesignSystem/DSSeparator";
import { DSH3, DSSmallPrint } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { timeSince } from "../../Util/DateFormat";
import { RoomIcon } from "../Room/RoomIcon";

type Props = {
  spaceId: KSpaceId,
  callId: KCallId,
}

const COPY_LINK = "Copy link";
const LINK_COPIED = "Link copied!";

const DockZoomCallPanel: React.FC<Props> = (props) => {
  const space = UIDataState.spaces[props.spaceId];
  const call = space.calls[props.callId];
  const zoomManager = OTUITree.userManager.zoomManager;

  return (
    <DSColumn
      style={{ padding: "15 15", minWidth: 300, maxWidth: 350, color: DSTheme.MainText }}
      separator={<DSSeparator style={{ opacity: 0.2, margin: "12 0", minHeight: 1 }} />}
    >
      <DSRow style={{ alignItems: 'center' }}>
        <RoomIcon
          spaceId={props.spaceId}
          callId={props.callId}
          size={60}
        />
        <DSColumn style={{ paddingLeft: 12, flex: 1 }}>
          <DSRow spacing={4} style={{ alignItems: 'center' }}>

            <DSH3 maxLines={2}> {call.name} </DSH3>

          </DSRow>

          <DSRow style={{ justifyContent: 'flex-start' }}>
            <DS2Button variant="text" color='secondary' onClick={zoomManager.clearStatus} endIcon={DS2Icons.hide}>
              clear Zoom status
            </DS2Button>
          </DSRow>

        </DSColumn>
      </DSRow>
      <DSColumn spacing={12}>
        {
          call.meetingLink ?
            <DSRow style={{}} spacing={12}>
              <DS2CopyLinkButton
                meetingLink={call.meetingLink}
                title="Copy Link"
              >
                Copy Link
              </DS2CopyLinkButton>
              {
                !call.inCall ?
                  <DS2Button
                    noBadge
                    startIcon={DS2Icons.call}
                    color='info'
                    onClick={() => call.meetingLink && window.Main.shellOpenExternal(call.meetingLink)}
                  >
                    Join Zoom
                  </DS2Button>
                  : null
              }
            </DSRow>
            : null
        }
      </DSColumn>
    </DSColumn >
  )
}

export default observer(DockZoomCallPanel);

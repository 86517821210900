import React, { Component } from 'react';
import { DS2MenuButton } from '../../DesignSystem';
import { DSButton } from '../../DesignSystem/DSButton';
import { DSPanel, DSRow } from "../../DesignSystem"
import { DSH3, DSH4, DSLabel } from '../../DesignSystem/DSText';
import { DSTheme } from '../../DesignSystem/DSTheme';

interface Props {
  template: any
}
export const Menu = (props: Props) => {
  return (
    <DSPanel
      style={{
        color: DSTheme.PanelContrastColor,
        minWidth: 180,
        borderRadius: 8,
        padding: 8,
        margin: 4,
      }}>
      {
        props.template.map((item, index) => (
          !(item.enabled ?? true) ?
            <MenuTitle key={index} title={item.label} />
            :
            item.type == "separator" ?
              <MenuSeparator key={index} />
              :
              <MenuButton
                key={index}
                title={item.label}
                icon={item.icon}
                onClick={item.click}
                type={item.type}
                checked={item.checked}
              />
        ))
      }

    </DSPanel>
  )
}

interface IButtonProps {
  title: string
  icon?: JSX.Element
  onClick?: () => void
  type: "radio" | "checkbox"
  checked: boolean
}

export const MenuButton = (props: IButtonProps) => {
  return (


    props.type == 'radio' || props.type == 'checkbox' ?
      <DSButton
        onClick={props.onClick}
        style={{
          padding: 4,
          margin: 2,
          width: '100%'
        }}>
        <DSRow style={{ alignItems: 'center' }}>

          <div
            style={{
              width: 14,
              height: 14,
              borderRadius: 7,
              backgroundColor: DSTheme.EmphasisBackgroundColor,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            {
              props.checked ?
                <div
                  style={{
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: DSTheme.EmphasisColor,
                    opacity: 1,
                  }}
                />
                :
                null
            }

          </div>
          <DSH3 style={{ marginLeft: 6, }}>
            {props.title}
          </DSH3>
        </DSRow>
      </DSButton>

      :

      <DS2MenuButton
        onClick={props.onClick}
        startIcon={props.icon}
        title={props.title}
        fullWidth
        noBadge
        style={{
          margin: 4,
        }}
      />

  )
}

interface ITitleProps {
  title: string
}

export const MenuTitle = (props: ITitleProps) => {
  return (
    <DSH3
      style={{
        margin: 4,
        paddingBottom: 4,
      }}>
      {props.title}
    </DSH3>)
}

export const MenuSeparator = () => {
  return (
    <DSPanel
      style={{
        height: 2,
        margin: 8,
        width: "100%",
        backgroundColor: DSTheme.PanelContrastColor
      }}>

    </DSPanel>
  )
}

import { CircularProgress, styled } from "@mui/material"
import { setAlpha } from "@openteam/design-system"
import { OTGlobals } from "@openteam/app-core"
import { KSpaceId } from "@openteam/models"
import { action } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useCallback, useEffect } from "react"
import OnboardingService from "../../Controllers/OnboardingService"
import { setDockComponentLength, UIState } from "../../Data/UIState"
import { DSPanel, useClickable } from "../../DesignSystem"
import { DSPrint } from "../../DesignSystem/DSText"
import DockControlButton from "./DockControlButton"

const DockOnboardingButton: React.FC<{ spaceId: KSpaceId }> = ({ spaceId }) => {

  const {numSteps, numDone} = OnboardingService;

  const value = Math.round(100 * numDone / numSteps)

  const showButton = OTGlobals.remoteUserSettings.onboardingHidden != true && value < 100

  const onClick = useCallback(action(() => {
    if (!UIState.showOnboarding) {
      UIState.showOnboarding = true
    } else {
      window.Main.showWindow('Onboarding', true);
    }
  }), [])

  const size = UIState.dockSize

  useEffect(() => {
    if (showButton) {
      setDockComponentLength('Onboarding', 1)
      return () => setDockComponentLength('Onboarding', 0, true)
    }
  }, [showButton])

  const clickProps = useClickable({ onClick, analyticsEvent: 'dockShowOnboarding' })

  return showButton ? (
    <DockControlButton
      {...clickProps}
      style={{
        margin: size * .1,
        width: size * .8,
        height: size * .8,
        position: 'relative'
      }}>
      <CircularProgress variant="determinate" value={value} size={2 * size / 3} />
      <DSPanel style={{ position: 'absolute', inset: 0, justifyContent: 'center' }}>
        <DSPrint style={{ fontSize: size / 5 }}>{`${value}%`}</DSPrint>
      </DSPanel>
    </DockControlButton>
  ) : null

}

export default observer(DockOnboardingButton)

import React from 'react';
import { DSColumn } from '../../DesignSystem';
import FeedbackForm from '../Feedback/FeedbackForm';

const FeedbackTab: React.FC<{ spaceId: string }> = (props) => {
  return (
    <DSColumn>
      <FeedbackForm spaceId={props.spaceId} />
    </DSColumn>
  )
}

export default FeedbackTab;

import { Logger } from "@openteam/app-util"
import React, { useCallback, useEffect, useState } from "react";
import { ISubWindowProps, ModalOptions, SubWindow, SubWindowCSS } from "../SubWindow";


const logger = new Logger("ModalWindow")

interface Props extends ISubWindowProps {
};

export const ModalWindow: React.FC<Props> = ({
  id,
  parentId,
  onClose,
  children,
  options,
  ...subwindowProps
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const {show} = subwindowProps

  if (show) {
    return (
      <SubWindow
        id={id}
        show={show}
        focus={show}
        parentId={parentId}
        protectContent={true}
        options={{
          ...ModalOptions,
          ...options
        }}
        {...subwindowProps}
      >
        <style type="text/css">{SubWindowCSS}</style>
        {children}
      </SubWindow>
    );
  } else {
    return null;
  }
};

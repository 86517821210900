import { getAnalytics, setUserId, setUserProperties, logEvent as _logEvent } from "firebase/analytics"

import mixpanel from "mixpanel-browser";

import * as config from "../config";
import * as System from "./System";

import { OTAppCoreData, OTUITree } from "@openteam/app-core";

import { Logger } from "@openteam/app-util";
import { analyticsEmitter } from "@openteam/design-system";

const logger = new Logger("Analytics");

mixpanel.init(config.MIXPANELID, { ignore_dnt: true });

export function aliasUser(userId) {
  mixpanel.alias(userId);
}

export function identifyUser(userId) {
  mixpanel.identify(userId);
  setUserId(getAnalytics(), userId);
  logger.debug("identifyUser", userId);
}

export function setProperties() {

  const userManager = OTUITree.unsafeUserManager;

  setUserProperties(
    getAnalytics(),
    {
    teamId: userManager?.currentTeamId,
    userId: OTUITree.auth.userId,
  });

  mixpanel.register_once({
    'First Login Date': new Date().toISOString()
  });


  if (userManager) {
    mixpanel.register({
      version: OTAppCoreData.version,
      platform: System.platform,
      teamId: userManager.currentTeamId,
    })

    const userTeamIds = Object.keys(userManager.userTeams || {})
    mixpanel.set_group("company", userTeamIds)
  }



  mixpanel.people.set({
    userId: OTUITree.auth.userId,
    version: OTAppCoreData.version,
    platform: System.platform,
    ...(userManager
      ? {
          $email: userManager.userDoc?.email, // only special properties need the $
          "Sign up date": userManager.userDoc?.crDate, // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
          teamId: userManager.currentTeamId,
          name: userManager.userDoc?.name,
        }
      : {}),
  });
}

export function logEvent(event: string, data?) {
  const eventData = {
    teamId: OTUITree.unsafeUserManager?.currentTeamId,
    ...data,
  };

  mixpanel.track(event, eventData);
  logger.debug("tracking event", event, eventData);

  _logEvent(getAnalytics(), event, eventData);
}

analyticsEmitter.on('button-press', logEvent);

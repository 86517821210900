import React from 'react';
import { observer } from 'mobx-react';
import { UIState } from '../../Data/UIState';
import Room from './Room';
import { Logger } from '@openteam/app-util';

const logger = new Logger('RoomOpened');
interface Props {
}

export const RoomOpened = observer((props: Props) => {

  return (
    <>
      {
        Object.keys(UIState.openChats).map((spaceId) => {
          logger.debug(`space ${spaceId} has channels ${JSON.stringify(UIState.openChats[spaceId].channels)}`);
          return <div key={`roomOpened-${spaceId}`}>
            {Object.keys(UIState.openChats[spaceId].channels).map(channelId =>
              <Room key={`room-${spaceId}-${channelId}`} spaceId={spaceId} channelId={channelId} />
            )}
          </div>
        })
      }
    </>
  )
})
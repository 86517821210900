import React, { cloneElement } from "react";
import { IconButton, IconButtonProps, Icons } from "@openteam/design-system"

interface DS2PinButtonProps extends IconButtonProps {
  pinned?: boolean;
}

const DS2PinButton: React.FC<DS2PinButtonProps> = ({pinned, ...props}) => {

  const style = pinned ? {
    transition: "all 0.075s ease-in"
  } : {
    transition: "all 0.075s ease-in",
    transform: "rotate(45deg)",
  }

  return (
    <IconButton
      color={pinned ? 'primary' : undefined}
      {...props}
    >
      {cloneElement(Icons.pin, {size: 14, style })}
    </IconButton>
  )
}

export default DS2PinButton;

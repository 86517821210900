import { OTUITree } from '@openteam/app-core';
import { Logger } from '@openteam/app-util';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef } from 'react';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaCog, FaLock } from 'react-icons/fa';
import { BsGripVertical, BsGripHorizontal } from 'react-icons/bs';
import { openSettingsAt, setDockAutoHide, setDockComponentLength, UIState } from '../../Data/UIState';
import { Draggable } from '../Draggable';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DS2DockControlButton, DS2Icons } from '../../DesignSystem/DS2';


const logger = new Logger('DockControls');

interface IDockControlsProps {
  hide: boolean;
  clearDockHover?: () => void
};

export const DockControls = observer((props: IDockControlsProps) => {
  const spaceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDockComponentLength('DockControls', DSTheme.DockControlsRatio);
    return () => setDockComponentLength('DockControls', 0);
  }, []);

  logger.debug("UIState.dockHorizontal", UIState.dockHorizontal, "UIState.dockPosition", UIState.dockPosition.onTop)

  const setDockAutoHideClick = useCallback(() => {
    const autohide = !UIState.dockAutoHide;
    setDockAutoHide(autohide);
    if (autohide) {
      props.clearDockHover?.()
    }
  }, [])

  const openDockSettings = useCallback(() => {
    openSettingsAt({domain: 'global', tabId: 'dock'})
  }, [])

  const iconSize = Math.floor(UIState.dockSize / 5)
  const length = Math.floor(UIState.dockSize * DSTheme.DockControlsRatio)

  return (
    <div ref={spaceRef} style={{
      display: 'flex',
      flexDirection: UIState.dockHorizontal ? (UIState.dockPosition.onTop ?? true) == false ? 'column-reverse' : 'column' : !UIState.dockPosition.onLeft ? 'row-reverse' : 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: UIState.dockHorizontal ? UIState.dockSize : length,
      width: UIState.dockHorizontal ? length : UIState.dockSize,
      padding: 0,
      opacity: props.hide ? 0 : 1,
      transition: 'all 200ms ease'
    }}>
          <DS2DockControlButton
            onClick={setDockAutoHideClick}
            data-tooltip={UIState.dockAutoHide ? 'Disable autohide' : 'Enable autohide'}
            style={{fontSize: iconSize, visibility: UIState.dockEdge ? 'visible' : 'hidden'}}
          >
            {
              UIState.dockAutoHide ?
                <FaLock />
                :
                UIState.dockEdge === 'bottom' ?
                  <FaArrowDown />
                  :
                  UIState.dockEdge === 'top' ?
                    <FaArrowUp />
                    :
                    UIState.dockEdge === 'right' ?
                      <FaArrowRight />
                      :
                      <FaArrowLeft />
            }
          </DS2DockControlButton>
      {
        UIState.dockHorizontal
      }
      <Draggable
        windowId="__dock__"
        //forceNative={true}
        // forceNonNative={isWindows}
        style={{
          display: "flex",
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          height: UIState.dockHorizontal ? "100%" : 20,
          width: UIState.dockHorizontal ? 20 : "100%",
        }}
      >
        {
          UIState.dockHorizontal ?
            <BsGripVertical style={{ color: DSTheme.MainText, fontSize: iconSize*1.5}}  />
            :
            <BsGripHorizontal style={{ color: DSTheme.MainText, fontSize: iconSize*1.5}} />
        }
      </Draggable>
      <DS2DockControlButton
        data-tooltip="Settings"
        onClick={openDockSettings}
        style={{fontSize: iconSize}}
      >
        {DS2Icons.settings}
      </DS2DockControlButton>
    </div>
  );
});

import React, { CSSProperties, MouseEventHandler, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ISubWindowProps, PanelOptions, SubWindow as SubWindow, SubWindowCSS, SubWindowHandle } from './SubWindow';
import { Logger } from "@openteam/app-util";
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from "uuid";
import { ToolTipContainer } from '../Controllers/ToolTips';
import { ChatMarkdownCSS } from './Chat/ChatMarkdownView';
import { RemirrorCompactCSS } from './Chat/MarkdownEditor';
import { useClickable } from '../DesignSystem';
import useResizeObserver from 'use-resize-observer';
import { UIState } from '../Data/UIState';
import { DSPanel } from '../DesignSystem';
import { DSTheme, ThemeState } from '../DesignSystem/DSTheme';
import calcWindowPosition from '../Controllers/calcWindowPosition';

const logger = new Logger("PanelButton");



export interface PanelButtonProps {
  id: string
  parentId?: string
  forceShow?: boolean
  disabled?: boolean
  onContextMenu?: MouseEventHandler<HTMLElement>
  onDoubleClick?: MouseEventHandler<HTMLElement>
  analyticsEvent?: string;
  analyticsArgs?: Record<string, string | null | undefined>
  renderContents: RenderContentsFunc
  withPanel?: boolean
  subwindowProps?: Partial<ISubWindowProps>
  savePanel?: boolean
  offset?: number
  position?: 'left' | 'top' | 'bottom' | 'right'
  positionElement?: HTMLElement | null
  onShowPanel?: (shown: boolean) => void
  style?: CSSProperties
  avoidParent?: boolean
  parentPosition?: Bounds
  workArea?: Electron.Rectangle
}

type RenderContentsFunc = (closeWindow: (
  onCloseCallback?: () => void) => void,
  windowId: string,
  openWindow: () => void,
  setDisableOnBlur: (disabled: boolean) => void
) => ReactNode;

const PanelButton: React.FC<PanelButtonProps> = (
  {
    id,
    parentId = '__dock__',
    onContextMenu,
    onDoubleClick,
    renderContents,
    withPanel = true,
    forceShow = false,
    disabled = false,
    subwindowProps,
    savePanel = false,
    position = 'left',
    offset = 0,
    positionElement,
    onShowPanel,
    style,
    parentPosition: _parentPosition,
    workArea: _workArea,
    avoidParent,
    ...props
  }) => {
  const ref = useRef<HTMLDivElement>(null);
  const subWin = useRef<SubWindowHandle>(null)
  const [clickShowPanel, setClickShowPanel] = useState(false)
  const [parentPosition, setParentPosition] = useState<Bounds>();
  const [windowPosition, setWindowPosition] = useState<Position>();
  const [workArea, setWorkArea] = useState<Electron.Rectangle>();
  const disableOnBlur = React.useRef<boolean>(false);
  const disableOnClick = React.useRef<boolean>(false);
  const uuid = React.useRef<string>(uuidv4());
  const focused = React.useRef(false);

  const onCloseCallback = React.useRef<() => void>()

  const { ref: windowRef, width: refWidth = 300, height: refHeight = 150 } = useResizeObserver<HTMLDivElement>({ box: 'border-box' });
  const windowId = `${id}-${uuid.current}`

  const showPanel = clickShowPanel || forceShow;


  const toggleWindow = useCallback(() => {
    if (!disabled && !disableOnClick.current) {
      setClickShowPanel((wasOpen) => {
        const isOpen = !wasOpen;
        if (isOpen) {
          disableOnClick.current = true;
        };
        return isOpen
      })
    }
  }, [disabled])

  const { onClick, onPointerDown } = useClickable({ ...props, onClick: toggleWindow })

  useEffect(() => {

    if (!_parentPosition) {
      const unsubscribe: (() => void)[] = []

      const handleMove = async ({ bounds }) => {
        setParentPosition(bounds)
        setWorkArea(await window.Main.getWindowWorkArea(parentId));
      }

      if (parentId === '__dock__') {
        unsubscribe.push(window.Main.on(`dock-moved`, handleMove))
        unsubscribe.push(window.Main.on(`resized`, handleMove))
      } else {
        unsubscribe.push(window.Main.on(`window-moved-${parentId}`, handleMove))
        unsubscribe.push(window.Main.on(`window-resized-${parentId}`, handleMove))
      }

      (async () => {
        setParentPosition(await window.Main.getWindowBounds(parentId));
        setWorkArea(await window.Main.getWindowWorkArea(parentId));
      })()

      return () => {
        unsubscribe.forEach(f => f());
      };
    }
  }, [parentId, _parentPosition]);


  useEffect(() => {
    //logger.debug(`positionElement is `, positionElement?.getBoundingClientRect());

    if (showPanel && (_parentPosition ?? parentPosition) && (positionElement ?? ref.current) && (_workArea ?? workArea)) {
      const result = calcWindowPosition({
        windowSize: { width: refWidth, height: refHeight },
        parentBounds: (_parentPosition ?? parentPosition)!,
        triggerPosition: (positionElement ?? ref.current)!.getBoundingClientRect(),
        workArea: (_workArea ?? workArea)!,
        avoidParent,
        position,
        offset,
      })

      if (result) {
        setWindowPosition(result.bounds)
      }
    }

    return () => setWindowPosition(undefined);

  }, [showPanel, parentPosition, refWidth, refHeight, workArea, position, offset])

  const onCloseWindow = useCallback(() => {
    logger.debug("onCloseWindow ${windowId}")
    setClickShowPanel(false)
    disableOnClick.current = false
  }, [setClickShowPanel])

  const onBlurWindow = useCallback(() => {
    focused.current = false;
    if (!disableOnBlur.current) {
      logger.debug(`onBlur ${windowId}`)
      setClickShowPanel(false);
      setTimeout(() => {
        disableOnClick.current = false
        logger.debug(`timeout onBlur ${windowId}`)
      }, 200)
    }
  }, [setClickShowPanel])


  const setDisableOnBlur = useCallback((val) => {
    if (disableOnBlur.current !== val) {
      disableOnBlur.current = val
      if (!val && subWin.current) {
        logger.debug(`Focusing ${windowId}`)
        subWin.current.show(true, true)
      }
      if (!val && !focused.current) {
        logger.info(`Should I close the window?`)
      }
    }
  }, [])

  const setFocused = useCallback(() => {
    logger.info(`Setting focused! ${windowId}`)
    focused.current = true
  }, [])

  const closeWindow = useCallback((callback?) => {
    onCloseCallback.current && onCloseCallback.current();
    onCloseCallback.current = callback
    setClickShowPanel(false)
    disableOnClick.current = false;
  }, [onCloseCallback, setClickShowPanel]);

  useEffect(() => {
    //logger.debug(`onShowPanel ${id}: ${showPanel}`)
    if (showPanel) {
      onShowPanel?.(showPanel)
      return () => onShowPanel?.(false)
    } else if (onCloseCallback.current) {
      onCloseCallback.current();
      onCloseCallback.current = undefined;
    }

  }, [showPanel, onShowPanel])


  //useEffect(() => {
  //  logger.debug(`window ${windowId} now ${refWidth}x${refHeight}`)
  //}, [refWidth, refHeight])

  return (
    <>
      <div ref={ref}
        onClick={onClick}
        onPointerDown={onPointerDown}
        onDoubleClick={onDoubleClick}
        onContextMenu={onContextMenu}
        {...props}
        style={{ cursor: disabled ? undefined : 'pointer', ...style }}
      />

      {
        (savePanel || showPanel) ? (
          <SubWindow
            ref={subWin}
            id={windowId}
            windowType='dockPanel'
            parentId={parentId}
            show={(showPanel ?? false) && windowPosition !== undefined}
            {...windowPosition}
            onClose={onCloseWindow}
            onBlur={onBlurWindow}
            onFocus={setFocused}
            protectContent={true}
            focus={!forceShow}
            {...subwindowProps}
            options={
              PanelOptions
            }
          >
            <style type="text/css">{SubWindowCSS}</style>
            <style type="text/css">{ChatMarkdownCSS}</style>
            <style type="text/css">{RemirrorCompactCSS}</style>

            <ToolTipContainer parentId={windowId} showWhenUnfocused={true} />

            {/* This div is reqd to make the resizing work */}
            <div>
              <div
                ref={windowRef}
                tabIndex={forceShow ? undefined : -1}
                onKeyDown={ev => !forceShow && ev.code === 'Escape' && closeWindow()}
                style={{
                  position: 'relative',
                  display: 'flex',
                  overflow: 'visible',
                }}
              >
                {
                  withPanel ?
                    (
                      <DSPanel style={{
                        backgroundColor: UIState.mainCapabilities.vibrancy && ThemeState.selectedTheme == 'SYSTEM' ? "transparent" : DSTheme.DockPanelBackgroundColor,
                        borderRadius: 8,
                      }}>
                        {
                          renderContents && renderContents(closeWindow, windowId, toggleWindow, setDisableOnBlur)
                        }
                      </DSPanel>
                    ) : (
                      renderContents && renderContents(closeWindow, windowId, toggleWindow, setDisableOnBlur)
                    )
                }
                <input autoFocus tabIndex={-1} style={{ position: 'absolute', width: 0, height: 0, top: 0, left: 0, opacity: 0 }} />
              </div>
            </div>
          </SubWindow>
        ) : null
      }
    </>
  )
}

export default observer(PanelButton)

type Position = { x: number; y: number };
type Bounds = { x: number; y: number, width: number, height: number };

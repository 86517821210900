import { Logger } from '@openteam/app-util';
import { IShortcutManager } from '@openteam/models';


const logger = new Logger("ShortcutManager")

export class ShortcutManager implements IShortcutManager {
  constructor() {
    this.registerShortcuts();
  }

  registerShortcuts = () => {

    // globalShortcut.register('alt+space', () => {
    //   logger.info('alt+space is pressed')
    //   if (OTGlobals.currentTeamId) {
    //     var teamManager = OTUITree.teamManagers[OTGlobals.currentTeamId]

    //     teamManager && teamManager.toggleLastPtt()
    //   }
    // })
  };

  unregisterShortcuts = () => {
  };
}


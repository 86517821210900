import { observer } from 'mobx-react';
import { Logger } from '@openteam/app-util';
import { CallWindowOptions, IBounds, SubWindow, SubWindowCSS, SubWindowHandle } from '../SubWindow';
import { DataState } from '../../Data/DataState';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import CallViewMini from './CallViewMini';
import { Column, darken, Panel, Row, setAlpha, useTheme } from '@openteam/design-system';
import { useHoverIntent } from '../../Util/HoverIntent';
import { ToolTipContainer } from '../../Controllers/ToolTips';
import { action, makeAutoObservable } from 'mobx';
import CallFooter from './CallFooter';
import CallHeader from './CallHeader';
import { Draggable } from '../Draggable';
import { ChatMarkdownCSS } from '../Chat/ChatMarkdownView';
import { RemirrorCompactCSS } from '../Chat/MarkdownEditor';
import CallTileLayout from './CallTileLayout';
import CallChatContainer from './CallChatContainer';
import { ThemeState } from '../../DesignSystem/DSTheme';
import { UIState } from '../../Data/UIState';
import { setCallWidgetHovered } from '@openteam/app-core';

const logger = new Logger('CallWindow');

const MINI = 300;
const SMALL = 600;
const LARGE_SIZE = { width: 1200, height: 600 };
const MIN_SIZE = { width: 250, height: 125 };
const START_SIZE = { width: 440, height: 220 };

function getSize(width: number, height: number) {
  if (width < MINI && height < MINI) {
    return 'small';
  } else if (width >= LARGE_SIZE.width && height >= LARGE_SIZE.height) {
    return 'large';
  } else {
    return 'medium';
  }
}

const CallWindow: React.FC = () => {
  const id = 'CallWindow';

  const isUsingVibrancy = UIState.mainCapabilities.vibrancy && ThemeState.selectedTheme == 'SYSTEM';
  const call = DataState.activeCall!;
  const windowRef = useRef<SubWindowHandle>(null);
  const [moving, setMoving] = useState(false);
  const moveTimeout = useRef<ReturnType<typeof setTimeout>>();
  const restoreTo = useRef<{ width: number; height: number }>(MIN_SIZE);

  const theme = useTheme();

  const [_hovered, hoverRef] = useHoverIntent<HTMLDivElement>({
    timeout: 500
  });

  const hovered = _hovered || moving || call.disableTransparency || call.hovered;
  const isWidget = call.mode === 'medium';
  const floating = isWidget && !hovered;


  logger.debug(`hovered: ${hovered}, floating: ${floating} isMinimized: ${call.isMinimized}, participant length: ${call.participantList.length} initialSize: ${call.initialSize}`);

  const bounds = useRef<IBounds>({
    x: undefined,
    y: undefined,
    ...(
      call.mode == 'small'
        ? MIN_SIZE
      : {
          height: 220,
          width: Math.min(6, Math.max(2, call.initialSize)) * 220
        }
    )
  });

  const checkSize = useCallback(
    action(() => {
      const { width, height } = bounds.current;
      call.mode = getSize(width, height)
    }),
    []
  );

  useEffect(() => {
    (async () => {
      try {
        if (call.mode === 'large') {
          await Promise.all([
            window.Main.setWindowVisibleOnAllWorkspaces(id, false, { skipTransformProcessType: true }),
            window.Main.setWindowAlwaysOnTop(id, false),
            window.Main.setWindowShadow(id, true)
          ]);
        } else {
          await Promise.all([
            window.Main.setWindowVisibleOnAllWorkspaces(id, true, { skipTransformProcessType: true }),
            window.Main.setWindowAlwaysOnTop(id, true),
            window.Main.setWindowShadow(id, false)
          ]);
        }
      } catch (err) {
        logger.error(`Error setting window visiblity: `, err);
      }
    })();
  }, [call.mode]);

  useEffect(() => {
    (async () => {
      try {
        if (floating || !isUsingVibrancy) {
          await window.Main.setWindowVibrancy(id, undefined);
        } else if (isUsingVibrancy) {
          await window.Main.setWindowVibrancy(id, 'hud');
        }
      } catch (err) {
        logger.error(`Error setting window vibrancy: `, err);
      }
    })();
  }, [floating, isUsingVibrancy]);

  useEffect(() => {
    if (call.forceMode) {
      if (call.forceMode === 'small' && call.mode !== 'small') {
        onCollapse();
      } else if (call.forceMode === 'medium' && call.mode !== 'medium') {
        windowRef.current?.setBounds({
          height: 220,
          width: Math.min(6, Math.max(2, call.participantList.length+1)) * 220
        })
      }
      call.forceMode = undefined;   
    }
  }, [call.forceMode])

  const triggerMove = useCallback((timeoutMS = 1000) => {
    if (moveTimeout.current) {
      clearTimeout(moveTimeout.current);
    } else {
      setMoving(true);
    }

    moveTimeout.current = setTimeout(() => {
      setMoving(false);
      moveTimeout.current = undefined;
    }, timeoutMS);
  }, []);

  const onShow = useCallback(() => {
    checkSize();
    triggerMove(3000);
    call.isHidden = false;
  }, [triggerMove, checkSize]);

  const onHide = useCallback(() => {
    call.isHidden = true;
  }, [triggerMove, checkSize]);

  const onResize = useCallback(
    ({ bounds: newBounds }) => {
      bounds.current = newBounds;
      checkSize();
      triggerMove();
    },
    [triggerMove]
  );

  const onMove = useCallback(
    ({ bounds: newBounds }) => {
      bounds.current = newBounds;
      triggerMove();
    },
    [triggerMove]
  );

  const onClose = useCallback(() => {
    call.actions.leaveCall();
  }, [call.actions.leaveCall]);

  const onMinimize = useCallback(
    action(() => {
      call.isMinimized = true;
    }),
    []
  );

  const onCollapse = useCallback(() => {
    const {width, height} = restoreTo.current;
    if (getSize(width, height) != 'small') {
      windowRef.current?.setBounds({...restoreTo.current, ...MIN_SIZE})  
    } else {
      windowRef.current?.setBounds(restoreTo.current);
    }
    restoreTo.current = bounds.current;
  }, []);

  const onExpand = useCallback(() => {
    const {width, height} = restoreTo.current;
    if (getSize(width, height) == 'small') {
      windowRef.current?.setBounds({...restoreTo.current, ...START_SIZE})
    } else {
      windowRef.current?.setBounds(restoreTo.current);
    }
    restoreTo.current = bounds.current;
  }, []);

  const onRestore = useCallback(
    action(() => {
      call.isMinimized = false;
    }),
    []
  );

  useEffect(() => {
    if (call.isMinimized) {
      window.Main.minimiseWindow(id);
    } else {
      window.Main.restoreWindow(id);
    }
  }, [call.isMinimized]);


  useEffect(() => {
    if (call.callMessageManager.numUnread && call.callMessageManager.numUnread > 0) {
      setCallWidgetHovered('unreadChat', true)
    } else {
      setCallWidgetHovered('unreadChat', false)
    }

    return () => setCallWidgetHovered('unreadChat', false)
  }, [call.callMessageManager.numUnread]);


  const alpha = call.mode === 'medium' ? 0.9 : 1;
  const dkn = ThemeState.currentTheme === 'LIGHT' ? 0.2 : 0.3;
  const background = isUsingVibrancy ? 'transparent' : setAlpha(darken(theme.palette.secondary.main, dkn), alpha);
  const backgroundHeader = setAlpha(darken(theme.palette.secondary.main, dkn/2), 0.8);
  const backgroundFooter = setAlpha(darken(theme.palette.secondary.main, dkn/2), 0.8);
  const shrinkCallView = false && isWidget && !floating;

  return (
    <SubWindow
      id={id}
      ref={windowRef}
      minWidth={MIN_SIZE.width}
      minHeight={MIN_SIZE.height}
      initialSize={bounds.current}
      show={true}
      onResize={onResize}
      onMove={onMove}
      onShow={onShow}
      onHide={onHide}
      onRestore={onRestore}
      onClose={onClose}
      protectContent={true}
      options={CallWindowOptions}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <style type="text/css">{ChatMarkdownCSS}</style>
      <style type="text/css">{RemirrorCompactCSS}</style>

      <ToolTipContainer parentId={id} show={!floating} showWhenUnfocused={true} />
      <Panel ref={hoverRef} style={{ width: '100%', height: '100%', color: theme.palette.text.primary }}>
        <Column style={Styles.panel({ floating, background })} spacing={0}>
          <Draggable
            windowId={id}
            onMoving={setMoving}
            style={Styles.header({ isWidget, background: backgroundHeader, hidden: floating })}
          >
            <CallHeader onMinimize={onMinimize} onCollapse={onCollapse} onExpand={onExpand} />
          </Draggable>
          <Row
            style={{
              flex: 1,
              maxHeight: shrinkCallView ? 'calc(100% - 28px)' : '100%',
              overflow: 'hidden',
              transition: 'max-height 0.2s'
            }}
          >
            <Column style={{ flex: 1, height: '100%', justifyContent: 'space-between', overflow: 'hidden' }}>
              {call.mode === 'small' ? <CallViewMini /> : <CallTileLayout callHovered={hovered} />}

              <CallFooter
                parentId={id}
                hidden={floating}
                style={Styles.footer({ isWidget, background: backgroundFooter })}
              />
            </Column>
            <CallChatContainer />
          </Row>
        </Column>
      </Panel>
    </SubWindow>
  );
};

export default observer(CallWindow);

class StyleClass {
  constructor() {
    makeAutoObservable(this);
  }
  panel({ floating, background }): CSSProperties {
    return {
      position: 'relative',
      justifyContent: 'center',
      borderRadius: 4,
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      background: floating ? 'transparent' : background,
      transition: floating ? 'all 1s ease 0.2s' : 'all 0.1s ease'
    };
  }
  header({ isWidget, background, hidden }): CSSProperties | undefined {
    return isWidget
      ? {
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          background,
          zIndex: 10,
          transition: 'opacity 0.2s',
          opacity: hidden ? 0 : 1
        }
      : undefined;
  }
  footer({ isWidget, background }): CSSProperties | undefined {
    return isWidget
      ? {
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          background,
          zIndex: 10
        }
      : undefined;
  }
}

const Styles = new StyleClass();

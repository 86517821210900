import { OTGlobals, toggleCallSelfMinimized } from "@openteam/app-core";
import { action } from "mobx";
import React from "react";
import { FaEllipsisH } from "react-icons/fa";
import { openCallMenu } from "./callButtonUtil";
import { DataState } from "../../../Data/DataState";
import { Logger } from "@openteam/app-util"
import { getUserSettingsMenu } from ".";
import { IconButton, IconButtonProps } from "@openteam/design-system";

const logger = new Logger('SettingsButton');

export const SettingsButton: React.FC<IconButtonProps> = ({
  style,
  size,
  color,
  ...otherProps
}) => {
  const call = DataState.activeCall!;

  const openMenu = React.useCallback(() => {

    const numDummyUsers = call.participantList.filter(id => id.startsWith('dummy')).length;

    let template: Electron.MenuItemConstructorOptions[] = [
      { enabled: false, label: `Call Controls` },
      ...call.participantList.map(userId => ({
        label: call.participants[userId].name,
        submenu: getUserSettingsMenu(userId, false)
      })),
      {
        label: "Mute all participants",
        click: () => call.actions.setAllMuted(true)
      },
      {
        label: "Un-mute all participants",
        click: () => call.actions.setAllMuted(false)
      },

      { type: "separator" },
      { enabled: false, label: `My video view` },
      {
        type: "checkbox",
        label: "Minimized",
        checked: call.minimizeSelf,
        click: toggleCallSelfMinimized
      },
      //{
      //  type: "checkbox",
      //  label: "Hide my video",
      //  checked: hideVideo,
      //  click: () => {}
      //},
      { type: "separator" },
      { enabled: false, label: `Screen Share Controls` },
      {
        type: "checkbox",
        label: "Popout Screen Shares Automatically",
        checked: OTGlobals.localUserSettings.screenSharePopoutOnStart,
        click: () => OTGlobals.userSettingsManager.updateLocalSettings(
          { screenSharePopoutOnStart: !OTGlobals.localUserSettings.screenSharePopoutOnStart }
        )
      },
      { enabled: false, label: `Default Popout Size` },
      {
        type: "radio",
        label: "Maximised",
        checked: OTGlobals.localUserSettings.screenSharePopoutSize === "MAXIMISE",
        click: () => OTGlobals.userSettingsManager.updateLocalSettings(
          { screenSharePopoutSize: "MAXIMISE" }
        )
      },
      {
        type: "radio",
        label: "Half screen size",
        checked: OTGlobals.localUserSettings.screenSharePopoutSize === "HALF_SCREEN",
        click: () => OTGlobals.userSettingsManager.updateLocalSettings(
          { screenSharePopoutSize: "HALF_SCREEN" }
        )
      },
      {
        type: "radio",
        label: "Original Size",
        checked: OTGlobals.localUserSettings.screenSharePopoutSize === "ORIGINAL",
        click: () => OTGlobals.userSettingsManager.updateLocalSettings(
          { screenSharePopoutSize: "ORIGINAL" }
        )
      },
    ];



    if (DataState.deviceSettings["screenshareQuality"]) {
      const { activeDeviceId: quality, availableDevices: sizes } =
        DataState.deviceSettings["screenshareQuality"];

      template.push({ type: "separator" })
      template.push({ enabled: false, label: `My Screen Share Quality` }),

        Object.keys(sizes).forEach((size) => {
          template.push({
            type: "radio",
            label: sizes[size].label,
            checked: size === quality,
            click: () => call?.actions.updateDeviceSettings("screenshareQuality", size),
          } as Electron.MenuItemConstructorOptions);
        });
    }



    if (import.meta.env.DEV) {
      template = template.concat([
        { type: "separator" },
        { enabled: false, label: `Development Settings` },
        { type: "separator" },
        {
          type: "checkbox",
          label: "Disable Transparency",
          checked: call?.disableTransparency,
          click: action(() => call!.disableTransparency = !call?.disableTransparency)
        },
        { type: "separator" },
        { enabled: false, label: `Dummy Users` },
        ...[0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 15, 20, 24].map((num) => ({
          type: "radio",
          label: `${num} dummy users`,
          checked: num === numDummyUsers,
          click: () => call.actions.setNumDummyParticipants(num)
        } as Electron.MenuItemConstructorOptions)),
      ]);
    }

    openCallMenu(template);
  }, [OTGlobals, call?.disableTransparency]);

  return (
    <IconButton
      data-tooltip="Call settings"
      analyticsEvent="CallSettings"
      onClick={() => openMenu()}
      {...otherProps}
    >
      <FaEllipsisH />
    </IconButton>
  );
};

import React, { useEffect, useCallback, useState } from 'react';
import { Panel, Row, IconButton, Icons, useTheme, darken } from '@openteam/design-system';
import { Logger } from '@openteam/app-util';
import { DataState } from '../../Data/DataState';
import { SubWindowCSS } from '../SubWindow';
import { PopupWindow } from '../Popup/PopupWindow';
import { ChatMarkdownCSS } from '../Chat/ChatMarkdownView';
import { RemirrorCompactCSS } from '../Chat/MarkdownEditor';
import CallChat from './CallChat';
import { setCallWidgetHovered } from '@openteam/app-core';
import { isMacOs } from 'react-device-detect';

const logger = new Logger('CallChatWindow');

type Props = {
  targetRef: React.MutableRefObject<HTMLDivElement | HTMLElement | Element | null>;
  parentId: string;
  show: boolean;
  onClose: () => void;
};

export const CallChatWindow: React.FC<Props> = (props) => {
  const { targetRef, parentId, show, onClose } = props;
  const call = DataState.activeCall!;
  const theme = useTheme();
  const [disableOnBlur, setDisableOnBlur] = useState(false);

  logger.debug(`parent: ${parentId}, show: ${show} target: ${targetRef.current}, disableOnBlur: ${disableOnBlur}`);

  useEffect(() => {
    if (show) {
      setCallWidgetHovered('ChatOpen', true);
      return () => {setCallWidgetHovered('ChatOpen', false)}
    }
  }, [show])

  return (
    <PopupWindow
      id="CallChatWindow"
      targetRef={targetRef}
      parentId={parentId}
      open={show}
      offset={5}
      width={350}
      height={500}
      onClose={() => {
        onClose();
        call.callMessageManager.unfocusChat('chatWindow');
      }}
      avoidParent={true}
      popupPosition="bottom"
      popupAlign="left"
      disableOnBlur={disableOnBlur}
      options={{visualEffectState: 'active'}}
      onFocus={() => {
        call.callMessageManager.focusChat('chatWindow', true);
        call.callMessageManager.markChatRead();
      }}
      onBlur={() => {
        call.callMessageManager.unfocusChat('chatWindow');
      }}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <style type="text/css">{ChatMarkdownCSS}</style>
      <style type="text/css">{RemirrorCompactCSS}</style>
      <Panel
        style={{
          backgroundColor: isMacOs ? 'transparent' : darken(theme.palette.secondary.main, 0.1),
          color: theme.palette.text.primary,
          width: '-webkit-fill-available',
          padding: 4,
          flex: 1
        }}
      >
        <IconButton
          noBadge
          onClick={props.onClose}
          style={{ alignSelf: 'end' }}
        >
          {Icons.close}
        </IconButton>
        <CallChat spaceId={call.spaceId} callId={call.id} callMessageManager={call.callMessageManager} setDisableOnBlur={setDisableOnBlur}/>
      </Panel>
    </PopupWindow>
  );
};

import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { DSInput } from '../../DesignSystem/DSInput';
import { DSH3 } from '../../DesignSystem/DSText';
import { DataState } from '../../Data/DataState';
import { DSModalPanel } from '../Modal/ModalLayout';
import { DSButton, DSLongButton } from '../../DesignSystem/DSButton';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DSColumn, DSRow } from '../../DesignSystem';
import { DS2RejectButton, DS2SaveButton } from '../../DesignSystem/DS2';



export const EditSubTeam: React.FC<
  {
    windowId: string
    spaceId: string
    subTeamId?: string | undefined
    onClose: () => void
  }> = observer(({ windowId, spaceId, subTeamId, onClose }) => {
    const space = DataState.spaces[spaceId];

    const [name, setName] = useState<string>(subTeamId ? space.subTeams[subTeamId] : "");

    const onDelete = () => {
      space.actions?.deleteSubTeam?.(subTeamId!);
      onClose();
    }
    const onSave = () => {
      if (subTeamId) {
        space.actions?.updateSubTeamName?.(subTeamId, name);
      } else {
        space.actions?.createSubTeam?.(name);
      }
      onClose();
    }

    return (
      <DSModalPanel
        windowId={windowId}
        title={subTeamId ? "Edit Team" : "Create a new team"}
        onClose={onClose}
        footer={<DSRow style={{marginTop: 15}} spacing={8}>
        <DS2RejectButton
          title='Cancel'
          fullWidth
          onClick={onClose}
        />
        <DS2SaveButton
          title={subTeamId ? "Save" : "Create Team"}
          fullWidth
          onClick={onSave}
        />
    </DSRow>}
        >
        <DSH3 style={{ marginBottom: 6 }}>
          Name
        </DSH3>
        <DSInput style={{
          padding: "4 5",
          borderRadius: 4,
        }}
          value={name}
          onChange={(e) => setName(e.target.value)} />

      </DSModalPanel >
    )
  })

import { OTUITree } from '@openteam/app-core';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { DSInput, DSTextArea } from '../../DesignSystem/DSInput';
import { DSH3, DSPrint, DSSmallPrint } from '../../DesignSystem/DSText';
import { DSColumn, DSRow, DSWrappedRow } from '../../DesignSystem';
import { DataState } from '../../Data/DataState';
import { DSModalPanel } from '../Modal/ModalLayout';
import { IUISpacePod, UITRoomType } from '@openteam/models';
import * as Analytics from '../../Util/Analytics'
import { EmojiButton } from '../User/EmojiButton';
import { DS2BaseButton, DS2DeleteButton, DS2Radio, DS2SaveButton, DS2Switch } from '../../DesignSystem/DS2';
import { RoomIcon } from '../Room/RoomIcon';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { RadioGroup, useTheme, styled } from '@openteam/design-system';
import { getStringBackground } from '../../Util/StringColor';
import emojiRegex from 'emoji-regex';
import { Logger } from '@openteam/app-util';

const logger = new Logger('PodSettings')

interface IAddPodProps {
  windowId: string
  spaceId: string
  podId?: string
  topLevel?: boolean
  onClose: () => void
}

export const PodSettings = observer(({ windowId, spaceId, podId, ...props }: IAddPodProps) => {
  const space = DataState.spaces[spaceId];

  const pod: IUISpacePod | undefined = podId ? space.pods[podId] : undefined
  const [name, setName] = useState<string>(pod?.name || "");
  const [_symbol, setSymbol] = useState<string>(pod?.symbol || "");
  const [desc, setDesc] = useState<string>(pod?.desc || "");
  const [podBackground, setPodBackground] = useState(pod?.color ?? getStringBackground(pod?.name ?? '', true))
  const [roomType, setRoomType] = useState<UITRoomType>(pod?.roomType ?? 'normal')
  const [isQuietRoom, setIsQuietRoom] = useState<boolean>(pod?.roomConfig?.call === undefined ? false : !pod?.roomConfig?.call);
  const symbol = _symbol.length ? _symbol : name ? name.split(' ').filter(w => !!w).slice(0,2).map(w => w[0]).reduce((s, x)=> s+x, '') : ''

  const myUserId = OTUITree.auth.userId;

  const theme = useTheme();

  useEffect(() => {
    if (podId) {
      Analytics.logEvent("Screen_EditPod")
    } else {
      Analytics.logEvent("Screen_AddPod")
    }
  }, [])


  const onDelete = () => {
    if (pod) {
      pod.actions.archivePod()
      props.onClose();
    }
  }

  const onLeave = () => {
    if (podId) {
      space.channels[podId].actions.removeChannelUser(myUserId)
      props.onClose();
    }
  }

  const onSave = () => {
    const space = DataState.spaces[spaceId]
    const func = pod ? pod.actions.updatePod : space.actions.createPod;

    if (name && func) {
      func({
        name,
        symbol,
        color: podBackground,
        desc,
        roomType,
        roomConfig: {
          call: !isQuietRoom,
        }
      })
      props.onClose()
      Analytics.logEvent(pod ? "EditPodSave" : "AddPodSave")
    }
  }

  const isEmoji = symbol.match(emojiRegex())

  logger.debug(`symbol ${symbol} _symbol: ${_symbol}`)
  return (
    <DSModalPanel
      windowId={windowId}
      title={pod ? "Room Settings" : "Create a Room"}
      panelStyle={{padding: 0}}
      {...props}
      footer={
        <DSRow
          style={{
            marginTop: 16,
            alignItems: "flex-end"
          }}
          spacing={8}
        >
          {pod && <DS2BaseButton color='secondary' noBadge fullWidth onClick={onLeave}> Leave Room </DS2BaseButton>}

          {pod && <DS2DeleteButton fullWidth onClick={onDelete} />}

          <DS2SaveButton fullWidth onClick={onSave} />
        </DSRow>
      }

    >
      <DSColumn spacing={12}>
        <Section>
          <DSH3>
            Room Name
          </DSH3>
          <DSSmallPrint style={{opacity: 0.7}} wrap>Enter room name and configure the room icon color and emoji or up to two letters.</DSSmallPrint>
          <DSInput value={name} onChange={(e) => setName(e.target.value)} />
        </Section>

        <Section>
          <DSRow style={{}} spacing={16}>
            <RoomIcon spaceId={spaceId} background={podBackground} symbol={symbol} size={82} />
            <DSColumn spacing={8}>
              <DSRow style={{ justifyContent: 'space-between' }}>

                <EmojiButton
                  parentId={windowId}
                  value={isEmoji ? symbol : undefined}
                  addEmoji={(emoji) => { setSymbol(emoji) }}
                  buttonStyle={{ padding: 0 }}
                />

                <DSInput maxLength={2}
                  style={{ width: "90px" }}
                  value={isEmoji ? '' : symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                  placeholder='Type Symbol'
                />
              </DSRow>

              <DSWrappedRow style={{ width: 170, backgroundColor: DSTheme.InputColor, padding: 4, borderRadius: 4 }}>
                {DSTheme.PodBackgrounds.map(([background, _], index) => (
                  <div style={{ cursor: 'pointer', borderWidth: 1, padding: 2, borderStyle: 'solid', borderColor: background == podBackground ? 'rgba(182, 182, 182, 1)' : 'transparent', borderRadius: 2 }} onClick={() => setPodBackground(background)}>
                    <RoomIcon spaceId={spaceId} background={background} size={14} symbol={''} />
                  </div>
                ))}

              </DSWrappedRow>
            </DSColumn>

          </DSRow>
        </Section>

        <Section>
          <DSH3>Description </DSH3>
          <DSTextArea value={desc} onChange={(e) => setDesc(e.target.value)} />
        </Section>

        <Section>
          <DSH3>Room access </DSH3>
          <RadioGroup value={roomType} onChange={(ev) => setRoomType(ev.target.value as UITRoomType)}>
            <DS2Radio label='Normal' value='normal' />
            <RadioDesc wrap>
              This Room is visable to all Teammates allowing anyone to join.
            </RadioDesc>
            <DS2Radio label='Private' value='private' />
            <RadioDesc wrap>
              This Room is only visable to Teammates who have been invited to join.
            </RadioDesc>
            <DS2Radio label='Company wide' value='global' />
            <RadioDesc wrap>
              All Teammates will join this room automatically.
            </RadioDesc>
          </RadioGroup>
        </Section>

        <Section>
          <DSRow style={{ alignItems: 'center' }}>
            <DSH3 style={{ flex: 1 }}>
              Quiet Room
            </DSH3>
            <DS2Switch
              disabled={roomType === 'private'}
              onChange={setIsQuietRoom}
              checked={isQuietRoom}
            />
          </DSRow>
        </Section>

      </DSColumn>
    </DSModalPanel >
  )
})

export default PodSettings


const Section = styled(DSColumn)``
Section.defaultProps = {
  spacing: 6
}

const RadioDesc = styled(DSSmallPrint)(() => ({
  marginLeft: 6, marginBottom: 6 , opacity: 0.7
}))

import { OTGlobals } from '@openteam/app-core';

import { autorun } from "mobx";
import { Logger, AwaitLock } from '@openteam/app-util';
import { IAudioContainer } from '@openteam/models';


const logger = new Logger("SoundEffects")


export class SoundEffectPlayer implements IAudioContainer {
  _player: HTMLAudioElement;
  _stateLock = new AwaitLock();
  playing: boolean = false

  constructor() {
    this._player = new Audio();
    this.setSink()

    this._player.play();

    autorun(this.setSink, { delay: 1000 });

  }

  setSink = () => {
    const deviceId = OTGlobals.mediaDevices.audiooutput?.deviceId;
    // @ts-ignore: setSinkId type missing in HTMLAudioElement
    if (this._player.setSinkId) {
      // @ts-ignore: setSinkId type missing in HTMLAudioElement
      this._player.setSinkId(deviceId);
    }
  }

  play = async (src?: string) => {
    await this._stateLock.acquireAsync();
    logger.debug("audio play got lock");

    try {
      if (src) {
        this._player.src = src;
      }
      this.playing = true;
      const promise = this._player.play();

      await promise;
    } finally {
      logger.debug("audio release lock");

      this._stateLock.release();
    }
  };

  setSource = async (src: string, loop?: boolean) => {
    await this._stateLock.acquireAsync();

    try {
      if (src) {
        this._player.src = src;
      }
      this._player.loop = loop ? true : false;

      logger.debug("set source");
    } finally {
      this._stateLock.release();
    }
  };

  pause = async () => {
    await this._stateLock.acquireAsync();
    try {
      await this._player.pause();
      this.playing = false;
    } finally {
      this._stateLock.release();
    }
  };
}

const soundEffect: SoundEffectPlayer = new SoundEffectPlayer();
const incomingRing: SoundEffectPlayer = new SoundEffectPlayer();
const outgoingRing: SoundEffectPlayer = new SoundEffectPlayer();

export function playGlobalAlert() {
  const chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/globalchime.mp3?alt=media"
  soundEffect.play(chimemp3);
}

export function playAlert() {
  const chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/me-too.mp3?alt=media"
  soundEffect.play(chimemp3);
}

export function playVideoBell() {
  const chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Fting.mp3?alt=media"
  soundEffect.play(chimemp3);
}

export function playKnock() {
  const chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/knockknockknock.mp3?alt=media"
  soundEffect.play(chimemp3);
}

export function playNewMessage() {
  const chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Fmessage-notify.mp3?alt=media"
  soundEffect.play(chimemp3);
}

export function playUnmute() {
  const unmutemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Funmute.mp3?alt=media"
  soundEffect.play(unmutemp3);
}

export function getIncomingRingtone() {
  const incomingmp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Fincoming-call.mp3?alt=media"
  incomingRing.setSource(incomingmp3, true)

  return incomingRing
}


export function getOutgoingRingtone() {
  const outgoingmp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Foutgoing-call.mp3?alt=media"
  outgoingRing.setSource(outgoingmp3, true)

  return outgoingRing
}

export function getQuietIncomingCall() {
  const mp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Fquietincomingcall.mp3?alt=media&token=2219850a-5749-47b0-a966-847473ffcf5e"
  incomingRing.setSource(mp3, true)

  return incomingRing
}

export function getQuietOutgoingCall() {
  const mp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Fquietincomingcall.mp3?alt=media&token=2219850a-5749-47b0-a966-847473ffcf5e"
  incomingRing.setSource(mp3, true)

  return incomingRing
}

export function playTestAudio() {
  const testmp3 =
    "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Ftestaudio.mp3?alt=media";
    soundEffect.play(testmp3);

}

export function playScreenShareStart() {
  const chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Fmessage-notify.mp3?alt=media"
  soundEffect.play(chimemp3);
}

export function playPluginOpen() {
  const chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Fmessage-notify.mp3?alt=media"
  soundEffect.play(chimemp3);
}

import React from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks'
import emojiRegex from 'emoji-regex';
import { findAndReplace } from 'mdast-util-find-and-replace';
import { openUserChat } from "../../Data/UIState";
import { Logger } from "@openteam/app-util";

const logger = new Logger("ChatMarkdownView");

const mentionRegex = new RegExp("mention://user/(.*?)$")
const editedRegex = new RegExp("#edited#");

type Props = {
  message: string
  spaceId: string
}

export const ChatMarkdownView: React.FC<Props> = (props) => {

  const message = props.message?.replace(/  \n/gi, '\n &nbsp;').replace(/&nbsp;>/gi, ">"); // hack to get markdown to obey blank lines
  return (
    <ReactMarkdown
      className='chatMarkdownView'
      children={message}
      remarkPlugins={[remarkEmoji, remarkGfm, remarkEmphasis, remarkEdited, remarkBreaks]}
      components={{
        a: ({ node, href, ...tagProps }) => {
          if (node.properties?.href) {

            const groups = (node.properties?.href as string)?.match(mentionRegex);

            if (groups) {
              const userId = groups[1]

              return <a {...tagProps} className="markdownMention" href={node.properties?.href as string} onClick={(e) => {
                e.preventDefault();
                openUserChat(props.spaceId, userId)
              }} />
            }
          }

          return <a tabIndex={-1} href={href} {...tagProps} onClick={(e) => {
            e.preventDefault();
            href && window.Main.shellOpenExternal(href)
          }} />
        },
        code: ({ children }) => {
          const newChildren = parseBody(
              String(children),
              (t, key) => <code key={key}>{t}</code>,
              (h, key) => <mark key={key}>{h}</mark>);
          return (
            <>
              {newChildren}
            </>
          )
        }
      }}
    />
  )
}

function remarkEmoji() {
  function replace(match) {
    return {
      type: 'text',
      value: match,
      data: {
        hName: 'span',
        hProperties: { role: 'img', class: 'emoji' },
        hChildren: [{ type: 'text', value: match }],
      },
    };
  }

  function transform(markdownAST) {
    // @ts-ignore
    findAndReplace(markdownAST, emojiRegex(), replace);
    return markdownAST;
  }

  return transform;
}

const highlightNode = (text: string) => ({
  type: 'text',
  data: {
    hName: 'mark',
    hProperties: { class: 'highlight' },
    hChildren: [{ type: 'text', value: text }],
  }
});

const textNode = (text: string) => ({
  type: 'text',
  value: text
});


const parsePreamble = (ss: string, tn: (s: string) => any, hn: (s: string, key: string) => any): any[] => {

  if (ss.length === 0)
    return [];

  const m = ss.match(/(.*)#\/mark#(.*)/);

  if (!m) {
    return [tn(ss)];

  } else {
    const children: any[] = [];

    if (m[1].length)
      children.push(hn(m[1], 'preamble'));

    if (m[2].length)
      children.push(tn(m[2]));

    return children;
  }
}

const parsePostamble = (ss: string, tn: (s: string) => any, hn: (s: string, key: string) => any): any[] => {
  if (ss.length === 0)
    return [];

  const m = ss.match(/(.*)#mark#(.*)/);

  if (!m) {
    return [tn(ss)];

  } else {
    const children: any[] = [];

    if (m[1].length)
      children.push(tn(m[1]));

    if (m[2].length)
      children.push(hn(m[2], 'postamble'));

    return children;
  }
}

const parseBody = (ss: string, tn: (s: string, index) => any, hn: (s: string, key: string) => any): any[] => {
  var s = ss;


  var children: any[] = [];

  const subRe = /(.*?)#mark#(.*?)#\/mark#/;
  while (true) {
    const m = s.match(subRe)

    if (!m) {
      if (s.length) {
        children.push(tn(s, `code-${children.length}`));
      }

      break;

    } else {
      // first group may be undefined
      const startIndex = m.index ? m.index : 0;
      const m1 = !!m[1] ? m[1] : '';

      const pre = s.substring(0, startIndex + m1.length);
      const highlight = m[2];
      const post = s.substring(startIndex + m[0].length);

      if (pre.length)
        children.push(tn(pre, children.length));

      children.push(hn(highlight, `mark-${children.length}`));

      s = post;
    }
  }
  // logger.debug("children: ", children);

  return children;
}


const anyEmphasis = /.*(#mark#|#\/mark#).*/;
const matchedEmphasis = /.*?#mark#(.*?)#\/mark#.*/;
const onlyPreamble = /.*?#\/mark#.*/;
const onlyPostamble = /.*?#mark#.*/;


const getPreamble = (s: string): [string, string] => {
  const i = s.search(/#mark#/);

  if (i === -1)
    return [s, ''];

  return [s.slice(0, i), s.slice(i)];
}

const getPostamble = (s: string): [string, string] => {
  const m = s.match(/(.*#\/mark#)(.*)/);

  if (!m)
    return [s, ''];

  return [m[1], m[2]];
}

function remarkEdited() {
  function replace(match) {

    return {
      type: 'text',
      value: match,
      data: {
        hName: 'span',
        hProperties: { class: 'edited' },
        hChildren: [{ type: 'text', value: "(edited)" }],
      },
    };
  }

  function transform(markdownAST) {
    // @ts-ignore
    findAndReplace(markdownAST, editedRegex, replace);
    return markdownAST;
  }

  return transform;
}

function remarkEmphasis() {
  function replace(s: string) {

    if (s.match(matchedEmphasis)) {

      const [preamble, rest] = getPreamble(s);
      const [body, postamble] = getPostamble(rest);

      //logger.debug("preamble: ", preamble);
      //logger.debug("body: ", body);
      //logger.debug("postamble: ", postamble);

      return [
        ...parsePreamble(preamble, textNode, highlightNode),
        ...parseBody(body, textNode, highlightNode),
        ...parsePostamble(postamble, textNode, highlightNode),
      ]

    } else if (s.match(onlyPreamble)) {
      return parsePreamble(s, textNode, highlightNode);

    } else if (s.match(onlyPostamble)) {
      return parsePostamble(s, textNode, highlightNode);
    }
  }

  function transform(markdownAST) {
    // @ts-ignore
    findAndReplace(markdownAST, anyEmphasis, replace);
    return markdownAST;
  }

  return transform;
}


export const ChatMarkdownCSS = `

.chatMarkdownView .emoji {
  font-size: 24px;
  vertical-align: middle;
}

.chatMarkdownView a.markdownMention {
  color: rgba(255, 159, 0, 1);
  text-decoration: none;
}


.chatMarkdownView .highlight {
}

.chatMarkdownView .edited {
  font-size: 12px;
  color: rgba(255, 159, 0, 1);
}

.chatMarkdownView p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  overflow-wrap: anywhere;
}

.chatMarkdownView a {
  color: rgba(0, 174, 249, 1);
}

.chatMarkdownView mark {
  background-color: rgba(255, 159, 0, 0.5);
  padding-top: 3px;
  padding-bottom: 3px;
}

.chatMarkdownView code {
  border: 1px solid #DADADA;
  border-radius: 4px;
  vertical-align: middle;
  padding: 1.6px;
  margin-left: 2px;
  margin-right: 2px;
}

.chatMarkdownView pre  {
  background-color: #F6F6F6;
  border: 1px solid #DADADA;
  color: #1D1C1D;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-wrap: break-word;
  word-break: normal;
  overflow-wrap: anywhere;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  padding: 1em;
  margin: 0.5em 0px;
  border-radius: 0.3em;
  max-width: 80vw;
}

.chatMarkdownView pre code {
  background-color: initial;
  border: none;
  line-height: inherit;
  padding: 0;
}

.chatMarkdownView blockquote {
  border-left: 3px solid grey;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  font-style: italic;
}

.chatMarkdownView blockquote p {
  color: #888;
}

`

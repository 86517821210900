import { observer } from "mobx-react";
import React from "react";
import { CSSProperties } from "react";
import { UIState } from "../Data/UIState";
import { DSPanel } from ".";
import { DSBadgeText } from "./DSText";
import { DSTheme } from "./DSTheme";

export type TBadgePosition = 'TopLeft' | 'BottomRight' | 'TopRight' | 'BottomLeft';

interface IDSBadgeViewProps {
  badgeNum?: number;
  badgeOverride?: string | JSX.Element
  badgePosition?: TBadgePosition
  style?: CSSProperties;
  show?: boolean;
  scaleToDock?: boolean
}

export const DSBadgeView: React.FC<IDSBadgeViewProps> = observer((props) => {
  const { badgeNum, badgeOverride, badgePosition: position = 'TopLeft', show = true, scaleToDock } = props

  const top = position.includes("Top") ? -1 : undefined
  const bottom = position.includes("Bottom") ? -1 : undefined
  const left = position.includes("Left") ? -1 : undefined
  const right = position.includes("Right") ? -1 : undefined

  let badgeSize = 16;
  let badgePadding = 4;
  let fontSize = 11;

  if (scaleToDock) {
    badgeSize = Math.floor(UIState.dockSize * 8 / 80 + 8);
    badgePadding = badgeSize / 4;
    fontSize = Math.floor(UIState.dockSize * 8 / 80 + 3);
  }

  return (badgeNum ?? 0) > 0 || badgeOverride ? (
    <div
      style={{
        position: "absolute",
        display: 'flex',
        top: top,
        bottom: bottom,
        left: left,
        right: right,
        height: badgeSize,
        minWidth: badgeSize,
        paddingLeft: badgePadding,
        paddingRight: badgePadding,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: badgeSize / 2,
        backgroundColor: DSTheme.BadgeColor,
        opacity: show ? 1 : 0,
        transition: "opacity 0.15s ease-in-out",
        boxSizing: 'border-box',
        ...props.style
      }}
    >
      <DSBadgeText style={{ color: "white", fontWeight: "bold", fontSize: fontSize }}>
        {
          badgeOverride ?
            badgeOverride
            :
            badgeNum
        }
      </DSBadgeText>
    </div>
  ) : null
})

export const DSBadgeHolder: React.FC<IDSBadgeViewProps> = observer((props) => {
  return <DSPanel style={{position: 'relative'}}>
    {props.children}
    <DSBadgeView badgeNum={props.badgeNum} style={props.style} />
  </DSPanel>
})

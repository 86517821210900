import { Logger } from "@openteam/app-util";
import { observer } from "mobx-react";
import React, { useLayoutEffect } from "react";
import { PopupWindow } from "../Popup/PopupWindow";
import { ModalOptions, SubWindow, SubWindowCSS } from "../SubWindow";
import { EmojiPicker } from './ChatEmojiPicker';
const logger = new Logger("EmojiPickerWindow")

type Props = {
  parentId: string;
  targetRef: React.MutableRefObject<HTMLElement | null>;
  show: boolean
  addEmoji: (emoji) => void
  onClose: () => void
}

export const EmojiPickerWindow: React.FC<Props> = observer((props) => {
  const windowId = `emoji-picker`

  logger.info("props.show", props.show, props.targetRef, props.parentId)
  return (
    <PopupWindow
      id={windowId}
      targetRef={props.targetRef}
      parentId={props.parentId}
      open={props.show}
      offset={10}
      width={330}
      height={350}
      onClose={props.onClose}
      options={{
        transparent: false,
        hasShadow: true,
      }}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <EmojiPicker onEmojiSelected={props.addEmoji} scrollHeight={undefined} tileSize={32} />
    </PopupWindow>
  )
})

import { observer } from "mobx-react";
import { KSpaceId, KSpaceUserId } from "@openteam/models";
import React, { useState, useRef, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { DataState } from "../../Data/DataState";
import { DSButton, DSCircleIconButton } from "../../DesignSystem/DSButton";
import { DSColumn, DSRow } from "../../DesignSystem";
import { DSH3 } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { timeSince } from "../../Util/DateFormat";
import { DS2Button, DS2Icons } from "../../DesignSystem";



interface Props {
  spaceId: KSpaceId
  userId: KSpaceUserId
}

export const ChatAlerts = observer((props: Props) => {
  const [refreshDate, setRefreshDate] = useState<number | undefined>(undefined);
  const timerID = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)

  const space = DataState.spaces[props.spaceId]
  const user = space.users[props.userId]


  const isActive = user.alerts?.called || user.alerts?.knocked

  const updateRefreshDate = () => {
    setRefreshDate(Date.now())
  }

  useEffect(() => {
    if (isActive && !timerID.current) {

      timerID.current = setInterval(
        updateRefreshDate,
        5000
      );
    } else if (!isActive && timerID.current) {
      clearInterval(timerID.current)

    }
  }, [
    isActive, timerID.current, refreshDate
  ])

  useEffect(() => {

    return () => {
      timerID.current && clearInterval(timerID.current)
    }
  }, [])

  const alerts: string[] = [];

  if (user.alerts?.called) {
    alerts.push(`${user.name} Called ${timeSince(user.alerts.called.timestamp, " ago")}`)
  }
  if (user.alerts?.knocked) {
    alerts.push(`${user.name} Knocked ${timeSince(user.alerts.knocked.timestamp, " ago")}`)
  }

  return (
    <DSColumn style={{ alignItems: 'center', justifyContent: 'center' }}>
      <DSColumn style={{ alignItems: 'center', justifyContent: 'center' }}>
        {
          alerts.map((alert) => (
            <DSRow style={{ alignItems: 'center', justifyContent: 'center', margin: 3 }}>
              <DS2Button
                startIcon={DS2Icons.close}
                onClick={user.alerts!.clear}
              >
                {alert}
              </DS2Button>
            </DSRow>
          ))
        }
      </DSColumn>
    </DSColumn>
  )

})

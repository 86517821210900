import { KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import React, { CSSProperties, useMemo, useState } from 'react';
import { DataState } from '../../Data/DataState';
import { Logger } from '@openteam/app-util';
import { DSH3 } from '../../DesignSystem/DSText';
import { DSConfirmedInput } from '../../DesignSystem/DSInput';
import { useCallback } from 'react';
import { SpaceIcon } from '../Space/SpaceIcon';
import { DSHSpacer, DSPanel, DSRow } from '../../DesignSystem';
import { useRef } from 'react';
import { FireUtils, OTUITree } from '@openteam/app-core';
import { DSSpinner } from '../../DesignSystem/DSSpinner';
import { DSAccept, DSImageUpload, DSReject } from '../../DesignSystem/DSIconButtons';
import * as Analytics from '../../Util/Analytics'
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DSImage } from '../../DesignSystem/DSImage';
import { getStringColor } from '../../Util/StringColor';
import { DS2UploadIconButton } from '../../DesignSystem/DS2';

const logger = new Logger("WorkspaceSettings")

export const WorkspaceSettings: React.FC<{
  spaceId: KSpaceId
}> = observer(({ spaceId }) => {

  const space = DataState.spaces[spaceId];
  const fileInput = useRef<HTMLInputElement>(null)
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | undefined>(undefined);
  const [showUploadedImage, setShowUploadedImage] = useState<boolean>(false);

  const backgroundColor = useMemo(() => getStringColor(space.details.name), [space.details.name])

  const updateName = useCallback((name: string) => {
    space.actions.updateTeamName?.(name);
  }, [space.actions.updateTeamName])

  const onFiles = useCallback((e) => {
    const files = e.target.files;
    const file = files[0];

    if (!file) {
      return;
    }
    logger.debug("uploading new profile image", file)

    setUploading(true);

    FireUtils.uploadUserFile(spaceId, OTUITree.auth.userId, "icon", file, (url) => {
      setUploading(false)
      setUploadedImageUrl(url);
      setShowUploadedImage(true);
    });

  }, [setUploading])

  const saveUploadedImage = useCallback(() => {
    logger.debug("uploaded new icon image", uploadedImageUrl)
    if (!uploadedImageUrl) {
      logger.error("no uploaded image url");
    } else {
      space.actions.updateTeamIcon?.(uploadedImageUrl);
      Analytics.logEvent("WorkspaceUpdateIconImage")
    }
  }, [uploadedImageUrl]);

  const tileSize = 225;

  return (
    <div>
      <DSRow style={{ marginBottom: 8 }}>
        <DSH3>Name</DSH3>
      </DSRow>
      <DSConfirmedInput
        style={{
          padding: "2 5",
          borderRadius: 4,
        }}
        containerStyle={{
          flex: "0 0 400px",
        }}
        value={space.details.name}
        onUpdate={(value) => updateName(value)}
      />
      <hr style={Styles.separator} />

      <DSRow style={{ marginTop: 12, marginBottom: 6, alignItems: 'center' }}>
        <DSH3 style={{ marginRight: 14 }}>
          Workspace logo
        </DSH3>
        <input
          ref={fileInput}
          style={{ display: 'none' }}
          type="file"
          onChange={onFiles}
          accept="image/x-png,image/gif,image/jpeg" />

        {
          uploading ?
            <DSSpinner size={20} />
            :
            <DS2UploadIconButton
              onClick={() => {
                fileInput.current?.click();
                Analytics.logEvent("WorkspaceIconOpenFilePicker");
              }} />
        }

      </DSRow>

      <DSRow style={{
        marginTop: 24,
        marginLeft: 30,
      }}>
        {(showUploadedImage && uploadedImageUrl) ? (
          <DSPanel
            style={{
              position: 'relative',
              borderRadius: DSTheme.BaseBorderRadius,
              width: tileSize,
              height: tileSize,
              overflow: 'hidden',
            }}>

            <DSImage
              url={uploadedImageUrl}
              style={{
                borderRadius: '50%',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                margin: 0,
                backgroundColor: backgroundColor,
                objectFit: 'cover',
                overflow: 'hidden',
              }}
              height={tileSize}
              width={tileSize}
            />

            <DSRow style={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 12,
              justifyContent: 'center'
            }}>
              <DSRow>
                <DSAccept onClick={() => saveUploadedImage()} />
                <DSHSpacer size={12} />
                <DSReject onClick={() => setShowUploadedImage(false)} />
              </DSRow>
            </DSRow>
          </DSPanel>
        ) : (
          <SpaceIcon spaceId={spaceId} tileSize={tileSize} />
        )}
      </DSRow>

    </div>
  );
});




const Styles: Record<string, CSSProperties> = {
  separator: {
    height: 0,
    width: "80%",
    borderTopWidth: 1,
    borderBottomWidth: 0,
    borderColor: "white",
    borderTopStyle: "solid",
    marginTop: 20,
  },
}

import React, { useEffect, useCallback } from "react";
import { isMacOs } from "react-device-detect";
import { FaTimes } from "react-icons/fa";
import { DSCircleIconButton, DSOutlineButton } from "../../../DesignSystem/DSButton";
import { DSColumn, DSHSpacer, DSPanel, DSPanelScrollable, DSRow, DSWrappedRow } from "../../../DesignSystem";
import { DSBody, DSH1, DSH2, DSH4, DSSmallPrint } from "../../../DesignSystem/DSText";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { SubWindow, SubWindowCSS } from "../../SubWindow";
import { Logger } from "@openteam/app-util";
import { Draggable } from "../../Draggable";
import { PopupWindow } from "../../Popup/PopupWindow";
import { ICapturerSource } from "@openteam/models";
import { OTGlobals, SCREENSHARE_SIZES } from "@openteam/app-core";
import { DSImage } from "../../../DesignSystem/DSImage";
import { ShareResourcePicker } from "./ShareResourcePicker";
import { DSSpinner } from "../../../DesignSystem/DSSpinner";
import * as Analytics from '../../../Util/Analytics'
import { UIState } from "../../../Data/UIState";
import { DS2Button } from "../../../DesignSystem";
import { openSystemPreferences } from "../../../Util/OpenSystemPreferences";

const logger = new Logger("ScreenSharePicker");

type Props = {
  targetRef: React.MutableRefObject<HTMLElement | null>;
  parentId: string
  show: boolean;
  amSharing: boolean;
  onClose: () => void;
  onStop: () => void;
  onStart: (stream: MediaStream, source: ICapturerSource) => void;
  onError: (err) => void;
};

type Sources = {
  screen: ICapturerSource[];
  window: ICapturerSource[];
};

export const CallScreenShareWindow: React.FC<Props> = (props) => {
  const {
    targetRef,
    parentId,
    show,
    amSharing,
    onClose,
    onStop,
    onStart,
    onError,
  } = props;
  return (
    <PopupWindow
      id="CallScreenSharePicker"
      targetRef={targetRef}
      parentId={parentId}
      open={show}
      offset={5}
      width={696}
      height={474}
      onClose={onClose}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <DSPanelScrollable
        style={{
          backgroundColor: DSTheme.BackgroundColor,
          color: DSTheme.MainText,
          width: "-webkit-fill-available",
          padding: 14,
          flex: 1,
        }}
      >
        <DSRow style={{ alignItems: 'flex-start' }}>
          <ScreenSharePicker {...props} />
          <DSHSpacer size={12} />
          <ShareResourcePicker />
        </DSRow>

      </DSPanelScrollable>
    </PopupWindow>
  );
};

const ScreenSharePicker: React.FC<Props> = (props) => {
  const {
    targetRef,
    parentId,
    show,
    amSharing,
    onClose,
    onStop,
    onStart,
    onError,
  } = props;

  const [sources, setSources] = React.useState<Sources | null>(null);
  const [havePermission, setHavePermission] = React.useState(!isMacOs);
  const permTimer = React.useRef<ReturnType<typeof setInterval>>()

  const checkPermission = useCallback(async () => {
    // logger.debug(`permission is ${await window.Main.getMediaAccessStatus("screen")}`, await loadSources())
    if (isMacOs && await window.Main.getMediaAccessStatus("screen") === "granted") {
      setHavePermission(true)
    } else {
      await loadSources()
    }
  }, []);

  useEffect(() => {
    if (show && !havePermission) {
      checkPermission()
    }
  }, [show, havePermission])

  useEffect(() => {
    if (show && havePermission) {
      logger.debug(`loading sources`);
      loadSources().then(setSources);
    } else {
      setSources(null);
    }
  }, [show, havePermission]);

  const onSourceSelect = useCallback(async (source: ICapturerSource) => {
    const constraints = getConstraints(source.id);
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      onStart(stream, source);
      onClose();
    } catch (err) {
      logger.error(`Failed to start screen `)
      onError(err);
    }

    Analytics.logEvent("CallShareScreen", { sourceId: source.id })

  }, []);

  const stop = useCallback(() => {
    logger.debug(`Stopping screen share`);
    onStop();
    onClose();
  }, [onStop, onClose]);


  return <DSPanel style={{
    backgroundColor: DSTheme.PanelDarkerColor,
    padding: "12 14",
    borderRadius: 8,
    width: 412,
    minHeight: "calc(100vh - 14px - 14px)",
  }}>
    <DSRow
      style={{
        justifyContent: "space-between",
        padding: 0,
        minHeight: 30,
      }}
    >
      <DSH2 style={{ cursor: "inherit" }}>Share Screen</DSH2>
      {amSharing && (

        <DSCircleIconButton
          stopPropagation={{ onClick: true }}
          onClick={stop}
          icon={
            <FaTimes
              size={DSTheme.IconSize}
              style={{
                color: DSTheme.ColorContrastColor,
              }}
            />

          }
          title="Stop Sharing"
          analyticsEvent="callStopScreenshare"
          style={{
            backgroundColor: DSTheme.CancelColor,
            padding: undefined,
            width: 26,
          }}
          lozenge
        />


      )}
    </DSRow>

    {
      sources ?
        <>
          {sources.screen.length > 0 ? (
            <>
              <DSH4 style={{ marginBottom: 8 }}>Entire Screen</DSH4>
              <DSWrappedRow style={{ marginLeft: -5, marginRight: -5, padding: 0 }}>
                {sources.screen.map((source) => (
                  <ScreenShareThumbnail
                    key={source.id}
                    source={source}
                    showName={false}
                    onSelect={onSourceSelect} />
                ))}
              </DSWrappedRow>
            </>
          ) : null}

          {
            sources.window.length > 0 ? (
              <>
                <DSH4 style={{ marginTop: 8, marginBottom: 8 }}>Application Window</DSH4>
                <DSWrappedRow style={{ marginLeft: -5, marginRight: -5, padding: 0 }}>
                  {sources.window.map((source) => (
                    <ScreenShareThumbnail
                      key={source.id}
                      source={source}
                      showName={true}
                      onSelect={onSourceSelect} />
                  ))}
                </DSWrappedRow>
              </>
            ) : null
          }
        </>
        : havePermission ? (
          <DSPanel style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <DSSpinner />
          </DSPanel>
        ) : (
          <DSColumn spacing={30} style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>

            <DSH2>We need your permission to screen share</DSH2>
            <DSBody>{`Please give ${UIState.productName} the screen recording permission in system settings`}</DSBody>
            <DS2Button onClick={() => openSystemPreferences("security", "Privacy_ScreenCapture")}>
              Open System Preferences
            </DS2Button>
          </DSColumn>
        )
    }

  </DSPanel>;
}


type ScreenShareThumbnailProps = {
  source: ICapturerSource;
  showName: boolean;
  onSelect: (source: ICapturerSource) => void;
};

const ScreenShareThumbnail: React.FC<ScreenShareThumbnailProps> = ({
  showName,
  onSelect,
  source
}) => {
  const ref = React.useRef(null)
  return (
    <div
      ref={ref}
      style={{ display: "flex", flexDirection: "column", width: 120, margin: "8 5", ...DSTheme.NoDrag, cursor: 'pointer' }}
      onClick={() => onSelect(source)}
      onPointerDown={e => (ref?.current === e.currentTarget && e.stopPropagation())}
    >
      <DSPanel style={{ width: 120, height: 88, justifyContent: 'center', alignItems: 'center' }}>
        <DSImage
          maxHeight={88}
          maxWidth={120}
          url={source.thumbnail} />
      </DSPanel>
      {showName && (
        <DSRow style={{ alignItems: 'center', marginTop: 3 }}>
          {source.appIcon && (
            <img
              onError={() => logger.warn(`appIcon`, source.appIcon)}
              src={source.appIcon}
              style={{ height: 16, width: 16, marginRight: 3 }}
              alt={""}
            />
          )}
          <DSSmallPrint style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{source.name}</DSSmallPrint>
        </DSRow>
      )}
    </div>
  );
};


async function loadSources () {
  const [screens, windows] = await Promise.all([
    window.Main.desktopCapturerGetSources({
      types: ["screen"],
      fetchWindowIcons: true,
    }),
    window.Main.desktopCapturerGetSources({
      types: ["window"],
      fetchWindowIcons: true,
    }),
  ]);

  return { screen: screens, window: windows };
}

function getConstraints (sourceId): MediaStreamConstraints {
  const quality = SCREENSHARE_SIZES[OTGlobals.localUserSettings.screenshareQuality!];

  return {
    audio: false,
    video: {
      mandatory: {
        chromeMediaSource: "desktop",
        chromeMediaSourceId: sourceId,
        maxWidth: quality?.width ?? 1920,
        maxHeight: quality?.height ?? 1080,
        maxFrameRate: 5,
      },
    }
  } as MediaStreamConstraints;
}

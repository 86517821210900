import React, { Children, CSSProperties, ReactNode, useCallback, PointerEventHandler } from "react";

interface PanelProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  reverse?: boolean;
  disableDrag?: boolean;
}

export const Panel = React.forwardRef<HTMLDivElement, PanelProps>(
  ({ style, children, disableDrag, onPointerDown, ...props }, ref) => {

    const _onPointerDown: PointerEventHandler<HTMLDivElement> = useCallback((ev) => {
      if (disableDrag) {
        ev.stopPropagation();
      }

      onPointerDown?.(ev);
   
    }, [disableDrag, onPointerDown])
    
    return (
      <div
        ref={ref}
        style={{
          alignItems: "stretch",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          ...(disableDrag ? {appRegion: 'no-drag', cursor: 'initial'} : null), 
          ...style,
        }}
        onPointerDown={_onPointerDown}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export const PanelScrollable = React.forwardRef<HTMLDivElement, PanelProps>(
  ({ style, children, ...props }, ref) => {
    return (
      <Panel
        ref={ref}
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          outline: "none",
          ...style,
        }}
        {...props}
      >
        {children}
      </Panel>
    );
  }
);

interface RowColProps extends PanelProps {
  spacing?: number;
  separator?: ReactNode;
}

export const Row = React.forwardRef<HTMLDivElement, RowColProps>(
  ({ style = {}, reverse = false, spacing, separator, children, ...props }, ref) => {
    const arrayChildren = Children.toArray(children);

    return (
      <Panel
        ref={ref}
        style={{ ...style, flexDirection: reverse ? "row-reverse" : "row" }}
        {...props}
      >
        {Children.map(arrayChildren, (child, index) =>
          index && (spacing || separator) ? (
            <>
              {spacing ? <HSpacer size={spacing} /> : null}
              {separator ? separator : null}
              {spacing && separator ? <HSpacer size={spacing} /> : null}
              {child}
            </>
          ) : (
            child
          )
        )}
      </Panel>
    );
  }
);

export const WrappedRow = React.forwardRef<HTMLDivElement, RowColProps>(
  ({ style = {}, children, ...props }, ref) => {
    return (
      <Row ref={ref} style={{ ...style, flexWrap: "wrap" }} {...props}>
        {children}
      </Row>
    );
  }
);

export const Column = React.forwardRef<HTMLDivElement, RowColProps>(
  ({ style = {}, reverse = false, spacing, separator, children, ...props }, ref) => {
    const arrayChildren = Children.toArray(children);
    return (
      <Panel
        ref={ref}
        style={{ ...style, flexDirection: reverse ? "column-reverse" : "column" }}
        {...props}
      >
        {Children.map(arrayChildren, (child, index) =>
          index && (spacing || separator) ? (
            <>
              {spacing ? <VSpacer size={spacing} /> : null}
              {separator ? separator : null}
              {spacing && separator ? <VSpacer size={spacing} /> : null}
              {child}
            </>
          ) : (
            child
          )
        )}
      </Panel>
    );
  }
);

export const WrappedColumn = React.forwardRef<HTMLDivElement, RowColProps>(
  ({ style = {}, children, ...props }, ref) => {
    return (
      <Column ref={ref} style={{ ...style, flexWrap: "wrap" }} {...props}>
        {children}
      </Column>
    );
  }
);

interface LabelledBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  legend: ReactNode;
}

export const LabelledBox = React.forwardRef<HTMLFieldSetElement, LabelledBoxProps>(
  ({ style = {}, children, legend }, ref) => {
    return (
      <fieldset
        ref={ref}
        style={{
          border: "white 1px solid",
          borderRadius: 10,
          marginTop: 4,
          marginBottom: 4,
          ...style,
        }}
      >
        <legend
          style={{
            fontSize: "16px",
            fontWeight: 600,
            color: "white",
            padding: "0px 10px",
          }}
        >
          {legend}
        </legend>
        {children}
      </fieldset>
    );
  }
);

export const HSpacer: React.FC<{ size: number; style?: CSSProperties }> = ({ size, style }) => (
  <div
    style={{ display: "flex", width: size, flexGrow: 0, flexShrink: 0, flexBasis: size, ...style }}
  />
);

export const VSpacer: React.FC<{ size: number; style?: CSSProperties }> = ({ size, style }) => (
  <div
    style={{ display: "flex", height: size, flexGrow: 0, flexShrink: 0, flexBasis: size, ...style }}
  />
);

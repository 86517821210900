import React, { Component, useEffect, useState } from 'react';
import { DSPanel } from '.';
import { DSTheme } from './DSTheme';

type VolueBarProps = {
  volume: number;
}

export function DSVolumeBar({ volume }: VolueBarProps) {

  return (
    <DSPanel style={{
      position: 'relative',
      height: 6,
      minHeight: 6,
      width: "100%",
      borderRadius: 3,
      backgroundColor: DSTheme.EmphasisBackgroundColor,
    }}>
      <DSPanel key="vol" style={{
        position: 'absolute',
        alignItems: 'center',
        height: 6,
        width: `${Math.min(100, volume / 6 * 100)}%`,
        borderRadius: 3,
        transitionProperty: 'transform width',
        transitionDuration: '0.3s',
        willChange: "transform width",
        backgroundColor: DSTheme.EmphasisColor,
      }} />
    </DSPanel>
  )
}


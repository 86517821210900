import React from 'react';
import { observer } from 'mobx-react';
import { SubWindow, SubWindowCSS } from '../SubWindow';
import { Logger } from '@openteam/app-util';
import { getDockSize, UIState } from '../../Data/UIState';
import { DataState } from '../../Data/DataState';
import { OTUITree } from '@openteam/app-core';
import { ChatMarkdownCSS } from '../Chat/ChatMarkdownView';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { KSpaceChannelId, KSpaceId } from '@openteam/models';
import { isMacOs } from 'react-device-detect';
import { getGenericWindowPosition } from '../../Controllers/DockWindowUtil';

const logger = new Logger('RoomWindow');

type Props = {
  windowId: string;
  title: string;
  initialWidth: number;
  initialHeight: number;
  minWidth: number;
  minHeight: number;
  onClose?: () => void
  onFocus?: () => void
  onBlur?: () => void
}

const RoomWindow: React.FC<Props> = (props) => {
  const { windowId, children, minWidth, minHeight, initialWidth, initialHeight } = props;

  const calcPosition = () => {
    const pos = getGenericWindowPosition(initialWidth, initialHeight)

    logger.debug(`Positioning to ${pos.x},${pos.y}`)

    return pos
  }

  const position = calcPosition()


  let documentListeners = {}

  documentListeners['drop'] = (event) => {
    event.preventDefault();
  }

  documentListeners['dragover'] = (event) => {
    event.preventDefault();
  }

  return (
    <SubWindow
      id={windowId}
      windowType="chatWindow"
      title={`${props.title} - ${UIState.productName}`}
      initialPosition={position}
      initialSize={{ width: initialWidth, height: initialHeight }}
      minWidth={minWidth}
      minHeight={minHeight}
      show={true}
      focus={true}
      onClose={props.onClose}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      protectContent={true}
      // visibleOnAllWorkspaces={true}
      documentListeners={documentListeners}
      options={{
        frame: false,
        transparent: isMacOs ? true : false,
        autoHideMenuBar: true,
        useContentSize: true,
        titleBarStyle: isMacOs ? 'hidden' : 'default',
        acceptFirstMouse: true,
        trafficLightPosition: {
          x: 10,
          y: 16
        },
        resizable: true,
        alwaysOnTop: false,
        skipTaskbar: false,
        focusable: true,
        maximizable: true,
        fullscreenable: false,
        hasShadow: true,
        closable: true,
        show: false,
        backgroundColor: DSTheme.CallBackgroundColor,
      }}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <style type="text/css">{ChatMarkdownCSS}</style>
      {children}
    </SubWindow>
  );
};

export default observer(RoomWindow);

import React from "react";
import { FaBell, FaBellSlash, FaCamera, FaCog, FaExternalLinkAlt, FaEye, FaEyeSlash, FaPen, FaThumbtack, FaTimes, FaUpload } from "react-icons/fa";
import { DSSpinner } from "../DSSpinner";
import {
  ButtonProps,
  IconActionButton,
  IconActionButtonProps,
  IconButton,
  IconButtonProps,
  Icons
} from "@openteam/design-system"



interface DS2NotifyButtonProps extends IconButtonProps {
  enabled?: boolean
}

export const DS2NotifyButton: React.FC<DS2NotifyButtonProps> = ({ enabled, ...props }) => {
  return (
    <IconButton {...props} >
      {enabled ? <FaBellSlash /> : <FaBell />
      }
    </IconButton>
  )
}

export const DS2SettingsButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton {...props} >
      {Icons.settings}
    </IconButton>
  )
}

export const DS2CancelIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton data-tooltip="Cancel" color="error" {...props} >
      {Icons.reject}
    </IconButton>
  )
}

export const DS2UploadIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconActionButton color='secondary' data-tooltip="Upload" {...props} >
      {Icons.upload}
    </IconActionButton>
  )
}

export const DS2EditIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconActionButton color='secondary' data-tooltip="Edit" {...props} >
      {Icons.edit}
    </IconActionButton>
  )
}

export const DS2DeleteIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconActionButton color='secondary' data-tooltip="delete" {...props} >
      {Icons.delete}
    </IconActionButton>
  )
}

export const DS2CreateIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconActionButton color='secondary' data-tooltip="create" {...props} >
      {Icons.create}
    </IconActionButton>
  )
}

export const DS2InviteIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconActionButton
      data-tooltip="Invite teammate"
      analyticsEvent="inviteUserButton"
      color='secondary'
      {...props}
    >
      {Icons.invite}
    </IconActionButton>
  )
}


export const DS2AcceptIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconButton
      data-tooltip="Accept"
      color="success"
      {...props}
    >
      {Icons.accept}
    </IconButton>
  )
}

export const DS2AcceptIconActionButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconActionButton
      data-tooltip="Accept"
      color="success"
      {...props}
    >
      {Icons.accept}
    </IconActionButton>
  )
}

export const DS2RejectIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconButton
      data-tooltip="Reject"
      color="error"
      {...props}
    >
      {Icons.reject}
    </IconButton>
  )
}

export const DS2RevertIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconButton
      color="error"
      {...props}
    >
      {Icons.revert}
    </IconButton>
  )
}

export const DS2RejectIconActionButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconActionButton
      data-tooltip="Reject"
      color="error"
      {...props}
    >
      {Icons.reject}
    </IconActionButton>
  )
}

export const DS2CaptureIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconActionButton {...props} >
      {Icons.camera}
    </IconActionButton>
  )
}

export const DS2CloseIconButton: React.FC<IconActionButtonProps> = (props) => {
  return (
    <IconActionButton color='secondary' {...props} >
      {Icons.close}
    </IconActionButton>
  )
}

export const DS2CopyIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconActionButton color='secondary' {...props} >
      {Icons.copy}
    </IconActionButton>
  )
}

export const DS2DownloadIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconActionButton color='secondary' {...props} >
      {Icons.download}
    </IconActionButton>
  )
}

export const DS2OpenExtIconButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconActionButton color='secondary' {...props} >
      {Icons.openExt}
    </IconActionButton>
  )
}

export const DS2ExternalIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      size="small"
      {...props}
    >
      {Icons.openExt}
    </IconButton>
  )
}

export const DS2EyeIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      size="small"
      {...props}
    >
      {Icons.show}
    </IconButton>
  )
}

export const DS2EyeSlashIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      size="small"
      {...props}
    >
      {Icons.hide}
    </IconButton>
  )
}

export const DS2BusyDisabledButton: React.FC<{}> = (props) => {
  return (
    <IconButton
      size="small"
      {...props}
      disabled={true}
    >
      <DSSpinner size={12} />
    </IconButton>
  )
}

import React, { CSSProperties, useCallback, useEffect } from "react";
import { Logger } from "@openteam/app-util";
import { DSPanel, DSRow } from "../../DesignSystem";
import { DSH3, DSH4 } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSButton } from "../../DesignSystem/DSButton";
import { doTutorialLater } from "../../Controllers/TutorialService";
const logger = new Logger("TutorialStep");
interface ITutorialViewProps {
  contents: () => JSX.Element
  showLaterButton?: boolean
}

export const TutorialView = (props: ITutorialViewProps) => {
  return (
    <DSPanel style={{
      backgroundColor: DSTheme.PanelBackgroundColor,
      padding: 16,
      borderRadius: 8,
      color: DSTheme.MainText
    }}>
      <props.contents />
      {

        props.showLaterButton ?
          <DSRow style={{ justifyContent: 'center', }}>
            <DSButton
              allowDuringTutorial={true}
              onClick={doTutorialLater}
              style={{
                marginTop: 4,
                padding: 3,
                color: DSTheme.SecondaryText
              }}>
              <DSH4>
                <u>
                  Do this later
                </u>
              </DSH4>
            </DSButton>
          </DSRow>
          :
          undefined
      }
    </DSPanel>
  )

}

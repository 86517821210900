import {
  User,
  getAuth,
  onAuthStateChanged as FBonAuthStateChanged,
  signInWithCustomToken as FBsignInWithCustomToken
} from 'firebase/auth';

import { Logger } from '@openteam/app-util';
import { OTUITree, FireAuth, UIDataStateReset } from '@openteam/app-core';
import { UIState, reset as UIState_reset } from '../Data/UIState';
import { IUserDoc } from '@openteam/models';
import { UserManagerDb } from '../../../../core/src/fire/UserManagerDb';
import { app, getRTDB } from './Fire';

const logger = new Logger('LoginController');

export function initLogin() {
  onAuthStateChanged(_onAuthStateChanged);
  // setupLoginTokenListener()
}

export function onAuthStateChanged(callback: (user: User | null) => void) {
  FBonAuthStateChanged(getAuth(app), callback);
}

export async function _onAuthStateChanged(user: User | null) {
  setTimeout(() => OTUITree.auth.onAuthStateChanged(user), 1);

  if (UIState.mainCapabilities.loginState) {
    if (user) {
      window.Main.setMainLoginState({ loggedIn: true, email: user.email! });
    } else {
      window.Main.setMainLoginState({ loggedIn: false });
    }
  }
}

export async function signInWithCustomToken(authToken) {
  const credential = await FBsignInWithCustomToken(getAuth(), authToken);
  logger.debug('got credential', credential);

  if (credential.user) {
    const user = credential.user;

    let name = user.displayName || user.email?.split('@')[0] || 'Unknown';

    UserManagerDb.setupAccount(getRTDB(), user.uid, name, user.email!, user.photoURL);
  }
}

export async function applyLoginToken(token: string) {
  if (OTUITree.auth.userId) {
    await signOut(false);
  }

  await signInWithCustomToken(token);
}

// export async function setupLoginTokenListener() {
//   const deeplinkingUrl = await window.Main.getDeepLink()
//   if (deeplinkingUrl) {
//     doDeepLink(deeplinkingUrl)
//   }
//   window.Main.on("deeplinkingUrl", handleDeepLink);
// }

// export function handleDeepLink(args) {
//   doDeepLink(args.deeplinkingUrl);
// }

// export async function doDeepLink(deeplinkingUrl) {
//   logger.info(`deeplinkingUrl is `, deeplinkingUrl);
//   const parsedUrl = new URL(deeplinkingUrl);
//   const token = parsedUrl.searchParams.get("token");
//   const path = parsedUrl.pathname;

//   if (deeplinkingUrl ) {
//     window.Main.clearDeepLink()
//   }

//   logger.info("parsedUrl", parsedUrl);

//   if (token) {
//     if (OTUITree.auth.userId) {
//       await signOut(false);
//     }

//     await signInWithCustomToken(token);
//   }
// }

export const signOut = async (refresh: boolean = true) => {
  logger.debug('signOut refresh=%s', refresh);

  await OTUITree.auth.signOut();
  await FireAuth.signOut();

  OTUITree.reset();
  UIState_reset();
  UIDataStateReset();
};

import React, { ReactNode, useCallback } from 'react';
import { ISubWindowProps } from '../SubWindow';
import { v4 as uuidv4 } from "uuid";
import { dockPanelClosed, dockPanelOpened, UIState } from '../../Data/UIState';
import { Logger } from "@openteam/app-util";
import { observer } from 'mobx-react';
import DockChatOpenIndicator from './DockChatOpenIndicator';
import { DockChatMessageIndicator } from './DockIndicator';
import PanelButton from '../PanelButton';
const logger = new Logger("DockIconBase");


interface IDockIconBase {
  id: string
  analyticsEvent?: string;
  analyticsArgs?: Record<string, string | null | undefined>;
  renderIcon: (showingWindow: boolean) => ReactNode
  indicator?: JSX.Element
  renderContents: (closeWindow: (
    onCloseCallback?: () => void) => void,
    windowId: string,
    openWindow: () => void,
    setDisableOnBlur: (disabled: boolean) => void
  ) => ReactNode;
  isOpen?: boolean;
  unread?: boolean;
  forceShow?: boolean;
  size?: number
  onContextMenu?: (e) => void;
  onDoubleClick?: () => void;
  withPanel?: boolean;
  subwindowProps?: Partial<ISubWindowProps>
  savePanel?: boolean
}

type Rect = {
  x: number
  y: number
  width: number
  height: number
}

const offset = 8

export const DockIconBase = observer(function DockIconBase (props: IDockIconBase) {

  const {
    id,
    renderIcon,
    indicator,
    isOpen,
    unread,
    ...otherProps
  } = props;

  const iconRef = React.useRef<HTMLDivElement>(null);
  const [showClickWindow, setShowClickWindow] = React.useState(false)

  const tipPosition = UIState.dockHorizontal ? UIState.dockPosition.onTop ?? UIState.dockEdge === 'top' ? 'bottom' : 'top' : UIState.dockPosition.onLeft ? 'right' : 'left'

  const onShowPanel = useCallback((shown) => {
    setShowClickWindow(shown)
    if (shown) {
      dockPanelOpened(id);
    } else {
      dockPanelClosed(id);
    }
  }, [id])

  return (
    <div ref={iconRef} className={`panel-icon-${id}`} style={{
      display: 'flex',
      width: "100%", height: "100%",
      // width: UIState.dockHorizontal ? undefined : UIState.dockSize,
      // height: UIState.dockHorizontal ? UIState.dockSize : undefined,
      justifyContent: 'center',
      alignItems: 'center'
    }} >


      {indicator}

      {props.size ?
        <>
          <DockChatOpenIndicator
            atTop={true}
            show={isOpen ?? false}
            tileSize={props.size} />
          <DockChatMessageIndicator
            show={unread ?? false}
            tileSize={props.size} />
        </> : null}


      <PanelButton
        id={id}
        data-tooltip-position={tipPosition}
        onShowPanel={onShowPanel}
        position={tipPosition}
        parentPosition={UIState.dockPosition}
        workArea={UIState.currentDisplay?.workArea}
        style={{
          position: undefined,
          width: UIState.dockHorizontal ? "100%" : "auto",
          height: UIState.dockHorizontal ? "auto" : "100%",
          display: 'flex',
          flexDirection: UIState.dockHorizontal ? 'row' : 'column',
          justifyContent: 'center',
        }}
        offset={offset}
        avoidParent
        {...otherProps}
      >
        {renderIcon(showClickWindow)}
      </PanelButton>


    </div>
  )
});

import React, { FC, forwardRef, useCallback, useState, useMemo, useImperativeHandle } from "react";
import jsx from "refractor/lang/jsx";
import typescript from "refractor/lang/typescript";

import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeBlockExtension,
  CodeExtension,
  HardBreakExtension,
  LinkExtension,
  ListItemExtension,
  OrderedListExtension,
  PlaceholderExtension,
  StrikeExtension,
  TrailingNodeExtension,
  MentionAtomNodeAttributes,
  TOP_50_TLDS
} from "remirror/extensions";

import {
  EditorComponent,
  Remirror,
  ThemeProvider,
  useRemirror,
  useActive,
  useCommands
} from "@remirror/react";


import { Logger } from "@openteam/app-util";
import { DSRow } from "../../DesignSystem";
import {
  FaBold,
  FaItalic,
  FaQuoteRight,
  FaCode,
  FaStrikethrough,
  FaListUl,
  FaListOl,
} from 'react-icons/fa';

import {
  CustomKeymapExtension,
  EhloEmojiExtension
} from "./MarkdownEditorExtensions"
import { DS2IconButton, DS2ToggleButton, DS2ToggleButtonGroup, styled } from "../../DesignSystem/DS2";
import MentionComponent from "./MentionComponent";
import { EhloItalicExtension, EhloMarkdownExtension, EhloMentionAtomExtension, EhloMentionAtomState } from "./RemirrorExtensions";
import { TLDList } from "./TLDList";

const logger = new Logger("MarkdownEditor")

interface IEmoji {
  name: string;
  unified: string;
  non_qualified: string;
  docomo: string;
  au: string;
  softbank: string;
  google: string;
  image: string;
  sheet_x: number;
  sheet_y: number;
  short_name: string;
  short_names: string[];
  text?: any;
  texts?: any;
  category: string;
  sort_order: number;
  added_in: string;
  has_img_apple: boolean;
  has_img_google: boolean;
  has_img_twitter: boolean;
  has_img_facebook: boolean;
}


// function convertIEmojiToFlatEmoji(emojiList: IEmoji[]) {
//   for (const e of emojiList) {
//     const fe: FlatEmoji = {

//     }
//   }
// }

export type MarkdownEditorHandle = {
  focus: () => void;
  insertText: (text) => void;
};

export interface MarkdownEditorProps {
  placeholder?: string;
  initialContent?: string;
  onChange: (value) => void
  mentionUsers?: { id: string, label: string }[]
  toolbarRight?: JSX.Element
  onSubmit?: () => void
  disableMenu?: boolean
}

/**
 * The editor which is used to create the annotation. Supports formatting.
 */
export const MarkdownEditor = forwardRef(({
  placeholder,
  initialContent,
  onChange,
  mentionUsers,
  toolbarRight,
  onSubmit,
  disableMenu=false
}: MarkdownEditorProps, ref) => {
  const menuRef = React.useRef<HTMLDivElement>(null)

  const extensions = useCallback(
    () => [
      new PlaceholderExtension({ placeholder }),
      new BlockquoteExtension(),
      new EhloEmojiExtension(),
      new LinkExtension({ 
        autoLink: true,
        autoLinkAllowedTLDs: TLDList
      }),
      new BoldExtension(),
      new StrikeExtension(),
      new EhloItalicExtension(),
      // new BulletListExtension({ enableSpine: true }),
      // new OrderedListExtension(),
      new EhloMentionAtomExtension({
        matchers: [
          { name: 'at', char: '@', appendText: '  ' },
        ],
      }),
      // new ListItemExtension({
      //   priority: ExtensionPriority.High,
      //   enableCollapsible: true
      // }),
      new CodeExtension(),
      new CodeBlockExtension({ supportedLanguages: [typescript, jsx] }),
      new TrailingNodeExtension(),
      new EhloMarkdownExtension({ copyAsMarkdown: false }),
      // /**
      //  * `HardBreakExtension` allows us to create a newline inside paragraphs.
      //  * e.g. in a list item
      //  */
      // new HardBreakExtension({
      // }),
      ...onSubmit ? [
        new CustomKeymapExtension({ onSubmit: onSubmit })
      ] : []

    ],
    [placeholder]
  );


  const { manager } = useRemirror({
    extensions,
    stringHandler: "markdown",
  });

  useImperativeHandle(ref, () => ({
    focus: menuRef.current?.focus,
    insertText: (text) => manager.store.commands.insertText(text)
  } as MarkdownEditorHandle));

  return (
      <Remirror
        key="remirror-editor"
        manager={manager}
        autoFocus
        initialContent={initialContent}
        onChange={({ helpers, state }) => {
          const newMarkdown = helpers.getMarkdown()
          const newHTML = helpers.getHTML()
          // const newMarkdown = htmlToMarkdown(newHTML)
          //logger.debug("html message to", newHTML)
          //logger.debug("setting message to", newMarkdown)
          onChange(newMarkdown)
        }} >
        <EditorComponent key="editor" />
        {!disableMenu ? <Menu ref={menuRef} rightComponent={toolbarRight} /> : null}
        <MentionComponent users={mentionUsers} />
      </Remirror>
  );
});


export const Menu = forwardRef((props: { rightComponent?: JSX.Element }, ref) => {
  const {
    toggleBold,
    toggleItalic,
    toggleStrike,
    toggleCode,
    focus,
    toggleCodeBlock,
    toggleBlockquote,
    toggleBulletList,
    toggleOrderedList
  } = useCommands();
  const active = useActive();

  const buttonFontSize = 16;

  useImperativeHandle(ref, () => ({
    focus
  }));

  const handleChange = useCallback((_, value) => {

    if (value == 'bold') {
      toggleBold()
    } else if (value == 'italic') {
      toggleItalic()
    } else if (value == 'strike') {
      toggleStrike()
    } else if (value == 'code') {
      toggleCode()
    } else if (value == 'quote') {
      toggleBlockquote()
    } else if (value == 'codeblock') {
      toggleCodeBlock({})
    }
    focus()
  }, [toggleBold, toggleItalic, toggleStrike, toggleCode, toggleCodeBlock, toggleBlockquote])

  return (
    <DSRow style={{ borderTop: "2px #eee solid", }}>
      <DSRow style={{ flex: 1, alignItems: 'center', paddingLeft: 0 }}>
        <FormatGroup size="small" color="secondary" onChange={handleChange}>
          <FormatButton
            data-tooltip="Bold [^b]"
            data-tooltip-position="bottom"
            value="bold"
            selected={active.bold()} >
            <FaBold />
          </FormatButton>
          <FormatButton
            data-tooltip="Italic [^i]"
            data-tooltip-position="bottom"
            value="italic"
            selected={active.italic()} >
            <FaItalic />
          </FormatButton>
          <FormatButton
            data-tooltip="Strikethrough [^d]"
            data-tooltip-position="bottom"
            value="strike"
            selected={active.strike()}>
            <FaStrikethrough />
          </FormatButton>
          <FormatButton
            data-tooltip="Code [^`]"
            data-tooltip-position="bottom"
            value="code"
            selected={active.code()}>
            <FaCode />
          </FormatButton>
          <FormatButton
            data-tooltip="Quote [>]"
            data-tooltip-position="bottom"
            value="quote"
            selected={active.blockquote()} >
            <FaQuoteRight />
          </FormatButton>
          <FormatButton
            data-tooltip="Codeblock [```]"
            data-tooltip-position="bottom"
            value="codeblock"
            selected={active.codeBlock()}>
            {"{ }"}
          </FormatButton>
        </FormatGroup>
      </DSRow>
      {props.rightComponent}
    </DSRow>
  );
});



const FormatGroup = styled(DS2ToggleButtonGroup)`
  padding: 2px;
`

const FormatButton = styled(DS2ToggleButton)(({theme}) => (
  {
  color: theme.palette.secondary.contrastText,
  fontSize: 14,
  fontFamily: theme.typography.fontFamily,
  padding: '4px 7px',
  border: 'none',

  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  }
}
))

export const RemirrorCSS = `
.remirror-editor-wrapper {
  width: 100%
}
.remirror-editor-wrapper .ProseMirror {
  min-height: 20px;
  max-height: 180px;
  box-shadow: none;
  padding: 8px;
  outline: none;
  overflow-y: scroll;
  background-color: white;
  color: black;
  overflow-wrap: anywhere;
}

.remirror-editor-wrapper .ProseMirror:active,
.remirror-editor-wrapper .ProseMirror:focus {
  box-shadow: none;
}

.ProseMirror p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ProseMirror a {
  color: rgba(0, 174, 249, 1);
}

.remirror-editor-wrapper .ProseMirror code {
  background-color: #F6F6F6;
  border: 1px solid #DADADA;
  border-radius: 4px;
  vertical-align: middle;
  padding: 1.6px;
  line-height: 1.5;
}

.remirror-editor-wrapper .ProseMirror pre  {
  background-color: #F6F6F6;
  border: 1px solid #DADADA;
  color: #1D1C1D;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-wrap: break-word;
  word-break: normal;
  overflow-wrap: anywhere;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  padding: 1em;
  margin: 0.5em 0px;
  border-radius: 0.3em;
  max-width: 80vw;
}

.remirror-editor-wrapper .ProseMirror pre code {
  background-color: initial;
  border: none;
  line-height: inherit;
  padding: 0;
}

.remirror-editor-wrapper .ProseMirror blockquote {
  border-left: 3px solid grey;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  font-style: italic;
}

.remirror-editor-wrapper .ProseMirror blockquote p {
  color: #888;
}

.remirror-editor-wrapper .ProseMirror .remirror-mention-atom {
  color: rgba(255, 159, 0, 1);
  text-decoration: none;
}


.remirror-floating-popover .remirror-mention-atom-popup-wrapper {
  border-radius: 4px;
  overflow: hidden;
  color: white;
  background-color: #1B1D21;
}


.remirror-floating-popover .remirror-mention-atom-popup-item {
  padding: 2px 8px;
}

.remirror-floating-popover .remirror-mention-atom-popup-highlight {
  background-color: rgba(255, 159, 0, 1);
}

.remirror-floating-popover .remirror-mention-atom-popup-hovered {
  background-color: rgba(255, 159, 0, 0.5);
}

`
export const RemirrorCompactCSS = `

.remirror-editor-wrapper {
  width: 100%
}

.remirror-editor-wrapper .ProseMirror {
  min-height: 20px;
  max-height: 180px;
  box-shadow: none;
  padding: 4px;
  outline: none;
  overflow-y: scroll;
  overflow-wrap: anywhere;
  background-color: transparent;
}

.inline .remirror-editor-wrapper .ProseMirror {
  max-height: 28px;
}

.remirror-editor-wrapper .ProseMirror:active,
.remirror-editor-wrapper .ProseMirror:focus {
  box-shadow: none;
}

.ProseMirror p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ProseMirror a {
  color: rgba(0, 174, 249, 1);
}

.remirror-editor-wrapper .ProseMirror code {
  background-color: #F6F6F6;
  border: 1px solid #DADADA;
  border-radius: 4px;
  vertical-align: middle;
  padding: 1.6px;
  line-height: 1.5;
}

.remirror-editor-wrapper .ProseMirror pre  {
  background-color: #F6F6F6;
  border: 1px solid #DADADA;
  color: #1D1C1D;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-wrap: break-word;
  word-break: normal;
  overflow-wrap: anywhere;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  padding: 1em;
  margin: 0.5em 0px;
  border-radius: 0.3em;
  max-width: 80vw;
}

.remirror-editor-wrapper .ProseMirror pre code {
  background-color: initial;
  border: none;
  line-height: inherit;
  padding: 0;
}

.remirror-editor-wrapper .ProseMirror blockquote {
  border-left: 3px solid grey;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  font-style: italic;
}

.remirror-editor-wrapper .ProseMirror blockquote p {
  color: #888;
}

.remirror-editor-wrapper .ProseMirror .remirror-mention-atom {
  color: rgba(255, 159, 0, 1);
  text-decoration: none;
}


.remirror-floating-popover .remirror-mention-atom-popup-wrapper {
  border-radius: 4px;
  overflow: hidden;
  color: white;
  background-color: #1B1D21;
}


.remirror-floating-popover .remirror-mention-atom-popup-item {
  padding: 2px 8px;
}

.remirror-floating-popover .remirror-mention-atom-popup-highlight {
  background-color: rgba(255, 159, 0, 1);
}

.remirror-floating-popover .remirror-mention-atom-popup-hovered {
  background-color: rgba(255, 159, 0, 0.5);
}

`

const Styles = {
  menuButton: {
    color: '#aaa',
    fontSize: 14
  },
  menuButtonActive: {
    color: 'black'
  },
}

import React, { CSSProperties } from "react";
import { Button, ButtonProps, Badge, styled } from "@mui/material";
import useClickable from "./useClickable";

const StyledButton = styled(Button)`
  box-shadow: none;
  app-region: no-drag;
  pointer-events: initial;
  min-height: 28px;
  white-space: nowrap;
  width: auto;

  &:hover {
    box-shadow: none;
  }

  &.Mui-focusVisible {
    box-shadow: none;
    outline: 0px solid grey;
  }
`
export interface DS2BaseButtonProps extends ButtonProps {
  analyticsEvent?: string
  analyticsArgs?: any
  badgeNum?: string | number
  badgeOverride?: string
  stopPropagation?: StopPropagation
  badgeColor?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"
  allowDuringTutorial?: boolean
  outerStyle?: CSSProperties
  noBadge?: boolean
};

type StopPropagation = {
  onClick: boolean
  onPointerDown: boolean
}

export default React.forwardRef<HTMLButtonElement, DS2BaseButtonProps>((props, ref) => {

    const {
      onClick: _onClick,
      analyticsEvent,
      analyticsArgs,
      stopPropagation: _stopPropagation,
      badgeNum,
      badgeOverride,
      badgeColor,
      noBadge = false,
      allowDuringTutorial = false,
      outerStyle,
      fullWidth,
      children,
      style,
      ...buttonProps
    } = props;


    const stopPropagation: StopPropagation = React.useMemo(() => (
      {
        onClick: true,
        onPointerDown: true,
        ..._stopPropagation
      }
    ), [_stopPropagation?.onClick, _stopPropagation?.onPointerDown]);

    const {onClick, onPointerDown} = useClickable(props)

    const enabled = !props.disabled && (props.onClick || props.onMouseDown)

    const flexStyle: CSSProperties = {}

    if (fullWidth) {
      flexStyle['flexGrow'] = 1
    }

    return noBadge ? (
      <StyledButton
        ref={ref}
        onClick={enabled ? onClick : undefined}
        tabIndex={-1}
        size="small"
        onPointerDown={onPointerDown}
        fullWidth={fullWidth}
        style={{...flexStyle, ...style}}
        {...buttonProps}
      >
        {children}
      </StyledButton>
    ) : (
      <Badge
        badgeContent={badgeOverride ?? badgeNum}
        color={badgeColor ?? 'error'}
        style={{ ...flexStyle, ...outerStyle }}
      >
        <StyledButton
          ref={ref}
          onClick={enabled ? onClick : undefined}
          tabIndex={-1}
          size="small"
          onPointerDown={onPointerDown}
          fullWidth={fullWidth}
          style={{...flexStyle, ...style}}
          {...buttonProps}
        >
          {children}
        </StyledButton>
      </Badge>
    )
  })

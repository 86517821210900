import React, { Component, useRef, useState } from "react";
import { firebaseConfig, IMAGECDN, IMAGEFILECDN, TEAM_STORAGE_BUCKET_PATH } from "../config";
import { DSTheme } from "./DSTheme";
import { DSPanel, } from ".";
import { relative } from "path";
import { FaPlay } from "react-icons/fa";


interface IDSVideoProps extends React.HTMLAttributes<HTMLVideoElement> {
  url: string
  width?: number | string
  height?: number | string

}

export function DSVideo(props: IDSVideoProps) {
  const { style, width, height, ...otherProps } = props

  const ref = useRef<HTMLVideoElement>(null);
  const [paused, setPaused] = useState<boolean>(true);

  let url = props.url

  const onClick = (e) => {
    e.stopPropagation();

    if (ref.current) {
      if (ref.current.paused) {
        ref.current.play()
        setPaused(false)
      } else {
        ref.current.pause()
        setPaused(true)
      }
    }
  }

  return (
    url ?
      <DSPanel
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          overflow: 'hidden',
          position: "relative"
        }}>
        <video
          ref={ref}
          onClick={onClick}
          {...otherProps}
          src={url || undefined}
          style={{
            display: "flex",
            alignSelf: 'center',
            width: "fit-content",
            height: "fit-content",
            maxWidth: "100%",
            maxHeight: "100%",
            overflow: 'hidden',
            objectFit: 'contain',
            ...paused ? { filter: 'brightness(40%)' } : {},
            ...style
          }}
        />
        <DSPanel
          style={{
            pointerEvents: 'none',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          {
            paused ?
              <FaPlay size={32} />
              :
              undefined
          }
        </DSPanel>
      </DSPanel>
      :
      <div
        style={{
          backgroundColor: DSTheme.DummyColor,
          width: width,
          height: height,
          ...style

        }}
      />

  );
}

import React, { useState, useMemo } from "react";
import { MentionAtomNodeAttributes } from "remirror/extensions";
import { DSRow } from "../../DesignSystem";
import { DSLargePrint } from "../../DesignSystem/DSText";
import UserIcon from "../User/UserIcon";
import { EhloMentionAtomState, EhloMentionAtomPopupComponent} from "./RemirrorExtensions";

interface MentionComponentProps<
  UserData extends MentionAtomNodeAttributes = MentionAtomNodeAttributes,
  > {
  users?: UserData[];
  tags?: string[];
}

function MentionComponent ({ users, tags }: MentionComponentProps) {
  const [mentionState, setMentionState] = useState<EhloMentionAtomState | null>();
  const tagItems = useMemo(
    () => (tags ?? []).map((tag) => ({ id: tag, label: `#${tag}` })),
    [tags],
  );
  const items = useMemo(() => {
    if (!mentionState) {
      return [];
    }

    const allItems = mentionState.name === 'at' ? users : tagItems;

    if (!allItems) {
      return [];
    }

    const query = mentionState.query.full?.toLowerCase() ?? '';
    return allItems.filter((item) => item.label?.toLowerCase().includes(query)).sort();
  }, [mentionState, users, tagItems]);

  return <EhloMentionAtomPopupComponent onChange={setMentionState} items={items} ItemComponent={MentionItem} />;
}

export default MentionComponent;


function MentionItem<Data extends MentionAtomNodeAttributes = MentionAtomNodeAttributes> (
  props,
) {
  const item = props.item
  return <DSRow style={{ alignItems: 'center' }}>
    <UserIcon
      size={24}
      hideStatusDot={true}
      user={{ id: item.id, name: item.label, imageUrl: item.imageUrl }} />
    <DSLargePrint style={{ marginLeft: 8 }}>
      {props.item.label}
    </DSLargePrint>
  </DSRow>;
}

import { Logger } from '@openteam/app-util';

const logger = new Logger('calcWindowPosition');

export type PositionType = 'top' | 'bottom' | 'left' | 'right';
export type AlignType = 'cursor' | 'left' | 'right' | 'centre' | 'top' | 'bottom' | null;

type Props = {
  windowSize: { width: number; height: number };
  parentBounds: { x: number; y: number; width: number; height: number };
  scrollPos?: { x: number; y: number };
  triggerPosition: DOMRect;
  workArea: { x: number; y: number; width: number; height: number };
  position: 'top' | 'bottom' | 'left' | 'right';
  offset: number;
  align?: AlignType;
  cursorPos?: { x: number; y: number };
  avoidParent?: boolean;
};

function calcWindowPosition({
  windowSize,
  parentBounds,
  scrollPos,
  triggerPosition,
  workArea,
  position,
  offset,
  align,
  cursorPos,
  avoidParent = false
}: Props) {
  let { left, right, top, bottom, width, height } = triggerPosition;

  // let triggerPosition = triggerEl.getBoundingClientRect();
  // let scrollY = window.scrollY !== undefined ? window.scrollY : window.pageYOffset;
  // let scrollX = window.scrollX !== undefined ? window.scrollX : window.pageXOffset;
  // let top = scrollY + triggerPosition.top;
  // //top = scrollY + triggerEl.clientTop
  // let bottom = scrollY + triggerPosition.bottom;
  // //bottom = scrollY + triggerEl.clientTop + triggerEl.clientHeight;
  // let left = scrollX + triggerPosition.left;
  // let right = scrollX + triggerPosition.right;

  if (scrollPos && !avoidParent) {
    top += scrollPos.y;
    bottom += scrollPos.y;
    left += scrollPos.x;
    right += scrollPos.x;
  }

  let _pos = position;
  const availablePositions = new Set(['top', 'left', 'bottom', 'right']);

  const { width: winWidth, height: winHeight } = windowSize;

  const { yTop, yBottom, xMaxLeft, xMaxRight, xMidLeft, xMidRight } = avoidParent
    ? {
        yTop: parentBounds.y - (winHeight + offset),
        yBottom: parentBounds.y + parentBounds.height + winHeight + offset,
        xMaxLeft: parentBounds.x - (winWidth + offset),
        xMaxRight: parentBounds.x + parentBounds.width + winWidth + offset,
        xMidLeft: parentBounds.x + left + width / 2 - winWidth / 2,
        xMidRight: parentBounds.x + left + width / 2 + winWidth / 2
      }
    : {
        yTop: parentBounds.y + top - (winHeight + offset),
        yBottom: parentBounds.y + bottom + winHeight + offset,
        xMaxLeft: parentBounds.x + left - (winWidth + offset),
        xMaxRight: parentBounds.x + right + winWidth + offset,
        xMidLeft: parentBounds.x + left + width / 2 - winWidth / 2,
        xMidRight: parentBounds.x + left + width / 2 + winWidth / 2
      };

  //const yTop = parentBounds.y + top - (winHeight + offset);
  //const yBottom = parentBounds.y + bottom + winHeight + offset;
  //const xMaxLeft = parentBounds.x + left - (winWidth + offset);
  //const xMaxRight = parentBounds.x + right + winWidth + offset;
  //const xMidLeft = parentBounds.x + left + width / 2 - winWidth / 2;
  //const xMidRight = parentBounds.x + left + width / 2 + winWidth / 2;

  if (yTop < workArea.y) {
    availablePositions.delete('top');
  }
  if (yBottom > workArea.y + workArea.height) {
    availablePositions.delete('bottom');
  }
  if (xMidLeft < workArea.x || xMidRight > workArea.x + workArea.width) {
    availablePositions.delete('top');
    availablePositions.delete('bottom');
  }
  if (xMaxLeft < workArea.x) {
    availablePositions.delete('left');
  }
  if (xMaxRight > workArea.x + workArea.width) {
    availablePositions.delete('right');
  }

  //logger.debug(`want ${_pos}, available ${Array.from(availablePositions.values())}`)

  if (!availablePositions.size) {
    logger.warn(`Unable to find a position for a ${winWidth}x${winHeight} window in workarea`, workArea);
    return;
  }

  while (!availablePositions.has(_pos)) {
    if (_pos === 'top') {
      _pos = 'bottom';
    } else if (_pos === 'bottom' && availablePositions.has('top')) {
      _pos = 'top';
    } else if (_pos === 'bottom') {
      _pos = 'right';
    } else if (_pos === 'right') {
      _pos = 'left';
    } else if (_pos === 'left' && availablePositions.has('right')) {
      _pos = 'right';
    } else if (_pos === 'left') {
      _pos = 'top';
    }
  }

  let [x, y] = [0, 0];
  if (_pos === 'top' || _pos === 'bottom') {
    y = _pos === 'top' ? yTop : yBottom - winHeight;

    if (align == 'cursor' && cursorPos) {
      x = parentBounds.x + cursorPos.x - winWidth / 2;
    } else if (align === 'left') {
      x = parentBounds.x + left;
    } else if (align === 'right') {
      x + parentBounds.x + left + width + winWidth;
    } else {
      x = xMidLeft;
    }
  } else {
    x = _pos === 'left' ? xMaxLeft : xMaxRight - winWidth

    if (align == 'cursor' && cursorPos) {
      y = parentBounds.y + cursorPos.y - winHeight / 2;
    } else if (align === 'top') {
      y = parentBounds.y + top;
    } else if (align == 'bottom') {
      y = parentBounds.y + top + height - winHeight;
    } else {
      y = parentBounds.y + top + height / 2 - winHeight / 2;
    }
  }

  const bounds = {
    x,
    y,
    width: winWidth,
    height: winHeight
  };

  return {
    bounds,
    position: _pos
  };
}

export default calcWindowPosition;

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DataState } from '../../Data/DataState';
import { focusScreenShareWindow } from './../../Data/UIState';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { DSButton } from '../../DesignSystem/DSButton';
import CallVideo from './CallVideo';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { HoverShade } from './CallTileUtil';
import { DS2CloseIconButton } from '../../DesignSystem';

interface ScreenShareTileProps {
  userId: string;
  size: number;
  circular?: boolean;
}

const ScreenShareTile: React.FC<ScreenShareTileProps> = ({
  userId,
  size,
  circular = false
}) => {
  const call = DataState.activeCall!;
  const callUser = call!.participants[userId];
  const stream = callUser?.streams['screen'];
  const [hovered, setHovered] = useState(false);
  const screenShareOpen = call.screenShares[userId] === true;
  const isMe = call.myUserId == userId;

  return stream ? (
    <div style={{ position: 'relative' }}>
      <DSButton
        data-tooltip={`${screenShareOpen ? 'Focus' : 'Open'} screenshare`}
        onClick={() => focusScreenShareWindow(userId)}
        analyticsEvent={screenShareOpen ? 'CallScreenshareFocus' : 'CallScreenshareOpen'}
        //style={{ filter: 'brightness(100%)' }}
        onHovered={setHovered}
        style={{
          filter: 'brightness(100%)',
          position: 'relative',
          aspectRatio: '1/1',
          width: size,
          height: size,
          borderRadius: circular ? 999 : DSTheme.BaseBorderRadius,
          overflow: 'hidden',
          backgroundColor: 'black'
        }}
      >
        <CallVideo
          key={`${userId}-screenshare`}
          track={stream.videoTrack}
          hasVideo={stream.hasVideo}
        />
        <HoverShade tileHovered={hovered}>
          <FaExternalLinkAlt size={Math.min(size / 2, 30)} color="white" />
        </HoverShade>
      </DSButton>
      {isMe ? (
        <DS2CloseIconButton
          analyticsEvent="stopScreenshare"
          color="error"
          outerStyle={{ position: 'absolute', right: -5, top: -5 }}
          onClick={() => call.actions.shareScreen()}
        />
      ) : null}
    </div>
  ) : null;
};

export default observer(ScreenShareTile);

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { DSColumn, DSRow } from "../../DesignSystem";
import { DSH1, DSH3, DSStrongPrint } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { Logger } from "@openteam/app-util";
import { DSButton } from "../../DesignSystem/DSButton";
import { APP_LOGIN_URL } from "../../config";
import { DSInput } from "../../DesignSystem/DSInput";
import { signInWithCustomToken } from "../../Controllers/Login";
import { OnboardingHeader } from "./OnboardingHeader";
import * as Analytics from '../../Util/Analytics'
import { DS2Button } from "../../DesignSystem/DS2";

const logger = new Logger("CreateSpaceContent")



export const CreateSpaceContent: React.FC = observer((props: React.PropsWithChildren<LoginContentProps>) => {
  logger.info("showing CreateSpaceContent")

  useEffect(() => {
    Analytics.logEvent("Screen_CreateSpaceContent")
  }, [])


  const openLoginChrome = () => {
    window.Main.shellOpenExternal(APP_LOGIN_URL)
  }
  return (

    <OnboardingHeader>

      <DSColumn style={{ alignItems: 'center' }}>

        <DSH1 style={{ fontSize: 30 }}>
          Create a workspace
        </DSH1>
        <DSH3 wrap style={{ textAlign: "center", width: 250, marginTop: 70, marginBottom: 70 }}>
          This will open in your browser
        </DSH3>
        <DS2Button onClick={openLoginChrome} > Create </DS2Button>
      </DSColumn>
    </OnboardingHeader>
  );
});

import { Logger } from "@openteam/app-util";
import { KSpaceId, KSpaceChannelId, IChatMessage } from "@openteam/models";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { DragEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { FaUpload } from "react-icons/fa";
import { DataState } from "../../../Data/DataState";
import { DS2IconButton, DS2Icons, lightTheme } from "../../../DesignSystem";
import { DSColumn, DSRow, DSWrappedRow } from "../../../DesignSystem";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { ChatFileUpload } from "../../Chat/ChatFileUpload";
import { Droppable } from "../../DragAndDrop";
import DockPanelChatCompose from "./DockPanelChatCompose";
import LastMessage from "./LastMessage";

const logger = new Logger('DockPanelChat');

type Props = {
  spaceId: KSpaceId;
  channelId: KSpaceChannelId;
  openChat: () => void;
  lastMessage?: IChatMessage
}

const acceptedImageFiles = ['image/gif', 'image/png', 'image/jpeg', 'image/bmp']

const DockPanelChat: React.FC<Props> = (props) => {
  const [sending, setSending] = useState(false)
  const [lastMessage, setLastMessage] = useState<IChatMessage>()
  const [isOver, setIsOver] = useState<boolean>(false);

  const space = DataState.spaces[props.spaceId];
  const chat = space.channels[props.channelId]


  const onPaste = useCallback((
    pasteEvent: React.ClipboardEvent<HTMLDivElement>
  ) => {
    for (const item of pasteEvent.clipboardData.files) {
      if (acceptedImageFiles.includes(item.type)) {
        chat?.actions.addFile(item)
      }
    }
    return false;
  }, [chat?.actions.addFile]);

  const onDrop: DragEventHandler = useCallback((e) => {
    setIsOver(false);

    if (e.dataTransfer.files) {
      for (const file of e.dataTransfer.files) {
        if (file.type) {
          chat?.actions.addFile(file)
        }
      }
    }

  }, [chat?.actions.addFile])

  const sendMessage = useCallback(async () => {
    if (chat) {

      logger.debug("sendMessage got _chat.draftMessage", chat.draftMessage, "_chat.draftFiles", chat.draftFiles)

      if (chat.draftMessage || chat.draftFiles.length > 0) {
        setLastMessage(await chat.actions.sendMessage(chat.draftMessage, chat.draftFiles, chat.draftReply));
        chat.actions.resetDraft()
        setSending(true)
      }
    }

  }, [chat])

  useEffect(() => {
    if (props.lastMessage) {
      setLastMessage(props.lastMessage);
    }
  }, [props.lastMessage])

  useEffect(() => {
    if (chat?.numUnread === 1) {
      chat.actions.markChatRead(chat.messageNum)
    }
  }, [chat?.numUnread])

  useEffect(() => {
    setSending(false)
    chat && setLastMessage(old => {
      if ((chat.lastMessage?.messageNum ?? -1) >= (old?.messageNum ?? 0)) {
        // fix old chat.lastMessage.reprocessMessageId messages on the fly
        return { ...chat.lastMessage, reprocessMessageId: undefined } as IChatMessage
      }
      return old
    })
  }, [chat?.lastMessage])

  const canSend = chat && (chat.draftMessage || chat.draftFiles.length > 0);


  // logger.debug(`chat is:`, toJS(chat))

  return chat ? (
    <DSColumn spacing={8}>
      <Droppable
        acceptItemType={"Files"}
        onDrop={onDrop}
        onDragOver={(e) => setIsOver(true)}
        onDragLeave={(e) => setIsOver(false)}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          position: 'relative'
        }}
      >
        {isOver &&
          <div
            style={{
              position: "absolute",
              top: 0, left: 0, bottom: 0, right: 0,
              backgroundColor: 'rgba(255,255,255,0.7)',
              zIndex: 9,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaUpload size={40} style={{ color: 'red', pointerEvents: 'none' }} />
          </div>
        }
        <LastMessage spaceId={props.spaceId} message={lastMessage} openChat={props.openChat} />
      </Droppable>
      <DSColumn
        style={{ width: '100%', paddingLeft: 2, backgroundColor: DSTheme.InputColor, borderRadius: 4 }}
      >
        <DSWrappedRow>
          {chat.draftFiles.map((uf, index) => (
            <ChatFileUpload
              key={`${index}-${uf.file.name}`}
              size={36}
              file={uf}
              onRemove={() => chat.actions.removeFile(index)}
            />
          ))}
        </DSWrappedRow>
        <DSRow
          onPaste={onPaste}
        >
          <DockPanelChatCompose
            onChange={chat.actions.setMessage}
            onSubmit={sendMessage}
            initialContent={chat.draftMessage}
            placeholder="Send quick message"
          />
          <DS2IconButton disabled={!canSend} color='primary' onClick={sendMessage}>
            {DS2Icons.send}
          </DS2IconButton>
        </DSRow>
      </DSColumn>
    </DSColumn>
  ) : null;

};


export default observer(DockPanelChat);

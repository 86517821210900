import { OTGlobals, OTUserInterface } from "@openteam/app-core";
import { observer } from "mobx-react";
import { IUIWebcamStream } from "@openteam/models";
import React from "react";
import { FaChevronDown, FaVideo, FaVideoSlash } from "react-icons/fa";
import { DataState } from "../../../Data/DataState";
import { DSPillButton } from "../../../DesignSystem/DSButton";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { MenuTemplate } from "../../Menu";
import { ButtonProps, getDeviceOptions, openCallMenu } from "./callButtonUtil";
import { isMacOs } from "react-device-detect";
import { openSystemPreferences } from "../../../Util/OpenSystemPreferences";
import { DS2Button, DS2ButtonGroup } from "../../../DesignSystem/DS2";

interface CamButtonProps extends ButtonProps {
  vertical?: boolean;
}

export const CamButton: React.FC<CamButtonProps> = observer(
  ({ style, size, color, ...otherProps }) => {
    const call = DataState.activeCall;
    const me = call?.participants[call.myUserId];
    const myStream = me?.streams["camera"] as IUIWebcamStream;
    const hasVideo = myStream?.hasVideo === true;

    const toggleVideo = async () => {
      if (!hasVideo && isMacOs) {
        if (await window.Main.getMediaAccessStatus('camera') !== 'granted') {
          if (await OTUserInterface.showConfirm({
            parentId: call!.windowId,
            title: 'Open System Preferences?',
            message: [
              'Ehlo requires Camera permission to enable video in a call.',
              'Do you want to open System Preferences to grant ths permission'
            ]
          })) {
            await openSystemPreferences("security", "Privacy_Camera");
          }
          return;
        }
      }
      call?.actions.toggleVideo();
    }
    const _openMenu = React.useCallback(() => {
      const template = [
        { enabled: false, label: `Select a Camera` },
        ...getDeviceOptions("videoinput"),
        { type: "separator" },
        {
          type: "checkbox",
          label: "Enable face tracking",
          checked: call?.faceTrackingEnabled,
          click: call?.actions.toggleFaceTracking,
        },
      ];

      if (DataState.deviceSettings["cameraQuality"]) {
        const { activeDeviceId: quality, availableDevices: sizes } =
          DataState.deviceSettings["cameraQuality"];

        template.push({ type: "separator" });

        Object.keys(sizes).forEach((size) => {
          template.push({
            type: "radio",
            label: sizes[size].label,
            checked: size === quality,
            click: () => call?.actions.updateDeviceSettings("cameraQuality", size),
          } as Electron.MenuItemConstructorOptions);
        });
      }

      openCallMenu(template as MenuTemplate);
    }, [call?.faceTrackingEnabled, call?.actions, DataState.deviceSettings, OTGlobals]);

    if (call && call.participants[call.myUserId]) {

      return (
        <DS2ButtonGroup color="secondary">
          <DS2Button
            noBadge
            onClick={toggleVideo}
            style={{paddingLeft: 8, paddingRight: 8}}
            analyticsEvent={hasVideo ? "CallCameraOff" : "CallCameraOn"}
            data-tooltip={hasVideo ? 'Disable camera' : 'Enable camera'}
          >
            {hasVideo ? (
              <FaVideo color={DSTheme.SuccessColor} size={16} />
            ) : (
              <FaVideoSlash color={DSTheme.CancelColor} size={16}/>
            )
            }
          </DS2Button>
          <DS2Button
            noBadge
            style={{padding: 6}}
            onClick={_openMenu}
            data-tooltip="Camera settings"
            analyticsEvent="CallCameraSettings"
          >
            <FaChevronDown size={DSTheme.IconSize} />
          </DS2Button>

        </DS2ButtonGroup>
      )
    } else {
      return null;
    }
  }
);

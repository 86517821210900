import { OTGlobals} from '@openteam/app-core';
import { Logger } from '@openteam/app-util';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite'
import React from 'react'
import { isMacOs } from 'react-device-detect';
import { ToolTipContainer } from '../../Controllers/ToolTips';
import { UIState } from '../../Data/UIState';
import { SubWindowHandle, SubWindow, PopupOptions, SubWindowCSS } from '../SubWindow';
import OnboardingService from '../../Controllers/OnboardingService';
import OnboardingContent from './OnboardingContent';

const logger = new Logger('OnboardingWindow')


const ASSETS: Record<string, string> = {
  invite: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fonboarding%2Finviteteammates.png?alt=media&token=3d481a4f-d9ea-46c5-ac8d-01590434f1a0",
  gcal: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fonboarding%2Fgooglecalendar.png?alt=media&token=c4c0d6df-52f1-4e4a-9bf1-ffebdf5bec35",
  zoom: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fonboarding%2Fzoom.png?alt=media&token=03dbbb45-c1ae-457d-af02-a4884103427d",
  schedule: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fonboarding%2FSchedule.png?alt=media&token=0960da32-7be4-4b1d-be95-16a3814defe3",
}

const OnboardingWindow: React.FC = ({ }) => {
  const id = "Onboarding"
  const title = `${UIState.productName} Setup`;
  const subWindowRef = React.useRef<SubWindowHandle>(null);
  const width = 690;
  const height = 460;

  const onClose = action(() => {
    if (OnboardingService.numSteps === OnboardingService.numDone) {
      OTGlobals.userSettingsManager.updateRemoteSettings({ onboardingHidden: true });
    }
    UIState.showOnboarding = false
  })

  return UIState.showOnboarding ? (
    <SubWindow
      ref={subWindowRef}
      id={id}
      height={height}
      width={width}
      minHeight={height}
      minWidth={width}
      maxHeight={height}
      maxWidth={width}
      focus={true}
      show={true}
      title={title}
      onClose={onClose}
      options={{
        ...PopupOptions,
        resizable: true,
        alwaysOnTop: false,
        skipTaskbar: false,
        titleBarStyle: isMacOs ? 'hidden' : 'default',
        acceptFirstMouse: true,
        trafficLightPosition: {
          x: 6,
          y: 6
        },
      }}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <ToolTipContainer parentId={id} />
      <OnboardingContent windowId={id} />
    </SubWindow >
  ) : null
}

export default observer(OnboardingWindow)

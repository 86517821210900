import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import RoomWindow from './RoomWindow';
import RoomCallContainer from './RoomCallContainer';
import RoomChatContainer from './RoomChatContainer';
import { DSColumn, DSRow } from '../../DesignSystem';
import { DataState } from '../../Data/DataState';
import { IUActiveCall, IUISpaceChannel, IUISpacePod, KSpaceChannelId, KSpaceId } from '@openteam/models';
import { Logger } from '@openteam/app-util';
import { isMacOs } from 'react-device-detect';
import { Draggable } from '../Draggable';
import RoomHeader from './RoomHeader';
import { OTUITree, toggleCallPoppedOut } from '@openteam/app-core';
import { closePod, closeUserChat } from '../../Data/UIState';
import { action, makeAutoObservable } from 'mobx';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { ToolTipContainer } from '../../Controllers/ToolTips';

const logger = new Logger('Room');

type Props = {
  spaceId: KSpaceId;
  channelId: KSpaceChannelId;
};

const Room: React.FC<Props> = (props) => {
  const { spaceId, channelId } = props;

  const [windowFocused, setWindowFocused] = useState<boolean>(false);

  let userId: string | undefined = undefined

  const id = `chat-${spaceId}-${channelId}`;

  const space = DataState.spaces[props.spaceId];
  const channel: IUISpaceChannel | undefined = space.channels[channelId];
  const pod: IUISpacePod | undefined = space.pods[channelId];

  let callId: string | undefined = undefined
  let activeCall: IUActiveCall | undefined = undefined

  if (channelId == "adhoc-call") {
    if (DataState.activeCall?.spaceId === spaceId && !DataState.activeCall?.channelId) {
      callId = DataState.activeCall.id
      activeCall = DataState.activeCall
    }
  } else {
    callId = Object.keys(space.calls).find(
      _callId => space.calls[_callId].channelId == props.channelId)

    activeCall = DataState.activeCall?.spaceId === spaceId && DataState.activeCall?.channelId === channelId ? DataState.activeCall : undefined
  }

  // const call = DataState.activeCall;

  // const inCall = call?.spaceId === spaceId && call?.channelId === channelId
  // const callId = inCall && call ? call.id : undefined


  //logger.debug(`activeCall ${activeCall}, callId ${callId}`)

  const inCall = (callId && space.calls[callId] && OTUITree.auth.userId in space.calls[callId].participants) || false;

  const poppedIn = activeCall?.poppedOut === false;
  const [chatOpen, setChatOpen] = useState(!poppedIn);
  //logger.debug(`inCall ${!!activeCall}, callId ${callId}, windowId: ${activeCall?.windowId}, poppedIn ${poppedIn}, chatOpen ${chatOpen}`)

  let closeChat: () => void;

  let title = '';

  let minHeight = 230;
  let minWidth = 0;
  let initialWidth = 800;
  let initialHeight = 640;

  if (channel?.chatType == 'chat') {

    const users = channel.userIds?.filter((userId) => userId != OTUITree.auth.userId) || [];
    if (users.length == 1) {
      userId = users[0];
      const user = space.users[userId]

      title = user.name
      closeChat = () => closeUserChat(spaceId, userId!)
      initialWidth = 620;
      initialHeight = 640;
    }


  } else if (pod) {
    title = `${pod.name}`;
    closeChat = () => closePod(spaceId, channelId)
  } else if (channelId === "adhoc-call") {
    title = `Direct Call`;
    closeChat = () => closePod(spaceId, channelId)
  } else {
    title = `Call`;
    closeChat = () => closePod(spaceId, channelId)
  }

  if (activeCall) {
    const { id: callId } = activeCall;
    const callName = DataState.spaces[spaceId].calls[callId]?.name ?? '';
    title = `${callName}`;
  }

  const onClose = action(() => {
    logger.debug(`Room onClose`)
    closeChat?.()
  });

  useEffect(() => {
    if (!activeCall) {
      setChatOpen(true)

      if (channelId === "adhoc-call") {
        logger.debug(`closeChat as adhoc-call`);
        closeChat?.()
      }

    } else {
      if (!chatOpen && !poppedIn) {
        // nothing to show so close chat
        logger.debug(`closeChat as !chatOpen && !poppedIn`);
        closeChat?.()
      }
    }
  }, [
    activeCall, chatOpen, poppedIn
  ])

  useEffect(() => {
    if (inCall && poppedIn) {
      window.Main.showWindow(id, true)
    }
  }, [inCall, poppedIn])

  if (poppedIn) {
    minWidth += 300
  }
  if (chatOpen) {
    minWidth += 500;
    minHeight = 300;
  }

  useEffect(() => {
    // If the room got deleted whilst the window is opem clean it up
    if (!channel && !callId) {
      closePod(spaceId, channelId)
    }
  }, [channel, callId, channelId, spaceId])

  return (
    <RoomWindow
      windowId={id}
      title={title}
      initialWidth={initialWidth}
      initialHeight={initialHeight}
      minWidth={minWidth}
      minHeight={minHeight}
      onClose={onClose}
      onFocus={() => {
        setWindowFocused(true);
      }}
      onBlur={() => {
        setWindowFocused(false);
      }}
    >
      <ToolTipContainer parentId={id} />
      <DSColumn style={Styles.container}>
        <Draggable windowId={id}>
          <RoomHeader spaceId={spaceId} channelId={channelId} callId={callId} inCall={inCall} userId={userId} parentId={id} />
        </Draggable>

        <DSRow className='RoomContent' style={{ flex: 1, overflow: "hidden" }}>
          <RoomChatContainer
            windowId={id}
            spaceId={spaceId}
            channelId={channelId}
            callId={activeCall?.summaryCallId || callId}
            chatOpen={chatOpen}
            windowFocused={windowFocused}
            title={title}
          />
        </DSRow>
      </DSColumn>
    </RoomWindow>
  );
};

export default observer(Room);
class StylesClass {
  constructor() {
    makeAutoObservable(this);
  }

  get container () {
    return {
      flex: 1,
      backgroundColor: DSTheme.PanelBackgroundColor,
      color: DSTheme.MainText,
      width: "100%",
      height: "100%"
    };
  }
}
const Styles = new StylesClass();

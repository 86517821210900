import { useTheme } from "@openteam/design-system";
import { OTUITree } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { KSpaceId, IChatMessage } from "@openteam/models";
import React, { useEffect, useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { DataState } from "../../../Data/DataState";
import { DS2Button } from "../../../DesignSystem";
import { DSRow, DSColumn, DSPanel } from "../../../DesignSystem";
import { DSSpinner } from "../../../DesignSystem/DSSpinner";
import { DSPrint, DSBody, DSSmallPrint } from "../../../DesignSystem/DSText";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { timeSince } from "../../../Util/DateFormat";
import setAlpha from "../../../Util/setAlpha";
import { ChatMarkdownView } from "../../Chat/ChatMarkdownView";

const logger = new Logger("LastMessage")

type Props = {
  spaceId: KSpaceId;
  message?: IChatMessage;
  openChat: () => void
}

const LastMessage: React.FC<Props> = ({ spaceId, message, openChat }) => {
  const space = DataState.spaces[spaceId];
  const user = message ? space.users[message.userId] : null;
  const isYou = message?.userId === OTUITree.auth.userId;
  const theme = useTheme()

  return message ? (
    <DSColumn
      style={{
        position: 'relative',
        backgroundColor: setAlpha(DSTheme.DockPanelBackgroundColor, 0.2),
        borderRadius: 4,
        padding: 4,
      }}>

      <DSRow spacing={4} style={{ justifyContent: 'flex-start', minHeight: 16 }}>
        <DSSmallPrint style={{ opacity: 0.5, fontWeight: 800 }}>
          Last message - {isYou ? 'You' : (user?.name ?? '')} {timeSince(message.crDate, ' ago')}
        </DSSmallPrint>

        {isYou ? (
          message.reprocessMessageId ?
            <DSSpinner size={16} />
            : (
              <div style={{ borderRadius: 999, border: `1px solid ${theme.palette.success.main}`, padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FaCheck size={10} color={theme.palette.success.main} />
              </div>
            )
        ) : null}
      </DSRow>

      <MessageBody spaceId={spaceId} message={message} openChat={openChat} />

    </DSColumn>
  ) : <DSPrint  style={{ opacity: 0.5, fontWeight: 800 }}>
    Send a quick chat message
  </DSPrint>
}

export default LastMessage;




const MessageBody: React.FC<{ spaceId: KSpaceId, message: IChatMessage, openChat: () => void }> = ({ spaceId, message, openChat }) => {
  const [isOverflow, setIsOverflow] = useState(false)
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const { clientHeight, scrollHeight } = ref.current;
      logger.debug(`clientHeight ${clientHeight}, scrollHeight: ${scrollHeight}, overflow: ${scrollHeight > clientHeight} `)
      setIsOverflow(scrollHeight > clientHeight)
    }
  })

  const msgIsEmoji = /^\p{Emoji}$/u.test(message.message);
  let body: JSX.Element;

  if (message.isSystem && message.systemType == 'CALLSUMMARY' && message.systemId) {
    body = (
      <DSBody style={{ color: DSTheme.SystemMessageColor, fontStyle: 'italic' }}>
        {message.message}
      </DSBody>
    )
  } else if (message.isSystem) {
    body = (
      <DSBody style={{ color: DSTheme.SystemMessageColor, fontStyle: 'italic' }}>
        {message.message}
      </DSBody>
    )
  } else if (message.message) {
    body = (
      <DSPanel ref={ref} style={{ maxHeight: 54, overflow: 'hidden', marginTop: msgIsEmoji ? 0 : 0 }}>
        <ChatMarkdownView spaceId={spaceId} message={message.message + (message.editDate ? "\n #edited#" : "")} />
      </DSPanel>)
  } else {
    body = (
      <DSBody style={{ color: DSTheme.SystemMessageColor, fontStyle: 'italic' }}>
        Message contains files or images
      </DSBody>)
  }
  let showShowMore = isOverflow || Object.keys(message.attachments ?? {}).length > 0
  return (
    <DSColumn style={{justifyContent: 'center', minHeight: 32}}>
      {body}
      {
        showShowMore ?
          <DSRow style={{ justifyContent: 'flex-end' }}>

            <DS2Button
              variant="text"
              color="info"
              fullWidth={false}
              noBadge
              onClick={openChat}
              style={{ width: 'fit-content' }}
            >
              ... Show more
            </DS2Button>
          </DSRow>
          : null
      }
    </DSColumn>
  )
}

// import { FaceDetection, Results as FaceDetectResults } from "@mediapipe/face_detection";
import { Logger } from "@openteam/app-util";
import EventEmitter from "events";

const logger = new Logger("FaceDetection");

const FD_VERSION = "0.4.1646425229"
const MIN_DETECTION_THRESHOLD = 0.4;
const FD_MAX_ZOOM = 2.5;
const DETECTION_INTERVAL = 1000;


const FaceDetection = (window as any).FaceDetection;


export interface IFaceDetect {
  centre: { x: number; y: number };
  zoom: number;
}

class TrackFaceDetectionClass extends EventEmitter {
  _fd;
  _detectInterval?: ReturnType<typeof setTimeout>;
  _currentFaceDetect?: IFaceDetect;
  _lastSuccess?: number;

  constructor() {
    super();

    this._fd = new FaceDetection({
      locateFile: (file, pfx) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection@${FD_VERSION}/${file}`;
      },
    });

    this._fd.setOptions({
      model: "short",
      minDetectionConfidence: MIN_DETECTION_THRESHOLD,
    });

    this._fd.onResults(this._onResults);
  }

  start = (track: MediaStreamTrack) => {
    if (!track) {
      logger.error(`Video track not provided`);
    }

    if (this._detectInterval) {
      clearInterval(this._detectInterval);
    }
    this._currentFaceDetect = undefined;
    this._fd.reset();

    //@ts-ignore WTF!?
    const imageCapture = new ImageCapture(track);

    this. _detectInterval = setInterval(async () => {
      try {
        const image = (await imageCapture.grabFrame()) as any;
        await this._fd.send({ image });
      } catch (err) {
        logger.warn(`Error processing facedetect frame: `, err);
        this.stop();
      }
    }, DETECTION_INTERVAL);
    logger.debug(`Starting face detect`)
  };

  _onResults = (results): void => {
    const now = new Date().getTime();
    if (results.detections.length) {
      const box = results.detections[0].boundingBox;

      const { xCenter, yCenter, height } = box;
      const zoom = Math.max(1 / FD_MAX_ZOOM, Math.min(1, height * 2.8));

      const faceDetect = {
        centre: {
          x: xCenter,
          y: yCenter,
        },
        zoom,
      };
      if (this._isSignificantChange(faceDetect)) {
        this.emit("detection", faceDetect);
      }
      this._lastSuccess = now;
    } else {
      logger.debug(`No detections`);
      if (now - (this._lastSuccess ?? now) > 5000) {
        logger.info(`resetting face detection area`);
        this.emit("detection", undefined);
        this._lastSuccess = undefined;
      }
    }
  };

  _isSignificantChange = (fd: IFaceDetect) => {
    const cfb = this._currentFaceDetect;

    const zoomChg = Math.abs((cfb?.zoom ?? 1) - fd.zoom) / fd.zoom;
    const xChg = Math.abs((cfb?.centre.x ?? 0.5) - fd.centre.x) / fd.zoom;
    const yChg = Math.abs((cfb?.centre.y ?? 0.5) - fd.centre.y) / fd.zoom;

    if (zoomChg > 0.15 || xChg > 0.075 || yChg > 0.075) {
      logger.debug(
        `changed zoom:${Math.round(zoomChg * 100)}%, X: ${Math.round(xChg * 100)}%, Y:${Math.round(
          yChg * 100
        )}%`,
        fd
      );
      this._currentFaceDetect = fd;
      return true;
    }
    return false;
  };


  stop = () => {
    logger.debug(`Stopping face detection`)
    this._detectInterval && clearInterval(this._detectInterval);
    this._currentFaceDetect = undefined;
    this._fd.reset();
    this.emit("close");
  };
}

export const TrackFaceDetection = FaceDetection && new TrackFaceDetectionClass();

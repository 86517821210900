import React, { useState } from 'react';
import { KSpaceId, ResizeHandler } from '@openteam/models';
import { observer } from 'mobx-react';
import { DataState } from '../../Data/DataState';
import { DSColumn, DSHSpacer, DSPanel, DSRow, DSVSpacer } from '../../DesignSystem';
import { DSH2, DSPrint } from '../../DesignSystem/DSText';
import { DSButton } from '../../DesignSystem/DSButton';
import { OTUITree } from '@openteam/app-core';
import { DSAnswerCall, DSHoldCall, DSRejectCall } from "../../DesignSystem/DSIconButtons";
import { DSTheme, ThemeState } from '../../DesignSystem/DSTheme';
import useResizeObserver from 'use-resize-observer';
import { doDeepLink } from '../../Controllers/DeepLink';
import { DSInput } from '../../DesignSystem/DSInput';
import { UIState } from '../../Data/UIState';
import UserIcon, { UserImageExternal } from '../User/UserIcon';
import { DS2AcceptButton, DS2AcceptRequestButton, DS2HoldOnButton, DS2Icons, DS2RejectRequestButton } from '../../DesignSystem/DS2';
import { FaVideo } from 'react-icons/fa';
import { DockNotifyPanel } from './DockNotifyPanel';


interface IWaitingUsersPanelProps {
  parentWindowId: string
  spaceId: KSpaceId
  closePanel: (onCloseCallback?: () => void) => void
}

export const WaitingUsersPanel = observer((props: IWaitingUsersPanelProps) => {
  const [token, setToken] = useState("");

  const space = DataState.spaces[props.spaceId]
  const externalMeetings = DataState.spaces[props.spaceId].externalMeetings

  const waitingUsers = externalMeetings.waitingUsers || {}
  const hasWaitingUsers = Object.keys(waitingUsers).length > 0
  const myUserId = OTUITree.auth.userId;

  let roomId: string | undefined = undefined
  roomId = DataState.activeCall?.id


  const acceptReq = (userId, existing) => {
    let joinRoomId: string | undefined = undefined

    if (existing) {
      // Object.keys(space.calls).forEach(callId => {
      //   if (myUserId in space.calls[callId].participants) {
      //     roomId = callId
      //   }
      // })
      joinRoomId = roomId

    }

    externalMeetings.actions?.acceptReq(userId, joinRoomId)
  }
  if (!hasWaitingUsers)
    return null;
  const renderUser = (userId: string) =>

    <DockNotifyPanel
      user={{
        id: userId,
        name: waitingUsers[userId].name,
        imageUrl: waitingUsers[userId].imageUrl
      }}
      title={`${waitingUsers[userId].name} is requesting to join meeting room`}
      rightIcon={
        <DSPanel style={{
          width: 20,
          height: 20,
          borderRadius: 4,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: DSTheme.SuccessColor,
          color: 'white'
        }} >{DS2Icons.call}</DSPanel>
      }
      buttons={
        <DSRow style={{ justifyContent: 'space-evenly' }} spacing={8}>
          {
            roomId ?
              <DS2AcceptRequestButton title={"Add to call"} fullWidth onClick={() => acceptReq(userId, true)} />
              :
              undefined
          }
          <DS2AcceptRequestButton title={"Start call"} fullWidth onClick={() => acceptReq(userId, false)} />
          {
            waitingUsers[userId].status != "H" ?
              <DS2HoldOnButton fullWidth onClick={() => externalMeetings.actions?.holdReq(userId)} />
              :
              null
          }
          <DS2RejectRequestButton fullWidth onClick={() => externalMeetings.actions?.rejectReq(userId)} />
        </DSRow>
      }


    />


  return (
    <DSPanel style={{ minWidth: 420 }}>
      {Object.keys(waitingUsers).map(userId => renderUser(userId))}

      {import.meta.env.DEV ?
        <DSPanel style={{ backgroundColor: "white" }}>
          <DSRow style={{
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: "center",
            padding: 8,
            backgroundColor: 'rgba(255,0,0,0.2)'
          }}>
            <DSInput
              value={token}
              onChange={(event) => setToken(event.target.value)}
            />

            <DSButton style={{ margin: 10 }} onClick={() => {
              doDeepLink(token);
              setToken("")
            }} title="Submit token" />
          </DSRow>
        </DSPanel>
        : null}
    </DSPanel>
  );
})


const Styles = {
  panelStyle: {
    borderRadius: 8,
    marginBottom: 16,
    padding: 16
  },
}

import { Logger } from '@openteam/app-util';
import { OTUITree, toDate } from '@openteam/app-core';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataState } from '../../Data/DataState';
import { dockAnimatingStart, dockAnimatingStop, registerHighlightUserTrigger, setDockComponentLength, UIState, unregisterHighlightUserTrigger } from '../../Data/UIState';
import { v1 as uuidv1 } from "uuid";
import { DSAnimateListGrow } from '../../DesignSystem/DSAnimate';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DockUserIcon } from './DockUserIcon';
import { DockRoomIcon, getDockRoomLength } from './DockRoomIcon';
import { weekDayShort } from '../../Util/DateFormat';
import { DSPrint, DSSmallPrint } from '../../DesignSystem/DSText';
import { fmtTime } from '../Calendar/CalendarSchedule';
import { KSpaceId } from '@openteam/models';
import { UserProfileMenu } from '../User/UserProfileMenu';
import { CirclePosition } from '../../Util/CirclePosition';
import { SpaceIcon } from '../Space/SpaceIcon';
import UserIcon from '../User/UserIcon';
import { DockIconBase } from './DockIconBase';


const logger = new Logger("DockCurrentUserIcon");

type itemType = "user" | "call" | "spacer";
interface Props {
  spaceId: string,

}

export const DockCurrentUserIcon = observer((props: Props) => {
  const itemLengthRatio = (1 - DSTheme.DockMargin * 2);
  const userSizeRatio = DSTheme.DockUserRatio;
  const roomSizeRatio = DSTheme.DockRoomRatio;

  const itemLength = Math.floor(itemLengthRatio * UIState.dockSize);
  const userSize = Math.floor(userSizeRatio * UIState.dockSize);

  const space = DataState.spaces[props.spaceId]
  const myUserId = OTUITree.auth.userId;

  const [currentCall, setCurrentCall] = useState<{ id: string, spaceId: string, podId?: string } | undefined>(undefined);
  const ref = useRef<{ trigger: () => void }>();

  const calendarManager = OTUITree.userManager.calendarManager
  const { currentEvent, nextEvent } = calendarManager;
  const event = currentEvent ?? nextEvent;


  useEffect(() => autorun(() => {

    let newCall: { id: string, spaceId: string, podId?: string } | undefined =
      DataState.activeCall && !DataState.activeCall.focusRoom &&
      {
        id: DataState.activeCall?.id,
        spaceId: DataState.activeCall?.spaceId,
        podId: DataState.activeCall?.channelId
      } || undefined

    if (!newCall) {
      Object.keys(space.calls).forEach(callId => {
        if (!space.calls[callId].focusRoom && myUserId in space.calls[callId].participants) {
          newCall = {
            id: callId,
            spaceId: props.spaceId,
            podId: space.calls[callId].channelId
          }
        }
      })
    }

    let height = newCall ?
      getDockRoomLength(newCall.spaceId, newCall.id, roomSizeRatio, itemLengthRatio)
      :
      0;

    if (!newCall || newCall.spaceId != props.spaceId) {
      height += itemLengthRatio
    }

    //if (event) {
    //  height += 0.3
    //}

    setDockComponentLength("CurrentDockUserIcon", height)

    setCurrentCall(newCall)

  }), [space.calls, setCurrentCall, itemLengthRatio, userSizeRatio, roomSizeRatio, event])


  useEffect(() => {
    const uid = uuidv1();
    const trigger = ref.current?.trigger;

    if (trigger)
      registerHighlightUserTrigger(props.spaceId, myUserId, uid, trigger);

    return () => unregisterHighlightUserTrigger(props.spaceId, myUserId, uid);

  }, [props.spaceId, myUserId])

  const items: (itemType)[] = []

  if (!currentCall) {
    items.push("user")
  } else if (currentCall) {
    if (currentCall.spaceId != props.spaceId) {
      items.push("user")
    }

    items.push("call")
  }


  const getItemSizeStyle = (item: itemType) => {
    let length = 0;
    if (item === "user") {
      length = itemLengthRatio
    } else if (item === "call" && currentCall) {
      length = getDockRoomLength(currentCall.spaceId, currentCall.id, roomSizeRatio, itemLengthRatio)
    }

    return {
      height: UIState.dockHorizontal ? UIState.dockSize : Math.floor(length * UIState.dockSize),
      width: UIState.dockHorizontal ? Math.floor(length * UIState.dockSize) : UIState.dockSize,
      opacity: 1,
      transform: "scale(1)"
    }

  }

  const renderItem = (item: itemType) => {
    if (item === "user") {
      const event = currentEvent ?? nextEvent;

      return (
        <DockMyUserIcon
            key={`currentUser-${myUserId}`}
            spaceId={props.spaceId}
            size={userSize}
        />
      )
    } else if (item === "call" && currentCall) {
      return <DockRoomIcon
        key={`currentCall-${currentCall.id}`}
        spaceId={currentCall.spaceId}
        podId={currentCall.podId}
        callId={currentCall.id}
        allowMyCallDisplay={true}
      />
    }
    return null
  }

  return (
    <>
      <DSAnimateListGrow
        label='CurrentDockUserIcon'
        horizontal={UIState.dockHorizontal}
        width={UIState.dockHorizontal ? itemLength : UIState.dockSize}
        height={UIState.dockHorizontal ? UIState.dockSize : itemLength}
        spacing={(itemLength - userSize) / 2}
        renderItem={renderItem}
        items={items}
        fromStyle={{
          height: 0,
          opacity: 0,
          transform: "scale(0)"
        }}
        leaveStyle={{
          height: 0,
          opacity: 0,
          transform: "scale(0)"

        }}
        initialStyle={getItemSizeStyle}
        enterStyle={getItemSizeStyle}
        outerStyle={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onListStart={() => {
          dockAnimatingStart('dock-current-user')
        }}
        onListRest={() => {
          dockAnimatingStop('dock-current-user')
        }}
      />

    </>
  )
})
interface IDockMyUserIconProps {
  spaceId: KSpaceId
  size: number
}


export const DockMyUserIcon = observer((props: IDockMyUserIconProps) => {

  const space = DataState.spaces[props.spaceId]
  const user = space.users[OTUITree.auth.userId]
  const calendarManager = OTUITree.userManager.calendarManager
  const { currentEvent, nextEvent } = calendarManager;
  const event = currentEvent ?? nextEvent;

  return (
    <DockIconBase
      id={`user-${user.id}`}
      data-tooltip={'Update status and view schedule'}
      size={props.size}

      renderIcon={() => (
        <>
          <UserIcon
            key={user.id}
            user={user}
            dotSize={Math.max(7, Math.round(props.size / 8))}
            showCustomEmoji={true}
          >
              <CirclePosition positionDegrees={225} offsetFactor={0.94} >
                <SpaceIcon spaceId={props.spaceId} tileSize={props.size / 5 + 8} />
              </CirclePosition>
            </UserIcon>
        </>

      )}

      renderContents={
        (
          closeWindow,
          windowId,
          toggleWindow,
          setDisableOnBlur
        ) => (
              <UserProfileMenu
                windowId={windowId}
                spaceId={OTUITree.userManager.currentTeamId!}
                setDisableOnBlur={setDisableOnBlur}
                onClose={closeWindow}
              />
            )
        }
    />
  )

})

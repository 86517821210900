import React, { useState } from 'react';

import { IUIUserMinimal, KSpaceId } from '@openteam/models';
import { observer } from 'mobx-react';
import UserIcon from '../Components/User/UserIcon';
import { Logger } from '@openteam/app-util';
import { DSButton } from '../DesignSystem/DSButton'
import { DSSmallPrint } from '../DesignSystem/DSText';
const logger = new Logger("UserIconListPaged");
interface IUserIconListPagedProps {
    spaceId: KSpaceId;
    users: IUIUserMinimal[];
    numPerRow: number;
    numRows: number;
    tileSize?: number;
    showName?: boolean;
    onClick?: (userId: string) => void;
    horizontalSpacing?: number;
    verticalSpacing?: number;
    renderUser?: (user: IUIUserMinimal) => JSX.Element
}

const ICON_HORIZ_SPACING = 8;
const ICON_VERT_SPACING = 4;

export const userIconListPagedWidth = (numUsers: number, maxIcons: number, tileSize: number = 60, scaling: number = 1) => {
    const numIcons = Math.min(numUsers, maxIcons)
    const width = numIcons * (tileSize * scaling + (2 * ICON_HORIZ_SPACING));
    //logger.debug(`userIconListPagedWidth: maxIcons=${maxIcons} numUsers=${numUsers} tilesize=${tileSize} scaling=${scaling} width=${width}`);
    return width;
}

const paginationHeight = 18;
const labelHeight = 15;
export const userIconListPagedHeight = (numUsers: number, numPerRow: number, numRows: number, tileSize: number = 60, scaling: number = 1) => {

    const numPerPage = numPerRow * numRows;
    const numPages = Math.ceil(numUsers / numPerPage);
    const hasPager = numPages > 1;

    const nRows = Math.min(Math.ceil(numUsers / numPerRow), numRows);
    const height = nRows * (tileSize * scaling + (2 * ICON_VERT_SPACING)) + (hasPager ? 14.5 + 20 : 0);

    const totalHeight = labelHeight + height + paginationHeight;
    //logger.debug(`userIconListPagedHeight: numUsers=${numUsers} numPerRow=${numPerRow} nRows=${nRows} tilesize=${tileSize} scaling=${scaling} height=${height} totalHeight=${totalHeight}`);

    return totalHeight;
}

const Paginate: React.FC<{
    pageNum: number,
    numPages: number,
    onClickPage: (page: number) => void,
}> = ({ pageNum, numPages, onClickPage }) => {
    const size = 8;
    return (
        <div style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: 4,
        }}>
            {[...Array(numPages).keys()].map(page => (
                <DSButton key={`pager-${page}`} onClick={() => onClickPage(page)} /* disabled={pageNum === 0} */><div
                    style={{
                        backgroundColor: pageNum === page ? "rgba(196,196,196,255)" : "rgba(196,196,196,0)",
                        borderColor: "rgba(196,196,196,255)",
                        borderWidth: 1,
                        borderStyle: "solid",
                        height: size,
                        width: size,
                        borderRadius: "50%",
                        margin: "0 4",
                    }}
                /></DSButton>
            ))}
        </div>
    )
}



export const UserIconListPaged = observer((props: IUserIconListPagedProps) => {
    const {
        spaceId,
        users,
        numPerRow,
        numRows,
        tileSize = 24,
        showName,
        onClick,
        horizontalSpacing: _horizontalSpacing,
        verticalSpacing: _verticalSpacing,
        ...otherProps
    } = props

    const horizontalSpacing = _horizontalSpacing ?? tileSize / 4;
    const verticalSpacing = _verticalSpacing ?? tileSize / 8;

    const [pageNum, setPageNum] = useState<number>(0);

    const numPerPage = props.numPerRow * props.numRows;
    const numPages = Math.ceil(props.users.length / numPerPage);

    const pageUp = () => setPageNum(Math.min(pageNum, numPages - 1));
    const pageDown = () => setPageNum(Math.max(pageNum, 0));
    const onPage = (pageNum: number) => setPageNum(Math.min(Math.max(pageNum, 0), numPages - 1));
    const canPageUp = () => pageNum < (numPages - 1);
    const canPageDown = () => pageNum > 0;

    const pagedUsers = users.slice(pageNum * numPerPage, pageNum + 1 * numPerPage);

    const byRow = pagedUsers.reduce<Array<IUIUserMinimal[]>>((acc, val, i) => {
        const idx = Math.floor(i / props.numPerRow)

        if (acc[idx])
            acc[idx].push(val);
        else
            acc.push([val]);

        return acc
    }, [])


    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    {byRow.map((rowUsers, index) => (
                        <div key={index} style={{ display: "flex", flexDirection: "row", }}>
                            {
                                rowUsers.map((user, idx) => (
                                    props.renderUser
                                        ? props.renderUser(user)
                                        : <DSButton
                                            key={`userlist-${props.spaceId}-${user.id}`}
                                            onClick={() => props.onClick?.(user.id)}
                                            style={{ flexDirection: "column", width: tileSize + horizontalSpacing * 2 }}
                                        >
                                            <UserIcon
                                                key={user.id}
                                                size={props.tileSize}
                                                user={user}
                                                showTooltip={!props.showName}
                                                outerStyle={{
                                                    marginRight: horizontalSpacing,
                                                    marginLeft: horizontalSpacing,
                                                    marginTop: verticalSpacing,
                                                    marginBottom: verticalSpacing
                                                }}
                                                data-tooltip={otherProps['data-tooltip-for'] ? `${spaceId}:${user.id}` : undefined}
                                                {...otherProps}
                                            />
                                            {
                                                props.showName &&
                                                <DSSmallPrint
                                                    data-tooltip={user.name}
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        textAlign: "center",
                                                        width: tileSize,
                                                    }}
                                                >
                                                    {user.name}
                                                </DSSmallPrint>
                                            }
                                        </DSButton>
                                ))}
                        </div>
                    ))}
                </div>
            </div>
            {numPages > 1 && (
                <div style={{ marginTop: "auto" }}>
                    <Paginate pageNum={pageNum} numPages={numPages} onClickPage={onPage} />
                </div>
            )}
        </div>
    );
});

export default UserIconListPaged

import { OTUITree } from "@openteam/app-core";
import { observer } from "mobx-react-lite"
import React, { useState } from "react";
import { useEffect } from "react"
import { setDockComponentLength, UIState } from "../../Data/UIState";
import { styled } from "../../DesignSystem";
import { format } from 'date-fns'
import { Logger } from "@openteam/app-util";
import { autorun, runInAction } from "mobx";
import { showNotification } from "../../Controllers/Notifications";

const logger = new Logger('DockNextEvent')

const DockNextEvent: React.FC = () => {

  const calendarManager = OTUITree.userManager.calendarManager
  const { currentEvent, nextEvent } = calendarManager;
  const [closedNotifications, setClosedNotifications] = useState<Record<string, boolean>>({});

  const event = currentEvent ?? nextEvent;
  const dtNow = new Date();
  const eventStart = event ? new Date(event!.start * 1000) : null;
  const SIZE = 0.6;

  const spaceId = OTUITree.userManager.currentTeamId

  let label
  let tooltip

  if (eventStart && format(eventStart, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')) {
    const now = new Date();
    if (eventStart < now) {
      tooltip = `${event?.event.summary} started at ${format(eventStart, 'HH mm')}`
    } else {
      tooltip = `Next event '${event?.event.summary}' starting at ${format(eventStart, 'HH mm')}`
    }
    if ( UIState.dockHorizontal) {
      label = format(eventStart, 'HH mm')
    } else {
      label = format(eventStart, 'HH:mm')
    }
  } else if (eventStart) {
    tooltip = `${event?.event.summary} starting on ${format(eventStart, 'EEEE HH:mm')}`
    label = format(eventStart, 'EEE')
  }

  useEffect(() => {
    setDockComponentLength("NextEvent", label ? SIZE : 0);
    return () => setDockComponentLength("NextEvent", 0);
  }, [label === undefined])


  useEffect(() => autorun(() => {
    runInAction(() => {

      const requiresNotification = !!currentEvent && !closedNotifications[currentEvent.event.id!];
      if (requiresNotification && spaceId) {

        showNotification({
          teamId: spaceId,
          title: `Meeting ${currentEvent.event.summary} is starting`,
          text: `Join now`,
        })

        setClosedNotifications({
          ...closedNotifications,
          [currentEvent.event.id!]: true,
        });
      }


    })
  }), [spaceId, currentEvent, closedNotifications])



  return label ? (
    <StyledDiv
      data-tooltip={tooltip}
      style={{
        fontSize: UIState.dockSize / 4.5,
        minWidth: UIState.dockHorizontal ? UIState.dockSize * (SIZE - 0.1) : UIState.dockSize * 0.8,
        minHeight: UIState.dockHorizontal ? UIState.dockSize * 0.8 : UIState.dockSize * (SIZE - 0.1),
        margin: UIState.dockSize * .05
      }}
    >
      {label}
    </StyledDiv>
  ) : null
}

export default observer(DockNextEvent);


const StyledDiv = styled('div')(() => (
  {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4
  }
))

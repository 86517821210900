

export const parseMeetingUrl = (meetingUrl: string, meetUrls: string[]) => {
  // https://openteam-dev.firebaseapp.com/meet/MehvovubkQDeMBEvR194Cgc8TXQ

  if (!meetUrls.some(meetUrl => meetingUrl.startsWith(meetUrl)))
    throw new Error("url is  not allowed");

  const url = new URL(meetingUrl);

  const match = url.pathname.match(/\/meet\/(.*)/);
  if (!match)
    throw new Error("failed to match path");

  const token = match[1];

  if (!token)
    throw new Error("token is not found");

  return token;
}
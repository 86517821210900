import { Logger } from '@openteam/app-util';
import { INotification } from "@openteam/models";
import { OTUITree } from '@openteam/app-core';
import { UIState } from '../Data/UIState';

const logger = new Logger("Notifications")
let notifications: { [id: string]: Notification } = {}
let init: boolean = false


export function initNotifications () {
  if (!init) {
    init = true

    if ('Notification' in window) {
      Promise.resolve(Notification.requestPermission()).then(function(permission) {
        logger.info(permission);
      });
    }
  }
}

export function showNotification(n: INotification) {
  if (!('Notification' in window )) {
    return
  }

  initNotifications()

  var img = '../assets/icon.png';
  var notification = new Notification(
    `${n.title} - ${UIState.productName}`,
    {
       body: n.text,
       icon: n.imageUrl || img,
       requireInteraction: n.id ? true : false,
       silent: true
    });
  notification.onclick = function(x) {
    logger.debug(`Notification clicked, switching to teamId: ${n.teamId}`)
    this.close();
    OTUITree.userManager.setCurrentTeam(n.teamId);
    n.onPress && n.onPress()
  };

  if (n.id) {
    notifications[n.id] = notification
  }
}

export function hideNotification(id) {
  if (notifications[id]) {
    logger.debug(`Hiding notification ${id}`)
    notifications[id].close()
  } else {
    logger.debug(`Notification ${id} not found, can't hide`)
  }
}

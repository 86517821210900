import { observer } from "mobx-react"
import React from "react"
import { DSButton, IDSButtonProps } from "./DSButton"
import { DSTheme } from "./DSTheme"

interface IDSwitchProps extends Omit<IDSButtonProps, 'title' | 'icon' | 'onChange'> {
  checked: boolean
  onChange?: (checked: boolean) => void
  activeColor?: string
  activeBackgroundColor?: string
  inactiveColor?: string
  inactiveBackgroundColor?: string
}

export const DSSwitch: React.FC<IDSwitchProps> = observer((
  { checked, onChange, ...props }) => {

  const activeColor = props.activeColor || 'white'
  const activeBackgroundColor = props.activeBackgroundColor || DSTheme.EmphasisColor
  const inactiveColor = props.inactiveColor || '#BBBEC0'
  const inactiveBackgroundColor = props.inactiveBackgroundColor || '#545C5F'

  return (
    <DSButton
      hoverEffect={"brightness(90%)"}
      style={{
        position: "relative",
        opacity: props.disabled ? 0.3 : 1,
        backgroundColor: props.disabled ? '#6B6A6A' : (checked ? activeBackgroundColor : inactiveBackgroundColor),
        transition: "all 0.2s ease-in-out",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: 1,
        borderRadius: 999,
        width: 36,
        minWidth: 36,
        height: 18,
        minHeight: 18
      }}
      onClick={() => onChange && onChange(!checked)}
      {...props}
    >
      <div
        style={{
          transform: checked ? 'translateX(18px)' : undefined,
          transition: "all 0.2s ease-in-out",
          backgroundColor: props.disabled ? '#575757' : (checked ? activeColor : inactiveColor),
          borderRadius: 999,
          margin: 1,
          height: 16,
          width: 16
        }}
      />
    </DSButton>
  )
})

import { createResourceFromAttachment } from '@openteam/app-core';
import { WrappedRow } from '@openteam/design-system';
import { IChatMessage, IOTChatMessage, IPendingMessage, IPinnedResource } from '@openteam/models';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataState } from '../../Data/DataState';
import { LightBoxEntry } from '../Lightbox/Lightbox';
import { ChatFile } from './ChatFile';

type ChatFilesProps = {
  spaceId: string;
  message: IOTChatMessage;
  pendingMessage?: IPendingMessage;
  updateResource?: (resource: IPinnedResource, isPinned: boolean, isResource: boolean) => void;
  showLightbox?: (items: LightBoxEntry[], initialSelected?: number) => void;
  filterMedia: boolean;
};

const isMedia = (type: string) => {
  return type.includes('image');
};

const ChatFiles: React.FC<ChatFilesProps> = ({
  spaceId,
  message,
  pendingMessage,
  showLightbox,
  updateResource,
  filterMedia
}) => {
  const space = DataState.spaces[spaceId];

  const user = space.users[message.userId] || {
    id: message.userId,
    name: 'Unknown User'
  };

  const attachmentIds = Object.keys(message.attachments || {})
    .sort()
    .filter((fileId) => (isMedia(message.attachments![fileId].type) ? filterMedia : !filterMedia));

  const saveAttachmentResource = updateResource
    ? (isResource: boolean, isPinned: boolean, fileId: string) => {
        if (!message.attachments?.[fileId]) throw new Error(`unknown attachment (${fileId})`);

        const file = message.attachments[fileId];
        const m = message as unknown as IChatMessage;

        const resource = createResourceFromAttachment(
          m.channelId,
          message.id,
          fileId,
          file,
          file.url!,
          user.name,
          m.crDate.getTime()
        );

        return updateResource(resource, isPinned, isResource);
      }
    : undefined;

  const onAttachmentClick = (index: number) => {
    const attachmentId = attachmentIds[index];
    const file = message.attachments![attachmentId];

    const attachments = attachmentIds.map((id) => ({
      type: message.attachments![id].type,
      url: message.attachments![id].url!,
      crDate: message.crDate,
      user: user
    }));

    if (showLightbox) {
      showLightbox(attachments, index);
    } else {
      file.url && window.Main.shellOpenExternal(file.url);
    }
  };

  return (
    <WrappedRow style={{ alignItems: 'flex-start' }}>
      {attachmentIds.map((fileId, index) => {
        const attachment = message.attachments![fileId];
        const pendingFile = pendingMessage?.attachmentFiles?.[fileId];

        const filesIndex = message.files?.findIndex((file) => file.url == attachment.url);

        const resourceFile = message.files && filesIndex != undefined ? message.files[filesIndex] : undefined;

        return (
          <ChatFile
            key={`${fileId}`}
            onClick={() => onAttachmentClick(index)}
            file={attachment}
            url={attachment.url || pendingFile?.downloadUrl || pendingFile?.getPreviewURL()}
            previewUrl={attachment.url || pendingFile?.downloadUrl || pendingFile?.getPreviewURL()}
            uploading={(pendingFile && !pendingFile.completed) || !attachment.uploaded}
            progress={pendingFile?.progress || attachment.progress}
            onRemove={pendingFile && !pendingFile.completed ? () => pendingFile.stop() : undefined}
            resourceParams={resourceFile}
            saveAttachmentResource={
              saveAttachmentResource ? (...args) => saveAttachmentResource(...args, fileId) : undefined
            }
          />
        );
      })}
    </WrappedRow>
  );
}
export default observer(ChatFiles);

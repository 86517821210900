import { Logger } from "@openteam/app-util"
import { observer } from "mobx-react";
import React, { useLayoutEffect } from "react";
import { DSColumn } from "../../DesignSystem";
import { DSTheme } from "../..//DesignSystem/DSTheme";
import { PopupOptions, SubWindow, SubWindowCSS } from "../SubWindow";
import SearchControl from "./SearchControl";
import { ChatMarkdownCSS } from "../Chat/ChatMarkdownView";
import { getDockSize, UIState } from "../../Data/UIState";
import { getGenericWindowPosition } from "../../Controllers/DockWindowUtil";


const logger = new Logger("SearchWindow")

type Props = {
  spaceId: string
  onClose: () => void
}

type Position = {
  x: number
  y: number
}

export const SearchWindow: React.FC<Props> = observer((props) => {
  const windowId = "search-window"
  let width = 700
  let height = 500

  const [position, setPosition] = React.useState<Position>();

  const calcPosition = () => {
    const pos = getGenericWindowPosition(width, height)

    logger.debug(`Positioning to ${pos.x},${pos.y}`)

    return pos
  }


  useLayoutEffect(() => {
    if (UIState.dockSearchOpen) {
      logger.debug(`Calculating position`)
      setPosition(calcPosition())
    }
  }, [UIState.dockSearchOpen])

  return (position && props.spaceId) ? (
    <SubWindow
      id={windowId}
      parentId={"__dock__"}
      show={UIState.dockSearchOpen}
      initialPosition={position}
      initialSize={{ width: width, height: height }}
      title={`Search`}
      onBlur={props.onClose}
      onClose={props.onClose}
      focus={true}
      options={{
        ...PopupOptions,
        transparent: true,
        hasShadow: true,
      }}
    >
      <style type="text/css">{SubWindowCSS}</style>
      <style type="text/css">{ChatMarkdownCSS}</style>
      <SearchPanel>
        <SearchControl windowId={windowId} spaceId={props.spaceId} onClose={props.onClose} />
      </SearchPanel>
    </SubWindow>
  ) : null
})


export const SearchPanel: React.FC<{}> = ({ children, ...props }) => {
  return (
    <DSColumn
      className="SearchPanel"
      style={{
        flexGrow: 1,
        height: "100%",
        borderRadius: 10,
        backgroundColor: DSTheme.PanelBackgroundColor,
        overflow: 'hidden',
      }}
    >
        {children}
    </DSColumn>
  )
}
import React, { Children, CSSProperties, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSpring, animated, useTransition, config, a } from '@react-spring/web'
import { UIState } from "../Data/UIState";
import { DSTheme } from "./DSTheme";
import { Logger } from "@openteam/app-util"

const logger = new Logger("DSAnimate")

interface IDSAnimateGrowProps {

  start: boolean

  width: number
  height: number

  onAnimationStart?: () => void
  onAnimationEnd?: () => void
}

export const DSAnimateGrow: React.FC<IDSAnimateGrowProps> = React.memo((props) => {

  let animating: boolean = false
  function onSpringStart() {
    if (!animating) {
      animating = true;
      props.onAnimationStart?.()
    }
  }
  function onSpringRest() {
    if (animating) {
      animating = false;
      props.onAnimationEnd?.()
    }
  }

  const springStyle = useSpring({
    to: props.start && {
      width: props.width, height: props.height, opacity: 1
    },
    from: { width: 0, height: 0, opacity: 0, },
    onStart: onSpringStart,
    onRest: onSpringRest
  })

  useEffect(() => logger.debug(`Rendering AnimateGrow`));

  return <animated.div
    style={{
      ...springStyle
    }}>
    {props.children}
  </animated.div>
})

interface IDSAnimateListGrowProps {
  label?: string
  items: any[]
  width: number
  height: number
  spacing?: number
  horizontal?: boolean
  fromStyle?: CSSProperties,
  leaveStyle?: CSSProperties | ((key, index) => {})
  initialStyle?: CSSProperties | ((key, index) => {})
  enterStyle?: CSSProperties | ((key, index) => {})
  updateStyle?: CSSProperties | ((key, index) => {})
  transitionOptions?: any
  renderItem: (item, index) => JSX.Element | null
  itemStyle?: React.CSSProperties
  outerStyle?: React.CSSProperties
  onStart?: (result, spring, item) => void
  onRest?: (result, spring, item) => void
  onListStart?: () => void
  onListRest?: () => void
}

export const DSAnimateListGrow: React.FC<IDSAnimateListGrowProps> = React.memo((props) => {
  const animating = useRef<Record<string, boolean>>({})

  const transitions = useTransition(props.items, {
    initial: props.initialStyle ?? { width: props.width, height: props.height, opacity: 1 },
    from: props.fromStyle ?? { width: 0, height: 0, opacity: 0, },
    enter: props.enterStyle ?? { width: props.width, height: props.height, opacity: 1 },
    update: props.updateStyle ?? props.enterStyle ?? { width: props.width, height: props.height, opacity: 1 },
    leave: props.leaveStyle ?? { width: 0, height: 0, opacity: 0 },
    //delay: 200,
    config: config.default,
    ...props.transitionOptions,

    onStart: (v, s, item) => {
      if (!animating.current[item]) {
        animating.current[item] = true
        if (Object.keys(animating.current).length === 1) {
          props.onListStart && props.onListStart()
        }
        props.onStart && props.onStart(v, s, item)
      }
    },

    onRest: (v, s, item) => {
      delete animating.current[item]

      props.onRest && props.onRest(v, s, item)

      if (Object.keys(animating.current).length === 0) {
        props.onListRest && props.onListRest()
      }
    }
  })

  //useLayoutEffect(() => logger.debug(`Rendering ListGrow ${props.label ?? ''}`));

  return (
    <div className="outerStyle" style={{
      display: 'flex',
      flexDirection: props.horizontal ? 'row' : 'column',
      ...props.outerStyle
    }}>
      {transitions((springStyle, item, transitionState, index) => (
        <animated.div
          className="animatedDiv"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "center",
            padding: props.spacing,
            ...props.itemStyle,
            ...springStyle,
          }}>
          {props.renderItem(item, index)}
        </animated.div>
      ))}
    </div>
  )
})

// export const DSAnimateListGrow2: React.FC<Omit<IDSAnimateListGrowProps, 'renderItem|items'>> = React.memo((props) => {
//   const animating = useRef<Record<string, boolean>>({});
//   const _children = Children.toArray(props.children);
//   const _keys = _children.map((child, index) => child.key ?? index);
//   const transitions = useTransition(_children, {
//     keys: _keys,
//     initial: props.initialStyle ?? { width: props.width, height: props.height, opacity: 1 },
//     from: props.fromStyle ?? { width: 0, height: 0, opacity: 0 },
//     enter: props.enterStyle ?? { width: props.width, height: props.height, opacity: 1 },
//     update: props.updateStyle ?? props.enterStyle ?? { width: props.width, height: props.height, opacity: 1 },
//     leave: props.leaveStyle ?? { width: 0, height: 0, opacity: 0 },
//     //delay: 200,
//     config: config.default,
//     ...props.transitionOptions,

//     onStart: (v, s, item) => {
//       if (!animating.current[item]) {
//         animating.current[item] = true;
//         if (Object.keys(animating.current).length === 1) {
//           props.onListStart && props.onListStart();
//         }
//         props.onStart && props.onStart(v, s, item);
//       }
//     },

//     onRest: (v, s, item) => {
//       delete animating.current[item];

//       props.onRest && props.onRest(v, s, item);

//       if (Object.keys(animating.current).length === 0) {
//         props.onListRest && props.onListRest();
//       }
//     }
//   });

//   useEffect(() => logger.debug(`Rendering ListGrow2 ${props.label ?? ''}, keys`, _children, _keys));

//   return (
//     <div className="outerStyle" style={{ display: 'flex', ...props.outerStyle }}>
//       {transitions((springStyle, item, transitionState, index) => (
//         <animated.div
//           className="animatedDiv"
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             ...springStyle
//           }}
//         >
//           {item}
//         </animated.div>
//       ))}
//     </div>
//   );
// });


interface IDSAnimateListGrowSpcProps extends IDSAnimateListGrowProps {
  spacing: number
}

export const DSAnimateGrowListGrowSpc = ({ spacing, ...props }: IDSAnimateListGrowSpcProps) => {

  const { width, height, items } = props;

  return <DSAnimateListGrow
    {...props}
    initialStyle={(item, index) => ({
      width,
      height,
      opacity: 1,
      paddingBottom: index === items.length - 1 ? 0 : spacing,
    })}
    enterStyle={(item, index) => ({
      width,
      height,
      opacity: 1,
      paddingBottom: index === items.length - 1 ? 0 : spacing,
    })}
    updateStyle={(item, index) => ({
      width,
      height,
      opacity: 1,
      paddingBottom: index === items.length - 1 ? 0 : spacing,
    })}
    leaveStyle={{
      width: 0,
      height: 0,
      opacity: 0,
      paddingBottom: 0,
    }}
    fromStyle={{
      width: 0,
      height: 0,
      opacity: 0,
      paddingBottom: 0,
    }} />

}
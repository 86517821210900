

export const toRad = (deg: number) => (deg / 180) * Math.PI;
export const toDeg = (rad: number) => (rad * 180.0) / Math.PI;

export default function degToPos(deg: number) {
    // 0 is N
    const rad = toRad(deg);
    const x = Math.sin(rad) * 0.5;
    const y = Math.cos(rad) * 0.5;
    const pos = {
      left: `${(x + 0.5) * 100}%`,
      top: `${(0.5 - y) * 100}%`
    }
    return pos
  }
  